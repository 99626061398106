/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAbstractRecord } from '../../../../models';
import { IListingData } from '../../../../models/listing';
import { trimTrailingZeros } from '../../../../utils/formatting-utils';

export function mapToMarinePolicyListData(
  data: any,
  statuses: Record<string, string>
): IListingData {
  const list: Record<string, any> = {};
  const items = data.Production.queries.getPolicyList.items;

  items.forEach((item: any) => {
    const policyId = item.production_Policy_Id;

    // Certificate Number
    const certificateNumber = item.production_Policy_CertificateNumber || '';

    // Subline
    const sublineExternalCode =
      item.production_Policy_SublineID?.views?.PlanConfigManagement_all
        ?.properties?.ExternalCode || '';
    const sublineName =
      item.production_Policy_SublineID?.views?.PlanConfigManagement_all
        ?.properties?.Name || '';
    const subline = `${sublineExternalCode} - ${sublineName}`;
    // Plan
    const planExternalCode =
      item.production_Policy_PlanID?.views?.PlanConfigManagement_all?.properties
        ?.ExternalCode || '';
    const planName =
      item.production_Policy_PlanID?.views?.PlanConfigManagement_all?.properties
        ?.Name || '';
    const plan = `${planExternalCode} - ${planName}`;
    const matter = item.policyMarine_Matter?.Title || '';
    const vesselName = item.policyMarine_VesselName || '';
    const vesselAge = item.policyMarine_VesselAge || '';

    const departureFrom = item.policyMarine_DepartureFrom || '';
    const destinationTo = item.policyMarine_DestinationTo || '';

    // Transportation Via
    const transportationVia = item.policyMarine_TransportationVia || '';

    // LC Details
    const lcNumber = item.policyMarine_LCNumber || '';
    const lcIssueDate = item.policyMarine_LCIssueDate || '';

    // Loading
    const loading = item.policyMarine_Loading || '';

    // Financials
    const sumPerShipment = item.policyMarine_SumPerShipment || 0;
    const sumInsured = item.pricingOption_SumInsured || 0;
    const netPremium = item.pricingOption_NetPremium || 0;

    const policyCostAmount = item.pricingOption_PolicyCostAmount || 0;
    const chargesAmount = item.pricingOption_ChargesAmount || 0;
    const tpaFeesTotalAmount = item.pricingOption_TPAFeesTotalAmount || 0;

    const costAndCharges =
      policyCostAmount + chargesAmount + tpaFeesTotalAmount;

    const fixedStampAmount = item.pricingOption_FixedStampAmount || 0;
    const proportionalStampAmount =
      item.pricingOption_ProportionalStampAmount || 0;
    const municipalityTaxAmount = item.pricingOption_MunicipalityTaxAmount || 0;

    const taxes =
      fixedStampAmount + proportionalStampAmount + municipalityTaxAmount;

    const totalPremium = item.pricingOption_TotalPremium || 0;

    // Effective Date and Status
    const effectiveDate = item.production_Policy_PolicyEffectiveDate || '';
    const status = statuses[item.production_Policy_Status] || '';

    // Currency Symbol
    const currencySymbol =
      item.pricingOption_PricingOptionCurrency?.Symbol || '';

    list[policyId] = {
      policyId,
      Production_Policy_CertificateNumber: certificateNumber,
      Production_Policy_SublineID: subline,
      Production_Policy_PlanID: plan,
      PolicyMarine_Matter: matter,
      PolicyMarine_VesselName: vesselName,
      PolicyMarine_VesselAge: vesselAge,
      PolicyMarine_DepartureFrom: departureFrom,
      PolicyMarine_DepartureTo: destinationTo,
      PolicyMarine_TransportationVia: transportationVia,
      PolicyMarine_LCNumber: lcNumber,
      PolicyMarine_LCIssueDate: lcIssueDate,
      PolicyMarine_Loading: loading,
      PolicyMarine_SumPerShipment: sumPerShipment,
      PricingOption_SumInsured: sumInsured,
      PricingOption_NetPremium: netPremium,
      costAndCharges,
      taxes,
      totalPremium,
      effectiveDate,
      Production_Policy_Status: status,
      currencySymbol,
    };
  });

  return {
    pagedItems: list,
    pageSize: data.Production.queries.getPolicyList.paging.pageSize,
    pageNumber: data.Production.queries.getPolicyList.paging.pageNumber,
    totalCount: data.Production.queries.getPolicyList.paging.totalCount,
  };
}

export function mapToFleetPolicyListData(
  data: IAbstractRecord,
  statuses: Record<string, string>
): IListingData {
  const list: Record<string, any> = {};
  const items = data.Production.queries.getPolicyList.items;

  items.forEach((item: any) => {
    const policyId = item.production_Policy_Id;

    const certificateNumber = item.production_Policy_CertificateNumber || '';

    const sublineExternalCode =
      item.production_Policy_SublineID?.views?.PlanConfigManagement_all
        ?.properties?.ExternalCode || '';
    const sublineName =
      item.production_Policy_SublineID?.views?.PlanConfigManagement_all
        ?.properties?.Name || '';
    const subline = `${sublineExternalCode} - ${sublineName}`;

    const planExternalCode =
      item.production_Policy_PlanID?.views?.PlanConfigManagement_all?.properties
        ?.ExternalCode || '';
    const planName =
      item.production_Policy_PlanID?.views?.PlanConfigManagement_all?.properties
        ?.Name || '';
    const plan = `${planExternalCode} - ${planName}`;

    const plateNumber = item.vehicle_PlateNumber || '';
    const plateCode = item.vehicle_PlateCode?.Title || '';
    const plate = `${plateNumber}/${plateCode}`;

    const make = item.vehicle_Make?.Title || '';
    const model = item.vehicle_Model?.Title || '';
    const color = item.vehicle_Color?.Title || '';
    const yearOfMake = item.vehicle_YearOfMake || '';
    const chassis = item.vehicle_Chassis || '';

    const sumInsured = item.pricingOption_SumInsured || 0;
    const netPremium = item.pricingOption_NetPremium || 0;

    const policyCostAmount = item.pricingOption_PolicyCostAmount || 0;
    const chargesAmount = item.pricingOption_ChargesAmount || 0;
    const tpaFeesTotalAmount = item.pricingOption_TPAFeesTotalAmount || 0;

    const costAndCharges =
      policyCostAmount + chargesAmount + tpaFeesTotalAmount;

    const fixedStampAmount = item.pricingOption_FixedStampAmount || 0;
    const proportionalStampAmount =
      item.pricingOption_ProportionalStampAmount || 0;
    const municipalityTaxAmount = item.pricingOption_MunicipalityTaxAmount || 0;

    const taxes =
      fixedStampAmount + proportionalStampAmount + municipalityTaxAmount;

    const totalPremium = item.pricingOption_TotalPremium || 0;

    const effectiveDate = item.production_Policy_PolicyEffectiveDate || '';
    const status = statuses[item.production_Policy_Status] || '';

    const currencySymbol =
      item.pricingOption_PricingOptionCurrency?.Symbol || '';

    list[policyId] = {
      Production_Policy_CertificateNumber: certificateNumber,
      Production_Policy_SublineID: subline,
      Production_Policy_PlanID: plan,
      Vehicle_PlateNumber: plate,
      Vehicle_Make: make,
      Vehicle_Model: model,
      Vehicle_Color: color,
      Vehicle_YearOfMake: yearOfMake,
      Vehicle_Chassis: chassis,
      PricingOption_SumInsured: sumInsured,
      PricingOption_NetPremium: netPremium,
      costAndCharges,
      taxes,
      PricingOption_TotalPremium: totalPremium,
      Production_Policy_PolicyEffectiveDate: effectiveDate,
      Production_Policy_Status: status,
      currencySymbol,
      policyId,
    };
  });

  return {
    pagedItems: list,
    pageSize: data.Production.queries.getPolicyList.paging.pageSize,
    pageNumber: data.Production.queries.getPolicyList.paging.pageNumber,
    totalCount: data.Production.queries.getPolicyList.paging.totalCount,
  };
}

export function mapToMarinePolicyListDataToExcel(
  data: IAbstractRecord
): Record<string, unknown>[] {
  const list: Record<string, unknown>[] = [];
  const items = data.Production.queries.getPolicyListWithoutPagination;

  items.forEach((item: any) => {
    // Certificate Number
    const certificateNumber = item.production_Policy_CertificateNumber || '';
    // Plan
    const planExternalCode =
      item.production_Policy_PlanID?.views?.PlanConfigManagement_all?.properties
        ?.ExternalCode || '';
    const planName =
      item.production_Policy_PlanID?.views?.PlanConfigManagement_all?.properties
        ?.Name || '';
    const plan = `${planExternalCode} - ${planName}`;

    const matter = item.policyMarine_Matter?.Title || '';
    const description = item.policyMarine_Description || '';
    const vesselName = item.policyMarine_VesselName || '';
    const vesselAge = item.policyMarine_VesselAge || '';
    const departureFrom = item.policyMarine_DepartureFrom || '';
    const destinationTo = item.policyMarine_DestinationTo || '';
    const transportationVia = item.policyMarine_TransportationVia || '';
    const survey = item.policyMarine_Survey || '';
    const cargoContract = item.policyMarine_CargoContract || '';
    const lcNumber = item.policyMarine_LCNumber || '';
    const lcIssueDate = item.policyMarine_LCIssueDate || null;
    const loading = item.policyMarine_Loading || '';
    const bank = item.policyMarine_Bank || '';
    const claimsPayableBy = item.policyMarine_ClaimsPayableBy || '';
    const remarks = item.policyMarine_Remarks || '';

    // Financials
    const sumPerShipment =
      trimTrailingZeros(item.policyMarine_SumPerShipment) || 0;
    const sumInsured = trimTrailingZeros(item.pricingOption_SumInsured) || 0;
    const netPremium = trimTrailingZeros(item.pricingOption_NetPremium) || 0;
    const policyCostAmount =
      trimTrailingZeros(item.pricingOption_PolicyCostAmount) || 0;
    const chargesAmount =
      trimTrailingZeros(item.pricingOption_ChargesAmount) || 0;
    const tpaFeesTotalAmount =
      trimTrailingZeros(item.pricingOption_TPAFeesTotalAmount) || 0;
    const fixedStampAmount =
      trimTrailingZeros(item.pricingOption_FixedStampAmount) || 0;
    const proportionalStampAmount =
      trimTrailingZeros(item.pricingOption_ProportionalStampAmount) || 0;
    const municipalityTaxAmount =
      trimTrailingZeros(item.pricingOption_MunicipalityTaxAmount) || 0;
    const totalPremium =
      trimTrailingZeros(item.pricingOption_TotalPremium) || 0;

    // Effective Date and Status
    const effectiveDate = item.production_Policy_PolicyEffectiveDate || '';

    // Currency Symbol
    const currencySymbol =
      item.pricingOption_PricingOptionCurrency?.Symbol || '';

    list.push({
      certificateNumber,
      description,
      survey,
      plan,
      matter,
      bank,
      claimsPayableBy,
      remarks,
      vesselName,
      vesselAge,
      departureFrom,
      cargoContract,
      destinationTo,
      transportationVia,
      lcNumber,
      lcIssueDate,
      loading: `${loading}%`,
      sumPerShipment,
      sumInsured,
      netPremium,
      totalPremium,
      effectiveDate,
      currencySymbol,
      policyCostAmount,
      chargesAmount,
      tpaFeesTotalAmount,
      fixedStampAmount,
      proportionalStampAmount,
      municipalityTaxAmount,
    });
  });

  return list;
}

export function mapToFleetPolicyListDataToExcel(
  data: IAbstractRecord
): Record<string, unknown>[] {
  const list: Record<string, unknown>[] = [];
  const items = data?.Production?.queries?.getPolicyListWithoutPagination || [];

  items.forEach((item: any) => {
    const plateNumber = item.vehicle_PlateNumber;
    const plateCode = item.vehicle_PlateCode?.Code || '';
    const vignetteCode = item.policyMotor_VignetteCode || '';
    const vignetteText = item.policyMotor_Vignette || '';
    const planExternalCode =
      item.production_Policy_PlanID?.views?.PlanConfigManagement_all?.properties
        ?.ExternalCode || '';
    const planName =
      item.production_Policy_PlanID?.views?.PlanConfigManagement_all?.properties
        ?.Name || '';

    list.push({
      certificateNumber: item.production_Policy_CertificateNumber || '',
      plate: `${plateNumber}/${plateCode}`,
      vignette: `${vignetteCode}/${vignetteText}`,
      usage: item.vehicle_UsageType?.Title || '',
      body: item.vehicle_BodyType?.Title || '',
      engineType: item.vehicle_EngineType?.Title || '',
      make: item.vehicle_Make?.Title || '',
      model: item.vehicle_Model?.Title || '',
      color: item.vehicle_Color?.Title || '',
      yearOfMake: item.vehicle_YearOfMake || '',
      engine: item.vehicle_Engine || '',
      weight: item.vehicle_Weight || '',
      engineSize: item.vehicle_EngineSize || '',
      seatingCapacity: item.vehicle_SeatingCapacity || '',
      chassis: item.vehicle_Chassis || '',
      horsePower: item.vehicle_HorsePower || '',
      carValue: item.vehicle_CarValue || 0,
      agencyRepairYears: item.policyMotor_AgencyRepairYear || '',
      depreciationYears: item.policyMotor_DepreciationYears || '',
      plan: `${planExternalCode} - ${planName}`,
      effectiveDate: item.production_Policy_PolicyEffectiveDate || '',
      currencySymbol: item.pricingOption_PricingOptionCurrency?.Symbol || '',
      sumInsured: trimTrailingZeros(item.pricingOption_SumInsured) || 0,
      netPremium: trimTrailingZeros(item.pricingOption_NetPremium) || 0,
      policyCost: trimTrailingZeros(item.pricingOption_PolicyCostAmount) || 0,
      charges: trimTrailingZeros(item.pricingOption_ChargesAmount) || 0,
      fixedStamp: trimTrailingZeros(item.pricingOption_FixedStampAmount) || 0,
      proportionalTax:
        trimTrailingZeros(item.pricingOption_ProportionalStampAmount) || 0,
      municipalityTax:
        trimTrailingZeros(item.pricingOption_MunicipalityTaxAmount) || 0,
      tpaFees: trimTrailingZeros(item.pricingOption_TPAFeesTotalAmount) || 0,
      totalPremium: trimTrailingZeros(item.pricingOption_TotalPremium) || 0,
    });
  });

  return list;
}
