import { gql } from '@apollo/client';

export const saveTreatyMutation = gql`
  mutation saveTreaty(
    $TreatyName: String!
    $TreatyType: String!
    $Currency: String!
    $UnderwritingLimit: Decimal!
    $ClaimLimit: Decimal!
    $SubstandardLimit: Decimal!
    $EffecitiveDate: Date!
    $ExpiryDate: Date!
    $CurrentTreatyID: String!
    $LayerDetails: [Production_LayerDetails!]!
  ) {
    production {
      actions {
        saveTreaty(
          TreatyName: $TreatyName
          TreatyType: $TreatyType
          Currency: $Currency
          UnderwritingLimit: $UnderwritingLimit
          ClaimLimit: $ClaimLimit
          SubstandardLimit: $SubstandardLimit
          EffecitiveDate: $EffecitiveDate
          ExpiryDate: $ExpiryDate
          CurrentTreatyID: $CurrentTreatyID
          LayerDetails: $LayerDetails
        )
      }
    }
  }
`;
