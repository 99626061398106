import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../../../components/enhanced-table';

export interface IListingData {
  pagedItems: [];
  pageSize: number;
  pageNumber: number;
  totalCount: number;
}

export const getHeaders = (
  currency?: string
): Record<string, EnhancedTableHeader> => ({
  Production_Policy_CertificateNumber: {
    name: 'Production_Policy_CertificateNumber',
    title: 'Certificate Number',
    type: EnhancedTableHeaderType.Link,
    urlTemplate: '/production/policies/{policyId}',
    openUrlInNewTab: false,
  },
  Production_Policy_SublineID: {
    name: 'Production_Policy_SublineID',
    title: 'Subline',
    type: EnhancedTableHeaderType.Text,
  },
  Production_Policy_PlanID: {
    name: 'Production_Policy_PlanID',
    title: 'Plan',
    type: EnhancedTableHeaderType.Text,
  },
  Vehicle_PlateNumber: {
    name: 'Vehicle_PlateNumber',
    title: 'Plate',
    type: EnhancedTableHeaderType.Text,
  },
  Vehicle_Make: {
    name: 'Vehicle_Make',
    title: 'Make',
    type: EnhancedTableHeaderType.Text,
  },
  Vehicle_Model: {
    name: 'Vehicle_Model',
    title: 'Model',
    type: EnhancedTableHeaderType.Text,
  },
  Vehicle_Color: {
    name: 'Vehicle_Color',
    title: 'Color',
    type: EnhancedTableHeaderType.Text,
  },
  Vehicle_YearOfMake: {
    name: 'Vehicle_YearOfMake',
    title: 'Year of Make',
    type: EnhancedTableHeaderType.Text,
  },
  Vehicle_Chassis: {
    name: 'Vehicle_Chassis',
    title: 'Chassis',
    type: EnhancedTableHeaderType.Text,
  },
  PricingOption_SumInsured: {
    name: 'PricingOption_SumInsured',
    title: 'Sum Insured',
    type: EnhancedTableHeaderType.RoundedCurrency,
    currency: currency,
  },
  PricingOption_NetPremium: {
    name: 'PricingOption_NetPremium',
    title: 'Net Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
    currency: currency,
  },
  costAndCharges: {
    name: 'costAndCharges',
    title: 'Cost & Charges',
    type: EnhancedTableHeaderType.RoundedCurrency,
    currency: currency,
  },
  taxes: {
    name: 'taxes',
    title: 'Taxes',
    type: EnhancedTableHeaderType.RoundedCurrency,
    currency: currency,
  },
  PricingOption_TotalPremium: {
    name: 'PricingOption_TotalPremium',
    title: 'Total Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
    currency: currency,
  },
  Production_Policy_PolicyEffectiveDate: {
    name: 'Production_Policy_PolicyEffectiveDate',
    title: 'Effective Date',
    type: EnhancedTableHeaderType.Date,
  },
  Production_Policy_Status: {
    name: 'Production_Policy_Status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
});

export const excelHeaders = (currencySymbol?: string) => ({
  certificateNumber: {
    label: 'Certificate Number',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  plate: {
    label: 'Plate',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  vignette: {
    label: 'Vignette',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  usage: {
    label: 'Usage',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  body: {
    label: 'Body',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  engineType: {
    label: 'Engine Type',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  make: {
    label: 'Make',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  model: {
    label: 'Model',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  color: {
    label: 'Color',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  yearOfMake: {
    label: 'Year of Make',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  engine: {
    label: 'Engine',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  weight: {
    label: 'Weight (in Tons)',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  engineSize: {
    label: 'Engine Size (in cc)',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  seatingCapacity: {
    label: 'Seating Capacity',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  chassis: {
    label: 'Chassis',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  horsePower: {
    label: 'Horse Power',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  carValue: {
    label: `Car Value - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  agencyRepairYears: {
    label: 'Agency Repair Years',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  depreciationYears: {
    label: 'Number of Depreciation Years',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  plan: {
    label: 'Plan',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  effectiveDate: {
    label: 'Effective Date',
    visible: true,
    isDate: true,
    isCurrency: false,
    currencySymbol,
  },
  sumInsured: {
    label: `Sum Insured - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  netPremium: {
    label: `Net Premium - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  policyCost: {
    label: `Policy Cost - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  charges: {
    label: `Charges - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  tpaFees: {
    label: `TPA Fees - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  fixedStamp: {
    label: `Fixed Stamp - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  proportionalTax: {
    label: `Proportional Stamp - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  municipalityTax: {
    label: `Municipality Tax - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  totalPremium: {
    label: `Total Premium - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
});

export const summaryPopupHeaders: Record<string, EnhancedTableHeader> = {
  plan: {
    name: 'plan',
    title: 'Plan',
    type: EnhancedTableHeaderType.Text,
  },
  plate: {
    name: 'plate',
    title: 'Plate',
    type: EnhancedTableHeaderType.Text,
  },
  make: {
    name: 'make',
    title: 'Make',
    type: EnhancedTableHeaderType.Text,
  },
  model: {
    name: 'model',
    title: 'Model',
    type: EnhancedTableHeaderType.Text,
  },
  color: {
    name: 'color',
    title: 'Color',
    type: EnhancedTableHeaderType.Text,
  },
  yearOfMake: {
    name: 'yearOfMake',
    title: 'Year of Make',
    type: EnhancedTableHeaderType.Text,
  },
  chassis: {
    name: 'chassis',
    title: 'Chassis',
    type: EnhancedTableHeaderType.Text,
  },
  sumInsured: {
    name: 'sumInsured',
    title: 'Sum Insured',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  totalPremium: {
    name: 'totalPremium',
    title: 'Total Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  effectiveDate: {
    name: 'effectiveDate',
    title: 'Policy Effective Date',
    type: EnhancedTableHeaderType.Date,
  },
};
