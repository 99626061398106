import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { summaryPopupHeaders } from '.';
import ToastErrorMessage from '../../../../../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../../../../../constants';
import { toast } from 'react-toastify';
import {
  getCertificateDetailsOutputInput,
  mapToFleetPolicyListingData,
} from './utils';
import EnhancedButton from '../../../../../../components/form-fields/buttons/EnhancedButton';
import { useMutation } from '@apollo/client';
import { generateFleetCertificateOutput } from './queries';
import { Production_CertificateDetailsOutputInput } from '../../../../../../forms/add-certificate-drawer';
import ListingTable from '../../../../../../components/form-fields/table/ListingTable';
import { getError } from '../../../../../../utils/graph-utils';

interface IPolicyCertficateMarineGroupSummaryDialogProps {
  data: Production_CertificateDetailsOutputInput;
  entityId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const useStyles = makeStyles()(() => ({
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '15px',
    paddingTop: '1em',
  },
}));

const PolicyCertficateFleetSummaryDialog: React.FC<
  IPolicyCertficateMarineGroupSummaryDialogProps
> = ({ data, entityId, onClose = () => undefined, onSuccess }) => {
  const { classes } = useStyles();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const tableData = mapToFleetPolicyListingData(data);

  const [generateOutput] = useMutation(generateFleetCertificateOutput());

  const submit = async () => {
    setSubmitting(true);

    try {
      const certificateDetailsOutputInput =
        getCertificateDetailsOutputInput(data);
      const variables = {
        entityId: entityId,
        certificateDetailsOutputInput,
      };

      const { errors } = await generateOutput({
        variables,
        errorPolicy: 'all',
      });

      if (!errors) {
        onSuccess();
      } else {
        toast.error(<ToastErrorMessage>{getError(errors)}</ToastErrorMessage>);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <ListingTable
        disableSelection
        name={'table'}
        inlineTitle={'Certificates'}
        inline={true}
        headers={summaryPopupHeaders}
        data={tableData}
        actions={[]}
      />
      <div className={classes.buttonsContainer}>
        <EnhancedButton onClick={() => onClose()}>Cancel</EnhancedButton>
        <EnhancedButton
          state={submitting ? 'loading' : undefined}
          isPrimary
          onClick={() => submit()}
        >
          Submit
        </EnhancedButton>
      </div>
    </>
  );
};

export default PolicyCertficateFleetSummaryDialog;
