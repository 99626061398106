import _, { cloneDeep } from 'lodash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import EnhancedDatePicker from '../components/enhanced-form/EnhancedDatePicker';
import EnhancedInput from '../components/enhanced-form/EnhancedInput';
import EnhancedInternationalPhoneInput from '../components/enhanced-form/EnhancedInternationalPhoneInput';
import EnhancedNumberInput from '../components/enhanced-form/EnhancedNumberInput';
import EnhancedSwitch from '../components/enhanced-form/EnhancedSwitch';
import EnhancedUploader from '../components/enhanced-form/EnhancedUploader';
import { mainFontFamilyBold } from '../constants';
import { useAppSelector } from '../redux/hooks';
import { validateDynamicForm } from '../utils/dynamic-utils';
import { isEmpty } from '../utils/validationUtils';
import {
  DynamicFormInputType,
  FormInputTypes,
  IDynamicSection,
  IDynamicWithSectionsForm,
} from './index';
import EnhancedCurrencyInput from '../components/enhanced-form/EnhancedCurrencyInput';
import EnhancedPercentageInput from '../components/enhanced-form/EnhancedPercentageInput';
import EnhancedLink from '../components/enhanced-form/EnhancedLink';
import EnhancedInlinePaginatedChipInput from '../components/enhanced-form/EnhancedInlinePaginatedChipInput';
import EnhancedCheckbox from '../components/EnhancedCheckbox';
import NewChipsInput from '../components/enhanced-form/NewChipsInput';
import AutocompleteFormField from '../components/form-fields/AutocompleteFormField';
import EnhancedButton from '../components/form-fields/buttons/EnhancedButton';
import RepeaterBoxFormField from '../components/form-fields/RepeaterBoxFormField';
import { IAbstractRecord } from '../models';
import DatePickerFormField from '../components/form-fields/DatePickerFormField';
import SelectFormField from '../components/form-fields/SelectFormField';

const useStyles = makeStyles<{ secondaryButton: boolean }>()(
  (theme, { secondaryButton }) => ({
    form: {
      width: '100%',
    },
    submitBtn: {
      margin: '0 auto 25px auto!important',
      display: 'flex!important',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: secondaryButton ? 'space-between' : 'center',
    },
    titleStyling: {
      fontFamily: mainFontFamilyBold,
      lineHeight: '18px',
    },
  })
);

const SectionDynamicForm: React.FC<
  IDynamicWithSectionsForm & {
    children?: React.ReactNode;
    onCustomBlur?: (
      fieldName: string,
      values: Record<string, any>,
      errors: Record<string, any>,
      subFieldName?: string,
      index?: number
    ) => any;
    onCustomValidate?: (
      values: Record<string, any>,
      errors: Record<string, any>
    ) => Promise<Record<string, string>>;
    onSearch?: (fieldName: string, value: string) => Promise<void>;
    onAutocompleteSearch?: (
      fieldName: string,
      value: string,
      pagination: { pageNumber: number; pageSize: number }
    ) => Promise<{ options: Record<string, string>; totalCount: number }>;
  }
> = ({
  title,
  sections,
  buttonText,
  onSubmit,
  disableForm = false,
  submitButtonState = null,
  isSubmitButtonDisabled = false,
  children,
  secondaryButton = false,
  secondaryButtonText = '',
  onClickSecondaryButton = () => undefined,
  mainButtonBgColor = '',
  loadingFields = {},
  customStyles,
  onChange,
  hasDoprdownSpecificBehavior = false,
  itemsPerPage,
  areNextToEachOther = false,
  popUpStyling = false,
  onCustomBlur,
  onCustomValidate,
  onSearch,
  onAutocompleteSearch,
  ...restOfProps
}) => {
  const tenant = useAppSelector((state) => state.tenant);

  const [values, setValues] = useState<Record<string, any>>();
  const [errors, setErrors] = useState<Record<string, any>>();
  const [touched, setTouched] = useState<Record<string, any>>();

  const [validatingForm, setValidatingForm] = useState<boolean>(false);

  const { classes } = useStyles({ secondaryButton });

  const inputs = useMemo(() => {
    let inputs: Record<string, DynamicFormInputType> = {};

    Object.values(sections).forEach((section) => {
      inputs = { ...inputs, ...section.inputs };
    });
    return inputs;
  }, [sections]);

  useEffect(() => {
    const initialValues: Record<string, any> = values || {};
    const initialErrors: Record<string, string> = errors || {};
    const initialTouched: Record<string, any> = touched || {};
    let sectionInputs: Record<string, DynamicFormInputType> = {};

    let mappedValues = _.cloneDeep(values);

    if (!mappedValues) {
      mappedValues = {};

      Object.keys(inputs).forEach((inputKey) => {
        mappedValues[inputKey] = inputs[inputKey]?.value;
      });

      Object.values(sections).forEach((section) => {
        sectionInputs = { ...sectionInputs, ...section.inputs };
      });

      Object.values(sectionInputs).forEach((input) => {
        initialValues[input.name] = mappedValues[input.name];
        initialErrors[input.name] =
          initialErrors[input.name] || input.error || '';
        // add touched array in repeater
        if (input.type === FormInputTypes.repeaterbox) {
          const name = input.name;
          if (isEmpty(initialTouched[name])) {
            initialTouched[name] = [];
          }

          initialValues[name].forEach(
            (item: Record<string, string>, index: number) => {
              initialTouched[name][index] = {};
              Object.keys(input.inputs).forEach((key) => {
                initialTouched[name][index][key] = false;
              });
            }
          );
        } else {
          initialTouched[input.name] =
            initialTouched[input.name] || !!input.error;
        }
      });

      setValues(initialValues);
      setErrors(initialErrors);
      setTouched(initialTouched);
    }
  }, [sections]);

  const onFieldBlur = async (
    name: string,
    additionalProperties?: {
      subFieldName?: string;
      index?: number;
      input?: DynamicFormInputType;
    }
  ) => {
    const { subFieldName, index, input } = additionalProperties || {};

    const touchedTemp = Object.assign({}, touched);

    if (input?.type === FormInputTypes.repeaterbox) {
      touchedTemp[name][index][subFieldName] = true;
    } else {
      touchedTemp[name] = true;
    }

    if (onCustomBlur) {
      setValidatingForm(true);
      const result = await onCustomBlur(
        name,
        cloneDeep(values),
        cloneDeep(errors),
        subFieldName,
        index
      );
      setValidatingForm(false);
      setValues(result.values);
      setErrors(result.errors);
    }

    setTouched(touchedTemp);
  };

  const onFieldChange = (
    name: string,
    value: any,
    additionalProperties?: {
      input?: DynamicFormInputType;
    }
  ) => {
    const { input } = additionalProperties || {};
    const errorsTemp = Object.assign({}, errors);
    const valuesTemp = Object.assign({}, values);
    const touchedTemp = Object.assign({}, touched);
    valuesTemp[name] = value;
    errorsTemp[name] = validateDynamicForm(value, inputs[name], valuesTemp);
    if (onChange) {
      onChange(
        name,
        value,
        valuesTemp,
        errorsTemp,
        touchedTemp,
        additionalProperties
      );

      //update touched array
      if (input?.type === FormInputTypes.repeaterbox) {
        if (
          isEmpty(touchedTemp[name]) ||
          typeof touchedTemp[name] === 'boolean'
        ) {
          touchedTemp[name] = [];
        }

        values[name].forEach((item: Record<string, string>, index: number) => {
          touchedTemp[name][index] = touchedTemp[name][index] || {};
          Object.keys(input.inputs).forEach((key) => {
            touchedTemp[name][index][key] =
              touchedTemp[name][index][key] || false;
          });
        });
      }
    }
    setValues(valuesTemp);
    setErrors(errorsTemp);
    setTouched(touchedTemp);
  };

  const onFieldChangeByEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFieldChange(event.target.name, event.target.value);
  };

  const validate = async (): Promise<IAbstractRecord> => {
    let newErrors: IAbstractRecord = {};
    Object.values(inputs).forEach((input) => {
      newErrors[input.name] = validateDynamicForm(
        values[input.name],
        input,
        values
      );
    });

    if (onCustomValidate) {
      newErrors = await onCustomValidate(values, newErrors);
    }

    return newErrors;
  };

  const submit = async () => {
    if (!disableForm && !validatingForm) {
      setValidatingForm(true);
      const validateErrors = await validate();
      setValidatingForm(false);
      const isInValid = Object.values(validateErrors).some((v) => {
        if (Array.isArray(v)) {
          return v.some((e) => {
            return Object.values(e).some((i) => !isEmpty(i));
          });
        }
        return !isEmpty(v);
      });
      if (isInValid) {
        const initialTouched: Record<string, boolean> = {};
        Object.values(inputs).forEach((input) => {
          initialTouched[input.name] = true;
        });
        setErrors(validateErrors);
        setTouched(initialTouched);
      } else {
        onSubmit(values);
      }
    }
  };

  const renderInput = (input: DynamicFormInputType) => {
    const disabledInput =
      input.disabled ||
      (input.conditionalDisable && input.conditionalDisable(values)) ||
      disableForm ||
      validatingForm;

    const hidden =
      input.hidden ||
      (input.conditionalHidden && input.conditionalHidden(values));

    if (hidden) {
      return <></>;
    }

    const isRequired =
      input.required ||
      (input.conditionalRequired && input.conditionalRequired(values));
    const title = isRequired ? `${input.title}*` : input.title;

    switch (input.type) {
      case FormInputTypes.text:
        return (
          <EnhancedInput
            key={input.name}
            name={input.name}
            title={title}
            type="text"
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={(event) => {
              const newValue = event.target.value;
              onFieldBlur(input.name);
              if (!isEmpty(newValue)) {
                onFieldChange(input.name, newValue);
                if (input.onChange) {
                  input.onChange(event);
                }
              }
            }}
            onChange={(v) => onFieldChange(input.name, v.target.value)}
            disabled={disabledInput}
            description={input.description}
            multiline={input.multiline}
            material={input.material}
            customStyles={input.customStyles}
          />
        );
      case FormInputTypes.checkbox:
        return (
          <EnhancedCheckbox
            key={input.name}
            name={input.name}
            title={title}
            checked={values[input.name]}
            onChange={(name, val) => onFieldChange(input.name, val)}
            disabled={disabledInput || disableForm}
            className={input.className}
            classes={input.classes}
          />
        );
      case FormInputTypes.password:
        return (
          <EnhancedInput
            key={input.name}
            name={input.name}
            title={title}
            type="password"
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChange(input.name, v.target.value)}
            disabled={disabledInput}
            material={input.material}
            description={input.description}
            includePasswordhint={input.includePasswordHint}
            includePasswordVisibility={input.includePasswordVisibility}
            includeCapsLockCheck={input.includeCapsLockCheck}
            customStyles={input.customStyles}
          />
        );

      case FormInputTypes.date:
        return (
          <EnhancedDatePicker
            key={input.name}
            name={input.name}
            title={title}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={() => onFieldBlur(input.name)}
            onDateChange={(dateValue) => {
              onFieldChange(input.name, dateValue);

              input.value = dateValue;

              if (input.onChange) {
                input.onChange(dateValue);
              }
            }}
            disabled={disabledInput}
            material={input.material}
            minDate={input.minDate}
            maxDate={input.maxDate}
            placeholder={input.placeholder}
            format={tenant.dateFormat}
            canClearDate={input.canClearDate}
          />
        );

      case FormInputTypes.imageFile:
        return (
          <EnhancedUploader
            key={input.name}
            name={input.name}
            title={title}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onUpload={(v) => onFieldChange(input.name, v)}
            disabled={disabledInput}
            allowedFileTypes={input.allowedFileTypes}
            type={''}
          />
        );

      case FormInputTypes.select:
        return <span>deprecated</span>;
      case FormInputTypes.number:
        return (
          <EnhancedNumberInput
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChangeByEvent(v)}
            disabled={disabledInput}
            material={input.material}
            minValue={input.minNumber}
            maxValue={input.maxNumber}
          />
        );
      case FormInputTypes.percentage:
        return (
          <EnhancedPercentageInput
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChangeByEvent(v)}
            disabled={disabledInput}
            material={input.material}
          />
        );

      case FormInputTypes.switch:
        return (
          <EnhancedSwitch
            key={input.name}
            name={input.name}
            title={title}
            value={values[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(name, val) => onFieldChange(input.name, val)}
            disabled={disabledInput}
            required={isRequired}
          />
        );

      case FormInputTypes.multiSelect:
        return <span>deprecated</span>;
      case FormInputTypes.chips:
        return hasDoprdownSpecificBehavior && input.hasPagination ? (
          <EnhancedInlinePaginatedChipInput
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            preselectedValues={input.preselectedValues}
            error={touched[input.name] ? errors[input.name] : ''}
            onChange={(v) => {
              onFieldChange(input.name, v);
              if (!isEmpty(v) && input.onSelect) {
                input.onSelect(v as string);
              }
              const newValue = v;
              onFieldBlur(input.name);
              if (!isEmpty(newValue)) {
                onFieldChange(input.name, newValue);
                if (input.onChange) {
                  input.onChange(newValue);
                }
              }
            }}
            disabled={disabledInput}
            customStyles={input.customStyles}
            selectOptions={input.selectOptions}
            required={isRequired}
            freeSolo={input.freeSolo}
            multiple={input.multiple}
            material={input.material}
            loader={loadingFields[input.name] || false}
            showSelectAll={input.showSelectAll}
            strongStyledOption={input.strongStyledOption}
            hidden={hidden}
            itemsPerPage={input.itemsPerPage}
          />
        ) : (
          <NewChipsInput
            key={input.name}
            name={input.name}
            title={title}
            multiple={input.multiple}
            required={input.required}
            value={values[input.name]}
            values={values[input.name] || []}
            disabled={disabledInput}
            items={input.selectOptions}
            material={input.material}
            onSearch={
              onSearch
                ? async (v) => {
                    if (onSearch) {
                      await onSearch(input.name, v);
                    }
                  }
                : null
            }
            placeholder={input.placeholder}
            showSelectAll={input.showSelectAll}
            error={touched[input.name] ? errors[input.name] : ''}
            onChange={(v) => {
              onFieldChange(input.name, v);
              if (!input.multiple) {
                if (!isEmpty(v) && input.onSelect) {
                  input.onSelect(v as string);
                }
              }
            }}
          />
        );
      case FormInputTypes.phoneNumber:
        return (
          <EnhancedInternationalPhoneInput
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onChange={(v) => onFieldChange(input.name, v)}
            onBlur={(v) => onFieldBlur(input.name)}
            disabled={disabledInput}
            countriesToShow={input.countriesToShow}
            disableDropDown={input.disableDropDown}
            editCountryCode={input.editCountryCode}
            defaultCountry={input.defaultCountry}
            customFormat={input.customFormat}
            material={input.material}
          />
        );
      case FormInputTypes.currency:
        return (
          <EnhancedCurrencyInput
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            error={touched[input.name] ? errors[input.name] : ''}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChangeByEvent(v)}
            disabled={disabledInput}
            type={''}
            useCurrencyText={input.useCurrencySymbol}
            currencyText={input.useCurrencySymbol ? input.currencySymbol : ''}
            currencyTitle={input.useCurrencySymbol ? null : '$'}
            currencyIcon={
              input.useCurrencySymbol
                ? null
                : tenant.cdnUrl + '/icons/dollar-primary.svg'
            }
            onFocus={(v) => onFieldBlur(input.name)}
          />
        );

      case FormInputTypes.link:
        return (
          <EnhancedLink
            key={input.name}
            name={input.name}
            title={title}
            onClick={() => {
              onFieldChange(input.name, null);
              if (input.onClick) input.onClick();
            }}
            hidden={hidden}
          />
        );
      case FormInputTypes.newautocomplete:
        return (
          <AutocompleteFormField
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            error={touched[input.name] ? errors[input.name] : ''}
            onChange={(v) => {
              onFieldChange(input.name, v);
            }}
            disabled={disabledInput}
            material={input.material}
            loader={loadingFields[input.name] || false}
            maxItemsDisplayed={input.maxItemsDisplayed}
            fetchOptions={function (
              query: string,
              pagination: { pageNumber: number; pageSize: number }
            ): Promise<{
              options: Record<string, string>;
              totalCount: number;
            }> {
              if (onAutocompleteSearch) {
                return onAutocompleteSearch(input.name, query, pagination);
              }

              return new Promise<{
                options: Record<string, string>;
                totalCount: number;
              }>((resolve) => {
                resolve({ options: {}, totalCount: 0 });
              });
            }}
          />
        );
      case FormInputTypes.repeaterbox:
        return (
          <RepeaterBoxFormField
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            values={values[input.name]}
            error={errors[input.name]}
            onChange={(v) => {
              onFieldChange(input.name, v, { input });
            }}
            onBlur={(subFieldName: string, index: number) => {
              onFieldBlur(input.name, { subFieldName, index, input });
            }}
            onSearch={
              onSearch
                ? async (v) => {
                    if (onSearch) {
                      await onSearch(input.name, v);
                    }
                  }
                : null
            }
            disabled={disabledInput}
            material={input.material}
            touched={touched[input.name]}
            {...input}
          />
        );
      case FormInputTypes.newdate:
        return (
          <DatePickerFormField
            key={input.name}
            name={input.name}
            title={title}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={() => onFieldBlur(input.name)}
            onDateChange={(dateValue) => {
              onFieldChange(input.name, dateValue);

              input.value = dateValue;

              if (input.onChange) {
                input.onChange(dateValue);
              }
            }}
            disabled={disabledInput}
            material={input.material}
            minDate={input.minDate}
            maxDate={input.maxDate}
            placeholder={input.placeholder}
            format={tenant.dateFormat}
            canClearDate={input.canClearDate}
          />
        );
      case FormInputTypes.newselect:
        return (
          <SelectFormField
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            disabled={disabledInput}
            selectOptions={input.selectOptions}
            loader={loadingFields[input.name] || false}
            freeSolo={input.freeSolo}
            material={input.material}
            error={touched[input.name] ? errors[input.name] : ''}
            onChange={(value) => {
              onFieldChange(input.name, value);
            }}
            onBlur={() => {
              onFieldBlur(input.name);
            }}
            canClearValue={input.canClearValue}
          />
        );

      default:
        return <></>;
    }
  };

  const renderSection = (section: IDynamicSection) => {
    return (
      <Fragment key={section.title}>
        <div className={classes.titleStyling}>
          <p>
            {section.hasTitleSpecificDesign
              ? section.specificTitleDesign()
              : section.title}
          </p>
          <hr style={{ opacity: '0.25' }} />
          <br />
        </div>
        {Object.values(section.inputs).map((input) => renderInput(input))}
      </Fragment>
    );
  };

  return values ? (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      <p>{title}</p>
      {Object.values(sections).map((section) => renderSection(section))}

      {children}
      <div className={classes.footerContainer}>
        {secondaryButton && (
          <EnhancedButton
            onClick={() => onClickSecondaryButton()}
            disabled={disableForm}
            variant="contained"
            className={classes.submitBtn}
            state={submitButtonState}
          >
            {secondaryButtonText}
          </EnhancedButton>
        )}

        <EnhancedButton
          type="submit"
          disabled={
            disableForm ||
            isSubmitButtonDisabled ||
            Object.values(errors).some((v) => {
              if (Array.isArray(v)) {
                return v.some((e) => {
                  return Object.values(e).some((i) => !isEmpty(i));
                });
              }
              return !isEmpty(v);
            })
          }
          isPrimary
          state={submitButtonState}
          className={customStyles && customStyles.submitButtonStyles}
        >
          {buttonText}
        </EnhancedButton>
      </div>
    </form>
  ) : (
    <></>
  );
};

export default SectionDynamicForm;
