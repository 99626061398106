import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../../../components/enhanced-table';

export const getHeaders = (
  currency?: string
): Record<string, EnhancedTableHeader> => ({
  Production_Policy_CertificateNumber: {
    name: 'Production_Policy_CertificateNumber',
    title: 'Certificate Number',
    type: EnhancedTableHeaderType.Link,
    urlTemplate: '/production/policies/{policyId}',
    openUrlInNewTab: false,
  },
  Production_Policy_SublineID: {
    name: 'Production_Policy_SublineID',
    title: 'Subline',
    type: EnhancedTableHeaderType.Text,
  },
  Production_Policy_PlanID: {
    name: 'Production_Policy_PlanID',
    title: 'Plan',
    type: EnhancedTableHeaderType.Text,
  },
  PolicyMarine_Matter: {
    name: 'PolicyMarine_Matter',
    title: 'Matter',
    type: EnhancedTableHeaderType.Text,
  },
  PolicyMarine_VesselName: {
    name: 'PolicyMarine_VesselName',
    title: 'Vessel Name',
    type: EnhancedTableHeaderType.Text,
  },
  PolicyMarine_VesselAge: {
    name: 'PolicyMarine_VesselAge',
    title: 'Vessel Age',
    type: EnhancedTableHeaderType.Text,
  },
  PolicyMarine_DepartureFrom: {
    name: 'PolicyMarine_DepartureFrom',
    title: 'Departure From',
    type: EnhancedTableHeaderType.Text,
  },
  PolicyMarine_DepartureTo: {
    name: 'PolicyMarine_DepartureTo',
    title: 'Destination To',
    type: EnhancedTableHeaderType.Text,
  },
  PolicyMarine_TransportationVia: {
    name: 'PolicyMarine_TransportationVia',
    title: 'Transportation Via',
    type: EnhancedTableHeaderType.Text,
  },
  PolicyMarine_LCNumber: {
    name: 'PolicyMarine_LCNumber',
    title: 'LC Number',
    type: EnhancedTableHeaderType.Text,
  },
  PolicyMarine_LCIssueDate: {
    name: 'PolicyMarine_LCIssueDate',
    title: 'LC Issue Date',
    type: EnhancedTableHeaderType.Date,
  },
  PolicyMarine_Loading: {
    name: 'PolicyMarine_Loading',
    title: 'Loading',
    type: EnhancedTableHeaderType.Rate,
  },
  PolicyMarine_SumPerShipment: {
    name: 'PolicyMarine_SumPerShipment',
    title: 'Sum Per Shipment',
    type: EnhancedTableHeaderType.RoundedCurrency,
    currency: currency,
  },
  PricingOption_SumInsured: {
    name: 'PricingOption_SumInsured',
    title: 'Sum Insured',
    type: EnhancedTableHeaderType.RoundedCurrency,
    currency: currency,
  },
  PricingOption_NetPremium: {
    name: 'PricingOption_NetPremium',
    title: 'Net Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
    currency: currency,
  },
  costAndCharges: {
    name: 'costAndCharges',
    title: 'Cost & Charges',
    type: EnhancedTableHeaderType.RoundedCurrency,
    currency: currency,
  },
  taxes: {
    name: 'taxes',
    title: 'Taxes',
    type: EnhancedTableHeaderType.RoundedCurrency,
    currency: currency,
  },
  PricingOption_TotalPremium: {
    name: 'PricingOption_TotalPremium',
    title: 'Total Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
    currency: currency,
  },
  Production_Policy_PolicyEffectiveDate: {
    name: 'Production_Policy_PolicyEffectiveDate',
    title: 'Effective Date',
    type: EnhancedTableHeaderType.Date,
  },
  Production_Policy_Status: {
    name: 'Production_Policy_Status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
});

export const excelHeaders = (currencySymbol?: string) => ({
  certificateNumber: {
    label: 'Certificate Number',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  matter: {
    label: 'Matter',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  description: {
    label: 'Description',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  vesselName: {
    label: 'Vessel Name',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  vesselAge: {
    label: 'Vessel Age',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  departureFrom: {
    label: 'Departure From',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  destinationTo: {
    label: 'Destination To',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  transportationVia: {
    label: 'Transportation Via',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  survey: {
    label: 'Survey',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  lcNumber: {
    label: 'LC Number',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  lcIssueDate: {
    label: 'LC Issue Date',
    visible: true,
    isDate: true,
    isCurrency: false,
    currencySymbol,
  },
  bank: {
    label: 'Bank',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  claimsPayableBy: {
    label: 'Claims Payable By',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  remarks: {
    label: 'Remarks',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  loading: {
    label: 'Loading',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  sumPerShipment: {
    label: `Sum Per Shipment - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  plan: {
    label: 'Plan',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  effectiveDate: {
    label: 'Effective Date',
    visible: true,
    isDate: true,
    isCurrency: false,
    currencySymbol,
  },
  sumInsured: {
    label: `Sum Insured - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  netPremium: {
    label: `Net Premium - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  policyCostAmount: {
    label: `Policy Cost - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  chargesAmount: {
    label: `Charges - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  tpaFeesTotalAmount: {
    label: `TPA Fees - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  fixedStampAmount: {
    label: `Fixed Stamp - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  proportionalStampAmount: {
    label: `Proportional Stamp - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  municipalityTaxAmount: {
    label: `Municipality Tax - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  totalPremium: {
    label: `Total Premium - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
});
