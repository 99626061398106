/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  calculateAndSaveMedical,
  getPlanSpecificMedicalCovers,
  getProposalMedicalDetails,
  saveProposalDraftMedical,
  uploadMedicalInsuredList,
} from './queries';
import { validateProposalPageForm } from './validation';
import {
  convertProposalPageStateToSubmission,
  IProposalDetailsMedicalPageSubmissionModel,
} from './submission';
import { toast } from 'react-toastify';
import { isEmpty, isValidNumber } from '../../../../../utils/validationUtils';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
// import { getError } from "../../../../../utils/graph-utils";
import { IEnhancedMenuItem } from '../../../../../components';
import SimpleActionBar from '../../../../../components/SimpleActionBar';
import { gridWidthToPercentage } from '../../../../../utils/grid-utils';
import EnhancedStepper from '../../../../../components/common/EnhancedStepper';
import WidgetPaper from '../../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../../components/common/WidgetSection';
import { DEFAULT_ERROR_TEXT, MAIN_ONE_THEME } from '../../../../../constants';
import { useParams } from 'react-router-dom';
import CloseProposalDrawer from '../../../../../forms/close-proposal-drawer/CloseProposalDrawer';
import StaticLayout from '../../../../../page-layout/static-layout/StaticLayout';
import Loader from '../../../../../components/Loader';
import { ComissionProposalWidget } from '../../../../../forms/proposal-details-page/custom-widgets/ComissionProposalWidget';
import { getProposalStatus, LookupToList } from './utils';
import PolicyProposalQuoteWidget from '../../../../../forms/proposal-details-page/custom-widgets/PolicyProposalQuoteWidget';
import PolicyCostChargesWidget from '../../../../../forms/proposal-details-page/custom-widgets/PolicyCostChargesWidget';
import {
  getProposalPageFormState,
  IProposalCover,
  IProposalPageFormState,
} from './form';
import {
  convertToDetailsSummary,
  convertToPolicyPersons,
  convertToPolicyPlanProposalCovers,
  getPlanCoversLov,
  IProposalDetailsPageResponse,
  IProposalDetailsSummary,
} from './index2';
import PolicyDetailsEntityInfo from '../../../../../forms/proposal-details-page/custom-widgets/PolicyDetailsEntityInfo';
import { PolicyDetailsWidget } from '../../../../../forms/proposal-details-page/custom-widgets/PolicyDetailsWidget';
import PolicyPayerWidget from '../../../../../forms/proposal-details-page/custom-widgets/PolicyPayerWidget';
import PolicyOwnerWidget from '../../../../../forms/proposal-details-page/custom-widgets/PolicyOwnerWidget';
import ToastSuccessMessage from '../../../../../components/ToastSuccessMessage';
import { getError } from '../../../../../utils/graph-utils';
import ProposalMedicalDetailsWidget from '../../../../../forms/proposal-details-page/custom-widgets/ProposalMedicalDetailsWidget';
import ProposalMedicalPrincipalWidget from './custom-widgets/insured-details/ProposalMedicalPrincipalWidget';
import { MedicalInsuredTableWidget } from './custom-widgets/insured-table-widget/MedicalInsuredTableWidget';
import PolicyBeneficiaryWidget from '../../../../../forms/proposal-details-page/custom-widgets/PolicyBeneficiaryWidget';
import PreviewProposalClauseModalShell from '../modals/PreviewProposalClauseModalShell';
import ProposalMedicalGroupDetailsWidget from '../../../../../forms/proposal-details-page/custom-widgets/ProposalMedicalGroupDetailsWidget';

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '95%',
    width: '95%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
  buttonsContainer: {
    width: '95%',
    margin: '10px auto 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  widgetTopMargin: {
    marginTop: '1em',
  },
}));

const ProposalMedicalPage: React.FC<{ currentLineId: string }> = ({
  currentLineId,
}) => {
  const { classes } = useStyles();
  const [issueModalOpen, setIssueModalOpen] = useState<boolean>(false);
  const [closeProposalDrawerOpen, setCloseProposalDrawerOpen] =
    useState<boolean>(false);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const params = useParams();
  const entityId = params.id;

  const [pageState, onPageStateUpdate] = useState<IProposalPageFormState>(
    getProposalPageFormState()
  );

  const [data, setData] = useState<IProposalDetailsSummary>();

  const ownerIsDifferent: boolean =
    pageState?.values?.policyPersons?.payer?.ownerIsDifferent || false;

  const [coversList, setCoversList] = useState<IProposalCover[]>([]);

  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({
    classes: {},
    levels: {},
    policyCovers: {},
    cnssOptions: {},
    insuredRelations: {},
    continuity: {},
    gender: {
      MALE: 'Male',
      FEMALE: 'Female',
    },
  });

  const [saveAndCalculationAction] = useMutation(calculateAndSaveMedical());

  const [saveAsDraftAction] = useMutation(saveProposalDraftMedical());

  const [uploadInsuredList] = useMutation(uploadMedicalInsuredList());

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isClosed =
    data?.Status === 'CLOSED_WON' || data?.Status === 'CLOSED_LOST';

  const disabledPage = isClosed || submitting || loading;

  const [getProposalDetailsLazy] = useLazyQuery(getProposalMedicalDetails());

  const [getNonSelectedPlanCoverLazy] = useLazyQuery(
    getPlanSpecificMedicalCovers()
  );

  const getPlanCoverDetails = async (
    planId: string,
    planCoverIds: string[]
  ) => {
    const planCoverList: { data: any } = await getNonSelectedPlanCoverLazy({
      variables: {
        selectedPlanID: planId,
        selectedPlanCoverIDs: planCoverIds,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return planCoverList.data;
  };

  const initialize = async () => {
    setLoading(true);
    const result: { data: IProposalDetailsPageResponse } =
      await getProposalDetailsLazy({
        variables: {
          id: entityId,
          isPrincipal: null,
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

    const summaryData = convertToDetailsSummary(result.data);

    const initialCovers = convertToPolicyPlanProposalCovers(result.data);
    const initialPersons = convertToPolicyPersons(result.data);

    const planCoverDetails = await getPlanCoverDetails(
      summaryData.PlanID.Id,
      []
    );

    const lists = LookupToList(result.data, summaryData.LineId.ExternalCode);
    const lovCovers = getPlanCoversLov(planCoverDetails);
    setLovs({
      classes: lists.classes,
      levels: lists.levels,
      policyCovers: lovCovers,
      cnssOptions: lists.cnssOptions,
      continuity: lists.continuity,
      insuredRelations: lists.insuredRelations,
      gender: lovs.gender,
    });

    setCoversList(initialCovers);
    onPageStateUpdate(getProposalPageFormState(summaryData, initialPersons));
    setData(summaryData);
    setLoading(false);
  };

  const renderLeftSection = () => {
    if (data) {
      return <PolicyDetailsEntityInfo data={data as any} />;
    }

    return <></>;
  };

  const onSubmit = async () => {
    const validationResult = validateProposalPageForm(data, pageState.values);

    if (!submitting) {
      setSubmitting(true);
      const newPageState = {
        ...pageState,
        errors: validationResult.errors,
        touched: validationResult.touched,
      };

      onPageStateUpdate(newPageState);
      if (validationResult.isValid) {
        const dataToSubmit = convertProposalPageStateToSubmission(
          data,
          pageState
        );

        try {
          const result = await saveAndCalculationAction({
            variables: { ...dataToSubmit },
            errorPolicy: 'all',
          });
          if (isEmpty(result.errors)) {
            // setDeactivateButtonState("success");
            toast.success(
              <ToastSuccessMessage>
                {'Proposal successfully saved.'}
              </ToastSuccessMessage>
            );
            initialize();
          } else {
            // setRegenerateButtonState(undefined);
            toast.error(
              <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
            );
          }
        } catch (err) {
          toast.error(
            <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
          );
          setSubmitting(false);
        }
      } else {
        toast.error(<ToastErrorMessage>Incomple Form</ToastErrorMessage>);
      }
      setSubmitting(false);
    }
  };

  const onSaveDraft = async () => {
    if (!submitting) {
      setSubmitting(true);
      const dataToSubmit = convertProposalPageStateToSubmission(
        data,
        pageState
      ) as Partial<IProposalDetailsMedicalPageSubmissionModel>;

      if (isEmpty(dataToSubmit.policyPersonInput[0]?.personId)) {
        dataToSubmit.policyPersonInput = [];
      }

      try {
        const result = await saveAsDraftAction({
          variables: { ...dataToSubmit },
          errorPolicy: 'all',
        });

        if (isEmpty(result.errors)) {
          // setDeactivateButtonState("success");
          toast.success(
            <ToastSuccessMessage>
              {'Proposal successfully saved.'}
            </ToastSuccessMessage>
          );

          initialize();
        } else {
          // setRegenerateButtonState(undefined);
          toast.error(
            <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
          );
        }
      } catch (err) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        setSubmitting(false);
      }
    }
    setSubmitting(false);
  };

  const onIssuePolicyAction = async () => {
    setIssueModalOpen(true);
  };

  const renderActions = () => {
    const actions: IEnhancedMenuItem[] = [
      {
        title: 'Save Draft',
        onClick: () => {
          onSaveDraft();
        },
        isSecondary: false,
        backgroundColor: '#000',
        color: '#fff',
        disabled: disabledPage,
        hidden: isClosed || data?.Status?.toLowerCase() !== 'new',
      },
      {
        title: 'Calculate & Save',
        onClick: () => {
          onSubmit();
        },
        isSecondary: false,
        backgroundColor: '#000',
        color: '#fff',
        disabled: disabledPage,
        hidden: isClosed || !isValidNumber(data?.LineId?.ExternalCode),
      },
      {
        title: 'Issue Policy',
        onClick: () => {
          onIssuePolicyAction();
        },
        isSecondary: false,
        hidden:
          isClosed ||
          !(
            data?.Status?.toLowerCase() === 'in_progress' &&
            isValidNumber(data?.LineId?.ExternalCode)
          ),
        backgroundColor: '#000',
        color: '#fff',
        disabled: disabledPage,
      },
      // {
      //   title: 'Download Policy Sample',
      //   onClick: () => {
      //     downloadDocument();
      //   },
      //   isSecondary: false,
      //   hidden:
      //     isClosed ||
      //     !(
      //       data?.Status?.toLowerCase() === 'in_progress' &&
      //       isValidNumber(data?.LineId?.ExternalCode)
      //     ),
      //   backgroundColor: '#000',
      //   color: '#fff',
      //   disabled: disabledPage,
      // },
      {
        title: 'Close Proposal',
        onClick: () => {
          setCloseProposalDrawerOpen(true);
        },
        isSecondary: false,
        backgroundColor: '#000',
        color: '#fff',
        disabled: disabledPage,
        hidden: isClosed,
      },
    ];

    return <SimpleActionBar items={actions} />;
  };

  const renderMainChildren = () => {
    if (data) {
      // const isClosed = status === "closedwon" || "closedlost";
      const isProposalWon = data.Status?.toUpperCase() === 'CLOSED_WON';
      const isProposalUserNew = data.Status?.toLowerCase() === 'new';
      const steps = isProposalUserNew
        ? ['New', 'In Progress', 'Closed Won']
        : ['New', 'In Progress', isProposalWon ? 'Closed Won' : 'Closed Lost'];

      return (
        <>
          <div style={{ marginTop: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <div style={{ width: `${gridWidthToPercentage(8)}%` }}>
                <EnhancedStepper
                  activeStep={getProposalStatus(steps, data.Status)}
                  steps={steps}
                  buttonTitle="Calculate & Save"
                  buttonOnClick={() => undefined}
                  className={classes.stepper}
                />
              </div>
              <div style={{ width: `${gridWidthToPercentage(4)}%` }}>
                {renderActions()}
              </div>
            </div>
            <div
              style={{
                marginTop: '18px',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  width: `${gridWidthToPercentage(8)}%`,
                  padding: '0px 1em 1em 0',
                }}
              >
                <PolicyDetailsWidget data={data as any} />
                <PolicyCostChargesWidget
                  pageState={pageState as any}
                  disabledForm={disabledPage}
                  status={data.Status}
                  line={data?.LineId.ExternalCode?.toString() || ''}
                  editableLine={data.BusinessUserID?.EditableLineChargesIDs?.includes(
                    data?.LineId.Id
                  )}
                  onPageStateUpdate={onPageStateUpdate as any}
                  currencySymbol={data.PolicyCurrency.Symbol}
                />
                <WidgetPaper style={{ marginTop: '1em' }}>
                  <WidgetSection
                    title="Payer & Owner"
                    hasTitleSpecificDesign={false}
                    classes={
                      {
                        // container: classes.section,
                      }
                    }
                    useSeparator={false}
                  >
                    <PolicyPayerWidget
                      pageState={pageState as any}
                      onPageStateUpdate={onPageStateUpdate as any}
                      lovs={lovs}
                      disabledForm={disabledPage}
                      data={data as any}
                    />
                    {ownerIsDifferent && (
                      <PolicyOwnerWidget
                        pageState={pageState as any}
                        onPageStateUpdate={onPageStateUpdate as any}
                        lovs={lovs}
                        disabledForm={disabledPage}
                        data={data as any}
                      />
                    )}
                  </WidgetSection>
                </WidgetPaper>

                <PolicyBeneficiaryWidget
                  pageState={pageState as any}
                  onPageStateUpdate={onPageStateUpdate as any}
                  lovs={lovs}
                  disabledForm={disabledPage}
                  maxNumber={data.PlanID.MaxNbBeneficiary}
                  data={data as any}
                />

                {data?.LineId?.ExternalCode?.toString() === '19' && (
                  <>
                    <ProposalMedicalDetailsWidget
                      pageState={pageState as any}
                      status={data.Status}
                      allowPlanCoverEdits={
                        data.BusinessUserID.AllowPlanCoverEdits
                      }
                      onPageStateUpdate={onPageStateUpdate as any}
                      lovs={lovs}
                      data={data as any}
                      disabledForm={disabledPage}
                    />

                    <ProposalMedicalPrincipalWidget
                      pageState={pageState as any}
                      onPageStateUpdate={onPageStateUpdate as any}
                      lovs={lovs}
                      data={data as any}
                      maxNumber={data.PlanID.MaxNbInsured}
                      disabledForm={disabledPage}
                    />

                    {data?.Status && data?.Status?.toLowerCase() !== 'new' && (
                      <MedicalInsuredTableWidget data={data} />
                    )}
                  </>
                )}

                {data?.LineId?.ExternalCode?.toString() === '6' && (
                  <>
                    <ProposalMedicalGroupDetailsWidget
                      pageState={pageState as any}
                      status={data.Status}
                      allowPlanCoverEdits={
                        data.BusinessUserID.AllowPlanCoverEdits
                      }
                      onPageStateUpdate={(pageState) => {
                        onPageStateUpdate(pageState);
                      }}
                      lovs={lovs}
                      data={data as any}
                      disabledForm={disabledPage}
                    />

                    {data?.Status && data?.Status?.toLowerCase() !== 'new' && (
                      <MedicalInsuredTableWidget data={data} />
                    )}
                  </>
                )}

                {closeProposalDrawerOpen && (
                  <CloseProposalDrawer
                    open={closeProposalDrawerOpen}
                    onClose={() => {
                      setCloseProposalDrawerOpen(false);
                    }}
                    onSuccess={() => {
                      initialize();
                      setCloseProposalDrawerOpen(false);
                    }}
                    id={entityId}
                  />
                )}
              </div>
              <div
                style={{
                  width: `${gridWidthToPercentage(4)}%`,
                  padding: '0px',
                }}
              >
                <PolicyProposalQuoteWidget
                  data={data as any}
                  covers={coversList as any}
                />

                {data?.Status && data?.Status?.toLowerCase() !== 'new' && (
                  <ComissionProposalWidget
                    className={classes.widgetTopMargin}
                    data={data as any}
                    onUpdateSuccess={() => {
                      initialize();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      );
    }

    return <></>;
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <StaticLayout
        name={'Proposal Details'}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
      />
      {issueModalOpen && (
        <PreviewProposalClauseModalShell
          data={data}
          onSuccess={() => {
            setIssueModalOpen(false);
            initialize();
          }}
          onClose={() => setIssueModalOpen(false)}
        />
      )}
    </>
  );
};

export default ProposalMedicalPage;
