import _, { cloneDeep, isArray, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { IAmendmentPageState, IOwnerData } from '../..';
import { ILineAmendmentProps } from '../../line-amendment';
import { fetchedPersonAddressList, fetchedPersons } from './queries';
import { isGuid } from '../../../../../utils/validationUtils';
import { validatePolicyOwnerField } from './validation';
import ProposalPolicyPersonDrawer from '../../../../../forms/proposal-policy-person-drawer/ProposalPolicyPersonDrawer';
import PersonAddressDrawer from '../../../../../forms/person-address-drawer/PersonAddressDrawer';
import NewChipsInput from '../../../../../components/enhanced-form/NewChipsInput';
import { extractPersonsAddressesFixed, extractPersonsFixed } from './utils';

interface IOwnerWidgetProps {
  generalData: ILineAmendmentProps['generalData'];
  pageState: IAmendmentPageState;
  onPageStateUpdate: (pageState: IAmendmentPageState) => void;
  isDisabled: boolean;
}

interface IOwnerLovs {
  ownerId: Record<string, string>;
  ownerAddresses: Record<string, string>;
}

const useStyles = makeStyles()(() => ({
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(2, 49%)`,
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'ce',
  },
  field: {
    width: '100%',
    marginRight: '25px !important',
    minWidth: '195px',
  },
  labelHeader: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
}));

const OwnerWidget: React.FC<IOwnerWidgetProps> = ({
  generalData,
  pageState,
  onPageStateUpdate,
  isDisabled,
}) => {
  const { id: currentAmendmentID } = useParams<{ id: string }>();
  const { classes } = useStyles();

  const [getPersonAddressListLazy] = useLazyQuery(fetchedPersonAddressList);

  const [newPersonDrawerOpen, setNewPersonDrawerOpen] =
    useState<boolean>(false);
  const [newAddressDrawerOpen, setNewAddressDrawerOpen] =
    useState<boolean>(false);
  const [currentSection, setCurrentSection] = useState<string | null>(null);

  const [lists, setLists] = useState<IOwnerLovs>({
    ownerId: {},
    ownerAddresses: {},
  });

  const values = pageState.values.policyPersons.owner;
  const errors = pageState.errors.policyPersons.owner;
  const touched = pageState.touched.policyPersons.owner;

  const [fetchedPersonsLazy] = useLazyQuery(fetchedPersons);

  const handleOpenDrawer = (section: string) => {
    setCurrentSection(section);
    setNewPersonDrawerOpen(true);
  };

  const handleOpenAddressDrawer = () => {
    setNewAddressDrawerOpen(true);
  };

  const handleOwnerSearch = async (inputValue: string) => {
    if (inputValue?.length > 3) {
      const newData = await fetchedPersonsLazy({
        variables: {
          searchKeyword: inputValue,
          selectedBusinessPartners: [generalData?.primaryBPID],
        },
      });
      return extractPersonsFixed(newData.data);
    }
    return {};
  };

  const initialize = async () => {
    const newList: IOwnerLovs = {
      ownerAddresses: {},
      ownerId: {
        [values.ownerId]: values.fullName,
      },
    };
    setLists(newList);
  };
  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOwnerAddresses = async (personId: string) => {
    const result = await getPersonAddressListLazy({
      variables: {
        selectedPersonID: personId,
      },
      errorPolicy: 'all',
    });

    return extractPersonsAddressesFixed(result.data);
  };

  const onFieldUpdate = async (
    fieldName: keyof IOwnerData,
    value: string,
    touched = false,
    updatedList: IOwnerLovs = lists
  ) => {
    const newPageState = _.cloneDeep(pageState);
    const newLists = { ...updatedList };
    if (fieldName === 'ownerId') {
      if (isEmpty(value) || isGuid(value)) {
        newPageState.values.policyPersons.owner[fieldName] = value;
        newPageState.errors.policyPersons.owner[fieldName] =
          validatePolicyOwnerField(fieldName, value);
        if (touched) {
          newPageState.touched.policyPersons.owner[fieldName] = true;
        }

        if (isGuid(value)) {
          const result2 = (await handleOwnerAddresses(
            value
          )) as unknown as Record<
            string,
            Record<string, { address: string; isPrimary: boolean }>
          >;
          const ownerAddresses = result2.ownerPersonAddress;
          const extractedAddresses: Record<string, string> = {};
          Object.keys(ownerAddresses).forEach((key) => {
            extractedAddresses[key] = ownerAddresses[key].address;
            if (ownerAddresses[key].isPrimary) {
              newPageState.values.policyPersons.owner.ownerAddress = key;
            }
          });
          newPageState.errors.policyPersons.owner.ownerAddress = '';
          newLists['ownerAddresses'] = extractedAddresses;
        }
      } else {
        newLists['ownerId'] = await handleOwnerSearch(value);
      }
    } else {
      newPageState.values.policyPersons.owner[fieldName] = value;
      newPageState.errors.policyPersons.owner[fieldName] =
        validatePolicyOwnerField(fieldName, value);
      if (touched) {
        newPageState.touched.policyPersons.owner[fieldName] = true;
      }
    }
    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onFieldSearch = async (fieldName: string, value: string) => {
    const newLists = cloneDeep(lists);

    if (fieldName === 'ownerId') {
      newLists['ownerId'] = await handleOwnerSearch(value);
    }

    setLists(newLists);
  };

  const handleDrawerSubmitSuccess = (
    personId: string,
    newPersonFullName: string
  ) => {
    const newPersonList: Record<string, string> = {};
    newPersonList[personId] = newPersonFullName;
    onFieldUpdate('ownerId', personId, false, {
      ownerAddresses: lists.ownerAddresses,
      ownerId: newPersonList,
    });
    setNewPersonDrawerOpen(false);
  };

  const handleAddressSubmitSuccess = (
    address: string,
    addressFullName: string
  ) => {
    onFieldUpdate('ownerAddress', address, false, {
      ownerId: lists.ownerId,
      ownerAddresses: { ...lists.ownerAddresses, [address]: addressFullName },
    });
    setNewAddressDrawerOpen(false);
  };

  const getDefaultPersonListIfEmpty = (
    list: Record<string, string>,
    owner: IOwnerData
  ) => {
    if (Object.keys(list || {}).length > 0 || !owner.fullName) {
      return list;
    }

    const newList: Record<string, string> = {
      [owner.ownerId]: owner.fullName,
      [owner.ownerAddress]: owner.addressDetails,
    };

    return newList;
  };

  return (
    <div className={classes.fieldRow}>
      {newPersonDrawerOpen && currentSection === 'owner' && (
        <ProposalPolicyPersonDrawer
          open={newPersonDrawerOpen}
          onClose={() => setNewPersonDrawerOpen(false)}
          section={currentSection}
          successRoute="/production/amendment/"
          proposalId={currentAmendmentID}
          lineExternalCode={generalData?.lineExternalCode}
          onSubmitSuccess={handleDrawerSubmitSuccess}
        />
      )}

      {newAddressDrawerOpen && (
        <PersonAddressDrawer
          open={newAddressDrawerOpen}
          onClose={() => setNewAddressDrawerOpen(false)}
          personId={values.ownerId}
          onSubmitSuccess={handleAddressSubmitSuccess}
        />
      )}

      <NewChipsInput
        key="ownerId"
        name="ownerId"
        title="Owner"
        placeholder="Owner"
        value={values.ownerId}
        error={touched.ownerId ? errors.ownerId : ''}
        onChange={(v) => {
          const value = isArray(v) ? v[0] : v;
          onFieldUpdate('ownerId', value);
        }}
        onSearch={async (v) => {
          const value = isArray(v) ? v[0] : v;
          onFieldSearch('ownerId', value);
        }}
        disabled={isDisabled}
        items={getDefaultPersonListIfEmpty(lists?.ownerId, values)}
        required
        material
        supportLink={isDisabled ? '' : 'Add Person'}
        onLinkClick={(e) => {
          e.preventDefault();
          handleOpenDrawer('owner');
        }}
        customStyles={{
          labelStyles: classes.labelHeader,
        }}
      />

      <NewChipsInput
        key="ownerAddress"
        name="ownerAddress"
        title="Owner Address"
        placeholder="Owner Address"
        value={values.ownerAddress}
        error={touched.ownerAddress ? errors.ownerAddress : ''}
        onChange={(v) => onFieldUpdate('ownerAddress', v as string)}
        disabled={isDisabled}
        items={getDefaultPersonListIfEmpty(lists.ownerAddresses, values)}
        required
        material
        supportLink={isDisabled ? '' : 'Add Address'}
        onLinkClick={(e) => {
          e.preventDefault();
          handleOpenAddressDrawer();
        }}
        customStyles={{
          labelStyles: classes.labelHeader,
        }}
      />
    </div>
  );
};

export default OwnerWidget;
