import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  contentFontFamilyBold,
  MAIN_ONE_THEME,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../../../constants';
import { ListingDataItemType } from '../../../../models/listing';
import { EnhancedTableFilterType } from '..';
import { IAbstractRecord } from '../../../../models';
import { IEnhancedMenuItem } from '..';
import { cloneDeep } from 'lodash';
import MultiselectFormField from '../../MultiselectFormField';
import DateRangeFormField from '../../DateRangeFormField';
import { formatDate } from '../../../../utils/formatting-utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Toolbar,
} from '@mui/material';
import FilterIcon from '../../../updated-enhanced-table/shared/FilterIcon';
import { ExpandMore } from '@mui/icons-material';
import EnhancedButton from '../../buttons/EnhancedButton';

const useStyles = makeStyles<{ expanded: boolean }>()(
  (theme, { expanded }) => ({
    actions: {
      gridGap: '1em',
      display: 'grid',
      gridAutoFlow: 'column',
    },
    action: {
      '& span': {
        margin: '0.5em auto 0',
      },
    },
    separator: {
      width: '1px',
      height: '25px',
      opacity: 0.5,
      backgroundColor: MAIN_ONE_THEME.palette.secondary3.main,
      marginLeft: '5px',
      marginRight: '5px',
      display: 'inline-block',
      verticalAlign: 'text-bottom',
      alignSelf: 'center',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
    },
    secondaryActions: {
      boxSizing: 'border-box',
      display: 'inline-flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'flex-end',
      color: MAIN_ONE_THEME.palette.primary1.main,
      margin: '0 0 0 8px',
    },
    dropdown: {
      width: 'unset',
    },
    dropdownField: {
      lineHeight: 1.5,
      textAlign: 'left',
      color: MAIN_ONE_THEME.palette.primary1.main,
      display: 'flex',
    },
    mainContainer: {
      backgroundColor: '#F9F9F9',
    },
    filterContainer: {
      backgroundColor: '#F9F9F9',
      padding: expanded ? '11px 26px 26px' : '11px 26px 11px',
    },
    filtersTitle: {
      fontFamily: 'SourceSansPro-Bold',
      fontSize: '15px',
      lineHeight: '21px',
      color: MAIN_ONE_THEME.palette.primary1.main,
      margin: '0 0 0 8px',
    },
    accordionSummary: {
      minHeight: 'unset!important',
      padding: '0',
      margin: '0',
      '& .MuiAccordionSummary-content': {
        padding: '0',
        margin: expanded ? '0 0 23px' : '0',
      },
    },
    accordionDetails: {
      padding: '0',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '16px',
    },
    filterButton: {
      marginLeft: '8px',
      boder: 0,
      backgroundColor: '#C02D33',
      color: 'white',
      '&:hover': {
        backgroundColor: '#A02428',
      },
    },
    filterGrid: {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px', // Space between fields
    },
    filterField: {
      width: '100%',
      '& .MuiFormControl-root': {
        backgroundColor: 'white !important',
        '& > div': {
          backgroundColor: 'inherit !important',
        },
      },
      '& input': {
        backgroundColor: 'inherit !important',
      },
      '& button': {
        backgroundColor: 'white !important',
      },
      '& .MuiAutocomplete-popupIndicator': {
        right: '2px',
      },
    },
    root: {
      padding: '1px 16px',
      minHeight: '50px!important',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    titleWrapper: {
      marginTop: '3px',
      flexGrow: 1,
      flexShrink: 1,
      minWidth: '30%',
      display: 'flex',
      marginLeft: '5px',
    },
    title: {
      flexGrow: 1,
      flexShrink: 1,
      fontFamily: contentFontFamilyBold,
      fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
      color: MAIN_ONE_THEME.palette.primary1.main,
      alignSelf: 'center',
    },
    rightSection: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export interface ListingTableCollapsableToolbarProps {
  data: ListingDataItemType;
  selected: string[];
  actions?: IEnhancedMenuItem[];
  cdnUrl: string;
  title?: string;
  inline?: boolean;
  filters?: Record<string, EnhancedTableFilterType>;
  dropdownActionsNames: string[];
  context?: IAbstractRecord;
  handleFilterChange: (
    name: string,
    value: string[] | Date[],
    type: string
  ) => void;
  onFilterClear(): void;
  onFilterAction(): void;
  forceClearSearchValue?: boolean;
}

const ListingTableCollapsableToolbar: React.FC<
  ListingTableCollapsableToolbarProps
> = ({
  actions,
  data,
  selected,
  title,
  dropdownActionsNames,
  filters,
  handleFilterChange,
  onFilterClear,
  onFilterAction,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [standAloneActions, setStandAloneActions] = useState<
    IEnhancedMenuItem[]
  >([]);
  const [dropdownActions, setDropdownActions] = useState<IEnhancedMenuItem[]>(
    []
  );

  const { classes } = useStyles({ expanded: expanded });

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const isValidAction = useCallback(
    (action: IEnhancedMenuItem) => {
      if (!action.displayActionPerRecord) {
        return true;
      }
      if (!data || !selected || selected.length === 0) {
        return false;
      }
      return selected.every(
        (key) =>
          data[key] &&
          data[key].AvailableActions &&
          data[key].AvailableActions.includes(action.name)
      );
    },
    [data, selected]
  );

  // const buildContext: () => typeof data = useCallback(() => {
  //   const output: typeof data = {};
  //   if (selected.length > 0) {
  //     selected.forEach((key) => {
  //       output[key] = data[key];
  //     });
  //   }
  //   return output;
  // }, [data, selected]);

  const addSelectedToAction = useCallback(
    (action: IEnhancedMenuItem): IEnhancedMenuItem => {
      // if (action.formType === ActionWidgetType.Confirmation) {
      //   return { ...action, numberOfItems: selected.length };
      // }
      return action;
    },
    []
  );

  useEffect(() => {
    if (actions) {
      let curStandAloneAct: IEnhancedMenuItem[] = [];
      const newDropdownActions: IEnhancedMenuItem[] = [];
      if (dropdownActionsNames && dropdownActionsNames.length > 0) {
        actions.forEach((act) => {
          if (!dropdownActionsNames.includes(act.name)) {
            curStandAloneAct.push(act);
          } else {
            const newAction = cloneDeep(act);
            // const isEntityAction = act.target === ActionTarget.Entity;
            newAction.disabled =
              // isEntityAction &&
              selected.length === 0 ||
              // !act.supportBulk &&
              selected.length > 1 ||
              !isValidAction(act);
            // newAction.context = isEntityAction ? buildContext() : undefined;
            newDropdownActions.push(
              // isEntityAction ?
              addSelectedToAction(newAction)
              // : newAction
            );
          }
        });
      } else {
        curStandAloneAct = actions;
      }
      setStandAloneActions(curStandAloneAct);
      setDropdownActions(newDropdownActions);
    } else {
      setStandAloneActions([]);
      setDropdownActions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  const renderActions = () => {
    const output: JSX.Element[] = [];
    if (standAloneActions && standAloneActions.length > 0) {
      // const outPutContext = buildContext();
      standAloneActions.forEach((action, index) => {
        if (index !== 0) {
          output.push(
            <span
              key={`${action.name}-separator`}
              className={classes.separator}
            />
          );
        }
        // const isEntityAction = action.target === ActionTarget.Entity;
        output.push(
          <EnhancedButton
            key={action.name}
            disabled={
              selected.length === 0 ||
              (!action.supportBulk && selected.length > 1) ||
              !isValidAction(action)
            }
            onClick={() => action.onClick(selected)}
          >
            {action.title}
          </EnhancedButton>
        );
      });
    }
    return output;
  };

  const renderDropdownActions = () => {
    if (dropdownActions && dropdownActions.length > 0) {
      return (
        <div className={classes.secondaryActions}>
          {/* <ActionMenu
            buttonElem={<ArrowButton open={openDropdown} />}
            onOpenChange={(visible) => setOpenDropdown(visible)}
            onMenuItemClick={() => setOpenDropdown(false)}
            open={openDropdown}
            actions={dropdownActions}
            menuMinWidth={91}
            classes={{
              menu: classes.dropdown,
              menuItem: classes.dropdownField,
            }}
          /> */}
        </div>
      );
    }
    return <></>;
  };

  // Function to render input based on filter type
  const renderFilterInput = (filter: EnhancedTableFilterType) => {
    const { name, type, placeholder } = filter;
    switch (type) {
      case 'daterange': {
        const value =
          filter.value && filter.value.length > 0
            ? filter.value.map((date) =>
                formatDate(date, SEND_TO_BACKEND_DATE_FORMAT)
              )
            : [];
        return (
          <DateRangeFormField
            name={name}
            title={''}
            inputOnly
            hideError
            value={value}
            placeholder={placeholder}
            onDateRangeChange={(dates) => handleFilterChange(name, dates, type)}
          />
        );
      }
      case 'select': {
        return (
          <MultiselectFormField
            name={name}
            inputOnly
            hideError
            selectOptions={filter.options}
            placeholder={placeholder}
            values={filter.selectedValues}
            onChange={(value) => handleFilterChange(name, value, type)}
            title={''}
          />
        );
      }
      default:
        return null;
    }
  };

  const FiltersComponent = (
    <Accordion
      elevation={0}
      className={classes.filterContainer}
      onChange={() => handleChange()}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordionSummary}
      >
        <div>
          <FilterIcon />
          <span className={classes.filtersTitle}>{'Filters'}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <div className={classes.filterGrid}>
          {Object.keys(filters).map((filter) => (
            <div key={filter} className={classes.filterField}>
              {renderFilterInput(filters[filter])}
            </div>
          ))}
        </div>
        <div className={classes.buttonContainer}>
          <EnhancedButton onClick={onFilterClear}>Reset</EnhancedButton>
          <EnhancedButton
            className={classes.filterButton}
            onClick={onFilterAction}
          >
            Filter
          </EnhancedButton>
        </div>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <>
      <Toolbar className={classes.root}>
        <div className={classes.titleWrapper}>
          {title && <div className={classes.title}>{title}</div>}
        </div>
        <div className={classes.rightSection} style={{ display: 'flex' }}>
          <div className={classes.actions}>{renderActions()}</div>
          {renderDropdownActions()}
        </div>
      </Toolbar>
      <div className={classes.mainContainer}>{FiltersComponent}</div>
    </>
  );
};

export default ListingTableCollapsableToolbar;
