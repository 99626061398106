import React, { useState } from 'react';
import GenericDrawer from '../../../../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../../../../DynamicForm/DynamicForm';
import { EnhancedButtonStatus } from '../../../../../components/EnhancedButton';
import { getUserToken } from '../../../../../utils/userUtils';
import { resolveGraphqlBaseUrl } from '../../../../../utils/tenant-utils';
import { toast } from 'react-toastify';
import ToastSuccessMessage from '../../../../../components/ToastSuccessMessage';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import { getError } from '../../../../../utils/graph-utils';
import { DEFAULT_ERROR_TEXT } from '../../../../../constants';
import { addFacDocumentDrawerInputs } from '../content';

interface IAddFacDocumentDrawerProps {
  currentFacId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export interface IFacDocumentDrawerValues {
  documentName: string;
  document: File;
}

const AddFacDocumentDrawer: React.FC<IAddFacDocumentDrawerProps> = ({
  currentFacId,
  isOpen,
  onSuccess,
  onClose,
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const submitForm = async (values: IFacDocumentDrawerValues) => {
    setIsDisabled(true);
    setSubmitButtonState('loading');
    try {
      const formData = new FormData();

      formData.append('formFile', values.document, values.document.name);
      formData.append(
        'operations',
        JSON.stringify({
          query: `
                  mutation createFacultativeDocuments(
                    $UploadedDocuments: Upload!
                    $DocumentName: String!
                    $CurrentFacID: String!
                  ) {
                    production {
                      actions {
                        createFacultativeDocuments(
                          UploadedDocuments: $UploadedDocuments
                          DocumentName: $DocumentName
                          CurrentFacID: $CurrentFacID
                        ) {
                          id
                        }
                      }
                    }
                  }`,
          variables: {
            UploadedDocuments: null as string,
            DocumentName: values.documentName,
            CurrentFacID: currentFacId,
          },
        })
      );
      //   map the uploaded file to the variables.UploadedDocuments
      formData.append(
        'map',
        JSON.stringify({ formFile: ['variables.UploadedDocuments'] })
      );

      const options = {
        method: 'POST',
        headers: {
          authorization: `Bearer ${getUserToken()}` || null,
          'GraphQL-preflight': '1',
        },
        body: formData,
      };

      try {
        const response = await fetch(
          `${resolveGraphqlBaseUrl()}/graphql`,
          options
        );

        const result = await response?.json();

        if (!result.errors) {
          toast.success(
            <ToastSuccessMessage>
              {'Document successfully uploaded'}
            </ToastSuccessMessage>
          );

          setTimeout(() => {
            setSubmitButtonState('success');
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(
            <ToastErrorMessage>{getError(response)}</ToastErrorMessage>
          );
        }
      } catch (error) {
        setSubmitButtonState(undefined);
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      }
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title={'Add Document'}
      onClose={() => onClose()}
      isOpen={isOpen}
    >
      <DynamicForm
        title="Document"
        buttonText={'Submit'}
        inputs={addFacDocumentDrawerInputs}
        disableForm={isDisabled}
        isSubmitButtonDisabled={isDisabled}
        submitButtonState={submitButtonState}
        onSubmit={(v: IFacDocumentDrawerValues) => submitForm(v)}
      />
    </GenericDrawer>
  );
};

export default AddFacDocumentDrawer;
