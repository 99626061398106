import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../../components/enhanced-table';

export const headers: Record<string, EnhancedTableHeader> = {
  billType: {
    name: 'billType',
    title: 'Bill Type',
    type: EnhancedTableHeaderType.Text,
  },
  totalPremium: {
    name: 'totalPremium',
    title: 'Total Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  amountDue: {
    name: 'amountDue',
    title: 'Total Amount Due',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  amountReceived: {
    name: 'amountReceived',
    title: 'Total Amount Received',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  commission: {
    name: 'commission',
    title: 'Total Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
    tooltipSettings: {
      headers: {
        BusinessPartner: {
          name: 'BusinessPartner',
          title: 'Business Partner',
          type: EnhancedTableHeaderType.Text,
          tooltipSettings: null,
        },
        Share: {
          name: 'Share',
          title: 'Share',
          type: EnhancedTableHeaderType.RoundedPercentage,
          tooltipSettings: null,
        },
        Commission: {
          name: 'Commission',
          title: 'Commission',
          type: EnhancedTableHeaderType.RoundedCurrency,
          tooltipSettings: null,
        },
      },
    },
  },
  taxOnCommission: {
    name: 'taxOnCommission',
    title: 'Total Tax on Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  amountPaid: {
    name: 'amountPaid',
    title: 'Total Amount Paid',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  linkedAmendment: {
    name: 'linkedAmendment',
    title: 'Linked Amendment',
    type: EnhancedTableHeaderType.Link,
    openUrlInNewTab: false,
    urlTemplate: '/production/amendment/{linkedAmendmentId}',
  },
  viewBreakdown: {
    name: 'viewBreakdown',
    title: '',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
};

export const breakdownHeaders: Record<string, EnhancedTableHeader> = {
  BillNumber: {
    name: 'BillNumber',
    title: 'Bill #',
    type: EnhancedTableHeaderType.Text,
  },
  DueDate: {
    name: 'DueDate',
    title: 'Due Date',
    type: EnhancedTableHeaderType.Date,
  },
  TotalPremium: {
    name: 'TotalPremium',
    title: 'Total Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  AmountDue: {
    name: 'AmountDue',
    title: 'Amount Due',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  AmountReceived: {
    name: 'AmountReceived',
    title: 'Amount Received',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  Commission: {
    name: 'Commission',
    title: 'Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  TaxOnCommission: {
    name: 'TaxOnCommission',
    title: 'Tax On Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  AmountPaid: {
    name: 'AmountPaid',
    title: 'Amount Paid',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  TotalBalance: {
    name: 'TotalBalance',
    title: 'Total Balance',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
};
