import { gql } from '@apollo/client';

export function getPolicyPersonEnums() {
  return gql`
    query getPolicyPersonEnums {
      SalesforceManagement_PersonTypes: __type(
        name: "SalesforceManagement_PersonTypes"
      ) {
        name
        enumValues {
          name
          __typename
        }
        __typename
      }
      Insurance_PhoneType: __type(name: "Insurance_PhoneType") {
        name
        enumValues {
          name
        }
      }
      Insurance_Genders: __type(name: "Insurance_Genders") {
        name
        enumValues {
          name
        }
      }
      Core_AddressTypes: __type(name: "Core_AddressTypes") {
        name
        enumValues {
          name
        }
      }
      Insurance {
        lookups {
          titles {
            Id
            Title
          }
          nationalities {
            Id
            Title
          }
          occupationClasses {
            Id
            Title
          }
          countries {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function getPersonInfo() {
  return gql`
    query getPersonInfo($id: String!) {
      SalesforceManagement {
        entities {
          person(id: $id) {
            id
            views {
              SalesforceManagement_all {
                properties {
                  PersonType
                  CompanyName
                  Title {
                    Code
                    Title
                  }
                  FirstName
                  MiddleName
                  LastName
                  DateOfBirth
                  Email
                  PhoneDetails {
                    PhoneType
                    PhoneNumber
                  }
                  NationalityOne {
                    Id
                    Code
                    Title
                  }
                  IsTaxable
                  OccupationClass {
                    Id
                    Code
                    Title
                  }
                  Profession
                  Employer
                  DateOfEmployment
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function updatePolicyPerson() {
  return gql`
    mutation updatePolicyPerson(
      $entityId: String!
      $planDetailInputs: PlanConfigManagement_PlanDetailInputs!
    ) {
      planConfigManagement {
        entities {
          plan {
            planConfigManagement {
              updatePlanDetails(
                entityId: $entityId
                planDetailInputs: $planDetailInputs
              ) {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                      NameArabic
                      NameFrench
                      Abbreviation
                      EffectiveFrom
                      EffectiveTo
                      PlanStatus
                      SublineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              Name
                              ExternalCode
                            }
                          }
                        }
                      }
                      LineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              Name
                              ExternalCode
                            }
                          }
                        }
                      }
                      createdOn
                      createdBy
                      modifiedOn
                      modifiedBy
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getZonesByCountry() {
  return gql`
    query getZonesByCountry($countryID: String!) {
      Insurance {
        lookups {
          zones(Insurance_Countries: $countryID) {
            Id
            Title
          }
        }
      }
    }
  `;
}
