import { gql } from '@apollo/client';

export const UPLOAD_CERTIFICATE_MUTATION = gql`
  mutation UploadCertificates(
    $entityId: String!
    $certificateInput: [Production_FleetCertificateInputs!]!
  ) {
    production {
      entities {
        policy {
          production {
            uploadFleetCertificateInputs(
              entityId: $entityId
              certificateInput: $certificateInput
            ) {
              Items {
                agencyRepairYear
                allRiskMinPremium
                allRiskRate
                chassis
                clauseText
                depreciationYears
                effectiveDate
                engine
                engineSize
                horsePower
                LineID
                planExternalCode
                planID
                planName
                plateNumber
                rowNumber
                seatingCapacity
                sublineID
                vignette
                vignetteCode
                weight
                yearOfMake
                bodyType {
                  Code
                  Id
                  Title
                }
                carValue
                category {
                  Code
                  createdBy
                  createdOn
                  Id
                  modifiedBy
                  modifiedOn
                  Status
                  Title
                }
                certificatePolicyBusinessPartner {
                  businessPartnerID
                  commissionPercentage
                  commissionToBasicRate
                  commissionToPolicyRate
                  discount
                  discountBasic
                  GracePeriod
                  isPrimary
                  planCommissionAmount
                  planCommissionAmountBasic
                  planCommissionRate
                  planCommissionType
                  planCommissionValue
                  planCommissionValueBasic
                  policyCommissionAmount
                  policyCommissionAmountBasic
                  taxOnCommissionAmount
                  taxOnCommissionBasic
                  applicableOn {
                    Code
                    Id
                    Status
                    Title
                  }
                  valueCurrency {
                    Code
                    Id
                    Status
                    Symbol
                    Title
                  }
                }
                certificatePolicyPlanCovers {
                  clauseEditable
                  clauseID
                  CoverEffectiveFrom
                  CoverEffectiveTo
                  coverPremiumArabic
                  coverPremiumBasic
                  coverPremiumPercentage
                  coverPremiumSecondary
                  coverPremiumType
                  coverPremiumValue
                  coverSumInsured
                  coverSumInsuredArabic
                  coverSumInsuredBasic
                  coverSumInsuredSecondary
                  excessOnClaimAmount
                  excessOnClaimDays
                  excessOnClaimPercentage
                  excessOnClaimType
                  isEditable
                  isMain
                  isMandatory
                  planCoverID
                  sumInsuredIsAdditive
                }
                certificatePricingOption {
                  basicPremium
                  basicPremiumBasic
                  basicToPolicyRate
                  chargesAmount
                  chargesBasic
                  chargesPercentage
                  chargesSecondary
                  commissionToBasicRate
                  commissionToPolicyRate
                  fixedStamp
                  fixedStampAmount
                  fixedStampBasic
                  fixedStampSecondary
                  fixedStampToBasicRate
                  fixedStampToPolicyRate
                  grossPremium
                  grossPremiumBasic
                  municipalityTax
                  municipalityTaxAmount
                  municipalityTaxBasic
                  municipalityTaxSecondary
                  netPremium
                  netPremiumBasic
                  planToBasicRate
                  planToPolicyRate
                  policyCost
                  policyCostAmount
                  policyCostBasic
                  policyCostSecondary
                  proportionalStamp
                  proportionalStampAmount
                  proportionalStampBasic
                  proportionalStampSecondary
                  reinsuranceTax
                  reinsuranceTaxAmount
                  status
                  sumInsured
                  sumInsuredArabic
                  sumInsuredArabicLetters
                  sumInsuredBasic
                  sumInsuredLetters
                  taxOnCommission
                  taxOnCommissionAmount
                  taxOnCommissionBasic
                  totalCommission
                  totalCommissionBasic
                  totalPremium
                  totalPremiumArabic
                  totalPremiumArabicLetters
                  totalPremiumBasic
                  totalPremiumLetters
                  totalPremiumSecondary
                  tpaFeesAmount
                  tpaFeesApplicableOn
                  tpaFeesPercentage
                  tpaFeesTotalAmount
                  tpaFeesTotalBasic
                  tpaFeesType
                  fixedStampCurrency {
                    Code
                    Title
                    Symbol
                    Id
                  }
                  pricingOptionCurrency {
                    Id
                    Title
                    Symbol
                  }
                }
                color {
                  Id
                  Title
                  Code
                }
                engineType {
                  Code
                  Id
                  Title
                }
                make {
                  Code
                  Id
                  Title
                }
                model {
                  Code
                  Id
                  Title
                }
                plateCode {
                  Code
                  Id
                  Title
                }
                usageType {
                  Code
                  Id
                  Title
                }
              }
            }
          }
        }
      }
    }
  }
`;
