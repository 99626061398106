import React from 'react';
import WidgetPaper from '../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../components/common/WidgetSection';
import { cloneDeep } from 'lodash';
import SelectFormField from '../../../../components/form-fields/SelectFormField';
import { IAmendmentDetails, IAmendmentPageState } from '..';
import EnhancedDatePicker from '../../../../components/enhanced-form/EnhancedDatePicker';
import { makeStyles } from 'tss-react/mui';
import { ILineAmendmentProps } from '../line-amendment';
import { IAbstractRecord } from '../../../../models';

interface IAmendmentDetailsProps {
  generalData: ILineAmendmentProps['generalData'];
  lovs: IAbstractRecord;
  pageState: IAmendmentPageState;
  onChange: (pageState: IAmendmentPageState) => void;
}

const useStyles = makeStyles()(() => ({
  inputProps: {
    fontSize: '14px !important',
    width: '100%',
    backgroundColor: 'unset',
    '& .MuiInputBase-input': {
      width: '100%',
      backgroundColor: 'unset',
      color: '#5A5A5A',
      margin: '0 auto',
      fontFamily: 'SourceSansPro-Regular !important',
      fontSize: '14px !important',
    },
    '& .MuiFilledInput-root': {
      '& .MuiInputAdornment-root': {
        marginLeft: '-40px',
        fontFamily: 'SourceSansPro-Regular !important',
        fontSize: '14px !important',
      },
    },
    '& .MuiButtonBase-root': {
      marginRight: '1px',
    },
  },
  boldLabel: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
    margin: '0px !important',
    padding: '0px !important',
  },
}));

const AmendmentDetails: React.FC<IAmendmentDetailsProps> = ({
  generalData,
  lovs,
  pageState,
  onChange,
}) => {
  const { classes } = useStyles();

  const values = pageState.values.amendmentDetails;
  const touched = pageState.touched.amendmentDetails;
  const errors = pageState.errors.amendmentDetails;

  const handleChange = (fieldName: keyof IAmendmentDetails, value: any) => {
    const newState = cloneDeep(pageState);
    newState.values.amendmentDetails[fieldName] = value;
    onChange(newState);
  };

  return (
    <WidgetPaper>
      <WidgetSection title="Amendment Details" isFieldsGrid>
        <SelectFormField
          key="plan"
          name="plan"
          title="Plan"
          placeholder="Plan"
          value={values.plan}
          error={touched.color ? errors.color : ''}
          onChange={(v) => handleChange('plan', v)}
          selectOptions={lovs.plans}
          disabled
          material
        />
        <EnhancedDatePicker
          name="amendmentEffectiveDate"
          title="Amendment Effective Date"
          value={values.amendmentEffectiveDate}
          error={
            touched.amendmentEffectiveDate ? errors.amendmentEffectiveDate : ''
          }
          onDateChange={(v) => handleChange('amendmentEffectiveDate', v)}
          customStyles={{
            labelStyles: classes.boldLabel,
            inputStyles: classes.inputProps,
          }}
          material
        />
        <EnhancedDatePicker
          name="amendmentExpiryDate"
          title="Amendment Expiry Date"
          value={values.amendmentExpiryDate}
          error={touched.amendmentExpiryDate ? errors.amendmentExpiryDate : ''}
          onDateChange={(v) => handleChange('amendmentExpiryDate', v)}
          customStyles={{
            labelStyles: classes.boldLabel,
            inputStyles: classes.inputProps,
          }}
          material
        />
        <SelectFormField
          key="policyCurrency"
          name="policyCurrency"
          title="Policy Currency"
          placeholder="Policy Currency"
          value={generalData?.currency?.Code}
          error={touched.color ? errors.color : ''}
          onChange={(v) => handleChange('policyCurrency', v)}
          selectOptions={{
            [generalData?.currency?.Code]: generalData?.currency?.Title,
          }}
          disabled
          material
        />
      </WidgetSection>
    </WidgetPaper>
  );
};

export default AmendmentDetails;
