import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { IProductionDetailsSummary } from '.';
import { EnhancedDisplayType } from '../../../../components/enhanced-form/EnhancedDisplay';
import { IEntityInfoView } from '../../../../components/widgets/entity-info/EntityInfoFields';
import EntityInfoWidget from '../../../../components/widgets/entity-info/EntityInfoWidget';
import { capitalizeFirstLetterLowerOthers } from '../../../../utils/formatting-utils';
import { isEmpty } from '../../../../utils/validationUtils';

export const useStyles = makeStyles()(() => ({
  paperClass: {
    boxShadow: 'none !important',
  },
  updateBtn: {
    margin: '15px auto 0',
  },
}));

interface IProductionPolicyEntityInfoProps {
  data: IProductionDetailsSummary;
  isGroup?: boolean;
}

const ProductionPolicyEntityInfo: React.FC<
  IProductionPolicyEntityInfoProps
> = ({ data, isGroup = false }) => {
  const newEntityView: IEntityInfoView = {
    iconUrl: '',
    name: 'Company',
    socialMedia: {
      facebook: '',
      twitter: '',
      linkedIn: '',
    },
    title: data.PolicyName,
    sections: [
      {
        name: 'BasicInformation',
        title: '',
        properties: {
          PolicyNumber: {
            multiline: false,
            value: data.PolicyNumber,
            name: 'PolicyNumber',
            title: 'Policy Number',
          },
          ParentPolicyNumber: {
            multiline: false,
            value: data.ParentPolicyNumber,
            name: 'ParentPolicyNumber',
            title: 'Parent Policy',
            type: EnhancedDisplayType.RelativeLink,
            url: `/production/group-policies/${data.ParentPolicyId}`,
            hidden: isEmpty(data.ParentPolicyNumber),
          },
          CertificateNumber: {
            multiline: false,
            value: data.CertificateNumber,
            name: 'CertificateNumber',
            title: 'Certificate Number',
            hidden: isEmpty(data.CertificateNumber),
          },
          Barcode: {
            multiline: false,
            value: data.Barcode,
            name: 'Barcode',
            title: 'Bar Code',
            hidden: data.LineId.ExternalCode?.toString() !== '43',
          },
          Line: {
            multiline: false,
            value: data.LineId.ExternalCode + ' - ' + data.LineId.Name,
            name: 'Line',
            title: 'Line',
          },
          Subline: {
            multiline: false,
            value: data.SubLineId.ExternalCode + ' - ' + data.SubLineId.Name,
            name: 'Subline',
            title: 'Subline',
          },
          Plan: {
            multiline: false,
            value: data?.PlanID?.ExternalCode + ' - ' + data?.PlanID?.Name,
            name: 'Plan',
            title: 'Plan',
          },
          RelatedCompany: {
            multiline: false,
            value: data?.PrimaryBPID?.RelatedCompany,
            name: 'RelatedCompany',
            title: 'Related Company',
          },
          GroupPolicy: {
            multiline: false,
            value: 'Yes',
            name: 'GroupPolicy',
            title: 'Group Policy',
            hidden: !isGroup,
          },
        },
      },
      {
        name: 'Section2',
        title: '',
        hidden: ![51, 50, 4, 6, 19].includes(Number(data.LineId.ExternalCode)),
        properties: {
          AcalCategory: {
            multiline: false,
            value: data?.PlanID?.AcalCategory?.Title,
            name: 'AcalCategory',
            title: 'Acal Category',
            hidden: isEmpty(data?.PlanID?.AcalCategory?.Code),
          },
          Scratching: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(data?.PlanID?.Scratching),
            name: 'Scratching',
            title: 'Scratching',
            hidden: isEmpty(data?.PlanID?.Scratching),
          },
          SOSService: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(
              data?.PlanID?.SOSService || '',
              '_'
            ),
            name: 'SOSService',
            title: 'SOS Service',
            hidden: isEmpty(data?.PlanID?.SOSService),
          },
          ReplacementCarApplied: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(
              data?.PlanID?.ReplacementCarApplied || ''
            ),
            name: 'ReplacementCarApplied',
            title: 'Replacement Car Applied',
            hidden: isEmpty(data?.PlanID?.ReplacementCarApplied),
          },
          ReplacementCarValue: {
            multiline: false,
            value: `${
              data?.PricingOption.PricingOptionCurrencySymbol
            } ${data?.PlanID?.ReplacementCarValue?.toString()}`,
            name: 'ReplacementCarValue',
            title: 'Replacement Car Value',
            hidden: isEmpty(data?.PlanID?.ReplacementCarValue),
          },
          ReplacementCarDays: {
            multiline: false,
            value: `${data?.PlanID?.ReplacementCarDays?.toString()} days`,
            name: 'ReplacementCarDays',
            title: 'Replacement Car Days',
            hidden: isEmpty(data?.PlanID?.ReplacementCarDays),
          },
          AgencyYear: {
            multiline: false,
            value: data.PolicyMotor.AgencyRepairYear?.toString(),
            name: 'AgencyYear',
            title: 'Agency Year',
            hidden: isEmpty(data.PolicyMotor.AgencyRepairYear?.toString()),
          },
          GRLimitDays: {
            multiline: false,
            value: data?.PlanID?.GRLimitDays?.toString() || '',
            name: 'GRLimitDays',
            title: 'GR Limit Days',
            hidden: ![6, 19].includes(Number(data.LineId.ExternalCode)),
          },
          // NoDepreciationYear: {
          //   multiline: false,
          //   value: data?.PlanID?.NoDepreciationYear,
          //   name: 'NoDepreciationYear',
          //   title: 'Depreciation Year',
          //   hidden: isEmpty(data?.PlanID?.NoDepreciationYear)
          // },
        },
      },
      {
        name: 'Section3',
        title: '',
        properties: {
          BusinessUser: {
            multiline: false,
            value:
              data.BusinessUserID.Code + ' - ' + data.BusinessUserID.FullName,
            name: 'BusinessUser',
            title: 'Business User',
          },
          MainBP: {
            multiline: false,
            value: data.PrimaryBPID.Code + ' - ' + data.PrimaryBPID.FullName,
            name: 'MainBP',
            title: 'Main Business Partner',
          },
        },
      },
      {
        name: 'Section4',
        title: '',
        properties: {
          CreatedBy: {
            multiline: false,
            value: data.createdBy,
            name: 'CreatedBy',
            title: 'Created By',
          },
          CreatedOn: {
            multiline: false,
            value: data.createdOn,
            name: 'CreatedOn',
            title: 'Created On',
            type: EnhancedDisplayType.DateTime,
          },
          ModifiedBy: {
            multiline: false,
            value: data.lastModifiedBy,
            name: 'ModifiedBy',
            title: 'Modified By',
          },
          ModifiedOn: {
            multiline: false,
            value: data.modifiedOn,
            name: 'ModifiedOn',
            title: 'Modified On',
            type: EnhancedDisplayType.DateTime,
          },
        },
      },
    ],
  };

  return (
    <EntityInfoWidget
      background="rgb(193, 0, 0)"
      classification={{
        classifications: {
          Transferred: {
            text: 'Transferred',
            value: 'Transferred',
            color: 'rgb(102, 188, 113)',
          },
        },
        value: data.TransferredToAccounting ? 'Transferred' : '',
      }}
      progress={100}
      supportsClassification={true}
      supportsProgressBar={true}
      // title={businessUserDetailsInfo.name}
      // title={"Proposal"}
      title={data.PolicyName}
      // view={entityView}
      view={newEntityView}
    ></EntityInfoWidget>
  );
};

export default ProductionPolicyEntityInfo;
