import React, { useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import Loader from '../../components/Loader';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import { useMutation } from '@apollo/client';
import { getInputs } from './content';
import { IDescriptionOfRiskDrawerProps } from '.';
import { updateDescriptionOfRisk } from './queries';

const DescriptionOfRiskDrawer: React.FC<IDescriptionOfRiskDrawerProps> = ({
  open,
  onClose,
  onSuccess,
  data,
  lovs,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [saveDescriptionOfRisk] = useMutation(updateDescriptionOfRisk());
  const inputForm = useMemo(
    () => getInputs(lovs, data),
    [lovs, data, data?.LineId?.ExternalCode]
  );

  const submitForm = async (formValues: Record<string, any>) => {
    try {
      setFormDisabled(true);
      setSubmitButtonState('loading');
      const normalizedValues = normaliseDynamicValues(
        getInputs(lovs, undefined),
        formValues
      );
      const { errors } = await saveDescriptionOfRisk({
        variables: { ...normalizedValues },
      });
      if (errors) {
        throw new Error('Submission failed');
      }
      toast.success(
        <ToastSuccessMessage>
          Description of Risk updated successfully!
        </ToastSuccessMessage>
      );
      setSubmitButtonState('success');
      onSuccess();
      onClose();
    } catch (err) {
      toast.error(<ToastErrorMessage>Something went wrong</ToastErrorMessage>);
      setSubmitButtonState(undefined);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title="Modify Description of Risk"
      isOpen={!!open}
      onClose={onClose}
    >
      {!data ? (
        <Loader />
      ) : (
        <DynamicForm
          inputs={inputForm}
          onSubmit={submitForm}
          buttonText="Submit"
          submitButtonState={submitButtonState}
          disableForm={formDisabled}
          title="Risk Information"
        />
      )}
    </GenericDrawer>
  );
};

export default DescriptionOfRiskDrawer;
