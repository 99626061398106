import { IAbstractRecord } from '../../../../../../models';
import { formatEnum } from '../../../../../../utils/formatting-utils';
import { isEmpty } from '../../../../../../utils/validationUtils';
import {
  IPaymentDetailsResponse,
  ITransactionTableData,
  IVoucherPopupTableData,
} from './types';

export const mapToPaymentDetails = (data: IPaymentDetailsResponse[]) => {
  if (isEmpty(data)) {
    return null;
  }

  // Destructure the first element to get shared payment details
  const singlePaymentRecord = data[0];

  // Using reduce to aggregate payments and transactions
  const { payments, transactions } = data.reduce(
    (acc, item) => {
      const voucherId = item.paymentDetails_BillID.id;

      // Add payment details if not already present
      if (!acc.payments[voucherId]) {
        acc.payments[voucherId] = {
          policyId: item.policyDetails_PolicyNumber,
          policyNum: item.policyDetails_PolicyNumber,
          billId: item.paymentDetails_BillID.id,
          billNum: item.billDetails_BillNumber,
          dueDate: item.billDetails_DueDate,
          amountDue: item.billDetails_AmountDue,
          amountOutstanding: item.paymentDetails_AmountOutstanding,
          amountOutstandingCurrency:
            item.paymentDetails_AmountOutstandingCurrency,
          amount: item.paymentDetails_AmountReceived,
          Currency:
            item.paymentDetails_BillID.views.Accounting_all.properties.Currency
              .Symbol,
        };
      }

      // Collect unique transactions based on transactionId
      if (!acc.transactionIds.has(item.transactionDetails_Id)) {
        acc.transactions.push({
          transactionId: item.transactionDetails_Id,
          accountId:
            item.transactionDetails_RelatedAccount?.views?.Accounting_all
              ?.properties?.AccountID,
          accountName:
            item.transactionDetails_RelatedAccount?.views?.Accounting_all
              ?.properties?.AccountName,
          accountNameDisabled: true,
          description: item.transactionDetails_Description,
          descriptionDisabled: true,
          accountCredit: item.transactionDetails_AccountCredit,
          accountDebit: item.transactionDetails_AccountDebit,
          systemCredit: item.transactionDetails_SystemCredit,
          systemDebit: item.transactionDetails_SystemDebit,
          systemSecondaryDebit: item.transactionDetails_SystemSecondaryDebit,
          systemSecondaryCredit: item.transactionDetails_SystemSecondaryCredit,
          transactionCurrency:
            item.transactionDetails_RelatedAccount?.views?.Accounting_all
              ?.properties?.AccountCurrency?.Code,
        });

        // Add transactionId to set to avoid duplicates
        acc.transactionIds.add(item.transactionDetails_Id);
      }

      return acc;
    },
    {
      payments: {} as Record<string, IVoucherPopupTableData>,
      transactions: [] as ITransactionTableData[],
      transactionIds: new Set<string>(),
    }
  );

  // Construct final result using the initial record's shared details
  return {
    paymentId: singlePaymentRecord.accounting_Payments_Id,
    dateOfPayment: singlePaymentRecord.accounting_Payments_PaymentDate,
    currency: singlePaymentRecord.accounting_Payments_PaymentCurrency,
    paymentMethod: singlePaymentRecord.accounting_Payments_PaymentMethod,
    referenceNum: singlePaymentRecord.accounting_Payments_ReferenceNo,
    totalAmount: singlePaymentRecord.accounting_Payments_TotalAmountReceived,
    totalAmountUnAllocated: 0,
    totalCredit: singlePaymentRecord.transaction_TotalSystemCredit,
    totalDebit: singlePaymentRecord.transaction_TotalSystemDebit,
    incomingDocuments:
      singlePaymentRecord.accounting_Payments_PaymentDocuments ?? [],
    note: singlePaymentRecord.accounting_Payments_Note,
    payments,
    transactions,
  };
};

export const mapNotesList = (data: IAbstractRecord) => {
  if (!data) {
    return {};
  }
  const list: IAbstractRecord = {};

  data.forEach((note: IAbstractRecord) => {
    list[note.accounting_Notes_Id] = {
      reference: note.accounting_Notes_Reference,
      amount: note.accounting_Notes_Amount,
      amountOutstanding: note.accounting_Notes_AmountOutstanding,
      status: formatEnum(note.accounting_Notes_Status, ' '),
      modifiedBy: note.accounting_Notes_ModifiedByText,
      Currency: note.billDetails_Currency?.Symbol,
    };
  });

  return list;
};
