import { IAssignedBPPlanList, IGenericProposalsTableData } from '.';
import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function mapToListingData(data: IAbstractRecord): IListingData {
  const list: IAbstractRecord = {};

  data.Production.queries.getProposalList.items.forEach(
    (obj: IGenericProposalsTableData, i: number) => {
      const subline =
        obj.production_Proposal_SublineID?.views?.PlanConfigManagement_all
          ?.properties;
      const line =
        obj.production_Proposal_LineID?.views?.PlanConfigManagement_all
          ?.properties;
      const plan =
        obj.production_Proposal_PlanID?.views?.PlanConfigManagement_all
          ?.properties;
      const insured =
        obj.production_Proposal_InsuredID?.views?.Production_all?.properties;

      list[i] = {
        id: obj.production_Proposal_Id,
        proposalNumber: obj.production_Proposal_InternalCode,
        name: obj.production_Proposal_ProposalName,
        line: `${line.ExternalCode} - ${line.Name}`,
        subline: `${subline.ExternalCode} - ${subline.Name}`,
        plan: `${plan.ExternalCode} - ${plan.Name}`,
        insured: insured?.FullName,
        status: capitalizeFirstLetter(
          obj.production_Proposal_Status?.replaceAll('_', ' ')
        ),
        statusReason: capitalizeFirstLetter(
          obj?.production_Proposal_StatusReason,
          '_'
        ),
        statusReasonTitle: capitalizeFirstLetter(
          obj?.production_Proposal_StatusReason,
          '_'
        ),
        effectiveDate: obj.production_Proposal_PolicyEffectiveDate,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: 10,
    pageNumber: data.Production.queries.getProposalList?.paging?.pageNumber,
    totalCount: data.Production.queries.getProposalList?.paging?.totalCount,
  };
}

export function staticToLovs(
  data: IAbstractRecord
): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    status: {},
    statusReason: {},
  };

  lovs.status = enumListAsRecordObject(
    data?.Production_ProposalStatuses?.enumValues
  );

  lovs.statusReason = lookupListAsRecordObject(
    data?.Production?.lookups?.proposalStatusReasons
  );
  return lovs;
}

export function dataToLovs(
  data: IAbstractRecord,
  pagination = false
): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    insured: {},
  };

  const items = pagination
    ? data?.Production?.queries?.GetAssignedPersonsWithPagination.items
    : data?.Production?.queries?.GetAssignedPersons;

  items?.forEach(
    (obj: {
      salesforceManagement_AssignedPerson_PersonID: { id: string };
      salesforceManagement_AssignedPerson_FullName: string;
      salesforceManagement_AssignedPerson_PersonCode: string;
    }) => {
      lovs.insured[obj.salesforceManagement_AssignedPerson_PersonID?.id] =
        `${obj.salesforceManagement_AssignedPerson_PersonCode} - ${obj.salesforceManagement_AssignedPerson_FullName}`;
    }
  );

  return lovs;
}

export function getBPPlanLovs(
  data: IAbstractRecord,
  filterV: IAbstractRecord
): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    line: {},
    subline: {},
    plan: {},
    businessPartner: {},
  };

  const assignedBPPlanList =
    data?.SalesforceManagement?.queries?.getAssignedBPPlanListWithoutPagination;

  assignedBPPlanList?.forEach((obj: IAssignedBPPlanList) => {
    const businessPartner =
      obj?.salesforceManagement_AssignedBPPlan_BusinessPartnerID;
    const line = obj?.salesforceManagement_AssignedBPPlan_LineID;
    const lineProperties = line.views?.PlanConfigManagement_all?.properties;
    const plan = obj?.salesforceManagement_AssignedBPPlan_PlanID;
    const planProperties = plan.views?.PlanConfigManagement_all?.properties;

    lovs.businessPartner[businessPartner?.id] =
      businessPartner?.views?.SalesforceManagement_all?.properties?.PersonID?.views?.SalesforceManagement_all?.properties?.FullName;
    lovs.line[lineProperties?.Id] =
      `${lineProperties?.ExternalCode} - ${lineProperties?.Name}`;
    lovs.subline[planProperties?.SublineID?.id] =
      `${planProperties?.SublineID?.views?.PlanConfigManagement_all?.properties?.ExternalCode} - ${planProperties?.SublineID?.views?.PlanConfigManagement_all?.properties?.Name}`;

    if (!filterV?.subline || filterV?.subline?.length === 0) {
      if (lovs.subline[planProperties?.SublineID?.id]) {
        lovs.plan[plan?.id] =
          `${planProperties?.ExternalCode} - ${planProperties?.Name}`;
      }
    } else {
      if (filterV?.subline?.includes(planProperties?.SublineID?.id)) {
        lovs.plan[plan?.id] =
          `${planProperties?.ExternalCode} - ${planProperties?.Name}`;
      }
    }
  });

  return lovs;
}
