import { isEmpty } from 'lodash';
import { IListingData } from '../../../../models/listing';
import { IAbstractRecord } from '../../../../models';
import { IProdDistResponse, IProdDistTableData } from '.';
import { formatEnum } from '../../../../utils/formatting-utils';

export function toLookupsData(data: IAbstractRecord) {
  const lovs: Record<string, Record<string, string>> = {
    line: {},
    subline: {},
  };

  return lovs;
}

export function mapToListingData(data: {
  items: IProdDistResponse[];
  paging: {
    pageSize: number;
    pageNumber: number;
    totalCount: number;
  };
}): IListingData {
  if (isEmpty(data.items)) {
    return {
      pagedItems: {},
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    };
  }

  const list: Record<string, IProdDistTableData> = {};

  data.items.forEach((item) => {
    list[item.production_Policy_Id] = {
      policyId: item.production_Policy_Id,
      Production_Policy_PolicyNumber: item.production_Policy_PolicyNumber,
      PolicyLine_Name: item.policyLine_Name,
      PolicySubLine_Name: item.policySubLine_Name,
      PolicySubLine_Category: formatEnum(item.policySubLine_Category, ' '),
      PolicyPlan_Name: item.policyPlan_Name,
      Production_Policy_PolicyCurrency:
        item.production_Policy_PolicyCurrency?.Title ?? '--',
      Production_Policy_PolicyEffectiveDate:
        item.production_Policy_PolicyEffectiveDate,
      Production_Policy_PolicyExpiryDate:
        item.production_Policy_PolicyExpiryDate,
    };
  });

  return {
    pagedItems: list,
    pageSize: data?.paging?.pageSize,
    pageNumber: data?.paging?.pageNumber,
    totalCount: data?.paging?.totalCount,
  };
}
