import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { IInsuredDetails } from './index';
import { IEntityInfoView } from '../../../../components/widgets/entity-info/EntityInfoFields';
import { EnhancedDisplayType } from '../../../../components/enhanced-form/EnhancedDisplay';
import EntityInfoWidget from '../../../../components/widgets/entity-info/EntityInfoWidget';

export const useStyles = makeStyles()(() => ({
  paperClass: {
    boxShadow: 'none !important',
  },
  updateBtn: {
    margin: '15px auto 0',
  },
}));

interface IInsuredDetailsEntityInfoProps {
  data: IInsuredDetails;
}

const InsuredDetailsEntityInfo: React.FC<IInsuredDetailsEntityInfoProps> = ({
  data,
}) => {
  const newEntityView: IEntityInfoView = {
    iconUrl: '',
    name: 'Company',
    socialMedia: {
      facebook: '',
      twitter: '',
      linkedIn: '',
    },
    title: '',
    sections: [
      {
        name: 'BasicInformation',
        title: '',
        properties: {
          Name: {
            multiline: false,
            value: data?.insuredName,
            name: 'Name',
            title: 'Name',
          },
          Code: {
            multiline: false,
            value: data.code,
            name: 'Code',
            title: 'Code',
          },
        },
      },
      {
        name: 'Section2',
        title: '',
        properties: {
          CreatedBy: {
            multiline: false,
            value: data.createdBy,
            name: 'CreatedBy',
            title: 'Created By',
          },
          CreatedOn: {
            multiline: false,
            value: data.createdOn,
            name: 'CreatedOn',
            title: 'Created On',
            type: EnhancedDisplayType.DateTime,
          },
          ModifiedBy: {
            multiline: false,
            value: data.modifiedBy,
            name: 'ModifiedBy',
            title: 'Modified By',
          },
          ModifiedOn: {
            multiline: false,
            value: data.createdOn,
            name: 'ModifiedOn',
            title: 'Modified On',
            type: EnhancedDisplayType.DateTime,
          },
        },
      },
    ],
  };

  return (
    <EntityInfoWidget
      background="rgb(193, 0, 0)"
      classification={{ classifications: {}, value: '' }}
      progress={100}
      supportsClassification={true}
      supportsProgressBar={true}
      // title={businessUserDetailsInfo.name}
      // title={"Proposal"}
      title={data.insuredName}
      // view={entityView}
      view={newEntityView}
    ></EntityInfoWidget>
  );
};

export default InsuredDetailsEntityInfo;
