import React, { useState } from 'react';
import { IProductionDetailsSummary } from '..';
import { IDynamicDiplayView } from '../../../../../components/widgets/dynamic-display';
import DynamicWidget from '../../../../../components/widgets/dynamic-display/DynamicWidget';
import { capitalizeFirstLetterLowerOthers } from '../../../../../utils/formatting-utils';
import EnhancedButton from '../../../../../components/form-fields/buttons/EnhancedButton';
import ExpatDetailsDrawer from '../../../../../forms/modify-expat-details/ExpatDetailsDrawer';

interface IProductionPolicyExpatDetailsWidgetProps {
  data: IProductionDetailsSummary;
  lovs: Record<string, Record<string, string>>;
}

const ProductionPolicyExpatDetailsWidget: React.FC<
  IProductionPolicyExpatDetailsWidgetProps
> = ({ data, lovs }) => {
  const [expatInfoDrawerOpen, setExpatInfoDrawerOpen] =
    useState<boolean>(false);
  const dynamicView: IDynamicDiplayView = {
    name: 'ExpatDetails',
    sections: [
      {
        name: 'ExpatDetails',
        title: 'Expat Details',
        properties: {
          medicalClass: {
            multiline: false,
            value: data?.ExpatDetails?.MedicalClass?.Title,
            name: 'medicalClass',
            title: 'Medical Class',
            fullLine: true,
          },
          continuity: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(
              data?.ExpatDetails?.Continuity || ''
            ),
            name: 'continuity',
            title: 'Continuity',
            fullLine: true,
          },
          continuityNumber: {
            multiline: false,
            value: data?.ExpatDetails?.ContinuityNumber,
            name: 'continuityNumber',
            title: 'Continuity Number',
            fullLine: true,
          },
          exclusion: {
            multiline: false,
            value: data?.ExpatDetails?.Exclusion,
            name: 'exclusion',
            title: 'Exclusion',
            fullLine: true,
          },
          remarks: {
            multiline: false,
            value: data?.ExpatDetails?.Remarks,
            name: 'remarks',
            title: 'Remarks',
            fullLine: true,
          },
        },
      },
    ],
  };

  return (
    <>
      <DynamicWidget
        hasFourFields={true}
        view={dynamicView}
        actions={
          data?.BusinessUserID?.AllowPolicyCorrection && (
            <div>
              <EnhancedButton
                isPrimary
                onClick={() => {
                  setExpatInfoDrawerOpen(true);
                }}
              >
                Modify
              </EnhancedButton>
            </div>
          )
        }
      />
      {expatInfoDrawerOpen && (
        <ExpatDetailsDrawer
          open={expatInfoDrawerOpen}
          onClose={() => setExpatInfoDrawerOpen(false)}
          onSuccess={() => {}}
          data={data}
          lovs={lovs}
        />
      )}
    </>
  );
};

export default ProductionPolicyExpatDetailsWidget;
