import { gql } from '@apollo/client';

export const getClausesListQuery = gql`
  query getClauseListForProposal(
    $pagination: PaginationInput!
    $selectedLineIds: [String]
  ) {
    PlanConfigManagement {
      queries {
        getClauseList(
          selectedLineIds: $selectedLineIds
          pagination: $pagination
        ) {
          paging {
            pageSize
            pageNumber
            totalCount
          }
          items {
            planConfigManagement_Clause_Id
            planConfigManagement_Clause_createdOn
            planConfigManagement_Clause_modifiedOn
            planConfigManagement_Clause_ExternalCode
            planConfigManagement_Clause_Name
            planConfigManagement_Clause_Description

            planConfigManagement_Clause_LineID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                    Status
                  }
                }
              }
            }
            planConfigManagement_Clause_Status
          }
        }
      }
    }
  }
`;

export const confirmAmendmentMutation = gql`
  mutation confirmAmendment(
    $selectedAmendmentID: String!
    $selectedPolicyVersionID: String!
    $clauseText: String!
    $selectedPolicyID: String!
  ) {
    production {
      actions {
        confirmAmendment(
          selectedAmendmentID: $selectedAmendmentID
          selectedPolicyVersionID: $selectedPolicyVersionID
          clauseText: $clauseText
          selectedPolicyID: $selectedPolicyID
        ) {
          Items {
            EntityTypeId
            EntityId
            EntityViewId
            PropertyId
            File {
              id
              fileName
            }
          }
        }
      }
    }
  }
`;
