import { IAmendmentPageState } from '.';
import { LineCategory } from '../../../utils/helper-utils';

export const getAmendmentInitialState = (
  lineCategory: LineCategory
): IAmendmentPageState => {
  const state: IAmendmentPageState = {
    values: {
      amendmentDetails: {
        plan: '',
        amendmentEffectiveDate: '',
        amendmentExpiryDate: '',
        policyCurrency: '',
      },
      costs: {
        amendmentCost: null,
        chargesPercentage: null,
        tpaFeesType: '',
        tpaFeesPercentage: null,
        tpaFeesAmount: null,
      },
    },
    errors: { amendmentDetails: {}, costs: {} },
    touched: { amendmentDetails: {}, costs: {} },
  };

  switch (lineCategory) {
    case LineCategory.Motor:
      state.values = {
        ...state.values,
        covers: [],
        policyPersons: {
          insured: [
            {
              id: '',
              order: '',
              fullName: '',
              address: '',
              addressDetails: '',
              limitOfCover: '',
            },
          ],
        },
        motorDetails: {
          nameOnLicenseEn: '',
          nameOnLicenseAr: '',
          vignetteCode: '',
          vignette: null,
          plateCode: '',
          plateNumber: null,
          usageType: '',
          bodyType: '',
          engineType: '',
          brand: '',
          model: '',
          color: '',
          yearOfMake: null,
          motor: '',
          weight: null,
          engineSize: null,
          seats: null,
          chassis: '',
          horsePower: null,
          carValue: null,
          agencyRepairYear: null,
          depreciationYears: null,
        },
      };
      state.errors = {
        ...state.errors,
        covers: [{}],
        policyPersons: {
          insured: [{}],
        },
        motorDetails: {},
      };

      state.touched = {
        ...state.touched,
        covers: [{}],
        policyPersons: {
          insured: [{}],
        },
        motorDetails: {},
      };
      break;

    case LineCategory.Expat:
      state.values = {
        ...state.values,
        covers: [],
        expatDetails: {
          medicalClass: '',
          continuity: '',
          continuityNumber: '',
          exclusion: '',
          remarks: '',
        },
        policyPersons: {
          payer: {
            payerId: '',
            fullName: '',
            payerAddress: '',
            ownerIsDifferent: false,
            addressDetails: '',
          },
          owner: {
            ownerId: '',
            fullName: '',
            ownerAddress: '',
            addressDetails: '',
          },
          insured: [
            {
              id: '',
              order: '',
              fullName: '',
              address: '',
              addressDetails: '',
              limitOfCover: '',
            },
          ],
          beneficiaries: [
            {
              id: '',
              address: '',
              percentage: null,
              fullName: '',
              addressDetails: '',
            },
          ],
        },
      };
      state.errors = {
        ...state.errors,
        covers: [{}],
        expatDetails: {},
        policyPersons: {
          payer: {},
          owner: {},
          insured: [{}],
          beneficiaries: [{}],
        },
      };

      state.touched = {
        ...state.touched,
        covers: [{}],
        expatDetails: {},
        policyPersons: {
          payer: {},
          owner: {},
          insured: [{}],
          beneficiaries: [{}],
        },
      };
      break;
    case LineCategory.Medical:
      state.values = {
        ...state.values,
        policyPersons: {
          payer: {
            payerId: '',
            fullName: '',
            payerAddress: '',
            ownerIsDifferent: false,
            addressDetails: '',
          },
          owner: {
            ownerId: '',
            fullName: '',
            ownerAddress: '',
            addressDetails: '',
          },
          insured: [
            {
              id: '',
              order: '',
              fullName: '',
              address: '',
              addressDetails: '',
              grLimitDays: null,
              cardNumber: '',
              continuity: '',
              tpaReference1: '',
              tpaReference2: '',
              exclusions: '',
              remarks: '',
              isPrincipal: null,
              relation: '',
            },
          ],
          beneficiaries: [
            {
              id: '',
              address: '',
              percentage: null,
              fullName: '',
              addressDetails: '',
            },
          ],
        },
        medicalDetails: {
          class: '',
          cnss: '',
          level: '',
          policyCover: [],
        },
      };
      state.errors = {
        ...state.errors,
        covers: [{}],
        expatDetails: {},
        policyPersons: {
          payer: {},
          owner: {},
          insured: [{}],
          beneficiaries: [{}],
        },
        medicalDetails: {},
      };

      state.touched = {
        ...state.touched,
        covers: [{}],
        expatDetails: {},
        policyPersons: {
          payer: {},
          owner: {},
          insured: [{}],
          beneficiaries: [{}],
        },
        medicalDetails: {},
      };
      break;
    case LineCategory.Travel:
      state.values = {
        ...state.values,
        policyPersons: {
          payer: {
            payerId: '',
            fullName: '',
            payerAddress: '',
            ownerIsDifferent: false,
            addressDetails: '',
          },
          owner: {
            ownerId: '',
            fullName: '',
            ownerAddress: '',
            addressDetails: '',
          },
          insured: [
            {
              id: '',
              order: '',
              fullName: '',
              address: '',
              addressDetails: '',
              grLimitDays: null,
              cardNumber: '',
              continuity: '',
              tpaReference1: '',
              tpaReference2: '',
              exclusions: '',
              remarks: '',
              isPrincipal: null,
              relation: '',
            },
          ],
          beneficiaries: [
            {
              id: '',
              address: '',
              percentage: null,
              fullName: '',
              addressDetails: '',
            },
          ],
        },
        travelDetails: {
          destination: '',
          duration: null,
          policyCover: [],
        },
      };
      state.errors = {
        ...state.errors,
        covers: [{}],
        expatDetails: {},
        policyPersons: {
          payer: {},
          owner: {},
          insured: [{}],
          beneficiaries: [{}],
        },
        travelDetails: {},
      };

      state.touched = {
        ...state.touched,
        covers: [{}],
        expatDetails: {},
        policyPersons: {
          payer: {},
          owner: {},
          insured: [{}],
          beneficiaries: [{}],
        },
        travelDetails: {},
      };
      break;
    case LineCategory.Generic:
      state.values = {
        ...state.values,
        covers: [],
        riskDetails: [
          {
            geoLocation: '',
            location: '',
            riskDescription: '',
            planCovers: [
              {
                planCoverID: '',
                premium: null,
                riskRate: null,
                sumInsured: null,
              },
            ],
          },
        ],
        descriptionOfRiskDetails: {
          descriptionOfRisk: '',
          geoLocation: '',
        },
        bankersDetails: [
          {
            branchName: '',
            isMain: '',
            isAgency: '',
            numberOfEmployees: null,
            underwritingYear: null,
            infidelity: null,
            burglary: null,
            holdUp: null,
            inSafe: null,
            inTransit: null,
            forgedChecks: null,
            counterfeitCurrencies: null,
            clause6: null,
            falsification: null,
          },
        ],
        marineDetails: {
          matter: '',
          description: '',
          vesselName: '',
          vesselAge: null,
          from: '',
          to: '',
          via: '',
          survey: '',
          cargoContract: '',
          sumPerShipment: null,
          lcNumber: '',
          lcIssueDate: '',
          bank: '',
          claimsPayableBy: '',
          loading: null,
          remarks: '',
        },
        marineHullDetails: {
          vesselName: '',
          connectionPort: '',
          territorialWaters: '',
          usage: '',
          builder: '',
          constructionYear: '',
          registeryPort: '',
          length: '',
          weight: '',
          height: '',
          engineType: '',
          engineNumber: '',
          engineYearBuilt: '',
          engineHorsePower: '',
          engineMaxDesignedSpeed: '',
          engineFuel: '',
          remarks: '',
        },
        policyPersons: {
          payer: {
            payerId: '',
            fullName: '',
            payerAddress: '',
            ownerIsDifferent: false,
            addressDetails: '',
          },
          owner: {
            ownerId: '',
            fullName: '',
            ownerAddress: '',
            addressDetails: '',
          },
          insured: [
            {
              id: '',
              order: '',
              fullName: '',
              address: '',
              addressDetails: '',
              limitOfCover: '',
            },
          ],
          beneficiaries: [
            {
              id: '',
              address: '',
              percentage: null,
              fullName: '',
              addressDetails: '',
            },
          ],
        },
      };
      state.errors = {
        ...state.errors,
        covers: [{}],
        riskDetails: [{}],
        descriptionOfRiskDetails: {},
        bankersDetails: [{}],
        marineDetails: {},
        marineHullDetails: {},
        policyPersons: {
          payer: {},
          owner: {},
          insured: [{}],
          beneficiaries: [{}],
        },
      };

      state.touched = {
        ...state.touched,
        covers: [{}],
        riskDetails: [{}],
        descriptionOfRiskDetails: {},
        bankersDetails: [{}],
        marineDetails: {},
        marineHullDetails: {},
        policyPersons: {
          payer: {},
          owner: {},
          insured: [{}],
          beneficiaries: [{}],
        },
      };
  }

  return state;
};
