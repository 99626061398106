import dayjs from 'dayjs';
import { isValidNumber, isEmpty } from './validationUtils';
import { DISPLAY_DATE_FORMAT } from '../constants';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export const EMPTY_FIELD_PLACEHOLDER = '- -';

/**
 * Transforms date string into a string with ISO format
 * @param {string} input The date string
 * @return {string} The date in ISO string format
 * If no input or the date cannot be parsed, returns null
 */
export function dateStringToISOString(input: string) {
  if (input && isValidDate(input)) {
    const date = new Date(input);
    date.setHours(date.getTimezoneOffset() / -60, 0, 0);
    return date.toISOString().split('T')[0];
  }
  return null;
}

export function isValidDate(date: string | Date): boolean {
  return dayjs(date).isValid();
}

/**
 * Transforms date object into a string with ISO format
 * @param {Date} date The date object
 * @return {string} The date in ISO string format
 * If no input returns null
 */
export function dateToISOString(date: Date) {
  if (date) {
    date.setHours(date.getTimezoneOffset() / -60, 0, 0);
    return date.toISOString().split('T')[0];
  }
  return null;
}

/**
 * Transforms a date into a local date string format
 * @param {Date | string} toFormat The date to be formatted
 * @return {string} The date in local date string format
 * If input is not a valid date returns the input as it is, and if no input returns undefined
 */
export function formatDate(
  toFormat: Date | string = '',
  dateFormat = DISPLAY_DATE_FORMAT
) {
  let date = '';
  if (toFormat instanceof Date) {
    date = getDateWithoutTime(toFormat.toISOString());
  } else {
    if (!dayjs(toFormat).isValid()) {
      return toFormat;
    }
    date = toFormat;
  }
  return dayjs(date).format(dateFormat.replace(/-/g, '/'));
}

export function formatDateYMD(date: Date | string, dateFormat = 'YYYY-MM-DD') {
  return dayjs(date).format(dateFormat);
}

/**
 * Transforms a date into a local date-time string format
 * @param {Date | string} toFormat The date to be formatted
 * @return {string} The date in local date-time string format
 * If input is not a valid date returns the input as it is, and if no input returns undefined
 */
export function formatDateTime(
  toFormat: Date | string = '',
  dateFormat = DISPLAY_DATE_FORMAT,
  timeFormat = 'HH:mm:ss'
) {
  let date: Date;
  if (toFormat instanceof Date) {
    date = toFormat;
  } else {
    const parsed = Date.parse(toFormat);
    if (isNaN(parsed)) {
      return toFormat;
    }
    date = new Date(parsed);
  }
  return dayjs(date).format(`${dateFormat.replace(/-/g, '/')} ${timeFormat}`);
}

export function convertToDatePickerFormat(value: Date) {
  return dayjs(value).format('YYYY-MM-DD');
}

/**
 * Transforms a number to a string with Intl number format
 * @param {number} value The value to be formatted
 * @return {string} The value in Intl number format
 */
export function formatNumber(value: number) {
  return new Intl.NumberFormat().format(value);
}

/**
 * Truncates a string according to the provided limit
 * @param {string} input The value to be truncated
 * @param {number} limit The max number of characters allowed
 * @return {string} The string after being truncated followed by '...'
 * If the input or limit are not provided returns N/A
 */
export function limitCharacterNumber(input: string, limit: number) {
  let output: string = input;
  if (output && limit) {
    if (output.length > limit) {
      output = `${input.substr(0, limit - 1)}...`;
    }
    return output;
  }
  return EMPTY_FIELD_PLACEHOLDER;
}

/**
 * Removes empty spaces from a string
 * @param {string} input The string
 * @return {string} The string with all empty spaces removed
 */
export function removeEmptySpaces(input: string): string {
  if (input) {
    const chunks = input.split(' ');
    let output = '';
    for (let i = 0; i < chunks.length; i += 1) {
      output += chunks[i];
    }
    return output;
  }
  return '';
}

export function normaliseUrl(url: string): string {
  if (!url) {
    return url;
  }

  const protocols = ['http', 'ip', 'ftp', 'ssh', 'ssl'];
  let isValid = false;

  for (let i = 0; i < protocols.length; i += 1) {
    if (url.startsWith(protocols[i])) {
      isValid = true;
      break;
    }
  }

  if (!isValid) {
    return `http://${url}`;
  }
  return url;
}

/**
 * Replaces all substrings between {} in the template string with their
 * matching values in the dictionary values
 * @param {string} template The string to work on
 * @param {string} primaryValue The primaryValue, the pattern {PRIMARY_VARIABLE} will be matched to this value
 * @param {Record<string, string>} values The dictionary with each pattern mapped to a value
 * @return {string} The string after being parsed
 */
export function parseStringTemplate(
  template: string,
  primaryValue: string | number,
  values: Record<string, string>
): string {
  const PRIMARY_VARIABLE = 'PRIMARY_VARIABLE';
  let output = template;
  const allPatterns = template.match(/(\{[a-zA-Z_]+\})/gi);
  if (allPatterns && allPatterns.length > 0) {
    allPatterns.forEach((pattern) => {
      const variableName = pattern.slice(1, pattern.length - 1);
      let value = values[variableName];
      if (variableName === PRIMARY_VARIABLE && !isEmpty(primaryValue)) {
        value = primaryValue.toString();
      }
      output = output.replace(pattern, value);
    });
  }
  return output;
}

export const isExternalLink = (link: string) => {
  return link.match(/((https|http):\/\/|www\.)/gi);
};

/**
 * Replaces all substrings between {} in the template string with their
 * matching values in the dictionary values
 * @param {string} template The string to work on
 * @param {string} key The key, the pattern {PRIMARY_VARIABLE} will be matched to this value
 * @param {Record<string, string>} values The dictionary with each pattern mapped to a value
 * @return {string} The string after being parsed
 */

/**
 * Returns dateTime as string in 'MMM d, h:mm a' format
 * @param {string} Date The Date to be converted
 * @return {string} The DateTime formatted string
 */

export function formatDatetimeToString(date: Date): string {
  if (!date) return 'undefined';
  const dateString = date
    .toLocaleString('en-US', {
      day: 'numeric',
      month: 'short',
    })
    .split(' ');
  const timeString = date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  return `${dateString[1]} ${dateString[0]}, ${timeString} `;
}

export function returnNumberSign(input: number): string {
  const sign = Math.sign(input);
  switch (sign) {
    case -1:
      return '-';
    case 1:
      return '+';
    default:
      return '';
  }
}

export function reduceNumberOfDecimals(
  value: string | number | undefined,
  numberOfDecimals: number
): string {
  const parts = (value || '').toString().split('.');
  if (parts[1]) {
    parts[1] = parts[1].substr(0, numberOfDecimals);
  }
  return parts.join('.');
}

export function valueCommaSeparated(
  value: string | number | undefined
): string {
  if (isEmpty(value)) {
    return '';
  }
  const parts = (value || '0').toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export const formatWithCommas = (num: string) => {
  if (isEmpty(num)) {
    return '';
  }

  try {
    const parts = num.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  } catch (error) {
    return '';
  }
};

export function removeNumberDelimeter(value: string | number): string {
  let returnValue = value.toString();
  returnValue = returnValue.toString().replace(/,/g, '');
  const numberValue = Number(returnValue);
  if (isValidNumber(numberValue) && numberValue >= 0) {
    return returnValue;
  }
  return '0';
}

export function addZeroes(num: string | number, toFixedNumber = 2): string {
  const value = Number(num);
  return value.toFixed(toFixedNumber);
}

export function addZeroesAndSeparatevalue(
  value: string | number | undefined
): string {
  if (value) {
    return valueCommaSeparated(addZeroes(value, 2));
  }
  return '0.00';
}

export function removeSpecialCharacters(input: string) {
  return input ? input.replace(/[^a-zA-Z ]/g, '') : input;
}

export function replaceAllString(
  value: string,
  search: string,
  replacement: string
): string {
  return value.replace(new RegExp(search, 'g'), replacement);
}

export const changeValueToPercentage = (value: string | number) => {
  const newVal = Number(value) * 100;
  return handlePrecision(newVal);
};

export const changeValueFromPercentage = (value: string | number) => {
  const newVal = Number(value) / 100;
  return handlePrecision(newVal);
};

export const handlePrecision = (value: number) => {
  return Number(value.toPrecision(9)) * 1;
};

export const cleanValueOfPattern = (
  value: string,
  pattern: string,
  mask: string
): string => {
  let cleanValue = value;
  let k = 0;
  for (let i = 0; i < value.length; i += 1) {
    if (pattern[i] !== mask && value[i] === pattern[i]) {
      if (!isEmpty(cleanValue[i - k])) {
        cleanValue =
          cleanValue.substring(0, i - k) +
          cleanValue.substring(i - k + 1, cleanValue.length);
        k += 1;
      }
    }
  }
  return cleanValue;
};

export const maskValueWithPattern = (
  value: string,
  pattern: string,
  mask = 'x'
): string => {
  const cleanValue = cleanValueOfPattern(value, pattern, mask);
  let returnValue = '';
  let j = 0;
  for (let i = 0; i < pattern.length; i += 1) {
    if (pattern[i] !== mask) {
      returnValue += pattern[i];
    } else if (!isEmpty(cleanValue[j])) {
      returnValue += cleanValue[j];
      j += 1;
    } else {
      returnValue += mask;
    }
  }
  return returnValue;
};

export const maskValueWithPatternIfMatch = (
  value: string,
  pattern: string,
  mask = 'x'
): string => {
  const cleanValue = cleanValueOfPattern(value, pattern, mask);
  let returnValue = '';
  let j = 0;
  for (let i = 0; i < pattern.length; i += 1) {
    if (pattern[i] !== mask) {
      returnValue += pattern[i];
    } else if (!isEmpty(cleanValue[j])) {
      returnValue += cleanValue[j];
      j += 1;
    } else {
      returnValue += mask;
    }
  }
  return returnValue.toLowerCase().includes(mask.toLowerCase())
    ? value
    : returnValue;
};

export function getDateWithoutTime(date: string | Date) {
  // checking the indexOf T to prevent inproper splitting example when we have Tue
  // the T will be at index 10 example: "2022-03-26T10:58:51"
  if (date) {
    let dateString = '';
    if (date instanceof Date) {
      dateString = dateToISOString(date);
    } else {
      dateString = date;
    }
    if (dateString && dateString.trim() && isValidDate(dateString)) {
      return dateString.includes('T') && dateString.indexOf('T') === 10
        ? dateString.split('T')[0]
        : dateString.split(' ')[0];
    }
    return dateString;
  }

  return date?.toString();
}

export function capitalizeFirstLetter(value: string, splitCharacter = ' ') {
  if (!value) {
    return '';
  }

  return value
    .toLowerCase()
    .split(splitCharacter)
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

export function capitalizeFirstCharacter(value: string, replaceChar?: string) {
  const returnValue = value.charAt(0).toUpperCase() + value.slice(1);
  if (replaceChar) {
    return returnValue.replace(replaceChar, ' ');
  }
  return returnValue;
}

export function removePlusFromMobileNumber(value: string) {
  return value.replace('+', '');
}

export function removeHTMLTagesFromDescription(value: string) {
  if (!value) {
    return '';
  }

  return value.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '');
}

export function getIdsFromMultiselectData(array: []) {
  return array?.map((item: { Id: string }) => item?.Id);
}

export function getTitlesFromMultiselectData(array: any[]) {
  if (!array) {
    return '';
  }

  return array?.map((item: { Title: string }) => item?.Title).join(', ');
}

export function capitalizeFirstLetterLowerOthers(
  value: string,
  splitCharacter = ' '
) {
  if (!value) {
    return '';
  }

  return value
    .toLowerCase()
    .split(splitCharacter)
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1)?.toLowerCase();
    })
    .join(' ');
}

export function multiplyAndFormat(num: number): string {
  if (!isValidNumber(num)) {
    return '';
  }
  const result = num * 100;
  return Number.isInteger(result)
    ? result.toString()
    : parseFloat(result.toFixed(2)).toString();
}

/**
 * Removes trailing zeros and limits the value to 3 decimal places without rounding,
 * returning a number.
 * If the resulting value is zero, it returns 0.
 *
 * @param {number | string} value - The input value that can be a number or a string representing a number.
 *
 * @returns {number} - The formatted value as a number with up to 3 decimal places, without trailing zeros or rounding.
 * - If the value is 0 or equivalent to 0 (e.g., "0.00", "000"), the function returns 0.
 *
 * @example
 * // Returns 0
 * trimTrailingZeros(0);
 *
 * @example
 * // Returns 0
 * trimTrailingZeros("0.00");
 *
 * @example
 * // Returns 1.2
 * trimTrailingZeros(1.2000);
 *
 * @example
 * // Returns 45
 * trimTrailingZeros(45.000);
 *
 * @example
 * // Returns 0.088
 * trimTrailingZeros(0.088);
 */
export function trimTrailingZeros(
  value: number | string,
  maxDecimalPrecision: number,
  keepFormat: true
): string;
export function trimTrailingZeros(
  value: number | string,
  maxDecimalPrecision?: number,
  keepFormat?: false
): number;
export function trimTrailingZeros(
  value: number | string,
  maxDecimalPrecision = 3,
  keepFormat = false
): string | number {
  if (isEmpty(value)) {
    return keepFormat ? '0' : 0;
  }

  // Convert value to a string and remove commas temporarily for processing
  const sanitizedValue = value.toString().replace(/,/g, '');
  const [integerPart, decimalPart = ''] = sanitizedValue.split('.');

  // Trim the decimal part to the specified precision without rounding
  const trimmedDecimalPart = decimalPart.slice(0, maxDecimalPrecision);

  // Reassemble with integer and trimmed decimal parts
  let result = trimmedDecimalPart
    ? `${integerPart}.${trimmedDecimalPart}`
    : integerPart;

  // Remove trailing zeros only in the decimal part
  if (trimmedDecimalPart) {
    result = result.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '');
  }

  if (keepFormat) {
    // Format integer part with commas
    const [formattedIntegerPart, formattedDecimalPart] = result.split('.');
    const formattedIntegerWithCommas =
      Number(formattedIntegerPart).toLocaleString();
    return formattedDecimalPart
      ? `${formattedIntegerWithCommas}.${formattedDecimalPart}`
      : formattedIntegerWithCommas;
  } else {
    // Return as a number if keepFormat is false
    return Number(result);
  }
}

/**
 *
 * @param {string} value - The value to be formatted
 * @returns - The formatted value as a string with the first letter of each word capitalized and joined by the provided character.
 *
 * @example
 * // Returns 'NonProportional'
 * formatEnum('NON_PROPORTIONAL');
 *
 * @example
 * // Returns 'Non Proportional'
 * formatEnum('NON_PROPORTIONAL', ' ');
 *
 * @example
 * // Returns 'Property'
 * formatEnum('PROPERTY');
 */
export function formatEnum(value: string, joinBy?: string): string {
  if (!value) {
    return '';
  }

  return value
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(joinBy || '');
}
