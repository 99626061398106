import { ITreatyDetails, ITreatyErrors } from '.';

export const initialTreatyValues: {
  values: ITreatyDetails;
  errors: ITreatyErrors;
} = {
  values: {
    treatyName: '',
    treatyType: '',
    effectiveDate: '',
    expiryDate: '',
    currency: '',
    underwritingLimit: '',
    claimAdviseLimit: '',
    substandardLimit: '100',
    layers: {
      tab_0: {
        treatySubType: '',
        retentionShare: '',
        paymentDate: '',
        paymentAmount: '',
        totalTreatyPremium: '',
        totalExpectedPremium: '',
        premiumRate: '',
        categories: [
          {
            category: '',
            minimumLayerLimit: '',
            maximumLayerLimit: '',
            retentionLimit: '',
            priorityLimit: '',
            liabilityLimit: '',
            aggregateLimit: '',
            commissionRate: '',
          },
        ],
      },
    },
  },
  errors: {
    treatyName: '',
    treatyType: '',
    effectiveDate: '',
    expiryDate: '',
    currency: '',
    underwritingLimit: '',
    claimAdviseLimit: '',
    substandardLimit: '',
    layers: {
      tab_0: {
        treatySubType: '',
        retentionShare: '',
        paymentDate: '',
        paymentAmount: '',
        totalTreatyPremium: '',
        totalExpectedPremium: '',
        premiumRate: '',
        categories: [
          {
            category: '',
            minimumLayerLimit: '',
            maximumLayerLimit: '',
            retentionLimit: '',
            priorityLimit: '',
            liabilityLimit: '',
            aggregateLimit: '',
            commissionRate: '',
          },
        ],
      },
    },
  },
};

export const validationRules = {
  proportional: {
    treatyName: true,
    treatyType: true,
    effectiveDate: true,
    expiryDate: true,
    currency: true,
    underwritingLimit: true,
    claimAdviseLimit: true,
    substandardLimit: true,
    layer: {
      QuotaShare: {
        retentionShare: true,
        categories: {
          category: true,
          minimumLayerLimit: true,
          maximumLayerLimit: true,
          commissionRate: true,
        },
      },
      Surplus: {
        categories: {
          category: true,
          maximumLayerLimit: true,
          retentionLimit: true,
          commissionRate: true,
        },
      },
      FacOblig: {
        categories: {
          category: true,
          maximumLayerLimit: true,
          commissionRate: true,
        },
      },
    },
  },
  non_proportional: {
    treatyName: true,
    treatyType: true,
    effectiveDate: true,
    expiryDate: true,
    currency: true,
    underwritingLimit: true,
    claimAdviseLimit: true,
    layer: {
      ExcessOfLoss: {
        paymentDate: true,
        paymentAmount: true,
        totalTreatyPremium: true,
        totalExpectedPremium: true,
        premiumRate: true,
        categories: {
          category: true,
          priorityLimit: true,
          liabilityLimit: true,
          aggregateLimit: true,
        },
      },
    },
  },
};
