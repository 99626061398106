import React, { useEffect, useMemo, useState } from 'react';
import TableWithFilters from './shared/TableWithFilters';
import PageTitle from './shared/PageTitle';
import { headers } from '../content';
import { addDaysUTC, convertDateToYMD } from '../../../../../utils/date-utils';
import { makeStyles } from 'tss-react/mui';
import { useLazyQuery } from '@apollo/client';
import { getLovs } from '../queries';
import { IFormValues } from './ViewBreakdown';
import { IEnhancedTableProps } from '../../../../../components/updated-enhanced-table/EnhancedTable';
import { enumListAsRecordObject } from '../../../../../utils/graph-utils';
import { transformPolicyArrayToObject } from '../utils';
import { IEnhancedMenuItem } from '../../../../../components/form-fields/table';
import { isEmpty } from '../../../../../utils/validationUtils';
import ViewBreakdownShell from './ViewBreakdownShell';
import { IAbstractRecord } from '../../../../../models';

interface IMainColumnProps {
  mainEntityId: string;
  fetchPolicies: (variables: IAbstractRecord) => void;
  data: IAbstractRecord;
  loading: boolean;
}

const useStyles = makeStyles()(() => ({
  container: {
    marginTop: '36px',
  },
}));

const BrokerMainColumn: React.FC<IMainColumnProps> = ({
  mainEntityId,
  fetchPolicies,
  data,
  loading,
}) => {
  const { classes } = useStyles();
  const [fetchLovs, { data: lovs }] = useLazyQuery(getLovs);
  const [viewBreakdownPopup, setViewBreakdownPopup] = useState<{
    open: boolean;
    selected?: {
      id: string;
    } & IFormValues;
  }>({
    open: false,
  });

  useEffect(() => {
    fetchLovs();
    fetchPolicies({
      variables: {
        BusinessPartnerID: mainEntityId,
      },
    });
  }, [fetchLovs, fetchPolicies, mainEntityId]);

  const tableFilters: IEnhancedTableProps['tableFilters'] = useMemo(() => {
    if (!lovs) return {};

    const allLinesArray: Record<string, string>[] =
      lovs?.PlanConfigManagement?.queries?.allLines;
    const allLines: Record<string, string> = {};
    allLinesArray?.forEach((item) => {
      allLines[item.planConfigManagement_Line_Id] =
        item.planConfigManagement_Line_Name;
    });
    const allCurrenciesArray: Record<string, string>[] =
      lovs?.Core?.lookups?.currencies;
    const allCurrencies = allCurrenciesArray?.reduce((acc, item) => {
      acc[item.Code] = item.Symbol;
      return acc;
    });

    return {
      Line: {
        name: 'Line',
        type: 'select',
        options: allLines,
        placeholder: 'Line',
        selectedValues: [],
      },
      PolicyIssuanceDate: {
        name: 'PolicyIssuanceDate',
        type: 'daterange',
        placeholder: 'Policy Issuance Date',
        value: [],
      },
      Currency: {
        name: 'Currency',
        type: 'select',
        placeholder: 'Currency',
        options: allCurrencies,
        selectedValues: [],
      },
    };
  }, [lovs]);

  const tableData: IEnhancedTableProps['data'] = useMemo(() => {
    if (!data || !lovs) {
      return {
        pagedItems: {},
        pageSize: 10,
        pageNumber: 0,
        totalCount: 0,
      };
    }

    const paymentDivisions = enumListAsRecordObject(
      lovs.Production_PaymentDivisionTypeList?.enumValues as {
        __typename: string;
        name: string;
      }[]
    );

    const items = transformPolicyArrayToObject(
      data?.Accounting?.queries?.BrokerPoliciesList,
      paymentDivisions
    );

    return {
      pagedItems: items,
      pageNumber: 1,
      pageSize: 10,
      totalCount: items.length,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const actions: IEnhancedMenuItem[] = useMemo(() => {
    return [
      {
        name: 'ViewBreakdown',
        title: 'View Breakdown',
        isEntity: true,
        isBulk: false,
        onClick: (selected: string[]) => {
          setViewBreakdownPopup({
            open: true,
            selected: {
              id: selected?.[0],
              ...tableData.pagedItems?.[selected?.[0]],
            },
          });
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getPolicyIssuanceDate = (values: Date[] | string[]) => {
    if (!values?.length) {
      return null;
    }
    if (values?.[1] === values?.[0] && !isEmpty(values?.[0])) {
      const date = values?.[0];
      const dateObj = addDaysUTC(date, 1);
      //without this function, adding a day to the date will give consistent results for all timezones
      return convertDateToYMD(dateObj);
    }
    return convertDateToYMD(values?.[1]);
  };

  return (
    <div>
      {viewBreakdownPopup.open && (
        <ViewBreakdownShell
          open={viewBreakdownPopup.open}
          policyId={viewBreakdownPopup.selected?.id}
          businessPartnerId={mainEntityId}
          data={viewBreakdownPopup.selected}
          onClose={() => {
            setViewBreakdownPopup({
              open: false,
              selected: undefined,
            });
          }}
        />
      )}
      <PageTitle text={'Receivables & Payables'} />
      <div className={classes.container}>
        <TableWithFilters
          inlineTitle="Policies"
          loading={loading}
          actions={actions}
          headers={headers}
          data={tableData}
          tableFilters={tableFilters}
          applyFilters={(values) => {
            fetchPolicies({
              variables: {
                SelectedLine: values.Line || undefined,
                SelectedCurrency: values.Currency || undefined,
                PolicyIssuanceFrom: convertDateToYMD(
                  values.PolicyIssuanceDate?.[0]
                ),
                PolicyIssuanceTo: getPolicyIssuanceDate(
                  values.PolicyIssuanceDate
                ),
                BusinessPartnerID: mainEntityId,
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export default BrokerMainColumn;
