import { gql } from '@apollo/client';

export const getPolicyDetailsQuery = gql`
  query getPolicyDetails($selectedPolicyID: String!) {
    Production {
      entities {
        policy(id: $selectedPolicyID) {
          views {
            Production_all {
              properties {
                PolicyName
                PolicyNumber
                createdOn
                modifiedOn
                PolicyName
                PolicyEffectiveDate
                PolicyExpiryDate
                PolicyCurrency {
                  Code
                  Title
                  Symbol
                }
              }
            }
          }
          queries {
            production {
              relatedUsers {
                createdBy_firstName
                createdBy_lastName
                modifiedBy_firstName
                modifiedBy_lastName
              }
              pricingOption {
                pricingOption_NetPremium
              }
            }
          }
        }
      }
    }
  }
`;

export const getTreatyDistListQuery = gql`
  query getTreatyDistributionList($PolicyID: String!) {
    Production {
      queries {
        GetTreatyDistributionPerPolicyCover(PolicyID: $PolicyID) {
          policyPlanCover_Id
          policyCover_Name
          treatyDetails_TreatyName
          treatyDetails_TreatyCurrency {
            Code
            Title
            Symbol
          }
          treatyDetails_EffectiveDate
          treatyDetails_ExpiryDate
          mainReinsurerDetails_FullName
          production_PolicyTreatyDistribution_CededSumInsured
          production_PolicyTreatyDistribution_CededNetPremium
          production_PolicyTreatyDistribution_Commission
        }
      }
    }
  }
`;

export const getTreatyDistPerCoverQuery = gql`
  query getTreatyDistributionPerPolicyCover(
    $policyId: String!
    $coverId: String!
  ) {
    Production {
      queries {
        GetTreatyDistributionPerPolicyCover(
          PolicyID: $policyId
          PolicyPlanCoverID: $coverId
        ) {
          production_PolicyTreatyDistribution_Id
          production_PolicyTreatyDistribution_LayerType
          production_PolicyTreatyDistribution_ExcessAmount
          production_PolicyTreatyDistribution_RetainedSumInsured
          production_PolicyTreatyDistribution_RetainedNetPremium
          production_PolicyTreatyDistribution_RetainedNetPremiumBasic
          production_PolicyTreatyDistribution_RetainedNetPremiumSecondary
          production_PolicyTreatyDistribution_CededSumInsured
          production_PolicyTreatyDistribution_CededNetPremium
          production_PolicyTreatyDistribution_CededNetPremiumBasic
          production_PolicyTreatyDistribution_CededNetPremiumSecondary
          production_PolicyTreatyDistribution_Commission
          production_PolicyTreatyDistribution_PolicyCurrency
        }
      }
    }
    Core {
      queries {
        getSystemCurrencies {
          core_SystemCurrency_BasicCurrency {
            Symbol
          }
          core_SystemCurrency_SecondaryCurrency {
            Symbol
          }
        }
      }
    }
  }
`;

export const getFacOfPolicyCoversQuery = gql`
  query getFacOfPolicyCovers($PolicyID: String!) {
    Production {
      queries {
        GetFacOfPolicyCovers(PolicyID: $PolicyID) {
          cover_Name
          facultative_Id
          facultative_SlipReferenceNumber
          policyPricing_PricingOptionCurrency {
            Code
            Title
            Symbol
          }
          policyPlanCover_CoverEffectiveFrom
          policyPlanCover_CoverEffectiveTo
          mainReinsurerDetails_FullName
          production_PolicyFacultativeDistribution_CededSumInsured
          production_PolicyFacultativeDistribution_CededNetPremium
          production_PolicyFacultativeDistribution_Commission
        }
      }
    }
  }
`;
