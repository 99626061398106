export const excelHeaders = (
  systemCurrency: string,
  systemSecondaryCurrency: string
) => ({
  transactionNumber: {
    label: 'Transaction Number',
    visible: true,
    isDate: false,
    isCurrency: false,
  },
  transactionType: {
    label: 'Transaction Type',
    visible: true,
    isDate: false,
    isCurrency: false,
  },
  referenceNumber: {
    label: 'Reference #',
    visible: true,
    isDate: false,
    isCurrency: false,
  },
  transactionDate: {
    label: 'Transaction Date',
    visible: true,
    isDate: true,
    isCurrency: false,
  },
  description: {
    label: 'Description',
    visible: true,
    isDate: false,
    isCurrency: false,
  },
  account: {
    label: 'Account',
    visible: true,
    isDate: false,
    isCurrency: false,
  },
  recordDescription: {
    label: 'Record Description',
    visible: true,
    isDate: false,
    isCurrency: false,
  },
  accountDebit: {
    label: 'Account Debit',
    visible: true,
    isDate: false,
    isCurrency: true,
  },
  accountCredit: {
    label: 'Account Credit',
    visible: true,
    isDate: false,
    isCurrency: true,
  },
  basicDebit: {
    label: 'Basic Debit',
    visible: true,
    isDate: false,
    isCurrency: true,
    currencySymbol: systemCurrency,
  },
  basicCredit: {
    label: 'Basic Credit',
    visible: true,
    isDate: false,
    isCurrency: true,
    currencySymbol: systemCurrency,
  },
  secondaryDebit: {
    label: 'Secondary Debit',
    visible: true,
    isDate: false,
    isCurrency: true,
    currencySymbol: systemSecondaryCurrency,
  },
  secondaryCredit: {
    label: 'Secondary Credit',
    visible: true,
    isDate: false,
    isCurrency: true,
    currencySymbol: systemSecondaryCurrency,
  },
});
