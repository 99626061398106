import { IAddVoucherErrors } from '..';
import { IAddVoucherLOVs, IVoucherPopupValues } from '../../shared/types';

export const initialValues: IVoucherPopupValues = {
  dateOfPayment: '',
  currency: '',
  paymentMethod: '',
  referenceNum: '',
  totalAmount: null,
  totalAmountUnAllocated: null,
  note: '',
  incomingDocuments: [],
  supportingDocuments: [],
  totalCredit: 0,
  totalDebit: 0,
  transactions: [],
  companyId: null,
  systemCurrencies: {
    primary: {},
    secondary: {},
  },
};

export const initialErrors: IAddVoucherErrors = {
  dateOfPayment: '',
  currency: '',
  paymentMethod: '',
  referenceNum: '',
  totalAmount: '',
  totalAmountUnAllocated: '',
  tableAmountPaid: [],
};

export const initialLovs: IAddVoucherLOVs = {
  currenciesSymbols: {},
  paymentMethods: {},
  accounts: {},
};
