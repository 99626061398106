import React, { useMemo } from 'react';
import { IAbstractRecord } from '../../../../../models';
import { EnhancedDisplayType } from '../../../../../components/enhanced-form/EnhancedDisplay';
import DynamicWidget from '../../../../../components/widgets/dynamic-display/DynamicWidget';
import { IDynamicDiplayView } from '../../../../../components/widgets/dynamic-display';
import { trimTrailingZeros } from '../../../../../utils/formatting-utils';

const PolicyDetailsWidget: React.FC<{ policy: IAbstractRecord }> = ({
  policy,
}) => {
  const dynamicView: IDynamicDiplayView = useMemo(() => {
    const policyDetails = policy?.views?.Production_all?.properties;
    const policyPricingOption = policy?.queries?.production?.pricingOption[0];

    return {
      name: 'PolicyDetails',
      sections: [
        {
          name: 'PolicyDetails',
          title: 'Policy Details',
          properties: {
            policyName: {
              multiline: false,
              value: policyDetails?.PolicyName,
              name: 'policyName',
              title: 'Policy Name',
              tooltip: false,
            },
            policyEffectiveDate: {
              multiline: false,
              value: policyDetails?.PolicyEffectiveDate,
              name: 'PolicyEffectiveDate',
              title: 'Policy Effective Date',
              tooltip: false,
              type: EnhancedDisplayType.Date,
            },
            policyExpiryDate: {
              multiline: false,
              value: policyDetails?.PolicyExpiryDate,
              name: 'PolicyEndDate',
              title: 'Policy Expiry Date',
              tooltip: false,
              type: EnhancedDisplayType.Date,
            },
            policyCurrency: {
              multiline: false,
              value: policyDetails?.PolicyCurrency?.Title || '',
              name: 'PolicyCurrency',
              title: 'Policy Currency',
              tooltip: false,
            },
            policyNetPremium: {
              multiline: false,
              value: trimTrailingZeros(
                policyPricingOption?.pricingOption_NetPremium
              ).toString(),
              name: 'PolicyNetPremium',
              title: 'Policy Net Premium',
              tooltip: false,
              type: EnhancedDisplayType.RoundedCurrency,
              currencySymbol: policyDetails?.PolicyCurrency?.Symbol,
            },
          },
        },
      ],
    };
  }, [policy]);

  return <DynamicWidget hasFourFields view={dynamicView} />;
};

export default PolicyDetailsWidget;
