import React, { useState } from 'react';
import SimpleActionBar from '../../../../../components/form-fields/menu/SimpleActionBar';
import { IEnhancedMenuItem } from '../../../../../components/form-fields/table';
import { IAmendmentPageData } from '../..';
import { ILineAmendmentProps } from '../../line-amendment';
import { LineCategory } from '../../../../../utils/helper-utils';
import { DocumentNode } from 'graphql';
import {
  calculateAmendmentExpat,
  calculateAmendmentGeneric,
  calculateAmendmentMedical,
  calculateAmendmentMotor,
  calculateAmendmentTravel,
  cancelAmendmentMutation,
} from './queries';
import { IAbstractRecord } from '../../../../../models';
import { getMotorCalculateVariables } from './utils';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import { getError } from '../../../../../utils/graph-utils';
import ToastSuccessMessage from '../../../../../components/ToastSuccessMessage';
import { isEmpty } from '../../../../../utils/validationUtils';
import PreviewClauseModal from '../../components/preview-clause-modal/PreviewClauseModal';
import { confirmAmendmentMutation } from '../../components/preview-clause-modal/queries';
import { useAppDispatch } from '../../../../../redux/hooks';
import { OpenConfirmationAction } from '../../../../../redux/confirmation/actions';
import { IConfirmation } from '../../../../../redux/confirmation/types';

interface IStepperActions {
  generalData: ILineAmendmentProps['generalData'];
  values: IAmendmentPageData;
  onSuccess: () => void;
}

const lineCategoryCalculation: Record<LineCategory, DocumentNode> = {
  Motor: calculateAmendmentMotor,
  Expat: calculateAmendmentExpat,
  Generic: calculateAmendmentGeneric,
  Medical: calculateAmendmentMedical,
  Travel: calculateAmendmentTravel,
};

const lineCategoryVariablesBuilder: Record<
  LineCategory,
  (
    values: IAmendmentPageData,
    generalData: ILineAmendmentProps['generalData']
  ) => IAbstractRecord
> = {
  Motor: getMotorCalculateVariables,
  Expat: () => ({}),
  Generic: () => ({}),
  Medical: () => ({}),
  Travel: () => ({}),
};

const StepperActions: React.FC<IStepperActions> = ({
  generalData,
  values,
  onSuccess,
}) => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [calculateAndSave] = useMutation(
    lineCategoryCalculation[generalData.lineCategory]
  );
  const [confirmAmendment] = useMutation(confirmAmendmentMutation);
  const [cancelAmendment] = useMutation(cancelAmendmentMutation);

  const handleCalculateAndSave = async () => {
    try {
      const mappedVariables = lineCategoryVariablesBuilder[
        generalData.lineCategory
      ](values, generalData);

      const { errors } = await calculateAndSave({
        variables: mappedVariables,
        errorPolicy: 'all',
      });

      if (isEmpty(errors)) {
        toast.success(
          <ToastSuccessMessage>
            Amendment successfully saved
          </ToastSuccessMessage>
        );
        onSuccess && onSuccess();
      } else {
        toast.error(
          <ToastErrorMessage>{getError({ errors })}</ToastErrorMessage>
        );
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
    }
  };

  const handleConfirmAmendment = async () => {
    if (['50', '43'].includes(generalData.lineExternalCode)) {
      try {
        const { errors } = await confirmAmendment({
          variables: {
            selectedAmendmentID: generalData.currentAmendmentId,
            selectedPolicyVersionID: generalData.versionId,
            selectedPolicyID: generalData.policyId,
            clauseText: generalData.clauseText,
          },
          errorPolicy: 'all',
        });

        if (isEmpty(errors)) {
          toast.success(
            <ToastSuccessMessage>
              Amendment successfully confirmed
            </ToastSuccessMessage>
          );
          onSuccess();
        } else {
          toast.error(
            <ToastErrorMessage>{getError({ errors })}</ToastErrorMessage>
          );
        }
      } catch (error) {
        toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
      }
    } else {
      setIsModalOpen(true);
    }
  };

  const handleCancel = async () => {
    const confirmation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Are you sure you want to cancel this amendment?',
      submitButtonText: 'Submit',
      cancelButtonText: 'Cancel',
      callback: async () => {
        try {
          const res = await cancelAmendment({
            variables: {
              selectedAmendmentID: generalData.currentAmendmentId,
              selectedPolicyVersionID: generalData.versionId,
            },
            errorPolicy: 'all',
          });

          if (!res.errors) {
            toast.success(
              <ToastSuccessMessage>
                Amendment successfully canceled
              </ToastSuccessMessage>
            );
            onSuccess();
          } else {
            toast.error(
              <ToastErrorMessage>
                {getError({ errors: res.errors })}
              </ToastErrorMessage>
            );
          }
        } catch (error) {
          toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
        }
      },
    };
    dispatch(OpenConfirmationAction(confirmation));
  };

  const getActions = (): IEnhancedMenuItem[] => {
    const actions: IEnhancedMenuItem[] = [
      {
        name: 'calculate&Save',
        title: 'Calculate & Save',
        onClick: () => handleCalculateAndSave(),
        isPrimary: true,
      },
      ...(generalData.amendmentStatus === 'IN_PROGRESS'
        ? [
            {
              name: 'confirm',
              title: 'Confirm',
              onClick: () => {
                handleConfirmAmendment();
              },
              isPrimary: true,
            },
          ]
        : []),
      {
        name: 'cancel',
        title: 'Cancel',
        onClick: () => handleCancel(),
        isPrimary: true,
      },
    ];

    return actions;
  };

  return (
    <>
      <SimpleActionBar items={getActions()} />
      {isModalOpen && (
        <PreviewClauseModal
          generalData={generalData}
          onClose={() => setIsModalOpen(false)}
          onSuccess={() => {}}
        />
      )}
    </>
  );
};

export default StepperActions;
