import React, { useEffect, useState } from 'react';
import DocumentWidget from '../../../../../../components/widgets/file-card/DocumentWidget';
import EnhancedButton from '../../../../../../components/form-fields/buttons/EnhancedButton';
import { useAppSelector } from '../../../../../../redux/hooks';
import { IFileCardProps } from '../../../../../../models/file';
import AddTreatyDocumentDrawer from '../add-treaty-document-drawer/AddTreatyDocumentDrawer';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { getTreatyDocumentsQuery } from './queries';
import Loader from '../../../../../../components/Loader';
import { mapFileToDocumentWidgetFiles } from './utils';

const TreatyDocuments: React.FC = () => {
  const { id: currentTreatyId } = useParams<{ id: string }>();
  const tenant = useAppSelector((state) => state.tenant);
  const [showAddDrawer, setShowAddDrawer] = useState<boolean>(false);

  const [documentsData, setDocumentsData] = useState<IFileCardProps[]>();

  const { refetch, data, loading } = useQuery(getTreatyDocumentsQuery, {
    variables: { TreatyID: currentTreatyId },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const docsList = mapFileToDocumentWidgetFiles(
      data?.Production?.queries?.GetTreatyDocuments
    );

    setDocumentsData(docsList);
  }, [data]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <DocumentWidget
        title="Supporting Documents"
        cdnUrl={tenant.cdnUrl}
        files={documentsData}
        actions={
          <EnhancedButton
            isPrimary={false}
            onClick={() => setShowAddDrawer(true)}
          >
            Add Document
          </EnhancedButton>
        }
      />

      {showAddDrawer && (
        <AddTreatyDocumentDrawer
          currentTreatyId={currentTreatyId}
          isOpen={showAddDrawer}
          onClose={() => setShowAddDrawer(false)}
          onSuccess={() => refetch()}
        />
      )}
    </>
  );
};

export default TreatyDocuments;
