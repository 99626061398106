import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  calculateMaxFrequencyQuery,
  calculateMaxPaymentsQuery,
  changePaymentSimulationQuery,
  createChangePaymentTermsQuery,
} from './queries';
import { makeStyles } from 'tss-react/mui';
import { enumListAsRecordObject, getError } from '../../utils/graph-utils';
import {
  ILovsTypes,
  initialErrors,
  IPaymentSimulationTableData,
  IPaymentTermsFormsProps,
  IPaymentTermsPopupFormState,
} from '.';
import {
  frequencyArrayToList,
  getPaymentTermsPopupFormState,
  mapToListingData,
  numToList,
} from './utils';
import {
  AddMonths,
  isDateBefore,
  subtractMonths,
} from '../../utils/date-utils';
import { formatDate, formatDateTime } from '../../utils/formatting-utils';
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../constants';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { paymentTermsHeaders } from './content';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { KeyOf } from '../../utils/helper-utils';
import TextInputFormField from '../../components/form-fields/TextInputFormField';
import SelectFormField from '../../components/form-fields/SelectFormField';
import DatePickerFormField from '../../components/form-fields/DatePickerFormField';
import CurrencyFormField from '../../components/form-fields/CurrencyFormField';
import EnhancedButton from '../../components/form-fields/buttons/EnhancedButton';
import ListingTable from '../../components/form-fields/table/ListingTable';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { IEnhancedRow } from '../../components/form-fields/table';

const useStyles = makeStyles()(() => ({
  dialogPaper: {
    height: '80%',
    width: '80%',
    maxWidth: '1539px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '20px 0',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(3, 30%)`,
    gap: '3%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '0 auto 15px',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  button: {
    margin: '20px 5px 5px',
  },
}));

const PaymentTermsForms: React.FunctionComponent<IPaymentTermsFormsProps> = ({
  generalData,
  currencySymbol,
  isProductionBill,
  data,
  onClose,
}) => {
  const { classes } = useStyles();
  const [pageState, setPageState] = useState<IPaymentTermsPopupFormState>(
    getPaymentTermsPopupFormState()
  );
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(true);
  const applyButtonClicked = useRef(false);

  const [lovs, setLovs] = useState<ILovsTypes>({
    paymentTypes: {},
    frequencies: {},
    numOfPayments: {},
    billStatuses: {},
  });

  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: [],
    pageNumber: 0,
    pageSize: 5,
    totalCount: 0,
  });

  const maxEffectiveFrom = useMemo(() => {
    return subtractMonths(new Date(pageState.values.policyExpiryDate), 1);
  }, [pageState.values.policyExpiryDate]);

  const amountToBeAllocated = useMemo(() => {
    const uniqueBills = Array.from(
      new Map(
        pageState.values.bills
          .filter((bill) => bill.accounting_Bills_BillType === 'RECEIVABLE')
          .map((bill) => [
            `${bill.accounting_Bills_BillNumber}-${bill.accounting_Bills_BillType}`,
            bill,
          ])
      ).values()
    );

    const totalAmount: number = uniqueBills.reduce(
      (total: number, bill: IAbstractRecord) => {
        return total + bill.accounting_Bills_OutstandingBalance;
      },
      0
    );

    return totalAmount;
  }, [pageState.values.bills]);

  const [calculateMaxFrequency] = useMutation(calculateMaxFrequencyQuery());
  const [calculateMaxPayments] = useMutation(calculateMaxPaymentsQuery());
  const [changePaymentSimulation] = useMutation(changePaymentSimulationQuery());
  const [createChangePaymentTerms] = useMutation(
    createChangePaymentTermsQuery()
  );

  const loadData = async () => {
    try {
      if (!data) return;

      const paymentTypes = enumListAsRecordObject(
        data.Production_PaymentDivisionTypeList?.enumValues
      );
      const billStatuses = enumListAsRecordObject(
        data.Accounting_BillStatusList?.enumValues,
        true
      );
      setLovs((prev) => ({
        ...prev,
        paymentTypes,
        billStatuses,
      }));

      const policyDetails =
        data.Production?.entities?.policy?.views?.Production_all?.properties;

      const policyExpiryDate =
        policyDetails?.PolicyExpiryDate ||
        AddMonths(new Date(policyDetails?.PolicyIssueDate), 6);

      const effectiveFrom = isDateBefore(
        new Date(),
        policyDetails.PolicyIssueDate
      )
        ? formatDate(policyDetails.PolicyIssueDate, SEND_TO_BACKEND_DATE_FORMAT)
        : formatDate(new Date(), SEND_TO_BACKEND_DATE_FORMAT);

      setPageState((prev) => ({
        ...prev,
        values: {
          ...prev.values,
          policyNumber: policyDetails?.PolicyNumber,
          policyIssuanceDate: isProductionBill
            ? policyDetails?.PolicyIssueDate
            : generalData.amendment.effectiveDate,
          policyExpiryDate: policyExpiryDate,
          paymentType: 'EQUIVALENT',
          effectiveFrom: effectiveFrom,
          bills: data.Accounting.queries.GetPolicyReceivableBills,
        },
      }));

      loadFrequencies(
        isProductionBill
          ? policyDetails?.PolicyIssueDate
          : generalData.amendment.effectiveDate,
        policyExpiryDate,
        new Date()
      );
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const loadFrequencies = async (
    policyIssuanceDate: string,
    policyExpiryDate: string,
    effectiveFromDate: Date
  ) => {
    try {
      const res = await calculateMaxFrequency({
        variables: {
          policyIssuanceDate: formatDate(
            policyIssuanceDate,
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          policyExpiryDate: formatDate(
            policyExpiryDate,
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          effectiveFromDate: formatDate(
            effectiveFromDate,
            SEND_TO_BACKEND_DATE_FORMAT
          ),
        },
      });

      if (
        isEmpty(res.data.accounting?.actions?.calculateMaxFrequency?.Values)
      ) {
        toast.error(
          <ToastErrorMessage>
            Cannot submit. One or many Due Date is greater than Policy Expiry
            Date minus 1 month
          </ToastErrorMessage>
        );
        return;
      }
      const maxFrequencies = frequencyArrayToList(
        res.data.accounting?.actions?.calculateMaxFrequency?.Values
      );

      setLovs((prev) => ({
        ...prev,
        frequencies: maxFrequencies,
      }));
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const loadPayments = async (selectedFrequency: string) => {
    try {
      const res = await calculateMaxPayments({
        variables: {
          policyIssuanceDate: formatDate(
            pageState.values.policyIssuanceDate,
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          policyExpiryDate: formatDate(
            pageState.values.policyExpiryDate,
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          effectiveFromDate: formatDate(
            pageState.values.effectiveFrom,
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          selectedFrequency: selectedFrequency || pageState.values.frequency,
        },
      });

      const maxPayments: number =
        res.data.accounting?.actions?.calculateMaxPayments?.Value;

      setLovs((prev) => ({
        ...prev,
        numOfPayments: numToList(maxPayments),
      }));
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const initialize = async () => {
    await loadData();
  };

  const validateSimulationForm = (
    values = pageState.values
  ): IPaymentTermsPopupFormState['errors'] => {
    const errors: IPaymentTermsPopupFormState['errors'] =
      cloneDeep(initialErrors);
    if (isEmpty(values.effectiveFrom)) {
      errors.effectiveFrom = 'Required';
    }
    if (values.paymentType === 'EQUIVALENT' && isEmpty(values.frequency)) {
      errors.frequency = 'Required';
    }
    if (isEmpty(values.numOfPayments)) {
      errors.numOfPayments = 'Required';
    }

    return errors;
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApply = async () => {
    try {
      applyButtonClicked.current = true;
      const errors = validateSimulationForm();
      setPageState((prev) => ({
        ...prev,
        errors,
      }));

      if (Object.values(errors).some((error) => !isEmpty(error))) {
        return;
      }

      setIsSubmitButtonDisabled(false);
      const res = await changePaymentSimulation({
        variables: {
          policyIssuanceDate: formatDate(
            pageState.values.policyIssuanceDate,
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          policyExpiryDate: formatDate(
            pageState.values.policyExpiryDate,
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          effectiveFromDate: formatDate(
            pageState.values.effectiveFrom,
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          selectedFrequency:
            pageState.values.paymentType === 'CUSTOM'
              ? 'Month'
              : pageState.values.frequency,
          paymentType: pageState.values.paymentType,
          noOfPayments: Number(pageState.values.numOfPayments),
          noOfPaymentsList: Array.from(
            { length: Number(pageState.values.numOfPayments) - 1 },
            (_, i) => i + 1
          ),
          amountToBeAllocated: amountToBeAllocated,
          currentPolicyID: generalData.policy.id,
        },
      });

      if (isEmpty(res.errors)) {
        const pagedItems = mapToListingData(
          res.data.accounting.actions.changePaymentSimulation.List,
          lovs,
          currencySymbol
        );

        setTableData({
          pagedItems: pagedItems,
          pageNumber: 1,
          pageSize: 5,
          totalCount: pagedItems.length,
        });
      } else {
        if (isEqual(res.errors[0].extensions.code, 'NotAllowedNoOfPayments')) {
          setPageState((prev) => ({
            ...prev,
            errors: {
              ...prev.errors,
              numOfPayments: 'Number of Payments is not in allowed range',
            },
          }));
        } else {
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      }
    } catch (error) {
      if (
        isEqual(
          error.graphQLErrors[0].extensions.code,
          'NotAllowedNoOfPayments'
        )
      ) {
        setPageState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            numOfPayments: 'Number of Payments is not in allowed range',
          },
        }));
      }
    } finally {
      applyButtonClicked.current = false;
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitButtonDisabled(true);
      const res = await createChangePaymentTerms({
        variables: {
          policyIssuanceDate: pageState.values.policyIssuanceDate,
          policyExpiryDate: pageState.values.policyExpiryDate,
          effectiveFromDate: pageState.values.effectiveFrom,
          selectedFrequency:
            pageState.values.paymentType === 'CUSTOM'
              ? 'Month'
              : pageState.values.frequency,
          paymentType: pageState.values.paymentType,
          noOfPayments: Number(pageState.values.numOfPayments),
          noOfPaymentsList: Array.from(
            { length: Number(pageState.values.numOfPayments) - 1 },
            (_, i) => i + 1
          ),
          amountToBeAllocated: amountToBeAllocated,
          currentPolicyID: generalData.policy.id,
          parentBillID: generalData.bill.id,
          newReceivableBillsList: Object.values(tableData.pagedItems).map(
            (item: IPaymentSimulationTableData) => ({
              AmountDue: item.amountDue,
              BillNumber: item.billNumber,
              BillStatus: lovs.billStatuses[item.status],
              DueDate: formatDate(item.dueDate, SEND_TO_BACKEND_DATE_FORMAT),
              Commission: item.commission,
              TotalCommission: item.commission,
              TaxOnCommission: item.taxOnCommission,
              TotalPremium: item.totalPremium,
              OutstandingBalance: item.outstandingAmount,
            })
          ),
        },
        errorPolicy: 'all',
      });

      if (res.errors) {
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        return;
      } else {
        toast.success(
          <ToastSuccessMessage>
            Payment Terms Changed Successfully
          </ToastSuccessMessage>
        );
        onClose(true);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const handleChange = async (inputName: string, value: any) => {
    setIsSubmitButtonDisabled(true);
    let newValues = cloneDeep(pageState.values);
    let newTouched = cloneDeep(pageState.touched);

    newValues = {
      ...newValues,
      [inputName]: value,
    };

    newTouched = {
      ...newTouched,
      [inputName]: true,
    };

    const newErrors = validateSimulationForm(newValues);

    switch (inputName) {
      case 'effectiveFrom':
        if (pageState.values.paymentType === 'EQUIVALENT') {
          newValues.frequency = '';
          newValues.numOfPayments = '';
        }
        break;

      case 'frequency':
        newValues.numOfPayments = null;
        break;

      case 'paymentType':
        newValues.frequency = null;
        break;

      default:
        break;
    }

    switch (inputName) {
      case 'effectiveFrom':
        if (
          pageState.values.paymentType === 'EQUIVALENT' &&
          isEmpty(newErrors.paymentType)
        ) {
          await loadFrequencies(
            newValues.policyIssuanceDate,
            newValues.policyExpiryDate,
            value
          );
        }
        break;
      case 'frequency':
        if (isEmpty(newErrors.frequency)) {
          await loadPayments(value);
        }
        break;
      default:
        break;
    }

    setPageState({
      values: newValues,
      touched: newTouched,
      errors: newErrors,
    });
  };

  const handleCellValueChange = (
    index: number,
    columnName: string,
    value: unknown
  ) => {
    const newTableData = cloneDeep(tableData);
    newTableData.pagedItems[index][columnName] = value;

    if (columnName === 'amountDue') {
      newTableData.pagedItems[index].outstandingAmount = value;
    }

    setTableData((prev) => ({
      ...prev,
      pagedItems: newTableData.pagedItems,
    }));
  };

  const validateAmountDue = () => {
    const newTableData = cloneDeep(tableData);
    const sumOfAmountDue = Object.values(newTableData.pagedItems).reduce(
      (sum: number, item: IPaymentSimulationTableData) =>
        sum + Number(item.amountDue),
      0
    );

    const error = sumOfAmountDue !== amountToBeAllocated;
    let errorMessage = null;
    const newState = cloneDeep(pageState);
    if (error) {
      if (sumOfAmountDue > amountToBeAllocated) {
        errorMessage =
          'Amount Due cannot be greater than Amount to Be Allocated';
      } else if (sumOfAmountDue < amountToBeAllocated) {
        errorMessage = 'Amount to be allocated is not fully allocated';
      } else {
        errorMessage = null;
      }
    }

    newState.errors.amountDue = errorMessage;

    setIsSubmitButtonDisabled(error);
    setPageState(newState);
  };

  const getFieldError = (
    name: KeyOf<IPaymentTermsPopupFormState['touched']>
  ) => {
    if (applyButtonClicked.current || pageState.touched[name]) {
      return pageState.errors[name];
    }

    return '';
  };

  const dynamicRowMessage = (row: IEnhancedRow): string[] => {
    if (row && row.columns) {
      if (pageState.errors.amountDue) {
        return [pageState.errors.amountDue];
      }
    }
    return [];
  };

  const renderContent = () => {
    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleApply();
          }}
        >
          <div className={classes.fieldRow}>
            <TextInputFormField
              title={
                isProductionBill
                  ? 'Policy Issuance Date*'
                  : 'Amendment Effective Date*'
              }
              name="issuanceDate"
              value={formatDate(pageState.values.policyIssuanceDate) || ''}
              onBlur={() => undefined}
              onChange={() => undefined}
              disabled
            />
            <TextInputFormField
              title="Policy Expiry Date*"
              name="PolicyExpiryDate"
              value={formatDate(pageState.values.policyExpiryDate) || ''}
              onBlur={() => undefined}
              onChange={() => undefined}
              disabled
            />
            <SelectFormField
              title="Payment Type*"
              name="paymentType"
              selectOptions={lovs.paymentTypes}
              onChange={(v) => {
                handleChange('paymentType', v);
              }}
              value={pageState.values.paymentType}
            />
          </div>
          <div className={classes.fieldRow}>
            <DatePickerFormField
              title="Change Effective From*"
              name="effectiveFrom"
              value={pageState.values.effectiveFrom}
              onDateChange={(v) => {
                handleChange(
                  'effectiveFrom',
                  formatDateTime(v, SEND_TO_BACKEND_DATE_FORMAT)
                );
              }}
              minDate={new Date(pageState.values.policyIssuanceDate)}
              maxDate={maxEffectiveFrom}
              error={getFieldError('effectiveFrom')}
            />
            {pageState.values.paymentType === 'EQUIVALENT' && (
              <SelectFormField
                title="Frequency*"
                name="frequency"
                placeholder="Select a Frequency"
                error={getFieldError('frequency')}
                selectOptions={lovs.frequencies}
                onChange={(v) => {
                  handleChange('frequency', v);
                }}
                value={pageState.values.frequency}
              />
            )}
            <TextInputFormField
              title="No of Payments*"
              name="numOfPayments"
              placeholder="Enter No of Payments"
              value={pageState.values.numOfPayments || ''}
              error={getFieldError('numOfPayments')}
              onBlur={() => undefined}
              onChange={(e) => {
                // validate value to be a whole number using regex
                const regex = /^[0-9]*$/;
                if (regex.test(e.target.value)) {
                  handleChange('numOfPayments', e.target.value);
                }
              }}
            />
            {pageState.values.paymentType === 'CUSTOM' && (
              <CurrencyFormField
                title="Amount to be Allocated*"
                name="amountToBeAllocated"
                value={amountToBeAllocated}
                onBlur={() => undefined}
                onChange={() => undefined}
                currencySymbol={currencySymbol}
                disabled
              />
            )}
          </div>
          <div className={classes.buttonContainer}>
            <EnhancedButton type="submit" variant="outlined">
              Apply
            </EnhancedButton>
          </div>
        </form>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <ListingTable
            name="paymentTermsSchedule"
            data={tableData}
            headers={paymentTermsHeaders(pageState)}
            disableSelection
            inline
            handleCellValueChanged={handleCellValueChange}
            handleUpdate={(index, columnName) => {
              if (columnName === 'amountDue') {
                validateAmountDue();
              }
            }}
            dynamicRowMessage={dynamicRowMessage}
            actions={[]}
            forceShowSelectColumn
          />
          <div className={classes.buttonsContainer}>
            <EnhancedButton
              type="submit"
              className={classes.button}
              isPrimary
              disabled={isSubmitButtonDisabled}
            >
              Submit
            </EnhancedButton>
          </div>
        </form>
      </>
    );
  };

  // if (!open) return undefined;

  return renderContent();
};

export default PaymentTermsForms;
