import React from 'react';
import { Box } from '@mui/system';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, index }) => {
  return (
    <div
      role="tabpanel"
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      <Box sx={{ px: 0, pt: 0, py: 0 }}>{children}</Box>
    </div>
  );
};

export default TabPanel;
