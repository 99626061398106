import React, { useEffect, useMemo, useState } from 'react';
import ListingTable from '../../../../../../components/form-fields/table/ListingTable';
import { unallocatedTableHeaders } from '../../content';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../../../../../constants';
import { useParams } from 'react-router-dom';
import { IListingData } from '../../../../../../models/listing';
import { makeStyles } from 'tss-react/mui';
import {
  ActionTarget,
  IEnhancedMenuItem,
} from '../../../../../../components/form-fields/table';
import { useQuery } from '@apollo/client';
import { getNotesList } from './queries';
import { isEmpty } from 'lodash';
import { mapNotesList } from './utils';

const useStyles = makeStyles()(() => ({
  container: {
    marginTop: '20px',
    padding: '-23px 0 0 0 !important',
  },
}));

interface IUnallocatedPayments {
  type: 'CREDIT' | 'DEBIT';
}

const UnallocatedPayments: React.FC<IUnallocatedPayments> = ({ type }) => {
  const { classes } = useStyles();
  const { id: businessPartnerId } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageNumber: 1,
    pageSize: 5,
    totalCount: 0,
  });
  const { data: notesList, loading: reqLoading } = useQuery(getNotesList, {
    variables: {
      CurrentBillingAccountID: businessPartnerId,
      Type: type,
    },
    errorPolicy: 'all',
    skip: !businessPartnerId || !type,
  });

  const tableActions: IEnhancedMenuItem[] = useMemo(() => {
    return [
      {
        name: 'refund',
        title: 'Refund',
        isSecondary: true,
        target: ActionTarget.Entity,
        rowIndependant: false,
        onClick: () => {},
      },
      {
        name: 'useAsPayment',
        title: 'Use as Payment',
        isSecondary: true,
        target: ActionTarget.Entity,
        rowIndependant: false,
        onClick: () => {},
      },
    ];
  }, []);

  const initialize = async () => {
    try {
      setLoading(true);
      const mappedItems = mapNotesList(notesList.Accounting.queries.NotesList);
      setTableData({
        pagedItems: mappedItems,
        pageNumber: 1,
        pageSize: 5,
        totalCount: Object.keys(mappedItems).length,
      });
      setLoading(false);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    if (!isEmpty(notesList)) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesList]);

  return (
    <div className={classes.container}>
      <ListingTable
        name="unallocatedpayments"
        inlineTitle="Unallocated Payments"
        headers={unallocatedTableHeaders}
        data={tableData}
        loader={loading || reqLoading}
        actions={tableActions}
        dropdownActionsNames={['refund', 'useAsPayment']}
        singleSelection
        inline
      />
    </div>
  );
};

export default UnallocatedPayments;
