import { gql } from '@apollo/client';

export const getReceivablesPaymentsOfBrokerQuery = gql`
  query getReceivablesPaymentsOfBroker($businessPartnerID: String!) {
    Core {
      lookups {
        currencies {
          Id
          Symbol
        }
      }
    }
    Accounting {
      queries {
        GetReceivablesPaymentsOfBroker(BusinessPartnerID: $businessPartnerID) {
          accounting_Payments_Id
          accounting_Payments_PaymentDate
          accounting_Payments_ReferenceNo
          accounting_Payments_PaymentMethod
          accounting_Payments_TotalAmountReceived
          accounting_Payments_PaymentCurrency
          accounting_Payments_createdOn
          accounting_Payments_createdBy
          accounting_Payments_CreatedByName
        }
      }
    }
  }
`;

export const getPaymentReceivablesMutation = gql`
  mutation getPaymentReceivables(
    $selectedCurrency: String
    $selectedPaymentDate: Date
    $currentBusinessPartnerID: String
  ) {
    accounting {
      actions {
        getPaymentReceivables(
          SelectedCurrency: $selectedCurrency
          SelectedPaymentDate: $selectedPaymentDate
          CurrentBusinessPartnerID: $currentBusinessPartnerID
        ) {
          PaymentBill {
            PolicyNumber
            ParentBillType
            BillID
            BillNumber
            BillCurrency
            DueDate
            AmountDue
            AmountOutstanding
            AmountOutstandingCurrency
            PolicyID
          }
        }
      }
    }
  }
`;

export const generateRVTransactionsMutation = gql`
  mutation generateRVTransactions(
    $paymentCurrency: String!
    $paymentDate: Date!
    $totalAmount: Decimal!
    $rVList: [Accounting_RVList!]!
    $currentBusinessPartnerID: String!
  ) {
    accounting {
      actions {
        generateRVTransactions(
          PaymentCurrency: $paymentCurrency
          PaymentDate: $paymentDate
          TotalAmountReceived: $totalAmount
          RVList: $rVList
          CurrentBusinessPartnerID: $currentBusinessPartnerID
        ) {
          PaymentTransactionList {
            AccountCredit
            AccountDebit
            AccountID
            AccountName
            DebitOrCredit
            Description
            SystemCredit
            SystemDebit
            SystemSecondaryCredit
            SystemSecondaryDebit
            TransactionCurrency
          }
        }
      }
    }
  }
`;

export const createRVPaymentMutation = `mutation createRVPayment(
      $paymentDate: Date!
      $paymentCurrency: String!
      $paymentType: String!
      $paymentMethod: Accounting_PaymentMethodList
      $referenceNumber: String!
      $totalAmount: Decimal!
      $totalAmountUnallocated: Decimal!
      $currentBusinessPartnerID: String!
      $totalDebit: Decimal!
      $totalCredit: Decimal!
      $note: String!
      $rvList: [Accounting_RVList!]!
      $transactionList: [Accounting_TransactionList!]!
      $UploadedDocuments: [Upload]
    ) {
      accounting {
        actions {
          createRVPayments(
            PaymentDate: $paymentDate
            PaymentCurrency: $paymentCurrency
            PaymentType: $paymentType
            PaymentMethod: $paymentMethod
            ReferenceNumber: $referenceNumber
            TotalAmountReceived: $totalAmount
            TotalAmountUnallocated: $totalAmountUnallocated
            CurrentBusinessPartnerID: $currentBusinessPartnerID
            TotalDebit: $totalDebit
            TotalCredit: $totalCredit
            Note: $note
            RVList: $rvList
            TransactionList: $transactionList
            UploadedDocuments: $UploadedDocuments
          )
        }
      }
    }`;
