import React, { useMemo } from 'react';
import InformationBox from '../../../../components/information-box/InformationBox';
import { IAbstractRecord } from '../../../../models';
import { InformationDataType } from '../../../../components/information-box';

interface IAmendmentOfferWidgetProps {
  data: IAbstractRecord;
}

const AmendmentOfferWidget: React.FC<IAmendmentOfferWidgetProps> = ({
  data = {},
}) => {
  const currency = data?.PricingOption?.Current?.currencySymbol;

  const tabs = useMemo(() => {
    return [
      {
        title: 'Premium Breakdown',
        name: 'Details',
        data: data.PricingOption || {},
        supportsGrouping: true,
        groupingValues: ['Current', 'Variance', 'New'],
        groupingTitle: null,
        headers: {
          BasicPremium: {
            name: 'BasicPremium',
            title: 'Basic Premium',
            type: InformationDataType.RoundedCurrency,
            headerType: InformationDataType.RoundedCurrency,
            currencySymbol: currency,
          },
          NetPremium: {
            name: 'NetPremium',
            title: 'Net Premium',
            type: InformationDataType.RoundedCurrency,
            currencySymbol: currency,
          },
          PolicyCostAmount: {
            name: 'PolicyCostAmount',
            title: 'Policy Cost',
            type: InformationDataType.RoundedCurrency,
            currencySymbol: currency,
          },
          ChargesAmount: {
            name: 'ChargesAmount',
            title: 'Charges',
            type: InformationDataType.RoundedCurrency,
            currencySymbol: currency,
          },
          TPAFeesTotalAmount: {
            name: 'TPAFeesTotalAmount',
            title: 'TPA Fees',
            type: InformationDataType.RoundedCurrency,
            currencySymbol: currency,
          },
          GrossPremium: {
            name: 'GrossPremium',
            title: 'Gross Premium',
            type: InformationDataType.RoundedCurrency,
            currencySymbol: currency,
          },
          FixedStampAmount: {
            name: 'FixedStampAmount',
            title: 'Fixed Stamp',
            type: InformationDataType.RoundedCurrency,
            currencySymbol: currency,
          },
          ProportionalStampAmount: {
            name: 'ProportionalStampAmount',
            title: 'Proportional Stamp',
            type: InformationDataType.RoundedCurrency,
            currencySymbol: currency,
          },
          MunicipalityTaxAmount: {
            name: 'MunicipalityTaxAmount',
            title: 'Municipality Tax',
            type: InformationDataType.RoundedCurrency,
            currencySymbol: currency,
          },
          TotalPremium: {
            name: 'TotalPremium',
            title: 'Total Premium',
            type: InformationDataType.RoundedCurrency,
            currencySymbol: currency,
          },
        },
        supportCycleDivision: false,
      },
    ];
  }, [data]);

  return (
    <InformationBox
      visibleRows={0}
      title={'Premium Breakdown'}
      status={''}
      statusReason={''}
      subtitle={``}
      statusIcon={''}
      statusColor={''}
      reviewedOn={''}
      currency={currency}
      tabs={tabs}
      collapsible={false}
      initiallyCollapsed={false}
      bodyClasses={{
        container: 'amendment-offer-widget',
      }}
    />
  );
};

export default AmendmentOfferWidget;
