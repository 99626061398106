import React, { useMemo, useRef, useState } from 'react';
import GenericDrawer from '../../../../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../../../../DynamicForm/DynamicForm';
import { useLazyQuery, useMutation } from '@apollo/client';
import { updateFacReinsurerMutation } from '../queries';
import {
  getError,
  lookupListAsRecordObject,
} from '../../../../../utils/graph-utils';
import { updateFacReinsurerInputs } from '../content';
import { toast } from 'react-toastify';
import ToastSuccessMessage from '../../../../../components/ToastSuccessMessage';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import { getReinsurersListQuery } from '../../shared/queries';
import { useParams } from 'react-router-dom';

interface IUpdateFacReinsurerDrawer {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  data: {
    reinsurerId: string;
    name: string;
    share: number;
    companyId: string;
  };
}

interface IValues {
  reinsurer: string;
  share: number;
}

const UpdateFacReinsurerDrawer: React.FC<IUpdateFacReinsurerDrawer> = ({
  isOpen,
  onClose,
  onSuccess,
  data,
}) => {
  const { id: currentFacId } = useParams<{ id: string }>();
  const [getReinsurersListLazy, { loading: reinsurersLoading }] = useLazyQuery(
    getReinsurersListQuery
  );
  const [updateFacReinsurer, { loading }] = useMutation(
    updateFacReinsurerMutation
  );
  const loaded = useRef<boolean>(false);
  const [values, setValues] = useState<IValues>({
    reinsurer: '',
    share: data.share,
  });

  const formInputs = useMemo(() => {
    return updateFacReinsurerInputs({
      reinsurer: values.reinsurer,
      share: values.share,
    });
  }, [values]);

  const handleChange = (
    fieldName: string,
    value: unknown,
    allValues: IValues
  ) => {
    setValues(allValues);
  };

  const handleSubmit = async () => {
    try {
      const res = await updateFacReinsurer({
        variables: {
          CurrentFacID: currentFacId,
          Reinsurer: values.reinsurer,
        },
      });

      if (!res.errors) {
        toast.success(
          <ToastSuccessMessage>
            Reinsurers updated successfully
          </ToastSuccessMessage>
        );

        setTimeout(() => {
          onSuccess();
          onClose();
        }, 500);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
    }
  };

  return (
    <GenericDrawer
      title={'Update Reinsurers'}
      onClose={() => onClose()}
      isOpen={isOpen}
    >
      <DynamicForm
        disableForm={loading}
        isSubmitButtonDisabled={loading}
        title="Reinsurer"
        inputs={formInputs}
        onSubmit={handleSubmit}
        buttonText={'Submit'}
        onChange={handleChange}
        loadingFields={{
          reinsurer: reinsurersLoading,
        }}
        onAutocompleteSearch={(
          fieldName: string,
          value: string,
          pagination
        ) => {
          if (fieldName === 'reinsurer' && data.companyId) {
            return getReinsurersListLazy({
              variables: {
                SelectedCompany: data.companyId,
                KeywordSearch: value,
                pagination,
              },
            }).then((result) => {
              if (!loaded.current) {
                setValues((oldValues) => ({
                  ...oldValues,
                  reinsurer: value || data.reinsurerId,
                }));
                loaded.current = true;
              }
              return {
                options: lookupListAsRecordObject(
                  result.data?.Production?.queries?.ReinsurersList?.items,
                  false,
                  'salesforceManagement_BusinessPartner_Id',
                  'salesforceManagement_BusinessPartner_FullName'
                ),
                totalCount:
                  result.data?.Production?.queries?.getProposalList?.paging
                    ?.totalCount,
              };
            });
          }

          return new Promise<{
            options: Record<string, string>;
            totalCount: number;
          }>((resolve) => {
            resolve({ options: {}, totalCount: 0 });
          });
        }}
      />
    </GenericDrawer>
  );
};

export default UpdateFacReinsurerDrawer;
