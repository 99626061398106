import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  line: '',
  fixedStamp: '',
  fixedStampCurrency: '',
  proportionalStamp: '',
  municipalityTax: '',
  taxOnCommission: '',
  reinsuranceTax: '',
  effectiveFrom: '',
  effectiveTo: '',
};

export const inputs: Record<string, DynamicFormInputType> = {
  line: {
    name: 'line',
    title: 'Line',
    type: FormInputTypes.chips,
    placeholder: 'Line',
    value: !isEmpty(initialValues.line) ? [initialValues.line] : [],
    multiple: false,
    disabled: false,
    required: true,
    hasPagination: false,
  },
  fixedStamp: {
    name: 'fixedStamp',
    title: 'Fixed Stamp',
    placeholder: 'FIxed Stamp',
    type: FormInputTypes.number,
    value: initialValues.fixedStamp,
    required: true,
    disabled: false,
    maxDecimalPrecision: 3,
  },
  fixedStampCurrency: {
    name: 'fixedStampCurrency',
    title: 'Fixed Stamp Currency',
    type: FormInputTypes.chips,
    placeholder: 'Fixed Stamp Currency',
    value: !isEmpty(initialValues.fixedStampCurrency)
      ? [initialValues.fixedStampCurrency]
      : [],
    multiple: false,
    disabled: false,
    required: true,
  },
  proportionalStamp: {
    name: 'proportionalStamp',
    title: 'Proportional Stamp',
    placeholder: 'Proportional Stamp',
    type: FormInputTypes.percentage,
    value: initialValues.proportionalStamp,
    required: true,
  },
  municipalityTax: {
    name: 'municipalityTax',
    title: 'Municipality Tax',
    placeholder: 'Municipality Tax',
    type: FormInputTypes.percentage,
    value: initialValues.municipalityTax,
    required: true,
  },
  taxOnCommission: {
    name: 'taxOnCommission',
    title: 'Tax On Commission',
    placeholder: 'Tax On Commission',
    type: FormInputTypes.percentage,
    value: initialValues.taxOnCommission,
    required: true,
  },
  reinsuranceTax: {
    name: 'reinsuranceTax',
    title: 'Reinsurance Tax',
    placeholder: 'Reinsurance Tax',
    type: FormInputTypes.percentage,
    value: initialValues.reinsuranceTax,
    required: true,
  },
  effectiveFrom: {
    name: 'effectiveFrom',
    title: 'Effective From',
    placeholder: 'Effective From',
    type: FormInputTypes.date,
    value: initialValues.effectiveFrom,
    required: true,
    minDate: new Date(),
  },
  effectiveTo: {
    name: 'effectiveTo',
    title: 'Effective To',
    placeholder: 'Effective To',
    type: FormInputTypes.date,
    value: initialValues.effectiveTo,
    disabled: true,
  },
};
