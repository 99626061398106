import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { IAbstractRecord } from '../../../../../../../models';
import EnhancedPercentageInput from '../../../../../../../components/enhanced-form/EnhancedPercentageInput';
import SelectFormField from '../../../../../../../components/form-fields/SelectFormField';
import TextInputFormField from '../../../../../../../components/form-fields/TextInputFormField';
import DatePickerFormField from '../../../../../../../components/form-fields/DatePickerFormField';
import CurrencyFormField from '../../../../../../../components/form-fields/CurrencyFormField';
import EnhancedButton, {
  EnhancedButtonStatus,
} from '../../../../../../../components/form-fields/buttons/EnhancedButton';
import { useMutation } from '@apollo/client';
import { generateCertificate } from './queries';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../../../components/ToastErrorMessage';
import { getError } from '../../../../../../../utils/graph-utils';
import { isEmpty } from '../../../../../../../utils/validationUtils';
import { mapCertificateOutput } from './utils';
import { trimTrailingZeros } from '../../../../../../../utils/formatting-utils';

interface IPolicyCertficateMarineGroupSummaryShellProps {
  data: {
    values: IAbstractRecord;
    lovs: IAbstractRecord;
    payload: IAbstractRecord;
  };
  onClose: () => void;
  onSuccess: () => void;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(3, 32%)`,
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  labelHeader: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '15px',
    marginTop: '3em',
  },
}));

const PolicyCertficateMarineGroupSummaryShell: React.FC<
  IPolicyCertficateMarineGroupSummaryShellProps
> = ({ data, onClose = () => undefined, onSuccess }) => {
  const { values, lovs, payload } = data;
  const { classes } = useStyles();
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [generateCertificateAction] = useMutation(generateCertificate());

  const submit = () => {
    setSubmitting(true);
    setSubmitButtonState('loading');
    generateCertificateAction({
      variables: {
        entityId: payload.entityId,
        certificateDetailsOutput: mapCertificateOutput(payload),
      },
      errorPolicy: 'all',
    })
      .then((result) => {
        if (isEmpty(result.errors)) {
          setSubmitting(false);
          setSubmitButtonState('success');
          onSuccess();
        } else {
          toast.error(
            <ToastErrorMessage>{getError(result.errors)}</ToastErrorMessage>
          );
          setSubmitting(false);
          setSubmitButtonState(undefined);
        }
      })
      .catch(() => {
        setSubmitting(false);
        setSubmitButtonState(undefined);
      });
  };

  return (
    <>
      <div className={classes.fieldRow}>
        <SelectFormField
          key="subline"
          name="subline"
          title="Subline*"
          value={values.subline}
          disabled={true}
          selectOptions={lovs.subline}
          // material
        />
        <SelectFormField
          key="plan"
          name="plan"
          title="Plan*"
          value={values.plan}
          disabled={true}
          selectOptions={lovs.plan}
        />
        <SelectFormField
          key="matter"
          name="matter"
          title="Matter*"
          value={values.matter}
          disabled={true}
          selectOptions={lovs.matters}
        />

        <TextInputFormField
          key="vesselName"
          name="vesselName"
          title="Vessel Name*"
          value={values.vesselName}
          onChange={() => {}}
          disabled={true}
        />

        <TextInputFormField
          key="vesselAge"
          name="vesselAge"
          title="Vessel Age"
          onChange={() => {}}
          value={values.vesselAge}
          disabled={true}
        />

        <TextInputFormField
          key="from"
          name="from"
          title="Departure From*"
          onChange={() => {}}
          value={values.from}
          disabled={true}
        />

        <TextInputFormField
          key="to"
          name="to"
          title="Destination To*"
          onChange={() => {}}
          value={values.to}
          disabled={true}
        />

        <TextInputFormField
          key="via"
          name="via"
          title="Transportation Via*"
          onChange={() => {}}
          value={values.via}
          disabled={true}
        />

        <TextInputFormField
          key="lcNumber"
          name="lcNumber"
          title="LC Number"
          onChange={() => {}}
          value={values.lcNumber}
          disabled={true}
        />

        <DatePickerFormField
          key="lcIssueDate"
          name="lcIssueDate"
          title="LC Issue Date"
          value={values.lcIssueDate}
          onDateChange={() => {}}
          disabled={true}
        />

        <EnhancedPercentageInput
          key="loading"
          name="loading"
          title="Loading*"
          value={values.loading}
          error={''}
          onBlur={() => {}}
          onChange={() => {}}
          disabled={true}
          // material={true}
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <CurrencyFormField
          key="sumPerShipment"
          name="sumPerShipment"
          title="Sum Per Shipment"
          value={values.sumPerShipment}
          error={''}
          onChange={() => {}}
          disabled={true}
          currencySymbol={values.currency}
        />

        <CurrencyFormField
          key="coverSumInsured"
          name="coverSumInsured"
          title="Sum Insured"
          value={trimTrailingZeros(values.sumInsured) || ''}
          onChange={() => {}}
          disabled={true}
          currencySymbol={values.currency}
        />

        <CurrencyFormField
          key="netPremium"
          name="netPremium"
          title="Net Premium"
          value={trimTrailingZeros(values.netPremium) || ''}
          onChange={() => {}}
          disabled={true}
          currencySymbol={values.currency}
        />

        <CurrencyFormField
          key="policyCostAmount"
          name="policyCostAmount"
          title="Policy Cost"
          value={trimTrailingZeros(values.policyCostAmount) || ''}
          onChange={() => {}}
          disabled={true}
          currencySymbol={values.currency}
        />

        <CurrencyFormField
          key="chargesAmount"
          name="chargesAmount"
          title="Charges"
          value={trimTrailingZeros(values.chargesAmount) || ''}
          onChange={() => {}}
          disabled={true}
          currencySymbol={values.currency}
        />

        <CurrencyFormField
          key="tpaFeesTotalAmount"
          name="tpaFeesTotalAmount"
          title="TPA Fees"
          value={trimTrailingZeros(values.tpaFeesTotalAmount) || ''}
          onChange={() => {}}
          disabled={true}
          currencySymbol={values.currency}
        />

        <CurrencyFormField
          key="grossPremium"
          name="grossPremium"
          title="Gross Premium"
          value={trimTrailingZeros(values.grossPremium) || ''}
          onChange={() => {}}
          disabled={true}
          currencySymbol={values.currency}
        />

        <CurrencyFormField
          key="fixedStampAmount"
          name="fixedStampAmount"
          title="Fixed Stamp"
          value={trimTrailingZeros(values.fixedStampAmount) || ''}
          onChange={() => {}}
          disabled={true}
          currencySymbol={values.currency}
        />

        <CurrencyFormField
          key="proportionalStampAmount"
          name="proportionalStampAmount"
          title="Proportional Stamp"
          value={trimTrailingZeros(values.proportionalStampAmount) || ''}
          onChange={() => {}}
          disabled={true}
          currencySymbol={values.currency}
        />

        <CurrencyFormField
          key="municipalityTaxAmount"
          name="municipalityTaxAmount"
          title="Municipality Tax"
          value={trimTrailingZeros(values.municipalityTaxAmount) || ''}
          onChange={() => {}}
          disabled={true}
          currencySymbol={values.currency}
        />

        <CurrencyFormField
          key="totalPremium"
          name="totalPremium"
          title="Total Premium"
          value={trimTrailingZeros(values.totalPremium) || ''}
          onChange={() => {}}
          disabled={true}
          currencySymbol={values.currency}
        />

        <DatePickerFormField
          key="policyEffectiveDate"
          name="policyEffectiveDate"
          title="Policy Effective Date*"
          value={values.policyEffectiveDate}
          onDateChange={() => {}}
          disabled={true}
        />
      </div>
      <div className={classes.buttonsContainer}>
        <EnhancedButton disabled={submitting} onClick={() => onClose()}>
          Cancel
        </EnhancedButton>
        <EnhancedButton
          state={submitButtonState}
          disabled={submitting}
          isPrimary
          onClick={() => submit()}
        >
          Submit
        </EnhancedButton>
      </div>
    </>
  );
};

export default PolicyCertficateMarineGroupSummaryShell;
