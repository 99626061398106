import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';

export const initialValues = {
  planCurrency: '',
  policyCover: '',
  isMain: false,
  isMandatory: false,
  isEditable: false,
  nbOfAdherent: '',
  class: '',
  coNssf: '',
  level: '',
  ageFrom: '',
  ageTo: '',
  sumInsured: '',
  sumInsuredIsAdditive: false,
  netPremium: '',
  tpaFeesPercentage: '',
  tpaFeesApplicableOn: '',
  minTPAFees: '',
  maxTPAFees: '',
  excessOnClaimType: '',
  excessOnClaimAmount: '',
  excessOnClaimPercentage: '',
  excessOnClaimDays: '',
  clauseReference: '',
  tpaType: '',
  tpaAmount: '',
};

export const getInputs = (
  values: typeof initialValues = initialValues,
  lovs: Record<string, Record<string, any>> = {},
  planCurrency: string,
  planCurrencySymbol: string
): Record<string, DynamicFormInputType> => {
  return {
    planCurrency: {
      name: 'planCurrency',
      title: 'Plan Currency',
      type: FormInputTypes.chips,
      placeholder: 'Plan Currency',
      value: values.planCurrency || planCurrency,
      multiple: false,
      disabled: true,
      required: true,
      selectOptions: lovs?.currencies || {},
      hidden: true,
    },
    policyCover: {
      name: 'policyCover',
      title: 'Policy Cover',
      type: FormInputTypes.chips,
      placeholder: 'Type to search for Policy Cover',
      value: values.policyCover || '',
      multiple: false,
      disabled: false,
      required: true,
      selectOptions: lovs?.policyCover || {},
    },
    isMain: {
      name: 'isMain',
      title: 'Main',
      type: FormInputTypes.switch,
      value: values.isMain,
      required: true,
    },
    isMandatory: {
      name: 'isMandatory',
      title: 'Mandatory',
      type: FormInputTypes.switch,
      value: values.isMandatory,
      required: true,
    },
    isEditable: {
      name: 'isEditable',
      title: 'Editable',
      type: FormInputTypes.switch,
      value: values.isEditable,
      required: true,
    },
    nbOfAdherent: {
      name: 'nbOfAdherent',
      title: 'Nb Of Adherent',
      placeholder: 'Nb Of Adherent',
      type: FormInputTypes.number,
      value: values.nbOfAdherent,
      required: true,
      minNumber: 0,
    },
    class: {
      name: 'class',
      title: 'Class',
      type: FormInputTypes.chips,
      placeholder: 'Class',
      value: values.class,
      multiple: false,
      disabled: false,
      required: true,
      selectOptions: lovs.medicalPlanClasses,
    },
    coNssf: {
      name: 'coNssf',
      title: 'Co-Nssf',
      type: FormInputTypes.chips,
      placeholder: 'Co-Nssf',
      value: values.coNssf,
      multiple: false,
      disabled: false,
      required: true,
      selectOptions: lovs?.coNSSFOptions || {},
    },
    level: {
      name: 'level',
      title: 'Level',
      type: FormInputTypes.chips,
      placeholder: 'Level',
      value: values.level,
      multiple: false,
      required: true,
      selectOptions: lovs.medicalLevels || {},
    },
    ageFrom: {
      name: 'ageFrom',
      title: 'Age From',
      placeholder: 'Age From',
      type: FormInputTypes.number,
      value: values.ageFrom,
      required: true,
      minNumber: 0,
    },
    ageTo: {
      name: 'ageTo',
      title: 'Age To',
      placeholder: 'Age To',
      type: FormInputTypes.number,
      value: values.ageTo,
      required: true,
    },
    sumInsured: {
      name: 'sumInsured',
      title: 'SumInsured',
      placeholder: 'SumInsured',
      type: FormInputTypes.currency,
      value: values.sumInsured,
      required: true,
      minNumber: 0,
      maxDecimalPercision: 3,
      useCurrencySymbol: true,
      currencySymbol: planCurrencySymbol,
    },
    sumInsuredIsAdditive: {
      name: 'sumInsuredIsAdditive',
      title: 'Additive SumInsured',
      type: FormInputTypes.switch,
      value: values.sumInsuredIsAdditive,
      required: true,
    },
    netPremium: {
      name: 'netPremium',
      title: 'Net Premium',
      placeholder: 'Net Premium',
      type: FormInputTypes.currency,
      value: values.netPremium,
      required: true,
      minNumber: 0,
      maxDecimalPercision: 3,
      useCurrencySymbol: true,
      currencySymbol: planCurrencySymbol,
    },
    tpaType: {
      name: 'tpaType',
      title: 'TPA Type',
      type: FormInputTypes.chips,
      placeholder: 'TPA Type',
      value: values.tpaType,
      multiple: false,
      required: true,
      selectOptions: {
        PERCENTAGE: 'Percentage',
        AMOUNT: 'Amount',
      },
    },
    tpaAmount: {
      name: 'tpaAmount',
      title: 'TPA Amount',
      placeholder: 'TPA Amount',
      type: FormInputTypes.currency,
      value: values.tpaAmount,
      required: values.tpaType === 'AMOUNT',
      minNumber: 0,
      maxDecimalPercision: 3,
      useCurrencySymbol: true,
      currencySymbol: planCurrencySymbol,
      hidden: values.tpaType !== 'AMOUNT',
    },
    tpaFeesPercentage: {
      name: 'tpaFeesPercentage',
      title: 'TPA Fees Percentage',
      placeholder: 'TPA Fees Percentage',
      type: FormInputTypes.percentage,
      value: values.tpaFeesPercentage,
      required: values.tpaType === 'PERCENTAGE',
      hidden: values.tpaType !== 'PERCENTAGE',
    },
    tpaFeesApplicableOn: {
      name: 'tpaFeesApplicableOn',
      title: 'TPA Fees Applicable On',
      type: FormInputTypes.chips,
      placeholder: 'TPA Fees ApplicableOn',
      value: values.tpaFeesApplicableOn || '',
      multiple: false,
      disabled: false,
      required: values.tpaType === 'PERCENTAGE',
      selectOptions: {
        NET_PREMIUM: 'Net Premium',
        GROSS_PREMIUM: 'Gross Premium',
      },
      hidden: values.tpaType !== 'PERCENTAGE',
    },
    minTPAFees: {
      name: 'minTPAFees',
      title: 'Min TPA Fees',
      placeholder: 'Min TPA Fees',
      type: FormInputTypes.currency,
      value: values.minTPAFees,
      required: values.tpaType === 'PERCENTAGE',
      minNumber: 0,
      maxDecimalPercision: 3,
      useCurrencySymbol: true,
      currencySymbol: planCurrencySymbol,
      hidden: values.tpaType !== 'PERCENTAGE',
    },
    maxTPAFees: {
      name: 'maxTPAFees',
      title: 'Max TPA Fees',
      placeholder: 'Max TPA Fees',
      type: FormInputTypes.currency,
      value: values.maxTPAFees,
      required: values.tpaType === 'PERCENTAGE',
      minNumber: 0,
      maxDecimalPercision: 3,
      useCurrencySymbol: true,
      currencySymbol: planCurrencySymbol,
      hidden: values.tpaType !== 'PERCENTAGE',
    },
    excessOnClaimType: {
      name: 'excessOnClaimType',
      title: 'Excess On Claim Type',
      placeholder: 'Excess On Claim Type',
      type: FormInputTypes.chips,
      value: values.excessOnClaimType,
      multiple: false,
      required: true,
      selectOptions: {
        PERCENTAGE: 'Percentage',
        VALUE: 'Value',
        DAYS: 'Days',
      },
    },
    excessOnClaimAmount: {
      name: 'excessOnClaimAmount',
      title: 'Excess On Claim Amount',
      placeholder: 'Excess On Claim Amount',
      type: FormInputTypes.currency,
      value: values.excessOnClaimAmount,
      required: values.excessOnClaimType === 'VALUE',
      minNumber: 0,
      maxDecimalPercision: 3,
      useCurrencySymbol: true,
      currencySymbol: planCurrencySymbol,
      hidden: values.excessOnClaimType !== 'VALUE',
    },
    excessOnClaimPercentage: {
      name: 'excessOnClaimPercentage',
      title: 'Excess On Claim (Percentage)',
      placeholder: 'Excess On Claim (Percentage)',
      type: FormInputTypes.percentage,
      value: values.excessOnClaimPercentage,
      required: values.excessOnClaimType === 'PERCENTAGE',
      hidden: values.excessOnClaimType !== 'PERCENTAGE',
    },
    excessOnClaimDays: {
      name: 'excessOnClaimDays',
      title: 'Excess On Claim (Days)',
      placeholder: 'Excess On Claim (Days)',
      type: FormInputTypes.number,
      value: values.excessOnClaimDays,
      required: values.excessOnClaimType === 'DAYS',
      hidden: values.excessOnClaimType !== 'DAYS',
      minNumber: 0,
    },
    clauseReference: {
      name: 'clauseReference',
      title: 'Clause Reference',
      placeholder: 'Clause Reference',
      type: FormInputTypes.chips,
      value: values.clauseReference,
      required: false,
      hasPagination: false,
      itemsPerPage: 15,
      multiple: false,
      order: 15,
      selectOptions: lovs?.clauseReference || {},
    },
  };
};
