import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../../../components/form-fields/buttons/EnhancedButton';
import GenericDrawer from '../../../../components/common/generic-drawer/GenericDrawer';
import Loader from '../../../../components/Loader';
import ToastErrorMessage from '../../../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../../../constants';
import { DynamicFormInputType, FormInputTypes } from '../../../../DynamicForm';
import DynamicForm from '../../../../DynamicForm/DynamicForm';
import { useLazyQuery, useQuery } from '@apollo/client';
import { exportTransactions } from './queries';
import { IAbstractRecord } from '../../../../models';
import { excelHeaders } from './content';
import { downloadExcelFile } from '../../../../components/custom/new-download-json-excel-service';
import { mapItemsToExcelData } from './utils';
import { getEnums } from '../../../../forms/transaction-popup/queries';
import {
  getSystemCurrency,
  getSystemSecondaryCurrency,
} from '../../../../forms/transaction-popup/utils';
import ToastSuccessMessage from '../../../../components/ToastSuccessMessage';
import { formatDate } from '../../../../utils/formatting-utils';

interface IExportTransactionsDrawerProps {
  onClose: () => void;
  onSuccess: (amendmentId: string) => void;
  open: boolean;
  data: {
    relatedCompany: Record<string, string>;
  };
}

const ExportTransactionsDrawer: React.FC<IExportTransactionsDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  data,
}) => {
  const { data: enums } = useQuery(getEnums());
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [exportTransactionsQuery] = useLazyQuery(exportTransactions());

  const inputsForm = useMemo((): Record<string, DynamicFormInputType> => {
    return {
      company: {
        name: 'company',
        title: 'Company',
        type: FormInputTypes.newselect,
        value: '',
        required: true,
        selectOptions: data.relatedCompany,
      },
      transactiondate: {
        name: 'transactiondate',
        title: 'Transaction Date',
        type: FormInputTypes.newdaterange,
        value: [],
      },
    };
  }, [data]);

  const submitForm = async (values: IAbstractRecord) => {
    setFormDisabled(true);
    setSubmitButtonState('loading');

    let fileName = 'Transactions';

    if (values.transactiondate?.length > 0) {
      fileName += ` - ${formatDate(values.transactiondate[0])} ${formatDate(values.transactiondate[1])}`;
    }
    exportTransactionsQuery({
      variables: {
        SelectedCompany: values.company,
        TransactionDateFrom: values.transactiondate?.[0] || null,
        TransactionDateTo: values.transactiondate?.[1] || null,
      },
    })
      .then(({ data }) => {
        const items: IAbstractRecord[] =
          data?.Accounting.queries.GetTransactionByCompanyAndDate;
        if (items.length === 0) {
          setSubmitButtonState(undefined);
          toast.error(
            <ToastErrorMessage>
              No transactions found for the selected Company and Transaction
              Date
            </ToastErrorMessage>
          );
        } else {
          downloadExcelFile({
            data: mapItemsToExcelData(items),
            filename: fileName,
            headers: excelHeaders(
              getSystemCurrency(enums),
              getSystemSecondaryCurrency(enums)
            ),
          });
          toast.success(
            <ToastSuccessMessage>
              Transaction successfully exported
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState('success');
            onSuccess('');
            onClose();
          }, 500);
        }
      })
      .catch(() => {
        setFormDisabled(false);
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      })
      .finally(() => {
        setFormDisabled(false);
      });
  };

  return (
    <GenericDrawer
      title={'Export Transactions'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(v) => submitForm(v)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default ExportTransactionsDrawer;
