import React from 'react';
import WidgetPaper from '../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../components/common/WidgetSection';
import _ from 'lodash';
import EnhancedPercentageInput from '../../../../components/enhanced-form/EnhancedPercentageInput';
import { makeStyles } from 'tss-react/mui';
import { ILineAmendmentProps } from '../line-amendment';
import { IAmendmentCostCharges, IAmendmentPageState } from '..';
import { validateCostWidgetField } from './validation';
import CurrencyFormField from '../../../../components/form-fields/CurrencyFormField';

interface ICostsAndCharges {
  generalData: ILineAmendmentProps['generalData'];
  pageState: IAmendmentPageState;
  onPageStateUpdate: (pageState: IAmendmentPageState) => void;
  isDisabled?: boolean;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(3, 32%)`,
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  labelHeader: {
    margin: '8.5px 0 0 0 !important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
}));

const CostsAndCharges: React.FC<ICostsAndCharges> = ({
  generalData,
  pageState,
  onPageStateUpdate,
  isDisabled,
}) => {
  const { classes } = useStyles();

  const values = pageState.values.costs;
  const errors = pageState.errors.costs;
  const touched = pageState.touched.costs;

  const disabledRow = isDisabled;

  const onFieldBlur = (fieldName: string) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.costs[fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IAmendmentCostCharges,
    value: IAmendmentCostCharges[keyof IAmendmentCostCharges],
    touched = false
  ) => {
    const newPageState = _.cloneDeep(pageState);

    (newPageState.values.costs as any)[fieldName] = value;
    newPageState.errors.costs[fieldName] = validateCostWidgetField(
      fieldName,
      value,
      newPageState.values.costs,
      generalData.lineExternalCode
    );

    if (fieldName === 'tpaFeesType') {
      newPageState.values.costs.tpaFeesPercentage = null;
      newPageState.values.costs.tpaFeesAmount = null;

      newPageState.errors.costs.tpaFeesPercentage = '';
      newPageState.errors.costs.tpaFeesAmount = '';

      newPageState.touched.costs.tpaFeesPercentage = false;
      newPageState.touched.costs.tpaFeesAmount = false;
    }

    if (touched) {
      newPageState.touched.costs[fieldName] = true;
    }

    onPageStateUpdate(newPageState);
  };

  return (
    <WidgetPaper>
      <WidgetSection title="Costs and Charges" isFieldsGrid fieldsPerRow={3}>
        <CurrencyFormField
          key="amendmentCost"
          name="amendmentCost"
          title="Amendment Cost*"
          placeholder="Policy Cost"
          value={values.amendmentCost}
          error={touched.amendmentCost ? errors.amendmentCost : ''}
          onBlur={() => onFieldBlur('amendmentCost')}
          onChange={(v) => onFieldUpdate('amendmentCost', v.target.value)}
          disabled={disabledRow}
          material
          currencySymbol={generalData.currency.Symbol}
        />

        <EnhancedPercentageInput
          key="chargesPercentage"
          name="chargesPercentage"
          title="Charges Percentage Variance*"
          placeholder="Charges Percentage Variance"
          value={values.chargesPercentage}
          error={touched.chargesPercentage ? errors.chargesPercentage : ''}
          onBlur={() => onFieldBlur('chargesPercentage')}
          onChange={(v) => onFieldUpdate('chargesPercentage', v.target.value)}
          disabled={disabledRow}
          material
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />
        {values.tpaFeesType === 'PERCENTAGE' ? (
          <EnhancedPercentageInput
            key="tpaFeesPercentage"
            name="tpaFeesPercentage"
            title="TPA Fees Percentage Variance*"
            placeholder="TPA Fees Percentage Variance"
            value={values.tpaFeesPercentage}
            error={touched.tpaFeesPercentage ? errors.tpaFeesPercentage : ''}
            onBlur={() => onFieldBlur('tpaFeesPercentage')}
            onChange={(v) => onFieldUpdate('tpaFeesPercentage', v.target.value)}
            disabled={disabledRow}
            material
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />
        ) : (
          <CurrencyFormField
            key="tpaFeesAmount"
            name="tpaFeesAmount"
            title="TPA Fees Variance*"
            placeholder="TPA Fees Variance"
            value={values.tpaFeesAmount}
            error={touched.tpaFeesAmount ? errors.tpaFeesAmount : ''}
            onBlur={() => onFieldBlur('tpaFeesAmount')}
            onChange={(v) => onFieldUpdate('tpaFeesAmount', v.target.value)}
            disabled={disabledRow}
            material
            currencySymbol={generalData.currency.Symbol}
          />
        )}
      </WidgetSection>
    </WidgetPaper>
  );
};

export default CostsAndCharges;
