import { FormInputTypes } from '../../../DynamicForm';
import {
  EnhancedHeaderInputTypes,
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../../models';

export const initialHeaders: Record<string, EnhancedTableHeader> = {
  link: {
    name: 'link',
    title: 'Link',
    type: EnhancedTableHeaderType.Text,
  },
  line: {
    name: 'line',
    title: 'Line',
    type: EnhancedTableHeaderType.Text,
  },
  account: {
    name: 'account',
    title: 'Account (Auxillary)*',
    type: EnhancedTableHeaderType.Input,
    inputType: EnhancedHeaderInputTypes.Select,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        company: {
          name: 'company',
          title: 'Company',
          type: FormInputTypes.newselect,
          value: filterValues?.company || '',
          placeholder: 'Company',
          triggerUpdate: true,
          selectOptions: { ...lovs.relatedCompanies },
        },
        type: {
          name: 'type',
          title: 'Type',
          type: FormInputTypes.newselect,
          value: filterValues?.type || '',
          placeholder: '',
          triggerUpdate: true,
          selectOptions: { ...lovs.transferTypes },
        },
        link: {
          name: 'link',
          title: 'Link',
          type: FormInputTypes.newmultiselect,
          value: filterValues?.link || [],
          placeholder: '',
          triggerUpdate: true,
          selectOptions: { ...lovs.transferLinks },
          maxTagCount: 5,
        },
      },
    },
  ];
  return newFilterSections;
}
