import Tooltip from '@mui/material/Tooltip';
import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { IListingFilterWidgetSection } from '.';
import { DynamicFormInputType, FormInputTypes } from '../../../DynamicForm';
import {
  MAIN_ONE_THEME,
  contentFontFamilyBold,
  contentFontFamilyRegular,
  mainFontFamilyRegular,
} from '../../../constants';
import { useAppSelector } from '../../../redux/hooks';
import EnhancedChipInput from '../../enhanced-form/EnhancedChipInput';
import EnhancedDatePicker from '../../enhanced-form/EnhancedDatePicker';
import EnhancedDateRangePicker from '../../enhanced-form/EnhancedDateRangePicker';
import EnhancedInput from '../../enhanced-form/EnhancedInput';
import EnhancedInternationalPhoneInput from '../../enhanced-form/EnhancedInternationalPhoneInput';
import EnhancedNumberInput from '../../enhanced-form/EnhancedNumberInput';
import EnhancedSwitch from '../../enhanced-form/EnhancedSwitch';
import EnhancedPercentageInput from '../../enhanced-form/EnhancedPercentageInput';
import SelectFormField from '../../form-fields/SelectFormField';
import DateRangeFormField from '../../form-fields/DateRangeFormField';
import MultiselectFormField from '../../form-fields/MultiselectFormField';
import AutocompleteFormField from '../../form-fields/AutocompleteFormField';
import MultiAutoCompleteFormField from '../../form-fields/MultiAutocompleteFormField';
import DatePickerFormField from '../../form-fields/DatePickerFormField';

export interface IListingFilterWidgetProps {
  name: string;
  filters: IListingFilterWidgetSection[];
  onApplyFilter: (values: Record<string, any>, inputName: string) => void;
  onClearFilter?: () => void;
  disabled?: boolean;
  onAutocompleteSearch?: (
    fieldName: string,
    value: string,
    pagination: { pageNumber: number; pageSize: number }
  ) => Promise<{ options: Record<string, string>; totalCount: number }>;
  initialMultiAutocompleteSearch?: (
    fieldName: string,
    values: string[],
    pagination: { pageNumber: number; pageSize: number }
  ) => Promise<{ options: Record<string, string>; totalCount: number }>;
}

const useStyles = makeStyles()(() => ({
  title: {
    display: 'block',
    textAlign: 'left',
    fontSize: MAIN_ONE_THEME.typography.medium.med2.fontSize,
    fontFamily: mainFontFamilyRegular,
    letterSpacing: 0,
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontWeight: 'normal',
    marginLeft: '5px',
  },
  container: {
    padding: '30px 20px 20px;',
  },
  sectionTitle: {
    fontSize: MAIN_ONE_THEME.typography.medium.med2.fontSize,
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontFamily: contentFontFamilyBold,
    textAlign: 'left',
    display: 'block',
    width: '100%',
  },
  sectionMessage: {
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    padding: '5px 0 10px',
    color: 'grey',
    fontStyle: 'italic',
    fontFamily: contentFontFamilyRegular,
    textAlign: 'left',
    display: 'block',
    width: '100%',
  },
  clearFilterBtn: {
    display: 'flex',
    justifyContent: 'center',
  },
  sectionTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    marginBottom: '10px',
    borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
  },
  clearFilterIconButton: {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
}));

const ListingFilterWidget: React.FC<IListingFilterWidgetProps> = ({
  filters,
  disabled,
  onClearFilter,
  onApplyFilter,
  onAutocompleteSearch,
  initialMultiAutocompleteSearch,
}) => {
  const tenant = useAppSelector((state) => state.tenant);
  const [values, setValues] = useState<Record<string, any>>();
  const [touched, setTouched] = useState<Record<string, boolean>>();
  // const [focused, setFocused] = useState<Record<string, boolean>>({});

  const { classes } = useStyles();

  useEffect(() => {
    const seperateInputs = filters.map((i) => i.inputs);
    let inputs: Record<string, DynamicFormInputType> = {};

    seperateInputs.forEach((input) => {
      inputs = { ...inputs, ...input };
    });
    const initialValues: Record<string, any> = {};
    const initialTouched: Record<string, boolean> = {};
    const initialFocused: Record<string, boolean> = {};
    Object.values(inputs).forEach((input) => {
      initialValues[input.name] = input.value;
      initialTouched[input.name] = !!input.error;
      initialFocused[input.name] = false;
    });
    setValues(initialValues);
    setTouched(initialTouched);
    // setFocused(initialFocused);
  }, [filters]);

  const onFieldBlur = (name: string, extraValue?: string) => {
    const touchedTemp = Object.assign({}, touched);
    touchedTemp[name] = true;
    setTouched(touchedTemp);

    const seperateInputs = filters.map((i) => i.inputs);
    let inputs: Record<string, DynamicFormInputType> = {};

    seperateInputs.forEach((input) => {
      inputs = { ...inputs, ...input };
    });

    switch (inputs[name].type) {
      case FormInputTypes.multiSelect:
      case FormInputTypes.chips:
        if (extraValue) {
          const valuesTemp = Object.assign({}, values);
          valuesTemp[name].push(extraValue);
          setValues(valuesTemp);
        }
      // eslint-disable-next-line no-fallthrough
      case FormInputTypes.text:
      case FormInputTypes.phoneNumber:
      case FormInputTypes.number:
      case FormInputTypes.newmultiselect:
      case FormInputTypes.newselect:
      case FormInputTypes.newautocomplete:
      case FormInputTypes.newmultiautocomplete:
        onApplyFilter(values, inputs[name].name);
    }
  };

  // const onFieldFocus = (name: string) => {
  //   let focusedTemp = Object.assign({}, focused);
  //   focusedTemp[name] = true;
  //   setFocused(focusedTemp);
  // };

  const onFieldChange = (name: string, value: any) => {
    const seperateInputs = filters.map((i) => i.inputs);
    let inputs: Record<string, DynamicFormInputType> = {};

    seperateInputs.forEach((input) => {
      inputs = { ...inputs, ...input };
    });

    const valuesTemp = Object.assign({}, values);
    valuesTemp[name] = value;
    setValues(valuesTemp);

    switch (inputs[name].type) {
      case FormInputTypes.select:
      case FormInputTypes.date:
      case FormInputTypes.newdate:
      case FormInputTypes.switch:
      case FormInputTypes.daterange:
      case FormInputTypes.newdaterange:
      case FormInputTypes.newmultiselect:
      case FormInputTypes.newmultiautocomplete:
        applyFilter(valuesTemp, inputs[name].name);
    }
  };

  const applyFilter = (v: Record<string, any>, inputName: string) => {
    const seperateInputs = filters.map((i) => i.inputs);
    let inputs: Record<string, DynamicFormInputType> = {};

    seperateInputs.forEach((input) => {
      inputs = { ...inputs, ...input };
    });
    const initialTouched: Record<string, boolean> = {};
    Object.values(inputs).forEach((input) => {
      initialTouched[input.name] = !!input.error;
    });
    setTouched(initialTouched);

    onApplyFilter(v, inputName);
  };

  const onFieldChangeByEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFieldChange(event.target.name, event.target.value);
  };

  const renderInput = (input: DynamicFormInputType) => {
    switch (input.type) {
      case FormInputTypes.text:
        return (
          <EnhancedInput
            key={input.name}
            name={input.name}
            title={input.title}
            type="text"
            placeholder={input.placeholder}
            value={values[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChange(input.name, v.target.value)}
            disabled={input.disabled || disabled}
            description={input.description}
          />
        );

      case FormInputTypes.password:
        return (
          <EnhancedInput
            key={input.name}
            name={input.name}
            title={input.title}
            type="password"
            placeholder={input.placeholder}
            value={values[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChange(input.name, v.target.value)}
            disabled={input.disabled || disabled}
            material={input.material}
            description={input.description}
          />
        );

      case FormInputTypes.date:
        return (
          <EnhancedDatePicker
            key={input.name}
            name={input.name}
            title={input.title}
            value={values[input.name]}
            onBlur={() => onFieldBlur(input.name)}
            onDateChange={(v) => onFieldChange(input.name, v)}
            disabled={input.disabled || disabled}
            material={input.material}
            minDate={input.minDate}
            maxDate={input.maxDate}
            format={tenant.dateFormat}
          />
        );
      case FormInputTypes.daterange:
        return (
          <EnhancedDateRangePicker
            key={input.name}
            name={input.name}
            title={input.title}
            value={values[input.name]}
            // onBlur={() => onFieldBlur(input.name)}
            onDateRangeChange={(v) => onFieldChange(input.name, v)}
            disabled={input.disabled || disabled}
            // material={input.material}
            minDate={input.minDate}
            maxDate={input.maxDate}
          />
        );
      case FormInputTypes.imageFile:
        // return (
        // <GenericUploader
        //   key={input.name}
        //   name={input.name}
        //   title={input.title}
        //   value={values[input.name]}
        //   error={touched[input.name] ? errors[input.name] : ''}
        //   onBlur={(v) => onFieldBlur(input.name)}
        //   onUpload={(v) => onFieldChange(input.name, v)}
        //   disabled={input.disabled || disabled}
        //   customStyles={input.customStyles}
        //   allowedFileTypes={input.allowedFileTypes}
        //   // required={input.required}
        // />
        return <></>;
      // );

      case FormInputTypes.select:
        return (
          <EnhancedChipInput
            key={input.name}
            name={input.name}
            title={input.title}
            placeholder={input.placeholder}
            value={values[input.name]}
            onChange={(v) => onFieldChange(input.name, v)}
            onBlur={(extraValue) => onFieldBlur(input.name, extraValue)}
            disabled={input.disabled || disabled}
            customStyles={input.customStyles}
            selectOptions={input.selectOptions}
            required={input.required}
            freeSolo={false}
            multiple={false}
            error=""
          />
        );
      case FormInputTypes.number:
        return (
          <EnhancedNumberInput
            key={input.name}
            name={input.name}
            title={input.title}
            placeholder={input.placeholder}
            value={values[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChangeByEvent(v)}
            disabled={input.disabled || disabled}
            material={input.material}
            minValue={input.minNumber}
            maxValue={input.maxNumber}
          />
        );
      case FormInputTypes.percentage:
        return (
          <EnhancedPercentageInput
            key={input.name}
            name={input.name}
            title={input.title}
            placeholder={input.placeholder}
            value={values[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChangeByEvent(v)}
            disabled={input.disabled || disabled}
            material={input.material}
          />
        );
      case FormInputTypes.switch:
        return (
          <EnhancedSwitch
            key={input.name}
            name={input.name}
            title={input.title}
            value={values[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChange(input.name, v)}
            disabled={input.disabled || disabled}
            required={input.required}
          />
        );
      case FormInputTypes.multiSelect:
        return <span>deprecated</span>;
      case FormInputTypes.chips:
        return (
          <EnhancedChipInput
            key={input.name}
            name={input.name}
            title={input.title}
            placeholder={input.placeholder}
            value={values[input.name]}
            onChange={(v) => onFieldChange(input.name, v)}
            onBlur={(extraValue) => onFieldBlur(input.name, extraValue)}
            disabled={input.disabled || disabled}
            customStyles={input.customStyles}
            selectOptions={input.selectOptions}
            required={input.required}
            freeSolo={input.freeSolo}
            error=""
            onBlurSaveNewData={input.onBlurSaveNewData}
            material={input.material}
            limitTags={2}
            multiple={input.multiple}
            canClearSingleValueSelection={input.canClearSingleValueSelection}
          />
        );
      case FormInputTypes.newselect:
        return (
          <SelectFormField
            key={input.name}
            name={input.name}
            title={input.title}
            placeholder={input.placeholder}
            value={values[input.name] || ''}
            onChange={(v) => onFieldChange(input.name, v)}
            onBlur={(extraValue) => onFieldBlur(input.name, extraValue)}
            disabled={input.disabled || disabled}
            selectOptions={input.selectOptions}
            freeSolo={input.freeSolo}
            material={input.material}
            canClearValue={input.canClearValue}
          />
        );
      case FormInputTypes.newmultiselect:
        return (
          <MultiselectFormField
            key={input.name}
            name={input.name}
            title={input.title}
            placeholder={input.placeholder}
            values={values[input.name]}
            onChange={(v) => onFieldChange(input.name, v)}
            onBlur={() => onFieldBlur(input.name)}
            disabled={input.disabled || disabled}
            selectOptions={input.selectOptions}
            maxTagCount={input.maxTagCount}
            freeSolo={input.freeSolo}
            material={input.material}
          />
        );
      case FormInputTypes.newautocomplete:
        return (
          <AutocompleteFormField
            key={input.name}
            name={input.name}
            title={input.title}
            placeholder={input.placeholder}
            value={values[input.name]}
            disabled={input.disabled || disabled}
            material={input.material}
            maxItemsDisplayed={input.maxItemsDisplayed}
            onChange={(v) => {
              onFieldChange(input.name, v);
            }}
            onBlur={(v) => onFieldBlur(input.name, v)}
            canClearValue={input.canClearValue}
            fetchOptions={function (
              query: string,
              pagination: { pageNumber: number; pageSize: number }
            ): Promise<{
              options: Record<string, string>;
              totalCount: number;
            }> {
              if (onAutocompleteSearch) {
                return onAutocompleteSearch(input.name, query, pagination);
              }

              return new Promise<{
                options: Record<string, string>;
                totalCount: number;
              }>((resolve) => {
                resolve({ options: {}, totalCount: 0 });
              });
            }}
          />
        );
      case FormInputTypes.newdate:
        return (
          <DatePickerFormField
            key={input.name}
            name={input.name}
            title={input.title}
            value={values[input.name]}
            onBlur={() => onFieldBlur(input.name)}
            onDateChange={(v) => onFieldChange(input.name, v)}
            disabled={input.disabled || disabled}
            material={input.material}
            minDate={input.minDate}
            maxDate={input.maxDate}
            format={tenant.dateFormat}
          />
        );
      case FormInputTypes.newmultiautocomplete:
        return (
          <MultiAutoCompleteFormField
            key={input.name}
            name={input.name}
            title={input.title}
            placeholder={input.placeholder}
            value={values[input.name]}
            disabled={input.disabled || disabled}
            material={input.material}
            onChange={(v) => {
              onFieldChange(input.name, v);
            }}
            onBlur={() => onFieldBlur(input.name)}
            canClearValue={input.canClearValue}
            fetchOptions={function (
              query: string,
              pagination: { pageNumber: number; pageSize: number }
            ): Promise<{
              options: Record<string, string>;
              totalCount: number;
            }> {
              if (onAutocompleteSearch) {
                return onAutocompleteSearch(input.name, query, pagination);
              }

              return new Promise<{
                options: Record<string, string>;
                totalCount: number;
              }>((resolve) => {
                resolve({ options: {}, totalCount: 0 });
              });
            }}
            fetchOptionsByValues={function (
              values: string[],
              pagination: { pageNumber: number; pageSize: number }
            ): Promise<{
              options: Record<string, string>;
              totalCount: number;
            }> {
              if (initialMultiAutocompleteSearch) {
                return initialMultiAutocompleteSearch(
                  input.name,
                  values,
                  pagination
                );
              }
              return new Promise<{
                options: Record<string, string>;
                totalCount: number;
              }>((resolve) => {
                resolve({ options: {}, totalCount: 0 });
              });
            }}
          />
        );
      case FormInputTypes.newdaterange:
        return (
          <DateRangeFormField
            key={input.name}
            name={input.name}
            title={input.title}
            value={values[input.name]}
            onDateRangeChange={(v) => onFieldChange(input.name, v)}
            disabled={input.disabled || disabled}
            minDate={input.minDate}
            maxDate={input.maxDate}
          />
        );
      case FormInputTypes.phoneNumber:
        return (
          <EnhancedInternationalPhoneInput
            key={input.name}
            name={input.name}
            title={input.title}
            placeholder={input.placeholder}
            value={values[input.name]}
            onChange={(v) => onFieldChange(input.name, v)}
            onBlur={(v) => onFieldBlur(input.name)}
            disabled={input.disabled || disabled}
            material={input.material}
          />
        );
      case FormInputTypes.fileuploader:
      default:
        return <></>;
    }
  };

  return (
    <div className={classes.container}>
      {/* {onClearFilter && (
        <div className={classes.clearFilterBtn}>
          <EnhancedButton
            color="#fff"
            onClick={() => onClearFilter()}
            backgroundColor="#353535"
          >
            Clear Filter
          </EnhancedButton>
        </div>
      )} */}

      {values &&
        touched &&
        filters.map((filter) => {
          return (
            <Fragment key={filter.title}>
              <div className={classes.sectionTitleContainer}>
                <span className={classes.sectionTitle}>{filter.title}</span>
                {filter.clearAbility && (
                  <Tooltip title={'Clear filter'}>
                    <button
                      className={classes.clearFilterIconButton}
                      onClick={() => onClearFilter()}
                    >
                      <svg
                        style={{ width: '24px', height: '24px' }}
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#353535"
                          d="M14.73,20.83L17.58,18L14.73,15.17L16.15,13.76L19,16.57L21.8,13.76L23.22,15.17L20.41,18L23.22,20.83L21.8,22.24L19,19.4L16.15,22.24L14.73,20.83M13,19.88C13.04,20.18 12.94,20.5 12.71,20.71C12.32,21.1 11.69,21.1 11.3,20.71L7.29,16.7C7.06,16.47 6.96,16.16 7,15.87V10.75L2.21,4.62C1.87,4.19 1.95,3.56 2.38,3.22C2.57,3.08 2.78,3 3,3V3H17V3C17.22,3 17.43,3.08 17.62,3.22C18.05,3.56 18.13,4.19 17.79,4.62L13,10.75V19.88M5.04,5L9,10.06V15.58L11,17.58V10.05L14.96,5H5.04Z"
                        />
                      </svg>
                    </button>
                  </Tooltip>
                )}
              </div>
              {Object.values(filter.inputs).map((input) => renderInput(input))}
            </Fragment>
          );
        })}
    </div>
  );
};

export default ListingFilterWidget;
