import React, { useEffect, useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import {
  ISalesForceBusinessUserDrawerInfo,
  ISalesForceBusinessUserDrawerProps,
} from '.';
import {
  getListForm,
  checkBusinessUserByPerson,
  createBusinessUser,
  updateBusinessUser,
  getPersonEmailDetailsInfo,
} from './queries';
import { getError } from '../../utils/graph-utils';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { useNavigate } from 'react-router-dom';
import { fetchedPersons } from '../salesforce-business-partners-drawer/queries';
import { extractPersons, toLookups } from './utils';
import { isEmpty } from '../../utils/validationUtils';
import _ from 'lodash';

const SalesForceBusinessUserDrawer: React.FC<
  ISalesForceBusinessUserDrawerProps
> = ({
  personId,
  open,
  onSuccess,
  onClose,
  salesForceBusinessUserDetailsInfo,
  businessUserId,
}) => {
  const navigate = useNavigate();
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [booted, setBooted] = useState<boolean>(false);

  const [businessUsersDetailsListResults] = useLazyQuery(getListForm());

  const [lookups, setLookups] = useState<
    Record<string, Record<string, string>>
  >({});

  // const [selectedPersonID, setSelectedPersonID] = useState('');

  const [fetchedPersonsLazy] = useLazyQuery(fetchedPersons());

  const [checkBusinessUserByPersonLazy] = useLazyQuery(
    checkBusinessUserByPerson(),
    {}
  );

  const [getPersonEmailDetailsLazy] = useLazyQuery(getPersonEmailDetailsInfo());

  const [salesForceBusinessUserAction] = useMutation(
    businessUserId ? updateBusinessUser() : createBusinessUser()
  );

  const [defaultValues, setDefaultValues] =
    useState<ISalesForceBusinessUserDrawerInfo>();

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const result = await businessUsersDetailsListResults();
      if (result.data) {
        const lookupLists = toLookups(result.data);
        setLookups(lookupLists);
        setDefaultValues(salesForceBusinessUserDetailsInfo);
      }
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const validateBusinessUserExistsOnPersonChange = async (
    selectedPersonID: string
  ) => {
    try {
      if (!isEmpty(selectedPersonID)) {
        const result = await checkBusinessUserByPersonLazy({
          variables: {
            selectedPersonID: selectedPersonID,
          },
        });

        if (
          result.data.SalesforceManagement?.queries?.checkBusinessUserByPerson
            .length > 0
        ) {
          return `Business User already created for 
          ${result.data.SalesforceManagement?.queries?.checkBusinessUserByPerson[0]?.salesforceManagement_BusinessUser_PersonID?.views?.SalesforceManagement_all?.properties?.PersonCode}
           - 
          ${result.data.SalesforceManagement?.queries?.checkBusinessUserByPerson[0]?.salesforceManagement_BusinessUser_PersonID?.views?.SalesforceManagement_all?.properties?.FullName}`;
        }
      }
    } catch (err) {
      //
    }

    return '';
  };

  const getPersonEmailDetails = async (
    selectedPersonID: string
  ): Promise<string> => {
    try {
      if (!isEmpty(selectedPersonID)) {
        const result = await getPersonEmailDetailsLazy({
          variables: {
            id: selectedPersonID,
          },
        });
        return (
          result.data?.SalesforceManagement?.entities?.person?.views
            ?.SalesforceManagement_all?.properties?.Email || ''
        );
      }
    } catch (err) {
      //
    }
    return '';
  };

  async function handlePersonInput(
    inputValue: any
  ): Promise<Record<string, string>> {
    try {
      if (inputValue !== null) {
        if (inputValue.length >= 3) {
          const result = await fetchedPersonsLazy({
            variables: {
              searchKeyword: inputValue,
            },
          });
          if (result.data) {
            return extractPersons(result.data);
          }
        }
      }
    } catch (err) {
      //
    }

    return {};
  }

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(formInputs, values);

    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      let variablesMutation = {};
      if (!businessUserId) {
        variablesMutation = {
          businessUserInputs: {
            personID: data.person,
            password: data.password || null,
            type: data.type,
            account: data.account || null,
            access: data.access,
            status: data.SalesforceManagement_BusinessUserStatuses,
            isAdmin: data.isAdmin,
          },
        };
      } else {
        variablesMutation = {
          personID: salesForceBusinessUserDetailsInfo.personId,
          type: data.type,
          account: data.account || null,
          access: data.access,
          isAdmin: data.isAdmin,
        };
      }

      const res = await salesForceBusinessUserAction({
        variables: businessUserId
          ? {
              ...variablesMutation,
              entityId: businessUserId,
            }
          : variablesMutation,
        errorPolicy: 'all',
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {businessUserId
              ? 'Business User successfully updated'
              : 'Business User successfully created'}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState('success');
          onSuccess();
          onClose();
          if (!businessUserId) {
            const newbusinessUserId =
              res.data.salesforceManagement.actions.createBusinessUser.id;
            navigate(`/salesforce/business-users/` + newbusinessUserId);
          }
          setFormDisabled(false);
        }, 500);
      } else {
        setFormDisabled(false);
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (err) {
      setFormDisabled(false);
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
    } finally {
      //
    }
  };

  const formInputs = useMemo(() => {
    return inputs(businessUserId, defaultValues, lookups);
  }, [businessUserId, defaultValues, lookups]);

  const onCustomChange = async (
    fieldName: string,
    value: any,
    allValues: Record<string, any>,
    errors: Record<string, any>,
    touched: Record<string, boolean>
  ) => {
    allValues[fieldName] = value;
    if (fieldName === 'person') {
      const [newEmailAddress, businessUserValidation] = await Promise.all([
        getPersonEmailDetails(value),
        validateBusinessUserExistsOnPersonChange(value),
      ]);

      allValues.email = newEmailAddress || '';

      errors.person = businessUserValidation || '';
      touched.person = true;
    }

    setDefaultValues(allValues as any);
  };

  const onSearch = async (fieldName: string, value: string) => {
    const newLovs = _.cloneDeep(lookups);
    if (fieldName === 'person') {
      newLovs.person = await handlePersonInput(value);
    }
    setLookups(newLovs);
  };

  return (
    <GenericDrawer
      title={businessUserId ? 'Modify Business User' : 'New Business User'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={formInputs}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            // isSubmitButtonDisabled={!isEmpty(businessUserPersonError)}
            onSearch={onSearch}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
            onChange={onCustomChange}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default SalesForceBusinessUserDrawer;
