import { FormInputTypes } from '../../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
} from '../../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  Accounting_AccountingTransfers_TransferNumber: {
    name: 'Accounting_AccountingTransfers_TransferNumber',
    title: 'Transfer #',
    type: EnhancedTableHeaderType.Text,
  },
  Accounting_AccountingTransfers_TransferType: {
    name: 'Accounting_AccountingTransfers_TransferType',
    title: 'Transfer Type',
    type: EnhancedTableHeaderType.Text,
  },
  Accounting_AccountingTransfers_TransferYear: {
    name: 'Accounting_AccountingTransfers_TransferYear',
    title: 'Transfer Year',
    type: EnhancedTableHeaderType.Text,
  },
  Accounting_AccountingTransfers_TransferMonth: {
    name: 'Accounting_AccountingTransfers_TransferMonth',
    title: 'Transfer Month',
    type: EnhancedTableHeaderType.Text,
  },
  Accounting_AccountingTransfers_TransferPeriodFrom: {
    name: 'Accounting_AccountingTransfers_TransferPeriodFrom',
    title: 'Transfer Date From',
    type: EnhancedTableHeaderType.Date,
  },
  Accounting_AccountingTransfers_TransferPeriodTo: {
    name: 'Accounting_AccountingTransfers_TransferPeriodTo',
    title: 'Transfer Date To',
    type: EnhancedTableHeaderType.Date,
  },
  Accounting_AccountingTransfers_createdOn: {
    name: 'Accounting_AccountingTransfers_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  viewHistory: {
    name: 'viewHistory',
    title: ' ',
    buttonLabel: 'View History',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => undefined,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        company: {
          name: 'company',
          title: 'Company',
          type: FormInputTypes.newselect,
          value: filterValues?.company || null,
          placeholder: 'Company',
          triggerUpdate: true,
          selectOptions: { ...lovs.company },
        },
        Type: {
          name: 'Type',
          title: 'Transfer Type',
          type: FormInputTypes.newmultiselect,
          value: filterValues?.Type || null,
          placeholder: 'Transfer Type',
          triggerUpdate: true,
          selectOptions: { ...lovs.Type },
        },
        createdOn: {
          name: 'createdOn',
          title: 'Date',
          type: FormInputTypes.newdaterange,
          value: filterValues.createdOn || [],
          placeholder: 'Created On',
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}

export const actions: IEnhancedTableMenuItem[] = [
  {
    title: '+ New Transfer',
    onClick: () => undefined,
    isEntity: false,
    isBulk: false,
    iconUrl: '',
  },
];
