import { cloneDeep } from 'lodash';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { IAmendmentBankerDetails, IAmendmentPageState } from '..';
import { ILineAmendmentProps } from '../line-amendment';
import { validateBankerRepeaterField } from './validation';
import EnhancedInput from '../../../../components/enhanced-form/EnhancedInput';
import EnhancedChipInput from '../../../../components/enhanced-form/EnhancedChipInput';
import EnhancedFormattedNumberInput from '../../../../components/enhanced-form/EnhancedFormattedNumberInput';
import EnhancedNumberInput from '../../../../components/enhanced-form/EnhancedNumberInput';
import EnhancedCurrencyInput from '../../../../components/enhanced-form/EnhancedCurrencyInput';
import CurrencyFormField from '../../../../components/form-fields/CurrencyFormField';
import { isEmpty } from '../../../../utils/validationUtils';
import { removeObjectAtIndex } from '../../../../utils/helper-utils';
import WidgetPaper from '../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../components/common/WidgetSection';

const initialValues = {
  initialError: {
    branchName: '',
    isMain: '',
    isAgency: '',
    numberOfEmployees: '',
    underwritingYear: '',
    infidelity: '',
    burglary: '',
    holdUp: '',
    inSafe: '',
    inTransit: '',
    forgedChecks: '',
    counterfeitCurrencies: '',
    clause6: '',
    falsification: '',
  },
  initialTouched: {
    branchName: false,
    isMain: false,
    isAgency: false,
    numberOfEmployees: false,
    underwritingYear: false,
    infidelity: false,
    burglary: false,
    holdUp: false,
    inSafe: false,
    inTransit: false,
    forgedChecks: false,
    counterfeitCurrencies: false,
    clause6: false,
    falsification: false,
  },
};

interface IBankersDetailsWidgetProps {
  generalData: ILineAmendmentProps['generalData'];
  pageState: IAmendmentPageState;
  onPageStateUpdate: (pageState: IAmendmentPageState) => void;
  isDisabled: boolean;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    backgroundColor: '#F9F9F9',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    padding: '4px 2px 10px 25px',
    marginBottom: '14px',
  },
  containerWithoutClear: {
    width: '100%',
    backgroundColor: '#F9F9F9',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    padding: '30px 2px 10px 25px',
    marginBottom: '14px',
  },
  section: {
    padding: '23px 0 25px',
  },
  numberContainer: {
    color: '#000000',
    marginBottom: 0,
    paddingBottom: 0,
    fontSize: '12px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(4, 23.5%)`,
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  fieldRowBorderBottom: {
    height: '1px',
    width: '100%',
    borderBottom: '1px solid #C5C6C7',
    margin: '5px 0 25px',
  },
  sumInsuredRow: {
    width: '100%',
  },
  addBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: '0',
    margin: '20px auto 0',
    display: 'block',
  },
  removeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  clearBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  labelHeader: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
    margin: '8.5px 0 0 0 !important',
    padding: '0px!important',
  },
  labelDropdown: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
    margin: '6px 0 0 0 !important',
    padding: '0px!important',
  },
  labelText: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
    margin: '2px 0 -2px 0 !important',
    padding: '0px!important',
  },
}));

const BankersDetails: React.FC<IBankersDetailsWidgetProps> = ({
  generalData,
  pageState,
  onPageStateUpdate,
  isDisabled,
}) => {
  const { classes } = useStyles();

  const values = pageState.values.bankersDetails;
  const errors = pageState.errors.bankersDetails;
  const touched = pageState.touched.bankersDetails;

  const onFieldBlur = (fieldName: string, rowIndex: number) => {
    const newPageState = cloneDeep(pageState);
    newPageState.touched.bankersDetails[rowIndex][fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IAmendmentBankerDetails,
    value: IAmendmentBankerDetails[keyof IAmendmentBankerDetails],
    rowIndex: number,
    touched = false
  ) => {
    const newPageState = cloneDeep(pageState);

    (newPageState.values.bankersDetails[rowIndex] as any)[fieldName] = value;

    newPageState.errors.bankersDetails[rowIndex][fieldName] =
      validateBankerRepeaterField(fieldName, value);
    if (touched) {
      newPageState.touched.bankersDetails[rowIndex][fieldName] = true;
    }
    onPageStateUpdate(newPageState);
  };

  const renderRow = (row: IAmendmentBankerDetails, index: number) => {
    const rowIndex = values.indexOf(row);

    return (
      <div
        key={index}
        className={
          values.length > 1 && !isDisabled
            ? classes.container
            : classes.containerWithoutClear
        }
      >
        <div className={classes.clearBtn}>
          {values.length > 1 && !isDisabled && (
            <button
              className={classes.removeBtn}
              onClick={() => onRowDelete(rowIndex)}
            >
              <ClearIcon fontSize="small" />
            </button>
          )}
        </div>
        <div className={classes.fieldRow}>
          <EnhancedInput
            key="branchName"
            name="branchName"
            title="Branch Name*"
            placeholder="Branch Name"
            value={values[rowIndex].branchName}
            error={
              touched[rowIndex].branchName ? errors[rowIndex].branchName : ''
            }
            onChange={(v) => {
              onFieldUpdate('branchName', v.target.value, rowIndex);
            }}
            disabled={isDisabled}
            material
            customStyles={{
              labelStyles: classes.labelText,
            }}
            type={''}
            onBlur={() => onFieldBlur('branchName', rowIndex)}
          />

          <EnhancedChipInput
            key="isMain"
            name="isMain"
            title="Main*"
            placeholder="Main"
            value={values[rowIndex].isMain}
            error={touched[rowIndex].isMain ? errors[rowIndex].isMain : ''}
            onChange={(v) => {
              onFieldUpdate('isMain', v, rowIndex);
            }}
            disabled={isDisabled}
            material
            customStyles={{
              labelStyles: classes.labelDropdown,
            }}
            selectOptions={{ YES: 'Yes', NO: 'No' }}
            onBlur={() => onFieldBlur('isMain', rowIndex)}
            multiple={false}
          />

          <EnhancedChipInput
            key="isAgency"
            name="isAgency"
            title="Agency*"
            placeholder="Agency"
            value={values[rowIndex].isAgency}
            error={touched[rowIndex].isAgency ? errors[rowIndex].isAgency : ''}
            onChange={(v) => {
              onFieldUpdate('isAgency', v, rowIndex);
            }}
            disabled={isDisabled}
            material
            customStyles={{
              labelStyles: classes.labelDropdown,
            }}
            selectOptions={{ YES: 'Yes', NO: 'No' }}
            onBlur={() => onFieldBlur('isAgency', rowIndex)}
            multiple={false}
          />

          <EnhancedFormattedNumberInput
            key="numberOfEmployees"
            name="numberOfEmployees"
            title="Number of Employees*"
            placeholder="Number of Employees"
            value={values[rowIndex].numberOfEmployees || ''}
            error={
              touched[rowIndex].numberOfEmployees
                ? errors[rowIndex].numberOfEmployees
                : ''
            }
            onChange={(v) => {
              onFieldUpdate('numberOfEmployees', v.target.value, rowIndex);
            }}
            maxDecimalPercision={0}
            disabled={isDisabled}
            material
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
            onBlur={() => onFieldBlur('numberOfEmployees', rowIndex)}
          />

          <EnhancedNumberInput
            key="underwritingYear"
            name="underwritingYear"
            title="Underwriting Year*"
            placeholder="Underwriting Year"
            value={
              !isEmpty(values?.[rowIndex]?.underwritingYear)
                ? values[rowIndex].underwritingYear?.toString()
                : ''
            }
            error={
              touched[rowIndex].underwritingYear
                ? errors[rowIndex].underwritingYear
                : ''
            }
            onChange={(v) => {
              onFieldUpdate(
                'underwritingYear',
                v.target.value?.toString(),
                rowIndex
              );
            }}
            disabled={isDisabled}
            material
            customStyles={{
              labelStyles: classes.labelText,
            }}
            maxDecimalPrecision={0}
            onBlur={() => onFieldBlur('underwritingYear', rowIndex)}
          />

          <CurrencyFormField
            key="infidelity"
            name="infidelity"
            title="Infidelity*"
            placeholder="Infidelity"
            value={values[rowIndex].infidelity || ''}
            error={
              touched[rowIndex].infidelity ? errors[rowIndex].infidelity : ''
            }
            onChange={(v) => {
              onFieldUpdate('infidelity', v.target.value, rowIndex);
            }}
            disabled={isDisabled}
            onBlur={() => onFieldBlur('infidelity', rowIndex)}
            material
            currencySymbol={generalData.currency.Symbol}
          />

          <EnhancedCurrencyInput
            key="burglary"
            name="burglary"
            title="Burglary*"
            placeholder="Burglary"
            value={values[rowIndex].burglary || ''}
            error={touched[rowIndex].burglary ? errors[rowIndex].burglary : ''}
            onChange={(v) => {
              onFieldUpdate('burglary', v.target.value, rowIndex);
            }}
            disabled={isDisabled}
            material
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
            onBlur={() => onFieldBlur('burglary', rowIndex)}
          />

          <EnhancedCurrencyInput
            key="holdUp"
            name="holdUp"
            title="Hold Up*"
            placeholder="Hold Up"
            value={values[rowIndex].holdUp || ''}
            error={touched[rowIndex].holdUp ? errors[rowIndex].holdUp : ''}
            onChange={(v) => {
              onFieldUpdate('holdUp', v.target.value, rowIndex);
            }}
            disabled={isDisabled}
            material
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
            onBlur={() => onFieldBlur('holdUp', rowIndex)}
          />

          <EnhancedCurrencyInput
            key="inSafe"
            name="inSafe"
            title="In Safe*"
            placeholder="In Safe"
            value={values[rowIndex].inSafe || ''}
            error={touched[rowIndex].inSafe ? errors[rowIndex].inSafe : ''}
            onChange={(v) => {
              onFieldUpdate('inSafe', v.target.value, rowIndex);
            }}
            disabled={isDisabled}
            material
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
            onBlur={() => onFieldBlur('inSafe', rowIndex)}
          />

          <EnhancedCurrencyInput
            key="inTransit"
            name="inTransit"
            title="In Transit*"
            placeholder="In Transit"
            value={values[rowIndex].inTransit || ''}
            error={
              touched[rowIndex].inTransit ? errors[rowIndex].inTransit : ''
            }
            onChange={(v) => {
              onFieldUpdate('inTransit', v.target.value, rowIndex);
            }}
            disabled={isDisabled}
            material
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
            onBlur={() => onFieldBlur('inTransit', rowIndex)}
          />

          <EnhancedCurrencyInput
            key="forgedChecks"
            name="forgedChecks"
            title="Forged Checks*"
            placeholder="Forged Checks"
            value={values[rowIndex].forgedChecks || ''}
            error={
              touched[rowIndex].forgedChecks
                ? errors[rowIndex].forgedChecks
                : ''
            }
            onChange={(v) => {
              onFieldUpdate('forgedChecks', v.target.value, rowIndex);
            }}
            disabled={isDisabled}
            material
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
            onBlur={() => onFieldBlur('forgedChecks', rowIndex)}
          />

          <EnhancedCurrencyInput
            key="counterfeitCurrencies"
            name="counterfeitCurrencies"
            title="Counter Feit Currencies*"
            placeholder="Counter Feit Currencies"
            value={values[rowIndex].counterfeitCurrencies || ''}
            error={
              touched[rowIndex].counterfeitCurrencies
                ? errors[rowIndex].counterfeitCurrencies
                : ''
            }
            onChange={(v) => {
              onFieldUpdate('counterfeitCurrencies', v.target.value, rowIndex);
            }}
            disabled={isDisabled}
            material
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
            onBlur={() => onFieldBlur('counterfeitCurrencies', rowIndex)}
          />

          <EnhancedCurrencyInput
            key="clause6"
            name="clause6"
            title="Clause 6*"
            placeholder="Clause 6"
            value={values[rowIndex].clause6 || ''}
            error={touched[rowIndex].clause6 ? errors[rowIndex].clause6 : ''}
            onChange={(v) => {
              onFieldUpdate('clause6', v.target.value, rowIndex);
            }}
            disabled={isDisabled}
            material
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
            onBlur={() => onFieldBlur('clause6', rowIndex)}
          />

          <EnhancedCurrencyInput
            key="falsification"
            name="falsification"
            title="Falsification*"
            placeholder="Falsification"
            value={values[rowIndex].falsification || ''}
            error={
              touched[rowIndex].falsification
                ? errors[rowIndex].falsification
                : ''
            }
            onChange={(v) => {
              onFieldUpdate('falsification', v.target.value, rowIndex);
            }}
            disabled={isDisabled}
            material
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
            onBlur={() => onFieldBlur('falsification', rowIndex)}
          />
        </div>
      </div>
    );
  };

  const onRowAdd = () => {
    const newPageState = cloneDeep(pageState);
    newPageState.values.bankersDetails.push({
      branchName: '',
      isMain: '',
      isAgency: '',
      numberOfEmployees: 0,
      underwritingYear: 0,
      infidelity: 0,
      burglary: 0,
      holdUp: 0,
      inSafe: 0,
      inTransit: 0,
      forgedChecks: 0,
      counterfeitCurrencies: 0,
      clause6: 0,
      falsification: 0,
    });

    newPageState.errors.bankersDetails.push({ ...initialValues.initialError });
    newPageState.touched.bankersDetails.push({
      ...initialValues.initialTouched,
    });

    onPageStateUpdate(newPageState);
  };

  const onRowDelete = (rowIndex: number) => {
    const newPageState = cloneDeep(pageState);

    newPageState.values.bankersDetails = removeObjectAtIndex(
      newPageState.values.bankersDetails,
      rowIndex
    );

    newPageState.errors.bankersDetails = removeObjectAtIndex(
      newPageState.errors.bankersDetails,
      rowIndex
    );
    newPageState.touched.bankersDetails = removeObjectAtIndex(
      newPageState.touched.bankersDetails,
      rowIndex
    );

    onPageStateUpdate(newPageState);
  };

  return (
    <WidgetPaper>
      <WidgetSection
        title="Bankers Blanket Bond Details"
        classes={{
          container: classes.section,
        }}
      >
        {values.map((v, index) => renderRow(v, index))}

        {!isDisabled && (
          <button className={classes.addBtn} onClick={() => onRowAdd()}>
            <AddCircleOutlineIcon color="error" fontSize="small" />
          </button>
        )}
      </WidgetSection>
    </WidgetPaper>
  );
};

export default BankersDetails;
