import React, { useState } from 'react';
import StaticLayout from '../../../../page-layout/static-layout/StaticLayout';
import { IListingFilterWidgetSection } from '../../../../components/widgets/custom-listing-filter';
import { getFilter, setFilter } from '../../../../utils/filter-utils';
import { IListingData } from '../../../../models/listing';
import { IAbstractRecord } from '../../../../models';
import { IFilterModel } from '../../../../components/form-fields/listing-widget';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../../../constants';
import ListingWidget from '../../../../components/form-fields/listing-widget/ListingWidget';
import ListingFilterWidget from '../../../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { useLazyQuery } from '@apollo/client';
import { filterSectionsContent, headers } from './content';
import { mapToListingData, toLookupsData } from './utils';
import {
  getLineListQuery,
  getProdDistListQuery,
  getSublineListQuery,
} from './queries';
import { lookupListAsRecordObject } from '../../../../utils/graph-utils';

const PAGE_CONTEXT = 'ProductionDistribution';
const FILTER_SESSION_KEY = 'prodDistFilter';

const ProdDistListingPage: React.FC = () => {
  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();
  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          line: [],
          subline: [],
          effectiveDate: [],
        };
  const [filterValues, setFilterValues] = useState<IFilterModel>({
    namedFilters: initialFilterValues,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  const [tableData, setTableData] = useState<
    IListingData & {
      lovs: Record<string, Record<string, string>>;
    }
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
    lovs: {},
  });

  const [getProdDistListLazy, { loading }] = useLazyQuery(
    getProdDistListQuery,
    {
      errorPolicy: 'all',
    }
  );
  const [getLineListLazy] = useLazyQuery(getLineListQuery);
  const [getSublineListLazy] = useLazyQuery(getSublineListQuery);

  function handlePageChange(
    page: number,
    filterModel = filterValues
  ): Promise<void> {
    return new Promise<void>(() =>
      getListingData({
        ...filterModel,
        pagination: {
          ...filterModel.pagination,
          pageNumber: page,
        },
      })
    );
  }

  const onFilterUpdate = async (filters: IAbstractRecord) => {
    const newFilters = {
      ...filterValues,
      namedFilters: filters,
      pagination: {
        ...filterValues.pagination,
        pageNumber: 1,
      },
    };
    setFilter(filters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    getListingData(newFilters);
  };

  const getVariables = (filter = filterValues) => {
    return {
      Line: filter.namedFilters?.line,
      Subline: filter.namedFilters?.subline,
      PolicyEffectiveFromDate: filter.namedFilters?.effectiveDate[0],
      PolicyEffectiveToDate: filter.namedFilters?.effectiveDate[1],
      KeywordSearch: filter.searchKey,
      OrderByField: filter.orderBy,
      DescendingField: filter.descending,
      pagination: {
        pageSize: filter.pagination.pageSize,
        pageNumber: filter.pagination.pageNumber,
      },
    };
  };

  const getListingData = (filter = filterValues) => {
    if (!filter) {
      return {};
    }
    const variables = getVariables(filter);
    return getProdDistListLazy({
      variables: variables,
    })
      .then(({ data }) => {
        if (data) {
          const lovs = toLookupsData(data);

          if (!Object.keys(lovs.line).length) {
            lovs.line = filter.namedFilters.line;
          }

          if (!Object.keys(lovs.subline).length) {
            lovs.subline = filter.namedFilters.subline;
          }

          const newFilterSections = filterSectionsContent(
            lovs,
            filter.namedFilters
          );
          setFilterSections(newFilterSections);
          setFilterValues(filter);

          const tableData = mapToListingData(
            data?.Production?.queries?.ProductionDistributionList
          );

          setTableData({
            ...tableData,
            pageNumber: tableData.pageNumber,
            pageSize: tableData.pageSize,
            totalCount: tableData.totalCount,
            lovs,
          });
        }
      })
      .catch(() => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      });
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={''}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
          onAutocompleteSearch={(
            fieldName: string,
            value: string,
            pagination
          ) => {
            if (fieldName === 'line') {
              return getLineListLazy({
                variables: {
                  KeywordSearch: value,
                  pagination,
                },
              }).then((result) => {
                const data =
                  result?.data?.PlanConfigManagement?.queries?.getLineList;
                return {
                  options: lookupListAsRecordObject(
                    data?.items,
                    false,
                    'planConfigManagement_Line_Name',
                    'planConfigManagement_Line_Name'
                  ),
                  totalCount: data?.paging?.totalCount,
                };
              });
            } else if (fieldName === 'subline') {
              return getSublineListLazy({
                variables: {
                  KeywordSearch: value,
                  pagination,
                },
              }).then((result) => {
                const data =
                  result?.data?.PlanConfigManagement?.queries?.getSubLineList;
                return {
                  options: lookupListAsRecordObject(
                    data?.items,
                    false,
                    'planConfigManagement_Subline_Name',
                    'planConfigManagement_Subline_Name'
                  ),
                  totalCount: data?.paging?.totalCount,
                };
              });
            }

            return new Promise<{
              options: Record<string, string>;
              totalCount: number;
            }>((resolve) => {
              resolve({ options: {}, totalCount: 0 });
            });
          }}
        ></ListingFilterWidget>
      )
    );
  };

  const renderMainChildren = () => {
    return (
      <ListingWidget
        name="productionDistribution"
        title="Production Distribution"
        orderByAscendingByDefault
        loading={loading}
        data={tableData}
        tableSettings={{
          headers: headers,
        }}
        actions={[]}
        pageContext={PAGE_CONTEXT}
        onPageChange={(filterModel) => {
          const newFilterModel = {
            ...filterValues,
            ...filterModel,
          };
          const page = filterModel.pagination.pageNumber;
          return handlePageChange(page, newFilterModel);
        }}
        disableSelection
        usePagination={true}
      />
    );
  };

  return (
    <StaticLayout
      name="Treaties"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default ProdDistListingPage;
