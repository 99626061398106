import React, { useCallback, useEffect, useState } from 'react';
import { IListingData } from '../../../models/listing';
import StaticLayout from '../../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import { getLovs, getPolicyBusinessPartnerListQuery } from './queries';
import { mapToListingData, toLookupsData } from './utils';
import { filterSectionsContent, headers } from './content';
import ListingWidget from '../../../components/form-fields/listing-widget/ListingWidget';
import { IListingFilterWidgetSection } from '../../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../../models';
import { getFilter, setFilter } from '../../../utils/filter-utils';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { DEFAULT_ERROR_TEXT } from '../../../constants';
import { IFilterModel } from '../../../components/form-fields/listing-widget';

const PAGE_CONTEXT = 'ReceivablesPayables';
const FILTER_SESSION_KEY = 'receivablesPayablesFilter';

const ReceivablesPayablesPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });
  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY).namedFilters
      : {
          type: [],
          company: '41',
          businessPartnerType: [],
        };
  const [filterValues, setFilterValues] = useState<IFilterModel>({
    namedFilters: initialfilterValues,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
    orderBy: 'SalesforceManagement_BusinessPartner_FullName',
    descending: false,
  });

  const [getLovsLazy] = useLazyQuery(getLovs);
  const [getPolicyBusinessPartnerListLazy] = useLazyQuery(
    getPolicyBusinessPartnerListQuery
  );

  const loadFilterLovs = useCallback(async () => {
    try {
      const { data: lovsData } = await getLovsLazy();

      const lovs = toLookupsData(lovsData);

      if (!Object.keys(lovs.company).length) {
        lovs.company = filterValues.namedFilters?.company;
      }

      if (!Object.keys(lovs.businessPartnerType).length) {
        lovs.businessPartnerType =
          filterValues.namedFilters?.businessPartnerType;
      }

      const newFilterSections = filterSectionsContent(
        lovs,
        filterValues.namedFilters
      );
      setFilterSections(newFilterSections);

      setFilter(filterValues, FILTER_SESSION_KEY);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadFilterLovs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterUpdate = async (filters: IAbstractRecord) => {
    const newFilters = {
      ...filterValues,
      namedFilters: filters,
    };

    getListingData(newFilters);
  };

  const getListingData = (filter = filterValues) => {
    setFilter(filter, FILTER_SESSION_KEY);
    setFilterValues(filter);
    if (!filter) {
      setLoading(false);
      return {};
    }

    const filterValues = filter.namedFilters;
    const pagination = filter.pagination || {};

    if (!filterValues) return {};

    setLoading(true);
    return getPolicyBusinessPartnerListLazy({
      variables: {
        selectedCompany: filterValues.company || '41',
        businessPartnerType: filterValues.businessPartnerType,
        keywordSearch: filter.searchKey,
        pagination: {
          pageNumber: pagination.pageNumber || 1,
          pageSize: pagination.pageSize || 10,
        },
        orderByField:
          filter.orderBy || 'SalesforceManagement_BusinessPartner_FullName',
        descendingField: filter.descending,
      },
    })
      .then(({ data }) => {
        if (data) {
          const newTableData = mapToListingData(data);
          setTableData({
            ...newTableData,
            pageNumber: newTableData.pageNumber,
            pageSize: newTableData.pageSize,
            totalCount: newTableData.totalCount,
          });
        }
      })
      .catch(() => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function handlePageChange(
    page: number,
    filterModel = filterValues
  ): Promise<void> {
    return new Promise<void>(() =>
      getListingData({
        ...filterModel,
        pagination: {
          ...filterModel.pagination,
          pageNumber: page,
        },
      })
    );
  }

  const renderMainChildren = () => (
    <ListingWidget
      name="receivables-payables"
      title="Receivables & Payables By Partner"
      orderByAscendingByDefault
      defaultOrderByColumn="SalesforceManagement_BusinessPartner_FullName"
      loading={loading}
      data={tableData}
      tableSettings={{ headers }}
      actions={[]}
      usePagination
      pageContext={PAGE_CONTEXT}
      disableSelection
      forceShowSelectColumn
      onPageChange={(filterModel) => {
        const newFilterModel = {
          ...filterValues,
          ...filterModel,
        };
        const page = filterModel.pagination.pageNumber;
        return handlePageChange(page, newFilterModel);
      }}
    />
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        disabled={loading}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  return (
    <StaticLayout
      name="ReceivablesPayables"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default ReceivablesPayablesPage;
