import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import LeftSideColumn from './components/shared/LeftSideStaticColumn';
import StaticLayout from '../../../../page-layout/static-layout/StaticLayout';
import MainColumn from './components/MainColumn';
import { getBusinessPartnerInfo, getGeneralLovs, getPolicies } from './queries';
import BrokerMainColumn from './components/BrokerMainColumn';
import TabsLayout from '../../../../page-layout/tabs-layout/TabsLayout';
import { useAppSelector } from '../../../../redux/hooks';
import { makeStyles } from 'tss-react/mui';
import { RPTabs } from './content';
import { cloneDeep } from 'lodash';
import ReceiptVouchersTable from './components/receipt-vouchers/ReceiptVouchersTable';
import PaymentVouchersTable from './components/payment-vouchers/PaymentVouchersTable';
import { enumListAsRecordObject } from '../../../../utils/graph-utils';
import UnallocatedPayments from './components/shared/UnallocatedPayments';

const useStyles = makeStyles()(() => ({
  tabPanelStyle: {
    padding: 'unset',
    marginTop: '20px',
  },
}));

const ReceivablesPayablesDetailsPage: React.FC = () => {
  const { id } = useParams();
  const { classes } = useStyles();
  const { data } = useQuery(getBusinessPartnerInfo, {
    variables: { id },
  });
  const { data: lovs } = useQuery(getGeneralLovs);
  const [
    fetchPolicies,
    { refetch, data: PoliciesData, loading: PoliciesLoading },
  ] = useLazyQuery(getPolicies);

  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);

  const businessPartner =
    data?.SalesforceManagement.entities.businessPartner.views
      .SalesforceManagement_all.properties;

  const businessPartnerFullName =
    businessPartner?.PersonID.views.SalesforceManagement_all.properties
      .FullName;

  const businessPartnerType = businessPartner?.Type.Code;

  const LefSideComponent = (
    <LeftSideColumn title={businessPartnerFullName} fields={[]} />
  );

  const paymentMethods = useMemo(() => {
    if (!lovs) return {};

    return enumListAsRecordObject(
      lovs.Accounting_PaymentMethodList?.enumValues as {
        __typename: string;
        name: string;
      }[]
    );
  }, [lovs]);

  const tabs = () => {
    const newTabs = cloneDeep(RPTabs);

    newTabs.tabs[0].widgets[0].children = (
      <>
        <ReceiptVouchersTable
          paymentMethods={paymentMethods}
          onSuccess={() => refetch()}
        />
        <UnallocatedPayments type="CREDIT" />
      </>
    );

    newTabs.tabs[1].widgets[0].children = (
      <>
        <PaymentVouchersTable
          paymentMethods={paymentMethods}
          onSuccess={() => refetch()}
        />
        <UnallocatedPayments type="DEBIT" />
      </>
    );

    return newTabs;
  };

  return businessPartner?.Type.Code === 'Broker' ? (
    <TabsLayout
      name="policyPageDetails"
      layout={tabs()}
      theme={tenant.theme}
      leftChildren={LefSideComponent}
      mainChildren={
        <BrokerMainColumn
          mainEntityId={id}
          fetchPolicies={fetchPolicies}
          data={PoliciesData}
          loading={PoliciesLoading}
        />
      }
      cdnUrl={tenant.cdnUrl}
      userInfo={user['info']}
      tabPanelClassName={classes.tabPanelStyle}
      firstTabAsActiveTab={true}
    />
  ) : (
    <StaticLayout
      name={'Receivables & Payables'}
      leftChildren={LefSideComponent}
      mainChildren={
        <MainColumn
          mainEntityId={id}
          businessPartnerType={businessPartnerType}
        />
      }
    />
  );
};

export default ReceivablesPayablesDetailsPage;
