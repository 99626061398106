import { isEmpty } from 'lodash';
import { IAbstractRecord } from '../../../../models';
import { IListingData } from '../../../../models/listing';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../../../utils/graph-utils';
import { IFacultativeData, IFacultativeResponse } from '.';
import { formatEnum } from '../../../../utils/formatting-utils';

export function toLookupsData(data: IAbstractRecord) {
  const lovs: Record<string, Record<string, string>> = {
    proposal: {},
    mainReinsurer: {},
    status: {},
  };

  lovs.proposal = lookupListAsRecordObject(
    data?.Production?.queries?.GetActiveProposals,
    false,
    'production_Proposal_Id',
    'production_Proposal_ProposalName'
  );

  lovs.mainReinsurer = lookupListAsRecordObject(
    data?.Production?.queries?.GetFacReinsurers,
    false,
    'facultativeReinsurersBusinessPartner_FullName',
    'facultativeReinsurersBusinessPartner_FullName'
  );

  lovs.status = enumListAsRecordObject(
    data?.Production_FacStatusList?.enumValues
  );

  return lovs;
}

export function mapToListingData(data: {
  items: IFacultativeResponse[];
  paging: { pageSize: number; pageNumber: number; totalCount: number };
}): IListingData {
  if (isEmpty(data.items)) {
    return {
      pagedItems: {},
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    };
  }

  const list: Record<string, IFacultativeData> = {};

  data.items.forEach((item) => {
    list[item.production_Facultatives_Id] = {
      facultativeId: item.production_Facultatives_Id,
      Production_Facultatives_FacultativeID:
        item.production_Facultatives_FacultativeID,
      Production_Facultatives_SlipReferenceNumber:
        item.production_Facultatives_SlipReferenceNumber,
      Proposal_ProposalName: item.proposal_ProposalName,
      MainReinsurerDetails_FullName: item.mainReinsurerDetails_FullName,
      Production_Facultatives_PaymentDueDate:
        item.production_Facultatives_PaymentDueDate,
      Proposal_Status: formatEnum(
        item.production_Facultatives_FacultativeStatus
      ),
      Proposal_createdOn: item.production_Facultatives_createdOn,
    };
  });

  return {
    pagedItems: list,
    pageSize: data?.paging?.pageSize,
    pageNumber: data?.paging?.pageNumber,
    totalCount: data?.paging?.totalCount,
  };
}
