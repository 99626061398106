import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { IAbstractRecord } from '../../models';
import { IProductionDetailsSummary } from '../../modules/production/policy/page';

const LINE_CODES_WITH_GEOLOCATION = [
  '11',
  '15',
  '16',
  '25',
  '27',
  '29',
  '30',
  '31',
  '37',
  '38',
  '39',
  '42',
  '46',
];

export const getInputs = (
  lovs: Record<string, IAbstractRecord> = {},
  values: IProductionDetailsSummary
): Record<string, DynamicFormInputType> => {
  const externalCode = values?.LineId?.ExternalCode;
  return {
    descriptionOfRisk: {
      name: 'descriptionOfRisk',
      title: 'Description of Risk',
      type: FormInputTypes.text,
      placeholder: 'Description of Risk',
      value: values?.DescriptionOfRisk || '',
      required: false,
      hidden: false,
    },
    geoLocation: {
      name: 'geoLocation',
      title: 'Geographical Location',
      type: FormInputTypes.chips,
      placeholder: 'Geographical Location',
      value: values?.GeoLocation?.Code || '',
      selectOptions: lovs?.geoLocations || {},
      hidden: !LINE_CODES_WITH_GEOLOCATION.includes(externalCode),
      required: LINE_CODES_WITH_GEOLOCATION.includes(externalCode),
      multiple: false,
    },
  };
};
