import {
  IMarineGroupCertficateForm,
  IMarineGroupCertificateCostCharges,
  IMarineGroupCertificateGroupCover,
} from '.';
import { IAbstractRecord } from '../../../../../../../models';
import {
  isEmpty,
  isValidNumber,
} from '../../../../../../../utils/validationUtils';
import { IFormState } from './form';

export const validateCostWidgetField = (
  fieldName: keyof IMarineGroupCertificateCostCharges,
  value: any
): string => {
  if (fieldName === 'policyCost') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'chargesPercentage') {
    if (isEmpty(value)) {
      return 'Required';
    } else {
      if (!isValidNumber(value)) {
        return 'Invalid Number';
      }

      const numberValue = Number(value);

      if (numberValue < 0 || numberValue > 100) {
        return 'Value should be between 0 and 100';
      }
    }
  }

  if (fieldName === 'tpaFeesAmount') {
    if (isEmpty(value)) {
      return 'Required';
    } else {
      if (!isValidNumber(value)) {
        return 'Invalid Number';
      }

      const numberValue = Number(value);

      if (numberValue < 0) {
        return 'Value should be greater than 0';
      }
    }
  }

  return '';
};

export const validateCoverRepeaterField = (
  fieldName: keyof IMarineGroupCertificateGroupCover,
  value: any,
  cover: IMarineGroupCertificateGroupCover
): string => {
  if (fieldName === 'policyCover') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'premium') {
    if (isEmpty(value)) {
      return 'Required';
    } else {
      if (!isValidNumber(value)) {
        return 'Invalid Number';
      }

      const numberValue = Number(value);

      if (numberValue < 0) {
        return 'Value should be greater than 0';
      }
    }
  }

  if (fieldName === 'coverSumInsured') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than 0';
    }
  }

  if (fieldName === 'excessOnClaimType') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'excessOnClaimPercentage') {
    if (cover.excessOnClaimType === 'PERCENTAGE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return 'Value should be between 0 and 100';
        }
      }
    }
  }

  if (fieldName === 'excessOnClaimAmount') {
    if (cover.excessOnClaimType === 'VALUE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  if (fieldName === 'excessOnClaimDays') {
    if (cover.excessOnClaimType === 'DAYS') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  return '';
};

export const validatePolicyWidgetField = (
  fieldName: keyof IMarineGroupCertficateForm['policyDetails'],
  value: any
): string => {
  if (fieldName === 'subline') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'plan') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'policyEffectiveDate') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validateMarineDetailsField = (
  fieldName: string,
  value: any
): string | null => {
  const requiredFields = [
    'matter',
    'description',
    'vesselName',
    'vesselAge',
    'from',
    'to',
    'via',
    'survey',
    'cargoContract',
    'claimsPayableBy',
    'loading',
  ];

  // Handle required fields
  if (
    requiredFields.includes(fieldName) &&
    (isEmpty(value) || value.trim() === '')
  ) {
    return `${fieldName} is required`;
  }

  // Handle specific validation cases
  if (fieldName === 'vesselAge' && value && isNaN(Number(value))) {
    return 'Vessel Age must be a number';
  }

  // No errors for valid fields
  return null;
};

export const validateProposalPageForm = (
  values: IFormState['values'],
  data: IAbstractRecord
): {
  errors: IFormState['errors'];
  touched: IFormState['touched'];
  isValid: boolean;
} => {
  const errors: IFormState['errors'] = {
    policyDetails: {},
    covers: [],
    costs: {},
    details: {},
  };
  const touched: IFormState['touched'] = {
    policyDetails: {},
    covers: [],
    costs: {},
    details: {},
  };

  // Validate Policy Details Fields
  Object.keys(values.policyDetails).forEach(
    (field: keyof typeof values.policyDetails) => {
      const value = values.policyDetails[field];

      errors.policyDetails[field] = validatePolicyWidgetField(field, value);
      touched.policyDetails[field] = true;
    }
  );

  // Validate Covers Fields
  errors.covers = values.covers.map((cover) => {
    const coverErrors: IAbstractRecord = {};
    if (cover.isMandatory || cover.isMain) {
      const editable =
        data.BusinessUserID?.AllowPlanCoverEdits && cover.isEditable;
      coverErrors.policyCover = validateCoverRepeaterField(
        'policyCover',
        cover.policyCover,
        cover
      );

      if (editable) {
        coverErrors.coverSumInsured = validateCoverRepeaterField(
          'coverSumInsured',
          cover.coverSumInsured,
          cover
        );
        coverErrors.premium = validateCoverRepeaterField(
          'premium',
          cover.premium,
          cover
        );
        coverErrors.excessOnClaimType = validateCoverRepeaterField(
          'excessOnClaimType',
          cover.excessOnClaimType,
          cover
        );

        if (cover.excessOnClaimType === 'VALUE') {
          coverErrors.excessOnClaimAmount = validateCoverRepeaterField(
            'excessOnClaimAmount',
            cover.excessOnClaimAmount,
            cover
          );
        } else if (cover.excessOnClaimType === 'PERCENTAGE') {
          coverErrors.excessOnClaimPercentage = validateCoverRepeaterField(
            'excessOnClaimPercentage',
            cover.excessOnClaimPercentage,
            cover
          );
        } else if (cover.excessOnClaimType === 'DAYS') {
          coverErrors.excessOnClaimDays = validateCoverRepeaterField(
            'excessOnClaimDays',
            cover.excessOnClaimDays,
            cover
          );
        }
      }
      coverErrors.coverEffectiveFrom = validateCoverRepeaterField(
        'coverEffectiveFrom',
        cover.coverEffectiveFrom,
        cover
      );
      coverErrors.coverEffectiveTo = validateCoverRepeaterField(
        'coverEffectiveTo',
        cover.coverEffectiveTo,
        cover
      );
    }

    touched.covers.push(
      Object.keys(coverErrors).reduce(
        (acc, key) => ({ ...acc, [key]: true }),
        {}
      )
    );
    return coverErrors;
  });

  const editable = data.BusinessUserID?.EditableLineChargesIDs?.includes(
    data?.LineId.Id
  );
  Object.keys(values.costs).forEach((field) => {
    const value = values.costs[field as keyof typeof values.costs];
    if (editable) {
      errors.costs[field] = validateCostWidgetField(
        field as keyof typeof values.costs,
        value
      );
    }
    touched.costs[field] = true;
  });

  // Validate Marine Details Fields
  const mandatoryFields = [
    'matter',
    'description',
    'vesselName',
    'from',
    'to',
    'via',
    'survey',
    'cargoContract',
    'claimsPayableBy',
    'loading',
  ];
  Object.keys(values.details).forEach((field) => {
    if (mandatoryFields.includes(field)) {
      errors.details[field] = !isEmpty(
        values.details[field as keyof typeof values.details]
      )
        ? ''
        : 'Required';
    }
    touched.details[field] = true;
  });

  // Final validity check
  const isValid =
    Object.values(errors.policyDetails).every((e) => !e) &&
    errors.covers.every((cover) => Object.values(cover).every((e) => !e)) &&
    Object.values(errors.costs).every((e) => !e) &&
    Object.values(errors.details).every((e) => !e);

  return { errors, touched, isValid };
};
