import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import WidgetPaper from '../../../components/common/WidgetPaper';
import WidgetSection from '../../../components/common/WidgetSection';
import EnhancedInput from '../../../components/enhanced-form/EnhancedInput';
import EnhancedChipInput from '../../../components/enhanced-form/EnhancedChipInput';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import _ from 'lodash';
import {
  IPropertyCoverDetails,
  IProposalPageFormState,
  IProposalRiskDetails,
} from '../../../modules/production/proposal/page/generic/form';
import {
  validateRiskCoverDetailsField,
  validateRiskDetailsField,
} from '../../../modules/production/proposal/page/generic/validation';
import { removeObjectAtIndex } from '../../../utils/helper-utils';
import EnhancedFormattedNumberInput from '../../../components/enhanced-form/EnhancedFormattedNumberInput';
import { Tabs, Tab, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EnhancedCurrencyInput from '../../../components/enhanced-form/EnhancedCurrencyInput';

const initialValues = {
  initialError: {
    riskDescription: '',
    geoLocation: '',
    location: '',
    planCovers: [
      {
        planCoverID: '',
        riskRate: '',
        sumInsured: '',
        premium: '',
      },
    ],
  },
  initialTouched: {
    riskDescription: false,
    geoLocation: false,
    location: false,
    planCovers: [
      {
        planCoverID: false,
        riskRate: false,
        sumInsured: false,
        premium: false,
      },
    ],
  },
  initialCoverError: {
    planCoverID: '',
    riskRate: '',
    sumInsured: '',
    premium: '',
  },
  initialCoverTouched: {
    planCoverID: false,
    riskRate: false,
    sumInsured: false,
    premium: false,
  },
};

interface IProposalRiskDetailsWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  currencySymbol: string;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    backgroundColor: '#F9F9F9',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    padding: '20px 2px 10px 25px',
    marginBottom: '14px',
    position: 'relative',
    borderTopLeftRadius: 'unset',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 23.5%)',
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  coverFieldRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 20%)',
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  addBtn: {
    marginLeft: '2px',
    minWidth: 'auto',
    minHeight: 'auto',
    backgroundColor: 'transparent',
    padding: 0,
    margin: '0 0 0 10px',
    alignSelf: 'center',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  removeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  coverActionButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  clearBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 !important',
  },
  label: {
    color: '#000000',
    margin: '0px 0 -1.5px 0 !important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  labelDropdown: {
    color: '#000000',
    margin: '0px!important',
    marginTop: '4px!important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  labelNumber: {
    color: '#000000',
    margin: '0px!important',
    marginTop: '8px!important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  locationField: {
    width: '255px',
  },
  cardTitle: {
    fontSize: '16px !important',
    lineHeight: '21px !important',
    margin: '0 0 16px',
    padding: '0',
    fontFamily: 'SourceSansPro-Bold !important',
  },
  tabActiveTitle: {
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  tabsWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  fieldRowChildSpan2: {
    gridColumn: 'span 2',
  },
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    padding: '0',
    '&::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#ccc',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#aaa',
    },
  },
  tabsRoot: {
    minHeight: 'auto',
    overflowX: 'auto',
    '& .MuiTabs-flexContainer': {
      borderBottom: 'none',
    },
  },
  tabRoot: {
    textTransform: 'none',
    minWidth: 72,
    minHeight: '31px',
    padding: '4px 8px',
    marginRight: '4px',
    color: '#8e8e8e',
    border: '1px solid #E5E5E5',
    borderBottom: 'none',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: '#F0F0F0',
    fontFamily: 'SourceSansPro-Regular',
    '&.Mui-selected': {
      color: '#000000',
      fontFamily: 'SourceSansPro-SemiBold',
      backgroundColor: '#F9F9F9',
      borderBottom: 'none',
      fontWeight: 600,
    },
  },
  tabLabel: {
    fontSize: '14px',
    fontFamily: 'SourceSansPro-Regular',
    textTransform: 'none',
    lineHeight: '18px',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    backgroundColor: 'transparent',
  },
  tabIconButton: {
    marginLeft: '4px',
    padding: '0',
  },
  indicator: {
    display: 'none',
  },
  closeIcon: {
    fontSize: '16px',
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
  other?: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`risk-tabpanel-${index}`}
      aria-labelledby={`risk-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

const ProposalPropertyDescriptionDetailsWidget: React.FC<
  IProposalRiskDetailsWidgetProps
> = ({ pageState, onPageStateUpdate, disabledForm, lovs, currencySymbol }) => {
  const { classes } = useStyles();
  const values = pageState.values.riskDetails;
  const errors = pageState.errors.riskDetails;
  const touched = pageState.touched.riskDetails;

  const planCovers = pageState.values.covers;
  const policyCoverIds = planCovers.map((cover) => cover.policyCover);

  // Filter lovs.planCovers to only include the ones present in policyCoverIds
  const filteredLovsPlanCovers: Record<string, string> = Object.fromEntries(
    Object.entries(lovs.planCovers).filter(([key]) =>
      policyCoverIds.includes(key)
    )
  );

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const onFieldBlur = (
    fieldName: keyof IProposalRiskDetails,
    index: number
  ) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.riskDetails[index][fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = <K extends keyof IProposalRiskDetails>(
    fieldName: K,
    value: IProposalRiskDetails[K],
    index: number,
    touchedField = false
  ) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.values.riskDetails[index][fieldName] = value;
    newPageState.errors.riskDetails[index][fieldName] =
      validateRiskDetailsField(fieldName, value);

    if (touchedField) {
      newPageState.touched.riskDetails[index][fieldName] = true;
    }

    onPageStateUpdate(newPageState);
  };

  const shouldDisplayPlus = (riskDetail: IProposalRiskDetails) => {
    // Get selected planCoverIDs for this riskDetail
    const selectedPlanCoverIDs: string[] = riskDetail.planCovers
      .map((cover) => cover.planCoverID)
      .filter((id): id is string => Boolean(id));

    // Filter available plan covers by excluding already selected ones
    const availablePlanCovers: Record<string, string> = Object.fromEntries(
      Object.entries(filteredLovsPlanCovers).filter(
        ([id]) => !selectedPlanCoverIDs.includes(id)
      )
    );

    // Check if the first cover is filled
    const firstCoverFilled = Boolean(
      riskDetail.planCovers[0]?.planCoverID &&
        riskDetail.planCovers[0]?.planCoverID !== ''
    );

    // Determine if there are any plan covers left to add and the first cover is filled
    return (
      !disabledForm &&
      Object.keys(availablePlanCovers).length > 0 &&
      firstCoverFilled
    );
  };

  const renderRow = (riskDetail: IProposalRiskDetails, index: number) => {
    // Filter lovs.geoLocations by excluding already selected ones
    const filteredGeoLocationLov = { ...lovs.geoLocations };
    values.forEach((r) => {
      if (r !== riskDetail && r.geoLocation) {
        delete filteredGeoLocationLov[r.geoLocation];
      }
    });
    return (
      <div key={index} className={classes.container}>
        <div className={classes.fieldRow}>
          <EnhancedInput
            key={`riskDescription-${index}`}
            name={`riskDescription-${index}`}
            title="Risk Description*"
            placeholder="Risk Description"
            value={riskDetail.riskDescription}
            error={
              touched[index]?.riskDescription
                ? errors[index]?.riskDescription
                : ''
            }
            onBlur={() => onFieldBlur('riskDescription', index)}
            onChange={(e) =>
              onFieldUpdate('riskDescription', e.target.value, index, true)
            }
            disabled={disabledForm}
            type="text"
            material
            customStyles={{ labelStyles: classes.label }}
          />
          <EnhancedChipInput
            key={`geoLocation-${index}`}
            name={`geoLocation-${index}`}
            title="Geographical Location*"
            placeholder="Geographical Location"
            value={riskDetail.geoLocation}
            error={
              touched[index]?.geoLocation ? errors[index]?.geoLocation : ''
            }
            onChange={(v) => onFieldUpdate('geoLocation', v, index, true)}
            disabled={disabledForm}
            selectOptions={filteredGeoLocationLov}
            required
            multiple={false}
            material
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.labelDropdown,
            }}
          />
          <EnhancedInput
            key={`location-${index}`}
            name={`location-${index}`}
            title="Location"
            placeholder="Location"
            value={riskDetail.location}
            error={touched[index]?.location ? errors[index]?.location : ''}
            onBlur={() => onFieldBlur('location', index)}
            onChange={(e) =>
              onFieldUpdate('location', e.target.value, index, true)
            }
            disabled={disabledForm}
            type="textarea"
            material
            customStyles={{ labelStyles: classes.label }}
            className={classes.locationField}
          />
        </div>

        <WidgetSection
          title="Cover Distribution"
          narrowSpacing
          hideHeaderBorder
        >
          {riskDetail.planCovers.map((cover, coverIndex) =>
            renderCoverRow(cover, coverIndex, index, riskDetail)
          )}
        </WidgetSection>
      </div>
    );
  };

  const renderCoverRow = (
    cover: IPropertyCoverDetails,
    coverIndex: number,
    riskDetailIndex: number,
    riskDetail: IProposalRiskDetails
  ) => {
    const coverErrors = errors[riskDetailIndex]?.planCovers || [];
    const coverTouched = touched[riskDetailIndex]?.planCovers || [];

    // Get selected planCoverIDs excluding the current cover
    const selectedPlanCoverIDs: string[] = riskDetail.planCovers
      .filter((_, idx) => idx !== coverIndex)
      .map((cover) => cover.planCoverID)
      .filter((id): id is string => Boolean(id));

    // Filter available plan covers by excluding already selected ones
    const availablePlanCovers: Record<string, string> = Object.fromEntries(
      Object.entries(filteredLovsPlanCovers).filter(
        ([id]) => !selectedPlanCoverIDs.includes(id)
      )
    );

    return (
      <div key={coverIndex} className={classes.coverFieldRow}>
        <EnhancedChipInput
          key={`planCoverID-${coverIndex}`}
          name={`planCoverID-${coverIndex}`}
          title="Cover*"
          placeholder="Cover"
          value={cover.planCoverID}
          error={
            coverTouched[coverIndex]?.planCoverID
              ? coverErrors[coverIndex]?.planCoverID
              : ''
          }
          onChange={(v) =>
            onCoverFieldUpdate(
              'planCoverID',
              v,
              coverIndex,
              riskDetailIndex,
              true
            )
          }
          disabled={disabledForm}
          selectOptions={availablePlanCovers}
          required
          multiple={false}
          material
          customStyles={{ labelStyles: classes.labelDropdown }}
        />
        <EnhancedFormattedNumberInput
          key={`riskRate-${coverIndex}`}
          name={`riskRate-${coverIndex}`}
          title="Risk Rate"
          placeholder="Risk Rate"
          value={cover.riskRate}
          error={
            coverTouched[coverIndex]?.riskRate
              ? coverErrors[coverIndex]?.riskRate
              : ''
          }
          onBlur={() =>
            onCoverFieldBlur('riskRate', coverIndex, riskDetailIndex)
          }
          onChange={(e) =>
            onCoverFieldUpdate(
              'riskRate',
              parseFloat(e.target.value),
              coverIndex,
              riskDetailIndex
            )
          }
          disabled={disabledForm}
          material
          customStyles={{ labelStyles: classes.labelNumber }}
        />
        <EnhancedCurrencyInput
          key={`sumInsured-${coverIndex}`}
          name={`sumInsured-${coverIndex}`}
          title="Sum Insured"
          placeholder="Sum Insured"
          value={cover.sumInsured}
          error={
            coverTouched[coverIndex]?.sumInsured
              ? coverErrors[coverIndex]?.sumInsured
              : ''
          }
          onBlur={() =>
            onCoverFieldBlur('sumInsured', coverIndex, riskDetailIndex)
          }
          onChange={(e) =>
            onCoverFieldUpdate(
              'sumInsured',
              parseFloat(e.target.value),
              coverIndex,
              riskDetailIndex
            )
          }
          disabled={disabledForm}
          material
          customStyles={{ labelStyles: classes.labelNumber }}
          maxDecimalPercision={3}
          useCurrencyText={true}
          currencyText={currencySymbol}
        />
        <EnhancedCurrencyInput
          key={`premium-${coverIndex}`}
          name={`premium-${coverIndex}`}
          title="Premium"
          placeholder="Premium"
          value={cover.premium}
          error={
            coverTouched[coverIndex]?.premium
              ? coverErrors[coverIndex]?.premium
              : ''
          }
          onBlur={() =>
            onCoverFieldBlur('premium', coverIndex, riskDetailIndex)
          }
          onChange={(e) =>
            onCoverFieldUpdate(
              'premium',
              parseFloat(e.target.value),
              coverIndex,
              riskDetailIndex
            )
          }
          disabled={disabledForm}
          material
          customStyles={{ labelStyles: classes.labelNumber }}
          maxDecimalPercision={3}
          useCurrencyText={true}
          currencyText={currencySymbol}
        />
        {!disabledForm && (
          <div className={classes.coverActionButtons}>
            {shouldDisplayPlus(riskDetail) && (
              <IconButton
                onClick={() => onCoverAdd(riskDetailIndex)}
                aria-label="Add Cover"
                size="small"
              >
                <AddIcon fontSize="small" />
              </IconButton>
            )}
            {riskDetail.planCovers.length > 1 && (
              <IconButton
                onClick={() => onCoverDelete(coverIndex, riskDetailIndex)}
                aria-label="Remove Cover"
                size="small"
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        )}
      </div>
    );
  };

  const onCoverFieldUpdate = <K extends keyof IPropertyCoverDetails>(
    fieldName: K,
    value: IPropertyCoverDetails[K],
    coverIndex: number,
    riskDetailIndex: number,
    touchedField = false
  ) => {
    const newPageState = _.cloneDeep(pageState);

    newPageState.values.riskDetails[riskDetailIndex].planCovers[coverIndex][
      fieldName
    ] = value;
    newPageState.errors.riskDetails[riskDetailIndex].planCovers[coverIndex][
      fieldName
    ] = validateRiskCoverDetailsField(fieldName, value);

    if (touchedField) {
      newPageState.touched.riskDetails[riskDetailIndex].planCovers[coverIndex][
        fieldName
      ] = true;
    }

    // If RiskRate or SumInsured changed, and Premium has not been manually entered (not touched), calculate Premium
    if (
      (fieldName === 'riskRate' || fieldName === 'sumInsured') &&
      !newPageState.touched.riskDetails[riskDetailIndex].planCovers[coverIndex]
        .premium
    ) {
      const riskRate =
        newPageState.values.riskDetails[riskDetailIndex].planCovers[coverIndex]
          .riskRate;
      const sumInsured =
        newPageState.values.riskDetails[riskDetailIndex].planCovers[coverIndex]
          .sumInsured;

      if (riskRate != null && sumInsured != null) {
        const calculatedPremium = (riskRate * sumInsured) / 1000;
        newPageState.values.riskDetails[riskDetailIndex].planCovers[
          coverIndex
        ].premium = calculatedPremium;

        newPageState.errors.riskDetails[riskDetailIndex].planCovers[
          coverIndex
        ].premium = validateRiskCoverDetailsField('premium', calculatedPremium);
      }
    }

    onPageStateUpdate(newPageState);
  };

  const onCoverFieldBlur = (
    fieldName: keyof IPropertyCoverDetails,
    coverIndex: number,
    riskDetailIndex: number
  ) => {
    const newPageState = _.cloneDeep(pageState);

    newPageState.touched.riskDetails[riskDetailIndex].planCovers[coverIndex][
      fieldName
    ] = true;
    onPageStateUpdate(newPageState);
  };

  // Add a new cover row
  const onCoverAdd = (riskDetailIndex: number) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.values.riskDetails[riskDetailIndex].planCovers.push({
      planCoverID: '',
      riskRate: null,
      sumInsured: null,
      premium: null,
    });
    newPageState.errors.riskDetails[riskDetailIndex].planCovers.push({
      ...initialValues.initialCoverError,
    });
    newPageState.touched.riskDetails[riskDetailIndex].planCovers.push({
      ...initialValues.initialCoverTouched,
    });
    onPageStateUpdate(newPageState);
  };

  const onCoverDelete = (coverIndex: number, riskDetailIndex: number) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.values.riskDetails[riskDetailIndex].planCovers.splice(
      coverIndex,
      1
    );
    newPageState.errors.riskDetails[riskDetailIndex].planCovers.splice(
      coverIndex,
      1
    );
    newPageState.touched.riskDetails[riskDetailIndex].planCovers.splice(
      coverIndex,
      1
    );
    onPageStateUpdate(newPageState);
  };

  // Handle row addition
  const onRowAdd = () => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.values.riskDetails.push({
      riskDescription: '',
      geoLocation: '',
      location: '',
      planCovers: [
        {
          planCoverID: '',
          premium: null,
          riskRate: null,
          sumInsured: null,
        },
      ],
    });

    newPageState.errors.riskDetails.push({ ...initialValues.initialError });
    newPageState.touched.riskDetails.push({ ...initialValues.initialTouched });
    onPageStateUpdate(newPageState);
    setSelectedTab(newPageState.values.riskDetails.length - 1);
  };

  // Handle row deletion
  const onRowDelete = (index: number) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.values.riskDetails = removeObjectAtIndex(
      newPageState.values.riskDetails,
      index
    );
    newPageState.errors.riskDetails = removeObjectAtIndex(
      newPageState.errors.riskDetails,
      index
    );
    newPageState.touched.riskDetails = removeObjectAtIndex(
      newPageState.touched.riskDetails,
      index
    );
    onPageStateUpdate(newPageState);

    if (selectedTab >= newPageState.values.riskDetails.length) {
      setSelectedTab(Math.max(0, newPageState.values.riskDetails.length - 1));
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <WidgetPaper style={{ marginTop: '1em' }}>
      <WidgetSection title="Property Risk Details">
        <div className={classes.tabsContainer}>
          <div className={classes.tabsWrapper}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="Risk Details Tabs"
              variant="scrollable"
              scrollButtons="auto"
              classes={{ root: classes.tabsRoot, indicator: classes.indicator }}
            >
              {values.map((_, index) => (
                <Tab
                  key={index}
                  className={classes.tabRoot}
                  label={
                    <div
                      className={`${classes.tabLabel} ${
                        selectedTab === index ? classes.tabActiveTitle : ''
                      }`}
                    >
                      {`Risk ${index + 1}`}
                      {!disabledForm && values.length > 1 && (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            onRowDelete(index);
                          }}
                          aria-label="Remove Risk Detail"
                          className={classes.tabIconButton}
                        >
                          <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                      )}
                    </div>
                  }
                  id={`risk-tab-${index}`}
                  aria-controls={`risk-tabpanel-${index}`}
                />
              ))}
            </Tabs>
          </div>
          {!disabledForm && (
            <IconButton
              className={classes.addBtn}
              onClick={onRowAdd}
              disabled={disabledForm}
              aria-label="Add"
            >
              <AddIcon color="primary" />
            </IconButton>
          )}
        </div>
        {values.map((riskDetail, index) => (
          <TabPanel key={index} value={selectedTab} index={index}>
            {renderRow(riskDetail, index)}
          </TabPanel>
        ))}
      </WidgetSection>
    </WidgetPaper>
  );
};

export default ProposalPropertyDescriptionDetailsWidget;
