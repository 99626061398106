import { makeStyles } from 'tss-react/mui';
import React, { Fragment } from 'react';
import { IEnhancedFormInputBaseProps } from '.';
import { IAbstractRecord } from '../../models';
import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import EnhancedPercentageInput from '../enhanced-form/EnhancedPercentageInput';
import NewChipsInput from '../enhanced-form/NewChipsInput';
import { MAIN_ONE_THEME } from '../../constants';
import { cloneDeep } from 'lodash';
import clsx from 'clsx';

export interface IRepeaterBoxFormFieldProps
  extends Omit<IEnhancedFormInputBaseProps, 'error'> {
  error: Record<string, string>[];
  touched: Record<string, boolean>[];
  inputs: Record<string, DynamicFormInputType>;
  values: Record<string, DynamicFormInputType['value']>[];
  maxRepeaterLength?: number;
  minRepeaterLength?: number;
  addButtonText?: string;
  onChange: (values: IAbstractRecord[]) => void;
  onBlur?: (inputName: string, index: number) => void;
  onSearch?: (inputName: string, value: string) => void;
  onAddBox?: () => void;
  onDeleteBox?: (index: number) => void;
}

const useStyles = makeStyles()(() => ({
  container: {
    borderRadius: '4px',
    padding: '0 6.5px 10px 6.5px',
    position: 'relative',
    margin: '0 -6.5px',
  },
  multiContainer: {
    paddingTop: '20px',
    backgroundColor: '#f9f9f9',
  },
  title: {
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontSize: '14px',
    fontFamily: 'HelveticaNeue-Bold',
    marginBottom: '10px',
  },
  closedButton: {
    position: 'absolute',
    right: '10px',
    top: '5px',
    width: 'fit-content',
    border: 0,
    backgroundColor: 'transparent',
    padding: 0,
    color: '#A8A8A8',
    fontSize: '16px',
    fontFamily: 'SourceSansPro-Regular',
    marginRight: '3px',
    cursor: 'pointer',
    '&:hover:not(disabled)': {
      color: '#D30D2B',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  addButton: {
    border: 0,
    backgroundColor: 'transparent',
    padding: 0,
    color: 'rgba(211, 13, 43, 0.8)',
    cursor: 'pointer',
    textAlign: 'left',
    fontFamily: 'SourceSansPro-Regular',
    fontSize: '14px',
    textDecoration: 'underline',

    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
}));

const renderInputs = (
  inputs: IRepeaterBoxFormFieldProps['inputs'],
  values: Record<string, DynamicFormInputType['value']>,
  rowIndex: number,
  onFieldChange: (
    inputName: string,
    value: DynamicFormInputType['value']
  ) => void,
  onFieldBlur: (inputName: string) => void,
  errors?: Record<string, string>,
  touched?: Record<string, boolean>,
  onSearch?: (inputName: string, value: string) => void,
  disabled?: boolean
) => {
  return Object.values(inputs).map((input) => {
    let title = input.title;
    const length = input?.dynamicTitle?.length;
    if (length > 0 && rowIndex < length) {
      title = input.dynamicTitle[rowIndex];
    }
    switch (input.type) {
      case FormInputTypes.percentage:
        return (
          <EnhancedPercentageInput
            key={`${input.name}-${rowIndex}`}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            error={touched?.[input.name] ? errors?.[input.name] : ''}
            disabled={input.disabled || disabled}
            material={input.material}
            onChange={(event) => {
              const value = event.target.value;
              onFieldChange(input.name, value);
            }}
            onBlur={() => {
              onFieldBlur(input.name);
            }}
          />
        );
      case FormInputTypes.chips:
        return (
          <NewChipsInput
            key={`${input.name}-${rowIndex}`}
            name={input.name}
            title={title}
            multiple={input.multiple}
            required={input.required}
            value={values[input.name]}
            values={values[input.name] || []}
            disabled={input.disabled || disabled}
            items={input.selectOptions}
            material={input.material}
            placeholder={input.placeholder}
            showSelectAll={input.showSelectAll}
            error={touched?.[input.name] ? errors?.[input.name] : ''}
            onChange={(value) => {
              onFieldChange(input.name, value);
            }}
            onBlur={() => {
              onFieldBlur(input.name);
            }}
            onSearch={
              onSearch
                ? async (v) => {
                    if (onSearch) {
                      await onSearch(input.name, v);
                    }
                  }
                : null
            }
          />
        );
      default:
        return <Fragment key={input.name}></Fragment>;
    }
  });
};

const RepeaterBoxFormField: React.FC<IRepeaterBoxFormFieldProps> = ({
  title,
  values,
  inputs,
  addButtonText,
  minRepeaterLength,
  maxRepeaterLength,
  onChange,
  onBlur,
  onSearch,
  error = [],
  touched = [],
  onAddBox,
  onDeleteBox,
  disabled,
}) => {
  const { classes } = useStyles();
  const canAdd = values?.length < maxRepeaterLength;
  const canDelete = values?.length > minRepeaterLength;

  const addNewRecord = () => {
    {
      if (values.length >= maxRepeaterLength) return;
      const newValues = cloneDeep(values);
      newValues.push(
        Object.keys(inputs).reduce<Record<string, string>>((acc, key) => {
          acc[key] = '';
          return acc;
        }, {})
      );
      onChange(newValues);
      onAddBox && onAddBox();
    }
  };

  return (
    <div>
      {title && <div className={classes.title}>{title}</div>}
      <div className={classes.rowContainer}>
        {values.map((row, index) => (
          <div
            key={`values-${index}`}
            className={clsx(classes.container, [
              values.length > 1 && classes.multiContainer,
            ])}
          >
            {canDelete && !disabled && (
              <button
                type="button"
                className={classes.closedButton}
                onClick={() => {
                  const newValues = cloneDeep(values);
                  newValues.splice(index, 1);
                  onDeleteBox && onDeleteBox(index);
                  onChange(newValues);
                }}
              >
                ✖︎
              </button>
            )}
            {renderInputs(
              inputs,
              row,
              index,
              (inputName, value) => {
                const newValues = [...values];
                newValues[index][inputName] = value;
                onChange(newValues);
              },
              (inputName) => {
                onBlur(inputName, index);
              },
              error[index],
              touched[index],
              onSearch,
              disabled
            )}
          </div>
        ))}
        {canAdd && (
          <button
            type="button"
            className={classes.addButton}
            onClick={addNewRecord}
            disabled={disabled}
          >
            {addButtonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default RepeaterBoxFormField;
