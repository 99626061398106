import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { MAIN_ONE_THEME } from '../../../../../constants';
import { useMutation } from '@apollo/client';
import { activateTreatyMutation, closeTreatyMutation } from '../queries';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import { getError } from '../../../../../utils/graph-utils';
import { IConfirmation } from '../../../../../redux/confirmation/types';
import ToastSuccessMessage from '../../../../../components/ToastSuccessMessage';
import { OpenConfirmationAction } from '../../../../../redux/confirmation/actions';
import { useAppDispatch } from '../../../../../redux/hooks';
import SimpleActionBar from '../../../../../components/form-fields/menu/SimpleActionBar';
import { IEnhancedMenuItem } from '../../../../../components/form-fields/table';
import EnhancedStepper from '../../../../../components/common/EnhancedStepper';

interface IStepper {
  status: string;
  isButtonDisabled: boolean;
  currentTreatyId: string;
  validate: () => boolean;
  onSuccess?: () => void;
}

const useStyles = makeStyles()(() => ({
  stepperContainer: {
    margin: '20px 0',
    display: 'flex',
    width: '100%',
  },
  steps: {
    width: '66%',
  },
  actions: {
    width: '33%',
  },
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '95%',
    width: '95%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
}));

const Stepper: React.FC<IStepper> = ({
  status,
  isButtonDisabled,
  currentTreatyId,
  validate,
  onSuccess,
}) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const [activateTreaty, { loading: activateLoading }] = useMutation(
    activateTreatyMutation
  );
  const [closeTreaty, { loading: closeLoading }] =
    useMutation(closeTreatyMutation);

  const activeStep = useMemo(() => {
    const statusLower = status?.toLowerCase();
    switch (statusLower) {
      case 'new':
        return 'New';
      case 'active':
        return 'Active';
      case 'expired':
        return 'Expired';
      case 'closed':
        return 'Closed';
      default:
        return 'New';
    }
  }, [status]);

  const steps: string[] = [
    'New',
    'Active',
    activeStep == 'Closed' ? 'Closed' : 'Expired',
  ];

  const handleActivate = async () => {
    const isValid = validate();
    if (!isValid) {
      return;
    }

    const confirmation: IConfirmation = {
      open: true,
      title: 'Activate Treaty',
      message: 'Are you sure you want to activate this treaty?',
      submitButtonText: 'Submit',
      cancelButtonText: 'Cancel',
      callback: async () => {
        try {
          const res = await activateTreaty({
            variables: {
              CurrentTreatyID: currentTreatyId,
            },
          });

          if (!res.errors) {
            toast.success(
              <ToastSuccessMessage>
                Treaty successfully activated
              </ToastSuccessMessage>
            );
            onSuccess();
          }
        } catch (error) {
          toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
        }
      },
    };
    dispatch(OpenConfirmationAction(confirmation));
  };

  const handleClose = async () => {
    const confirmation: IConfirmation = {
      open: true,
      title: 'Close Treaty',
      message: 'Are you sure you want to close this treaty?',
      submitButtonText: 'Submit',
      cancelButtonText: 'Cancel',
      callback: async () => {
        try {
          const res = await closeTreaty({
            variables: {
              CurrentTreatyID: currentTreatyId,
            },
          });

          if (!res.errors) {
            toast.success(
              <ToastSuccessMessage>
                Treaty successfully closed
              </ToastSuccessMessage>
            );
            onSuccess();
          }
        } catch (error) {
          toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
        }
      },
    };
    dispatch(OpenConfirmationAction(confirmation));
  };

  const actions: IEnhancedMenuItem[] = useMemo(() => {
    const finalActions: IEnhancedMenuItem[] = [];
    if (activeStep === 'New') {
      finalActions.push({
        name: 'activate',
        title: 'Activate',
        onClick: () => handleActivate(),
        isPrimary: true,
        disabled: isButtonDisabled || activateLoading,
      });
    }

    if (activeStep !== 'Expired' && activeStep !== 'Closed') {
      finalActions.push({
        name: 'close',
        title: 'Close',
        onClick: () => handleClose(),
        disabled: isButtonDisabled || closeLoading,
      });
    }

    return finalActions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateLoading, activeStep, closeLoading, isButtonDisabled, validate]);

  return (
    <div className={classes.stepperContainer}>
      <div className={classes.steps}>
        <EnhancedStepper
          activeStep={activeStep}
          steps={steps}
          className={classes.stepper}
        />
      </div>
      <div className={classes.actions}>
        <SimpleActionBar items={actions} />
      </div>
    </div>
  );
};

export default Stepper;
