import React, { useEffect, useState } from 'react';
import { ILineAmendmentProps } from '.';
import AmendmentDetails from '../components/AmendmentDetails';
import PolicyCovers from '../components/PolicyCovers';
import CostsAndCharges from '../components/CostsAndCharges';
import MotorDetails from '../components/MotorDetails';
import { useLazyQuery } from '@apollo/client';
import { getCoversPersonsQuery, getMotorDetailsQuery } from '../queries';
import {
  convertToPolicyPlanProposalCovers,
  mapToAmendmentDetails,
} from '../utils';
import { cloneDeep } from 'lodash';
import { initialCoverValues } from '../components/content';
import Loader from '../../../../components/Loader';
import InsuredWidget from '../components/policy-persons-widgets/InsuredWidget';
import { IAbstractRecord } from '../../../../models';
import { getAmendmentInitialState } from '../content';
import { convertToPolicyPersons } from '../components/policy-persons-widgets/utils';

export interface IMotorLovs {
  plans: IAbstractRecord;
}

const MotorAmendment: React.FC<ILineAmendmentProps> = ({
  generalData,
  data,
  pageState,
  handleStateChange,
}) => {
  const isGroupPolicy = generalData.policyType === 'GROUP';
  const [loading, setLoading] = useState<boolean>();
  const isDisabled = ['COMPLETED', 'CANCELLED', 'EXPIRED'].includes(
    generalData.amendmentStatus
  );

  const [lovs, setLovs] = useState<IMotorLovs>({
    plans: {},
  });

  const [getCoversPersonsLazy] = useLazyQuery(getCoversPersonsQuery('both'));

  const [getMotorDetailsLazy] = useLazyQuery(getMotorDetailsQuery, {
    variables: {
      selectedAmendmentID: generalData.currentAmendmentId,
      version: generalData.version,
    },
    errorPolicy: 'all',
  });

  const initialize = async () => {
    setLoading(true);

    const { data: detailsData } = await getMotorDetailsLazy();

    const newValues = mapToAmendmentDetails({
      ...data?.[0],
      ...detailsData.Production.queries.getAmendmentDetails[0],
    });

    const newErrors = cloneDeep(
      getAmendmentInitialState(generalData.lineCategory).errors
    );
    const newTouched = cloneDeep(
      getAmendmentInitialState(generalData.lineCategory).touched
    );

    // get covers and/or persons --start
    const { data: coversPersonsData } = await getCoversPersonsLazy({
      variables: {
        id: generalData.proposalId,
        version: generalData.version,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
    const covers = convertToPolicyPlanProposalCovers(coversPersonsData, data);
    newValues.covers = covers;
    newErrors.covers = covers.map(() => initialCoverValues.initialError);
    newTouched.covers = covers.map(() => initialCoverValues.initialTouched);

    const persons = convertToPolicyPersons(coversPersonsData);
    newValues.policyPersons = persons;
    newErrors.policyPersons = {
      insured: persons.insured.map(() => newErrors['policyPersons'].insured[0]),
    };
    newTouched.policyPersons = {
      insured: persons.insured.map(
        () => newTouched['policyPersons'].insured[0]
      ),
    };
    // get covers and/or persons --end

    setLovs({
      plans: { [data?.[0]?.plan_Id]: data?.[0]?.plan_Name },
    });

    handleStateChange({
      values: newValues,
      errors: newErrors,
      touched: newTouched,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <AmendmentDetails
        generalData={generalData}
        lovs={lovs}
        pageState={pageState}
        onChange={handleStateChange}
      />
      <PolicyCovers
        generalData={generalData}
        pageState={pageState}
        onChange={handleStateChange}
        isGroupPolicy={isGroupPolicy}
      />
      <CostsAndCharges
        generalData={generalData}
        pageState={pageState}
        onPageStateUpdate={handleStateChange}
        isDisabled={
          isDisabled ||
          generalData?.businessUser?.editableLines?.includes(
            generalData?.lineExternalCode
          )
        }
      />
      <InsuredWidget
        generalData={generalData}
        pageState={pageState}
        onPageStateUpdate={handleStateChange}
        isDisabled={isDisabled}
        maxNumber={
          data?.[0]?.policy_PlanID?.views?.PlanConfigManagement_all?.properties
            ?.MaxNbInsured
        }
      />
      {!isGroupPolicy && (
        <MotorDetails
          generalData={generalData}
          pageState={pageState}
          onPageStateUpdate={handleStateChange}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
};

export default MotorAmendment;
