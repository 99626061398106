import { makeStyles } from 'tss-react/mui';

const baseMaterialLabel = {
  fontSize: '14px !important',
  lineHeight: '16px !important',
  fontFamily: 'SourceSansPro-SemiBold !important',
};

export const useAmendmentStyles = makeStyles()(() => {
  return {
    fullWidthItem: {
      gridColumn: '1 / -1',
      width: '100%',
    },
    labelHeader: baseMaterialLabel,
    labelDropdown: {
      color: '#000000',
      margin: '5px 0 0 0 !important',
      padding: '0px!important',
      ...baseMaterialLabel,
    },
  };
});
