import { gql } from '@apollo/client';

export const getReinsurersListQuery = gql`
  query ReinsurersList(
    $SelectedCompany: String!
    $pagination: PaginationInput!
    $KeywordSearch: String
  ) {
    Production {
      queries {
        ReinsurersList(
          SelectedCompany: $SelectedCompany
          KeywordSearch: $KeywordSearch
          pagination: $pagination
        ) {
          items {
            salesforceManagement_BusinessPartner_Id
            salesforceManagement_BusinessPartner_Code
            salesforceManagement_BusinessPartner_FullName
          }
          paging {
            pageSize
            pageNumber
            totalCount
          }
        }
      }
    }
  }
`;
