import React, { useState } from 'react';
import EnhancedModal from '../../../../../../../components/common/generic-modal/EnhancedModal';
import { IProductionDetailsSummary } from '../../..';
import PolicyCertficateMarineGroupSummaryDialog from './PolicyCertficateMarineGroupSummaryDialog';
import PolicyCertficateMarineGroupSummary from './PolicyCertficateMarineGroupSummary';
import { IAbstractRecord } from '../../../../../../../models';
import { mapSummaryFields } from './utils';

interface IPolicyCertficateMarineGroupSummaryDialogShellProps {
  data: IProductionDetailsSummary;
  onClose: () => void;
  onSuccess: () => void;
}

const PolicyCertficateMarineGroupSummaryDialogShell: React.FC<
  IPolicyCertficateMarineGroupSummaryDialogShellProps
> = ({ data, onClose = () => undefined, onSuccess }) => {
  const [mode, setMode] = useState<{
    type: 'summary' | 'form';
    payload?: {
      values: IAbstractRecord;
      lovs: IAbstractRecord;
      payload: IAbstractRecord;
    };
  }>({
    type: 'form',
  });

  if (mode.type === 'summary') {
    return (
      <EnhancedModal
        onClose={onClose}
        open={true}
        blur={true}
        title={'Summary'}
      >
        <PolicyCertficateMarineGroupSummary
          data={mode.payload}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      </EnhancedModal>
    );
  }

  return (
    <EnhancedModal
      onClose={onClose}
      open={true}
      blur={true}
      title={'Add Certificate'}
    >
      <PolicyCertficateMarineGroupSummaryDialog
        data={data}
        onClose={onClose}
        onSuccess={(payload) => {
          setMode({
            type: 'summary',
            payload: {
              values: mapSummaryFields(payload.data),
              lovs: payload.lovs,
              payload: payload.data,
            },
          });
          window.scrollTo(0, 0);
        }}
      />
    </EnhancedModal>
  );
};

export default PolicyCertficateMarineGroupSummaryDialogShell;
