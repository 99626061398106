import dayjs from 'dayjs';
import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  policyDetails: {
    statusReason: '',
    lineOfBusiness: '',
    plan: '',
    agencyName: '',
    agentName: '',
    customerName: '',
    createdBy: '',
    createdOn: '',
    modifiedBy: '',
    modifiedOn: '',
    nameOnLicenseEn: '',
    nameOnLicenseAr: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    phoneType: '',
    phoneNumber: '',
    countryId: '',
    country: '',
    city: '',
    street: '',
    building: '',
    policyNumber: '',
    isRenewal: '',
    renewalNumber: '',
    policyEffectiveDate: '',
    policyExpiryDate: '',
    towingService: '',
    plateCode: '',
    plateNumber: '',
    usageType: '',
    bodyType: '',
    engineType: '',
    brandId: '',
    brand: '',
    modelId: '',
    model: '',
    yearOfMake: 0,
    motor: '',
    weight: 0,
    engineSize: 0,
    seats: 0,
    chassis: '',
    horsePower: 0,
    vignetteCode: '',
    vignette: 0,
    fullPolicyNumber: '',
    productCode: '',
    policyIssueDate: '',
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  policyNumber: {
    name: 'policyNumber',
    title: 'Policy Number',
    type: FormInputTypes.text,
    placeholder: 'Policy Number',
    value: initialValues.policyDetails.policyNumber,
    disabled: true,
  },
  isRenewal: {
    name: 'isRenewal',
    title: 'Renewal',
    type: FormInputTypes.chips,
    placeholder: 'Renewal',
    value: !isEmpty(initialValues.policyDetails.isRenewal)
      ? initialValues.policyDetails.isRenewal
      : [],
    required: true,
    multiple: false,
    triggerUpdate: true,
  },
  renewalNumber: {
    name: 'renewalNumber',
    title: 'Renewal Number',
    type: FormInputTypes.text,
    placeholder: 'Renewal Number',
    value: initialValues.policyDetails.renewalNumber,
  },
  policyEffectiveDate: {
    name: 'policyEffectiveDate',
    title: 'Policy Effective Date',
    type: FormInputTypes.date,
    placeholder: 'Policy Effective Date',
    value: initialValues.policyDetails.policyEffectiveDate,
    minDate: new Date(),
    maxDate:
      dayjs().month() === 11
        ? dayjs().endOf('month').toDate()
        : dayjs().add(1, 'month').toDate(),
    required: true,
    disabled: false,
  },
  policyIssueDate: {
    name: 'policyIssueDate',
    title: 'Policy Issue Date',
    type: FormInputTypes.date,
    placeholder: 'Policy Issue Date',
    value: initialValues.policyDetails.policyIssueDate,
    disabled: true,
  },
  policyExpiryDate: {
    name: 'policyExpiryDate',
    title: 'Policy Expiry Date',
    type: FormInputTypes.date,
    placeholder: 'Policy Expiry Date',
    value: initialValues.policyDetails.policyExpiryDate,
    disabled: true,
  },
  product: {
    name: 'product',
    title: 'Product',
    type: FormInputTypes.text,
    placeholder: 'Product',
    value: initialValues.policyDetails.plan,
    disabled: true,
  },
  productCode: {
    name: 'productCode',
    title: 'Product Code',
    type: FormInputTypes.text,
    placeholder: 'Product',
    value: initialValues.policyDetails.productCode,
    disabled: true,
    hidden: true,
  },
  towingService: {
    name: 'towingService',
    title: 'Towing Service',
    type: FormInputTypes.text,
    placeholder: 'Towing Service',
    value: initialValues.policyDetails.towingService,
    disabled: true,
  },
  vignetteCode: {
    name: 'vignetteCode',
    title: 'Vignette Code',
    type: FormInputTypes.text,
    placeholder: 'Vignette Code',
    value: initialValues.policyDetails.vignetteCode,
  },
  vignette: {
    name: 'vignette',
    title: 'Vignette',
    type: FormInputTypes.number,
    placeholder: 'Vignette',
    value: initialValues.policyDetails.vignette,
  },
  nameOnLicenseEn: {
    name: 'nameOnLicenseEn',
    title: 'Name on License (EN)',
    type: FormInputTypes.text,
    placeholder: 'Name on License (EN)',
    value: initialValues.policyDetails.nameOnLicenseEn,
    required: true,
  },
  nameOnLicenseAr: {
    name: 'nameOnLicenseAr',
    title: 'Name on License (AR)',
    type: FormInputTypes.text,
    placeholder: 'Name on License (AR)',
    value: initialValues.policyDetails.nameOnLicenseAr,
    required: true,
  },
  firstName: {
    name: 'firstName',
    title: 'First Name',
    type: FormInputTypes.text,
    placeholder: 'First Name',
    value: initialValues.policyDetails.firstName,
    required: true,
  },
  middleName: {
    name: 'middleName',
    title: 'Middle Name',
    type: FormInputTypes.text,
    placeholder: 'Middle Name',
    value: initialValues.policyDetails.middleName,
    required: false,
  },
  lastName: {
    name: 'lastName',
    title: 'Last Name',
    type: FormInputTypes.text,
    placeholder: 'Last Name',
    value: initialValues.policyDetails.lastName,
    required: true,
  },
  email: {
    name: 'email',
    title: 'Email',
    type: FormInputTypes.text,
    placeholder: 'Email',
    value: initialValues.policyDetails.email,
    disabled: true,
  },
  phoneType: {
    name: 'phoneType',
    title: 'Phone Type',
    type: FormInputTypes.chips,
    placeholder: 'Phone Type',
    value: !isEmpty(initialValues.policyDetails.phoneType)
      ? initialValues.policyDetails.phoneType
      : [],
    required: true,
    multiple: false,
  },
  mobileNumber: {
    name: 'mobileNumber',
    title: 'Phone Number',
    type: FormInputTypes.phoneNumber,
    placeholder: 'Phone Number',
    value: initialValues.policyDetails.phoneNumber,
    required: true,
    editCountryCode: false,
  },
  country: {
    name: 'country',
    title: 'Country',
    type: FormInputTypes.chips,
    placeholder: 'Country',
    value: !isEmpty(initialValues.policyDetails.countryId)
      ? initialValues.policyDetails.countryId
      : [],
    required: true,
    multiple: false,
  },
  city: {
    name: 'city',
    title: 'City',
    type: FormInputTypes.text,
    placeholder: 'City',
    value: initialValues.policyDetails.city,
    required: true,
  },
  street: {
    name: 'street',
    title: 'Street',
    type: FormInputTypes.text,
    placeholder: 'Street',
    value: initialValues.policyDetails.street,
    required: false,
  },
  building: {
    name: 'building',
    title: 'Building',
    type: FormInputTypes.text,
    placeholder: 'Building',
    value: initialValues.policyDetails.building || null,
    required: false,
  },
  plateCode: {
    name: 'plateCode',
    title: 'Plate Code',
    type: FormInputTypes.chips,
    placeholder: 'Plate Code',
    value: !isEmpty(initialValues.policyDetails.plateCode)
      ? initialValues.policyDetails.plateCode
      : [],
    multiple: false,
  },
  plateNumber: {
    name: 'plateNumber',
    title: 'Plate Number',
    type: FormInputTypes.number,
    placeholder: 'Plate Number',
    value: initialValues.policyDetails.plateNumber,
  },
  usageType: {
    name: 'usageType',
    title: 'Usage Type',
    type: FormInputTypes.text,
    placeholder: 'Usage',
    value: initialValues.policyDetails.usageType,
    disabled: true,
  },
  bodyType: {
    name: 'bodyType',
    title: 'Body',
    type: FormInputTypes.text,
    placeholder: 'Body',
    value: initialValues.policyDetails.bodyType,
    disabled: true,
  },
  engineType: {
    name: 'engineType',
    title: 'Engine Type',
    type: FormInputTypes.chips,
    placeholder: 'Engine Type',
    value: initialValues.policyDetails.engineType,
    disabled: false,
    multiple: false,
  },
  brand: {
    name: 'brand',
    title: 'Make',
    type: FormInputTypes.chips,
    placeholder: 'Make',
    value: !isEmpty(initialValues.policyDetails.brandId)
      ? initialValues.policyDetails.brandId
      : [],
    required: true,
    multiple: false,
  },
  model: {
    name: 'model',
    title: 'Model',
    type: FormInputTypes.newselect,
    placeholder: 'Model',
    value: !isEmpty(initialValues.policyDetails.modelId)
      ? initialValues.policyDetails.modelId
      : [],
    required: true,
    selectOptions: {},
  },
  yearOfMake: {
    name: 'yearOfMake',
    title: 'Year of Make',
    type: FormInputTypes.number,
    placeholder: 'Year of Make',
    value: initialValues.policyDetails.yearOfMake,
    disabled: false,
  },
  motor: {
    name: 'motor',
    title: 'Engine',
    type: FormInputTypes.text,
    placeholder: 'Engine',
    value: initialValues.policyDetails.motor,
    required: true,
  },
  weight: {
    name: 'weight',
    title: 'Weight',
    type: FormInputTypes.number,
    placeholder: 'Weight',
    value: initialValues.policyDetails.weight,
    disabled: true,
  },
  engineSize: {
    name: 'engineSize',
    title: 'Engine Size (in cc)',
    type: FormInputTypes.number,
    placeholder: 'Engine Size (in cc)',
    value: initialValues.policyDetails.engineSize,
    disabled: true,
  },
  seats: {
    name: 'seats',
    title: 'Seats',
    type: FormInputTypes.number,
    placeholder: 'Seats',
    value: initialValues.policyDetails.seats,
    disabled: true,
  },
  chassis: {
    name: 'chassis',
    title: 'Chassis',
    type: FormInputTypes.text,
    placeholder: 'chassis',
    value: initialValues.policyDetails.chassis,
    required: true,
  },
  horsePower: {
    name: 'horsePower',
    title: 'Horse Power',
    type: FormInputTypes.number,
    placeholder: 'Horse Power',
    value: initialValues.policyDetails.horsePower,
    required: true,
  },
};
