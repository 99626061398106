import { IMarineGroupCertficateForm } from '.';
import { IAbstractRecord } from '../../../../../../../models';

export const getInitialFormState = (data: IAbstractRecord): IFormState => ({
  values: {
    policyDetails: {
      subline: '',
      plan: '',
      policyEffectiveDate: data.PolicyEffectiveDate,
      effectiveDate: data.PolicyEffectiveDate,
      policyExpiryDate: data.PolicyExpiryDate,
      expiryDate: data.PolicyExpiryDate,
    },
    covers: [
      {
        isMain: false,
        isMandatory: false,
        policyCover: '',
        premium: undefined,
        currency: '',
        coverSumInsured: undefined,
        sumInsuredIsAdditive: false,
        excessOnClaimType: '',
        excessOnClaimAmount: undefined,
        isEditable: false,
        excessOnClaimPercentage: undefined,
        excessOnClaimDays: undefined,
        coverEffectiveFrom: '',
        coverEffectiveTo: '',
        planCoverId: '',
      },
    ],
    costs: {
      policyCost: 0,
      chargesPercentage: 0,
      tpaFeesAmount: 0,
    },
    details: {
      matter: '',
      description: '',
      vesselName: '',
      vesselAge: 0,
      from: '',
      to: '',
      via: '',
      survey: '',
      cargoContract: '',
      sumPerShipment: 0,
      lcNumber: '',
      lcIssueDate: '',
      bank: '',
      claimsPayableBy: 'UFA',
      loading: 0,
      remarks: '',
    },
  },
  errors: {
    policyDetails: {
      subline: '',
      plan: '',
      policyEffectiveDate: '',
      policyExpiryDate: '',
    },
    covers: [
      {
        isMandatory: '',
        policyCover: '',
        policyCoverId: '',
        premium: '',
        currency: '',
        coverSumInsured: '',
        sumInsuredIsAdditive: '',
        excessOnClaimType: '',
        excessOnClaimAmount: '',
        excessOnClaimPercentage: '',
        excessOnClaimDays: '',
        coverEffectiveFrom: '',
        coverEffectiveTo: '',
      },
    ],
    costs: {
      policyCost: '',
      chargesPercentage: '',
      tpaFeesAmount: '',
    },
    details: {
      matter: '',
      description: '',
      vesselName: '',
      vesselAge: '',
      from: '',
      to: '',
      via: '',
      survey: '',
      cargoContract: '',
      sumPerShipment: '',
      lcNumber: '',
      lcIssueDate: '',
      bank: '',
      claimsPayableBy: '',
      loading: '',
      remarks: '',
    },
  },
  touched: {
    policyDetails: {
      subline: false,
      plan: false,
      policyEffectiveDate: false,
      policyExpiryDate: false,
    },
    covers: [
      {
        isMandatory: false,
        policyCover: false,
        premium: false,
        currency: false,
        coverSumInsured: false,
        sumInsuredIsAdditive: false,
        excessOnClaimType: false,
        excessOnClaimAmount: false,
        excessOnClaimPercentage: false,
        excessOnClaimDays: false,
        coverEffectiveFrom: false,
        coverEffectiveTo: false,
      },
    ],
    costs: {
      policyCost: false,
      chargesPercentage: false,
      tpaFeesAmount: false,
    },
    details: {
      matter: false,
      description: false,
      vesselName: false,
      vesselAge: false,
      from: false,
      to: false,
      via: false,
      survey: false,
      cargoContract: false,
      sumPerShipment: false,
      lcNumber: false,
      lcIssueDate: false,
      bank: false,
      claimsPayableBy: false,
      loading: false,
      remarks: false,
    },
  },
});

export interface IFormState {
  values: IMarineGroupCertficateForm;
  errors: {
    policyDetails: Record<string, string>;
    covers: Record<string, string>[];
    costs: Record<string, string>;
    details: Record<string, string>;
  };
  touched: {
    policyDetails: Record<string, boolean>;
    covers: Record<string, boolean>[];
    costs: Record<string, boolean>;
    details: Record<string, boolean>;
  };
}
