import { gql } from '@apollo/client';

export function getRelatedCompanies() {
  return gql`
    query getRelatedCompanies() {
      SalesforceManagement {
        lookups {
          relatedCompanies {
            Code
            Id
            Title
          }
        }
      }
    }
  `;
}

export function exportTransactions() {
  return gql`
    query GetTransactionByCompanyAndDate(
      $SelectedCompany: String
      $TransactionDateFrom: Date
      $TransactionDateTo: Date
    ) {
      Accounting {
        queries {
          GetTransactionByCompanyAndDate(
            SelectedCompany: $SelectedCompany
            TransactionDateFrom: $TransactionDateFrom
            TransactionDateTo: $TransactionDateTo
          ) {
            accounting_Transactions_TransactionNumber
            accounting_Transactions_TransactionDate
            accounting_Transactions_ReferenceNumber
            accounting_Transactions_Description
            transactionDetails_Description
            transactionDetails_AccountDebit
            transactionDetails_AccountCredit
            transactionDetails_SystemCredit
            transactionDetails_SystemDebit
            transactionDetails_SystemSecondaryCredit
            transactionDetails_SystemSecondaryDebit
            accounting_Transactions_TransactionType {
              LongTitle
            }
            transactionDetails_RelatedAccount {
              views {
                Accounting_all {
                  properties {
                    AccountName
                    AccountID
                    AccountCurrency {
                      Symbol
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
