import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query LIST_PROPOSALLISTS(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedLines: [String]
      $selectedSublines: [String]
      $selectedPlans: [String]
      $selectedBusinessPartners: [String]
      $SelectedStatuses: [Production_ProposalStatuses]
      $selectedStatusReasons: [String]
      $FromCreatedOn: Date
      $ToCreatedOn: Date
      $FromEffectiveOn: Date
      $ToEffectiveOn: Date
      $selectedInsuredIDs: [String]
      $KeywordSearch: String
      $OrderBy: Core_OrderBy
    ) {
      Production {
        queries {
          getProposalList(
            KeywordSearch: $KeywordSearch
            OrderBy: $OrderBy
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            selectedStatuses: $SelectedStatuses
            selectedStatusReasons: $selectedStatusReasons
            selectedLines: $selectedLines
            selectedSublines: $selectedSublines
            selectedPlans: $selectedPlans
            selectedBusinessPartners: $selectedBusinessPartners
            FromCreatedOn: $FromCreatedOn
            ToCreatedOn: $ToCreatedOn
            FromPolicyEffectiveDate: $FromEffectiveOn
            ToPolicyEffectiveDate: $ToEffectiveOn
            selectedInsuredIDs: $selectedInsuredIDs
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              production_Proposal_Id
              production_Proposal_ProposalName
              production_Proposal_InternalCode
              production_Proposal_PolicyEffectiveDate
              production_Proposal_PolicyExpiryDate
              production_Proposal_Status
              production_Proposal_StatusReason
              production_Proposal_MotorDetailsID {
                id
                views {
                  Production_all {
                    properties {
                      NameOnLicenseEn
                      NameOnLicenseAr
                    }
                  }
                }
              }
              production_Proposal_PrimaryBPID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Code
                    }
                  }
                }
              }
              production_Proposal_InsuredID {
                id
                views {
                  Production_all {
                    properties {
                      PersonCode
                      FullName
                    }
                  }
                }
              }
              production_Proposal_createdOn
              production_Proposal_modifiedOn
              line_Id
              line_Name
              line_ExternalCode
              production_Proposal_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                    }
                  }
                }
              }
              production_Proposal_SublineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                    }
                  }
                }
              }
              production_Proposal_PlanID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                    }
                  }
                }
              }
              production_Proposal_BusinessUserID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Account
                    }
                  }
                }
              }
              production_Proposal_PolicyBPID {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function getAssignedBPPlanListWithoutPaginationQuery() {
  return gql`
    query getAssignedBPPlanListWithoutPaginationLine(
      $selectedBusinessUserID: String!
      $selectedLineID: [String]
      $selectedPlanID: [String]
    ) {
      SalesforceManagement {
        queries {
          getAssignedBPPlanListWithoutPagination(
            selectedBusinessUserID: $selectedBusinessUserID
            selectedLineID: $selectedLineID
            selectedPlanID: $selectedPlanID
          ) {
            salesforceManagement_AssignedBPPlan_LineID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Id
                    Name
                    ExternalCode
                  }
                }
              }
            }
            salesforceManagement_AssignedBPPlan_BusinessPartnerID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    Code
                    PersonID {
                      id
                      views {
                        SalesforceManagement_all {
                          properties {
                            FullName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            salesforceManagement_AssignedBPPlan_PlanID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                    ExternalCode
                    SublineID {
                      id
                      views {
                        PlanConfigManagement_all {
                          properties {
                            Name
                            ExternalCode
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getAssignedBPPlanListQuery() {
  return gql`
    query getAssignedBPPlanListLine(
      $selectedBusinessUserID: String!
      $selectedLineID: [String]
      $pagination: PaginationInput!
      $KeywordSearch: String
    ) {
      SalesforceManagement {
        queries {
          getAssignedBPPlanList(
            selectedBusinessUserID: $selectedBusinessUserID
            selectedLineID: $selectedLineID
            pagination: $pagination
            KeywordSearch: $KeywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              salesforceManagement_AssignedBPPlan_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Id
                      Name
                      ExternalCode
                    }
                  }
                }
              }
              salesforceManagement_AssignedBPPlan_BusinessPartnerID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Code
                      PersonID {
                        id
                        views {
                          SalesforceManagement_all {
                            properties {
                              FullName
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              salesforceManagement_AssignedBPPlan_PlanID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                      SublineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              Name
                              ExternalCode
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getStaticLovsQuery() {
  return gql`
    query GetStaticLovs {
      Production {
        lookups {
          proposalStatusReasons {
            Id
            Title
          }
        }
      }
      Production_ProposalStatuses: __type(name: "Production_ProposalStatuses") {
        name
        enumValues {
          name
        }
      }
    }
  `;
}

export function getAssignedPersonsQuery() {
  return gql`
    query GetAssignedPersonsWithPagination(
      $selectedBusinessPartners: [String]
      $pagination: PaginationInput!
      $searchKeyword: String
    ) {
      Production {
        queries {
          GetAssignedPersonsWithPagination(
            selectedBusinessPartners: $selectedBusinessPartners
            pagination: $pagination
            searchKeyword: $searchKeyword
            selectedType: INSURED
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              salesforceManagement_AssignedPerson_PersonID {
                id
              }
              salesforceManagement_AssignedPerson_FullName
              salesforceManagement_AssignedPerson_PersonCode
            }
          }
        }
      }
    }
  `;
}

export function getAssignedPersonsWithoutPaginationQuery() {
  return gql`
    query getAssignedPersons(
      $selectedBusinessPartners: [String]
      $pagination: PaginationInput!
      $searchKeyword: String
    ) {
      Production {
        queries {
          GetAssignedPersons(
            selectedBusinessPartners: $selectedBusinessPartners
            selectedType: INSURED
          ) {
            salesforceManagement_AssignedPerson_PersonID {
              id
            }
            salesforceManagement_AssignedPerson_FullName
            salesforceManagement_AssignedPerson_PersonCode
          }
        }
      }
    }
  `;
}
