import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { IAbstractRecord } from '../../models';
import { IMarineInfo } from '.';
import { IProductionDetailsSummary } from '../../modules/production/policy/page';

export const getMarineInputs = (
  lovs: Record<string, IAbstractRecord>,
  values: IProductionDetailsSummary
): Record<string, DynamicFormInputType> => {
  return {
    Matter: {
      name: 'Matter',
      title: 'Matter',
      type: FormInputTypes.chips,
      placeholder: 'Matter',
      value: values?.Marine?.matter || '',
      required: true,
      hidden: false,
      selectOptions: lovs?.matters,
    },
    Description: {
      name: 'Description',
      title: 'Description',
      type: FormInputTypes.text,
      placeholder: 'Description',
      value: values?.Marine?.description || '',
      required: true,
      hidden: false,
    },
    VesselName: {
      name: 'VesselName',
      title: 'Vessel Name',
      type: FormInputTypes.text,
      placeholder: 'Vessel Name',
      value: values?.Marine?.vesselName || '',
      required: true,
      hidden: false,
    },
    VesselAge: {
      name: 'VesselAge',
      title: 'Vessel Age',
      type: FormInputTypes.number,
      placeholder: 'Vessel Age',
      value: values?.Marine?.vesselAge || '',
      required: false,
      hidden: false,
    },
    From: {
      name: 'From',
      title: 'From',
      type: FormInputTypes.text,
      placeholder: 'From',
      value: values?.Marine?.from || '',
      required: true,
      hidden: false,
    },
    To: {
      name: 'To',
      title: 'To',
      type: FormInputTypes.text,
      placeholder: 'To',
      value: values?.Marine?.to || '',
      required: true,
      hidden: false,
    },
    Via: {
      name: 'Via',
      title: 'Via',
      type: FormInputTypes.text,
      placeholder: 'Via',
      value: values?.Marine?.via || '',
      required: true,
      hidden: false,
    },
    Survey: {
      name: 'Survey',
      title: 'Survey',
      type: FormInputTypes.text,
      placeholder: 'Survey',
      value: values?.Marine?.survey || '',
      required: true,
      hidden: false,
    },
    CargoContract: {
      name: 'CargoContract',
      title: 'Cargo Contract',
      type: FormInputTypes.text,
      placeholder: 'Cargo Contract',
      value: values?.Marine?.cargoContract || '',
      required: true,
      hidden: false,
    },
    SumPerShipment: {
      name: 'SumPerShipment',
      title: 'Sum Per Shipment',
      type: FormInputTypes.number,
      placeholder: 'Sum Per Shipment',
      value: values?.Marine?.sumPerShipment || '',
      required: true,
      hidden: false,
    },
    LCNumber: {
      name: 'LCNumber',
      title: 'LC Number',
      type: FormInputTypes.text,
      placeholder: 'LC Number',
      value: values?.Marine?.lcNumber || '',
      required: false,
      hidden: false,
    },
    LCIssueDate: {
      name: 'LCIssueDate',
      title: 'LC Issue Date',
      type: FormInputTypes.date,
      placeholder: 'LC Issue Date',
      value: values?.Marine?.lcIssueDate || '',
      required: false,
      hidden: false,
    },
    Bank: {
      name: 'Bank',
      title: 'Bank',
      type: FormInputTypes.text,
      placeholder: 'Bank',
      value: values?.Marine?.bank || '',
      required: true,
      hidden: false,
    },
    ClaimsPayableBy: {
      name: 'ClaimsPayableBy',
      title: 'Claims Payable by',
      type: FormInputTypes.text,
      placeholder: 'Claims Payable by',
      value: values?.Marine?.claimsPayableBy || '',
      required: true,
      hidden: false,
    },
    Loading: {
      name: 'Loading',
      title: 'Loading',
      type: FormInputTypes.text,
      placeholder: 'Loading',
      value: values?.Marine?.loading || '',
      required: true,
      hidden: false,
    },
    Remarks: {
      name: 'Remarks',
      title: 'Remarks',
      type: FormInputTypes.text,
      placeholder: 'Remarks',
      value: values?.Marine?.remarks || '',
      required: false,
      hidden: false,
    },
  };
};
