import { IAmendmentItems, IAmendmentTableData } from '.';
import { IAbstractRecord } from '../../../../../models';
import { IListingData } from '../../../../../models/listing';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../../../../utils/graph-utils';

export function mapToListingData(
  data: IAbstractRecord,
  transferredToAccounting: boolean,
  allowPolicyCorrection: boolean,
  allowEndorsement: boolean
): {
  lovs: Record<string, Record<string, string>>;
  items: IListingData;
} {
  const list: Record<string, IAmendmentTableData> = {};

  const types = enumListAsRecordObject(
    data.Production_AmendmentTypes.enumValues
  );
  const subTypes = lookupListAsRecordObject(
    data.Production.lookups.amendmentSubTypes
  );
  const statuses = enumListAsRecordObject(
    data.Production_AmendmentStatuses.enumValues
  );

  // Filter the types based on conditions
  const filteredTypes = Object.fromEntries(
    Object.entries(types).filter(([key]) => {
      if (key === 'POLICY_CORRECTION') {
        return !transferredToAccounting && allowPolicyCorrection;
      }
      if (key === 'POLICY_ENDORSEMENT') {
        return transferredToAccounting && allowEndorsement;
      }
      return true;
    })
  );

  data.Production.queries.getAmendmentList.forEach(
    (amendment: IAmendmentItems) => {
      const amendmentStatus = amendment.production_Amendment_AmendmentStatus;

      list[amendment.production_Amendment_Id] = {
        id: amendment.production_Amendment_Id,
        production_Amendment_Id: amendment.production_Amendment_Id,
        production_Amendment_AmendmentNumber:
          amendment.production_Amendment_AmendmentNumber?.toString(),
        production_Amendment_AmendmentType:
          filteredTypes[amendment.production_Amendment_AmendmentType],
        production_Amendment_AmendmentSubType:
          amendment.production_Amendment_AmendmentSubType?.Title,
        production_Amendment_AmendmentEffectiveDate:
          amendment.production_Amendment_AmendmentEffectiveDate,
        production_Amendment_AmendmentStatus: statuses[amendmentStatus],
        StatusColor: STATUS_COLOR_LIST[amendmentStatus],
      };
    }
  );

  return {
    lovs: {
      types: filteredTypes,
      subTypes,
    },
    items: {
      pagedItems: list,
      pageSize: 5,
      pageNumber: 1,
      totalCount: Object.keys(list).length,
    },
  };
}

const STATUS_COLOR_LIST: Record<string, string> = {
  NEW: 'rgb(167, 167, 167)',
  IN_PROGRESS: 'rgb(255, 163, 85)',
  EXPIRED: 'rgb(207, 52, 57)',
  CANCELLED: 'rgb(0, 177, 223)',
  COMPLETED: 'rgba(128, 192, 136, 1)',
};
