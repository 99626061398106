import { isEmpty } from 'lodash';
import {
  IAmendmentBankerDetails,
  IAmendmentCostCharges,
  IAmendmentCover,
  IAmendmentExpatDetails,
  IAmendmentMarineDetails,
  IAmendmentMarineHullDetails,
  IAmendmentMedicalDetails,
  IAmendmentRiskDetails,
  IPropertyCoverDetails,
} from '..';
import { isValidNumber } from '../../../../utils/validationUtils';

export const validateCoverRepeaterField = (
  fieldName: keyof IAmendmentCover,
  value: unknown,
  cover: IAmendmentCover
): string => {
  if (fieldName === 'policyCover') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'coverPremiumType') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'coverPremiumPercentage') {
    if (cover.coverPremiumType === 'PERCENTAGE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return 'Value should be between 0 and 100';
        }
      }
    }
  }

  if (fieldName === 'amendmentCoverPremiumPercentage') {
    if (cover.coverPremiumType === 'PERCENTAGE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return 'Value should be between 0 and 100';
        }
      }
    }
  }

  if (fieldName === 'coverPremiumValue') {
    if (cover.coverPremiumType === 'VALUE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  if (fieldName === 'amendmentCoverPremiumValue') {
    if (cover.coverPremiumType === 'VALUE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  if (fieldName === 'coverSumInsured') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than 0';
    }
  }

  if (fieldName === 'amendmentCoverSumInsured') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than 0';
    }
  }

  if (fieldName === 'excessOnClaimType') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'excessOnClaimPercentage') {
    if (cover.excessOnClaimType === 'PERCENTAGE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return 'Value should be between 0 and 100';
        }
      }
    }
  }

  if (fieldName === 'amendmentExcessOnClaimPercentage') {
    if (cover.excessOnClaimType === 'PERCENTAGE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return 'Value should be between 0 and 100';
        }
      }
    }
  }

  if (fieldName === 'excessOnClaimAmount') {
    if (cover.excessOnClaimType === 'VALUE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  if (fieldName === 'amendmentExcessOnClaimAmount') {
    if (cover.excessOnClaimType === 'VALUE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  if (fieldName === 'excessOnClaimDays') {
    if (cover.excessOnClaimType === 'DAYS') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  if (fieldName === 'amendmentExcessOnClaimDays') {
    if (cover.excessOnClaimType === 'DAYS') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  return '';
};

export const validateCostWidgetField = (
  fieldName: keyof IAmendmentCostCharges,
  value: unknown,
  cover: IAmendmentCostCharges,
  lineExternalCode?: string
): string => {
  if (fieldName === 'amendmentCost') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'tpaFeesType' && lineExternalCode !== '43') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'chargesPercentage') {
    if (isEmpty(value)) {
      return 'Required';
    } else {
      if (!isValidNumber(value)) {
        return 'Invalid Number';
      }

      const numberValue = Number(value);

      if (numberValue < 0 || numberValue > 100) {
        return 'Value should be between 0 and 100';
      }
    }
  }

  if (fieldName === 'tpaFeesPercentage') {
    if (cover.tpaFeesType === 'PERCENTAGE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return 'Value should be between 0 and 100';
        }
      }
    }
  }

  if (fieldName === 'tpaFeesAmount') {
    if (cover.tpaFeesType === 'AMOUNT') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  return '';
};

export const validateExpatDetailsField = (
  fieldName: keyof IAmendmentExpatDetails,
  value: unknown
): string => {
  if (fieldName === 'medicalClass') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }
  return '';
};

export const validateMedicalDetailsField = (
  fieldName: keyof IAmendmentMedicalDetails,
  value: any,
  externalCode: string
): string => {
  if (externalCode === '19') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'policyCover') {
    if ((value || []).length === 0) {
      return 'Required';
    }
  }

  return '';
};

export const validateBankerRepeaterField = (
  fieldName: keyof IAmendmentBankerDetails,
  value: IAmendmentBankerDetails[keyof IAmendmentBankerDetails]
): string => {
  if (fieldName === 'branchName') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'isMain') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'isAgency') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'numberOfEmployees') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than 0';
    }
  }

  if (fieldName === 'underwritingYear') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }
  }

  if (fieldName === 'infidelity') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'burglary') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'holdUp') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'inSafe') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'inTransit') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'forgedChecks') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'counterfeitCurrencies') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'clause6') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'falsification') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  return '';
};

export const validateMarineDetailsField = (
  fieldName: keyof IAmendmentMarineDetails,
  value: IAmendmentMarineDetails[keyof IAmendmentMarineDetails]
): string => {
  if (fieldName === 'matter') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'description') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'vesselName') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'from') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'to') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  // if (fieldName === 'via') {
  //   if (isEmpty(value)) {
  //     return 'Required';
  //   }
  // }

  if (fieldName === 'survey') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'cargoContract') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'sumPerShipment') {
    if (value && !isValidNumber(value)) {
      return 'Invalid Number';
    }
    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than 0';
    }
  }

  if (fieldName === 'claimsPayableBy') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'loading') {
    if (isEmpty(value)) {
      return 'Required';
    } else if (!isValidNumber(value)) {
      return 'Invalid Number';
    }
  }

  if (fieldName === 'vesselAge') {
    if (value && !isValidNumber(value)) {
      return 'Invalid Number';
    }
    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than 0';
    }
  }

  return '';
};

export const validateMarineHullDetailsField = (
  fieldName: keyof IAmendmentMarineHullDetails,
  value: IAmendmentMarineHullDetails[keyof IAmendmentMarineHullDetails]
): string => {
  if (fieldName === 'vesselName') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'connectionPort') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'territorialWaters') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'usage') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'builder') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'constructionYear') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'registeryPort') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'engineType') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'engineHorsePower') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'engineYearBuilt') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validateRiskDetailsField = (
  fieldName: keyof IAmendmentRiskDetails,
  value: IAmendmentRiskDetails[keyof IAmendmentRiskDetails]
): string => {
  if (fieldName === 'riskDescription') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'geoLocation') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validateRiskCoverDetailsField = (
  fieldName: keyof IPropertyCoverDetails,
  value: IPropertyCoverDetails[keyof IPropertyCoverDetails]
): string => {
  if (fieldName === 'planCoverID') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'riskRate') {
    if (value && (!isValidNumber(value) || Number(value) < 0)) {
      return 'Invalid Number';
    }
  }

  if (fieldName === 'sumInsured') {
    if (value && (!isValidNumber(value) || Number(value) < 0)) {
      return 'Invalid Number';
    }
  }

  if (fieldName === 'premium') {
    if (value && (!isValidNumber(value) || Number(value) < 0)) {
      return 'Invalid Number';
    }
  }
  return '';
};
