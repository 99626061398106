import React, { useEffect, useState } from 'react';
import WidgetPaper from '../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../components/common/WidgetSection';
import ToastErrorMessage from '../../../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../../../constants';
import { toast } from 'react-toastify';
import { cloneDeep, isEmpty } from 'lodash';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { lookupListAsRecordObject } from '../../../../utils/graph-utils';
import {
  filteredBodyTypeList,
  filteredUsageTypeList,
} from '../../../../forms/proposal-details-page/utils';
import { validateMotorVignette } from '../../proposal/page/expat/queries';
import { getModelsByBrand } from '../../../../forms/proposal-details-page/queries';
import TextInputFormField from '../../../../components/form-fields/TextInputFormField';
import SelectFormField from '../../../../components/form-fields/SelectFormField';
import CurrencyFormField from '../../../../components/form-fields/CurrencyFormField';
import NumberInputFormField from '../../../../components/form-fields/NumberInputFormField';
import dayjs from 'dayjs';
import { validateMotorDetailsField } from '../../../../forms/proposal-details-page/validation';
import DataService from '../../../../services/dataService';
import { useParams } from 'react-router-dom';
import { ILineAmendmentProps } from '../line-amendment';
import { getMotorLovsQuery } from '../queries';
import Loader from '../../../../components/Loader';
import { getMotorLovs } from '../utils';
import { IAmendmentMotorDetails, IAmendmentPageState } from '..';

interface IMotorDetails {
  generalData: ILineAmendmentProps['generalData'];
  pageState: IAmendmentPageState;
  onPageStateUpdate: (pageState: IAmendmentPageState) => void;
  isDisabled?: boolean;
}

interface IMotorLovs {
  plateCodes: Record<string, string>;
  usageTypes: Record<string, string>;
  bodyTypes: Record<string, string>;
  engineTypes: Record<string, string>;
  brands: Record<string, string>;
  colors: Record<string, string>;
}

const MotorDetails: React.FC<IMotorDetails> = ({
  generalData,
  pageState,
  onPageStateUpdate,
  isDisabled,
}) => {
  const params = useParams();

  const values = pageState.values.motorDetails;
  const errors = pageState.errors.motorDetails;
  const touched = pageState.touched.motorDetails;

  const [getMotorLovsLazy, { loading }] = useLazyQuery(getMotorLovsQuery);
  const [getModelsByBrandsLazy] = useLazyQuery(getModelsByBrand());

  const [lovs, setLovs] = useState<IMotorLovs>({
    bodyTypes: {},
    brands: {},
    colors: {},
    engineTypes: {},
    plateCodes: {},
    usageTypes: {},
  });

  const [modelLovs, setModelLovs] =
    useState<Record<string, Record<string, string>>>(null);

  const [loadingNameOnLicenseArState, setLoadingNameOnLicenseArState] =
    useState<boolean>(false);

  const [filteredUsageTypes, setFilteredUsageTypes] = useState<
    Record<string, string>
  >(lovs.usageTypes);
  const [filteredBodyTypes, setFilteredBodyTypes] = useState<
    Record<string, string>
  >(lovs.bodyTypes);

  const [validateVignetteLazy] = useMutation(validateMotorVignette(), {});

  const initialize = async () => {
    const { data: lovsData } = await getMotorLovsLazy();

    const lists = getMotorLovs(lovsData);
    setLovs({
      plateCodes: lists.plateCodes,
      usageTypes: lists.usageTypes,
      bodyTypes: lists.bodyTypes,
      engineTypes: lists.engineTypes,
      brands: lists.brands,
      colors: lists.colors,
    });

    if (values.brand) {
      const result = await getModelsByBrandsLazy({
        variables: { modelId: values.brand || null },
      });
      const models = result.data;
      const modelsToLookup = lookupListAsRecordObject(
        models?.Insurance?.lookups?.models
      );
      setModelLovs({ model: modelsToLookup });
    }
    if (values.plateCode) {
      const filteredUsage = filteredUsageTypeList(
        values.plateCode,
        lists.usageTypes
      );
      setFilteredUsageTypes(filteredUsage);
    }

    if (values.usageType) {
      const filteredBody = filteredBodyTypeList(
        values.usageType,
        lists.bodyTypes
      );
      setFilteredBodyTypes(filteredBody);
    }
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFieldBlur = async (fieldName: string) => {
    const newPageState = cloneDeep(pageState);
    newPageState.touched.motorDetails[fieldName] = true;

    if (fieldName === 'brand') {
      if (values.brand) {
        const result = await getModelsByBrandsLazy({
          variables: { modelId: values.brand || null },
        });
        const models = result.data;
        const modelsToLookup = lookupListAsRecordObject(
          models?.Insurance?.lookups?.models
        );
        setModelLovs({ model: modelsToLookup });
      }
    }

    if (fieldName === 'vignetteCode') {
      if (!isEmpty(values.vignette)) {
        validateVignetteLazy({
          variables: {
            businessPartnerID: generalData.primaryBPID,
            entityId: params.id,
            effectiveDate: dayjs(
              new Date(generalData.policyEffectiveDate)
            ).format('YYYY-MM-DD'),
            vignetteCode: values.vignetteCode,
            vignette: Number(values.vignette),
          },
        }).catch((error) => {
          if (
            error instanceof ApolloError &&
            error.message === 'Vignette Number is not assigned to you'
          ) {
            const vignetteAlreadyExistsErrorMessage = error.message;

            newPageState.errors.motorDetails['vignetteCode'] =
              vignetteAlreadyExistsErrorMessage;
          } else if (
            error instanceof ApolloError &&
            error.message === 'Vignette already in use'
          ) {
            const vignetteAlreadyExistsErrorMessage = error.message;

            newPageState.errors.motorDetails['vignetteCode'] =
              vignetteAlreadyExistsErrorMessage;
          } else {
            newPageState.errors.motorDetails['vignetteCode'] = '';
          }
        });
      }
    }
    if (fieldName === 'vignette') {
      validateVignetteLazy({
        variables: {
          businessPartnerID: generalData.primaryBPID,
          entityId: params.id,
          effectiveDate: dayjs(
            new Date(generalData.policyEffectiveDate)
          ).format('YYYY-MM-DD'),
          vignetteCode: values.vignetteCode,
          vignette: Number(values.vignette),
        },
      }).catch((error) => {
        if (
          error instanceof ApolloError &&
          error.message === 'Vignette Number is not assigned to you'
        ) {
          const vignetteAlreadyExistsErrorMessage = error.message;

          newPageState.errors.motorDetails['vignette'] =
            vignetteAlreadyExistsErrorMessage;
        } else if (
          error instanceof ApolloError &&
          error.message === 'Vignette already in use'
        ) {
          const vignetteAlreadyExistsErrorMessage = error.message;

          newPageState.errors.motorDetails['vignette'] =
            vignetteAlreadyExistsErrorMessage;
        } else {
          newPageState.errors.motorDetails['vignette'] = '';
        }
      });
    }

    if (fieldName === 'nameOnLicenseEn') {
      autoSetNameOnLicenseAr(values.nameOnLicenseEn);
    }

    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IAmendmentMotorDetails,
    value: unknown,
    touched = false
  ) => {
    const newPageState = cloneDeep(pageState) as IAmendmentPageState;

    (newPageState.values.motorDetails as any)[fieldName] = value;

    if (fieldName === 'plateCode') {
      const filteredUsage = filteredUsageTypeList(
        value as string,
        lovs.usageTypes
      );
      setFilteredUsageTypes(filteredUsage);
    }

    if (fieldName === 'usageType') {
      const filteredBody = filteredBodyTypeList(
        value as string,
        lovs.bodyTypes
      );
      setFilteredBodyTypes(filteredBody);
    }

    if (fieldName === 'brand') {
      newPageState.values.motorDetails.model = '';
      newPageState.errors.motorDetails.model = '';
      newPageState.touched.motorDetails.model = false;
    }
    newPageState.errors.motorDetails[fieldName] = validateMotorDetailsField(
      fieldName,
      value,
      newPageState.values.motorDetails
    );

    if (touched) {
      newPageState.touched.costs[fieldName] = true;
    }

    onPageStateUpdate(newPageState);
  };

  const autoSetNameOnLicenseAr = (enTxt: string) => {
    const newPageState = cloneDeep(pageState);
    setLoadingNameOnLicenseArState(true);
    DataService.get(`/api/prx/transliterate.qcri.org/en2ar/` + enTxt)
      .then(async (response) => {
        if (response.ok) {
          const result: { results: string } = await response.json();
          newPageState.values.motorDetails.nameOnLicenseAr = result.results;
          onFieldUpdate('nameOnLicenseAr', result.results);
          onPageStateUpdate(newPageState);
        }
      })
      .catch(() => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        newPageState.values.motorDetails.nameOnLicenseAr = enTxt;
      })
      .finally(() => {
        setLoadingNameOnLicenseArState(false);
      });
    onPageStateUpdate(newPageState);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <WidgetPaper>
      <WidgetSection title="Motor Details" isFieldsGrid>
        <TextInputFormField
          key="nameOnLicenseEn"
          name="nameOnLicenseEn"
          title="Name on License (EN)*"
          placeholder="Name on License (EN)"
          value={values.nameOnLicenseEn}
          error={touched.nameOnLicenseEn ? errors.nameOnLicenseEn : ''}
          onBlur={() => onFieldBlur('nameOnLicenseEn')}
          onChange={(v) => onFieldUpdate('nameOnLicenseEn', v.target.value)}
          disabled={isDisabled}
          material
        />

        <TextInputFormField
          key="nameOnLicenseAr"
          name="nameOnLicenseAr"
          title="Name on License (AR)*"
          placeholder="Name on License (AR)"
          value={values.nameOnLicenseAr}
          error={touched.nameOnLicenseAr ? errors.nameOnLicenseAr : ''}
          onBlur={() => onFieldBlur('nameOnLicenseAr')}
          onChange={(v) => onFieldUpdate('nameOnLicenseAr', v.target.value)}
          disabled={isDisabled}
          material
          loader={loadingNameOnLicenseArState}
        />

        {generalData.lineExternalCode === '50' && (
          <>
            <TextInputFormField
              key="vignetteCode"
              name="vignetteCode"
              title="Vignette Code*"
              placeholder="Vignette Code"
              value={values.vignetteCode}
              error={touched.vignetteCode ? errors.vignetteCode : ''}
              onBlur={() => onFieldBlur('vignetteCode')}
              onChange={(v) => onFieldUpdate('vignetteCode', v.target.value)}
              disabled={isDisabled}
              material
            />

            <NumberInputFormField
              key="vignette"
              name="vignette"
              title="Vignette*"
              placeholder="Vignette"
              value={values.vignette}
              error={touched.vignette ? errors.vignette : ''}
              onBlur={() => onFieldBlur('vignette')}
              onChange={(v) => onFieldUpdate('vignette', v.target.value)}
              disabled={isDisabled}
              material
            />
          </>
        )}

        <SelectFormField
          key="plateCode"
          name="plateCode"
          title="Plate Code"
          placeholder="Plate Code"
          value={values.plateCode}
          error={touched.plateCode ? errors.plateCode : ''}
          onChange={(v) => onFieldUpdate('plateCode', v, true)}
          disabled={isDisabled}
          selectOptions={lovs.plateCodes}
          material
        />

        <NumberInputFormField
          key="plateNumber"
          name="plateNumber"
          title="Plate Number"
          placeholder="Plate Number"
          value={values.plateNumber}
          error={touched.plateNumber ? errors.plateNumber : ''}
          onBlur={() => onFieldBlur('plateNumber')}
          onChange={(v) => onFieldUpdate('plateNumber', v.target.value)}
          disabled={isDisabled}
          material
        />

        <SelectFormField
          key="usageType"
          name="usageType"
          title="Usage*"
          placeholder="Usage"
          value={values.usageType}
          error={touched.usageType ? errors.usageType : ''}
          onChange={(v) => onFieldUpdate('usageType', v, true)}
          disabled={isDisabled}
          selectOptions={filteredUsageTypes}
          material
        />

        <SelectFormField
          key="bodyType"
          name="bodyType"
          title="Body*"
          placeholder="Body"
          value={values.bodyType}
          error={touched.bodyType ? errors.bodyType : ''}
          onChange={(v) => onFieldUpdate('bodyType', v, true)}
          disabled={isDisabled}
          selectOptions={filteredBodyTypes}
          material
        />

        <SelectFormField
          key="engineType"
          name="engineType"
          title="Engine Type*"
          placeholder="Engine Type"
          value={values.engineType}
          error={touched.engineType ? errors.engineType : ''}
          onChange={(v) => onFieldUpdate('engineType', v, true)}
          disabled={isDisabled}
          selectOptions={lovs.engineTypes}
          material
        />

        <SelectFormField
          key="brand"
          name="brand"
          title="Make*"
          placeholder="Make"
          value={values.brand}
          error={touched.brand ? errors.brand : ''}
          onChange={(v) => onFieldUpdate('brand', v, true)}
          onBlur={() => onFieldBlur('brand')}
          disabled={isDisabled}
          selectOptions={lovs.brands}
          material
        />

        <SelectFormField
          key="model"
          name="model"
          title="Model*"
          placeholder="Model"
          value={values.model}
          error={touched.model ? errors.model : ''}
          onChange={(v) => onFieldUpdate('model', v, true)}
          disabled={isDisabled}
          selectOptions={modelLovs?.model || {}}
          material
        />

        <SelectFormField
          key="color"
          name="color"
          title="Color"
          placeholder="Color"
          value={values.color}
          error={touched.color ? errors.color : ''}
          onChange={(v) => onFieldUpdate('color', v, true)}
          disabled={isDisabled}
          selectOptions={lovs?.colors || {}}
          material
        />

        <NumberInputFormField
          key="yearOfMake"
          name="yearOfMake"
          title="Year of Make*"
          placeholder="Year of Make"
          value={values.yearOfMake}
          error={touched.yearOfMake ? errors.yearOfMake : ''}
          onBlur={() => onFieldBlur('yearOfMake')}
          onChange={(v) => onFieldUpdate('yearOfMake', v.target.value)}
          disabled={isDisabled}
          material
        />

        <TextInputFormField
          key="motor"
          name="motor"
          title="Engine*"
          placeholder="Engine"
          value={values.motor}
          error={touched.motor ? errors.motor : ''}
          onBlur={() => onFieldBlur('motor')}
          onChange={(v) => onFieldUpdate('motor', v.target.value)}
          disabled={isDisabled}
          material
        />

        {['VAN', 'PICKUP', 'F_TANKER'].includes(values.bodyType) && (
          <NumberInputFormField
            key="weight"
            name="weight"
            title="Weight (in Tons)*"
            placeholder="Weight (in Tons)"
            value={values.weight}
            error={touched.weight ? errors.weight : ''}
            onBlur={() => onFieldBlur('weight')}
            onChange={(v) => onFieldUpdate('weight', v.target.value)}
            disabled={isDisabled}
            material
          />
        )}

        {['MOTORCYCLE'].includes(values.bodyType) && (
          <NumberInputFormField
            key="engineSize"
            name="engineSize"
            title="Engine Size (in cc)*"
            placeholder="Engine Size (in cc)"
            value={values.engineSize}
            error={touched.engineSize ? errors.engineSize : ''}
            onBlur={() => onFieldBlur('engineSize')}
            onChange={(v) => onFieldUpdate('engineSize', v.target.value)}
            disabled={isDisabled}
            material
          />
        )}

        <NumberInputFormField
          key="seats"
          name="seats"
          title="Seating Capacity*"
          placeholder="Seating Capacity"
          value={values.seats}
          error={touched.seats ? errors.seats : ''}
          onBlur={() => onFieldBlur('seats')}
          onChange={(v) => onFieldUpdate('seats', v.target.value)}
          disabled={isDisabled}
          material
        />

        <TextInputFormField
          key="chassis"
          name="chassis"
          title="Chassis*"
          placeholder="Chassis"
          value={values.chassis}
          error={touched.chassis ? errors.chassis : ''}
          onBlur={() => onFieldBlur('chassis')}
          onChange={(v) => onFieldUpdate('chassis', v.target.value)}
          disabled={isDisabled}
          material
        />

        <NumberInputFormField
          key="horsePower"
          name="horsePower"
          title="Horse Power*"
          placeholder="Horse Power"
          value={values.horsePower}
          error={touched.horsePower ? errors.horsePower : ''}
          onBlur={() => onFieldBlur('horsePower')}
          onChange={(v) => onFieldUpdate('horsePower', v.target.value)}
          disabled={isDisabled}
          material
        />

        {generalData.lineExternalCode === '51' && (
          <>
            <CurrencyFormField
              key="carValue"
              name="carValue"
              title="Car Value*"
              placeholder="Car Value"
              value={values.carValue}
              error={touched.carValue ? errors.carValue : ''}
              onBlur={() => onFieldBlur('carValue')}
              onChange={(v) => onFieldUpdate('carValue', v.target.value)}
              disabled={isDisabled}
              material
              currencySymbol={generalData.currency.Symbol}
            />

            <NumberInputFormField
              key="agencyRepairYear"
              name="agencyRepairYear"
              title="Agency Repair Years*"
              placeholder="Agency Repair Years"
              value={values.agencyRepairYear}
              error={touched.agencyRepairYear ? errors.agencyRepairYear : ''}
              onBlur={() => onFieldBlur('agencyRepairYear')}
              onChange={(v) =>
                onFieldUpdate('agencyRepairYear', v.target.value)
              }
              disabled={isDisabled}
              material
            />

            <NumberInputFormField
              key="depreciationYears"
              name="depreciationYears"
              title="Depreciation Years*"
              placeholder="Depreciation Years"
              value={values.depreciationYears}
              error={touched.depreciationYears ? errors.depreciationYears : ''}
              onBlur={() => onFieldBlur('depreciationYears')}
              onChange={(v) =>
                onFieldUpdate('depreciationYears', v.target.value)
              }
              disabled={isDisabled}
              material
            />
          </>
        )}
      </WidgetSection>
    </WidgetPaper>
  );
};

export default MotorDetails;
