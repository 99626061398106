import React, { useCallback } from 'react';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import {
  isEmpty,
  isValidNumber,
  isValidNumberInput,
} from '../../utils/validationUtils';
import { IEnhancedFormInputBaseProps } from '.';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import { trimTrailingZeros } from '../../utils/formatting-utils';

export interface INumberInputFormFieldProps
  extends IEnhancedFormInputBaseProps {
  value: number | string;
  loader?: boolean;
  hideError?: boolean;
  maxValue?: number;
  minValue?: number;
  allowNegativeValues?: boolean;
  allowExponential?: boolean;
  maxDecimalPrecision?: number;
  formatOnBlur?: boolean;
  classes?: {
    labelWrapper?: string;
    label?: string;
    input?: string;
  };
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles()(() => ({
  input: {
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
    lineHeight: '15px',
    height: 35,
    width: '100%',
    padding: '0 15px',
    margin: '0 auto',
    backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    '&:disabled': {
      cursor: 'not-allowed',
      color: 'rgba(0, 0, 0, 0.38)!important',
    },
    '&::placeholder': {
      fontStyle: 'italic',
      fontFamily: contentFontFamilyRegular,
      color: '#33333333',
      opacity: 1,
      fontSize: '14px',
    },
    fontFamily: contentFontFamilyRegular,
    '&:focus': {
      outline: 'none',
    },
    '::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 10px)',
    right: '3%',
    opacity: 1,
    height: '25px',
    width: '25px',
  },
  materialInput: {
    backgroundColor: 'unset',
    border: 'none',
    borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    color: MAIN_ONE_THEME.palette.primary2.main,
    margin: '0 auto',
    borderRadius: 0,
    padding: '10px 15px 4px 4px',
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
  },
  inputError: {
    borderColor: `${MAIN_ONE_THEME.palette.error.main} !important`,
    outlineColor: MAIN_ONE_THEME.palette.error.main,
  },
  inputContainer: {
    position: 'relative',
  },
  disabledInput: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.5)!important',
      WebkitTextFillColor: 'rgba(0, 0, 0, 0.5)!important',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.5)!important',
      WebkitTextFillColor: 'rgba(0, 0, 0, 0.5)!important',
    },
  },
}));

const NumberInputFormField: React.FC<INumberInputFormFieldProps> = ({
  error,
  name,
  title,
  value = '',
  placeholder,
  disabled,
  material,
  loader,
  inputOnly,
  hideError,
  classes = {},
  maxValue,
  minValue,
  allowNegativeValues = false,
  allowExponential = false,
  formatOnBlur = true,
  maxDecimalPrecision = 3,
  onChange,
  onFocus,
  onBlur,
}) => {
  const { classes: inputClasses } = useStyles();
  const hasError = !!error;
  const errorId = `errorMsg-${name}`;

  const limitNumber = useCallback(
    (targetValue: string) => {
      if (
        Number(maxDecimalPrecision) >= 0 &&
        isValidNumber(maxDecimalPrecision)
      ) {
        targetValue = trimTrailingZeros(
          targetValue,
          maxDecimalPrecision
        ).toString();
      }
      return targetValue;
    },
    [maxDecimalPrecision]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const originalValue = event.target.value;
      const inputName = event.target.name;

      let newValue = originalValue;
      let isValid = true;

      if (!isEmpty(newValue)) {
        if (!allowNegativeValues && Number(newValue) < 0) {
          newValue = minValue?.toString();
        }

        const numericValue = Number(newValue);

        if (isValidNumber(minValue) && isValidNumber(maxValue)) {
          isValid = isValidNumberInput(
            numericValue,
            Number(minValue),
            Number(maxValue)
          );
        } else if (isValidNumber(maxValue)) {
          isValid = numericValue <= Number(maxValue);
        } else if (isValidNumber(minValue)) {
          isValid = numericValue >= Number(minValue);
        }
      }

      if (isValid) {
        onChange({
          ...event,
          target: {
            ...event.target,
            name: inputName,
            value: newValue,
          },
        });
      }
    },
    [allowNegativeValues, maxValue, minValue, onChange]
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (formatOnBlur && !isEmpty(event.target.value)) {
        const targetValue = event.target.value;
        const newValue = limitNumber(targetValue);
        onChange({
          ...event,
          target: {
            ...event.target,
            value: newValue,
          },
        });
      }
      onBlur && onBlur(event);
    },
    [onBlur, limitNumber, onChange, formatOnBlur]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // If exponential is not allowed, prevent entering 'e' or 'E'.
      if (!allowExponential && (event.key === 'e' || event.key === 'E')) {
        event.preventDefault();
      }
    },
    [allowExponential]
  );

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      // prevent scrolling on input focus
      event.target.addEventListener(
        'wheel',
        function (e) {
          e.preventDefault();
        },
        { passive: false }
      );

      onFocus && onFocus(event);
    },
    [onFocus]
  );

  const renderInput = () => (
    <div className={inputClasses.inputContainer}>
      <input
        className={clsx(inputClasses.input, {
          [inputClasses.materialInput]: material,
          [inputClasses.inputError]: error,
          [inputClasses.disabledInput]: disabled,
          [classes.input]: classes.input,
        })}
        title={disabled && !isEmpty(value) ? value.toString() : title}
        id={name}
        min={allowNegativeValues ? minValue : 0}
        max={maxValue}
        name={name}
        inputMode="numeric"
        aria-invalid={hasError}
        aria-describedby={hasError ? errorId : undefined}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        type={'number'}
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  );

  return inputOnly ? (
    renderInput()
  ) : (
    <EnhancedInputsWrapper
      title={title}
      error={error}
      name={name}
      material={material}
      className={classes?.labelWrapper}
      hideError={hideError}
    >
      {renderInput()}
      {loader && (
        <CircularProgress
          className={inputClasses.loader}
          size={20}
          thickness={3}
        />
      )}
    </EnhancedInputsWrapper>
  );
};

export default NumberInputFormField;
