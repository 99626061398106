import React, { useEffect, useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { getSections, initialValues } from './content';
import { cloneDeep, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  getPolicyPersonEnums,
  getPersonInfo,
  updatePolicyPerson,
  getZonesByCountry,
} from './queries';
import { graphqlEntityToPersonInfo, LookupToList } from './utils';
import { EnhancedButtonStatus } from '../../components/EnhancedButton';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { getError, lookupListAsRecordObject } from '../../utils/graph-utils';
import Loader from '../../components/Loader';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import SectionDynamicForm from '../../DynamicForm/SectionDynamicForm';
import { IPersonDetailsDrawerProps, IPersonDetailsInfo } from '.';

const PersonDetailsDrawer: React.FC<IPersonDetailsDrawerProps> = ({
  proposalId,
  personId,
  section,
  lineExternalCode,
  open,
  onSuccess,
  onClose,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [booted, setBooted] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [values, setValues] = useState<Partial<IPolicyPersonInfo>>({});
  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({});

  const [personDetailsListResults] = useLazyQuery(getPolicyPersonEnums());
  const [personInfoQuery] = useLazyQuery(getPersonInfo(), {
    fetchPolicy: 'no-cache',
  });

  const [getZonesByCountryLazy] = useLazyQuery(getZonesByCountry(), {
    fetchPolicy: 'no-cache',
  });

  const [personAction] = useMutation(updatePolicyPerson());

  const loadLovList = async () => {
    const result = await personDetailsListResults();
    const personEnums = LookupToList(result.data);

    return personEnums;
  };

  const getEntityInfo = async () => {
    if (personId) {
      const apiResult = await personInfoQuery({
        variables: { id: personId },
      });
      if (apiResult.data) {
        const personEntity = graphqlEntityToPersonInfo(apiResult.data);
        return personEntity;
      }
    }

    return initialValues;
  };

  const loadZoneLov = async (countryId: string) => {
    const result = await getZonesByCountryLazy({
      fetchPolicy: 'no-cache',
      variables: { countryID: countryId },
    });

    const zonesData = result.data.Insurance.lookups.zones;

    return lookupListAsRecordObject(zonesData);
  };

  const initialize = async () => {
    try {
      const newValues: Partial<IPersonDetailsInfo> = initialValues;
      const newLovs: Record<string, Record<string, string>> = {};
      const [personEntity, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);

      if (personEntity) {
        newValues.fullName = personEntity.fullName;
        newValues.dateOfBirth = personEntity.dateOfBirth;
        newValues.phoneType = personEntity.phoneType;
        newValues.phoneNumber = personEntity.phoneNumber;
        newValues.nationalityOne = personEntity.nationalityOne;
        newValues.isTaxable = personEntity.isTaxable;
        newValues.occupationClass = personEntity.occupationClass;
        newValues.profession = personEntity.profession;
        newValues.country = personEntity.country;
        newValues.zone = personEntity.zone;
        newValues.city = personEntity.city;
        newValues.street = personEntity.street;
        newValues.building = personEntity.building;
        newValues.addressDetails = personEntity.addressDetails;
        newValues.addressDetailsArabic = personEntity.addressDetailsArabic;
      }

      if (personEntity?.country) {
        newLovs.zone = await loadZoneLov(personEntity.country);
      }
      setLovs({ ...lovData, ...newLovs });
      setValues(newValues);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const submitForm = async (data: Partial<IPersonDetailsInfo>) => {
    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const variables = {
        entityId: params.id,
        dateOfBirth: data.dateOfBirth
          ? new Date(dayjs(data.dateOfBirth).format('YYYY-MM-DD'))
          : null,
        policyPersonType: section.toUpperCase(),
        personDetailsInputs: {
          phoneType: !isEmpty(data.phoneType) ? data.phoneType : null,
          phoneNumber: !isEmpty(data.phoneNumber) ? data.phoneNumber : null,
        },
        personWorkDetailsInputs: {
          nationalityOne: data.nationalityOne || null,
          isTaxable: data.isTaxable,
          occupationClass: !isEmpty(data.occupationClass)
            ? data.occupationClass
            : null,
          profession: !isEmpty(data.profession) ? data.profession : null,
        },
      };

      const res = await personAction({
        variables: personId ? { ...variables, entityId: personId } : variables,
        errorPolicy: 'all',
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {personId
              ? 'Payer details successfully updated'
              : 'Payer details successfully created'}
          </ToastSuccessMessage>
        );

        setTimeout(() => {
          setSubmitButtonState('success');
          setFormDisabled(false);
          onSuccess && onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        setFormDisabled(false);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      setFormDisabled(false);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const onChange = async (
    fieldName: string,
    value: any,
    values: Record<string, any>
  ) => {
    if (fieldName === 'country') {
      values.zone = '';
      const newLovs = cloneDeep(lovs);
      newLovs.zone = await loadZoneLov(value);
      setLovs(newLovs);
    }
    setValues(values);
  };

  const sections = useMemo(() => {
    return getSections(values, lovs, section, lineExternalCode);
  }, [values, lovs, section, lineExternalCode]);

  return (
    <GenericDrawer
      title={
        section === 'payer' ? 'Modify Payer Details' : 'Modify Owner Details'
      }
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <SectionDynamicForm
            sections={sections}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={!!submitButtonState}
            disableForm={formDisabled}
            title=""
            onChange={onChange}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PersonDetailsDrawer;
