import React, { useEffect, useCallback } from 'react';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { cloneDeep, isEmpty } from 'lodash';
import clsx from 'clsx';
import { MAIN_ONE_THEME, mainFontFamilyMedium } from '../../../constants';
import { IAbstractRecord } from '../../../models';
import { IListingData } from '../../../models/listing';
import { useAppSelector } from '../../../redux/hooks';
import { formatDate } from '../../../utils/formatting-utils';
import {
  TableSortOrder,
  rowSort,
  getSorting,
} from '../../../utils/table-utils';
import Separator from '../../common/Separator';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../enhanced-table';
import { ITableRowSettings } from '../../updated-enhanced-table';
import { makeStyles } from 'tss-react/mui';
import { IEnhancedMenuItem, IEnhancedRow, IListingTableFilter } from '.';
import FormattingServices from '../../../services/formating-services';
import ListingTableBody from './ListingTableBody';
import ListingTableHead from './ListingTableHead';
import ListingTableToolbar from './ListingTableToolbar';

export interface IListingTableProps {
  name: string;
  entityName?: string;
  headers: Record<string, EnhancedTableHeader>;
  rowSettings?: ITableRowSettings;
  actions: IEnhancedMenuItem[];
  data: IListingData;
  inline?: boolean;
  inlineTitle?: string;
  dropdownActionsNames?: string[];
  defaultOrderByColumn?: string;
  filterableNames?: string[];
  splitCharacter?: string;
  orderByAscendingByDefault?: boolean;
  secondOrderByColumn?: string;
  secondOrderByAscendingByDefault?: boolean;
  disableSelection?: boolean;
  context?: IAbstractRecord;
  loader?: boolean;
  forceHideToolbar?: boolean;
  onSelectionChange?: (selectedRows: string[]) => void;
  singleSelection?: boolean;
  pageSizeOptions?: number[];
  handleUpdate?(rowKey: number, property: string, value: unknown): void;
  handleCellValueChanged?(
    rowKey: number,
    property: string,
    value: unknown
  ): void;
  dynamicRowMessage?: (row: IEnhancedRow) => string[];
  forceShowSelectColumn?: boolean;
  tableClasses?: {
    inputContainer?: string;
  };
}

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    boxShadow: '0px 0px 6px #C7C7C7',
    margin: '0 auto',
  },
  toolbarPaper: {
    margin: '0 0 1em',
  },
  separator: {
    height: '2px !important',
    margin: '0 !important',
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  header: {
    margin: '0 0 1em',
    display: 'grid',
    gridTemplateAreas: `'title actions'`,
    gridGap: '1em',
  },
  title: {
    display: 'block',
    textAlign: 'left',
    fontSize: MAIN_ONE_THEME.typography.medium.med2.fontSize,
    fontFamily: mainFontFamilyMedium,
    letterSpacing: 0,
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontWeight: 'normal',
    margin: '0 0 17.5px 0',
    gridArea: 'title',
    alignSelf: 'center',
    // textTransform: 'uppercase',
    width: '450px',
  },
  actionsContainer: {
    margin: '0 3px 0 auto',
  },
  action: {
    '& span': {
      margin: '0.5em auto 0',
    },
  },
  button: {
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px!important`,
  },
  heightAndCenter: {
    height: '500px',
    justifyContent: 'center',
  },
}));

const defaultpageSizeOptions = [5, 10, 25];

const ListingTable: React.FC<IListingTableProps> = ({
  name,
  headers,
  rowSettings,
  data,
  actions,
  inline,
  inlineTitle,
  dropdownActionsNames = [],
  defaultOrderByColumn,
  orderByAscendingByDefault,
  secondOrderByColumn,
  secondOrderByAscendingByDefault,
  filterableNames,
  entityName = '',
  disableSelection,
  context,
  splitCharacter = '',
  loader,
  forceHideToolbar = false,
  onSelectionChange = undefined,
  singleSelection = false,
  pageSizeOptions = defaultpageSizeOptions,
  handleUpdate,
  handleCellValueChanged,
  dynamicRowMessage,
  forceShowSelectColumn,
  tableClasses,
}) => {
  const { classes } = useStyles();
  const cdnUrl = useAppSelector((state) => state.tenant.cdnUrl);
  const dateFormat = 'MM/DD/YYYY';
  let filterDelay: NodeJS.Timeout;

  const [order, setOrder] = React.useState<TableSortOrder>();
  const [orderBy, setOrderBy] = React.useState<string>();
  const [secondOrder, setSecondOrder] = React.useState<TableSortOrder>();
  const [page, setPage] = React.useState(
    data.pageNumber > 0 ? data.pageNumber - 1 : 0
  );
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(data.pageSize);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [filtered, setFiltered] = React.useState(data.pagedItems);
  const [filterValue, setFilterValue] = React.useState('');
  const currentName = React.useRef<string>();

  const [filters, setFilters] =
    React.useState<Record<string, IListingTableFilter>>();

  useEffect(() => {
    if (onSelectionChange != undefined) {
      onSelectionChange(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const getFilterValues = useCallback(
    (filterName: string) => {
      const options: string[] = [];
      if (data.pagedItems.length === 0) {
        return [];
      }
      Object.values(data.pagedItems).forEach((item) => {
        if (splitCharacter) {
          if (!isEmpty(item[filterName])) {
            const subItems = (item[filterName] as string).split(splitCharacter);
            subItems.forEach((subItem) => {
              if (!options.includes(subItem)) {
                options.push(subItem);
              }
            });
          }
        } else if (
          !isEmpty(item[filterName]) &&
          !options.includes(item[filterName])
        ) {
          options.push(item[filterName]);
        }
      });
      return options;
    },
    [data.pagedItems, splitCharacter]
  );

  useEffect(() => {
    if (filterableNames) {
      const newFilters: Record<string, IListingTableFilter> = {};
      filterableNames.forEach((key) => {
        let options = getFilterValues(key);
        if (
          headers &&
          headers[key] &&
          headers[key].type === EnhancedTableHeaderType.Date
        ) {
          options = options.map((option) => formatDate(option));
        }
        if (options.length > 0) {
          newFilters[key] = {
            name: key,
            title: headers[key].title || key,
            options,
            selectedValues: [],
          };
        }
      });
      setFilters(newFilters);
    } else {
      setFilters(undefined);
    }
  }, [data.pagedItems, filterableNames, getFilterValues, headers]);

  // Watches data.pagedItems, headers and fiterValue, and recalculates when one changes
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    filterDelay = setTimeout(() => {
      if (headers) {
        const outKeys: string[] = Object.keys(data.pagedItems).filter(
          (itemKey) => {
            const curItem = data.pagedItems[itemKey];
            return (
              Object.values(headers).some(
                (header) =>
                  (header.type != EnhancedTableHeaderType.Date &&
                    curItem[header.name] &&
                    typeof curItem[header.name] === 'string' &&
                    curItem[header.name]
                      .toLowerCase()
                      .includes(filterValue.toLowerCase())) ||
                  (header.type == EnhancedTableHeaderType.Date &&
                    curItem[header.name] &&
                    typeof curItem[header.name] === 'string' &&
                    formatDate(curItem[header.name], dateFormat).includes(
                      filterValue
                    ))
              ) &&
              (!filters ||
                Object.values(filters).every((filter) => {
                  if (
                    filter.options.length === 0 ||
                    filter.selectedValues.length === 0
                  ) {
                    return true;
                  }

                  if (
                    headers[filter.name].type === EnhancedTableHeaderType.Date
                  ) {
                    return filter.selectedValues.includes(
                      FormattingServices.formatDate(curItem[filter.name])
                    );
                  }

                  if (curItem[filter.name] && !!splitCharacter) {
                    return curItem[filter.name]
                      .split(splitCharacter)
                      .some((v: string) => filter.selectedValues.includes(v));
                  }

                  return filter.selectedValues.includes(curItem[filter.name]);
                }))
            );
          }
        );
        const output: typeof data.pagedItems = {};
        outKeys.forEach((key) => {
          output[key] = data.pagedItems[key];
        });
        setSelected(selected.filter((key) => outKeys.includes(key)));
        setFiltered(output);
        setPage(0);
      } else {
        setFiltered(data.pagedItems);
      }
    }, 250);
    return () => {
      clearTimeout(filterDelay);
    };
  }, [data.pagedItems, filterValue, headers, filters]);

  useEffect(() => {
    if (currentName.current !== name && defaultOrderByColumn) {
      currentName.current = name;
      setOrder(orderByAscendingByDefault ? 'asc' : 'desc');
      setOrderBy(defaultOrderByColumn);
      if (secondOrderByColumn) {
        setSecondOrder(secondOrderByAscendingByDefault ? 'asc' : 'desc');
      }
    }
  }, [
    defaultOrderByColumn,
    name,
    orderByAscendingByDefault,
    secondOrderByAscendingByDefault,
    secondOrderByColumn,
  ]);

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: string
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(rowName: string, checked: boolean) {
    if (checked) {
      setSelected(Object.keys(filtered));
      return;
    }
    setSelected([]);
  }

  function handleClick(rowName: string) {
    setSelected((prevSelected) => {
      // Handle single selection mode (toggle)
      if (singleSelection) {
        return prevSelected.includes(rowName) ? [] : [rowName];
      }

      // Handle multi-selection mode
      return prevSelected.includes(rowName)
        ? prevSelected.filter((name) => name !== rowName) // Deselect rowName if already selected
        : [...prevSelected, rowName]; // Add rowName to selection
    });
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(Number(+event.target.value));
    setPage(0);
  }

  const handleChange = (search: string) => {
    setFilterValue(search);
  };

  const isSelected = (key: string) => selected.indexOf(key) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, Object.keys(filtered).length - page * rowsPerPage);

  const handleFilterChange = (
    filterName: string,
    field: string,
    value: boolean
  ) => {
    if (filters && filters[filterName]) {
      const row: Record<string, IListingTableFilter> = {};
      row[filterName] = cloneDeep(filters[filterName]);
      if (value) {
        row[filterName].selectedValues.push(field);
      } else {
        row[filterName].selectedValues = row[filterName].selectedValues.filter(
          (option) => option !== field
        );
      }
      setFilters({ ...filters, ...row });
    }
  };

  const handleFilterClear = () => {
    if (filters) {
      const newFilters = cloneDeep(filters);
      Object.values(newFilters).forEach((filter) => {
        // eslint-disable-next-line no-param-reassign
        filter.selectedValues = [];
      });
      setFilters(newFilters);
    }
  };

  const renderToolbar = () => (
    <ListingTableToolbar
      dropdownActionsNames={dropdownActionsNames}
      selected={selected}
      actions={actions}
      data={filtered}
      handleChange={handleChange}
      cdnUrl={cdnUrl}
      title={inlineTitle}
      onFilterClick={handleFilterChange}
      inlineFilters={filters}
      onFilterClear={handleFilterClear}
      context={context}
    />
  );
  return (
    <div className={classes.root}>
      {!forceHideToolbar && !inline && (
        <Paper className={clsx(classes.paper, classes.toolbarPaper)}>
          {renderToolbar()}
        </Paper>
      )}
      <Paper className={classes.paper}>
        {!forceHideToolbar && inline && (
          <>
            {renderToolbar()}
            <Separator className={classes.separator} />
          </>
        )}
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <ListingTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={Object.keys(filtered).length}
              headRows={headers}
              disableSelection={disableSelection}
              singleSelection={singleSelection}
              forceShowSelectColumn={forceShowSelectColumn}
            />
            <ListingTableBody
              entityName={entityName}
              emptyRows={emptyRows}
              handleClick={handleClick}
              headers={headers}
              rowSettings={rowSettings}
              dynamicRowMessage={dynamicRowMessage}
              isSelected={isSelected}
              rows={rowSort(
                filtered,
                getSorting(
                  order,
                  orderBy,
                  headers,
                  secondOrder,
                  secondOrderByColumn
                )
              ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
              dateFormat={dateFormat}
              disableSelection={disableSelection}
              loader={loader}
              handleBlur={handleUpdate}
              handleCellValueChanged={handleCellValueChanged}
              forceShowSelectColumn={forceShowSelectColumn}
              tableClasses={tableClasses}
            />
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={pageSizeOptions || defaultpageSizeOptions}
          component="div"
          count={Object.keys(filtered).length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage=""
        />
      </Paper>
    </div>
  );
};
export default ListingTable;
