import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { cloneDeep, isArray, isEmpty } from 'lodash';
import { Tabs, Tab, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { IAmendmentPageState, IInsuredDetailedData } from '../..';
import { ILineAmendmentProps } from '../../line-amendment';
import { fetchedPersonAddressList, fetchedPersons } from './queries';
import { extractPersonsAddressesFixed, extractPersonsFixed } from './utils';
import { isGuid } from '../../../../../utils/validationUtils';
import NewChipsInput from '../../../../../components/enhanced-form/NewChipsInput';
import TextInputFormField from '../../../../../components/form-fields/TextInputFormField';
import { removeObjectAtIndex } from '../../../../../utils/helper-utils';
import WidgetPaper from '../../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../../components/common/WidgetSection';
import ProposalPolicyPersonDrawer from '../../../../../forms/proposal-policy-person-drawer/ProposalPolicyPersonDrawer';
import PersonAddressDrawer from '../../../../../forms/person-address-drawer/PersonAddressDrawer';
import { getMedicalPrincipalLovsQuery } from '../../queries';
import { getMedicalPrincipalLovs } from '../../utils';
import TabPanel from '../../../../../components/form-fields/tabs-widget/TabPanel';

interface IMedicalPrincipalInsured {
  generalData: ILineAmendmentProps['generalData'];
  pageState: IAmendmentPageState;
  onPageStateUpdate: (pageState: IAmendmentPageState) => void;
  isDisabled: boolean;
  maxNumber: number;
}

interface IInsuredLovs {
  insuredRelations: Record<string, string>;
  continuity: Record<string, string>;
}

interface IInsuredLists {
  id: Record<number, Record<string, string>>;
  insuredAddresses: Record<number, Record<string, string>>;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    backgroundColor: '#F9F9F9',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    padding: '33px 23px 23px',
    marginBottom: '14px',
    position: 'relative',
    borderTopLeftRadius: 'unset',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(4, 23.5%)`,
    gap: '8px',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  addBtn: {
    marginLeft: '2px',
    minWidth: 'auto',
    minHeight: 'auto',
    backgroundColor: 'transparent',
    padding: 0,
    margin: '0 0 0 10px',
    alignSelf: 'center',
    border: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    color: '#000000',
    margin: '0px!important',
    padding: '0px!important',
    marginBottom: '4px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  labelHeader: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
    marginBottom: '4px!important',
  },
  fullFieldRow: {
    gridColumn: '1 / -1',
    width: '100%',
  },
  cardTitle: {
    fontSize: '15px !important',
    lineHeight: '21px !important',
    margin: '0 0 16px',
    padding: '0',
    fontFamily: 'SourceSansPro-Bold !important',
  },
  tabLabel: {
    fontSize: '14px',
    fontFamily: 'SourceSansPro-Regular',
    textTransform: 'none',
    lineHeight: '18px',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
  },
  tabActiveTitle: {
    fontFamily: 'SourceSansPro-SemiBold !important',
    fontWeight: 600,
  },
  tabIconButton: {
    marginLeft: '8px',
    padding: '0',
  },
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    padding: '0',
    '&::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#ccc',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#aaa',
    },
  },
  tabsWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  indicator: {
    display: 'none',
  },
  fieldRowChildSpan2: {
    gridColumn: 'span 2',
  },
  tabsRoot: {
    minHeight: 'auto',
    overflowX: 'auto',
    '& .MuiTabs-flexContainer': {
      borderBottom: 'none',
    },
  },
  tabRoot: {
    textTransform: 'none',
    minWidth: 72,
    minHeight: 'auto',
    padding: '6px 12px',
    border: '1px solid #E5E5E5',
    borderBottom: 'none',
    borderRadius: '4px 4px 0 0',
    marginRight: '2px',
    backgroundColor: '#F0F0F0',
    '&.Mui-selected': {
      fontWeight: 600,
    },
  },
}));

const MedicalPrincipalInsured: React.FC<IMedicalPrincipalInsured> = ({
  generalData,
  pageState,
  onPageStateUpdate,
  isDisabled,
  maxNumber,
}) => {
  const { classes } = useStyles();
  const { id: currentAmendmentID } = useParams<{ id: string }>();
  const [getMedicalLovsLazy] = useLazyQuery(getMedicalPrincipalLovsQuery);

  const [loaded, setLoaded] = useState<boolean>(false);

  const [newPersonDrawerOpen, setNewPersonDrawerOpen] = useState<number>(-1);
  const [newAddressDrawerOpen, setNewAddressDrawerOpen] = useState<number>(-1);
  const [currentSection, setCurrentSection] = useState<string | null>(null);

  const [lists, setLists] = useState<IInsuredLists>({
    id: {},
    insuredAddresses: {},
  });
  const [lovs, setLovs] = useState<IInsuredLovs>({
    continuity: {},
    insuredRelations: {},
  });

  const insuredValues = pageState.values.policyPersons.insured;
  const insuredErrors = pageState.errors.policyPersons.insured;
  const insuredTouched = pageState.touched.policyPersons.insured;

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [fetchedPersonsLazy] = useLazyQuery(fetchedPersons);
  const [getPersonAddressListLazy] = useLazyQuery(fetchedPersonAddressList);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleOpenDrawer = (section: string, rowIndex: number) => {
    setCurrentSection(section);
    setNewPersonDrawerOpen(rowIndex);
  };

  const handleOpenAddressDrawer = (section: string, rowIndex: number) => {
    setCurrentSection(section);
    setNewAddressDrawerOpen(rowIndex);
  };

  const handleInsuredAddresses = async (personId: string) => {
    const result = await getPersonAddressListLazy({
      variables: { selectedPersonID: personId },
      errorPolicy: 'all',
    });
    return extractPersonsAddressesFixed(result.data);
  };

  const handleInsuredSearch = async (inputValue: string) => {
    if (inputValue?.length > 3) {
      const newData = await fetchedPersonsLazy({
        variables: {
          searchKeyword: inputValue,
          selectedBusinessPartners: [generalData.primaryBPID],
        },
      });

      return extractPersonsFixed(newData.data);
    }

    return {} as Record<string, string>;
  };

  const initialize = async () => {
    const { data: lovsData } = await getMedicalLovsLazy();

    const lists = getMedicalPrincipalLovs(
      lovsData,
      generalData?.lineExternalCode
    );
    setLovs({
      continuity: lists.continuityOptions,
      insuredRelations: lists.insuredRelations,
    });

    const newList: Record<number, Record<string, string>> = {};
    insuredValues.forEach((insured, index) => {
      if (!newList[index]) {
        newList[index] = {};
      }
      newList[index][insured.address] = insured.addressDetails;
    });
    setLists((prevLists) => ({
      ...prevLists,
      insuredAddresses: newList,
    }));
    setLoaded(true);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFieldBlur = async (fieldName: string, rowIndex: number) => {
    const newPageState = cloneDeep(pageState);
    newPageState.touched.policyPersons.insured[rowIndex][fieldName] = true;

    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = async (
    fieldName: keyof IInsuredDetailedData,
    value: unknown,
    rowIndex: number,
    touched = false,
    updatedList: IInsuredLists = lists
  ) => {
    const newPageState = cloneDeep(pageState);
    const newLists = { ...updatedList };

    if (fieldName === 'id') {
      const mainValue: string = Array.isArray(value) ? value[0] : value;

      if (isEmpty(mainValue) || isGuid(mainValue)) {
        newPageState.values.policyPersons.insured[rowIndex][fieldName] =
          mainValue;
        newPageState.errors.policyPersons.insured[rowIndex][fieldName] = '';
        if (touched) {
          newPageState.touched.policyPersons.insured[rowIndex][fieldName] =
            true;
        }

        newPageState.values.policyPersons.insured[rowIndex].address = '';
        newPageState.values.policyPersons.insured[rowIndex].addressDetails = '';

        if (isGuid(mainValue)) {
          const searchAddressResult = await handleInsuredAddresses(mainValue);

          const insuredAddresses = searchAddressResult;
          const extractedAddresses: Record<string, string> = {};
          if (insuredAddresses) {
            Object.keys(insuredAddresses).forEach((key) => {
              extractedAddresses[key] = insuredAddresses[key].address;
              if (insuredAddresses[key].isPrimary) {
                newPageState.values.policyPersons.insured[rowIndex].address =
                  key;
                newPageState.values.policyPersons.insured[
                  rowIndex
                ].addressDetails = insuredAddresses[key].address;
              }
            });
            newPageState.errors.policyPersons.insured[rowIndex].insuredAddress =
              '';
            newLists['insuredAddresses'][rowIndex] = extractedAddresses;
          }
        }
      }
    } else {
      (newPageState.values.policyPersons.insured[rowIndex] as any)[fieldName] =
        value;

      newPageState.errors.policyPersons.insured[rowIndex][fieldName] = '';
      if (touched) {
        newPageState.touched.policyPersons.insured[rowIndex][fieldName] = true;
      }
    }

    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onPersonAddition = async (personId: string, personFullName: string) => {
    const rowIndex = newPersonDrawerOpen;

    const newPageState = cloneDeep(pageState);

    const newLists = cloneDeep(lists);

    newPageState.values.policyPersons.insured[rowIndex]['id'] = personId;
    newPageState.errors.policyPersons.insured[rowIndex]['id'] = '';
    newPageState.touched.policyPersons.insured[rowIndex]['id'] = true;

    newLists.id[rowIndex] = {};
    newLists.id[rowIndex][personId] = personFullName;

    const searchAddressResult = await handleInsuredAddresses(personId);

    const insuredAddresses = searchAddressResult;
    const extractedAddresses: Record<string, string> = {};
    if (insuredAddresses) {
      Object.keys(insuredAddresses).forEach((key) => {
        extractedAddresses[key] = insuredAddresses[key].address;
        if (insuredAddresses[key].isPrimary) {
          newPageState.values.policyPersons.insured[rowIndex].address = key;
          newPageState.values.policyPersons.insured[rowIndex].addressDetails =
            insuredAddresses[key].address;
        }
      });
      newPageState.errors.policyPersons.insured[rowIndex].insuredAddress = '';
      newLists['insuredAddresses'][rowIndex] = extractedAddresses;
    }

    if (Object.keys(newLists['insuredAddresses'][rowIndex]).length === 1) {
      newPageState.values.policyPersons.insured[rowIndex]['address'] =
        Object.keys(newLists['insuredAddresses'][rowIndex])[0];
    }

    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onAddressAddition = async (
    addressId: string,
    addressFullName: string
  ) => {
    const rowIndex = newAddressDrawerOpen;

    const newPageState = cloneDeep(pageState);

    const newLists = cloneDeep(lists);

    newPageState.values.policyPersons.insured[rowIndex]['address'] = addressId;
    newPageState.errors.policyPersons.insured[rowIndex]['address'] = '';
    newPageState.touched.policyPersons.insured[rowIndex]['address'] = true;

    newLists.insuredAddresses[rowIndex] = {};
    newLists.insuredAddresses[rowIndex][addressId] = addressFullName;

    newPageState.values.policyPersons.insured[rowIndex].address = addressId;
    newPageState.values.policyPersons.insured[rowIndex].addressDetails =
      addressFullName;

    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onFieldSearch = async (
    fieldName: string,
    value: string,
    rowIndex: number
  ) => {
    const newLists = cloneDeep(lists);

    if (fieldName === 'id') {
      newLists['id'][rowIndex] = await handleInsuredSearch(value);
    }

    setLists(newLists);
  };

  const getDefaultPersonListIfEmpty = (
    list: Record<string, string>,
    insured: any
  ) => {
    if (Object.keys(list || {}).length > 0 || !insured.fullName) {
      return list;
    }

    const newList: Record<string, string> = {
      [insured.id]: insured.fullName,
    };

    return newList;
  };

  const getInsuredLov = (row: IInsuredDetailedData) => {
    const result = { ...lovs.insuredRelations };
    if (row.relation !== 'SPOUSE') {
      if (insuredValues.some((a) => a.relation === 'SPOUSE')) {
        delete result['SPOUSE'];
      }
    }
    return result;
  };

  const renderInsuredRow = (row: IInsuredDetailedData) => {
    const rowIndex = insuredValues.indexOf(row);
    return (
      <div
        className={
          maxNumber === 1 && insuredValues.length === 1
            ? undefined
            : classes.container
        }
        key={rowIndex}
      >
        <div className={classes.fieldRow}>
          <div className={classes.fieldRowChildSpan2}>
            <NewChipsInput
              key="id"
              name="id"
              title="Insured*"
              placeholder="Insured"
              value={insuredValues[rowIndex].id}
              error={
                insuredTouched[rowIndex].id ? insuredErrors[rowIndex].id : ''
              }
              onChange={(v) => {
                const value = isArray(v) ? v[0] : v;
                onFieldUpdate('id', value, rowIndex);
              }}
              onSearch={async (v) => {
                const value = isArray(v) ? v[0] : v;
                onFieldSearch('id', value, rowIndex);
              }}
              disabled={isDisabled}
              items={getDefaultPersonListIfEmpty(
                lists?.id[rowIndex],
                insuredValues[rowIndex]
              )}
              required
              material
              supportLink={isDisabled ? '' : 'Add Person'}
              onLinkClick={(e) => {
                e.preventDefault();
                handleOpenDrawer('insured', rowIndex);
              }}
              customStyles={{
                labelStyles: classes.label,
              }}
            />
          </div>
          <div className={classes.fieldRowChildSpan2}>
            {row.isPrincipal ? (
              <NewChipsInput
                key="address"
                name="address"
                title="Insured Address*"
                placeholder="Insured Address"
                value={insuredValues[rowIndex].address}
                error={
                  insuredTouched[rowIndex].address
                    ? insuredErrors[rowIndex].address
                    : ''
                }
                onChange={(v) => onFieldUpdate('address', v, rowIndex)}
                disabled={isDisabled}
                items={lists.insuredAddresses[rowIndex]}
                required
                material
                supportLink={isDisabled ? '' : 'Add Address'}
                onLinkClick={(e) => {
                  e.preventDefault();
                  handleOpenAddressDrawer('insured', rowIndex);
                }}
                customStyles={{
                  labelStyles: classes.label,
                }}
              />
            ) : (
              <NewChipsInput
                key="relation"
                name="relation"
                title="Relation*"
                placeholder="Relation"
                value={insuredValues[rowIndex].relation}
                error={
                  insuredTouched[rowIndex].relation
                    ? insuredErrors[rowIndex].relation
                    : ''
                }
                onChange={(v) => onFieldUpdate('relation', v, rowIndex)}
                disabled={isDisabled}
                items={getInsuredLov(row)}
                required
                material
                customStyles={{
                  labelStyles: classes.label,
                }}
              />
            )}
          </div>
          <TextInputFormField
            key="cardNumber"
            name="cardNumber"
            title="Card Number"
            placeholder="Card Number"
            value={insuredValues[rowIndex].cardNumber}
            error={
              insuredTouched[rowIndex].cardNumber
                ? insuredErrors[rowIndex].cardNumber
                : ''
            }
            onBlur={() => onFieldBlur('cardNumber', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('cardNumber', v.target.value, rowIndex)
            }
            disabled={isDisabled}
            material
          />
          <NewChipsInput
            key="continuity"
            name="continuity"
            title="Continuity"
            placeholder="Continuity"
            required
            value={insuredValues[rowIndex].continuity}
            error={
              insuredTouched[rowIndex].continuity
                ? insuredErrors[rowIndex].continuity
                : ''
            }
            disabled={isDisabled}
            items={lovs.continuity}
            onBlur={() => onFieldBlur('continuity', rowIndex)}
            onChange={(v) => onFieldUpdate('continuity', v, rowIndex)}
            material
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <TextInputFormField
            key="tpaReference1"
            name="tpaReference1"
            title="TPA Reference 1"
            placeholder="TPA Reference 1"
            value={insuredValues[rowIndex].tpaReference1}
            error={
              insuredTouched[rowIndex].tpaReference1
                ? insuredErrors[rowIndex].tpaReference1
                : ''
            }
            onBlur={() => onFieldBlur('tpaReference1', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('tpaReference1', v.target.value, rowIndex)
            }
            disabled={isDisabled}
            material
          />

          <TextInputFormField
            key="tpaReference2"
            name="tpaReference2"
            title="TPA Reference 2"
            placeholder="TPA Reference 2"
            value={insuredValues[rowIndex].tpaReference2}
            error={
              insuredTouched[rowIndex].tpaReference2
                ? insuredErrors[rowIndex].tpaReference2
                : ''
            }
            onBlur={() => onFieldBlur('tpaReference2', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('tpaReference2', v.target.value, rowIndex)
            }
            disabled={isDisabled}
            material
          />
          <TextInputFormField
            key="exclusions"
            name="exclusions"
            title="Exclusions"
            placeholder="Exclusions"
            value={insuredValues[rowIndex].exclusions}
            error={
              insuredTouched[rowIndex].exclusions
                ? insuredErrors[rowIndex].exclusions
                : ''
            }
            onBlur={() => onFieldBlur('exclusions', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('exclusions', v.target.value, rowIndex)
            }
            disabled={isDisabled}
            className={classes.fullFieldRow}
            material
          />

          <TextInputFormField
            key="remarks"
            name="remarks"
            title="Remarks"
            placeholder="Remarks"
            value={insuredValues[rowIndex].remarks}
            error={
              insuredTouched[rowIndex].remarks
                ? insuredErrors[rowIndex].remarks
                : ''
            }
            onBlur={() => onFieldBlur('remarks', rowIndex)}
            onChange={(v) => onFieldUpdate('remarks', v.target.value, rowIndex)}
            disabled={isDisabled}
            className={classes.fullFieldRow}
            material
          />
        </div>
      </div>
    );
  };

  const onInsuredRowAdd = () => {
    if (!isDisabled && insuredValues.length < maxNumber) {
      const newPageState = cloneDeep(pageState);
      const newRowIndex = newPageState.values.policyPersons.insured.length;
      newPageState.values.policyPersons.insured.push({
        id: '',
        order: `${newRowIndex + 1}`,
        address: '',
        grLimitDays: undefined,
        cardNumber: '',
        continuity: '',
        tpaReference1: '',
        tpaReference2: '',
        exclusions: '',
        remarks: '',
        relation: '',
        isPrincipal: false,
      });

      newPageState.errors.policyPersons.insured.push({
        id: '',
        address: '',
        grLimitDays: '',
        cardNumber: '',
        continuity: '',
        tpaReference1: '',
        tpaReference2: '',
        exclusions: '',
        remarks: '',
        type: '',
        relation: '',
      });

      newPageState.touched.policyPersons.insured.push({
        id: false,
        address: false,
        grLimitDays: false,
        cardNumber: false,
        continuity: false,
        tpaReference1: false,
        tpaReference2: false,
        exclusions: false,
        remarks: false,
        type: false,
        relation: false,
      });

      onPageStateUpdate(newPageState);

      setSelectedTab(insuredValues.length); // Set to the new index
    }
  };

  const onInsuredRowDelete = (rowIndex: number) => {
    if (!isDisabled) {
      const newPageState = cloneDeep(pageState);
      newPageState.values.policyPersons.insured = removeObjectAtIndex(
        newPageState.values.policyPersons.insured,
        rowIndex
      );
      newPageState.errors.policyPersons.insured = removeObjectAtIndex(
        newPageState.errors.policyPersons.insured,
        rowIndex
      );
      newPageState.touched.policyPersons.insured = removeObjectAtIndex(
        newPageState.touched.policyPersons.insured,
        rowIndex
      );

      setLists((prevLists) => {
        const newId: Record<number, Record<string, string>> = {};
        const newInsuredAddresses: Record<number, Record<string, string>> = {};

        Object.keys(prevLists.id).forEach((key) => {
          const keyNum = Number(key);
          if (keyNum < rowIndex) {
            newId[keyNum] = prevLists.id[keyNum];
            newInsuredAddresses[keyNum] = prevLists.insuredAddresses[keyNum];
          } else if (keyNum > rowIndex) {
            newId[keyNum - 1] = prevLists.id[keyNum];
            newInsuredAddresses[keyNum - 1] =
              prevLists.insuredAddresses[keyNum];
          }
        });

        return {
          id: newId,
          insuredAddresses: newInsuredAddresses,
        };
      });

      onPageStateUpdate(newPageState);

      // Adjust selectedTab
      if (selectedTab >= newPageState.values.policyPersons.insured.length) {
        setSelectedTab(
          Math.max(0, newPageState.values.policyPersons.insured.length - 1)
        );
      }
    }
  };

  if (!loaded) {
    return null;
  }

  return (
    <WidgetPaper>
      <WidgetSection title="Insured Details">
        <div className={classes.tabsContainer}>
          <div className={classes.tabsWrapper}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="Insured Tabs"
              variant="scrollable"
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.indicator,
              }}
            >
              {insuredValues.map((v, index) => (
                <Tab
                  key={index}
                  className={classes.tabRoot}
                  label={
                    <div
                      className={`${classes.tabLabel} ${
                        index === selectedTab ? classes.tabActiveTitle : ''
                      }`}
                    >
                      {v.isPrincipal ? 'Principal' : `Adherent ${index}`}
                      {!isDisabled &&
                        insuredValues.length > 1 &&
                        !v.isPrincipal && (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              onInsuredRowDelete(index);
                            }}
                            className={classes.tabIconButton}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                    </div>
                  }
                  id={`insured-tab-${index}`}
                  aria-controls={`insured-tabpanel-${index}`}
                  value={index}
                />
              ))}
            </Tabs>
          </div>
          {insuredValues.length < maxNumber && (
            <IconButton
              className={classes.addBtn}
              onClick={onInsuredRowAdd}
              disabled={isDisabled}
              aria-label="Add"
            >
              <AddIcon color="primary" />
            </IconButton>
          )}
        </div>
        {insuredValues.map(
          (v, index) =>
            selectedTab === index && (
              <TabPanel index={index} key={index}>
                {renderInsuredRow(v)}
              </TabPanel>
            )
        )}
        {newPersonDrawerOpen !== -1 && currentSection === 'insured' && (
          <ProposalPolicyPersonDrawer
            open={newPersonDrawerOpen !== -1}
            onClose={() => setNewPersonDrawerOpen(-1)}
            section={currentSection}
            successRoute="/production/amendment/"
            proposalId={currentAmendmentID}
            lineExternalCode={generalData?.lineExternalCode}
            onSubmitSuccess={onPersonAddition}
          />
        )}
        {newAddressDrawerOpen !== -1 && currentSection === 'insured' && (
          <PersonAddressDrawer
            open={newAddressDrawerOpen !== -1}
            onClose={() => setNewAddressDrawerOpen(-1)}
            personId={insuredValues?.[newAddressDrawerOpen]?.id || ''}
            onSubmitSuccess={onAddressAddition}
          />
        )}
      </WidgetSection>
    </WidgetPaper>
  );
};

export default MedicalPrincipalInsured;
