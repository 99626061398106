import React, { useEffect, useState } from 'react';
import EnhancedModal from '../../../../../components/common/generic-modal/EnhancedModal';
import ListingWidget from '../../../../../components/form-fields/listing-widget/ListingWidget';
import { IListingData } from '../../../../../models/listing';
import { getTreatyBreakdownTableData } from '../content';
import { useQuery } from '@apollo/client';
import { getTreatyDistPerCoverQuery } from '../queries';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import { getError } from '../../../../../utils/graph-utils';
import { mapToBreakdownTableData } from '../utils';

interface ITreatyDistributionBreakdown {
  isOpen: boolean;
  onClose: () => void;
  policy: {
    id: string;
    name: string;
  };
  cover: {
    id: string;
    name: string;
  };
}

const TreatyDistributionBreakdown: React.FC<ITreatyDistributionBreakdown> = ({
  isOpen,
  onClose,
  policy,
  cover,
}) => {
  const { data, loading, error } = useQuery(getTreatyDistPerCoverQuery, {
    variables: {
      policyId: policy.id,
      coverId: cover.id,
    },
    errorPolicy: 'all',
  });

  if (error) {
    toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
  }

  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageNumber: 1,
    pageSize: 10,
    totalCount: 0,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    setTableData(
      mapToBreakdownTableData(
        data?.Production?.queries?.GetTreatyDistributionPerPolicyCover
      )
    );
  }, [data]);

  return (
    <EnhancedModal
      open={isOpen}
      blur={true}
      onClose={onClose}
      title={`Treaty Distribution of ${policy.name} - ${cover.name}`}
    >
      <ListingWidget
        name="receivableBillsSchedule"
        data={tableData}
        loading={loading}
        tableSettings={{ headers: getTreatyBreakdownTableData(data) }}
        disableSelection
        inline
        title=""
        actions={[]}
      />
    </EnhancedModal>
  );
};

export default TreatyDistributionBreakdown;
