import React from 'react';
import EntityInfoWidget from '../../../../../components/widgets/entity-info/EntityInfoWidget';
import { IAbstractRecord } from '../../../../../models';
import { EnhancedDisplayType } from '../../../../../components/enhanced-form/EnhancedDisplay';
import { IEntityInfoView } from '../../../../../components/widgets/entity-info/EntityInfoFields';

const LeftPanel: React.FC<{ data: IAbstractRecord }> = ({ data }) => {
  const policy =
    data?.Production?.entities?.policy?.views?.Production_all?.properties;

  const relatedUsers =
    data?.Production?.entities?.policy?.queries?.production?.relatedUsers[0];

  const createdBy = `${relatedUsers?.createdBy_firstName} ${relatedUsers?.createdBy_lastName}`;
  const modifiedBy = `${relatedUsers?.modifiedBy_firstName} ${relatedUsers?.modifiedBy_lastName}`;

  const entityView: IEntityInfoView = {
    iconUrl: '',
    socialMedia: {
      facebook: '',
      twitter: '',
      linkedIn: '',
    },
    sections: [
      {
        title: '',
        name: 'BasicInformation',
        properties: {
          createdBy: {
            name: 'createdBy',
            title: 'Created By',
            value: createdBy,
            multiline: false,
          },
          createdOn: {
            name: 'createdOn',
            title: 'Created On',
            value: policy?.createdOn,
            multiline: false,
            type: EnhancedDisplayType.DateTime,
          },
          modifiedBy: {
            name: 'modifiedBy',
            title: 'Modified By',
            value: modifiedBy,
            multiline: false,
          },
          modifiedOn: {
            name: 'modifiedOn',
            title: 'Modified On',
            value: policy?.modifiedOn,
            multiline: false,
            type: EnhancedDisplayType.DateTime,
          },
        },
      },
    ],
  };

  return (
    <EntityInfoWidget
      title={policy?.PolicyNumber}
      view={entityView}
      supportsClassification={true}
      background="rgb(193, 0, 0)"
      classification={{ classifications: {}, value: '' }}
      progress={100}
      supportsProgressBar={true}
    />
  );
};

export default LeftPanel;
