import React, { useEffect, useMemo, useState } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import {
  getAssignedBPPlanListWithoutPaginationQuery,
  getAssignedPersonsQuery,
  getStaticLovsQuery,
  listQuery,
} from './queries';
import {
  dataToLovs,
  getBPPlanLovs,
  mapToListingData,
  staticToLovs,
} from './utils';
import { filterSectionsContent, headers } from './content';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../constants';
import dayjs from 'dayjs';
import { useAppSelector } from '../../redux/hooks';
import ProposalDrawer from '../../forms/proposal-drawer/ProposalDrawer';
import ListingWidget from '../../components/form-fields/listing-widget/ListingWidget';
import { IFilterModel } from '../../components/form-fields/listing-widget';
import { IEnhancedMenuItem } from '../../components/form-fields/table';
import { IAbstractRecord } from '../../models';
import { isEqual } from 'lodash';
import { isEmpty } from '../../utils/validationUtils';

const ProposalPage: React.FC = () => {
  const user = useAppSelector((state) => state.user);
  const businessUserId = user.info.businessUser_Id;
  const [proposalDrawerOpen, setProposalDrawerOpen] = useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>('');
  const isBusinessUser = user.userRoles.includes('Core-BusinessUser');
  const isAdmin = user.userRoles.includes('Insurance-Admin');
  const FILTER_SESSION_KEY = 'proposalsFilter';

  const actions: IEnhancedMenuItem[] = useMemo(
    () => [
      ...[
        isBusinessUser || isAdmin
          ? {
              name: 'new',
              title: '+ New',
              onClick: () => {
                setChosenEditId(undefined);
                setProposalDrawerOpen(true);
              },
            }
          : undefined,
      ],
    ],
    [isBusinessUser, isAdmin]
  );

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          line: [],
          subline: [],
          plan: [],
          businessPartner: [],
          insured: [],
          status: [],
          statusReason: [],
          effectiveOn: [],
        };

  const [filterValues, setFilterValues] = useState<IFilterModel>({
    namedFilters: initialFilterValues,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
  });

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [getProductionPoliciesLazy, { loading: loadingTable }] =
    useLazyQuery(listQuery());
  const [getStaticLovs] = useLazyQuery(getStaticLovsQuery());
  const [getAssignedPersons] = useLazyQuery(getAssignedPersonsQuery());
  const [getAssignedBPPlanListQueryWithoutPagination] = useLazyQuery(
    getAssignedBPPlanListWithoutPaginationQuery()
  );

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = () => {
    Promise.all([
      getAssignedBPPlanListQueryWithoutPagination({
        variables: {
          selectedBusinessUserID: businessUserId,
          selectedLineID: filterValues.namedFilters.line,
        },
      }),
      getStaticLovs(),
    ]).then(([{ data: assignedBPPlanList }, { data: staticLovsData }]) => {
      const planLovs = getBPPlanLovs(
        assignedBPPlanList,
        filterValues.namedFilters
      );
      const staticLovs = staticToLovs(staticLovsData);
      const lovs = {
        ...planLovs,
        ...staticLovs,
      };

      setFilterSections(filterSectionsContent(lovs, filterValues.namedFilters));
    });
  };

  const getListingData = (filter = filterValues) => {
    if (!filter) {
      return {};
    }

    const filterV = filter.namedFilters;
    const pagination = filter.pagination;
    return getProductionPoliciesLazy({
      variables: {
        currentPage: pagination.pageNumber,
        currentPageSize: pagination.pageSize,
        KeywordSearch: isEmpty(filter.searchKey) ? null : filter.searchKey,
        // OrderBy: {
        //   Attribute: filter.orderBy || 'Production_Proposal_PolicyEffectiveDate',
        //   Descending: filter.descending || true,
        // },
        selectedLines:
          filterV?.line && filterV?.line?.length > 0 ? filterV?.line : null,
        selectedSublines:
          filterV?.subline && filterV?.subline?.length > 0
            ? filterV?.subline
            : null,
        selectedPlans:
          filterV?.plan && filterV?.plan?.length > 0 ? filterV?.plan : null,
        selectedBusinessPartners:
          filterV?.businessPartner && filterV?.businessPartner?.length > 0
            ? filterV?.businessPartner
            : null,
        selectedInsuredIDs:
          filterV?.insured && filterV?.insured?.length > 0
            ? filterV?.insured
            : null,
        SelectedStatuses:
          filterV?.status && filterV?.status?.length > 0
            ? filterV.status
            : null,
        selectedStatusReasons:
          filterV?.statusReason && filterV?.statusReason?.length > 0
            ? filterV.statusReason
            : null,
        FromEffectiveOn: filterV?.effectiveOn?.[0]
          ? dayjs(filterV?.effectiveOn?.[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
          : null,
        ToEffectiveOn: filterV?.effectiveOn?.[1]
          ? dayjs(filterV?.effectiveOn?.[1]).format(SEND_TO_BACKEND_DATE_FORMAT)
          : null,
      },
    })
      .then(({ data: proposalsData }) => {
        if (proposalsData.error) {
          toast.error(
            <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
          );
          return;
        }

        setFilterValues(filter);

        // Update table data
        const newTableData = mapToListingData(proposalsData);
        setTableData(newTableData);
      })
      .catch(() => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      });
  };

  function handlePageChange(
    page: number,
    filterModel = filterValues
  ): Promise<void> {
    return new Promise<void>(() =>
      getListingData({
        ...filterModel,
        pagination: {
          ...filterModel.pagination,
          pageNumber: page,
        },
      })
    );
  }

  const onFilterUpdate = async (
    filters: IAbstractRecord,
    inputName?: string
  ) => {
    if (isEqual(filters, filterValues.namedFilters)) {
      // Do nothing if filters are the same
      return;
    }
    const newFilters = {
      ...filterValues,
      namedFilters: filters,
    };

    if (inputName !== 'insured') {
      setFilter(filters, FILTER_SESSION_KEY);
    }
    setFilterValues(newFilters);
    getListingData(newFilters);
  };

  const renderMainChildren = () => (
    <>
      <ListingWidget
        title="Proposals"
        name="proposals"
        data={tableData}
        tableSettings={{ headers }}
        onPageChange={(filterModel) => {
          const newFilterModel = {
            ...filterValues,
            ...filterModel,
          };
          const page = filterModel.pagination.pageNumber;
          return handlePageChange(page, newFilterModel);
        }}
        usePagination
        disableSelection
        disabledSorting
        actions={actions}
        loading={loadingTable}
      />
      {proposalDrawerOpen && (
        <ProposalDrawer
          open={proposalDrawerOpen}
          onClose={() => setProposalDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0);
          }}
          proposalId={chosenEditId}
          proposalDetailsInfo={undefined}
        />
      )}
    </>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        // disabled={loadingFilter}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
        onAutocompleteSearch={(
          fieldName: string,
          value: string,
          pagination
        ) => {
          if (fieldName === 'insured') {
            return getAssignedPersons({
              variables: {
                selectedBusinessPartners:
                  filterValues.namedFilters?.businessPartner,
                searchKeyword: value,
                pagination,
              },
            }).then((result) => {
              const lovs = dataToLovs(result.data, true);

              return {
                options: lovs.insured,
                totalCount:
                  result.data?.Production?.queries
                    ?.GetAssignedPersonsWithPagination?.paging?.totalCount,
              };
            });
          }

          return new Promise<{
            options: Record<string, string>;
            totalCount: number;
          }>((resolve) => {
            resolve({ options: {}, totalCount: 0 });
          });
        }}
      />
    );

  return (
    <StaticLayout
      name="Proposals"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default ProposalPage;
