import dayjs from 'dayjs';
import { FormInputTypes, IDynamicSection } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';
// import { useLazyQuery } from "@apollo/client";
// import { checkPlanExists } from "../plan-drawer/queries";

export const getSections = (
  values: Record<string, any>,
  newPlanDetailsEnums: any,
  lineIdExternalCode: string
): Record<string, IDynamicSection> => {
  const isMotor = ['51', '50', '4'].includes(lineIdExternalCode);
  const showGRDetails = ['6', '19'].includes(lineIdExternalCode);
  const showSOSService = ['51', '4'].includes(lineIdExternalCode);
  const showReplacement = ['51'].includes(lineIdExternalCode);

  return {
    section1: {
      title: 'General Details',
      inputs: {
        planName: {
          name: 'planName',
          title: 'Plan Name',
          type: FormInputTypes.text,
          placeholder: 'Plan Name',
          value: values.planName,
          disabled: false,
          required: true,
        },
        externalCode: {
          name: 'externalCode',
          title: 'External Code',
          type: FormInputTypes.text,
          placeholder: 'External Code',
          value: values.externalCode,
          disabled: false,
          required: true,
        },
        arabicName: {
          name: 'arabicName',
          title: 'Arabic Name',
          type: FormInputTypes.text,
          placeholder: 'Arabic Name',
          value: values.arabicName,
          required: true,
          disabled: false,
        },
        abbreviation: {
          name: 'abbreviation',
          title: 'Abbreviation',
          type: FormInputTypes.text,
          placeholder: 'Abbreviation',
          value: values.abbreviation,
          disabled: false,
          required: true,
        },
        effectiveFrom: {
          name: 'effectiveFrom',
          title: 'Effective From',
          type: FormInputTypes.date,
          placeholder: 'Effective From',
          value: values.effectiveFrom,
          disabled: false,
          required: true,
        },
        effectiveTo: {
          name: 'effectiveTo',
          title: 'Effective To',
          type: FormInputTypes.date,
          placeholder: 'Effective To',
          value: values.effectiveTo,
          required: true,
          disabled: false,
          minDate: new Date(values.effectiveFrom),
        },
        planCurrency: {
          name: 'planCurrency',
          title: 'Plan Currency',
          type: FormInputTypes.chips,
          placeholder: 'Plan Currency',
          value: !isEmpty(values.planCurrency) ? values.planCurrency : '',
          multiple: false,
          disabled: false,
          required: true,
          selectOptions: newPlanDetailsEnums['planCurrency'],
        },
        maxNumberofInsured: {
          name: 'maxNumberofInsured',
          title: 'Max Number of Insured',
          type: FormInputTypes.number,
          placeholder: 'Max Number of Insured',
          value: values.maxNumberofInsured,
          disabled: false,
          required: true,
          minNumber: 0,
        },
        maxNumberofBeneficiary: {
          name: 'maxNumberofBeneficiary',
          title: 'Max Number of Beneficiary',
          type: FormInputTypes.number,
          placeholder: 'Max Number of Beneficiary',
          value: values.maxNumberofBeneficiary,
          required: !isMotor,
          hidden: isMotor,
          disabled: isMotor,
          minNumber: 0,
        },
        clauseEditable: {
          name: 'clauseEditable',
          title: 'Clause Editable',
          placeholder: 'Clause Editable',
          type: FormInputTypes.switch,
          value: values.clauseEditable,
          required: true,
        },
      },
    },
    ...(values &&
      (showGRDetails || isMotor) && {
        section2: {
          title: 'Additional Details',
          inputs: {
            acalCategory: {
              name: 'acalCategory',
              title: 'Acal Category',
              type: FormInputTypes.chips,
              placeholder: 'Acal Category',
              value: !isEmpty(values.acalCategory) ? values.acalCategory : '',
              multiple: false,
              required: isMotor,
              disabled: !isMotor,
              hidden: !isMotor,
              selectOptions: newPlanDetailsEnums['acalCategory'],
            },
            scratching: {
              name: 'scratching',
              title: 'Scratching',
              type: FormInputTypes.chips,
              placeholder: 'Scratching',
              value: !isEmpty(values.scratching) ? values.scratching : '',
              multiple: false,
              required: isMotor,
              disabled: !isMotor,
              hidden: !isMotor,
              selectOptions: newPlanDetailsEnums['ScratchingOptions'],
            },
            sOSService: {
              name: 'sOSService',
              title: 'SOS Service',
              type: FormInputTypes.chips,
              placeholder: 'SOS Service',
              value: !isEmpty(values.sOSService) ? values.sOSService : '',
              multiple: false,
              required: showSOSService,
              disabled: !showSOSService,
              hidden: !showSOSService,
              selectOptions: newPlanDetailsEnums['sOSService'],
            },
            replacementCarApplied: {
              name: 'replacementCarApplied',
              title: 'Replacement Car Applied',
              type: FormInputTypes.chips,
              placeholder: 'Replacement Car Applied',
              value: !isEmpty(values.replacementCarApplied)
                ? values.replacementCarApplied
                : '',
              multiple: false,
              required: showReplacement,
              disabled: !showReplacement,
              hidden: !showReplacement,
              selectOptions: newPlanDetailsEnums['sOSService'],
            },
            replacementCarValue: {
              name: 'replacementCarValue',
              title: 'Replacement Car Value',
              type: FormInputTypes.number,
              placeholder: 'Replacement Car Value',
              value: values.replacementCarValue,
              required: showReplacement,
              disabled: !showReplacement,
              hidden: !showReplacement,
            },
            replacementCarDays: {
              name: 'replacementCarDays',
              title: 'Replacement Car Days',
              type: FormInputTypes.number,
              placeholder: 'Replacement Car Days',
              value: values.replacementCarDays,
              required: showReplacement,
              disabled: !showReplacement,
              hidden: !showReplacement,
            },
            grLimitDetails: {
              name: 'grLimitDetails',
              title: 'GR Limit Days',
              type: FormInputTypes.number,
              placeholder: 'GR Limit Days',
              value: values.grLimitDetails,
              required: showGRDetails,
              hidden: !showGRDetails,
              disabled: !showGRDetails,
              minNumber: 0,
            },
          },
        },
      }),
  };
};
