import React from 'react';
import EnhancedModal from '../../../../../../components/common/generic-modal/EnhancedModal';
import PolicyCertficateFleetSummaryDialog from './PolicyCertficateFleetSummaryDialog';
import { Production_CertificateDetailsOutputInput } from '../../../../../../forms/add-certificate-drawer';

interface IPolicyCertficateFleetSummaryDialogShellProps {
  data: Production_CertificateDetailsOutputInput;
  entityId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const PolicyCertficateFleetSummaryDialogShell: React.FC<
  IPolicyCertficateFleetSummaryDialogShellProps
> = ({ data, entityId, onClose = () => undefined, onSuccess }) => {
  return (
    <EnhancedModal
      onClose={onClose}
      open={true}
      blur={true}
      title={'Add Certificate'}
    >
      <PolicyCertficateFleetSummaryDialog
        data={data}
        entityId={entityId}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    </EnhancedModal>
  );
};

export default PolicyCertficateFleetSummaryDialogShell;
