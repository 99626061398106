import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { cloneDeep, isArray, isEmpty } from 'lodash';
import { Tabs, Tab, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ILineAmendmentProps } from '../../line-amendment';
import { IAmendmentPageState } from '../..';
import { makeStyles } from 'tss-react/mui';
import { fetchedPersonAddressList, fetchedPersons } from './queries';
import { extractPersonsAddressesFixed, extractPersonsFixed } from './utils';
import { isGuid } from '../../../../../utils/validationUtils';
import { IAbstractRecord } from '../../../../../models';
import { removeObjectAtIndex } from '../../../../../utils/helper-utils';
import WidgetPaper from '../../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../../components/common/WidgetSection';
import InsuredRow from './TravelInsuredRow';
import ProposalPolicyPersonDrawer from '../../../../../forms/proposal-policy-person-drawer/ProposalPolicyPersonDrawer';
import PersonAddressDrawer from '../../../../../forms/person-address-drawer/PersonAddressDrawer';
import { ITravelAmendmentLovs } from '../../line-amendment/TravelAmendment';
import TabPanel from '../../../../../components/form-fields/tabs-widget/TabPanel';

interface ITravelPrincipalInsured {
  generalData: ILineAmendmentProps['generalData'];
  pageState: IAmendmentPageState;
  onPageStateUpdate: (pageState: IAmendmentPageState) => void;
  isDisabled: boolean;
  maxNumber: number;
  lovs: ITravelAmendmentLovs;
}

export interface IInsuredLists {
  id: Record<number, Record<string, string>>;
  insuredAddresses: Record<number, Record<string, string>>;
}

const useStyles = makeStyles()(() => ({
  widgetPaper: {
    marginTop: '1em',
  },
  addBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  removeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    top: '8px',
    right: '0',
    margin: '0',
  },
  clearBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 !important',
  },
  cardTitle: {
    fontSize: '15px !important',
    lineHeight: '21px !important',
    margin: '0 0 16px',
    padding: '0',
    fontFamily: 'SourceSansPro-Bold !important',
  },
  tabLabel: {
    fontSize: '14px',
    fontFamily: 'SourceSansPro-Regular',
    textTransform: 'none',
    lineHeight: '18px',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
  },
  tabActiveTitle: {
    fontFamily: 'SourceSansPro-SemiBold !important',
    fontWeight: 600,
  },
  tabIconButton: {
    marginLeft: '8px',
    padding: '0',
  },
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    padding: '0',
    '&::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#ccc',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#aaa',
    },
  },
  tabsWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  indicator: {
    display: 'none',
  },
  tabRoot: {
    textTransform: 'none',
    minWidth: 72,
    minHeight: '31px',
    padding: '4px 8px',
    marginRight: '4px',
    color: '#8e8e8e',
    border: '1px solid #E5E5E5',
    borderBottom: 'none',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: '#F0F0F0',
    fontFamily: 'SourceSansPro-Regular',
    '&.Mui-selected': {
      color: '#000000',
      fontFamily: 'SourceSansPro-SemiBold',
      backgroundColor: '#F9F9F9',
      borderBottom: 'none',
      fontWeight: 600,
    },
  },
  tabsRoot: {
    minHeight: 'auto',
    overflowX: 'auto',
    '& .MuiTabs-flexContainer': {
      borderBottom: 'none',
    },
  },
}));

const TravelPrincipalInsured: React.FC<ITravelPrincipalInsured> = ({
  generalData,
  pageState,
  onPageStateUpdate,
  isDisabled,
  maxNumber,
  lovs,
}) => {
  const { classes } = useStyles();
  const { id: currentAmendmentID } = useParams<{ id: string }>();

  const [loaded, setLoaded] = useState<boolean>(false);

  const [newPersonDrawerOpen, setNewPersonDrawerOpen] = useState<number>(-1);
  const [newAddressDrawerOpen, setNewAddressDrawerOpen] = useState<number>(-1);
  const [currentSection, setCurrentSection] = useState<string | null>(null);

  const [lists, setLists] = useState<IInsuredLists>({
    id: {},
    insuredAddresses: {},
  });
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const insuredValues = pageState.values.policyPersons.insured;
  const insuredErrors = pageState.errors.policyPersons.insured;
  const insuredTouched = pageState.touched.policyPersons.insured;

  const [fetchedPersonsLazy] = useLazyQuery(fetchedPersons);
  const [getPersonAddressListLazy] = useLazyQuery(fetchedPersonAddressList);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleOpenDrawer = (section: string, rowIndex: number) => {
    setCurrentSection(section);
    setNewPersonDrawerOpen(rowIndex);
  };

  const handleOpenAddressDrawer = (section: string, rowIndex: number) => {
    setCurrentSection(section);
    setNewAddressDrawerOpen(rowIndex);
  };

  const handleInsuredAddresses = async (personId: string) => {
    const result = await getPersonAddressListLazy({
      variables: { selectedPersonID: personId },
      errorPolicy: 'all',
    });
    return extractPersonsAddressesFixed(result.data);
  };

  const handleInsuredSearch = async (
    inputValue: string
  ): Promise<Record<string, string>> => {
    if (inputValue?.length > 3) {
      const newData = await fetchedPersonsLazy({
        variables: {
          searchKeyword: inputValue,
          selectedBusinessPartners: [generalData.primaryBPID],
        },
      });

      return extractPersonsFixed(newData.data);
    }

    return {};
  };

  const initialize = () => {
    const newList: Record<number, Record<string, string>> = {};
    insuredValues.forEach((insured, index) => {
      if (!newList[index]) {
        newList[index] = {};
      }
      newList[index][insured.address] = insured.addressDetails;
    });
    setLists((prevLists) => ({
      ...prevLists,
      insuredAddresses: newList,
    }));
    setLoaded(true);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFieldBlur = async (fieldName: string, rowIndex: number) => {
    const newPageState = cloneDeep(pageState);
    newPageState.touched.policyPersons.insured[rowIndex][fieldName] = true;

    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = async (
    fieldName: string,
    value: string | string[],
    rowIndex: number,
    touched = false,
    updatedList: IInsuredLists = lists
  ) => {
    const newPageState = cloneDeep(pageState);
    const newLists = { ...updatedList };

    if (fieldName === 'id') {
      const mainValue: string = isArray(value) ? value[0] : value;

      if (isEmpty(mainValue) || isGuid(mainValue)) {
        newPageState.values.policyPersons.insured[rowIndex][fieldName] =
          mainValue;
        newPageState.errors.policyPersons.insured[rowIndex][fieldName] = '';
        if (touched) {
          newPageState.touched.policyPersons.insured[rowIndex][fieldName] =
            true;
        }

        newPageState.values.policyPersons.insured[rowIndex].address = '';
        newPageState.values.policyPersons.insured[rowIndex].addressDetails = '';

        if (isGuid(mainValue)) {
          const searchAddressResult = await handleInsuredAddresses(mainValue);

          const insuredAddresses = searchAddressResult;
          const extractedAddresses: Record<string, string> = {};
          if (insuredAddresses) {
            Object.keys(insuredAddresses).forEach((key) => {
              extractedAddresses[key] = insuredAddresses[key].address;
              if (insuredAddresses[key].isPrimary) {
                newPageState.values.policyPersons.insured[rowIndex].address =
                  key;
                newPageState.values.policyPersons.insured[
                  rowIndex
                ].addressDetails = insuredAddresses[key].address;
              }
            });
            newPageState.errors.policyPersons.insured[rowIndex].insuredAddress =
              '';
            newLists['insuredAddresses'][rowIndex] = extractedAddresses;
          }
        }
      }
    } else {
      (newPageState.values.policyPersons.insured[rowIndex] as IAbstractRecord)[
        fieldName
      ] = value;

      newPageState.errors.policyPersons.insured[rowIndex][fieldName] = '';
      if (touched) {
        newPageState.touched.policyPersons.insured[rowIndex][fieldName] = true;
      }
    }

    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onPersonAddition = async (person: string, personFullName?: string) => {
    const rowIndex = newPersonDrawerOpen;

    const newPageState = cloneDeep(pageState);

    const newLists = cloneDeep(lists);

    newPageState.values.policyPersons.insured[rowIndex]['id'] = person;
    newPageState.errors.policyPersons.insured[rowIndex]['id'] = '';
    newPageState.touched.policyPersons.insured[rowIndex]['id'] = true;

    newLists.id[rowIndex] = {};
    newLists.id[rowIndex][person] = personFullName;

    const searchAddressResult = await handleInsuredAddresses(person);

    const insuredAddresses = searchAddressResult;
    const extractedAddresses: Record<string, string> = {};
    if (insuredAddresses) {
      Object.keys(insuredAddresses).forEach((key) => {
        extractedAddresses[key] = insuredAddresses[key].address;
        if (insuredAddresses[key].isPrimary) {
          newPageState.values.policyPersons.insured[rowIndex].address = key;
          newPageState.values.policyPersons.insured[rowIndex].addressDetails =
            insuredAddresses[key].address;
        }
      });
      newPageState.errors.policyPersons.insured[rowIndex].insuredAddress = '';
      newLists['insuredAddresses'][rowIndex] = extractedAddresses;
    }

    if (Object.keys(newLists['insuredAddresses'][rowIndex]).length === 1) {
      newPageState.values.policyPersons.insured[rowIndex]['address'] =
        Object.keys(newLists['insuredAddresses'][rowIndex])[0];
    }

    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onAddressAddition = async (
    address: string,
    addressFullName?: string
  ) => {
    const rowIndex = newAddressDrawerOpen;

    const newPageState = cloneDeep(pageState);

    const newLists = cloneDeep(lists);

    newPageState.values.policyPersons.insured[rowIndex]['address'] = address;
    newPageState.errors.policyPersons.insured[rowIndex]['address'] = '';
    newPageState.touched.policyPersons.insured[rowIndex]['address'] = true;

    newLists.insuredAddresses[rowIndex] = {};
    newLists.insuredAddresses[rowIndex][address] = addressFullName;

    newPageState.values.policyPersons.insured[rowIndex].address = address;
    newPageState.values.policyPersons.insured[rowIndex].addressDetails =
      addressFullName;

    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onFieldSearch = async (
    fieldName: string,
    value: string,
    rowIndex: number
  ) => {
    const newLists = { ...lists };

    if (fieldName === 'id') {
      const result = await handleInsuredSearch(value);
      newLists['id'][rowIndex] = result;
    }

    setLists(newLists);
  };

  const onInsuredRowAdd = () => {
    if (!isDisabled && insuredValues.length < maxNumber) {
      const newPageState = cloneDeep(pageState);
      const newRowIndex = newPageState.values.policyPersons.insured.length;
      newPageState.values.policyPersons.insured.push({
        id: '',
        order: `${newRowIndex + 1}`,
        address: '',
        cardNumber: '',
        exclusions: '',
        remarks: '',
        relation: '',
        isPrincipal: false,
      });

      newPageState.errors.policyPersons.insured.push({
        id: '',
        address: '',
        grLimitDays: '',
        cardNumber: '',
        continuity: '',
        tpaReference1: '',
        tpaReference2: '',
        exclusions: '',
        remarks: '',
        type: '',
        relation: '',
      });

      newPageState.touched.policyPersons.insured.push({
        id: false,
        address: false,
        grLimitDays: false,
        cardNumber: false,
        continuity: false,
        tpaReference1: false,
        tpaReference2: false,
        exclusions: false,
        remarks: false,
        type: false,
        relation: false,
      });

      onPageStateUpdate(newPageState);

      setSelectedTab(insuredValues.length); // Set to the new index
    }
  };

  const onInsuredRowDelete = (rowIndex: number) => {
    if (!isDisabled) {
      const newPageState = cloneDeep(pageState);
      newPageState.values.policyPersons.insured = removeObjectAtIndex(
        newPageState.values.policyPersons.insured,
        rowIndex
      );
      newPageState.errors.policyPersons.insured = removeObjectAtIndex(
        newPageState.errors.policyPersons.insured,
        rowIndex
      );
      newPageState.touched.policyPersons.insured = removeObjectAtIndex(
        newPageState.touched.policyPersons.insured,
        rowIndex
      );

      setLists((prevLists) => {
        const newId: Record<number, Record<string, string>> = {};
        const newInsuredAddresses: Record<number, Record<string, string>> = {};

        Object.keys(prevLists.id).forEach((key) => {
          const keyNum = Number(key);
          if (keyNum < rowIndex) {
            newId[keyNum] = prevLists.id[keyNum];
            newInsuredAddresses[keyNum] = prevLists.insuredAddresses[keyNum];
          } else if (keyNum > rowIndex) {
            newId[keyNum - 1] = prevLists.id[keyNum];
            newInsuredAddresses[keyNum - 1] =
              prevLists.insuredAddresses[keyNum];
          }
        });

        return {
          id: newId,
          insuredAddresses: newInsuredAddresses,
        };
      });

      onPageStateUpdate(newPageState);

      // Adjust selectedTab
      if (selectedTab >= newPageState.values.policyPersons.insured.length) {
        setSelectedTab(
          Math.max(0, newPageState.values.policyPersons.insured.length - 1)
        );
      }
    }
  };

  if (!loaded) {
    return null;
  }

  return (
    <WidgetPaper className={classes.widgetPaper}>
      <WidgetSection title="Insured Details">
        <div className={classes.tabsContainer}>
          <div className={classes.tabsWrapper}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="Insured Tabs"
              variant="scrollable"
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.indicator,
              }}
            >
              {insuredValues.map((v, index) => (
                <Tab
                  key={index}
                  className={classes.tabRoot}
                  label={
                    <div
                      className={`${classes.tabLabel} ${
                        index === selectedTab ? classes.tabActiveTitle : ''
                      }`}
                    >
                      {v.isPrincipal ? 'Principal' : `Adherent ${index}`}
                      {!isDisabled &&
                        insuredValues.length > 1 &&
                        !v.isPrincipal && (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              onInsuredRowDelete(index);
                            }}
                            className={classes.tabIconButton}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                    </div>
                  }
                  id={`insured-tab-${index}`}
                  aria-controls={`insured-tabpanel-${index}`}
                  value={index}
                />
              ))}
            </Tabs>
          </div>
          {insuredValues.length < maxNumber && (
            <IconButton
              className={classes.addBtn}
              onClick={onInsuredRowAdd}
              disabled={isDisabled}
              aria-label="Add"
            >
              <AddIcon color="primary" />
            </IconButton>
          )}
        </div>
        {insuredValues.map(
          (row, index) =>
            selectedTab === index && (
              <TabPanel index={index} key={`insured-tabpanel-${index}`}>
                <InsuredRow
                  row={row}
                  rowIndex={index}
                  insuredValues={insuredValues}
                  insuredTouched={insuredTouched}
                  insuredErrors={insuredErrors}
                  onFieldUpdate={onFieldUpdate}
                  onFieldSearch={onFieldSearch}
                  onFieldBlur={onFieldBlur}
                  isDisabled={isDisabled}
                  lists={lists}
                  lovs={lovs}
                  handleOpenDrawer={handleOpenDrawer}
                  handleOpenAddressDrawer={handleOpenAddressDrawer}
                />
              </TabPanel>
            )
        )}
        {newPersonDrawerOpen !== -1 && currentSection === 'insured' && (
          <ProposalPolicyPersonDrawer
            open={newPersonDrawerOpen !== -1}
            onClose={() => setNewPersonDrawerOpen(-1)}
            section={currentSection}
            successRoute="/production/amendment/"
            proposalId={currentAmendmentID}
            lineExternalCode={generalData?.lineExternalCode}
            onSubmitSuccess={onPersonAddition}
          />
        )}
        {newAddressDrawerOpen !== -1 && currentSection === 'insured' && (
          <PersonAddressDrawer
            open={newAddressDrawerOpen !== -1}
            onClose={() => setNewAddressDrawerOpen(-1)}
            personId={insuredValues?.[newAddressDrawerOpen]?.id || ''}
            onSubmitSuccess={onAddressAddition}
          />
        )}
      </WidgetSection>
    </WidgetPaper>
  );
};

export default TravelPrincipalInsured;
