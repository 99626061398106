import { gql } from '@apollo/client';

export const getAmendmentMainDetailsQuery = gql`
  query getAmendmentMainDetails($selectedAmendmentID: String!) {
    Production {
      queries {
        getAmendmentDetails(
          selectedAmendmentID: $selectedAmendmentID
          version: null
        ) {
          policy_Type
          policyVersion_Version
          policy_ClauseText
          production_Amendment_AmendmentNumber
          production_Amendment_AmendmentStatus
          policyVersion_Id
          policy_StatusReason {
            Code
            Title
          }
          policy_ProposalID {
            id
          }
          policy_Id
          policy_PolicyNumber
          policy_PolicyEffectiveDate
          policy_PolicyExpiryDate
          line_Id
          line_ExternalCode
          line_Name
          policy_SublineID {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  ExternalCode
                  Name
                }
              }
            }
          }
          plan_Id
          plan_Name

          policy_PlanID {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  ClauseEditable
                  MaxNbInsured
                  MaxNbBeneficiary
                  AcalCategory {
                    Title
                  }
                  Scratching
                  SOSService
                  ReplacementCarApplied
                  ReplacementCarValue
                  ReplacementCarDays
                  GRLimitDays
                }
              }
            }
          }

          policy_PolicyCurrency {
            Code
            Title
            Symbol
          }

          policy_BusinessUserID {
            views {
              SalesforceManagement_all {
                properties {
                  Code
                  AllowPlanCoverEdits
                  AllowClauseEdits
                  EditableLineChargesIDs {
                    views {
                      PlanConfigManagement_all {
                        properties {
                          ExternalCode
                        }
                      }
                    }
                  }
                  PersonID {
                    views {
                      SalesforceManagement_all {
                        properties {
                          FullName
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          policy_PrimaryBPID {
            id
            views {
              SalesforceManagement_all {
                properties {
                  Code
                  PersonID {
                    views {
                      SalesforceManagement_all {
                        properties {
                          FullName
                        }
                      }
                    }
                  }
                  RelatedCompany {
                    Title
                  }
                }
              }
            }
          }

          production_Amendment_createdBy
          production_Amendment_createdOn
          production_Amendment_modifiedBy
          production_Amendment_modifiedOn

          production_Amendment_AmendmentEffectiveDate
          production_Amendment_AmendedExpiryDate

          amendmentPolicyPlanCoverVariance_PlanCoverID {
            id
          }
          amendmentPolicyPlanCoverVariance_AmendmentCoverSumInsured
          amendmentPolicyPlanCoverVariance_AmendmentCoverPremiumPercentage
          amendmentPolicyPlanCoverVariance_AmendmentCoverPremiumValue
          amendmentPolicyPlanCoverVariance_AmendmentExcessOnClaimAmount
          amendmentPolicyPlanCoverVariance_AmendmentExcessOnClaimPercentage
          amendmentPolicyPlanCoverVariance_AmendmentExcessOnClaimDays

          amendmentPricingOptionVariance_AmendmentCost
          amendmentPricingOptionVariance_AmendmentCostAmount
          amendmentPricingOptionVariance_AmendmentChargesPercentage
          amendmentPricingOptionVariance_AmendmentTPAFeesType
          amendmentPricingOptionVariance_AmendmentTPAFeesPercentage
          amendmentPricingOptionVariance_AmendmentTPAFeesAmount
        }
      }
    }
  }
`;

export const getPricingOptionsQuery = gql`
  query getCurrentPricingOption(
    $selectedPolicyID: String!
    $version: Long
    $selectedAmendmentID: String!
  ) {
    Production {
      queries {
        current: getCurrentPricingOption(
          selectedPolicyID: $selectedPolicyID
          state: "Latest"
        ) {
          production_PricingOption_BasicPremium
          production_PricingOption_NetPremium
          production_PricingOption_PolicyCostAmount
          production_PricingOption_ChargesAmount
          production_PricingOption_TPAFeesTotalAmount
          production_PricingOption_GrossPremium
          production_PricingOption_FixedStampAmount
          production_PricingOption_ProportionalStampAmount
          production_PricingOption_MunicipalityTaxAmount
          production_PricingOption_TotalPremium
          production_PricingOption_PricingOptionCurrency {
            Symbol
          }
        }
        new: getCurrentPricingOption(
          selectedPolicyID: $selectedPolicyID
          version: $version
        ) {
          production_PricingOption_BasicPremium
          production_PricingOption_NetPremium
          production_PricingOption_PolicyCostAmount
          production_PricingOption_ChargesAmount
          production_PricingOption_TPAFeesTotalAmount
          production_PricingOption_GrossPremium
          production_PricingOption_FixedStampAmount
          production_PricingOption_ProportionalStampAmount
          production_PricingOption_MunicipalityTaxAmount
          production_PricingOption_TotalPremium
          production_PricingOption_PricingOptionCurrency {
            Title
          }
        }
        variance: getAmendmentDetails(
          selectedAmendmentID: $selectedAmendmentID
        ) {
          amendmentPricingOptionVariance_AmendmentBasicPremium
          amendmentPricingOptionVariance_AmendmentNetPremium
          amendmentPricingOptionVariance_AmendmentChargesAmount
          amendmentPricingOptionVariance_AmendmentGrossPremium
          amendmentPricingOptionVariance_AmendmentFixedStampAmount
          amendmentPricingOptionVariance_AmendmentProportionalStampAmount
          amendmentPricingOptionVariance_AmendmentMunicipalityTax
          amendmentPricingOptionVariance_AmendmentTotalPremium
          amendmentPricingOptionVariance_PricingOptionCurrency {
            Title
          }
        }
      }
    }
  }
`;

export const getMotorDetailsQuery = gql`
  query getMotorDetailsQuery($selectedAmendmentID: String!, $version: Long) {
    Production {
      queries {
        getAmendmentDetails(
          selectedAmendmentID: $selectedAmendmentID
          version: $version
        ) {
          policyVersion_MotorDetailsID {
            views {
              Production_all {
                properties {
                  Id
                  VignetteCode
                  Vignette
                  AgencyRepairYear
                  NameOnLicenseEn
                  NameOnLicenseAr
                  DepreciationYears
                  VehicleID {
                    views {
                      Production_all {
                        properties {
                          PlateCode {
                            Id
                            Code
                            Title
                          }
                          PlateNumber
                          UsageType {
                            Id
                            Title
                          }
                          BodyType {
                            Id
                            Title
                          }
                          EngineType {
                            Id
                            Title
                          }
                          Make {
                            Id
                            Title
                          }
                          Model {
                            Id
                            Title
                          }
                          Color {
                            Id
                            Title
                          }
                          YearOfMake
                          Engine
                          Weight
                          EngineSize
                          SeatingCapacity
                          Chassis
                          HorsePower
                          CarValue
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getExpatDetailsQuery = gql`
  query getExpatDetailsQuery($selectedAmendmentID: String!, $version: Long) {
    Production {
      queries {
        getAmendmentDetails(
          selectedAmendmentID: $selectedAmendmentID
          version: $version
        ) {
          # Expat Details
          policyExpat_Id
          policyExpat_Exclusion
          policyExpat_Remarks
          policyExpat_MedicalClass {
            Id
            Code
            Title
          }
          policyExpat_Emergency
          policyExpat_Continuity
          policyExpat_ContinuityNumber
        }
      }
    }
  }
`;

export const getMedicalDetailsQuery = gql`
  query getMedicalDetailsQuery($selectedPolicyID: String!, $version: Long) {
    Production {
      queries {
        GetPolicyMedical(
          selectedPolicyID: $selectedPolicyID
          version: $version
        ) {
          production_PolicyMedical_Id
          production_PolicyMedical_Class {
            Id
            Code
            Title
          }
          production_PolicyMedical_Level {
            Id
            Code
            Title
          }
          production_PolicyMedical_Nssf
          production_PolicyMedical_CoversIDText
        }
      }
    }
  }
`;

export const getTravelDetailsQuery = gql`
  query getTravelDetailsQuery($selectedPolicyID: String!, $version: Long) {
    Production {
      queries {
        getPolicyTravelDetails(
          selectedPolicyID: $selectedPolicyID
          version: $version
        ) {
          production_PolicyTravel_Id
          production_PolicyTravel_Destination {
            Id
            Code
            Title
          }
          production_PolicyTravel_Duration
          production_PolicyTravel_PolicyCoverIDText
          production_PolicyTravel_PolicyCoverID {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  Name
                  ExternalCode
                }
              }
            }
          }
        }
      }
    }
    Insurance_InsuredRelations: __type(name: "Insurance_InsuredRelations") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const getBBBDetailsQuery = gql`
  query getBBBDetailsQuery($selectedPolicyID: String!, $version: Long) {
    Production {
      queries {
        getPolicyBankersBlanketBondDetails(
          selectedPolicyID: $selectedPolicyID
          version: $version
        ) {
          production_PolicyBBBond_Id
          production_PolicyBBBond_BranchName
          production_PolicyBBBond_IsMain
          production_PolicyBBBond_IsAgency
          production_PolicyBBBond_NumberOfEmployees
          production_PolicyBBBond_UnderwritingYear
          production_PolicyBBBond_Infidelity
          production_PolicyBBBond_Burglary
          production_PolicyBBBond_HoldUp
          production_PolicyBBBond_InSafe
          production_PolicyBBBond_InTransit
          production_PolicyBBBond_ForgedCheckes
          production_PolicyBBBond_CounterFeitCurrencies
          production_PolicyBBBond_Clause6
          production_PolicyBBBond_Falsification
        }
      }
    }
  }
`;

export const getMarineHullDetailsQuery = gql`
  query getMarineHullDetailsQuery(
    $selectedAmendmentID: String!
    $version: Long
  ) {
    Production {
      queries {
        getAmendmentDetails(
          selectedAmendmentID: $selectedAmendmentID
          version: $version
        ) {
          # Marine Hull Details
          policyMaineHull_VesselName
          policyMaineHull_ConnectionPort
          policyMaineHull_TerritorialWaters
          policyMaineHull_Usage
          policyMaineHull_Builder
          policyMaineHull_ConstructionYear
          policyMaineHull_RegisteryPort
          policyMaineHull_Length
          policyMaineHull_Weight
          policyMaineHull_Height
          policyMaineHull_EngineType
          policyMaineHull_EngineNumber
          policyMaineHull_EngineYearBuilt
          policyMaineHull_EngineHorsePower
          policyMaineHull_EngineMaxDesignedSpeed
          policyMaineHull_EngineFuel
          policyMaineHull_Remarks
        }
      }
    }
  }
`;

export const getMarineDetailsQuery = gql`
  query getMarineDetailsQuery($selectedAmendmentID: String!, $version: Long) {
    Production {
      queries {
        getAmendmentDetails(
          selectedAmendmentID: $selectedAmendmentID
          version: $version
        ) {
          # Marine Details
          policyMarine_Id
          policyMarine_Matter {
            Id
            Title
            Code
          }
          policyMarine_Description
          policyMarine_VesselName
          policyMarine_VesselAge
          policyMarine_DepartureFrom
          policyMarine_DestinationTo
          policyMarine_TransportationVia
          policyMarine_Survey
          policyMarine_CargoContract
          policyMarine_LCNumber
          policyMarine_LCIssueDate
          policyMarine_Bank
          policyMarine_ClaimsPayableBy
          policyMarine_Loading
          policyMarine_Remarks
          policyMarine_SumPerShipment
        }
      }
    }
    Core {
      lookups {
        matters {
          Id
          Code
          Title
        }
      }
    }
  }
`;

export const getPropertyRiskDetailsQuery = gql`
  query getPropertyRiskDetails($selectedProposalID: String!, $version: Long) {
    Production {
      queries {
        getPolicyPropertyDetails(
          selectedProposalID: $selectedProposalID
          version: $version
        ) {
          production_PolicyProperty_Id
          production_PolicyProperty_Order
          production_PolicyProperty_Id
          production_PolicyProperty_PlanCoverPropertyDetails {
            RiskDescription
            Location
            GeoLocation {
              Id
              Code
              Title
            }
            PlanCoverID
            RiskRate
            SumInsured
            Premium
          }
        }
      }
    }
    Core {
      lookups {
        geoLocations {
          Id
          Code
          Title
        }
      }
    }
  }
`;

export const getCoversPersonsQuery = (
  mode: 'covers' | 'persons' | 'both' = 'both'
) => {
  let proposalPart = '';
  let coversPart = '';
  let personsPart = '';

  if (mode === 'covers' || mode === 'both') {
    proposalPart = `
      entities {
        proposal(id: $id) {
          id
          views {
            Production_all {
              properties {
                PolicyEffectiveDate
                PolicyExpiryDate
              }
            }
          }
        }
      }
    `;
  }

  if (mode === 'covers' || mode === 'both') {
    coversPart = `
      getPolicyPlanCovers(selectedProposalID: $id, version: $version) {
        production_PolicyPlanCover_ProposalID {
          id
          views {
            Production_all {
              properties {
                PolicyCurrency {
                  Code
                  Title
                }
              }
            }
          }
        }
        policyCover_Id
        policyCover_Name
        production_PolicyPlanCover_Id
        production_PolicyPlanCover_CoverEffectiveFrom
        production_PolicyPlanCover_CoverEffectiveTo
        production_PolicyPlanCover_CoverPremiumPercentage
        production_PolicyPlanCover_CoverPremiumValue
        production_PolicyPlanCover_ExcessOnClaimType
        production_PolicyPlanCover_ExcessOnClaimAmount
        production_PolicyPlanCover_ExcessOnClaimPercentage
        production_PolicyPlanCover_ExcessOnClaimDays
        production_PolicyPlanCover_ClauseID {
          id
        }
        production_PolicyPlanCover_CoverPremium
        production_PolicyPlanCover_TotalExcessOnClaim
        production_PolicyPlanCover_CoverSumInsuredBasic
        production_PolicyPlanCover_CoverPremiumBasic
        production_PolicyPlanCover_CoverSumInsuredArabic
        production_PolicyPlanCover_CoverPremiumArabic
        planCover_Id
        planCover_PolicyCoverID {
          id
        }
        production_PolicyPlanCover_IsMain
        production_PolicyPlanCover_IsEditable
        production_PolicyPlanCover_CoverSumInsured
        production_PolicyPlanCover_SumInsuredIsAdditive
        production_PolicyPlanCover_IsMandatory
        production_PolicyPlanCover_CoverPremiumType
      }
    `;
  }

  if (mode === 'persons' || mode === 'both') {
    personsPart = `
      GetPolicyPerson(proposalID: $id, version: $version) {
        production_PolicyPerson_Id
        production_PolicyPerson_PersonID {
          id
        }
        production_PolicyPerson_Percentage
        production_PolicyPerson_PersonCode
        production_PolicyPerson_FullName
        production_PolicyPerson_Type
        production_PolicyPerson_OrderNumber
        production_PolicyPerson_OwnerIsDifferent
        production_PolicyPerson_PersonAddressID {
          id
          views {
            SalesforceManagement_all {
              properties {
                IsPrimary
                Address {
                  Country {
                    Id
                    Title
                  }
                  City
                  Street
                  Building
                }
              }
            }
          }
        }
        production_PolicyPerson_Address {
          Building
          Street
          City
          Country {
            Id
            Title
          }
          AddressDetails
        }
        production_PolicyPerson_OrderNumber

        production_PolicyPerson_GRLimitDays
        production_PolicyPerson_CardNumber
        production_PolicyPerson_Continuity
        production_PolicyPerson_TPAReference1
        production_PolicyPerson_TPAReference2
        production_PolicyPerson_Exclusions
        production_PolicyPerson_Remarks
        production_PolicyPerson_IsPrincipal
        production_PolicyPerson_Relation
      }
    `;
  }

  return gql`
    query getCoversPersonsQuery($id: String!, $version: Long) {
      Production {
        ${proposalPart}
        queries {
          ${coversPart}
          ${personsPart}
        }
      }
    }
  `;
};

export const getMotorLovsQuery = gql`
  query getLovs {
    Core {
      lookups {
        colors {
          Id
          Code
          Title
        }
      }
    }
    Insurance {
      lookups {
        plateCodes {
          Id
          Title
        }
        usageTypes {
          Id
          Title
        }
        bodyTypes {
          Id
          Title
        }
        engineTypes {
          Id
          Title
        }
        brands {
          Id
          Title
        }
      }
    }
  }
`;

export const getExpatLovsQuery = gql`
  query getLovs {
    Core {
      lookups {
        medicalClasses {
          Id
          Code
          Title
        }
      }
    }
    Production_ContinuityOptions: __type(name: "Production_ContinuityOptions") {
      name
      enumValues {
        name
      }
    }
    PlanConfigManagement_EmergencyOptions: __type(
      name: "PlanConfigManagement_EmergencyOptions"
    ) {
      name
      enumValues {
        name
      }
    }
  }
`;

export const getMedicalPrincipalLovsQuery = gql`
  query getLovs {
    Production_ContinuityOptions: __type(name: "Production_ContinuityOptions") {
      name
      enumValues {
        name
      }
    }
    Insurance_InsuredRelations: __type(name: "Insurance_InsuredRelations") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const getMedicalLovsQuery = gql`
  query getLovs($selectedPlanID: String!) {
    Core_CoNSSFOptions: __type(name: "Core_CoNSSFOptions") {
      name
      enumValues {
        name
      }
    }
    Core {
      lookups {
        medicalClasses {
          Id
          Code
          Title
        }
        medicalLevels {
          Id
          Code
          Title
        }
      }
    }
    PlanConfigManagement {
      queries {
        getPlanSpecificMedicalCovers(selectedPlanId: $selectedPlanID) {
          planConfigManagement_PlanSpecificMedical_Id
          planConfigManagement_PlanSpecificMedical_PolicyCoverID {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  ExternalCode
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getTravelLovsQuery = gql`
  query getLovs($selectedPlanID: String!) {
    Core {
      lookups {
        travelDestinations {
          Id
          Code
          Title
        }
      }
    }
    PlanConfigManagement {
      queries {
        getPlanSpecificTravelCovers(selectedPlanId: $selectedPlanID) {
          planConfigManagement_PlanSpecificTravel_Id
          planConfigManagement_PlanSpecificTravel_PolicyCoverID {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  ExternalCode
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getUsersFullNameQuery = gql`
  query getUsersFullName($createdById: String!, $modifiedById: String!) {
    System {
      entities {
        createdBy: user(id: $createdById) {
          views {
            System_all {
              properties {
                firstName
                lastName
                Id
              }
            }
          }
        }
        modifiedBy: user(id: $modifiedById) {
          views {
            System_all {
              properties {
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;
