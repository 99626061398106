import { gql } from '@apollo/client';

export const calculateAmendmentMotor = gql`
  mutation calculateAmendmentMotor(
    $policyID: String!
    $amendmentID: String!
    $policyVersionID: String!
    $amendmentEffectiveDate: Date!
    $planID: String!
    $amendmentExpiryDate: Date!
    $amendmentChargesPercentage: Decimal!
    $amendmentCost: Decimal!
    $amendmentTPAFeesAmount: Decimal!
    $nameOnLicenseEn: String
    $nameOnLicenseAr: String
    $vignette: Long!
    $vignetteCode: String
    $plateCode: String
    $plateNumber: String
    $usageType: String
    $bodyType: String
    $engineType: String
    $make: String
    $model: String
    $yearOfMake: Long
    $agencyRepairYear: Long
    $depreciationYears: Long
    $weight: Decimal
    $engine: String
    $engineSize: Decimal
    $seatingCapacity: Long
    $chassis: String
    $horsePower: Decimal
    $carValue: Decimal
    $color: String
    $policyPersonInput: [Production_policyPersonInputs]
    $policyPlanCoverInput: [Production_policyPlanCoverInputs]
  ) {
    production {
      actions {
        calculateAmendmentMotor(
          policyID: $policyID
          amendmentID: $amendmentID
          policyVersionID: $policyVersionID
          amendmentEffectiveDate: $amendmentEffectiveDate
          planID: $planID
          amendmentExpiryDate: $amendmentExpiryDate
          amendmentChargesPercentage: $amendmentChargesPercentage
          amendmentCost: $amendmentCost
          amendmentTPAFeesAmount: $amendmentTPAFeesAmount
          nameOnLicenseEn: $nameOnLicenseEn
          nameOnLicenseAr: $nameOnLicenseAr
          vignette: $vignette
          vignetteCode: $vignetteCode
          plateCode: $plateCode
          plateNumber: $plateNumber
          usageType: $usageType
          bodyType: $bodyType
          engineType: $engineType
          make: $make
          model: $model
          yearOfMake: $yearOfMake
          agencyRepairYear: $agencyRepairYear
          depreciationYears: $depreciationYears
          weight: $weight
          engine: $engine
          engineSize: $engineSize
          seatingCapacity: $seatingCapacity
          chassis: $chassis
          horsePower: $horsePower
          carValue: $carValue
          color: $color
          policyPersonInput: $policyPersonInput
          policyPlanCoverInput: $policyPlanCoverInput
        )
      }
    }
  }
`;

export const calculateAmendmentExpat = gql`
  mutation calculateAmendmentLine {
    production {
      actions {
        calculateAmendmentMotor(
          policyID: ""
          policyVersionID: ""
          amendmentID: ""
          planID: ""
          amendmentEffectiveDate: ""
          amendmentExpiryDate: ""
          amendmentChargesPercentage: ""
          amendmentCost: ""
          amendmentTPAFeesAmount: ""
        )
      }
    }
  }
`;

export const calculateAmendmentGeneric = gql`
  mutation calculateAmendmentLine {
    production {
      actions {
        calculateAmendmentMotor(
          policyID: ""
          policyVersionID: ""
          amendmentID: ""
          planID: ""
          amendmentEffectiveDate: ""
          amendmentExpiryDate: ""
          amendmentChargesPercentage: ""
          amendmentCost: ""
          amendmentTPAFeesAmount: ""
        )
      }
    }
  }
`;

export const calculateAmendmentMedical = gql`
  mutation calculateAmendmentLine {
    production {
      actions {
        calculateAmendmentMotor(
          policyID: ""
          policyVersionID: ""
          amendmentID: ""
          planID: ""
          amendmentEffectiveDate: ""
          amendmentExpiryDate: ""
          amendmentChargesPercentage: ""
          amendmentCost: ""
          amendmentTPAFeesAmount: ""
        )
      }
    }
  }
`;

export const calculateAmendmentTravel = gql`
  mutation calculateAmendmentLine {
    production {
      actions {
        calculateAmendmentMotor(
          policyID: ""
          policyVersionID: ""
          amendmentID: ""
          planID: ""
          amendmentEffectiveDate: ""
          amendmentExpiryDate: ""
          amendmentChargesPercentage: ""
          amendmentCost: ""
          amendmentTPAFeesAmount: ""
        )
      }
    }
  }
`;

export const cancelAmendmentMutation = gql`
  mutation cancelAmendment(
    $selectedAmendmentID: String!
    $selectedPolicyVersionID: String!
  ) {
    production {
      actions {
        cancelAmendment(
          selectedAmendmentID: $selectedAmendmentID
          selectedPolicyVersionID: $selectedPolicyVersionID
        )
      }
    }
  }
`;
