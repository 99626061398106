import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { IInsuredData } from '../..';
import NewChipsInput from '../../../../../components/enhanced-form/NewChipsInput';
import TextInputFormField from '../../../../../components/form-fields/TextInputFormField';
import { getDefaultPersonListIfEmpty, getInsuredLov } from './utils';
import { IInsuredLists } from './TravelPrincipalInsured';
import { ITravelAmendmentLovs } from '../../line-amendment/TravelAmendment';

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    backgroundColor: '#F9F9F9',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    padding: '33px 23px 23px',
    marginBottom: '14px',
    position: 'relative',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(2, 49%)`,
    gap: '8px',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  label: {
    color: '#000000',
    margin: '0px!important',
    padding: '0px!important',
    marginBottom: '4px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  labelHeader: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
    marginBottom: '4px!important',
  },
  fullFieldRow: {
    gridColumn: '1 / -1',
    width: '100%',
  },
}));

interface InsuredRowProps {
  row: IInsuredData;
  rowIndex: number;
  insuredValues: IInsuredData[];
  insuredTouched: Record<string, boolean>[];
  insuredErrors: Record<string, string>[];
  onFieldUpdate: (
    field: string,
    value: string | string[],
    index: number
  ) => void;
  onFieldSearch: (field: string, value: string, index: number) => void;
  onFieldBlur: (field: string, index: number) => void;
  isDisabled: boolean;
  lists: IInsuredLists;
  lovs: ITravelAmendmentLovs;
  handleOpenDrawer: (type: string, index: number) => void;
  handleOpenAddressDrawer: (type: string, index: number) => void;
}

const InsuredRow: React.FC<InsuredRowProps> = ({
  row,
  rowIndex,
  insuredValues,
  insuredTouched,
  insuredErrors,
  onFieldUpdate,
  onFieldSearch,
  onFieldBlur,
  isDisabled,
  lists,
  lovs,
  handleOpenDrawer,
  handleOpenAddressDrawer,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container} key={rowIndex}>
      <div className={classes.fieldRow}>
        <NewChipsInput
          key="id"
          name="id"
          title="Insured*"
          placeholder="Insured"
          value={insuredValues[rowIndex].id}
          error={insuredTouched[rowIndex].id ? insuredErrors[rowIndex].id : ''}
          onChange={(v) => {
            const value = Array.isArray(v) ? v[0] : v;
            onFieldUpdate('id', value, rowIndex);
          }}
          onSearch={async (v) => {
            const value = Array.isArray(v) ? v[0] : v;
            onFieldSearch('id', value, rowIndex);
          }}
          disabled={isDisabled}
          items={getDefaultPersonListIfEmpty(
            lists?.id[rowIndex],
            insuredValues[rowIndex]
          )}
          required
          material
          supportLink={isDisabled ? '' : 'Add Person'}
          onLinkClick={(e) => {
            e.preventDefault();
            handleOpenDrawer('insured', rowIndex);
          }}
          customStyles={{
            labelStyles: classes.label,
          }}
        />
        {row.isPrincipal ? (
          <NewChipsInput
            key="address"
            name="address"
            title="Insured Address*"
            placeholder="Insured Address"
            value={insuredValues[rowIndex].address}
            error={
              insuredTouched[rowIndex].address
                ? insuredErrors[rowIndex].address
                : ''
            }
            onChange={(v) => onFieldUpdate('address', v, rowIndex)}
            disabled={isDisabled}
            items={lists.insuredAddresses[rowIndex]}
            required
            material
            supportLink={isDisabled ? '' : 'Add Address'}
            onLinkClick={(e) => {
              e.preventDefault();
              handleOpenAddressDrawer('insured', rowIndex);
            }}
            customStyles={{
              labelStyles: classes.label,
            }}
          />
        ) : (
          <NewChipsInput
            key="relation"
            name="relation"
            title="Relation*"
            placeholder="Relation"
            value={insuredValues[rowIndex].relation}
            error={
              insuredTouched[rowIndex].relation
                ? insuredErrors[rowIndex].relation
                : ''
            }
            onChange={(v) => onFieldUpdate('relation', v, rowIndex)}
            disabled={isDisabled}
            items={getInsuredLov(row, lovs, insuredValues)}
            required
            material
            customStyles={{
              labelStyles: classes.label,
            }}
          />
        )}
        <TextInputFormField
          key="cardNumber"
          name="cardNumber"
          title="Card Number"
          placeholder="Card Number"
          value={insuredValues[rowIndex].cardNumber}
          error={
            insuredTouched[rowIndex].cardNumber
              ? insuredErrors[rowIndex].cardNumber
              : ''
          }
          onBlur={() => onFieldBlur('cardNumber', rowIndex)}
          onChange={(v) =>
            onFieldUpdate('cardNumber', v.target.value, rowIndex)
          }
          disabled={isDisabled}
          material
        />

        <TextInputFormField
          key="exclusions"
          name="exclusions"
          title="Exclusions"
          placeholder="Exclusions"
          value={insuredValues[rowIndex].exclusions}
          error={
            insuredTouched[rowIndex].exclusions
              ? insuredErrors[rowIndex].exclusions
              : ''
          }
          onBlur={() => onFieldBlur('exclusions', rowIndex)}
          onChange={(v) =>
            onFieldUpdate('exclusions', v.target.value, rowIndex)
          }
          disabled={isDisabled}
          className={classes.fullFieldRow}
          material
        />

        <TextInputFormField
          key="remarks"
          name="remarks"
          title="Remarks"
          placeholder="Remarks"
          value={insuredValues[rowIndex].remarks}
          error={
            insuredTouched[rowIndex].remarks
              ? insuredErrors[rowIndex].remarks
              : ''
          }
          onBlur={() => onFieldBlur('remarks', rowIndex)}
          onChange={(v) => onFieldUpdate('remarks', v.target.value, rowIndex)}
          disabled={isDisabled}
          className={classes.fullFieldRow}
          material
        />
      </div>
    </div>
  );
};

export default InsuredRow;
