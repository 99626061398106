import React, { useEffect, useState } from 'react';
import { ITabsLayout } from '..';
import {
  COLLAPSED_COLUMN_WIDTH,
  ColumnDirection,
  IColumn,
  initialColumnData,
} from '../three-column-layout';
import FixedColumn from '../three-column-layout/FixedColumn';
import MainColumn from '../three-column-layout/MainColumn';
import { ITheme } from '../../redux/tenant/types';
import { GridWidth } from '../../models/grid';
import TabsWidgets from '../../components/widgets/tabs-widgets/TabsWidgets';
import { IUserInfo } from '../../redux/user/types';

interface IThreeColumnLayoutProps {
  // Name is essential for the sake of reseting the page layout
  name: string;
  layout: ITabsLayout;
  theme: ITheme;
  leftChildren?: JSX.Element;
  rightChildren?: JSX.Element;
  mainChildren?: JSX.Element;
  cdnUrl: string;
  userInfo: IUserInfo;
  tabPanelClassName?: string;
  firstTabAsActiveTab?: boolean;
}

const TabsLayout: React.FC<IThreeColumnLayoutProps> = ({
  name,
  layout,
  theme,
  leftChildren,
  rightChildren,
  mainChildren,
  cdnUrl,
  userInfo,
  tabPanelClassName,
  firstTabAsActiveTab,
}) => {
  const [leftColumn, setLeftColumn] = useState<IColumn>(
    layout.leftColumn || initialColumnData
  );
  const [rightColumn, setRightColumn] = useState<IColumn>(
    layout.rightColumn || initialColumnData
  );
  const [mainWidth, setMainWidth] = useState<GridWidth>(
    layout.mainColumn
      ? (layout.mainColumn.width as GridWidth)
      : (7 as GridWidth)
  );
  const visibleTabs = layout?.tabs
    ? layout.tabs.filter((tab) => !tab.hidden)
    : [];

  useEffect(() => {
    if (layout.leftColumn) {
      setLeftColumn(layout.leftColumn);
    }
  }, [name, layout.leftColumn]);

  useEffect(() => {
    if (layout.rightColumn) {
      setRightColumn(layout.rightColumn);
    }
  }, [name, layout.rightColumn]);

  useEffect(() => {
    let curMainWidth = layout.mainColumn ? layout.mainColumn.width : 7;
    if (
      (leftColumn.collapsed && leftColumn.width > 0) ||
      leftColumn.collapsable
    ) {
      curMainWidth += leftColumn.width - COLLAPSED_COLUMN_WIDTH;
    }
    if (
      (rightColumn.collapsed && rightColumn.width > 0) ||
      rightColumn.collapsable
    ) {
      curMainWidth += rightColumn.width - COLLAPSED_COLUMN_WIDTH;
    }
    setMainWidth(curMainWidth as GridWidth);
  }, [
    leftColumn.width,
    rightColumn.width,
    leftColumn.collapsable,
    rightColumn.collapsable,
    leftColumn.collapsed,
    rightColumn.collapsed,
    layout.mainColumn,
  ]);

  const toggleColumn = (side: ColumnDirection) => {
    if (side === ColumnDirection.left) {
      setLeftColumn({
        ...leftColumn,
        collapsed: !leftColumn.collapsed,
      });
    } else {
      setRightColumn({
        ...rightColumn,
        collapsed: !rightColumn.collapsed,
      });
    }
  };

  return (
    <>
      <FixedColumn
        background={leftColumn.backgroundColor}
        collapsible={leftColumn.collapsable}
        collapsed={!!leftColumn.collapsed}
        direction={ColumnDirection.left}
        width={leftColumn.width as GridWidth}
        onToggle={toggleColumn}
      >
        {leftChildren}
      </FixedColumn>
      <MainColumn
        background={
          layout.mainColumn
            ? layout.mainColumn.backgroundColor
            : theme.palette.secondary4.main
        }
        width={mainWidth}
        leftMargin={
          (leftColumn.width > 0 && leftColumn.collapsed) ||
          leftColumn.collapsable
            ? (COLLAPSED_COLUMN_WIDTH as GridWidth)
            : (leftColumn.width as GridWidth)
        }
        rightMargin={
          (rightColumn.width > 0 && rightColumn.collapsed) ||
          rightColumn.collapsable
            ? (COLLAPSED_COLUMN_WIDTH as GridWidth)
            : (rightColumn.width as GridWidth)
        }
      >
        {visibleTabs.length > 0 ? (
          <>
            {mainChildren}
            <TabsWidgets
              tabs={visibleTabs}
              userInfo={userInfo}
              mainPageName={name}
              cdnUrl={cdnUrl}
              theme={theme}
              tabPanelClassName={tabPanelClassName}
              firstTabAsActiveTab={firstTabAsActiveTab}
            />
          </>
        ) : (
          mainChildren
        )}
      </MainColumn>
      <FixedColumn
        background={rightColumn.backgroundColor}
        collapsible={rightColumn.collapsable}
        collapsed={!!rightColumn.collapsed}
        direction={ColumnDirection.right}
        width={rightColumn.width as GridWidth}
        onToggle={toggleColumn}
      >
        {rightChildren}
      </FixedColumn>
    </>
  );
};

export default TabsLayout;
