import { IBeneficiaryData } from '../..';
import { isEmpty } from '../../../../../utils/validationUtils';

export const validatePolicyInsuredField = (
  fieldName: string,
  value: any
): string => {
  if (isEmpty(value)) {
    return 'Required';
  }

  if (fieldName === 'id') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'order') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'address') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validatePolicyPayerField = (
  fieldName: string,
  value: any
): string => {
  if (isEmpty(value)) {
    return 'Required';
  }

  if (fieldName === 'payerId') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'payerAddress') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validatePolicyOwnerField = (
  fieldName: string,
  value: any
): string => {
  if (isEmpty(value)) {
    return 'Required';
  }

  if (fieldName === 'ownerId') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'ownerAddress') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validatePolicyBeneficiaryField = (
  fieldName: keyof IBeneficiaryData,
  value: unknown
): string => {
  if (isEmpty(value)) {
    return 'Required';
  }
  return '';
};
