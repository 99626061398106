import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import WidgetPaper from '../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../components/common/WidgetSection';
import SelectFormField from '../../../../components/form-fields/SelectFormField';
import TextInputFormField from '../../../../components/form-fields/TextInputFormField';
import { IAmendmentExpatDetails, IAmendmentPageState } from '..';
import { validateExpatDetailsField } from './validation';
import { ILineAmendmentProps } from '../line-amendment';
import { useLazyQuery } from '@apollo/client';
import { getExpatLovsQuery } from '../queries';
import Loader from '../../../../components/Loader';
import { getExpatLovs } from '../utils';

interface IExpatDetailsWidgetProps {
  generalData: ILineAmendmentProps['generalData'];
  pageState: IAmendmentPageState;
  onPageStateUpdate: (pageState: IAmendmentPageState) => void;
  isDisabled: boolean;
}

const useStyles = makeStyles()(() => ({
  fullFieldRow: {
    gridColumn: '1 / -1', // Span across all columns
    width: '100%', // Ensure it takes the full width available
  },
}));

interface IExpatLovs {
  medicalClasses: Record<string, string>;
  emergencyOptions: Record<string, string>;
  continuityOptions: Record<string, string>;
}

const ExpatDetailsWidget: React.FC<IExpatDetailsWidgetProps> = ({
  pageState,
  onPageStateUpdate,
  isDisabled,
}) => {
  const { classes } = useStyles();

  const [getExpatLovsLazy, { loading }] = useLazyQuery(getExpatLovsQuery);

  const values = pageState.values.expatDetails;
  const errors = pageState.errors.expatDetails;
  const touched = pageState.touched.expatDetails;

  const [lovs, setLovs] = useState<IExpatLovs>({
    continuityOptions: {},
    emergencyOptions: {},
    medicalClasses: {},
  });

  const initialize = async () => {
    const { data: lovsData } = await getExpatLovsLazy();

    const lists = getExpatLovs(lovsData);
    setLovs({
      medicalClasses: lists.medicalClasses,
      emergencyOptions: lists.emergencyOptions,
      continuityOptions: lists.continuityOptions,
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  const onFieldBlur = async (fieldName: string) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.expatDetails[fieldName] = true;

    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IAmendmentExpatDetails,
    value: unknown,
    touched = false
  ) => {
    const newPageState = _.cloneDeep(pageState);

    (newPageState.values.expatDetails as any)[fieldName] = value;

    newPageState.errors.expatDetails[fieldName] = validateExpatDetailsField(
      fieldName,
      value
    );

    if (touched) {
      newPageState.touched.costs[fieldName] = true;
    }

    onPageStateUpdate(newPageState);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <WidgetPaper>
      <WidgetSection title="Expat Details" isFieldsGrid fieldsPerRow={3}>
        <SelectFormField
          key="medicalClass"
          name="medicalClass"
          title="Medical Class*"
          placeholder="Medical Class"
          value={values.medicalClass}
          error={touched.medicalClass ? errors.medicalClass : ''}
          onChange={(v) => onFieldUpdate('medicalClass', v, true)}
          disabled={isDisabled}
          selectOptions={lovs.medicalClasses}
          material
        />
        <SelectFormField
          key="continuity"
          name="continuity"
          title="Continuity"
          placeholder="Continuity"
          value={values.continuity}
          error={touched.usageType ? errors.continuity : ''}
          onChange={(v) => onFieldUpdate('continuity', v, true)}
          disabled={isDisabled}
          selectOptions={lovs.continuityOptions}
          material
        />

        <TextInputFormField
          key="continuityNumber"
          name="continuityNumber"
          title="Continuity Number"
          placeholder="Continuity Number"
          value={values.continuityNumber}
          error={touched.continuityNumber ? errors.continuityNumber : ''}
          onBlur={() => onFieldBlur('continuityNumber')}
          onChange={(v) => onFieldUpdate('continuityNumber', v.target.value)}
          disabled={isDisabled}
          material
        />

        <TextInputFormField
          key="exclusion"
          name="exclusion"
          title="Exclusion"
          placeholder="Exclusion"
          value={values.exclusion}
          error={touched.exclusion ? errors.exclusion : ''}
          onBlur={() => onFieldBlur('exclusion')}
          onChange={(v) => onFieldUpdate('exclusion', v.target.value)}
          disabled={isDisabled}
          classes={{
            labelWrapper: classes.fullFieldRow,
          }}
          material
        />

        <TextInputFormField
          key="remarks"
          name="remarks"
          title="Remarks"
          placeholder="Remarks"
          value={values.remarks}
          error={touched.remarks ? errors.remarks : ''}
          onBlur={() => onFieldBlur('remarks')}
          onChange={(v) => onFieldUpdate('remarks', v.target.value)}
          disabled={isDisabled}
          classes={{
            labelWrapper: classes.fullFieldRow,
          }}
          material
        />
      </WidgetSection>
    </WidgetPaper>
  );
};

export default ExpatDetailsWidget;
