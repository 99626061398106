import React, { useEffect, useMemo, useState } from 'react';
import ListingTable from '../../../../../components/form-fields/table/ListingTable';
import { IListingData } from '../../../../../models/listing';
import {
  ActionTarget,
  IEnhancedMenuItem,
} from '../../../../../components/form-fields/table';
import { treatyDistributionHeaders } from '../content';
import TreatyDistributionBreakdown from './TreatyDistributionBreakdown';
import { getTreatyDistListQuery } from '../queries';
import { useQuery } from '@apollo/client';
import { mapToTreatyDistTableData } from '../utils';

interface IBreakdownPopup {
  id: string;
  isOpen: boolean;
}

const TreatyDistributionWidget: React.FC<{
  currentPolicyId: string;
  currentPolicyName: string;
}> = ({ currentPolicyId, currentPolicyName }) => {
  const { data, loading } = useQuery(getTreatyDistListQuery, {
    variables: {
      PolicyID: currentPolicyId,
      PolicyPlanCoverID: currentPolicyId,
    },
    errorPolicy: 'all',
  });
  const [breakdownPopup, setBreakdownPopup] = useState<IBreakdownPopup>({
    id: '',
    isOpen: false,
  });

  const tableActions: IEnhancedMenuItem[] = useMemo(() => {
    return [
      {
        name: 'viewBreakdown',
        title: 'View Breakdown',
        isPrimary: true,
        disabled: !breakdownPopup.id,
        onClick: () => {
          setBreakdownPopup((prev) => ({ ...prev, isOpen: true }));
        },
        target: ActionTarget.Entity,
        rowIndependant: false,
      },
    ];
  }, [breakdownPopup.id]);

  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageNumber: 1,
    pageSize: 10,
    totalCount: 0,
  });

  useEffect(() => {
    if (data) {
      setTableData(
        mapToTreatyDistTableData(
          data?.Production?.queries?.GetTreatyDistributionPerPolicyCover
        )
      );
    }
  }, [data]);

  return (
    <>
      {breakdownPopup.isOpen && (
        <TreatyDistributionBreakdown
          policy={{
            id: currentPolicyId,
            name: currentPolicyName,
          }}
          cover={{
            id: breakdownPopup.id,
            name: tableData.pagedItems[breakdownPopup.id]?.coverName,
          }}
          isOpen={true}
          onClose={() =>
            setBreakdownPopup((prev) => ({ ...prev, isOpen: false }))
          }
        />
      )}
      <ListingTable
        name="treatyDistribution"
        inlineTitle="Treaty Distribution"
        headers={treatyDistributionHeaders}
        data={tableData}
        loader={loading}
        actions={tableActions}
        inline
        singleSelection
        onSelectionChange={(newSelection) => {
          setBreakdownPopup((prev) => ({
            ...prev,
            id: newSelection.length === 1 ? newSelection[0] : '',
          }));
        }}
      />
    </>
  );
};

export default TreatyDistributionWidget;
