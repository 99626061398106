import React from 'react';
import { makeStyles } from 'tss-react/mui';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ITreatyLovs } from '../..';
import { ITreatyDetails, ITreatyLayer, ITreatyLayerCategory } from '../..';
import SelectFormField from '../../../../../../components/form-fields/SelectFormField';
import { cloneDeep } from 'lodash';
import CurrencyFormField from '../../../../../../components/form-fields/CurrencyFormField';
import EnhancedPercentageInput from '../../../../../../components/enhanced-form/EnhancedPercentageInput';
import { IAbstractRecord } from '../../../../../../models';

interface ICategoryRepeater {
  treatyValues: ITreatyDetails;
  currentLayerValues: ITreatyLayer;
  currentLayerErrors: IAbstractRecord;
  lovs: ITreatyLovs;
  onChange: (tabValues: ITreatyLayerCategory[]) => void;
  updateErrors?: (layerErrors: any) => void;
  isDisabled?: boolean;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    backgroundColor: '#F9F9F9',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    padding: '33px 23px 23px',
    marginBottom: '14px',
    position: 'relative',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(3, 32%)`,
    gap: '0 2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  addBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    margin: '20px auto 0',
    display: 'block',
  },
  removeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    top: '8px',
  },
  clearBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const emptyValues: ITreatyLayerCategory = {
  category: '',
  minimumLayerLimit: '',
  maximumLayerLimit: '',
  retentionLimit: '',
  priorityLimit: '',
  liabilityLimit: '',
  aggregateLimit: '',
  commissionRate: '',
};

const CategoryRepeater: React.FC<ICategoryRepeater> = ({
  treatyValues,
  currentLayerValues,
  currentLayerErrors,
  lovs,
  onChange,
  updateErrors,
  isDisabled,
}) => {
  const { classes } = useStyles();

  const handleFieldChange = (
    value: any,
    fieldName: keyof ITreatyLayerCategory,
    index: number
  ) => {
    const newValues = cloneDeep(currentLayerValues) as ITreatyLayer;
    newValues.categories[index][fieldName] = value;
    onChange(newValues.categories);
  };

  const handleAddRow = () => {
    const newValues = cloneDeep(currentLayerValues) as ITreatyLayer;
    newValues.categories.push(emptyValues);

    const newErrors = cloneDeep(currentLayerErrors) as IAbstractRecord;
    newErrors.categories.push(emptyValues); // structure of errors is the same of values

    onChange(newValues.categories);
    updateErrors(newErrors);
  };

  const handleDeleteRow = (index: number) => {
    const newValues = cloneDeep(currentLayerValues) as ITreatyLayer;
    newValues.categories.splice(index, 1);

    const newErrors = cloneDeep(currentLayerErrors) as IAbstractRecord;
    newErrors.categories.splice(index, 1);

    onChange(newValues.categories);
    updateErrors(newErrors);
  };

  const validateLayerCategories = () => {
    const newErrors = cloneDeep(currentLayerErrors) as IAbstractRecord;
    const categories = currentLayerValues.categories.map((c) => c.category);
    const uniqueCategories = new Set(categories);
    if (categories.length !== uniqueCategories.size) {
      newErrors.categories.forEach((rowErrors: IAbstractRecord) => {
        rowErrors.category = 'Duplicate categories not allowed';
      });
    } else {
      newErrors.categories.forEach((rowErrors: IAbstractRecord) => {
        rowErrors.category = '';
      });
    }
    updateErrors(newErrors);
  };

  return (
    <section>
      {currentLayerValues.categories.map((category, index) => {
        const layerType = currentLayerValues.treatySubType;
        const currentCategoryRowErrors = currentLayerErrors?.categories[index];
        return (
          <div key={index} className={classes.container}>
            {!isDisabled && (
              <div className={classes.clearBtn}>
                {currentLayerValues.categories.length > 1 && (
                  <button
                    className={classes.removeBtn}
                    onClick={() => handleDeleteRow(index)}
                  >
                    <ClearIcon fontSize="small" />
                  </button>
                )}
              </div>
            )}
            <div className={classes.fieldRow}>
              <SelectFormField
                title="Category"
                name="category*"
                placeholder="Select Category"
                value={category.category}
                selectOptions={lovs.category}
                onChange={(v) => handleFieldChange(v, 'category', index)}
                error={currentCategoryRowErrors?.category}
                onBlur={validateLayerCategories}
                disabled={isDisabled}
              />
              {layerType === 'QuotaShare' && (
                <CurrencyFormField
                  name="minimumLayerLimit"
                  title="Minimum Layer Limit*"
                  placeholder="Enter Minimum Layer Limit"
                  value={category.minimumLayerLimit}
                  onChange={(e) =>
                    handleFieldChange(
                      e.target.value,
                      'minimumLayerLimit',
                      index
                    )
                  }
                  currencySymbol={lovs.currency[treatyValues.currency]}
                  error={currentCategoryRowErrors?.minimumLayerLimit}
                  disabled={isDisabled}
                />
              )}
              {['QuotaShare', 'Surplus', 'FacOblig'].includes(layerType) && (
                <CurrencyFormField
                  name="maximumLayerLimit"
                  title="Maximum Layer Limit*"
                  placeholder="Enter Maximum Layer Limit"
                  value={category.maximumLayerLimit}
                  onChange={(e) =>
                    handleFieldChange(
                      e.target.value,
                      'maximumLayerLimit',
                      index
                    )
                  }
                  currencySymbol={lovs.currency[treatyValues.currency]}
                  error={currentCategoryRowErrors?.maximumLayerLimit}
                  disabled={isDisabled}
                />
              )}
              {layerType === 'Surplus' && (
                <CurrencyFormField
                  name="retentionLimit"
                  title="Retention Limit*"
                  placeholder="Enter Retention Limit"
                  value={category.retentionLimit}
                  onChange={(e) =>
                    handleFieldChange(e.target.value, 'retentionLimit', index)
                  }
                  currencySymbol={lovs.currency[treatyValues.currency]}
                  error={currentCategoryRowErrors?.retentionLimit}
                  disabled={isDisabled}
                />
              )}
              {layerType === 'ExcessOfLoss' && (
                <>
                  <CurrencyFormField
                    name="priorityLimit"
                    title="Priority Limit*"
                    placeholder="Enter Priority Limit"
                    value={category.priorityLimit}
                    onChange={(e) =>
                      handleFieldChange(e.target.value, 'priorityLimit', index)
                    }
                    currencySymbol={lovs.currency[treatyValues.currency]}
                    error={currentCategoryRowErrors?.priorityLimit}
                    disabled={isDisabled}
                  />
                  <CurrencyFormField
                    name="liabilityLimit"
                    title="Liability Limit*"
                    placeholder="Enter Liability Limit"
                    value={category.liabilityLimit}
                    onChange={(e) =>
                      handleFieldChange(e.target.value, 'liabilityLimit', index)
                    }
                    currencySymbol={lovs.currency[treatyValues.currency]}
                    error={currentCategoryRowErrors?.liabilityLimit}
                    disabled={isDisabled}
                  />
                  <CurrencyFormField
                    name="aggregateLimit"
                    title="Aggregate Limit*"
                    placeholder="Enter Aggregate Limit"
                    value={category.aggregateLimit}
                    onChange={(e) =>
                      handleFieldChange(e.target.value, 'aggregateLimit', index)
                    }
                    currencySymbol={lovs.currency[treatyValues.currency]}
                    error={currentCategoryRowErrors?.aggregateLimit}
                    disabled={isDisabled}
                  />
                </>
              )}
              {['QuotaShare', 'Surplus', 'FacOblig'].includes(layerType) && (
                <EnhancedPercentageInput
                  name="commissionRate"
                  title="Commission Rate*"
                  placeholder="Enter Commission Rate"
                  value={category.commissionRate}
                  onChange={(e) =>
                    handleFieldChange(e.target.value, 'commissionRate', index)
                  }
                  onBlur={() => {}}
                  error={currentCategoryRowErrors?.commissionRate}
                  disabled={isDisabled}
                />
              )}
            </div>
          </div>
        );
      })}
      {!isDisabled && (
        <button className={classes.addBtn} onClick={handleAddRow}>
          <AddCircleOutlineIcon fontSize="small" color="error" />
        </button>
      )}
    </section>
  );
};

export default CategoryRepeater;
