import React from 'react';
import EntityInfoWidget from '../../../../../components/widgets/entity-info/EntityInfoWidget';
import { IEntityInfoView } from '../../../../../components/widgets/entity-info/EntityInfoFields';
import { EnhancedDisplayType } from '../../../../../components/enhanced-form/EnhancedDisplay';

interface ILeftPanel {
  data: {
    title: string;
    createdBy: string;
    createdOn: string;
    modifiedBy: string;
    modifiedOn: string;
  };
}

const LeftPanel: React.FC<ILeftPanel> = ({ data }) => {
  const entityView: IEntityInfoView = {
    iconUrl: '',
    socialMedia: {
      facebook: '',
      twitter: '',
      linkedIn: '',
    },
    sections: [
      {
        title: '',
        name: 'BasicInformation',
        properties: {
          createdBy: {
            name: 'createdBy',
            title: 'Created By',
            value: data?.createdBy,
            multiline: false,
          },
          createdOn: {
            name: 'createdOn',
            title: 'Created On',
            value: data?.createdOn,
            multiline: false,
            type: EnhancedDisplayType.DateTime,
          },
          modifiedBy: {
            name: 'modifiedBy',
            title: 'Modified By',
            value: data?.modifiedBy,
            multiline: false,
          },
          modifiedOn: {
            name: 'modifiedOn',
            title: 'Modified On',
            value: data?.modifiedOn,
            multiline: false,
            type: EnhancedDisplayType.DateTime,
          },
        },
      },
    ],
  };

  return (
    <EntityInfoWidget
      title={data?.title}
      view={entityView}
      supportsClassification={true}
      background="rgb(193, 0, 0)"
      classification={{ classifications: {}, value: '' }}
      progress={100}
      supportsProgressBar={true}
    />
  );
};

export default LeftPanel;
