import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  lineName: '',
  sublineName: '',
  planName: '',
  externalCode: '',
  arabicName: '',
  abbreviation: '',
  effectiveFrom: '',
  effectiveTo: '',
  planStatus: '',
};

export const getInputs = (
  values: IPlanInfo = initialValues,
  lovs: Record<string, Record<string, string>>,
  isLineNameDisabled: boolean,
  isSublineNameDisabled: boolean
): Record<string, DynamicFormInputType> => {
  return {
    lineName: {
      name: 'lineName',
      title: 'Line',
      type: FormInputTypes.chips,
      placeholder: 'Line',
      value: !isEmpty(values.lineName) ? values.lineName : [],
      multiple: false,
      disabled: isLineNameDisabled,
      required: true,
      selectOptions: lovs.line,
    },
    sublineName: {
      name: 'sublineName',
      title: 'Subline',
      placeholder: 'Subline',
      type: FormInputTypes.chips,
      value: !isEmpty(values.sublineName) ? values.sublineName : [],
      required: true,
      multiple: false,
      selectOptions: lovs.subline,
      disabled: isSublineNameDisabled,
    },
    planName: {
      name: 'planName',
      title: 'Plan Name',
      placeholder: 'Plan Name',
      type: FormInputTypes.newtext,
      value: values.planName,
      required: true,
    },
    externalCode: {
      name: 'externalCode',
      title: 'External Code',
      placeholder: 'External Code',
      type: FormInputTypes.newtext,
      value: values.externalCode,
      required: true,
    },
    arabicName: {
      name: 'arabicName',
      title: 'Arabic Name',
      placeholder: 'Arabic Name',
      type: FormInputTypes.text,
      value: values.arabicName,
      required: true,
    },
    abbreviation: {
      name: 'abbreviation',
      title: 'Abbreviation',
      placeholder: 'Abbreviation',
      type: FormInputTypes.text,
      value: values.abbreviation,
      required: true,
    },
    effectiveFrom: {
      name: 'effectiveFrom',
      title: 'Effective From',
      placeholder: 'Effective From',
      type: FormInputTypes.date,
      value: values.effectiveFrom,
      required: true,
    },
    effectiveTo: {
      name: 'effectiveTo',
      title: 'Effective To',
      placeholder: 'Effective To',
      type: FormInputTypes.date,
      value: values.effectiveTo,
      required: true,
      minDate: new Date(values.effectiveFrom),
    },
    planStatus: {
      name: 'planStatus',
      title: 'Status',
      placeholder: 'Status',
      type: FormInputTypes.chips,
      value: !isEmpty(values.planStatus) ? values.planStatus : 'INACTIVE',
      required: true,
      multiple: false,
      hidden: true,
    },
  };
};
