import { IGenericBusinessPartnersPlansTableData } from '.';
import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';

export function entityToBusinessUserDetails(data: any): any {
  const businessUserDetailsInfo =
    data?.SalesforceManagement?.entities?.businessUser?.views
      ?.SalesforceManagement_all?.properties;
  const personDetailsInfo =
    businessUserDetailsInfo.PersonID?.views?.SalesforceManagement_all
      ?.properties;
  const businessUserRetaledUsersInfo =
    data?.SalesforceManagement?.entities?.businessUser?.queries
      ?.salesforceManagement?.relatedUsers[0];

  return {
    name: personDetailsInfo?.FullName,
    businessUserCode: businessUserDetailsInfo?.Code,
    personCode: personDetailsInfo?.PersonCode,
    personId: businessUserDetailsInfo?.PersonID?.id,
    dateOfBirth: personDetailsInfo?.DateOfBirth,
    email: personDetailsInfo?.Email,
    phoneNumber: personDetailsInfo?.PhoneDetails?.PhoneNumber,
    phoneType: capitalizeFirstLetter(
      personDetailsInfo?.PhoneDetails?.PhoneType
    ),
    taxable: personDetailsInfo?.IsTaxable ? 'Yes' : 'No',
    isAdmin: businessUserDetailsInfo?.IsAdmin ? 'Yes' : 'No',

    createdBy: `${businessUserRetaledUsersInfo?.createdBy_firstName} ${businessUserRetaledUsersInfo?.createdBy_lastName}`,
    createdOn: businessUserDetailsInfo?.createdOn,
    modifiedBy: `${businessUserRetaledUsersInfo?.modifiedBy_firstName} ${businessUserRetaledUsersInfo?.modifiedBy_lastName}`,
    modifiedOn: businessUserDetailsInfo?.modifiedOn,

    type: businessUserDetailsInfo.Type?.Id,
    typeTitle: businessUserDetailsInfo.Type?.Title,
    account: businessUserDetailsInfo.Account,
    access: businessUserDetailsInfo.Access?.Id,
    accessTitle: businessUserDetailsInfo.Access?.Title,
    editableLineCharges: businessUserDetailsInfo.EditableLineChargesIDs?.map(
      (item: any) => item?.views?.PlanConfigManagement_all?.properties?.Id
    ),
    editableLineChargesTitle:
      businessUserDetailsInfo.EditableLineChargesIDs?.map(
        (item: any) => item?.views?.PlanConfigManagement_all?.properties?.Name
      ).join(', '),
    allowPolicyEndDateEdits: businessUserDetailsInfo.AllowPolicyEndDateEdits,
    allowPlanCoverEdits: businessUserDetailsInfo.AllowPlanCoverEdits,
    allowClauseEdits: businessUserDetailsInfo.AllowClauseEdits,
    allowGroupPolicy: businessUserDetailsInfo.AllowGroupPolicy,
    allowEndorsement: businessUserDetailsInfo.AllowEndorsement,
    allowEndorsementCostEdits:
      businessUserDetailsInfo.AllowEndorsementCostEdits,
    allowPolicyCorrection: businessUserDetailsInfo.AllowPolicyCorrection,
    SalesforceManagement_BusinessUserStatuses: businessUserDetailsInfo.Status,
  };
}

export const getBusinessUserStatus = (steps: string[], status: string) => {
  switch (status) {
    case 'active':
      return steps[1];
    case 'inactive':
      return steps[1];
    default:
      return steps[0];
  }
};

export function mapToBusinessPartnersPlansListingData(
  data: any
): IListingData<any> {
  const list: IAbstractRecord = {};

  data?.SalesforceManagement?.queries?.getAssignedBPPlanList?.items?.forEach(
    (obj: IGenericBusinessPartnersPlansTableData, i: number) => {
      list[i] = {
        salesforceManagement_AssignedBPPlan_Id:
          obj.salesforceManagement_AssignedBPPlan_Id,
        businessPartner_Code:
          obj.salesforceManagement_AssignedBPPlan_BusinessPartnerID?.views
            ?.SalesforceManagement_all?.properties?.Code,

        businessPartner_PersonID_ID:
          obj.salesforceManagement_AssignedBPPlan_BusinessPartnerID?.id,
        person_FullName:
          obj.businessPartner_PersonID?.views?.SalesforceManagement_all
            ?.properties?.FullName,

        salesforceManagement_AssignedBPPlan_LineID_ID:
          obj.salesforceManagement_AssignedBPPlan_LineID?.id,
        line_ExternalCode:
          obj.salesforceManagement_AssignedBPPlan_LineID?.views
            ?.PlanConfigManagement_all?.properties?.ExternalCode,
        line_Name:
          obj.salesforceManagement_AssignedBPPlan_LineID?.views
            ?.PlanConfigManagement_all?.properties?.Name,

        salesforceManagement_AssignedBPPlan_PlanID_ID:
          obj.salesforceManagement_AssignedBPPlan_PlanID?.id,
        plan_ExternalCode:
          obj.salesforceManagement_AssignedBPPlan_PlanID?.views
            ?.PlanConfigManagement_all?.properties?.ExternalCode,
        plan_Name:
          obj.salesforceManagement_AssignedBPPlan_PlanID?.views
            ?.PlanConfigManagement_all?.properties?.Name,

        salesforceManagement_AssignedBPPlan_BusinessPartnerLineID_Status:
          obj.salesforceManagement_AssignedBPPlan_BusinessPartnerLineID?.views
            ?.SalesforceManagement_all?.properties?.Status,
        salesforceManagement_AssignedBPPlan_BusinessPartnerPlanID_Status:
          obj.salesforceManagement_AssignedBPPlan_BusinessPartnerPlanID?.views
            ?.SalesforceManagement_all?.properties?.Status,

        salesforceManagement_AssignedBPPlan_Status: capitalizeFirstLetter(
          obj.salesforceManagement_AssignedBPPlan_Status?.replaceAll('_', ' ')
        ),
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.SalesforceManagement?.queries?.getAssignedBPPlanList?.paging
        ?.pageSize,
    pageNumber:
      data?.SalesforceManagement?.queries?.getAssignedBPPlanList?.paging
        ?.pageNumber,
    totalCount:
      data?.SalesforceManagement?.queries?.getAssignedBPPlanList?.paging
        ?.totalCount,
  };
}

export function extractAssignedBP(
  data: any,
  planData: any
): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    bp: {},
    line: {},
    plan: {},
  };
  data?.SalesforceManagement?.queries?.getAssignedBPListWithoutPagination.forEach(
    (element: {
      salesforceManagement_AssignedBP_BusinessPartnerID: {
        id: string;
        views: {
          SalesforceManagement_all: {
            properties: {
              PersonID: {
                views: {
                  SalesforceManagement_all: {
                    properties: {
                      FullName: string;
                    };
                  };
                };
              };
            };
          };
        };
      };
    }) => {
      lovs.bp[element.salesforceManagement_AssignedBP_BusinessPartnerID?.id] =
        element.salesforceManagement_AssignedBP_BusinessPartnerID?.views?.SalesforceManagement_all?.properties?.PersonID?.views?.SalesforceManagement_all?.properties?.FullName;
    }
  );

  data?.SalesforceManagement?.queries?.getAssignedBPPlanListWithoutPagination?.forEach(
    (obj: {
      salesforceManagement_AssignedBPPlan_LineID: {
        id: string;
        views: {
          PlanConfigManagement_all: {
            properties: {
              Name: string;
              ExternalCode: string;
            };
          };
        };
      };
    }) => {
      lovs.line[obj?.salesforceManagement_AssignedBPPlan_LineID?.id] =
        `${obj?.salesforceManagement_AssignedBPPlan_LineID?.views?.PlanConfigManagement_all?.properties?.ExternalCode} - ${obj?.salesforceManagement_AssignedBPPlan_LineID?.views?.PlanConfigManagement_all?.properties?.Name}`;
    }
  );

  planData?.SalesforceManagement?.queries?.getAssignedBPPlanListWithoutPagination?.forEach(
    (obj: {
      salesforceManagement_AssignedBPPlan_PlanID: {
        id: string;
        views: {
          PlanConfigManagement_all: {
            properties: {
              Name: string;
              ExternalCode: string;
            };
          };
        };
      };
    }) => {
      lovs.plan[obj?.salesforceManagement_AssignedBPPlan_PlanID?.id] =
        `${obj?.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.ExternalCode} - ${obj?.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.Name}`;
    }
  );

  return lovs;
}

export function extractDistinctLines(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data?.SalesforceManagement?.queries?.getAssignedBPPlanList?.items?.forEach(
    (obj: IGenericBusinessPartnersPlansTableData, i: number) => {
      list[i] = {
        salesforceManagement_AssignedBPPlan_LineID_ID:
          obj.salesforceManagement_AssignedBPPlan_LineID?.id,
        salesforceManagement_AssignedBPPlan_LineID_Name:
          obj.salesforceManagement_AssignedBPPlan_LineID?.views
            ?.PlanConfigManagement_all?.properties?.Name,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.SalesforceManagement?.queries?.getAssignedBPPlanList?.paging
        ?.pageSize,
    pageNumber:
      data?.SalesforceManagement?.queries?.getAssignedBPPlanList?.paging
        ?.pageNumber,
    totalCount:
      data?.SalesforceManagement?.queries?.getAssignedBPPlanList?.paging
        ?.totalCount,
  };
}

export function extractDistinctPlans(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data?.SalesforceManagement?.queries?.getAssignedBPPlanList?.items?.forEach(
    (obj: IGenericBusinessPartnersPlansTableData, i: number) => {
      list[i] = {
        salesforceManagement_AssignedBPPlan_PlanID_ID:
          obj.salesforceManagement_AssignedBPPlan_PlanID?.id,
        salesforceManagement_AssignedBPPlan_PlanID_Name:
          obj.salesforceManagement_AssignedBPPlan_PlanID?.views
            ?.PlanConfigManagement_all?.properties?.Name,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.SalesforceManagement?.queries?.getAssignedBPPlanList?.paging
        ?.pageSize,
    pageNumber:
      data?.SalesforceManagement?.queries?.getAssignedBPPlanList?.paging
        ?.pageNumber,
    totalCount:
      data?.SalesforceManagement?.queries?.getAssignedBPPlanList?.paging
        ?.totalCount,
  };
}
