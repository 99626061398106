import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  calculateAndSaveGeneric,
  getNonSelectedPlanCover,
  getProposalExpatDetails,
  saveProposalDraftGeneric,
  downloadExpatDocument,
} from './queries';
import { validateProposalPageForm } from './validation';
import {
  convertProposalPageStateToSubmission,
  IProposalDetailsExpatPageSubmissionModel,
} from './submission';
import { toast } from 'react-toastify';
import { isEmpty, isValidNumber } from '../../../../../utils/validationUtils';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import { IEnhancedMenuItem } from '../../../../../components';
import SimpleActionBar from '../../../../../components/SimpleActionBar';
import { gridWidthToPercentage } from '../../../../../utils/grid-utils';
import EnhancedStepper from '../../../../../components/common/EnhancedStepper';
import WidgetPaper from '../../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../../components/common/WidgetSection';
import { MAIN_ONE_THEME } from '../../../../../constants';
import { useParams } from 'react-router-dom';
import CloseProposalDrawer from '../../../../../forms/close-proposal-drawer/CloseProposalDrawer';
import StaticLayout from '../../../../../page-layout/static-layout/StaticLayout';
import Loader from '../../../../../components/Loader';
import { ComissionProposalWidget } from '../../../../../forms/proposal-details-page/custom-widgets/ComissionProposalWidget';
import { getProposalStatus, LookupToList } from './utils';
import PolicyProposalQuoteWidget from '../../../../../forms/proposal-details-page/custom-widgets/PolicyProposalQuoteWidget';
import PolicyCoversRepeaterWidget from '../../../../../forms/proposal-details-page/custom-widgets/PolicyCoversRepeaterWidget';
import PolicyCostChargesWidget from '../../../../../forms/proposal-details-page/custom-widgets/PolicyCostChargesWidget';
import PolicyInsuredWidget from '../../../../../forms/proposal-details-page/custom-widgets/PolicyInsuredWidget';
import {
  getProposalPageFormState,
  IProposalCover,
  IProposalPageFormState,
} from './form';
import {
  convertToDetailsSummary,
  convertToPolicyBankersBlanketBondDetails,
  convertToPolicyPersons,
  convertToPolicyPlanProposalCovers,
  convertToPolicyPropertyDetails,
  convertToProposalCover,
  getPlanCoversLov,
  IProposalDetailsPageResponse,
  IProposalDetailsSummary,
} from './index2';
import PolicyDetailsEntityInfo from '../../../../../forms/proposal-details-page/custom-widgets/PolicyDetailsEntityInfo';
import { PolicyDetailsWidget } from '../../../../../forms/proposal-details-page/custom-widgets/PolicyDetailsWidget';
import PolicyPayerWidget from '../../../../../forms/proposal-details-page/custom-widgets/PolicyPayerWidget';
import PolicyOwnerWidget from '../../../../../forms/proposal-details-page/custom-widgets/PolicyOwnerWidget';
import ToastSuccessMessage from '../../../../../components/ToastSuccessMessage';
import { getError } from '../../../../../utils/graph-utils';
import {
  downloadFileFromUrl,
  generateDownloadLink,
} from '../../../../../utils/file-utils';
import BankersDetailsRepeaterWidget from '../../../../../forms/proposal-details-page/custom-widgets/BankersDetailsRepeaterWidget';
import PreviewProposalClauseModalShell from '../modals/PreviewProposalClauseModalShell';
import ProposalMarineDetailsWidget from '../../../../../forms/proposal-details-page/custom-widgets/ProposalMarineDetailsWidget';
import ProposalMarineHullDetailsWidget from '../../../../../forms/proposal-details-page/custom-widgets/ProposalMarineHullDetailsWidget';
import ProposalDescriptionOfRiskWidget from '../../../../../forms/proposal-details-page/custom-widgets/ProposalDescriptionOfRiskWidget';
import ProposalPropertyDescriptionDetailsWidget from '../../../../../forms/proposal-details-page/custom-widgets/ProposalPropertyDescriptionDetailsWidget';

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '95%',
    width: '95%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
  buttonsContainer: {
    width: '95%',
    margin: '10px auto 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  widgetTopMargin: {
    marginTop: '1em',
  },
}));

const ProposalGenericPage: React.FC = () => {
  const { classes } = useStyles();

  const [closeProposalDrawerOpen, setCloseProposalDrawerOpen] =
    useState<boolean>(false);
  const [issueModalOpen, setIssueModalOpen] = useState<boolean>(false);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const params = useParams();
  const entityId = params.id;

  const [pageState, onPageStateUpdate] = useState<IProposalPageFormState>(
    getProposalPageFormState()
  );

  const [data, setData] = useState<IProposalDetailsSummary>();

  const ownerIsDifferent: boolean =
    pageState?.values?.policyPersons?.payer?.ownerIsDifferent || false;
  const isActionVisible = [
    '12',
    '3',
    '33',
    '5',
    '9',
    '10',
    '13',
    '21',
    '35',
    '11',
    '15',
    '16',
    '25',
    '27',
    '29',
    '30',
    '31',
    '37',
    '38',
    '39',
    '42',
    '46',
    '2',
    '8',
    '23',
    '26',
  ].includes(data?.LineId?.ExternalCode);

  const [coversList, setCoversList] = useState<IProposalCover[]>([]);

  const [planCoversList, setPlanCoversList] = useState<
    Record<string, IProposalCover>
  >({});

  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({
    planCovers: {},
  });

  const [saveAndCalculationAction] = useMutation(calculateAndSaveGeneric());

  const [saveAsDraftAction] = useMutation(saveProposalDraftGeneric());

  // const [issuePolicyExpatAction] = useMutation(issuePolicyExpat());

  const [downloadAction] = useMutation(downloadExpatDocument());

  useEffect(() => {
    initialize();
  }, []);

  const isClosed =
    data?.Status === 'CLOSED_WON' || data?.Status === 'CLOSED_LOST';

  const disabledPage = isClosed || submitting || loading;

  const [getProposalDetailsLazy] = useLazyQuery(getProposalExpatDetails());

  const [getNonSelectedPlanCoverLazy] = useLazyQuery(getNonSelectedPlanCover());

  const getPlanCoverDetails = async (
    planId: string,
    planCoverIds: string[]
  ) => {
    const planCoverList: { data: any } = await getNonSelectedPlanCoverLazy({
      variables: {
        selectedPlanID: planId,
        selectedPlanCoverIDs: planCoverIds,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return planCoverList.data;
  };

  const initialize = async () => {
    setLoading(true);
    const result: { data: IProposalDetailsPageResponse } =
      await getProposalDetailsLazy({
        variables: { id: entityId },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

    const summaryData = convertToDetailsSummary(result.data);

    const initialCovers = convertToPolicyPlanProposalCovers(result.data);
    const initialBankers = convertToPolicyBankersBlanketBondDetails(
      result.data
    );
    const initialPersons = convertToPolicyPersons(result.data);
    const initialPolicyProperties = convertToPolicyPropertyDetails(result.data);

    const planCoverDetails = await getPlanCoverDetails(
      summaryData.PlanID.Id,
      []
    );

    const lists = LookupToList(result.data);
    setLovs({
      currency: lists.currency,
      medicalClasses: lists.medicalClasses,
      continuityOptions: lists.continuityOptions,
      matters: lists.matters,
      geoLocations: lists.geoLocations,
      planCovers: getPlanCoversLov(planCoverDetails),
    });

    setPlanCoversList(
      convertToProposalCover(
        planCoverDetails,
        summaryData.PolicyCurrency?.Code,
        summaryData.PricingOption.PlanToPolicyRate,
        summaryData.PolicyEffectiveDate,
        summaryData.PolicyExpiryDate
      )
    );
    setCoversList(initialCovers);

    const newPageState = getProposalPageFormState(
      summaryData,
      initialCovers,
      initialPersons,
      initialBankers,
      initialPolicyProperties
    );
    onPageStateUpdate(newPageState);

    setData(summaryData);
    setLoading(false);
  };

  const renderLeftSection = () => {
    if (data) {
      return <PolicyDetailsEntityInfo data={data as any} />;
    }

    return <></>;
  };

  const onSubmit = async () => {
    const validationResult = validateProposalPageForm(data, pageState.values);

    if (!submitting) {
      setSubmitting(true);
      const newPageState = {
        ...pageState,
        errors: validationResult.errors,
        touched: validationResult.touched,
      };

      onPageStateUpdate(newPageState);
      if (validationResult.isValid) {
        const dataToSubmit = convertProposalPageStateToSubmission(
          data,
          pageState
        );
        const result = await saveAndCalculationAction({
          variables: { ...dataToSubmit },
          errorPolicy: 'all',
        });
        if (isEmpty(result.errors)) {
          // setDeactivateButtonState("success");
          toast.success(
            <ToastSuccessMessage>
              {'Proposal successfully saved.'}
            </ToastSuccessMessage>
          );
          initialize();
        } else {
          // setRegenerateButtonState(undefined);
          toast.error(
            <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
          );
        }
      } else {
        toast.error(<ToastErrorMessage>Incomple Form</ToastErrorMessage>);
      }
      setSubmitting(false);
    }
  };

  const onSaveDraft = async () => {
    try {
      if (!submitting) {
        setSubmitting(true);
        const dataToSubmit = convertProposalPageStateToSubmission(
          data,
          pageState
        ) as Partial<IProposalDetailsExpatPageSubmissionModel>;

        if (isEmpty(dataToSubmit.policyPersonInput[0]?.personId)) {
          dataToSubmit.policyPersonInput = [];
        }

        const result = await saveAsDraftAction({
          variables: { ...dataToSubmit },
          errorPolicy: 'all',
        });

        if (isEmpty(result.errors)) {
          // setDeactivateButtonState("success");
          toast.success(
            <ToastSuccessMessage>
              {'Proposal successfully saved.'}
            </ToastSuccessMessage>
          );

          initialize();
        } else {
          // setRegenerateButtonState(undefined);
          toast.error(
            <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
          );
        }
      }
      setSubmitting(false);
    } catch {
      setSubmitting(false);
    }
  };

  // const onIssuePolicyAction = async () => {
  //   if (!submitting) {
  //     setSubmitting(true);
  //     const dataToSubmit = {
  //       entityId: data.Id,
  //       clauseText: data.ClauseText || "",
  //     };
  //     const result = await issuePolicyExpatAction({
  //       variables: { ...dataToSubmit },
  //       errorPolicy: "all",
  //     });

  //     if (isEmpty(result.errors)) {
  //       // setDeactivateButtonState("success");
  //       toast.success(
  //         <ToastSuccessMessage>
  //           {"Policy successfully issued."}
  //         </ToastSuccessMessage>
  //       );

  //       const filesInfo = getFileDetailsList(result.data);

  //       for (const fileInfo of filesInfo) {
  //         const downloadLink = generateDownloadLink(
  //           fileInfo.fileId,
  //           fileInfo.EntityTypeId,
  //           fileInfo.EntityId,
  //           fileInfo.EntityViewId,
  //           fileInfo.PropertyId
  //         );
  //         await downloadFileFromUrl(downloadLink, fileInfo.fileName);
  //         setSubmitting(false);
  //       }
  //     } else {
  //       // setRegenerateButtonState(undefined);
  //       setSubmitting(false);
  //       toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
  //     }
  //   }
  // };

  const downloadDocument = async () => {
    if (!submitting) {
      setSubmitting(true);
      const dataToSubmit = {
        entityId: data.Id,
      };
      const result = await downloadAction({
        variables: { ...dataToSubmit },
        errorPolicy: 'all',
      });
      if (isEmpty(result.errors)) {
        // setDeactivateButtonState("success");
        const fileInfo = getFileDetails(result.data);
        const downloadLink = generateDownloadLink(
          fileInfo.fileId,
          fileInfo.EntityTypeId,
          fileInfo.EntityId,
          fileInfo.EntityViewId,
          fileInfo.PropertyId
        );

        await downloadFileFromUrl(downloadLink, fileInfo.fileName);
        toast.success(
          <ToastSuccessMessage>
            {'Policy sample successfully downloaded.'}
          </ToastSuccessMessage>
        );
        setSubmitting(false);
        // onSuccess();
      } else {
        // setRegenerateButtonState(undefined);
        setSubmitting(false);
        toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
      }
    }
  };

  downloadDocument;

  const renderActions = () => {
    const actions: IEnhancedMenuItem[] = [
      {
        title: 'Save Draft',
        onClick: () => {
          onSaveDraft();
        },
        isSecondary: false,
        backgroundColor: '#000',
        color: '#fff',
        disabled: disabledPage,
        hidden:
          isClosed || data?.Status?.toLowerCase() !== 'new' || !isActionVisible,
      },
      {
        title: 'Calculate & Save',
        onClick: () => {
          onSubmit();
        },
        isSecondary: false,
        backgroundColor: '#000',
        color: '#fff',
        disabled: disabledPage,
        hidden:
          isClosed ||
          !isValidNumber(data?.LineId?.ExternalCode) ||
          !isActionVisible,
      },
      {
        title: 'Issue Policy',
        onClick: () => {
          setIssueModalOpen(true);
        },
        isSecondary: false,
        hidden:
          isClosed ||
          !(
            data?.Status?.toLowerCase() === 'in_progress' &&
            isValidNumber(data?.LineId?.ExternalCode)
          ) ||
          !isActionVisible,
        backgroundColor: '#000',
        color: '#fff',
        disabled: disabledPage,
      },
      // {
      //   title: "Download Policy Sample",
      //   onClick: () => {
      //     downloadDocument();
      //   },
      //   isSecondary: false,
      //   hidden:
      //     isClosed ||
      //     !(
      //       data?.Status?.toLowerCase() === "in_progress" &&
      //       isValidNumber(data?.LineId?.ExternalCode)
      //     ),
      //   backgroundColor: "#000",
      //   color: "#fff",
      //   disabled: disabledPage,
      // },
      {
        title: 'Close Proposal',
        onClick: () => {
          setCloseProposalDrawerOpen(true);
        },
        isSecondary: false,
        backgroundColor: '#000',
        color: '#fff',
        disabled: disabledPage,
        hidden: isClosed,
      },
    ];

    return <SimpleActionBar items={actions} />;
  };

  const renderMainChildren = () => {
    if (data) {
      // const isClosed = status === "closedwon" || "closedlost";
      const isProposalWon = data.Status?.toUpperCase() === 'CLOSED_WON';
      const isProposalUserNew = data.Status?.toLowerCase() === 'new';
      const steps = isProposalUserNew
        ? ['New', 'In Progress', 'Closed Won']
        : ['New', 'In Progress', isProposalWon ? 'Closed Won' : 'Closed Lost'];

      return (
        <>
          <div style={{ marginTop: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <div style={{ width: `${gridWidthToPercentage(8)}%` }}>
                <EnhancedStepper
                  activeStep={getProposalStatus(steps, data.Status)}
                  steps={steps}
                  buttonTitle="Calculate & Save"
                  buttonOnClick={() => undefined}
                  className={classes.stepper}
                />
              </div>
              <div style={{ width: `${gridWidthToPercentage(4)}%` }}>
                {renderActions()}
              </div>
            </div>
            <div
              style={{
                marginTop: '18px',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  width: `${gridWidthToPercentage(8)}%`,
                  padding: '0px 1em 1em 0',
                }}
              >
                <PolicyDetailsWidget data={data as any} />
                <PolicyCoversRepeaterWidget
                  defaultPlanCoversList={planCoversList}
                  pageState={pageState as any}
                  disabledForm={disabledPage}
                  status={data.Status}
                  data={data as any}
                  allowPlanCoverEdits={data.BusinessUserID.AllowPlanCoverEdits}
                  onPageStateUpdate={onPageStateUpdate as any}
                  lovs={lovs}
                />
                {['2', '8', '23', '26'].includes(
                  data?.LineId?.ExternalCode
                ) && (
                  <ProposalPropertyDescriptionDetailsWidget
                    pageState={pageState}
                    onPageStateUpdate={onPageStateUpdate}
                    disabledForm={disabledPage}
                    lovs={lovs}
                    currencySymbol={data.PolicyCurrency.Symbol}
                  />
                )}
                <PolicyCostChargesWidget
                  pageState={pageState as any}
                  disabledForm={disabledPage}
                  status={data.Status}
                  line={data?.LineId.ExternalCode?.toString() || ''}
                  editableLine={data.BusinessUserID?.EditableLineChargesIDs?.includes(
                    data?.LineId.Id
                  )}
                  onPageStateUpdate={onPageStateUpdate as any}
                  currencySymbol={data.PolicyCurrency.Symbol}
                />
                {[
                  '5',
                  '9',
                  '10',
                  '13',
                  '21',
                  '35',
                  '11',
                  '15',
                  '16',
                  '25',
                  '27',
                  '29',
                  '30',
                  '31',
                  '37',
                  '38',
                  '39',
                  '42',
                  '46',
                ].includes(data?.LineId?.ExternalCode) && (
                  <ProposalDescriptionOfRiskWidget
                    pageState={pageState}
                    onPageStateUpdate={onPageStateUpdate}
                    status={data.Status}
                    disabledForm={disabledPage}
                    lovs={lovs}
                    lineExternalCode={data?.LineId?.ExternalCode}
                  />
                )}
                <WidgetPaper style={{ marginTop: '1em' }}>
                  <WidgetSection
                    title="Payer & Owner"
                    hasTitleSpecificDesign={false}
                    classes={
                      {
                        // container: classes.section,
                      }
                    }
                    useSeparator={false}
                  >
                    <PolicyPayerWidget
                      pageState={pageState as any}
                      onPageStateUpdate={onPageStateUpdate as any}
                      lovs={lovs}
                      disabledForm={disabledPage}
                      data={data as any}
                    />
                    {ownerIsDifferent && (
                      <PolicyOwnerWidget
                        pageState={pageState as any}
                        onPageStateUpdate={onPageStateUpdate as any}
                        lovs={lovs}
                        disabledForm={disabledPage}
                        data={data as any}
                      />
                    )}
                  </WidgetSection>
                </WidgetPaper>

                <PolicyInsuredWidget
                  pageState={pageState as any}
                  onPageStateUpdate={onPageStateUpdate as any}
                  lovs={lovs}
                  disabledForm={disabledPage}
                  maxNumber={data.PlanID.MaxNbInsured}
                  data={data as any}
                />

                {data?.LineId?.ExternalCode === '12' && (
                  <BankersDetailsRepeaterWidget
                    pageState={pageState}
                    onPageStateUpdate={onPageStateUpdate}
                    status={data.Status}
                    data={data}
                    disabledForm={disabledPage}
                  />
                )}
                {data?.LineId?.ExternalCode === '3' && (
                  <ProposalMarineDetailsWidget
                    pageState={pageState}
                    onPageStateUpdate={onPageStateUpdate}
                    status={data.Status}
                    data={data}
                    disabledForm={disabledPage}
                    lovs={lovs}
                  />
                )}
                {(data?.LineId?.ExternalCode === '33' ||
                  data?.LineId?.ExternalCode === '20') && (
                  <ProposalMarineHullDetailsWidget
                    pageState={pageState}
                    onPageStateUpdate={onPageStateUpdate}
                    status={data.Status}
                    data={data}
                    disabledForm={disabledPage}
                    lovs={lovs}
                  />
                )}

                {/* <ProposalExpatDetailsWidget
                  defaultPlanCoversList={planCoversList}
                  pageState={pageState as any}
                  status={data.Status}
                  allowPlanCoverEdits={data.BusinessUserID.AllowPlanCoverEdits}
                  onPageStateUpdate={onPageStateUpdate as any}
                  lovs={lovs}
                  data={data as any}
                  disabledForm={disabledPage}
                /> */}
                {closeProposalDrawerOpen && (
                  <CloseProposalDrawer
                    open={closeProposalDrawerOpen}
                    onClose={() => {
                      setCloseProposalDrawerOpen(false);
                    }}
                    onSuccess={() => {
                      initialize();
                      setCloseProposalDrawerOpen(false);
                    }}
                    id={entityId}
                  />
                )}
              </div>
              <div
                style={{
                  width: `${gridWidthToPercentage(4)}%`,
                  padding: '0px',
                }}
              >
                <PolicyProposalQuoteWidget
                  data={data as any}
                  covers={coversList}
                />
                {data?.Status && data?.Status?.toLowerCase() !== 'new' && (
                  <ComissionProposalWidget
                    className={classes.widgetTopMargin}
                    data={data as any}
                    onUpdateSuccess={() => {
                      initialize();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      );
    }

    return <></>;
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <StaticLayout
        name={'Proposal Details'}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
      />
      {issueModalOpen && (
        <PreviewProposalClauseModalShell
          data={data}
          onSuccess={() => {
            setIssueModalOpen(false);
            initialize();
          }}
          onClose={() => setIssueModalOpen(false)}
        />
      )}
    </>
  );
};

export default ProposalGenericPage;

// function getFileDetailsList(data: any): FileDetail[] {
//   const fileDetails: FileDetail[] = [];

//   const items = data.production.entities.proposal.production.issuePolicyExpat;

//   const fileDetail: FileDetail = {
//     EntityTypeId: items.EntityTypeId,
//     EntityId: items.EntityId,
//     EntityViewId: items.EntityViewId,
//     PropertyId: items.PropertyId,
//     fileId: items.File.id,
//     fileName: items.File.fileName,
//   };
//   fileDetails.push(fileDetail);

//   return fileDetails;
// }

function getFileDetails(data: any) {
  const downloadPolicySampleExpat =
    data.production.entities.proposal.production.downloadPolicySampleExpat;
  const { EntityTypeId, EntityId, EntityViewId, PropertyId, File } =
    downloadPolicySampleExpat;
  const { id: fileId, fileName } = File;

  return {
    EntityTypeId,
    EntityId,
    EntityViewId,
    PropertyId,
    fileId,
    fileName,
  };
}

// type FileDetail = {
//   EntityTypeId: string;
//   EntityId: string;
//   EntityViewId: string;
//   PropertyId: string;
//   fileId: string;
//   fileName: string;
// };
