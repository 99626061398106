import {
  CertificateItem,
  Production_CertificateDetailsOutputInput,
} from '../../../../../../forms/add-certificate-drawer';

export function mapToFleetPolicyListingData(
  data: Production_CertificateDetailsOutputInput
) {
  if (!data || !data.Items || data.Items.length === 0) {
    return {
      pagedItems: [],
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    };
  }

  const pagedItems = data.Items.map((item: CertificateItem) => {
    return {
      planID: item.planID,
      plan: `${item.planExternalCode || ''} - ${item.planName || ''}`,
      plate: `${item.plateNumber}/${item.plateCode?.Title || ''}`,
      make: item.make?.Title || '',
      model: item.model?.Title || '',
      color: item.color?.Title || '',
      yearOfMake: item.yearOfMake,
      chassis: item.chassis,
      sumInsured: item.certificatePricingOption.sumInsured,
      totalPremium: item.certificatePricingOption.totalPremium,
      effectiveDate: item.effectiveDate,
      Currency: item.certificatePricingOption?.pricingOptionCurrency?.Symbol,
    };
  });

  return {
    pagedItems,
    pageSize: 10,
    pageNumber: 1,
    totalCount: pagedItems.length,
  };
}

export function getCertificateDetailsOutputInput(
  data: Production_CertificateDetailsOutputInput
) {
  return data.Items.map((item: CertificateItem) => ({
    rowNumber: item.rowNumber,
    plateCode: item.plateCode?.Id || null,
    plateNumber: item.plateNumber,
    vignetteCode: item.vignetteCode,
    vignette: item.vignette,
    usageType: item.usageType?.Id || null,
    bodyType: item.bodyType?.Id || null,
    engineType: item.engineType?.Id || null,
    make: item.make?.Id || null,
    model: item.model?.Id || null,
    color: item.color?.Id || null,
    category: item.category?.Id || null,
    yearOfMake: item.yearOfMake,
    engine: item.engine,
    weight: item.weight,
    engineSize: item.engineSize,
    seatingCapacity: item.seatingCapacity,
    chassis: item.chassis,
    horsePower: item.horsePower,
    carValue: item.carValue,
    agencyRepairYear: item.agencyRepairYear,
    depreciationYears: item.depreciationYears,
    LineID: item.LineID,
    sublineID: item.sublineID,
    planID: item.planID,
    planName: item.planName || '',
    planExternalCode: item.planExternalCode || '',
    effectiveDate: item.effectiveDate,
    allRiskRate: item.allRiskRate || 0,
    allRiskMinPremium: item.allRiskMinPremium || 0,
    clauseText: item.clauseText || '',
    certificatePolicyPlanCovers: item.certificatePolicyPlanCovers
      ? item.certificatePolicyPlanCovers.map(({ __typename, ...rest }) => ({
          ...rest,
        }))
      : null,
    certificatePricingOption: item.certificatePricingOption
      ? {
          ...item.certificatePricingOption,
          fixedStampCurrency:
            item.certificatePricingOption.fixedStampCurrency?.Id || null,
          pricingOptionCurrency:
            item.certificatePricingOption.pricingOptionCurrency?.Id || null,
        }
      : null,
    certificatePolicyBusinessPartner: item.certificatePolicyBusinessPartner
      ? {
          ...item.certificatePolicyBusinessPartner,
          applicableOn: item.certificatePolicyBusinessPartner.applicableOn?.Id,
          valueCurrency:
            item.certificatePolicyBusinessPartner.valueCurrency?.Id,
        }
      : null,
  }));
}
