import React, { useMemo } from 'react';
import EntityInfoWidget from '../../../../components/widgets/entity-info/EntityInfoWidget';
import { IAbstractRecord } from '../../../../models';
import { getLineEntityView } from '../utils';
import { useQuery } from '@apollo/client';
import { getUsersFullNameQuery } from '../queries';

interface ILeftPanel {
  data: IAbstractRecord;
}

const LeftPanel: React.FC<ILeftPanel> = ({ data }) => {
  const { data: usersNamesData } = useQuery(getUsersFullNameQuery, {
    variables: {
      createdById: data?.production_Amendment_createdBy,
      modifiedById: data?.production_Amendment_modifiedBy,
    },
    errorPolicy: 'all',
    skip:
      !data?.production_Amendment_createdBy ||
      !data?.production_Amendment_modifiedBy,
  });

  const names = useMemo(() => {
    if (!usersNamesData) return null;

    const buildFullName = (props?: { firstName: string; lastName: string }) => {
      if (!props) return '';
      const { firstName, lastName } = props;
      return [firstName, lastName].filter(Boolean).join(' ');
    };

    return {
      createdBy: buildFullName(
        usersNamesData?.System?.entities?.createdBy?.views?.System_all
          ?.properties
      ),
      modifiedBy: buildFullName(
        usersNamesData?.System?.entities?.modifiedBy?.views?.System_all
          ?.properties
      ),
    };
  }, [usersNamesData]);

  const entityView = useMemo(
    () => getLineEntityView(data, names),
    [data, names]
  );

  return (
    <EntityInfoWidget
      title={entityView?.title}
      view={entityView}
      supportsClassification
      background="rgb(193, 0, 0)"
      classification={{ classifications: {}, value: '' }}
      progress={100}
      supportsProgressBar
    />
  );
};

export default LeftPanel;
