import { IPaymentTermGenericTableData } from '.';
import { IAbstractRecord } from '../../../../../models';
import { IListingData } from '../../../../../models/listing';

export function mapToListingData(data: IAbstractRecord): IListingData {
  const bills = data.Accounting.queries.GetPolicyBills || [];
  const list: Record<string, IPaymentTermGenericTableData> = {};

  // const receivableBills: string[] = [];
  // const payableBills: string[] = [];
  // const BPaccounts: string[] = [];
  // const groupedAmounts = bills?.reduce(
  //   (acc: { [key: string]: Record<string, number> }, bill: IAbstractRecord) => {
  //     const key = bill.accounting_Bills_Id;

  //     if (!acc[key]) {
  //       acc[key] = {};
  //       acc[key].AmountReceived = 0;
  //       acc[key].AmountPaid = 0;
  //       acc[key].TaxOnCommission = 0;
  //     }

  //     if (!receivableBills.includes(bill.receivablePayment_Id)) {
  //       acc[key].AmountReceived += Number(
  //         bill.receivablePayment_AmountReceivedToBillCurrency
  //       );
  //       receivableBills.push(bill.receivablePayment_Id);
  //     }
  //     if (!payableBills.includes(bill.payablePayment_Id)) {
  //       acc[key].AmountPaid += Number(
  //         bill.payablePayment_AmountReceivedToBillCurrency
  //       );
  //       payableBills.push(bill.payablePayment_Id);
  //     }

  //     if (!BPaccounts.includes(bill.payableBills_BillingAccountID?.id)) {
  //       acc[key].TaxOnCommission += Number(bill.payableBills_TaxOnCommission);
  //       BPaccounts.push(bill.payableBills_BillingAccountID?.id);
  //     }

  //     return acc;
  //   },
  //   {}
  // );

  bills?.forEach((obj: IPaymentTermGenericTableData) => {
    list[obj.accounting_Bills_Id] = {
      id: obj.accounting_Bills_Id,
      accounting_Bills_Id: obj.accounting_Bills_Id,
      accounting_Bills_BillNumber: obj.accounting_Bills_BillNumber,
      accounting_Bills_DueDate: obj.accounting_Bills_DueDate,
      accounting_Bills_TotalPremium: obj.accounting_Bills_TotalPremium,
      accounting_Bills_AmountReceived: obj.accounting_Bills_AmountReceived,
      accounting_Bills_AmountDue: obj.accounting_Bills_AmountDue,
      accounting_Bills_TotalCommission: obj.accounting_Bills_TotalCommission,
      accounting_Bills_TaxOnCommission: obj.accounting_Bills_TaxOnCommission,
      payableBills_AmountPaid: obj.payableBills_AmountPaid,
      totalBalance:
        Number(obj.accounting_Bills_AmountDue) -
        Number(obj.accounting_Bills_AmountReceived) -
        (Number(obj.accounting_Bills_TotalCommission) -
          Number(obj.accounting_Bills_TaxOnCommission) -
          Number(obj.payableBills_AmountPaid)),
      Currency: obj.accounting_Bills_Currency?.Symbol,
    };
  });

  return {
    pagedItems: list,
    pageSize: 5,
    pageNumber: 1,
    totalCount: Object.keys(list).length,
  };
}
