import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../../../components/widgets/custom-listing-filter';
import { FormInputTypes } from '../../../../DynamicForm';
import { IAbstractRecord } from '../../../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  SalesforceManagement_BusinessPartner_Code: {
    name: 'SalesforceManagement_BusinessPartner_Code',
    title: 'Reinsurer Code',
    type: EnhancedTableHeaderType.Text,
  },
  SalesforceManagement_BusinessPartner_FullName: {
    name: 'SalesforceManagement_BusinessPartner_FullName',
    title: 'Reinsurer Name',
    type: EnhancedTableHeaderType.Text,
  },
  SalesforceManagement_BusinessPartner_Category: {
    name: 'SalesforceManagement_BusinessPartner_Category',
    title: 'Reinsurer Type',
    type: EnhancedTableHeaderType.Text,
  },
  SalesforceManagement_BusinessPartner_RegisterNumber: {
    name: 'SalesforceManagement_BusinessPartner_RegisterNumber',
    title: 'Register Number',
    type: EnhancedTableHeaderType.Text,
  },
  SalesforceManagement_BusinessPartner_Account: {
    name: 'SalesforceManagement_BusinessPartner_Account',
    title: 'Account',
    type: EnhancedTableHeaderType.Text,
  },
  PersonDetails_Comments: {
    name: 'PersonDetails_Comments',
    title: 'Comments',
    type: EnhancedTableHeaderType.Text,
  },
  SalesforceManagement_BusinessPartner_Status: {
    name: 'SalesforceManagement_BusinessPartner_Status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        reinsurerType: {
          name: 'reinsurerType',
          title: 'Reinsurer Type',
          type: FormInputTypes.newmultiselect,
          value: filterValues?.reinsurerType || null,
          placeholder: 'Select a Type',
          triggerUpdate: true,
          selectOptions: { ...lovs.reinsurerType },
        },
        isLocal: {
          name: 'isLocal',
          title: 'Is Local',
          type: FormInputTypes.newselect,
          value: filterValues?.isLocal || null,
          placeholder: 'Select if local',
          triggerUpdate: true,
          selectOptions: { ...lovs.isLocal },
          canClearValue: true,
        },
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.newmultiselect,
          value: filterValues?.status || null,
          placeholder: 'Select a status',
          triggerUpdate: true,
          selectOptions: { ...lovs.status },
        },
      },
    },
  ];
  return newFilterSections;
}
