import React, { useEffect, useState } from 'react';
import { ILineAmendmentProps } from '.';
import { useLazyQuery } from '@apollo/client';
import BeneficiaryWidget from '../components/policy-persons-widgets/BeneficiaryWidget';
import PayerOwnerWidget from '../components/policy-persons-widgets/PayerOwnerWidget';
import CostsAndCharges from '../components/CostsAndCharges';
import { getCoversPersonsQuery, getMedicalDetailsQuery } from '../queries';
import { mapToAmendmentDetails } from '../utils';
import { cloneDeep } from 'lodash';
import { getAmendmentInitialState } from '../content';
import { convertToPolicyPersons } from '../components/policy-persons-widgets/utils';
import { IAbstractRecord } from '../../../../models';
import Loader from '../../../../components/Loader';
import AmendmentDetails from '../components/AmendmentDetails';
import MedicalPrincipalInsured from '../components/policy-persons-widgets/MedicalPrincipalInsured';
import MedicalDetailsWidget from '../components/MedicalDetails';

const MedicalAmendment: React.FC<ILineAmendmentProps> = ({
  generalData,
  data,
  pageState,
  handleStateChange,
}) => {
  const [loading, setLoading] = useState<boolean>();
  const isDisabled = ['COMPLETED', 'CANCELLED', 'EXPIRED'].includes(
    generalData.amendmentStatus
  );

  const [lovs, setLovs] = useState<IAbstractRecord>({
    plans: {},
  });

  const [getCoversPersonsLazy] = useLazyQuery(getCoversPersonsQuery('persons'));

  const [getMedicalDetailsLazy] = useLazyQuery(getMedicalDetailsQuery, {
    variables: {
      selectedPolicyID: generalData.policyId,
      version: generalData.version,
    },
    errorPolicy: 'all',
  });

  const initialize = async () => {
    setLoading(true);

    const { data: detailsData } = await getMedicalDetailsLazy();

    const newValues = mapToAmendmentDetails({
      ...data?.[0],
      ...detailsData.Production.queries.GetPolicyMedical[0],
    });

    const newErrors = cloneDeep(
      getAmendmentInitialState(generalData?.lineCategory).errors
    );
    const newTouched = cloneDeep(
      getAmendmentInitialState(generalData?.lineCategory).touched
    );

    // get covers and/or persons --start
    const { data: coversPersonsData } = await getCoversPersonsLazy({
      variables: {
        id: generalData?.proposalId,
        version: generalData.version,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    const persons = convertToPolicyPersons(coversPersonsData);
    newValues.policyPersons = persons;
    newErrors.policyPersons = {
      insured: persons.insured.map(() => newErrors['policyPersons'].insured[0]),
      payer: newErrors['policyPersons'].payer,
      owner: newErrors['policyPersons'].owner,
      beneficiaries: persons.beneficiaries.map(
        () => newErrors['policyPersons'].beneficiaries[0]
      ),
    };
    newTouched.policyPersons = {
      insured: persons.insured.map(
        () => newTouched['policyPersons'].insured[0]
      ),
      payer: newTouched['policyPersons'].payer,
      owner: newTouched['policyPersons'].owner,
      beneficiaries: persons.beneficiaries.map(
        () => newTouched['policyPersons'].beneficiaries[0]
      ),
    };
    // get covers and/or persons --end

    setLovs((prev) => ({
      ...prev,
      plans: { [data?.[0]?.plan_Id]: data?.[0]?.plan_Name },
    }));

    handleStateChange({
      values: newValues,
      errors: newErrors,
      touched: newTouched,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <AmendmentDetails
        generalData={generalData}
        lovs={lovs}
        pageState={pageState}
        onChange={handleStateChange}
      />
      <CostsAndCharges
        generalData={generalData}
        pageState={pageState}
        onPageStateUpdate={handleStateChange}
        isDisabled={
          isDisabled ||
          generalData?.businessUser?.editableLines?.includes(
            generalData?.lineExternalCode
          )
        }
      />
      <PayerOwnerWidget
        generalData={generalData}
        pageState={pageState}
        onPageStateUpdate={handleStateChange}
        isDisabled={isDisabled}
      />
      <BeneficiaryWidget
        generalData={generalData}
        pageState={pageState}
        onPageStateUpdate={handleStateChange}
        isDisabled={isDisabled}
        maxNumber={
          data?.[0]?.policy_PlanID?.views?.PlanConfigManagement_all?.properties
            ?.MaxNbBeneficiary
        }
      />
      <MedicalDetailsWidget
        generalData={generalData}
        pageState={pageState}
        onPageStateUpdate={handleStateChange}
        isDisabled={isDisabled}
      />
      <MedicalPrincipalInsured
        generalData={generalData}
        pageState={pageState}
        onPageStateUpdate={handleStateChange}
        isDisabled={isDisabled}
        maxNumber={
          data?.[0]?.policy_PlanID?.views?.PlanConfigManagement_all?.properties
            ?.MaxNbInsured
        }
      />
    </>
  );
};

export default MedicalAmendment;
