import React, { useMemo, useState } from 'react';
import { IDynamicDiplayView } from '../../../../../components/widgets/dynamic-display';
import DynamicWidget from '../../../../../components/widgets/dynamic-display/DynamicWidget';
import { IProductionDetailsSummary } from '..';
import { EnhancedDisplayType } from '../../../../../components/enhanced-form/EnhancedDisplay';
import EnhancedButton from '../../../../../components/form-fields/buttons/EnhancedButton';
import MarineDrawer from '../../../../../forms/marine-drawer/MarineDrawer';

interface IMarineDetailsWidgetProps {
  data: IProductionDetailsSummary;
  lovs: Record<string, Record<string, string>>;
}

const ProductionPolicyMarineDetailsWidget: React.FC<
  IMarineDetailsWidgetProps
> = ({ data, lovs }) => {
  const [marineInfoDrawerOpen, setMarineInfoDrawerOpen] =
    useState<boolean>(false);

  const dynamicView = useMemo(
    (): IDynamicDiplayView => ({
      name: 'PolicyMarineDetails',
      sections: [
        {
          name: 'MarineDetails',
          title: 'Marine Details',
          properties: {
            matter: {
              multiline: false,
              value: data?.Marine?.matter,
              name: 'matter',
              title: 'Matter',
              fullLine: true,
            },
            description: {
              multiline: false,
              value: data?.Marine?.description,
              name: 'description',
              title: 'Description',
              fullLine: true,
            },
            vesselName: {
              multiline: false,
              value: data?.Marine?.vesselName,
              name: 'vesselName',
              title: 'Vessel Name',
            },
            vesselAge: {
              multiline: false,
              value: data?.Marine?.vesselAge?.toString(),
              name: 'vesselAge',
              title: 'Vessel Age',
            },
            from: {
              multiline: false,
              value: data?.Marine?.from,
              name: 'from',
              title: 'Departure From',
            },
            to: {
              multiline: false,
              value: data?.Marine?.to,
              name: 'to',
              title: 'Destination To',
            },
            via: {
              multiline: false,
              value: data?.Marine?.via,
              name: 'via',
              title: 'Transportation Via',
            },
            survey: {
              multiline: false,
              value: data?.Marine?.survey,
              name: 'survey',
              title: 'Survey',
            },
            cargoContract: {
              multiline: false,
              value: data?.Marine?.cargoContract,
              name: 'cargoContract',
              title: 'Cargo Contract',
            },
            lcNumber: {
              multiline: false,
              value: data?.Marine?.lcNumber,
              name: 'lcNumber',
              title: 'LC Number',
            },
            lcIssueDate: {
              multiline: false,
              value: data?.Marine?.lcIssueDate,
              type: EnhancedDisplayType.Date,
              name: 'lcIssueDate',
              title: 'LC Issue Date',
            },
            bank: {
              multiline: false,
              value: data?.Marine?.bank,
              name: 'bank',
              title: 'Bank',
            },
            claimsPayableBy: {
              multiline: false,
              value: data?.Marine?.claimsPayableBy,
              name: 'claimsPayableBy',
              title: 'Claims Payable by',
            },
            loading: {
              multiline: false,
              value: data?.Marine?.loading?.toString(),
              type: EnhancedDisplayType.Percentage,
              name: 'loading',
              title: 'Loading',
            },
            remarks: {
              multiline: false,
              value: data?.Marine?.remarks,
              name: 'remarks',
              title: 'Remarks',
              fullLine: true,
            },
          },
        },
      ],
    }),
    [data]
  );

  return (
    <>
      <DynamicWidget
        hasFourFields={true}
        view={dynamicView}
        actions={
          data?.BusinessUserID?.AllowPolicyCorrection && (
            <div>
              <EnhancedButton
                isPrimary
                onClick={() => {
                  setMarineInfoDrawerOpen(true);
                }}
              >
                Modify
              </EnhancedButton>
            </div>
          )
        }
      />
      {marineInfoDrawerOpen && (
        <MarineDrawer
          open={marineInfoDrawerOpen}
          onClose={() => setMarineInfoDrawerOpen(false)}
          onSuccess={() => {}}
          data={data}
          lovs={lovs}
        />
      )}
    </>
  );
};

export default ProductionPolicyMarineDetailsWidget;
