import { gql } from '@apollo/client';

export const getTreatyDetailsQuery = gql`
  query getTreatyDetails($TreatyID: String!, $SublineCategory: String) {
    Production {
      queries {
        GetTreatyDetails(TreatyID: $TreatyID) {
          production_Treaties_Id
          production_Treaties_TreatyName
          production_Treaties_TreatyID
          production_Treaties_CreatedByName
          production_Treaties_createdBy
          production_Treaties_createdOn
          production_Treaties_UpdatedByName
          production_Treaties_modifiedBy
          production_Treaties_modifiedOn
          production_Treaties_TreatyStatus
          production_Treaties_TreatyType
          production_Treaties_EffectiveDate
          production_Treaties_ExpiryDate
          production_Treaties_TreatyCurrency {
            Code
            Symbol
          }
          production_Treaties_TreatyUnderwritingLimit
          production_Treaties_ClaimAdviseLimit
          production_Treaties_SubstandardLimit
        }
        GetTreatyLayerAndCategory(
          TreatyID: $TreatyID
          SublineCategory: $SublineCategory
        ) {
          production_TreatyLayer_Id
          production_TreatyLayer_TreatyLayerOrder
          production_TreatyLayer_TreatySubType {
            Code
            Title
          }
          production_TreatyLayer_QuotaSharePercentage
          production_TreatyLayer_PaymentDate
          production_TreatyLayer_PaymentAmount
          production_TreatyLayer_TotalTreatyPremium
          treatyLayerCategory_TotalExpectedPremium
          treatyLayerCategory_PremiumRate
          treatyLayerCategory_Id
          treatyLayerCategory_Category
          treatyLayerCategory_MinimumLayerLimit
          treatyLayerCategory_MaximumLayerLimit
          treatyLayerCategory_CommissionRate
          treatyLayerCategory_RetentionLimit
          treatyLayerCategory_PriorityLimit
          treatyLayerCategory_LiabilityLimit
          treatyLayerCategory_AggregateLimit
        }
        GetTreatyReinsurers(TreatyID: $TreatyID) {
          production_TreatyReinsurers_Id
          treatyReinsurersBusinessPartner_FullName
          production_TreatyReinsurers_SharePercentage
          production_TreatyReinsurers_IsLeader
        }
      }
      lookups {
        treatySubType {
          Id
          Code
          Title
          TreatyType
        }
      }
    }
    Production_TreatyType: __type(name: "Production_TreatyType") {
      enumValues {
        name
      }
    }
    PlanConfigManagement_SublineCategoryList: __type(
      name: "PlanConfigManagement_SublineCategoryList"
    ) {
      name
      enumValues {
        name
      }
    }
    Core {
      lookups {
        currencies {
          Id
          Code
          Symbol
          Title
        }
      }
    }
  }
`;

export const activateTreatyMutation = gql`
  mutation activeTreaty($CurrentTreatyID: String!) {
    production {
      actions {
        activateTreaty(CurrentTreatyID: $CurrentTreatyID) {
          id
        }
      }
    }
  }
`;

export const closeTreatyMutation = gql`
  mutation closeTreaty($CurrentTreatyID: String!) {
    production {
      actions {
        closeTreaty(CurrentTreatyID: $CurrentTreatyID) {
          id
        }
      }
    }
  }
`;

export const getTreatyAuditTrailsQuery = gql`
  query getTreatyAuditTrails($TreatyID: String!) {
    Production {
      queries {
        GetTreatyAuditTrails(TreatyID: $TreatyID) {
          production_AuditTrail_Id
          production_AuditTrail_createdOn
          production_AuditTrail_Message
        }
      }
    }
  }
`;
