import { isEmpty } from 'lodash';
import { IAbstractRecord } from '../../../../models';
import { IListingData } from '../../../../models/listing';
import { lookupListAsRecordObject } from '../../../../utils/graph-utils';
import { ITreatiesResponse, ITreatiesTableData } from '.';
import {
  capitalizeFirstCharacter,
  capitalizeFirstLetter,
  formatEnum,
} from '../../../../utils/formatting-utils';

export function toLookupsData(data: IAbstractRecord) {
  const lovs: Record<string, Record<string, string>> = {
    treatyType: {},
    leaderReinsurer: {},
    currencyTitles: {},
    currencySymbols: {},
    status: {},
  };

  const treatyTypes: Record<string, string> = {};
  data?.Production_TreatyType?.enumValues?.forEach(
    (element: { __typename: string; name: string }) => {
      if (element.name) {
        const displayName = capitalizeFirstLetter(
          element.name.replaceAll('_', ' ')
        );
        treatyTypes[element.name] = displayName;
      }
    }
  );
  lovs.treatyType = treatyTypes;

  lovs.leaderReinsurer = lookupListAsRecordObject(
    data?.Production?.queries?.GetMainReinsurers,
    false,
    'mainReinsurerDetails_FullName',
    'mainReinsurerDetails_FullName'
  );

  // generate two lovs for currency (one for title and one for symbol)
  data?.Core?.lookups?.currencies?.forEach(
    (element: { [key: string]: string }) => {
      lovs.currencyTitles[element?.Code] = element?.Title;
      lovs.currencySymbols[element?.Code] = element?.Symbol;
    }
  );

  const statuses: Record<string, string> = {};
  data?.Production_TreatyStatusList?.enumValues?.forEach(
    (element: { __typename: string; name: string }) => {
      if (element.name) {
        const displayName = capitalizeFirstLetter(
          element.name.replaceAll('_', ' ')
        );
        statuses[formatEnum(element.name)] = displayName;
      }
    }
  );

  lovs.status = statuses;

  return lovs;
}

export function mapToListingData(
  data: {
    items: ITreatiesResponse[];
    paging: { pageSize: number; pageNumber: number; totalCount: number };
  },
  treatyTypesLov: IAbstractRecord
): IListingData {
  if (isEmpty(data.items)) {
    return {
      pagedItems: {},
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    };
  }

  const list: Record<string, ITreatiesTableData> = {};

  data.items.forEach((item) => {
    list[item.production_Treaties_Id] = {
      treatyId: item.production_Treaties_Id,
      Production_Treaties_TreatyID: item.production_Treaties_TreatyID,
      Production_Treaties_TreatyName: item.production_Treaties_TreatyName,
      Production_Treaties_TreatyType:
        treatyTypesLov?.[item.production_Treaties_TreatyType],
      Production_Treaties_EffectiveDate:
        item.production_Treaties_EffectiveDate,
      Production_Treaties_ExpiryDate: item.production_Treaties_ExpiryDate,
      Production_Treaties_TreatyCurrency:
        item.production_Treaties_TreatyCurrency.Title,
      MainReinsurerDetails_FullName: item.mainReinsurerDetails_FullName,
      Production_Treaties_TreatyStatus: !isEmpty(
        item.production_Treaties_TreatyStatus
      )
        ? capitalizeFirstCharacter(
            item.production_Treaties_TreatyStatus.toLowerCase()
          )
        : null,
    };
  });

  return {
    pagedItems: list,
    pageSize: data?.paging?.pageSize,
    pageNumber: data?.paging?.pageNumber,
    totalCount: data?.paging?.totalCount,
  };
}
