import { gql } from '@apollo/client';

export function getPolicyCoverEnums() {
  return gql`
    query getPolicyCoverEnums($TreatyID: String) {
      Production {
        queries {
          GetActiveTreaties(TreatyID: $TreatyID) {
            production_Treaties_Id
            production_Treaties_TreatyName
            production_Treaties_TreatyID
          }
        }
      }
      PlanConfigManagement {
        queries {
          allPoliciesCovers {
            planConfigManagement_PolicyCover_Id
            planConfigManagement_PolicyCover_Name
            planConfigManagement_PolicyCover_LineID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                  }
                }
              }
            }
          }
          allLines {
            planConfigManagement_Line_Id
            planConfigManagement_Line_Name
            planConfigManagement_Line_Status
          }
        }
      }
    }
  `;
}

export function checkPolicyCoverExists() {
  return gql`
    query checkPolicyCoverExists(
      $lineId: String!
      $policyCoverId: String
      $name: String
      $externalCode: String
    ) {
      PlanConfigManagement {
        queries {
          checkPolicyCoverExists(
            lineId: $lineId
            name: $name
            externalCode: $externalCode
            policyCoverId: $policyCoverId
          ) {
            planConfigManagement_PolicyCover_Id
            planConfigManagement_PolicyCover_Name
            planConfigManagement_PolicyCover_ExternalCode
            planConfigManagement_PolicyCover_LineID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                    ExternalCode
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function createPolicyCover() {
  return gql`
    mutation createPolicyCover(
      $policyCoverInputs: PlanConfigManagement_PolicyCoverInputs!
    ) {
      planConfigManagement {
        actions {
          createPolicyCover(policyCoverInputs: $policyCoverInputs) {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  Name
                  ExternalCode
                  NameArabic
                  NameFrench
                  PrintingOrder
                  PolicyCoverDescription
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getPolicyCoverInfo() {
  return gql`
    query getPolicyCoverInfo($id: String!) {
      PlanConfigManagement {
        entities {
          policyCover(id: $id) {
            views {
              PlanConfigManagement_all {
                properties {
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                        }
                      }
                    }
                  }
                  Name
                  ExternalCode
                  NameArabic
                  PrintingOrder
                  PolicyCoverDescription
                }
              }
            }
          }
        }
      }
      Production {
        queries {
          CheckIfPolicyCoverTreatyExist(policyCoverId: $id) {
            production_PolicyCovertoTreaty_Id
            treaty_TreatyID
            treaty_Id
          }
        }
      }
    }
  `;
}

export function updatePolicyCover() {
  return gql`
    mutation updatePolicyCover(
      $entityId: String!
      $policyCoverInputs: PlanConfigManagement_PolicyCoverInputs!
    ) {
      planConfigManagement {
        entities {
          policyCover {
            planConfigManagement {
              updatePolicyCover(
                entityId: $entityId
                policyCoverInputs: $policyCoverInputs
              ) {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                      NameArabic
                      NameFrench
                      PrintingOrder
                      PolicyCoverDescription
                      LineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              Name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function checkPolicyCoverPrintingOrderExists() {
  return gql`
    query checkPolicyCoverPrintingOrderExists(
      $printingOrder: Long
      $lineId: String!
      $policyCoverId: String
    ) {
      PlanConfigManagement {
        queries {
          checkPolicyCoverPrintingOrderExists(
            printingOrder: $printingOrder
            lineId: $lineId
            policyCoverId: $policyCoverId
          ) {
            planConfigManagement_PolicyCover_Id
            planConfigManagement_PolicyCover_Name
            planConfigManagement_PolicyCover_PrintingOrder
            planConfigManagement_PolicyCover_LineID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                    ExternalCode
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
