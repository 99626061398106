export const initialCoverValues = {
  initialError: {
    isMain: '',
    isMandatory: '',
    coverPremiumType: '',
    coverPremiumPercentage: '',
    coverPremiumValue: '',
    currency: '',
    coverSumInsured: '',
    sumInsuredIsAdditive: '',
    excessOnClaimType: '',
    excessOnClaimAmount: '',
    excessOnClaimPercentage: '',
    excessOnClaimDays: '',
    coverEffectiveFrom: '',
    coverEffectiveTo: '',
    amdCoverSumInsured: '',
    amdCoverPremiumPercentage: '',
    amdCoverPremiumValue: '',
    amdExcessOnClaimAmount: '',
    amdExcessOnClaimPercentage: '',
    amdExcessOnClaimDays: '',
  },
  initialTouched: {
    isMain: false,
    isMandatory: false,
    coverPremiumType: false,
    coverPremiumPercentage: false,
    coverPremiumValue: false,
    currency: false,
    coverSumInsured: false,
    sumInsuredIsAdditive: false,
    excessOnClaimType: false,
    excessOnClaimAmount: false,
    excessOnClaimPercentage: false,
    excessOnClaimDays: false,
    coverEffectiveFrom: false,
    coverEffectiveTo: false,
    amdCoverSumInsured: false,
    amdCoverPremiumPercentage: false,
    amdCoverPremiumValue: false,
    amdExcessOnClaimAmount: false,
    amdExcessOnClaimPercentage: false,
    amdExcessOnClaimDays: false,
  },
};
