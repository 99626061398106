import { gql } from '@apollo/client';

export function getAdditionalFeesAndChargesEnums() {
  return gql`
    query getAdditionalFeesAndChargesEnums {
      Core_TPATypes: __type(name: "Core_TPATypes") {
        name
        enumValues {
          name
        }
      }
      Core_TPAApplicableOnOptions: __type(name: "Core_TPAApplicableOnOptions") {
        name
        enumValues {
          name
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function updatePlanFees() {
  return gql`
    mutation updatePlanFees(
      $entityId: String!
      $planFeesInputs: PlanConfigManagement_PlanFeesInputs!
    ) {
      planConfigManagement {
        entities {
          plan {
            planConfigManagement {
              updatePlanFees(
                entityId: $entityId
                planFeesInputs: $planFeesInputs
              ) {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      ChargesPercentage
                      MaxTPA
                      MinTPA
                      PolicyCost
                      EndorsementCost
                      TPAFeesAmount
                      TPAFeesApplicableOn
                      TPAFeesPercentage
                      TPAFeesType
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
