import React, { useMemo } from 'react';
import { IFacCoverDetails, IFacDetails, IFacErrors, IFacLovs } from '..';
import { makeStyles } from 'tss-react/mui';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { cloneDeep } from 'lodash';
import SelectFormField from '../../../../../components/form-fields/SelectFormField';
import { initialFacValues } from '../content';
import DatePickerFormField from '../../../../../components/form-fields/DatePickerFormField';
import CurrencyFormField from '../../../../../components/form-fields/CurrencyFormField';
import EnhancedPercentageInput from '../../../../../components/enhanced-form/EnhancedPercentageInput';
import TextInputFormField from '../../../../../components/form-fields/TextInputFormField';
import { extractValidationDetails, getSumOf } from '../utils';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  calculateRemainingAmountsMutation,
  getCoverDetailsQuery,
} from '../queries';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import { getError } from '../../../../../utils/graph-utils';

interface ICoversRepeaterWidget {
  values: IFacDetails;
  updateValues: (values: IFacDetails) => void;
  errors: IFacErrors['covers'];
  updateErrors: (errors: IFacErrors['covers']) => void;
  lovs: IFacLovs;
  isDisabled?: boolean;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    backgroundColor: '#F9F9F9',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    padding: '33px 23px 23px',
    marginBottom: '14px',
    position: 'relative',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(3, 32%)`,
    gap: '0 2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  addBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    margin: '20px auto 0',
    display: 'block',
  },
  removeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    top: '8px',
  },
  clearBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const CoversRepeaterWidget: React.FC<ICoversRepeaterWidget> = ({
  values,
  updateValues,
  errors,
  updateErrors,
  lovs,
  isDisabled,
}) => {
  const { classes } = useStyles();
  const [getCoverDetailsLazy] = useLazyQuery(getCoverDetailsQuery);
  const [calculateRemainingAmounts] = useMutation(
    calculateRemainingAmountsMutation
  );

  const handleFieldChange = async <K extends keyof IFacCoverDetails>(
    newValue: IFacCoverDetails[K],
    fieldName: K,
    index: number
  ) => {
    const newValues = cloneDeep(values);
    newValues.covers[index][fieldName] = newValue;
    if (fieldName === 'coverId') {
      try {
        const [
          { data: coverData, error: coverError },
          { data: remainingAmounts, errors: mutationErrors },
        ] = await Promise.all([
          getCoverDetailsLazy({
            variables: {
              CoverID: newValue,
            },
            errorPolicy: 'all',
          }),
          calculateRemainingAmounts({
            variables: {
              Proposal: values.proposal,
              SelectedCover: newValue,
              EnterCededSumInsured:
                parseFloat(newValues.covers[index].cededSumInsured) || 0,
              EnterCededPremium:
                parseFloat(newValues.covers[index].cededPremium) || 0,
            },
            errorPolicy: 'all',
          }),
        ]);

        if (!coverError && !mutationErrors) {
          const values = extractValidationDetails({
            coverData: coverData,
            remainingAmountsData: remainingAmounts,
          });
          newValues.covers[index].maxCededSumInsured =
            values.maxCededSumInsured;
          newValues.covers[index].maxCededPremium = values.maxCededPremium;
          newValues.covers[index].maxSumInsured = values.maxSumInsured;
          newValues.covers[index].maxPremium = values.maxPremium;
          newValues.covers[index].sumInsuredIsAdditive =
            values.sumInsuredIsAdditive;

          newValues.totalCededSumInsured = getSumOf(
            newValues.covers,
            'cededSumInsured'
          );
        }
      } catch (error) {
        toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
      }
    }
    updateValues(newValues);
  };

  const validateAmount = (
    fieldName: keyof IFacCoverDetails,
    maxFieldName: keyof IFacCoverDetails,
    index: number,
    errorMessage: string
  ) => {
    const newValues = cloneDeep(values);
    const maxValue = newValues.covers[index][maxFieldName];

    return (maxValue || maxValue == 0) &&
      newValues.covers[index][fieldName] > maxValue
      ? errorMessage
      : '';
  };

  const handleFieldBlur = async (
    fieldName: keyof IFacCoverDetails,
    index: number
  ) => {
    const newErrors = cloneDeep(errors);
    const newValues = cloneDeep(values);
    switch (fieldName) {
      case 'cededSumInsured':
        newValues.totalCededSumInsured = getSumOf(
          newValues.covers,
          'cededSumInsured'
        );
        newErrors[index].cededSumInsured = validateAmount(
          fieldName,
          'maxSumInsured',
          index,
          'Cannot be greater than Cover Sum Insured'
        );

        if (!newErrors[index].cededSumInsured) {
          newErrors[index].cededSumInsured = validateAmount(
            fieldName,
            'maxCededSumInsured',
            index,
            'Cannot be greater than remaining sum insured'
          );
        }
        break;
      case 'commissionRate':
        newValues.covers[index].commissionAmount = (
          parseFloat(newValues.covers[index].cededPremium) *
          (Number(newValues.covers[index].commissionRate) / 100)
        ).toString();

        newValues.totalCommission = getSumOf(
          newValues.covers,
          'commissionAmount'
        );
        break;
      case 'cededPremium':
        newValues.covers[index].commissionAmount = (
          parseFloat(newValues.covers[index].cededPremium) *
          (Number(newValues.covers[index].commissionRate) / 100)
        ).toString();

        newValues.totalCommission = getSumOf(
          newValues.covers,
          'commissionAmount'
        );

        newValues.totalCededPremium = getSumOf(
          newValues.covers,
          'cededPremium'
        );

        newErrors[index].cededPremium = validateAmount(
          fieldName,
          'maxPremium',
          index,
          'Cannot be greater than Cover Net Premium'
        );

        if (!newErrors[index].cededPremium) {
          newErrors[index].cededPremium = validateAmount(
            fieldName,
            'maxCededPremium',
            index,
            'Cannot be greater than remaining premium'
          );
        }
        break;
    }

    updateErrors(newErrors);
    updateValues(newValues);
  };

  const handleAddRow = () => {
    const newValues = cloneDeep(values);
    const newCover = cloneDeep(initialFacValues.values.covers[0]);
    newCover.currencyCode = values.covers[0].currencyCode;
    newCover.currencySymbol = values.covers[0].currencySymbol;
    newValues.covers.push(newCover);

    const newErrors = cloneDeep(errors);
    newErrors.push(initialFacValues.errors.covers[0]);

    updateValues(newValues);
    updateErrors(newErrors);
  };

  const handleDeleteRow = (index: number) => {
    const newValues = cloneDeep(values) as IFacDetails;
    newValues.covers.splice(index, 1);

    newValues.totalCededSumInsured = getSumOf(
      newValues.covers,
      'cededSumInsured'
    );
    newValues.totalCommission = getSumOf(newValues.covers, 'commissionAmount');

    newValues.totalCededPremium = getSumOf(newValues.covers, 'cededPremium');

    const newErrors = cloneDeep(errors);
    newErrors.splice(index, 1);

    updateValues(newValues);
    updateErrors(newErrors);
  };

  const selectedCoversIds = useMemo(() => {
    return values.covers.map((cover) => cover.coverId);
  }, [values.covers]);

  return (
    <section>
      {values.covers.map((row, index) => {
        const currentRowErrors = errors[index];
        const coversLov = Object.entries(lovs.covers.name).reduce(
          (acc, [key, value]) => {
            if (key === row.coverId || !selectedCoversIds.includes(key)) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, string>
        );
        return (
          <div key={index} className={classes.container}>
            {!isDisabled && (
              <div className={classes.clearBtn}>
                {values.covers.length > 1 && (
                  <button
                    className={classes.removeBtn}
                    onClick={() => handleDeleteRow(index)}
                  >
                    <ClearIcon fontSize="small" />
                  </button>
                )}
              </div>
            )}
            <div className={classes.fieldRow}>
              <SelectFormField
                title="Cover*"
                name="coverId*"
                placeholder="Select Cover"
                value={row.coverId}
                selectOptions={coversLov}
                onChange={(v) => handleFieldChange(v, 'coverId', index)}
                error={currentRowErrors?.coverId}
                disabled={isDisabled}
              />
              <DatePickerFormField
                title="Cover Effective Date*"
                name="effectiveFrom"
                value={lovs.covers.dates[row.coverId]?.effectiveDate}
                onDateChange={(v) => {
                  handleFieldChange(v.toString(), 'effectiveFrom', index);
                }}
                error={currentRowErrors.effectiveFrom}
                disabled={true}
              />
              <DatePickerFormField
                title="Cover Expiry Date*"
                name="effectiveTo"
                value={lovs.covers.dates[row.coverId]?.expiryDate}
                onDateChange={(v) => {
                  handleFieldChange(v.toString(), 'effectiveTo', index);
                }}
                error={currentRowErrors.effectiveTo}
                disabled={true}
              />
              <TextInputFormField
                title="Cover Currency*"
                name="coverCurrency*"
                placeholder="Cover Currency"
                value={row.currencySymbol}
                onChange={(e) =>
                  handleFieldChange(e.target.value, 'coverId', index)
                }
                error={currentRowErrors?.coverId}
                disabled={true}
              />
              <CurrencyFormField
                title="Ceded Sum Insured*"
                name="cededSumInsured"
                placeholder="Enter Ceded Sum Insured"
                value={row.cededSumInsured}
                onChange={(e) =>
                  handleFieldChange(e.target.value, 'cededSumInsured', index)
                }
                onBlur={() => {
                  handleFieldBlur('cededSumInsured', index);
                }}
                currencySymbol={row.currencySymbol}
                error={currentRowErrors?.cededSumInsured}
                disabled={isDisabled}
              />
              <CurrencyFormField
                title="Ceded Premium*"
                name="cededPremium"
                placeholder="Enter Ceded Premium"
                value={row.cededPremium}
                onChange={(e) =>
                  handleFieldChange(e.target.value, 'cededPremium', index)
                }
                onBlur={() => {
                  handleFieldBlur('cededPremium', index);
                  // check if entered value is greater than ...
                }}
                currencySymbol={row.currencySymbol}
                error={currentRowErrors?.cededPremium}
                disabled={isDisabled}
              />
              <EnhancedPercentageInput
                title="Facultative Rate*"
                name="facultativeRate"
                placeholder="Enter Facultative Rate"
                value={row.facultativeRate}
                onChange={(e) =>
                  handleFieldChange(e.target.value, 'facultativeRate', index)
                }
                onBlur={() => {}}
                error={currentRowErrors?.facultativeRate}
                disabled={isDisabled}
              />
              <EnhancedPercentageInput
                title="Commission Rate*"
                name="commissionRate"
                placeholder="Enter Commission Rate"
                value={row.commissionRate}
                onChange={(e) =>
                  handleFieldChange(e.target.value, 'commissionRate', index)
                }
                onBlur={() => {
                  handleFieldBlur('commissionRate', index);
                }}
                error={currentRowErrors?.commissionRate}
                disabled={isDisabled}
              />
              <CurrencyFormField
                title="Commission Amount*"
                name="commissionAmount"
                placeholder="Commission Amount"
                value={row.commissionAmount}
                onChange={(e) =>
                  handleFieldChange(e.target.value, 'commissionAmount', index)
                }
                currencySymbol={row.currencySymbol}
                error={currentRowErrors?.commissionAmount}
                disabled={true}
              />
            </div>
          </div>
        );
      })}
      {!isDisabled && (
        <button className={classes.addBtn} onClick={handleAddRow}>
          <AddCircleOutlineIcon fontSize="small" color="error" />
        </button>
      )}
    </section>
  );
};

export default CoversRepeaterWidget;
