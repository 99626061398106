import { gql } from '@apollo/client';

export const fetchedPersons = gql`
  query fetchedPersons(
    $searchKeyword: String
    $selectedBusinessPartners: [String]
  ) {
    Production {
      queries {
        GetAssignedPersons(
          searchKeyword: $searchKeyword
          selectedBusinessPartners: $selectedBusinessPartners
        ) {
          salesforceManagement_AssignedPerson_PersonID {
            id
          }
          salesforceManagement_AssignedPerson_FullName
          salesforceManagement_AssignedPerson_PersonCode
        }
      }
    }
  }
`;

export const fetchedPersonAddressList = gql`
  query fetchedPersonAddressList($selectedPersonID: String) {
    SalesforceManagement {
      queries {
        getPersonAddressListWithoutPagination(
          selectedPersonID: $selectedPersonID
        ) {
          salesforceManagement_PersonAddress_Id
          salesforceManagement_PersonAddress_AddressType
          salesforceManagement_PersonAddress_Address {
            Country {
              Title
            }
            City
            Street
            Building
          }
          salesforceManagement_PersonAddress_IsPrimary
        }
      }
    }
  }
`;
