import { IFacultativeDrawerLovs } from '.';
import { IAbstractRecord } from '../../../../models';

export const extractProposalDetails = (data: IAbstractRecord) => {
  if (!data) return {};
  const covers: IFacultativeDrawerLovs['covers'] = {
    name: {},
    dates: {},
  };
  data?.Production?.queries?.getPolicyPlanCovers?.forEach(
    (cover: IAbstractRecord) => {
      covers.name[cover.production_PolicyPlanCover_Id] = cover.policyCover_Name;
      covers.dates[cover.production_PolicyPlanCover_Id] = {
        effectiveDate: cover.production_PolicyPlanCover_CoverEffectiveFrom,
        expiryDate: cover.production_PolicyPlanCover_CoverEffectiveTo,
      };
    }
  );

  const proposal = data.Production.entities.proposal;
  const proposalCurrency =
    proposal.queries.production.pricingOption[0]
      ?.pricingOption_PricingOptionCurrency;

  return {
    company: proposal.views.Production_all.properties.RelatedCompany?.Code,
    currencyCode: proposalCurrency.Code,
    currencySymbol: proposalCurrency.Symbol,
    covers,
  };
};
