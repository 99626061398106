import { isEmpty, isValidNumber } from './validationUtils';
import { isEmpty as lodashIsEmpty } from 'lodash';

export function removeObjectAtIndex<T>(array: T[], index: number): T[] {
  if (index < 0 || index >= array.length) {
    return array;
  }

  return array.filter((_, idx) => idx !== index);
}

export function numberToPercentage(num: number | string): number {
  if (isEmpty(num) || !isValidNumber(num)) {
    return num as any;
  }

  // Convert num to a number if it's a string
  const parsedNum = typeof num === 'string' ? parseFloat(num) : num;

  // Calculate percentage
  const percentage = parsedNum * 100;

  // Format the percentage as a string with 2 decimal places and a percentage sign
  return Number(percentage.toFixed(2));
}

export function convertToTitleCase(input: string): string {
  if (isEmpty(input)) {
    return '';
  }

  // Replace underscores with spaces and split into words
  let words = input.replace(/_/g, ' ').split(' ');

  // Convert each word to lowercase except the first character
  words = words.map((word) => {
    if (word.length === 0) return ''; // Handle empty strings
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Join the words into a single string
  return words.join(' ');
}

export function updateFormField<T, K extends keyof T>(
  obj: T,
  key: K,
  value: T[K]
): T {
  return {
    ...obj,
    [key]: value,
  };
}

export type ValueOf<T> = T[keyof T];
export type KeyOf<T> = keyof T;

export function formattedCurrency(
  value: number | string,
  fixed = 2,
  defaultToZero = false
): string {
  if (isEmpty(value) && !defaultToZero) {
    return '';
  }
  return Number(value).toFixed(fixed);
}

export function getKeyFromValue(
  lov: Record<string, string>,
  value: string
): string | undefined {
  for (const [key, val] of Object.entries(lov)) {
    if (val === value?.toString()) {
      return key; // Return the key if a match is found
    }
  }
  return '';
}

export function sumArray(numbers: number[]): number {
  return numbers.reduce((total, num) => total + num, 0);
}

/**
 *
 * @param value any
 * @returns checks if the value is empty and returns null if it is, otherwise returns the value
 */
export function valueOrNull(value: any): any {
  return lodashIsEmpty(value) ? null : value;
}

export function numberOrNull(value: any): number | null {
  return isValidNumber(value) ? parseFloat(value) : null;
}

export function compareStrings(
  a: string,
  b: string,
  caseSensitive = false
): number {
  if (!caseSensitive) {
    a = a.toLowerCase();
    b = b.toLowerCase();
  }

  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }

  return 0;
}

export enum LineCategory {
  Motor = 'Motor',
  Medical = 'Medical',
  Travel = 'Travel',
  Expat = 'Expat',
  Generic = 'Generic',
}

export enum GenericLines {
  BBB = 'BBB',
  MARINE = 'MARINE',
  MARINE_HULL = 'MARINE_HULL',
  GENERIC = 'GENERIC',
}

const lineCategories: Record<string, LineCategory> = {
  '4': LineCategory.Motor,
  '50': LineCategory.Motor,
  '51': LineCategory.Motor,
  '6': LineCategory.Medical,
  '19': LineCategory.Medical,
  '48': LineCategory.Travel,
  '43': LineCategory.Expat,
};

const genericLines: Record<string, GenericLines> = {
  '12': GenericLines.BBB,
  '3': GenericLines.MARINE,
  '20': GenericLines.MARINE_HULL,
  '33': GenericLines.MARINE_HULL,
};

export function getLineCategory(line: string): LineCategory {
  return lineCategories[line] || LineCategory.Generic;
}

export function getGenericLine(line: string): GenericLines {
  return genericLines[line] || GenericLines.GENERIC;
}

export function hasRisk(line: string) {
  return [
    '5',
    '9',
    '10',
    '13',
    '21',
    '35',
    '11',
    '15',
    '16',
    '25',
    '27',
    '29',
    '30',
    '31',
    '37',
    '38',
    '39',
    '42',
    '46',
  ].includes(line);
}

export function hasProperty(line: string) {
  return ['2', '8', '23', '26'].includes(line);
}
