import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { MAIN_ONE_THEME, mainFontFamilyMedium } from '../../../../constants';
import { IAbstractRecord } from '../../../../models';
import { IListingData } from '../../../../models/listing';
import { useAppSelector } from '../../../../redux/hooks';
import {
  TableSortOrder,
  rowSort,
  getSorting,
} from '../../../../utils/table-utils';
import { EnhancedTableHeader } from '../../../enhanced-table';
import EnhancedTableBody from '../../../enhanced-table/EnhancedTableBody';
import EnhancedTableHead from '../../../enhanced-table/EnhancedTableHead';
import { ITableRowSettings } from '../../../updated-enhanced-table';
import { makeStyles } from 'tss-react/mui';
import { EnhancedTableFilterType, IEnhancedMenuItem } from '../';
import ListingTableCollapsableToolbar from '../with-collapsable-filters/ListingTableCollapsableToolbar';

export interface IListingTableWithCollapsibleFiltersProps {
  name: string;
  entityName?: string;
  headers: Record<string, EnhancedTableHeader>;
  rowSettings?: ITableRowSettings;
  actions: IEnhancedMenuItem[];
  data: IListingData;
  inline?: boolean;
  inlineTitle?: string;
  dropdownActionsNames?: string[];
  defaultOrderByColumn?: string;
  orderByAscendingByDefault?: boolean;
  secondOrderByColumn?: string;
  secondOrderByAscendingByDefault?: boolean;
  disableSelection?: boolean;
  context?: IAbstractRecord;
  loader?: boolean;
  onSelectionChange?: (selectedRows: string[]) => void;
  pageSizeOptions?: number[];
  tableFilters?: Record<string, EnhancedTableFilterType>;
  handleFilterChange?: (
    name: string,
    value: string[] | Date[],
    type: string
  ) => void;
  handleFilterAction?: () => void;
  handleFilterClear?: () => void;
}

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    boxShadow: '0px 0px 6px #C7C7C7',
    margin: '0 auto',
  },
  toolbarPaper: {
    margin: '0 0 1em',
  },
  separator: {
    height: 2,
    margin: 0,
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  header: {
    margin: '0 0 1em',
    display: 'grid',
    gridTemplateAreas: `'title actions'`,
    gridGap: '1em',
  },
  title: {
    display: 'block',
    textAlign: 'left',
    fontSize: MAIN_ONE_THEME.typography.medium.med2.fontSize,
    fontFamily: mainFontFamilyMedium,
    letterSpacing: 0,
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontWeight: 'normal',
    margin: '0 0 17.5px 0',
    gridArea: 'title',
    alignSelf: 'center',
    // textTransform: 'uppercase',
    width: '450px',
  },
  actionsContainer: {
    margin: '0 3px 0 auto',
  },
  action: {
    '& span': {
      margin: '0.5em auto 0',
    },
  },
  button: {
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px!important`,
  },
  heightAndCenter: {
    height: '500px',
    justifyContent: 'center',
  },
}));

const defaultpageSizeOptions = [5, 10, 25];

const ListingTableWithCollapsibleFilters: React.FC<
  IListingTableWithCollapsibleFiltersProps
> = ({
  name,
  headers,
  rowSettings,
  data,
  actions,
  inlineTitle,
  dropdownActionsNames = [],
  defaultOrderByColumn,
  orderByAscendingByDefault,
  secondOrderByColumn,
  secondOrderByAscendingByDefault,
  tableFilters,
  handleFilterClear,
  handleFilterAction,
  handleFilterChange,
  entityName = '',
  disableSelection,
  context,
  loader,
  onSelectionChange = undefined,
  pageSizeOptions = defaultpageSizeOptions,
}) => {
  const { classes } = useStyles();
  const cdnUrl = useAppSelector((state) => state.tenant.cdnUrl);
  const dateFormat = 'MM/DD/YYYY';
  const dataKeys = Object.keys(data.pagedItems);

  const [order, setOrder] = React.useState<TableSortOrder>();
  const [orderBy, setOrderBy] = React.useState<string>();
  const [secondOrder, setSecondOrder] = React.useState<TableSortOrder>();
  const [page, setPage] = React.useState(
    data.pageNumber > 0 ? data.pageNumber - 1 : 0
  );
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(data.pageSize);
  const [selected, setSelected] = React.useState<string[]>([]);
  const currentName = React.useRef<string>();

  useEffect(() => {
    if (currentName.current !== name && defaultOrderByColumn) {
      currentName.current = name;
      setOrder(orderByAscendingByDefault ? 'asc' : 'desc');
      setOrderBy(defaultOrderByColumn);
      if (secondOrderByColumn) {
        setSecondOrder(secondOrderByAscendingByDefault ? 'asc' : 'desc');
      }
    }
  }, [
    defaultOrderByColumn,
    name,
    orderByAscendingByDefault,
    secondOrderByAscendingByDefault,
    secondOrderByColumn,
  ]);

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: string
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(rowName: string, checked: boolean) {
    if (checked) {
      setSelected(dataKeys);
      if (onSelectionChange != undefined) {
        onSelectionChange(selected);
      }

      return;
    }
    setSelected([]);
    if (onSelectionChange != undefined) {
      onSelectionChange([]);
    }
  }

  function handleClick(rowName: string) {
    const selectedIndex = selected.indexOf(rowName);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    if (onSelectionChange != undefined) {
      onSelectionChange(newSelected);
    }
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(Number(+event.target.value));
    setPage(0);
  }

  const isSelected = (key: string) => selected.indexOf(key) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, dataKeys.length - page * rowsPerPage);

  const renderToolbar = () => (
    <ListingTableCollapsableToolbar
      dropdownActionsNames={dropdownActionsNames}
      selected={selected}
      actions={actions}
      data={data.pagedItems}
      cdnUrl={cdnUrl}
      title={inlineTitle}
      handleFilterChange={(name, value, type) =>
        handleFilterChange(name, value, type)
      }
      filters={tableFilters}
      context={context}
      onFilterClear={handleFilterClear}
      onFilterAction={handleFilterAction}
    />
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {renderToolbar()}
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={dataKeys.length}
              headRows={headers}
              disableSelection={disableSelection}
            />
            <EnhancedTableBody
              entityName={entityName}
              emptyRows={emptyRows}
              handleClick={handleClick}
              headers={headers}
              rowSettings={rowSettings}
              isSelected={isSelected}
              rows={rowSort(
                data.pagedItems,
                getSorting(
                  order,
                  orderBy,
                  headers,
                  secondOrder,
                  secondOrderByColumn
                )
              ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
              // currencySymbol={currencySymbol}
              dateFormat={dateFormat}
              // phoneNumberPattern={phoneNumberPattern}
              disableSelection={disableSelection}
              loader={loader}
            />
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={pageSizeOptions || defaultpageSizeOptions}
          component="div"
          count={dataKeys.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage=""
        />
      </Paper>
    </div>
  );
};
export default ListingTableWithCollapsibleFilters;
