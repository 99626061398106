import React, { useState } from 'react';
import { excelHeaders, getHeaders } from '.';
import { IListingData } from '../../../../../../models/listing';
import { downloadExcelFile } from '../../../../../../components/custom/new-download-json-excel-service';
import ListingTableWithPagination from '../../../../../../components/form-fields/table/with-pagination/ListingTableWithPagination';
import {
  ActionTarget,
  IEnhancedMenuItem,
} from '../../../../../../components/form-fields/table';
import {
  getMarineGroupPolicyList,
  getMarineGroupPolicyWithoutPaginationList,
} from '../../queries';
import { useLazyQuery } from '@apollo/client';
import ToastErrorMessage from '../../../../../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { DEFAULT_ERROR_TEXT } from '../../../../../../constants';
import {
  mapToMarinePolicyListData,
  mapToMarinePolicyListDataToExcel,
} from '../../group-utils';
import { enumListAsRecordObject } from '../../../../../../utils/graph-utils';

interface IMarineGroupCertificateTableWidgetProps {
  className?: string;
  entityId: string;
  currenySymbol: string;
  onUpdateSuccess?: () => void;
  onAddCertActionClicked: () => void;
}

export const MarineGroupCertificateTableWidget: React.FC<
  IMarineGroupCertificateTableWidgetProps
> = ({ entityId, currenySymbol, onAddCertActionClicked }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [getData] = useLazyQuery(getMarineGroupPolicyList());
  const [getDownloadData] = useLazyQuery(
    getMarineGroupPolicyWithoutPaginationList()
  );

  const tableAction: IEnhancedMenuItem[] = [
    {
      title: 'Download',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => {
        getDownloadData({
          variables: {
            id: entityId,
            type: 'CERTIFICATE',
          },
        })
          .then((result) => {
            const data = mapToMarinePolicyListDataToExcel(result.data);
            downloadExcelFile({
              data: data,
              filename: 'Policy Certificates',
              headers: excelHeaders(currenySymbol),
            });
          })
          .catch(() => {
            toast.error(
              <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
            );
          });
      },
      target: ActionTarget.Entity,
      rowIndependant: true,
      disabled: false,
      name: 'download',
    },
    {
      title: 'Add Certificates',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => {
        onAddCertActionClicked();
      },
      target: ActionTarget.Entity,
      rowIndependant: true,
      disabled: false,
      name: 'addCertificates',
    },
  ];

  const loadData = async (
    currentPage: number,
    pageSize = tableData.pageSize,
    searchKey?: string,
    order?: string,
    orderBy?: string
  ) => {
    setLoading(true);

    try {
      const result = await getData({
        variables: {
          id: entityId,
          currentPage: currentPage,
          currentPageSize: pageSize,
          KeywordSearch: searchKey,
          OrderBy: orderBy
            ? {
                Attribute: orderBy,
                Descending: order === 'desc',
              }
            : null,
          type: 'CERTIFICATE',
        },
        errorPolicy: 'all',
      });

      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const data = result.data;
      const statuses = enumListAsRecordObject(
        data?.Production_PolicyStatuses?.enumValues
      );

      const newTableData = mapToMarinePolicyListData(data, statuses);
      setTableData({
        ...newTableData,
      });
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (
    page: number,
    searchKey?: string,
    rowsPerPage?: number,
    order?: string,
    orderBy?: string
  ) => {
    await loadData(
      page,
      rowsPerPage || tableData.pageSize,
      searchKey,
      order,
      orderBy
    );
  };

  return (
    <ListingTableWithPagination
      disableSelection
      actions={tableAction}
      name={'table'}
      inlineTitle={'Certificates'}
      inline={true}
      headers={getHeaders(currenySymbol)}
      data={tableData}
      loader={loading}
      onPageChange={handlePageChange}
    />
  );
};
