import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { getTreatyAuditTrailsQuery, getTreatyDetailsQuery } from './queries';
import Loader from '../../../../components/Loader';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT, MAIN_ONE_THEME } from '../../../../constants';
import StaticLayout from '../../../../page-layout/static-layout/StaticLayout';
import LeftPanel from './components/LeftPanel';
import Stepper from './components/Stepper';
import {
  ITreatyDetailsResponse,
  ITreatyLovs,
  ITreatyReinsurerResponse,
  ITreatyDetails,
  ITreatyErrors,
} from '.';
import TreatyDetailsWidget from './components/TreatyDetails/TreatyDetailsWidget';
import {
  convertAuditTrailsToActivities,
  initializeErrors,
  mapToWidgetValues,
  toLookupsData,
  validateForm,
} from './utils';
import { makeStyles } from 'tss-react/mui';
import ReinsurersWidget from './components/TreatyReinsurers/ReinsurersWidget';
import TreatyDocuments from './components/treaty-documents/TreatyDocuments';
import { initialTreatyValues } from './content';
import CustomActivities from '../../../../activities/CustomActivities';
import { IActivityEntityBase } from '../../../../activities';
import { getError } from '../../../../utils/graph-utils';

const useStyles = makeStyles()(() => ({
  mainChildrenContainer: {
    marginTop: '18px',
    gap: '1em',
    display: 'flex',
    alignItems: 'flex-start',
  },
  mainDetailsWidget: {
    width: '66%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
  },
  mainReinsurersWidget: {
    width: '33%',
  },
}));

const TreatyDetailsPage: React.FC = () => {
  const { classes } = useStyles();
  const { id: currentTreatyId } = useParams<{ id: string }>();
  const { refetch, data, error, loading } = useQuery(getTreatyDetailsQuery, {
    variables: {
      TreatyID: currentTreatyId,
    },
    errorPolicy: 'all',
  });
  const [lovs, setLovs] = useState<ITreatyLovs>({
    currency: {},
    treatyType: {},
    treatySubType: {
      proportional: {},
      non_proportional: {},
    },
    category: {},
  });
  const [auditTrailInfo, setAuditTrailInfo] = useState<{
    isCollapsed: boolean;
    title: string;
    items: IActivityEntityBase[];
  }>({
    isCollapsed: true,
    title: '',
    items: [],
  });
  const [shouldSave, setShouldSave] = useState(false);
  const [values, setValues] = useState<ITreatyDetails>(
    initialTreatyValues.values
  );
  const [errors, setErrors] = useState<ITreatyErrors>(
    initialTreatyValues.errors
  );

  const [getTreatyAuditTrails, { loading: auditTrailsLoading }] = useLazyQuery(
    getTreatyAuditTrailsQuery
  );

  useEffect(() => {
    if (data) {
      const lovs = toLookupsData(data);

      const values = mapToWidgetValues(
        data.Production.queries.GetTreatyDetails[0],
        data.Production.queries.GetTreatyLayerAndCategory
      );

      const initialErrors = initializeErrors(values);

      setErrors(initialErrors);
      setValues(values);
      setLovs(lovs);
    }
  }, [data]);

  const getAuditTrail = async () => {
    try {
      setAuditTrailInfo({
        isCollapsed: false,
        items: [],
        title: values.treatyName,
      });

      const result = await getTreatyAuditTrails({
        variables: {
          TreatyID: currentTreatyId,
        },
      });

      if (result.data) {
        setAuditTrailInfo({
          isCollapsed: false,
          items: convertAuditTrailsToActivities(result.data),
          title: values.treatyName,
        });
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
    }
  };

  const handleFormValidation = () => {
    const { isValid, errors: newErrors } = validateForm(values, errors);

    if (!isValid) {
      toast.error(<ToastErrorMessage>Incomplete Form</ToastErrorMessage>);
    }

    setErrors(newErrors);
    return isValid;
  };

  const renderMainChildren = (data: {
    GetTreatyDetails: ITreatyDetailsResponse[];
    GetTreatyReinsurers: ITreatyReinsurerResponse[];
  }) => {
    const isDisabled =
      data?.GetTreatyDetails[0]?.production_Treaties_TreatyStatus?.toLowerCase() !==
      'new';

    return (
      <>
        <Stepper
          isButtonDisabled={shouldSave}
          status={data?.GetTreatyDetails[0]?.production_Treaties_TreatyStatus}
          currentTreatyId={currentTreatyId}
          validate={handleFormValidation}
          onSuccess={async () => await refetch()}
        />
        <div className={classes.mainChildrenContainer}>
          <div className={classes.mainDetailsWidget}>
            <TreatyDetailsWidget
              currentTreatyId={currentTreatyId}
              isDisabled={isDisabled}
              values={values}
              updateValues={(allValues) => setValues(allValues)}
              errors={errors}
              updateErrors={(allErrors) => setErrors(allErrors)}
              lovs={lovs}
              shouldSave={shouldSave}
              setShouldSave={(v) => setShouldSave(v)}
              validateForm={handleFormValidation}
            />
            <TreatyDocuments />
          </div>
          <div className={classes.mainReinsurersWidget}>
            <ReinsurersWidget
              isButtonDisabled={isDisabled}
              reinsurersList={data?.GetTreatyReinsurers}
              onSuccess={async () => await refetch()}
            />
          </div>
        </div>
      </>
    );
  };

  if (error) {
    toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name="Treaty Details"
      loading={loading}
      mainChildren={renderMainChildren(data?.Production?.queries)}
      leftChildren={
        <LeftPanel data={data?.Production?.queries?.GetTreatyDetails[0]} />
      }
      rightChildren={
        <CustomActivities
          loader={auditTrailsLoading}
          title={auditTrailInfo.title}
          items={auditTrailInfo.items}
        />
      }
      onRightCollapseClick={(isOpen: boolean) => {
        if (isOpen) {
          setAuditTrailInfo({
            isCollapsed: true,
            items: [],
            title: '',
          });
        } else {
          getAuditTrail();
        }
      }}
      config={{
        leftColumn: {
          backgroundColor: '#FFFFFF',
          collapsable: false,
          collapsed: false,
          width: 2,
        },
        mainColumn: {
          backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
          collapsable: false,
          collapsed: false,
          width: auditTrailInfo.isCollapsed ? 9.9 : 7,
        },
        rightColumn: {
          backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
          collapsable: true,
          collapsed: auditTrailInfo.isCollapsed,
          width: auditTrailInfo.isCollapsed ? 0.1 : 3,
        },
      }}
    />
  );
};

export default TreatyDetailsPage;
