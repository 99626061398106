import { cloneDeep, isArray, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { IAmendmentPageState, IPayerData } from '../..';
import { ILineAmendmentProps } from '../../line-amendment';
import { fetchedPersonAddressList, fetchedPersons } from './queries';
import { isGuid } from '../../../../../utils/validationUtils';
import { validatePolicyPayerField } from './validation';
import ProposalPolicyPersonDrawer from '../../../../../forms/proposal-policy-person-drawer/ProposalPolicyPersonDrawer';
import PersonAddressDrawer from '../../../../../forms/person-address-drawer/PersonAddressDrawer';
import NewChipsInput from '../../../../../components/enhanced-form/NewChipsInput';
import EnhancedSwitch from '../../../../../components/enhanced-form/EnhancedSwitch';
import { extractPersonsAddressesFixed, extractPersonsFixed } from './utils';

interface IPayerWidgetProps {
  generalData: ILineAmendmentProps['generalData'];
  pageState: IAmendmentPageState;
  onPageStateUpdate: (pageState: IAmendmentPageState) => void;
  isDisabled: boolean;
}

interface IPayerLovs {
  payerId: Record<string, string>;
  payerAddresses: Record<string, string>;
}

const useStyles = makeStyles()(() => ({
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(2, 49%)`,
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'ce',
  },
  field: {
    width: '100%',
    marginRight: '25px !important',
    minWidth: '195px',
  },
  labelHeader: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
}));

const PayerWidget: React.FC<IPayerWidgetProps> = ({
  generalData,
  pageState,
  onPageStateUpdate,
  isDisabled,
}) => {
  const { id: currentAmendmentID } = useParams<{ id: string }>();
  const { classes } = useStyles();

  const [getPersonAddressListLazy] = useLazyQuery(fetchedPersonAddressList);

  const [newPersonDrawerOpen, setNewPersonDrawerOpen] =
    useState<boolean>(false);
  const [newAddressDrawerOpen, setNewAddressDrawerOpen] =
    useState<boolean>(false);
  const [currentSection, setCurrentSection] = useState<string | null>(null);

  const [lists, setLists] = useState<IPayerLovs>({
    payerId: {},
    payerAddresses: {},
  });

  const values = pageState.values.policyPersons.payer;
  const errors = pageState.errors.policyPersons.payer;
  const touched = pageState.touched.policyPersons.payer;

  const [fetchedPersonsLazy] = useLazyQuery(fetchedPersons);

  const handleOpenDrawer = (section: string) => {
    setCurrentSection(section);
    setNewPersonDrawerOpen(true);
  };

  const handleOpenAddressDrawer = () => {
    setNewAddressDrawerOpen(true);
  };

  const handlePayerSearch = async (
    inputValue: string
  ): Promise<Record<string, string>> => {
    if (inputValue?.length > 3) {
      const newData = await fetchedPersonsLazy({
        variables: {
          searchKeyword: inputValue,
          selectedBusinessPartners: [generalData?.primaryBPID],
        },
      });

      return extractPersonsFixed(newData.data);
    }

    return {};
  };

  const initialize = async () => {
    const addressList: Record<string, string> = {};
    addressList[values.payerAddress] = values.addressDetails;
    setLists({
      payerId: {},
      payerAddresses: addressList,
    });
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePayerAddresses = async (personId: string) => {
    const result = await getPersonAddressListLazy({
      variables: {
        selectedPersonID: personId,
      },
      errorPolicy: 'all',
    });

    return extractPersonsAddressesFixed(result.data);
  };

  const onFieldBlur = (fieldName: string) => {
    const newPageState = cloneDeep(pageState);
    newPageState.touched.policyPersons.payer[fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = async (
    fieldName: keyof IPayerData,
    value: string | boolean,
    touched = false,
    updatedList: IPayerLovs = lists
  ) => {
    const newPageState = cloneDeep(pageState);
    const newLists = cloneDeep(updatedList);
    if (fieldName === 'payerId') {
      const mainValue: string = isArray(value) ? value[0] : value;
      if (isEmpty(mainValue) || isGuid(mainValue)) {
        newPageState.values.policyPersons.payer[fieldName] = mainValue;
        newPageState.errors.policyPersons.payer[fieldName] =
          validatePolicyPayerField(fieldName, mainValue);
        if (touched) {
          newPageState.touched.policyPersons.payer[fieldName] = true;
        }

        if (isGuid(mainValue)) {
          const result2 = (await handlePayerAddresses(mainValue)) as Record<
            string,
            { address: string; isPrimary: boolean }
          >;
          const payerAddresses = result2;
          const extractedAddresses: Record<string, string> = {};
          Object.keys(payerAddresses).forEach((key) => {
            extractedAddresses[key] = payerAddresses[key].address;
            if (payerAddresses[key].isPrimary) {
              newPageState.values.policyPersons.payer.payerAddress = key;
            }
          });
          newPageState.errors.policyPersons.payer.payerAddress = '';
          newLists.payerAddresses = extractedAddresses;
        }
      } else {
        newLists.payerId = await handlePayerSearch(value as string);
      }
    } else {
      (newPageState.values.policyPersons.payer as any)[fieldName] = value;
      newPageState.errors.policyPersons.payer[fieldName] =
        validatePolicyPayerField(fieldName, value);
      if (touched) {
        newPageState.touched.policyPersons.payer[fieldName] = true;
      }
    }
    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onFieldSearch = async (fieldName: string, value: string) => {
    const newLists = cloneDeep(lists);
    if (fieldName === 'payerId') {
      newLists['payerId'] = await handlePayerSearch(value);
    }
    setLists(newLists);
  };

  const handleDrawerSubmitSuccess = (
    personId: string,
    newPersonFullName: string
  ) => {
    const newPersonList: Record<string, string> = {};
    newPersonList[personId] = newPersonFullName;
    onFieldUpdate('payerId', personId, false, {
      payerId: newPersonList,
      payerAddresses: lists.payerAddresses,
    });
    setNewPersonDrawerOpen(false);
  };

  const handleAddressSubmitSuccess = (
    address: string,
    addressFullName: string
  ) => {
    onFieldUpdate('payerAddress', address, false, {
      payerId: lists.payerId,
      payerAddresses: {
        ...lists.payerAddresses,
        [address]: addressFullName,
      },
    });
    setNewAddressDrawerOpen(false);
  };

  const getDefaultPersonListIfEmpty = (
    list: Record<string, string>,
    payer: IPayerData
  ) => {
    if (Object.keys(list || {}).length > 0 || !payer.fullName) {
      return list;
    } else {
      const newList: Record<string, string> = {
        [payer.payerId]: payer.fullName,
        [payer.payerAddress]: payer.addressDetails,
      };
      return newList;
    }
  };

  return (
    <div>
      <div className={classes.fieldRow}>
        {newPersonDrawerOpen && currentSection === 'payer' && (
          <ProposalPolicyPersonDrawer
            open={newPersonDrawerOpen}
            onClose={() => setNewPersonDrawerOpen(false)}
            section={currentSection}
            successRoute="/production/amendment/"
            proposalId={currentAmendmentID}
            lineExternalCode={generalData?.lineExternalCode}
            onSubmitSuccess={handleDrawerSubmitSuccess}
          />
        )}

        {newAddressDrawerOpen && (
          <PersonAddressDrawer
            open={newAddressDrawerOpen}
            onClose={() => setNewAddressDrawerOpen(false)}
            personId={values.payerId}
            onSubmitSuccess={handleAddressSubmitSuccess}
          />
        )}

        <NewChipsInput
          key="payerId"
          name="payerId"
          title="Payer"
          placeholder="Payer"
          value={values.payerId}
          error={touched.payerId ? errors.payerId : ''}
          onChange={(v) => {
            const value = isArray(v) ? v[0] : v;
            onFieldUpdate('payerId', value);
          }}
          onSearch={async (v) => {
            const value = isArray(v) ? v[0] : v;
            onFieldSearch('payerId', value);
          }}
          disabled={isDisabled}
          items={getDefaultPersonListIfEmpty(lists?.payerId, values)}
          required
          material
          supportLink={isDisabled ? '' : 'Add Person'}
          onLinkClick={(e) => {
            e.preventDefault();
            handleOpenDrawer('payer');
          }}
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <NewChipsInput
          key="payerAddress"
          name="payerAddress"
          title="Payer Address"
          placeholder="Payer Address"
          value={values.payerAddress}
          error={touched.payerAddress ? errors.payerAddress : ''}
          onChange={(v) => {
            onFieldUpdate('payerAddress', v as string);
          }}
          disabled={isDisabled}
          items={getDefaultPersonListIfEmpty(lists.payerAddresses, values)}
          required
          material
          supportLink={isDisabled ? '' : 'Add Address'}
          onLinkClick={(e) => {
            e.preventDefault();
            handleOpenAddressDrawer();
          }}
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />
      </div>

      <div style={{ width: '235px' }}>
        <EnhancedSwitch
          key="ownerIsDifferent"
          name="ownerIsDifferent"
          title="Owner is different from Payer"
          value={values.ownerIsDifferent}
          error={touched.ownerIsDifferent ? errors.ownerIsDifferent : ''}
          onBlur={() => onFieldBlur('ownerIsDifferent')}
          onChange={(name, value) => {
            onFieldUpdate('ownerIsDifferent', value, true);
          }}
          className={classes.field}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default PayerWidget;
