import React, { useCallback, useEffect, useState } from 'react';
import StaticLayout from '../../../../page-layout/static-layout/StaticLayout';
import LeftPanel from './components/LeftPanel';
import Stepper from './components/Stepper';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import FacultativeDetailsWidget from './components/FacultativeDetailsWidget';
import { initialFacValues } from './content';
import { IFacDetails, IFacErrors, IFacLovs } from '.';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  calculateRemainingAmountsMutation,
  getCoverDetailsQuery,
  getFacAuditTrailsQuery,
  getFacDetailsQuery,
  getProposalDetailsQuery,
} from './queries';
import {
  convertAuditTrailsToActivities,
  extractValidationDetails,
  getCoversDetails,
  initializeErrors,
  mapDataToFacDetails,
  validateForm,
} from './utils';
import {
  getError,
  lookupListAsRecordObject,
} from '../../../../utils/graph-utils';
import FacultativeReinsurerWidget from './components/FacultativeReinsurerWidget';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../components/ToastErrorMessage';
import FacultativeDocumentsWidget from './components/FacultativeDocumentsWidget';
import CustomActivities from '../../../../activities/CustomActivities';
import { MAIN_ONE_THEME } from '../../../../constants';
import { IActivityEntityBase } from '../../../../activities';

const useStyles = makeStyles()(() => ({
  mainChildrenContainer: {
    marginTop: '18px',
    gap: '1em',
    display: 'flex',
    alignItems: 'flex-start',
  },
  mainDetailsWidget: {
    width: '66%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
  },
  mainReinsurersWidget: {
    width: '33%',
  },
}));

const FacultativeDetailsPage: React.FC = () => {
  const { classes } = useStyles();
  const { id: currentFacId } = useParams<{ id: string }>();
  const [getProposalCoversLazy] = useLazyQuery(getProposalDetailsQuery);
  const [getCoverDetailsLazy] = useLazyQuery(getCoverDetailsQuery);
  const [calculateRemainingAmounts] = useMutation(
    calculateRemainingAmountsMutation
  );
  const { refetch, data, loading, error } = useQuery(getFacDetailsQuery, {
    variables: { FacultativeID: currentFacId },
  });
  const [getFacAuditTrails, { loading: auditTrailsLoading }] = useLazyQuery(
    getFacAuditTrailsQuery
  );

  if (error) {
    toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
  }

  const [values, setValues] = useState<IFacDetails>(initialFacValues.values);
  const [errors, setErrors] = useState<IFacErrors>(initialFacValues.errors);
  const [lovs, setLovs] = useState<IFacLovs>({
    proposals: {},
    covers: {
      name: {},
      dates: {},
    },
    currency: {
      code: '',
      symbol: '',
    },
  });
  const [auditTrailInfo, setAuditTrailInfo] = useState<{
    isCollapsed: boolean;
    title: string;
    items: IActivityEntityBase[];
  }>({
    isCollapsed: true,
    title: '',
    items: [],
  });

  const [shouldSave, setShouldSave] = useState<boolean>(false);

  const handleFormValidation = (): boolean => {
    const { isValid, errors: newErrors } = validateForm(values, errors);

    if (!isValid) {
      toast.error(<ToastErrorMessage>Incomplete Form</ToastErrorMessage>);
    }

    setErrors(newErrors);
    return isValid;
  };

  const getAuditTrail = async () => {
    try {
      setAuditTrailInfo({
        isCollapsed: false,
        items: [],
        title: values.facId,
      });

      const result = await getFacAuditTrails({
        variables: {
          FacultativesID: currentFacId,
        },
      });

      if (result.data) {
        setAuditTrailInfo({
          isCollapsed: false,
          items: convertAuditTrailsToActivities(result.data),
          title: values.facId,
        });
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
    }
  };

  const getProposalDetails = useCallback(
    async (
      proposalId: string
    ): Promise<{
      currencySymbol: string;
      currencyCode: string;
      covers: IFacLovs['covers'];
    }> => {
      try {
        const { data, error } = await getProposalCoversLazy({
          variables: {
            proposalId: proposalId,
          },
        });
        if (!error) {
          return getCoversDetails(data);
        }
        return {
          currencyCode: lovs.currency.code || '',
          currencySymbol: lovs.currency.symbol || '',
          covers: {
            name: {},
            dates: {},
          },
        };
      } catch (error) {
        toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
        return {
          currencyCode: lovs.currency.code || '',
          currencySymbol: lovs.currency.symbol || '',
          covers: {
            name: {},
            dates: {},
          },
        };
      }
    },
    [getProposalCoversLazy, lovs.currency]
  );

  const initialize = async () => {
    if (data) {
      try {
        const mappedData = mapDataToFacDetails(
          data?.Production?.queries?.GetFacDetails
        );

        const proposals = lookupListAsRecordObject(
          data?.Production?.queries?.GetActiveProposals,
          false,
          'production_Proposal_Id',
          'production_Proposal_ProposalName'
        );

        const { currencyCode, currencySymbol, covers } =
          await getProposalDetails(mappedData.proposal);

        await Promise.all(
          mappedData.covers.map(async (cover) => {
            const { data, error } = await getCoverDetailsLazy({
              variables: { CoverID: cover.coverId },
              errorPolicy: 'all',
            });

            const { data: remainingAmounts, errors: mutationErrors } =
              await calculateRemainingAmounts({
                variables: {
                  Proposal: mappedData.proposal,
                  SelectedCover: cover.coverId,
                  EnterCededSumInsured: parseFloat(cover.cededSumInsured) || 0,
                  EnterCededPremium: parseFloat(cover.cededPremium) || 0,
                },
                errorPolicy: 'all',
              });

            if (!error && !mutationErrors) {
              const values = extractValidationDetails({
                coverData: data,
                remainingAmountsData: remainingAmounts,
              });

              cover.maxCededSumInsured = values.maxCededSumInsured;
              cover.maxCededPremium = values.maxCededPremium;
              cover.maxSumInsured = values.maxSumInsured;
              cover.maxPremium = values.maxPremium;
              return;
            }
            return null;
          })
        );

        const initialErrors = initializeErrors(mappedData);

        setLovs({
          proposals,
          covers,
          currency: {
            code: currencyCode,
            symbol: currencySymbol,
          },
        });
        setErrors(initialErrors);
        setValues(mappedData);
      } catch (error) {
        toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
      }
    }
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderMainChildren = () => {
    if (!data) return <></>;

    const isDisabled = values.status.toLowerCase() !== 'new';
    const reinsurer = data?.Production?.queries?.GetFacReinsurers?.[0];

    return (
      <>
        <Stepper
          isButtonDisabled={shouldSave}
          status={values.status}
          currentFacId={currentFacId}
          validate={handleFormValidation}
          onSuccess={async () => await refetch()}
        />
        <div className={classes.mainChildrenContainer}>
          <div className={classes.mainDetailsWidget}>
            <FacultativeDetailsWidget
              currentFacId={currentFacId}
              isDisabled={isDisabled}
              values={values}
              updateValues={(allValues) => setValues(allValues)}
              errors={errors}
              updateErrors={(allErrors) => setErrors(allErrors)}
              lovs={lovs}
              updateLovs={(allLovs) => setLovs(allLovs)}
              shouldSave={shouldSave}
              setShouldSave={(v) => setShouldSave(v)}
              validateForm={handleFormValidation}
              onSuccess={async () => await refetch()}
              getProposalDetails={getProposalDetails}
            />
            <FacultativeDocumentsWidget />
          </div>
          <div className={classes.mainReinsurersWidget}>
            <FacultativeReinsurerWidget
              isButtonDisabled={isDisabled}
              reinsurer={{
                reinsurerId: reinsurer?.facultativeReinsurersBusinessPartner_Id,
                name: reinsurer?.facultativeReinsurersBusinessPartner_FullName,
                share:
                  reinsurer?.production_FacultativeReinsurers_SharePercentage,
                companyId:
                  reinsurer?.facultativeReinsurersBusinessPartner_RelatedCompany
                    ?.Code,
              }}
              onSuccess={async () => await refetch()}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <StaticLayout
      name="Facultative Details"
      loading={loading}
      mainChildren={renderMainChildren()}
      leftChildren={
        <LeftPanel
          data={{
            title: values.facId,
            createdBy: values.createdBy,
            createdOn: values.createdOn,
            modifiedBy: values.modifiedBy,
            modifiedOn: values.modifiedOn,
          }}
        />
      }
      rightChildren={
        <CustomActivities
          loader={auditTrailsLoading}
          title={auditTrailInfo.title}
          items={auditTrailInfo.items}
        />
      }
      onRightCollapseClick={(isOpen: boolean) => {
        if (isOpen) {
          setAuditTrailInfo({
            isCollapsed: true,
            items: [],
            title: '',
          });
        } else {
          getAuditTrail();
        }
      }}
      config={{
        leftColumn: {
          backgroundColor: '#FFFFFF',
          collapsable: false,
          collapsed: false,
          width: 2,
        },
        mainColumn: {
          backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
          collapsable: false,
          collapsed: false,
          width: auditTrailInfo.isCollapsed ? 9.9 : 7,
        },
        rightColumn: {
          backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
          collapsable: true,
          collapsed: auditTrailInfo.isCollapsed,
          width: auditTrailInfo.isCollapsed ? 0.1 : 3,
        },
      }}
    />
  );
};

export default FacultativeDetailsPage;
