import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../components/enhanced-table';
import { ITabsLayout, LayoutType } from '../../../../page-layout';

export const headers: Record<string, EnhancedTableHeader> = {
  PolicyNumber: {
    name: 'PolicyNumber',
    title: 'Policy #',
    type: EnhancedTableHeaderType.Link,
    urlTemplate: '/production/policies/{Id}',
    openUrlInNewTab: false,
  },
  TotalPremium: {
    name: 'TotalPremium',
    title: 'Total Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  AmountReceived: {
    name: 'AmountReceived',
    title: 'Amount Received',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  TotalCommission: {
    name: 'TotalCommission',
    title: 'Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  TaxOnCommission: {
    name: 'TaxOnCommission',
    title: 'Tax On Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  AmountPaid: {
    name: 'AmountPaid',
    title: 'Amount Paid',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  OutstandingPayable: {
    name: 'OutstandingPayable',
    title: 'Total Balance',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
};

export const receivableBillsHeaders: Record<string, EnhancedTableHeader> = {
  BillType: {
    name: 'BillType',
    title: 'Bill Type',
    type: EnhancedTableHeaderType.Text,
  },
  BillNumber: {
    name: 'BillNumber',
    title: 'Bill #',
    type: EnhancedTableHeaderType.Text,
  },
  DueDate: {
    name: 'DueDate',
    title: 'Due Date',
    type: EnhancedTableHeaderType.Date,
  },
  NetPremium: {
    name: 'NetPremium',
    title: 'Net Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  TotalPremium: {
    name: 'TotalPremium',
    title: 'Total Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  AmountDue: {
    name: 'AmountDue',
    title: 'Amount Due',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  AmountReceived: {
    name: 'AmountReceived',
    title: 'Amount Received',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  OutstandingBalance: {
    name: 'OutstandingBalance',
    title: 'Outstanding Amount',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  BillStatus: {
    name: 'BillStatus',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
};

export const payableBillsHeaders: Record<string, EnhancedTableHeader> = {
  BillType: {
    name: 'BillType',
    title: 'Bill Type',
    type: EnhancedTableHeaderType.Text,
  },
  BillNumber: {
    name: 'BillNumber',
    title: 'Bill #',
    type: EnhancedTableHeaderType.Text,
  },
  DueDate: {
    name: 'DueDate',
    title: 'Due Date',
    type: EnhancedTableHeaderType.Date,
  },
  Discount: {
    name: 'Discount',
    title: 'Discount',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  TotalCommission: {
    name: 'TotalCommission',
    title: 'Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  TaxOnCommission: {
    name: 'TaxOnCommission',
    title: 'Tax On Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  AmountReceived: {
    name: 'AmountReceived',
    title: 'Amount Paid',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  OutstandingBalance: {
    name: 'OutstandingBalance',
    title: 'Outstanding Amount',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  BillStatus: {
    name: 'BillStatus',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
};

export const RPTabs: ITabsLayout = {
  typeLogicalId: LayoutType.tabslayout,
  leftColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 2,
    collapsed: false,
  },
  mainColumn: {
    backgroundColor: '#F7F7F7',
    collapsable: false,
    width: 10,
    collapsed: false,
  },
  rightColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 0,
    collapsed: false,
  },
  tabs: [
    {
      name: 'receivables',
      title: 'Receivables',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
    {
      name: 'payables',
      title: 'Payables',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
  ],
};

export const unallocatedTableHeaders: Record<string, EnhancedTableHeader> = {
  reference: {
    name: 'reference',
    title: 'Reference',
    type: EnhancedTableHeaderType.Text,
  },
  amount: {
    name: 'amount',
    title: 'Amount',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  amountOutstanding: {
    name: 'amountOutstanding',
    title: 'Amount Outstanding',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  status: {
    name: 'status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  modifiedBy: {
    name: 'modifiedBy',
    title: 'Modified By',
    type: EnhancedTableHeaderType.Text,
  },
};
