import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import {
  contentFontFamilyBold,
  MAIN_ONE_THEME,
} from '../../../../../constants';
import { ILineAmendmentProps } from '../../line-amendment';
import { confirmAmendmentMutation, getClausesListQuery } from './queries';
import { IClauseOption } from '.';
import { isEmpty } from '../../../../../utils/validationUtils';
import ToastSuccessMessage from '../../../../../components/ToastSuccessMessage';
import {
  downloadFileFromUrl,
  generateDownloadLink,
} from '../../../../../utils/file-utils';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import { getError } from '../../../../../utils/graph-utils';
import EnhancedDialog from '../../../../../components/common/EnhancedDialog';
import Loader from '../../../../../components/Loader';
import SelectFormField from '../../../../../components/form-fields/SelectFormField';
import EnhancedTinyMCEEditor from '../../../../../components/enhanced-form/EnhancedTinyMCEEditor';
import EnhancedButton from '../../../../../components/form-fields/buttons/EnhancedButton';
import { extractClauseOptions, getFileDetailsList } from './utils';
import { LineCategory } from '../../../../../utils/helper-utils';

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    minHeight: '529px',
  },
  body: {
    width: '100%',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
  loader: {
    width: '80px',
    height: '80px',
  },
  loaderContainer: {
    textAlign: 'center',
  },
  main: {
    width: '100%',
    minHeight: '500px',
    padding: '35px 25px 25px',
  },
  title: {
    display: 'block',
    minWidth: 'min-content',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    fontFamily: contentFontFamilyBold,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg4.fontSize}px !important`,
  },
  textContainer: {
    width: '100%',
  },
  field: {
    width: '100%',
    margin: '0 0 20px',
  },
  buttonsContainer: {
    width: '95%',
    margin: '10px auto 0',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '15px',
  },
}));

interface IPreviewClauseModalProps {
  generalData: ILineAmendmentProps['generalData'];
  onClose?: () => void;
  onSuccess?: () => void;
}

interface IPageState {
  values: {
    clauseId: string;
    text: string;
  };
  errors: Record<string, string>;
  touched: Record<string, boolean>;
}

const PreviewClauseModal: React.FC<IPreviewClauseModalProps> = ({
  generalData,
  onClose = () => undefined,
  onSuccess,
}) => {
  const currentStatus = generalData.amendmentStatus;
  const lineId = generalData.lineId;
  const { classes } = useStyles();

  const [loader, setLoader] = useState<boolean>(true);

  const [clauseListQuery] = useLazyQuery(getClausesListQuery);

  const [clauses, setClauses] = useState<IClauseOption[]>([]);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitAction] = useMutation(confirmAmendmentMutation);

  const [pageState, setPageState] = useState<IPageState>({
    values: {
      clauseId: '',
      text: generalData.clauseText,
    },
    errors: {},
    touched: {},
  });

  const clausesList = useMemo(() => {
    const result: Record<string, string> = {};
    clauses.forEach((c) => {
      result[c.id] = `${c.externalCode} - ${c.name}`;
    });
    return result;
  }, [clauses]);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = async () => {
    const data = await clauseListQuery({
      variables: {
        pagination: {
          pageNumber: 1,
          pageSize: 1000,
        },
        selectedLineIds: lineId,
      },
      errorPolicy: 'all',
    });

    setClauses(extractClauseOptions(data));
    setLoader(false);
  };

  const onFieldUpdate = (
    fieldName: keyof IPageState['values'],
    value: string
  ) => {
    const newPageState = cloneDeep(pageState);
    newPageState.values[fieldName] = value;
    newPageState.errors[fieldName] = validateField(fieldName, value);
    setPageState(newPageState);
  };

  const onFieldBlur = (fieldName: string) => {
    const newPageState = cloneDeep(pageState);
    newPageState.touched[fieldName] = true;
    setPageState(newPageState);
  };

  const validateField = (
    fieldName: keyof IPageState['values'],
    value: string
  ) => {
    if (fieldName === 'text') {
      if (isEmpty(value)) {
        return 'Required';
      }
    }
    return '';
  };

  const validateForm = () => {
    const newPageState = cloneDeep(pageState);
    setPageState(newPageState);
    newPageState.errors = {
      clauseId: validateField('clauseId', pageState.values.clauseId),
      text: validateField('text', pageState.values.text),
    };

    newPageState.touched = {
      clauseId: true,
      text: true,
    };

    setPageState(newPageState);

    return Object.values(newPageState.errors).every((e) => isEmpty(e));
  };

  const submit = async () => {
    const isValid = validateForm();

    if (isValid) {
      if (!submitting) {
        setSubmitting(true);
        const result = await submitAction({
          variables: {
            selectedAmendmentID: generalData.currentAmendmentId,
            selectedPolicyVersionID: generalData.versionId,
            selectedPolicyID: generalData.policyId,
            clauseText: generalData.clauseText,
          },
          errorPolicy: 'all',
        });

        if (isEmpty(result.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {'Policy successfully issued.'}
            </ToastSuccessMessage>
          );

          if (
            [LineCategory.Motor, LineCategory.Expat].includes(
              generalData.lineCategory
            )
          ) {
            const filesInfo = getFileDetailsList(result.data);
            for (const fileInfo of filesInfo) {
              const downloadLink = generateDownloadLink(
                fileInfo.fileId,
                fileInfo.EntityTypeId,
                fileInfo.EntityId,
                fileInfo.EntityViewId,
                fileInfo.PropertyId
              );
              await downloadFileFromUrl(downloadLink, fileInfo.fileName);
            }
          }

          onSuccess();
        } else {
          setSubmitting(false);
          toast.error(
            <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
          );
        }
      }
    }
  };

  const currentClause = clauses.find((c) => c.id === pageState.values.clauseId);

  return (
    <EnhancedDialog onClose={onClose} open={true} className={classes.container}>
      <div className={classes.body}>
        {loader ? (
          <div className={classes.loaderContainer}>
            <Loader className={classes.loader} />
          </div>
        ) : (
          <div className={classes.main}>
            <h2 className={classes.title}>Preview Clause</h2>

            <div className={classes.textContainer}>
              <SelectFormField
                name="clauses"
                title="Insert Clause"
                value={pageState.values.clauseId || ''}
                onChange={(e) => {
                  onFieldUpdate('clauseId', e);
                }}
                onBlur={() => {
                  onFieldBlur('clauseId');
                }}
                disabled={
                  submitting ||
                  !(
                    generalData.businessUser.allowClauseEdits &&
                    generalData.planClauseEditable
                  )
                }
                selectOptions={clausesList}
                error={pageState.errors.clauseId}
              />
            </div>

            <EnhancedTinyMCEEditor
              name="text"
              title="Description*"
              value={pageState.values.text || ''}
              onChange={(_, v) => {
                onFieldUpdate('text', v);
              }}
              onBlur={() => {
                onFieldBlur('text');
              }}
              htmlToAdd={currentClause?.description}
              error={pageState.touched.text ? pageState.errors.text : ''}
              disabled={
                submitting ||
                !(
                  generalData.businessUser.allowClauseEdits &&
                  generalData.planClauseEditable
                )
              }
              className={classes.field}
              placeholder={''}
              addButton={true}
            />

            {
              <div className={classes.buttonsContainer}>
                <EnhancedButton disabled={submitting} onClick={() => onClose()}>
                  Cancel
                </EnhancedButton>

                {currentStatus !== 'NEW' && (
                  <EnhancedButton
                    state={submitting ? 'loading' : undefined}
                    isPrimary
                    disabled={submitting}
                    onClick={() => submit()}
                  >
                    Confirm
                  </EnhancedButton>
                )}
              </div>
            }
          </div>
        )}
      </div>
    </EnhancedDialog>
  );
};

export default PreviewClauseModal;
