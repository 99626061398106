import { FileDetail, IClauseOption } from '.';
import { IAbstractRecord } from '../../../../../models';

export function extractClauseOptions(jsonData: any): IClauseOption[] {
  const items = jsonData.data.PlanConfigManagement.queries.getClauseList.items;

  const clauseOptions: IClauseOption[] = items.map((item: any) => ({
    id: item.planConfigManagement_Clause_Id,
    externalCode: item.planConfigManagement_Clause_ExternalCode,
    name: item.planConfigManagement_Clause_Name,
    description: item.planConfigManagement_Clause_Description,
  }));

  return clauseOptions;
}

export function getFileDetailsList(data: IAbstractRecord): FileDetail[] {
  const fileDetails: FileDetail[] = [];

  const items =
    data?.production?.entities?.proposal?.production?.issuePolicyMotor?.Items ||
    [];
  for (const item of items) {
    const fileDetail: FileDetail = {
      EntityTypeId: item.EntityTypeId,
      EntityId: item.EntityId,
      EntityViewId: item.EntityViewId,
      PropertyId: item.PropertyId,
      fileId: item.File.id,
      fileName: item.File.fileName,
    };
    fileDetails.push(fileDetail);
  }

  return fileDetails;
}
