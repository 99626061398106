import { gql } from '@apollo/client';

export const getLovs = gql`
  query ReinsurersList(
    $SelectedCompany: String!
    $pagination: PaginationInput!
    $KeywordSearch: String
  ) {
    Production {
      queries {
        ReinsurersList(
          SelectedCompany: $SelectedCompany
          KeywordSearch: $KeywordSearch
          pagination: $pagination
        ) {
          items {
            salesforceManagement_BusinessPartner_Id
            salesforceManagement_BusinessPartner_Code
            salesforceManagement_BusinessPartner_FullName
          }
          paging {
            pageSize
            pageNumber
            totalCount
          }
        }
      }
    }
  }
`;

export const createTreatyMutation = gql`
  mutation createTreaty(
    $Company: String!
    $TreatyName: String!
    $SelectedType: String!
    $SelectedCurrency: String!
    $UnderwritingLimit: Decimal!
    $ClaimLimit: Decimal!
    $SubstandardLimit: Decimal!
    $EffecitiveDate: Date!
    $ExpiryDate: Date!
    $ReinsurersList: [Production_ReinsurersList!]!
  ) {
    production {
      actions {
        createTreaty(
          Company: $Company
          TreatyName: $TreatyName
          SelectedType: $SelectedType
          SelectedCurrency: $SelectedCurrency
          UnderwritingLimit: $UnderwritingLimit
          ClaimLimit: $ClaimLimit
          SubstandardLimit: $SubstandardLimit
          EffecitiveDate: $EffecitiveDate
          ExpiryDate: $ExpiryDate
          ReinsurersList: $ReinsurersList
        ) {
          id
        }
      }
    }
  }
`;
