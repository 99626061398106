import { ITravelPlanRatesDrawerInfo } from '.';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const travelPlanRatesEnums: Record<string, Record<string, string>> = {};

  travelPlanRatesEnums['currencies'] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  travelPlanRatesEnums['travelDestinations'] = lookupListAsRecordObject(
    data?.Core?.lookups?.travelDestinations
  );

  travelPlanRatesEnums['gender'] = enumListAsRecordObject(
    data?.Insurance_Genders?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  travelPlanRatesEnums['tpaApplicableOnOptions'] = enumListAsRecordObject(
    data?.Core_TPAApplicableOnOptions?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  return travelPlanRatesEnums;
}

export function graphqlToTravelPlanRatesInfo(
  data: any
): ITravelPlanRatesDrawerInfo {
  const travelPlanRates =
    data?.PlanConfigManagement?.entities?.planSpecificTravel?.views
      ?.PlanConfigManagement_all?.properties;

  const PolicyCoverData =
    travelPlanRates?.PolicyCoverID?.views?.PlanConfigManagement_all?.properties;
  const clauseRefData =
    travelPlanRates?.ClauseID?.views?.PlanConfigManagement_all?.properties;
  const clauseReferenceTitle = clauseRefData
    ? `${clauseRefData?.ExternalCode} - ${clauseRefData?.Name}`
    : undefined;

  return {
    planCurrency: '',
    ageFrom: travelPlanRates?.AgeFrom,
    ageTo: travelPlanRates?.AgeTo,
    gender: travelPlanRates?.Gender,
    travelDurationFrom: travelPlanRates?.TravelDurationFrom,
    travelDurationTo: travelPlanRates?.TravelDurationTo,
    travelDestination: travelPlanRates?.TravelDestination?.Id,
    nbOfAdherent: travelPlanRates?.NbOfAdherent,
    premium: travelPlanRates?.Premium,
    policyCover: travelPlanRates?.PolicyCoverID?.id,
    policyCoverTitle: PolicyCoverData
      ? `${PolicyCoverData?.ExternalCode} - ${PolicyCoverData?.Name}`
      : '',
    isMain: travelPlanRates?.IsMain,
    isMandatory: travelPlanRates?.IsMandatory,
    isEditable: travelPlanRates?.IsEditable,
    sumInsured: travelPlanRates?.SumInsured,
    sumInsuredIsAdditive: travelPlanRates?.SumInsuredIsAdditive,
    tpaType: travelPlanRates?.TPAType || '',
    tpaAmount: travelPlanRates?.TPAFeesAmount,
    tpaFeesPercentage: travelPlanRates?.TPAFeesPercentage
      ? `${Number(travelPlanRates?.TPAFeesPercentage) * 100}`
      : '',
    tpaFeesApplicableOn: travelPlanRates?.TPAApplicableOn,
    minTPAFees: travelPlanRates?.MinTPAFees,
    maxTPAFees: travelPlanRates?.MaxTPAFees,
    excessOnClaimType: travelPlanRates?.ExcessOnClaimType,
    excessOnClaimAmount: travelPlanRates?.ExcessOnClaimAmount,
    excessOnClaimPercentage: travelPlanRates?.ExcessOnClaimPercentage
      ? `${Number(travelPlanRates?.ExcessOnClaimPercentage) * 100}`
      : '',
    excessOnClaimDays: travelPlanRates?.ExcessOnClaimDays,
    clauseReference: travelPlanRates?.ClauseID?.id || null,
    clauseReferenceTitle: clauseReferenceTitle,
  };
}

export function extractPolicyCovers(data: any) {
  const policyCoverList: Record<string, string> = {};

  if (!data) return policyCoverList;

  data.forEach(
    (element: {
      planConfigManagement_PolicyCover_Id: string;
      planConfigManagement_PolicyCover_Name: string;
      planConfigManagement_PolicyCover_ExternalCode: string;
    }) => {
      policyCoverList[element.planConfigManagement_PolicyCover_Id] =
        `${element.planConfigManagement_PolicyCover_ExternalCode} - ${element.planConfigManagement_PolicyCover_Name}`;
    }
  );

  return policyCoverList;
}

export function extractClauses(data: any) {
  const clauses: Record<string, string> = {};

  if (!data) return clauses;

  data.forEach(
    (element: {
      planConfigManagement_Clause_Id: string;
      planConfigManagement_Clause_ExternalCode: string;
      planConfigManagement_Clause_Name: string;
    }) => {
      clauses[element.planConfigManagement_Clause_Id] =
        `${element.planConfigManagement_Clause_ExternalCode} - ${element.planConfigManagement_Clause_Name}`;
    }
  );

  return clauses;
}
