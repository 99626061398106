import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getPolicyDetailsQuery } from './queries';
import StaticLayout from '../../../../page-layout/static-layout/StaticLayout';
import { IAbstractRecord } from '../../../../models';
import LeftPanel from './components/LeftPanel';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../components/ToastErrorMessage';
import { getError } from '../../../../utils/graph-utils';
import PolicyDetailsWidget from './components/PolicyDetailsWidget';
import TreatyDistributionWidget from './components/TreatyDistributionWidget';
import { makeStyles } from 'tss-react/mui';
import FacDistributionWidget from './components/FacDistributionWidget';

const useStyles = makeStyles()(() => ({
  container: {
    marginTop: '36px',
  },
  containerWithPadding: {
    marginTop: '36px',
    margin: '36px 3px 0',
  },
}));

const ProdDistDetailsPage: React.FC = () => {
  const { id: currentPolicyId } = useParams<{ id: string }>();
  const { classes } = useStyles();
  const {
    data: policyDetailsData,
    loading: policyDetailsLoading,
    error: policyDetailsError,
  } = useQuery(getPolicyDetailsQuery, {
    variables: {
      selectedPolicyID: currentPolicyId,
    },
    errorPolicy: 'all',
  });

  if (policyDetailsError) {
    toast.error(
      <ToastErrorMessage>{getError(policyDetailsError)}</ToastErrorMessage>
    );
  }

  const renderMainChildren = (policyData: IAbstractRecord) => {
    const policy = policyData?.Production?.entities?.policy;

    return (
      <>
        <div className={classes.container}>
          <PolicyDetailsWidget policy={policy} />
        </div>
        <div className={classes.containerWithPadding}>
          <TreatyDistributionWidget
            currentPolicyId={currentPolicyId}
            currentPolicyName={
              policy?.views?.Production_all?.properties?.PolicyName
            }
          />
        </div>
        <div className={classes.containerWithPadding}>
          <FacDistributionWidget currentPolicyId={currentPolicyId} />
        </div>
      </>
    );
  };

  return (
    <StaticLayout
      name="Production Distribution"
      loading={policyDetailsLoading}
      mainChildren={renderMainChildren(policyDetailsData)}
      leftChildren={<LeftPanel data={policyDetailsData} />}
    />
  );
};

export default ProdDistDetailsPage;
