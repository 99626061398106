import { gql } from '@apollo/client';

export function getTravelPlanRatesEnums() {
  return gql`
    query getTravelPlanRatesEnums {
      Insurance_Genders: __type(name: "Insurance_Genders") {
        name
        enumValues {
          name
        }
      }
      Core_TPAApplicableOnOptions: __type(name: "Core_TPAApplicableOnOptions") {
        name
        enumValues {
          name
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
          travelDestinations {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function getPlanSpecificTravelInfo() {
  return gql`
    query getPlanSpecificTravelInfo($id: String!) {
      PlanConfigManagement {
        entities {
          planSpecificTravel(id: $id) {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  Id
                  PlanID {
                    id
                  }
                  PolicyCoverID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                  AgeFrom
                  AgeTo
                  Gender
                  TravelDurationFrom
                  TravelDurationTo
                  TravelDestination {
                    Id
                    Title
                  }
                  NbOfAdherent
                  Premium
                  # Newly added fields
                  IsMain
                  IsMandatory
                  IsEditable
                  SumInsured
                  SumInsuredIsAdditive
                  TPAType
                  TPAFeesAmount
                  TPAFeesPercentage
                  TPAApplicableOn
                  MinTPAFees
                  MaxTPAFees
                  ExcessOnClaimType
                  ExcessOnClaimAmount
                  ExcessOnClaimPercentage
                  ExcessOnClaimDays
                  ClauseID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          ExternalCode
                          Name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function createPlanSpecificTravel() {
  return gql`
    mutation createPlanSpecificTravel(
      $planSpecificTravelInputs: PlanConfigManagement_PlanSpecificTravelInputs!
    ) {
      planConfigManagement {
        actions {
          createPlanSpecificTravel(
            planSpecificTravelInputs: $planSpecificTravelInputs
          ) {
            id
          }
        }
      }
    }
  `;
}

export function updatePlanSpecificTravel() {
  return gql`
    mutation updatePlanSpecificTravel(
      $entityId: String!
      $planSpecificTravelInputs: PlanConfigManagement_PlanSpecificTravelInputs!
    ) {
      planConfigManagement {
        entities {
          planSpecificTravel {
            planConfigManagement {
              updatePlanSpecificTravel(
                entityId: $entityId
                planSpecificTravelInputs: $planSpecificTravelInputs
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export const getPolicyCoversListQuery = gql`
  query getPolicyCoversList(
    $selectedLineIds: [String]
    $searchKeyword: String!
    $pagination: PaginationInput!
  ) {
    PlanConfigManagement {
      queries {
        allPolicyCoverListPaginated(
          pagination: $pagination
          searchKeyword: $searchKeyword
          selectedLineIds: $selectedLineIds
        ) {
          items {
            planConfigManagement_PolicyCover_Id
            planConfigManagement_PolicyCover_createdOn
            planConfigManagement_PolicyCover_ExternalCode
            planConfigManagement_PolicyCover_Name
            planConfigManagement_PolicyCover_NameArabic
            planConfigManagement_PolicyCover_NameFrench
            planConfigManagement_PolicyCover_LineID {
              id
            }
          }
          paging {
            pageSize
            pageNumber
            totalCount
          }
        }
      }
    }
  }
`;

export const getClausesListQuery = gql`
  query getClausesList(
    $selectedLineIds: [String]
    $searchKeyword: String!
    $pagination: PaginationInput!
  ) {
    PlanConfigManagement {
      queries {
        allClauseListPaginated(
          pagination: $pagination
          searchKeyword: $searchKeyword
          selectedLineIds: $selectedLineIds
        ) {
          items {
            planConfigManagement_Clause_Id
            planConfigManagement_Clause_ExternalCode
            planConfigManagement_Clause_Name
          }
          paging {
            pageSize
            pageNumber
            totalCount
          }
        }
      }
    }
  }
`;
