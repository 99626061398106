import { FormInputTypes, IDynamicSection } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  planCurrency: '',
  carAgeFrom: '',
  carAgeTo: '',
  carValueFrom: '',
  carValueTo: '',
  carCategory: '',
  minimumPremium: '',
  rate: '',
  agencyYear: '',
  noDepreciationYear: '',
};
export const getInputs = (
  planCurrencySymbol: string
): Record<string, IDynamicSection> => ({
  information: {
    title: 'Information',
    inputs: {
      planCurrency: {
        name: 'planCurrency',
        title: 'Plan Currency',
        type: FormInputTypes.chips,
        placeholder: 'Plan Currency',
        value: !isEmpty(initialValues.planCurrency)
          ? initialValues.planCurrency
          : '',
        multiple: false,
        disabled: true,
        required: true,
        hidden: true,
      },
      carValueFrom: {
        name: 'carValueFrom',
        title: 'Car Value From',
        placeholder: 'Car Value From',
        type: FormInputTypes.currency,
        value: initialValues.carValueFrom,
        required: true,
        minNumber: 0,
        maxDecimalPercision: 0,
        useCurrencySymbol: true,
        currencySymbol: planCurrencySymbol,
      },
      carValueTo: {
        name: 'carValueTo',
        title: 'Car Value To',
        placeholder: 'Car Value To',
        type: FormInputTypes.currency,
        value: initialValues.carValueTo,
        required: true,
        maxDecimalPercision: 0,
        useCurrencySymbol: true,
        currencySymbol: planCurrencySymbol,
      },
      carAgeFrom: {
        name: 'carAgeFrom',
        title: 'Car Age From',
        placeholder: 'Car Age From',
        type: FormInputTypes.number,
        value: initialValues.carAgeFrom,
        required: true,
        maxNumber: 50,
        maxDecimalPrecision: 0,
      },
      carAgeTo: {
        name: 'carAgeTo',
        title: 'Car Age To',
        placeholder: 'Car Age To',
        type: FormInputTypes.number,
        value: initialValues.carAgeTo,
        required: true,
        maxNumber: 50,
        maxDecimalPrecision: 0,
      },
      carCategory: {
        name: 'carCategory',
        title: 'Car Category',
        type: FormInputTypes.chips,
        placeholder: 'Car Category',
        value: !isEmpty(initialValues.carCategory)
          ? initialValues.carCategory
          : '',
        multiple: false,
        disabled: false,
        required: true,
      },
      agencyYear: {
        name: 'agencyYear',
        title: 'Agency Year',
        placeholder: 'Agency Year',
        type: FormInputTypes.number,
        value: initialValues.agencyYear,
        required: false,
        maxDecimalPrecision: 0,
      },
      noDepreciationYear: {
        name: 'noDepreciationYear',
        title: 'Depreciation Year',
        placeholder: 'Depreciation Year',
        type: FormInputTypes.number,
        value: initialValues.noDepreciationYear,
        required: false,
        maxDecimalPrecision: 0,
      },
    },
  },
  premiumDetails: {
    title: 'Premium Details',
    inputs: {
      minimumPremium: {
        name: 'minimumPremium',
        title: 'Minimum Premium',
        placeholder: 'Minimum Premium',
        type: FormInputTypes.currency,
        value: initialValues.minimumPremium,
        required: true,
        minNumber: 0,
        maxDecimalPercision: 3,
        useCurrencySymbol: true,
        currencySymbol: planCurrencySymbol,
      },
      rate: {
        name: 'rate',
        title: 'Rate',
        type: FormInputTypes.percentage,
        placeholder: 'Rate',
        value: initialValues.rate,
        required: true,
        disabled: false,
      },
    },
  },
});
