import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { IAbstractRecord } from '../../../../../models';
import { IEnhancedTableProps } from '../../../../../components/enhanced-table/EnhancedTable';
import ListingWidget from '../../../../../components/form-fields/listing-widget/ListingWidget';
import { breakdownHeaders } from './content';
import { getBreakdown } from './queries';
import { transformReceivableBillsArrayToObject } from './utils';

export interface IFormValues {
  PolicyNumber: string;
  PolicyName: string;
  PaymentDivisionType: string;
  PaymentMode: string;
}

interface IViewBreakdownProps {
  billId: string;
  data: Partial<IFormValues>;
}

const ViewBreakdown: React.FC<IViewBreakdownProps> = ({ billId }) => {
  const { data: bills } = useQuery(getBreakdown, {
    variables: {
      selectedParentBillID: billId,
    },
  });

  const receivableBillsData: IEnhancedTableProps['data'] = useMemo(() => {
    if (!bills)
      return {
        pagedItems: {},
        pageSize: 5,
        pageNumber: 0,
        totalCount: 0,
      };

    const receivableBillsItems: IAbstractRecord[] =
      bills?.Accounting?.queries?.GetReceivableAndPayableBillsByParentBillID;

    return {
      pagedItems: transformReceivableBillsArrayToObject(receivableBillsItems),
      pageNumber: 1,
      pageSize: 5,
      totalCount: receivableBillsItems?.length,
    };
  }, [bills]);

  return (
    <ListingWidget
      name="receivableBillsSchedule"
      data={receivableBillsData}
      tableSettings={{ headers: breakdownHeaders }}
      disableSelection
      inline
      title=""
      actions={[]}
    />
  );
};

export default ViewBreakdown;
