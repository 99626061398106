import { IDocumentInfo } from '.';
import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { FileType } from '../../models/file';
import { ITenant } from '../../redux/tenant/types';

export const ALLOWED_EXTENSIONS = [FileType.xls, FileType.xlsx];

export const initialValues: IDocumentInfo = {
  documentName: '',
  document: {
    id: '',
    location: '',
    path: '',
    fileName: '',
    length: undefined,
    contentType: '',
  },
};

export const inputs = (
  tenant: ITenant
): Record<string, DynamicFormInputType> => ({
  document: {
    name: 'document',
    title: 'List of Vehicles',
    type: FormInputTypes.fileuploader,
    value: null,
    required: true,
    disabled: false,
    maxFileSizeInMB: 200,
    allowedFileTypes: ALLOWED_EXTENSIONS,
    imageUrl: '',
    downloadLinks: [
      {
        downloadUrl: tenant.cdnUrl + '/files/List of Vehicles Template.xlsx',
        displayName: 'Download Template',
      },
    ],
  },
});
