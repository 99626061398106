import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { excelHeaders, getHeaders } from '.';
import { IProposalDetailsSummary } from '../../index2';
import { IListingData } from '../../../../../../../models/listing';
import EnhancedTable from '../../../../../../../components/enhanced-table/EnhancedTable';
import { IEnhancedTableMenuItem } from '../../../../../../../components/enhanced-table';
import { downloadExcelFile } from '../../../../../../../components/custom/download-json-excel-service';
import {
  capitalizeFirstLetterLowerOthers,
  trimTrailingZeros,
} from '../../../../../../../utils/formatting-utils';
import { sumArray } from '../../../../../../../utils/helper-utils';
import { isValidNumber } from '../../../../../../../utils/validationUtils';

const useStyles = makeStyles()(() => ({
  paperClass: {
    marginTop: '1em',
  },
}));

interface IMedicalInsuredTableWidgetProps {
  data: IProposalDetailsSummary;
  className?: string;
  onUpdateSuccess?: () => void;
}

export const TravelInsuredTableWidget: React.FC<
  IMedicalInsuredTableWidgetProps
> = ({ data }) => {
  const { classes } = useStyles();

  const [tableData, setTableData] = useState<IListingData>();

  const tableAction: IEnhancedTableMenuItem[] = [
    {
      title: 'Download',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => {
        downloadExcelFile({
          data: Object.values(prepareData()),
          filename: 'Travel Premium Details',
          headers: excelHeaders(data.PolicyCurrency.Symbol),
        });
      },
      isEntity: false,
      isBulk: false,
      disabled: false,
    },
  ];

  const prepareData = () => {
    const relationOrder = ['PRINCIPAL', 'SPOUSE', 'CHILD'];

    const result = data.InsuredPricingOptionTravel.map((item) => {
      const relationRaw =
        item?.PolicyPersonID?.Relation?.toString().toUpperCase() || '';
      return {
        insured: item?.PolicyPersonID?.FullName || '',
        relation: capitalizeFirstLetterLowerOthers(
          item?.PolicyPersonID?.Relation?.toString() || ''
        ),
        age: item?.Age?.toString() || '',
        cover: item?.PolicyCover?.toString() || '',
        sumInsured: trimTrailingZeros(item?.CoverSumInsured),
        netPremium: trimTrailingZeros(item?.CoverNetPremiumValue),
        cost: trimTrailingZeros(item?.CoverCost),
        charges: trimTrailingZeros(item?.CoverCharges),
        tpaFees: trimTrailingZeros(item?.CoverTPAFees),
        fixedStamp: trimTrailingZeros(item?.CoverFixedStamp),
        propStamp: trimTrailingZeros(item?.CoverProportionalStamp),
        munTax: trimTrailingZeros(item?.CoverMunicipalityTax),
        grossPremium: trimTrailingZeros(item?.CoverGrossPremium),
        totalPremium: trimTrailingZeros(item?.CoverTotalPremium),
        additiveSumInsured: item.SumInsuredIsAdditive ? 'Yes' : 'No',
        principalReference:
          item?.PolicyPersonID?.PrincipalReference || 'Unknown',
        relationOrderIndex: relationOrder.indexOf(relationRaw),
      };
    });

    result.sort((a, b) => {
      // First, sort by PrincipalReference
      if (a.principalReference < b.principalReference) return -1;
      if (a.principalReference > b.principalReference) return 1;

      // If PrincipalReference is the same, sort by Relation order
      return a.relationOrderIndex - b.relationOrderIndex;
    });

    // Remove sorting helper properties if desired
    return result.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ relationOrderIndex, principalReference, ...rest }) => rest
    );
  };

  const prepareDataBase = () => {
    const relationOrder = ['PRINCIPAL', 'SPOUSE', 'CHILD'];

    const insuredList = [
      ...new Set(
        data.InsuredPricingOptionTravel.map((i) => i.PolicyPersonID.Id)
      ),
    ];

    const result = insuredList
      .map((i) => {
        const insured = data.InsuredPricingOptionTravel.filter(
          (d) => d.PolicyPersonID.Id == i
        );
        const item = insured[0];

        const relationRaw =
          item?.PolicyPersonID?.Relation?.toString().toUpperCase() || '';
        return {
          insured: item?.PolicyPersonID?.FullName || '',
          relation: capitalizeFirstLetterLowerOthers(
            item?.PolicyPersonID?.Relation?.toString() || ''
          ),
          age: item?.Age?.toString() || '',
          sumInsured: sumArray(
            insured.map((a) =>
              item.SumInsuredIsAdditive && isValidNumber(a.CoverSumInsured)
                ? Number(a.CoverSumInsured)
                : 0
            )
          ),
          netPremium: sumArray(
            insured.map((a) =>
              isValidNumber(a.CoverNetPremiumValue)
                ? Number(a.CoverNetPremiumValue)
                : 0
            )
          ),
          cost: sumArray(
            insured.map((a) =>
              isValidNumber(a.CoverCost) ? Number(a.CoverCost) : 0
            )
          ),
          charges: sumArray(
            insured.map((a) =>
              isValidNumber(a.CoverCharges) ? Number(a.CoverCharges) : 0
            )
          ),
          tpaFees: sumArray(
            insured.map((a) =>
              isValidNumber(a.CoverTPAFees) ? Number(a.CoverTPAFees) : 0
            )
          ),
          fixedStamp: sumArray(
            insured.map((a) =>
              isValidNumber(a.CoverFixedStamp) ? Number(a.CoverFixedStamp) : 0
            )
          ),
          propStamp: sumArray(
            insured.map((a) =>
              isValidNumber(a.CoverProportionalStamp)
                ? Number(a.CoverProportionalStamp)
                : 0
            )
          ),
          munTax: sumArray(
            insured.map((a) =>
              isValidNumber(a.CoverMunicipalityTax)
                ? Number(a.CoverMunicipalityTax)
                : 0
            )
          ),
          grossPremium: sumArray(
            insured.map((a) =>
              isValidNumber(a.CoverGrossPremium)
                ? Number(a.CoverGrossPremium)
                : 0
            )
          ),
          totalPremium: sumArray(
            insured.map((a) =>
              isValidNumber(a.CoverTotalPremium)
                ? Number(a.CoverTotalPremium)
                : 0
            )
          ),
          additiveSumInsured: item.SumInsuredIsAdditive ? 'Yes' : 'No',
          nssf: capitalizeFirstLetterLowerOthers(
            item?.CoverNssf?.toString() || ''
          ),
          principalReference: '',
          relationOrderIndex: relationOrder.indexOf(relationRaw),
        };
      })
      .sort((a, b) => a.relationOrderIndex - b.relationOrderIndex);
    // Remove sorting helper properties if desired
    return result;
  };

  useEffect(() => {
    const result = prepareDataBase();
    setTableData({
      pagedItems: result,
      pageSize: 5,
      pageNumber: 0,
      totalCount: Object.values(result).length,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!tableData) {
    return <></>;
  }

  return (
    <EnhancedTable
      disableSelection
      tableClasses={{
        paperClass: classes.paperClass,
      }}
      hideToolbar={false}
      usePagination={false}
      actions={tableAction}
      name={'table'}
      inlineTitle={'Premium Details'}
      inline={true}
      headers={getHeaders(data?.PolicyCurrency?.Symbol)}
      data={tableData}
    />
  );
};
