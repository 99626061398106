import { gql } from '@apollo/client';

export const getActiveProposals = gql`
  query getFacLovs {
    Production {
      queries {
        GetActiveProposals {
          production_Proposal_Id
          production_Proposal_ProposalName
        }
      }
    }
  }
`;

export const getProposalDetailsQuery = gql`
  query getProposalDetails($proposalId: String!) {
    Production {
      queries {
        getPolicyPlanCovers(selectedProposalID: $proposalId) {
          production_PolicyPlanCover_Id
          policyCover_Name
          production_PolicyPlanCover_CoverEffectiveFrom
          production_PolicyPlanCover_CoverEffectiveTo
        }
      }
      entities {
        proposal(id: $proposalId) {
          views {
            Production_all {
              properties {
                RelatedCompany {
                  Code
                }
              }
            }
          }
          queries {
            production {
              pricingOption {
                pricingOption_PricingOptionCurrency {
                  Code
                  Symbol
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const createFacultativeMutation = gql`
  mutation createFacultativeMutation(
    $Proposal: String!
    $Company: String!
    $ProposalCover: String!
    $Reinsurer: String!
  ) {
    production {
      actions {
        createFacultative(
          Proposal: $Proposal
          Company: $Company
          ProposalCover: $ProposalCover
          Reinsurer: $Reinsurer
        ) {
          id
        }
      }
    }
  }
`;
