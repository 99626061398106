import { gql } from '@apollo/client';

export const getLineListQuery = gql`
  query getLineList($pagination: PaginationInput!, $KeywordSearch: String) {
    PlanConfigManagement {
      queries {
        getLineList(pagination: $pagination, KeywordSearch: $KeywordSearch) {
          items {
            planConfigManagement_Line_Id
            planConfigManagement_Line_Name
          }
          paging {
            totalCount
          }
        }
      }
    }
  }
`;

export const getSublineListQuery = gql`
  query getProdDistFilters(
    $pagination: PaginationInput!
    $KeywordSearch: String
  ) {
    PlanConfigManagement {
      queries {
        getSubLineList(pagination: $pagination, KeywordSearch: $KeywordSearch) {
          items {
            planConfigManagement_Subline_Id
            planConfigManagement_Subline_Name
          }
          paging {
            totalCount
          }
        }
      }
    }
  }
`;

export const getProdDistListQuery = gql`
  query productionDistributionList(
    $pagination: PaginationInput!
    $Line: [String]
    $Subline: [String]
    $PolicyEffectiveFromDate: Date
    $PolicyEffectiveToDate: Date
    $KeywordSearch: String
    $OrderByField: String
    $DescendingField: Boolean
  ) {
    Production {
      queries {
        ProductionDistributionList(
          pagination: $pagination
          Line: $Line
          Subline: $Subline
          PolicyEffectiveFromDate: $PolicyEffectiveFromDate
          PolicyEffectiveToDate: $PolicyEffectiveToDate
          KeywordSearch: $KeywordSearch
          OrderByField: $OrderByField
          DescendingField: $DescendingField
        ) {
          items {
            production_Policy_Id
            production_Policy_PolicyNumber
            policyLine_Name
            policySubLine_Name
            policySubLine_Category
            production_Policy_PolicyCurrency {
              Title
            }
            policyPlan_Name
            production_Policy_PolicyEffectiveDate
            production_Policy_PolicyExpiryDate
          }
          paging {
            pageSize
            pageNumber
            totalCount
          }
        }
      }
    }
  }
`;
