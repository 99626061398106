import { gql } from '@apollo/client';

export const getTreatyReinsurersQuery = gql`
  query getTreatyReinsurers(
    $TreatyID: String!
    $SelectedCompany: String!
    $pagination: PaginationInput!
    $KeywordSearch: String
  ) {
    Production {
      queries {
        GetTreatyReinsurers(TreatyID: $TreatyID) {
          production_TreatyReinsurers_Id
          treatyReinsurersBusinessPartner_Id
          treatyReinsurersBusinessPartner_FullName
          production_TreatyReinsurers_SharePercentage
          production_TreatyReinsurers_IsLeader
        }
        ReinsurersList(
          SelectedCompany: $SelectedCompany
          KeywordSearch: $KeywordSearch
          pagination: $pagination
        ) {
          items {
            salesforceManagement_BusinessPartner_Id
            salesforceManagement_BusinessPartner_Code
            salesforceManagement_BusinessPartner_FullName
          }
          paging {
            pageSize
            pageNumber
            totalCount
          }
        }
      }
    }
  }
`;

export const updateTreatyReinsurersMutation = gql`
  mutation updateTreatyReinsurers(
    $CurrentTreatyID: String!
    $ReinsurersList: [Production_ReinsurersList!]!
  ) {
    production {
      actions {
        updateTreatyReinsurers(
          CurrentTreatyID: $CurrentTreatyID
          ReinsurersList: $ReinsurersList
        )
      }
    }
  }
`;
