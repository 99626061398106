import React, { useState } from 'react';
import WidgetPaper from '../../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../../components/common/WidgetSection';
import EnhancedTable from '../../../../../components/enhanced-table/EnhancedTable';
import { makeStyles } from 'tss-react/mui';
import { EnhancedTableHeaderType } from '../../../../../components/enhanced-table';
import EnhancedButton from '../../../../../components/form-fields/buttons/EnhancedButton';
import UpdateFacReinsurerDrawer from './UpdateFacReinsurerDrawer';

interface IReinsurersWidget {
  isButtonDisabled?: boolean;
  reinsurer: {
    reinsurerId: string;
    name: string;
    share: number;
    companyId: string;
  };
  onSuccess: () => void;
}

const useStyles = makeStyles()(() => ({
  paperClass: {
    boxShadow: 'none !important',
  },
  updateButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '15px 0',
  },
}));

const FacultativeReinsurerWidget: React.FC<IReinsurersWidget> = ({
  isButtonDisabled,
  reinsurer,
  onSuccess,
}) => {
  const { classes } = useStyles();
  const [showReinsurersDrawer, setShowReinsurersDrawer] =
    useState<boolean>(false);

  return (
    <WidgetPaper>
      <WidgetSection
        title="Reinsurers"
        hasTitleSpecificDesign={false}
        useSeparator={false}
      >
        <EnhancedTable
          name="reinsurers"
          title=""
          orderByAscendingByDefault
          data={{
            pagedItems: [
              {
                reinsurer: reinsurer.name,
                share: reinsurer.share,
              },
            ],
            pageSize: 1,
            pageNumber: 1,
            totalCount: 1,
          }}
          headers={{
            reinsurer: {
              name: 'reinsurer',
              title: 'Reinsurer',
              type: EnhancedTableHeaderType.Text,
            },
            share: {
              name: 'share',
              title: 'Share',
              type: EnhancedTableHeaderType.Percentage,
            },
          }}
          tableClasses={{
            paperClass: classes.paperClass,
          }}
          showTablePagination={false}
          usePagination
          inline
          disableSelection
          hideToolbar
        />
        {!isButtonDisabled && (
          <div className={classes.updateButtonContainer}>
            <EnhancedButton onClick={() => setShowReinsurersDrawer(true)}>
              Update
            </EnhancedButton>
          </div>
        )}
        {showReinsurersDrawer && (
          <UpdateFacReinsurerDrawer
            isOpen={showReinsurersDrawer}
            data={reinsurer}
            onClose={() => {
              setShowReinsurersDrawer(false);
            }}
            onSuccess={() => {
              onSuccess();
            }}
          />
        )}
      </WidgetSection>
    </WidgetPaper>
  );
};

export default FacultativeReinsurerWidget;
