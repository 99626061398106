import React from 'react';
import WidgetPaper from '../../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../../components/common/WidgetSection';
import PayerWidget from './PayerWidget';
import { ILineAmendmentProps } from '../../line-amendment';
import { IAmendmentPageState } from '../..';
import OwnerWidget from './OwnerWidget';

interface IPayerOwnerWidget {
  generalData: ILineAmendmentProps['generalData'];
  pageState: IAmendmentPageState;
  onPageStateUpdate: (pageState: IAmendmentPageState) => void;
  isDisabled: boolean;
}

const PayerOwnerWidget: React.FC<IPayerOwnerWidget> = ({
  generalData,
  pageState,
  onPageStateUpdate,
  isDisabled,
}) => {
  return (
    <WidgetPaper>
      <WidgetSection title="Payer & Owner">
        <PayerWidget
          generalData={generalData}
          pageState={pageState}
          onPageStateUpdate={onPageStateUpdate}
          isDisabled={isDisabled}
        />
        {pageState?.values?.policyPersons?.payer?.ownerIsDifferent && (
          <OwnerWidget
            generalData={generalData}
            pageState={pageState}
            onPageStateUpdate={onPageStateUpdate}
            isDisabled={isDisabled}
          />
        )}
      </WidgetSection>
    </WidgetPaper>
  );
};

export default PayerOwnerWidget;
