import React, { useEffect, useState } from 'react';
import { ITreatyReinsurerResponse } from '../..';
import WidgetPaper from '../../../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../../../components/common/WidgetSection';
import { IListingData } from '../../../../../../models/listing';
import { isEmpty } from 'lodash';
import EnhancedTable from '../../../../../../components/enhanced-table/EnhancedTable';
import { makeStyles } from 'tss-react/mui';
import EnhancedButton from '../../../../../../components/form-fields/buttons/EnhancedButton';
import { headers } from './content';
import UpdateReinsurersDrawer from '../update-reinsurers-drawer/UpdateReinsurersDrawer';

interface IReinsurersWidget {
  isButtonDisabled?: boolean;
  reinsurersList: ITreatyReinsurerResponse[];
  onSuccess: () => void;
}

const useStyles = makeStyles()(() => ({
  paperClass: {
    boxShadow: 'none !important',
  },
  updateButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '15px 0',
  },
}));

const ReinsurersWidget: React.FC<IReinsurersWidget> = ({
  isButtonDisabled,
  reinsurersList,
  onSuccess,
}) => {
  const { classes } = useStyles();
  const [showReinsurersDrawer, setShowReinsurersDrawer] =
    useState<boolean>(false);
  const [tableData, setTableData] = useState<
    IListingData & {
      lovs: Record<string, Record<string, string>>;
    }
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
    lovs: {},
  });

  useEffect(() => {
    if (isEmpty(reinsurersList)) return;

    const mappedData = reinsurersList.map((reinsurer) => ({
      reinsurerId: reinsurer.production_TreatyReinsurers_Id,
      isLeadReinsurer: reinsurer.production_TreatyReinsurers_IsLeader,
      TreatyReinsurersBusinessPartner_FullName:
        reinsurer.treatyReinsurersBusinessPartner_FullName,
      Production_TreatyReinsurers_SharePercentage:
        reinsurer.production_TreatyReinsurers_SharePercentage,
    }));

    // make sure the leader reinsurer is always the first one in the list
    mappedData.sort((a, b) => {
      if (a.isLeadReinsurer) return -1;
      if (b.isLeadReinsurer) return 1;
      return 0;
    });

    setTableData({
      pagedItems: mappedData,
      pageSize: mappedData?.length,
      pageNumber: 1,
      totalCount: mappedData?.length,
      lovs: {},
    });
  }, [reinsurersList]);

  return (
    <WidgetPaper>
      <WidgetSection
        title="Reinsurers"
        hasTitleSpecificDesign={false}
        useSeparator={false}
      >
        <EnhancedTable
          name="reinsurers"
          title=""
          orderByAscendingByDefault
          data={tableData}
          headers={headers}
          tableClasses={{
            paperClass: classes.paperClass,
          }}
          showTablePagination={false}
          usePagination
          inline
          disableSelection
          hideToolbar
        />
        {!isButtonDisabled && (
          <div className={classes.updateButtonContainer}>
            <EnhancedButton
              isPrimary={false}
              variant="contained"
              onClick={() => setShowReinsurersDrawer(true)}
            >
              Update
            </EnhancedButton>
          </div>
        )}
        {showReinsurersDrawer && (
          <UpdateReinsurersDrawer
            isOpen={showReinsurersDrawer}
            onClose={() => {
              setShowReinsurersDrawer(false);
            }}
            onSuccess={() => {
              onSuccess();
            }}
          />
        )}
      </WidgetSection>
    </WidgetPaper>
  );
};

export default ReinsurersWidget;
