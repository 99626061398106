import React, { useEffect, useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import SectionDynamicForm from '../../DynamicForm/SectionDynamicForm';
import { EnhancedButtonStatus } from '../../components/EnhancedButton';
import Loader from '../../components/Loader';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { IMarineHullDrawerProps } from '.';
import { getMarineHullSections } from './content';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { updateMarineHull } from './queries';
import { useMutation } from '@apollo/client';
import { getError } from '../../utils/graph-utils';

const MarineHullDrawer: React.FC<IMarineHullDrawerProps> = ({
  open,
  onClose,
  onSuccess,
  data,
}) => {
  const [initialized, setInitialized] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [marineHullAction] = useMutation(updateMarineHull());

  useEffect(() => {
    setInitialized(true);
  }, []);

  const sections = useMemo(() => {
    return getMarineHullSections(data);
  }, [data]);

  const handleSubmit = async (values: Record<string, any>) => {
    setFormDisabled(true);
    setSubmitButtonState('loading');
    try {
      const { errors } = await marineHullAction({ variables: { ...values } });

      if (errors) {
        throw new Error(getError(errors));
      }

      toast.success(
        <ToastSuccessMessage>
          Marine Hull details updated successfully!
        </ToastSuccessMessage>
      );
      setSubmitButtonState('success');
      onSuccess();
      onClose();
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
      setSubmitButtonState(undefined);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      isOpen={open}
      onClose={onClose}
      title="Modify Marine Hull Details"
    >
      {!initialized ? (
        <Loader />
      ) : (
        <SectionDynamicForm
          sections={sections}
          onSubmit={handleSubmit}
          buttonText="Submit"
          submitButtonState={submitButtonState}
          isSubmitButtonDisabled={!!submitButtonState}
          disableForm={formDisabled}
          title=""
        />
      )}
    </GenericDrawer>
  );
};

export default MarineHullDrawer;
