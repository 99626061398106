import { gql } from '@apollo/client';

export const getTreaties = gql`
  query TreatiesList(
    $TreatyType: [String]
    $LeaderReinsurer: [String]
    $Currency: [String]
    $EffectiveToDate: Date
    $EffectiveFromDate: Date
    $Status: [String]
    $KeywordSearch: String
    $OrderByField: String
    $DescendingField: Boolean
    $SelectedCompany: String!
    $pagination: PaginationInput!
  ) {
    Production {
      queries {
        TreatiesList(
          TreatyType: $TreatyType
          LeaderReinsurer: $LeaderReinsurer
          Currency: $Currency
          EffectiveToDate: $EffectiveToDate
          EffectiveFromDate: $EffectiveFromDate
          Status: $Status
          KeywordSearch: $KeywordSearch
          OrderByField: $OrderByField
          DescendingField: $DescendingField
          pagination: $pagination
        ) {
          items {
            production_Treaties_Id
            production_Treaties_TreatyID
            production_Treaties_TreatyID
            production_Treaties_TreatyName
            production_Treaties_TreatyType
            production_Treaties_EffectiveDate
            production_Treaties_ExpiryDate
            production_Treaties_TreatyCurrency {
              Id
              Code
              Symbol
              Title
            }
            mainReinsurerDetails_FullName
            production_Treaties_TreatyStatus
          }
          paging {
            totalCount
            pageNumber
            pageSize
          }
        }
        GetMainReinsurers(SelectedCompany: $SelectedCompany) {
          mainReinsurerDetails_FullName
        }
      }
    }
    Production_TreatyType: __type(name: "Production_TreatyType") {
      enumValues {
        name
      }
    }
    Production_TreatyStatusList: __type(name: "Production_TreatyStatusList") {
      enumValues {
        name
      }
    }
    Core {
      lookups {
        currencies {
          Id
          Code
          Symbol
          Title
        }
      }
    }
  }
`;
