import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../../../components/widgets/custom-listing-filter';
import { FormInputTypes } from '../../../../DynamicForm';
import { IAbstractRecord } from '../../../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  Production_Treaties_TreatyID: {
    name: 'Production_Treaties_TreatyID',
    title: 'Treaty ID',
    type: EnhancedTableHeaderType.Link,
    urlTemplate: '/reinsurance/treaties/{treatyId}',
    openUrlInNewTab: false,
  },
  Production_Treaties_TreatyName: {
    name: 'Production_Treaties_TreatyName',
    title: 'Treaty Name',
    type: EnhancedTableHeaderType.Text,
  },
  Production_Treaties_TreatyType: {
    name: 'Production_Treaties_TreatyType',
    title: 'Treaty Type',
    type: EnhancedTableHeaderType.Text,
  },
  Production_Treaties_EffectiveDate: {
    name: 'Production_Treaties_EffectiveDate',
    title: 'Effective Date',
    type: EnhancedTableHeaderType.Date,
  },
  Production_Treaties_ExpiryDate: {
    name: 'Production_Treaties_ExpiryDate',
    title: 'Expiry Date',
    type: EnhancedTableHeaderType.Date,
  },
  Production_Treaties_TreatyCurrency: {
    name: 'Production_Treaties_TreatyCurrency',
    title: 'Currency',
    type: EnhancedTableHeaderType.Currency,
  },
  MainReinsurerDetails_FullName: {
    name: 'MainReinsurerDetails_FullName',
    title: 'Leader Reinsurer',
    type: EnhancedTableHeaderType.Text,
  },
  Production_Treaties_TreatyStatus: {
    name: 'Production_Treaties_TreatyStatus',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        treatyType: {
          name: 'treatyType',
          title: 'Treaty Type',
          type: FormInputTypes.newmultiselect,
          value: filterValues?.treatyType || null,
          placeholder: 'Select a Type',
          triggerUpdate: true,
          selectOptions: { ...lovs.treatyType },
        },
        leaderReinsurer: {
          name: 'leaderReinsurer',
          title: 'Leader Reinsurer',
          type: FormInputTypes.newselect,
          value: filterValues?.leaderReinsurer || null,
          placeholder: 'Select a Leader Reinsurer',
          triggerUpdate: true,
          selectOptions: { ...lovs.leaderReinsurer },
          canClearValue: true,
        },
        currency: {
          name: 'currency',
          title: 'Currency',
          type: FormInputTypes.newmultiselect,
          value: filterValues?.currency || null,
          placeholder: 'Select a currency',
          triggerUpdate: true,
          selectOptions: { ...lovs.currencyTitles },
        },
        effectiveDate: {
          name: 'effectiveDate',
          title: 'Effective Date',
          type: FormInputTypes.daterange,
          value: filterValues?.effectiveDate || null,
          triggerUpdate: true,
          canClearDate: true,
        },
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.newmultiselect,
          value: filterValues?.status || null,
          placeholder: 'Select a Status',
          triggerUpdate: true,
          selectOptions: { ...lovs.status },
        },
      },
    },
  ];
  return newFilterSections;
}
