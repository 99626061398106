import { gql } from '@apollo/client';

export const getPayablePaymentsOfBrokersQuery = gql`
  query getPayablePaymentsOfBrokers($businessPartnerID: String!) {
    Core {
      lookups {
        currencies {
          Id
          Symbol
        }
      }
    }
    Accounting {
      queries {
        GetPayablesPaymentsOfBroker(BusinessPartnerID: $businessPartnerID) {
          accounting_Payments_Id
          accounting_Payments_PaymentDate
          accounting_Payments_ReferenceNo
          accounting_Payments_PaymentMethod
          accounting_Payments_TotalAmountReceived
          accounting_Payments_PaymentCurrency
          accounting_Payments_createdOn
          accounting_Payments_createdBy
          accounting_Payments_CreatedByName
        }
      }
    }
  }
`;

export const getPaymentPayablesMutation = gql`
  mutation getPaymentPayables(
    $selectedCurrency: String
    $selectedPaymentDate: Date
    $currentBusinessPartnerID: String
  ) {
    accounting {
      actions {
        getPaymentPayables(
          SelectedCurrency: $selectedCurrency
          SelectedPaymentDate: $selectedPaymentDate
          CurrentBusinessPartnerID: $currentBusinessPartnerID
        ) {
          PaymentBill {
            FacultativeID
            PolicyNumber
            ParentBillType
            BillID
            BillCurrency
            BillNumber
            DueDate
            AmountDue
            AmountOutstanding
            AmountOutstandingCurrency
            PolicyID
          }
        }
      }
    }
  }
`;

export const generatePVTransactionsMutation = gql`
  mutation generatePVTransactions(
    $paymentCurrency: String!
    $paymentDate: Date!
    $totalAmount: Decimal!
    $pVList: [Accounting_PVList!]!
    $currentBusinessPartnerID: String!
  ) {
    accounting {
      actions {
        generatePVTransactions(
          PaymentCurrency: $paymentCurrency
          PaymentDate: $paymentDate
          TotalAmountPaid: $totalAmount
          PVList: $pVList
          CurrentBusinessPartnerID: $currentBusinessPartnerID
        ) {
          PaymentTransactionList {
            AccountCredit
            AccountDebit
            AccountID
            AccountName
            DebitOrCredit
            Description
            SystemCredit
            SystemDebit
            SystemSecondaryCredit
            SystemSecondaryDebit
            TransactionCurrency
          }
        }
      }
    }
  }
`;

export const createPVPaymentMutation = `
    mutation createPVPayment(
      $paymentDate: Date!
      $paymentCurrency: String!
      $paymentType: String!
      $paymentMethod: Accounting_PaymentMethodList
      $referenceNumber: String!
      $totalAmount: Decimal!
      $totalAmountUnallocated: Decimal!
      $currentBusinessPartnerID: String!
      $totalDebit: Decimal!
      $totalCredit: Decimal!
      $note: String!
      $pvList: [Accounting_PVList!]!
      $transactionList: [Accounting_PaymentTransaction!]!
      $UploadedDocuments: [Upload]
    ) {
      accounting {
        actions {
          createPVPayments(
            PaymentDate: $paymentDate
            PaymentCurrency: $paymentCurrency
            PaymentType: $paymentType
            PaymentMethod: $paymentMethod
            ReferenceNumber: $referenceNumber
            TotalAmountPaid: $totalAmount
            TotalAmountUnallocated: $totalAmountUnallocated
            CurrentBusinessPartnerID: $currentBusinessPartnerID
            TotalDebit: $totalDebit
            TotalCredit: $totalCredit
            Note: $note
            PVList: $pvList
            PaymentTransactionList: $transactionList
            UploadedDocuments: $UploadedDocuments
          )
        }
      }
    }
  `;
