import React from 'react';
import WidgetPaper from '../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../components/common/WidgetSection';
import { makeStyles } from 'tss-react/mui';
import { isEmpty } from '../../../../utils/validationUtils';
import EnhancedPercentageInput from '../../../../components/enhanced-form/EnhancedPercentageInput';
import EnhancedFormattedNumberInput from '../../../../components/enhanced-form/EnhancedFormattedNumberInput';
import EnhancedDatePicker from '../../../../components/enhanced-form/EnhancedDatePicker';
import { cloneDeep } from 'lodash';
import { ILineAmendmentProps } from '../line-amendment';
import { IAmendmentCover, IAmendmentPageState } from '..';
import SelectFormField from '../../../../components/form-fields/SelectFormField';
import CurrencyFormField from '../../../../components/form-fields/CurrencyFormField';
import { validateCoverRepeaterField } from './validation';

interface IPolicyCovers {
  generalData: ILineAmendmentProps['generalData'];
  pageState: IAmendmentPageState;
  onChange: (pageState: IAmendmentPageState) => void;
  isGroupPolicy?: boolean;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    backgroundColor: '#F9F9F9',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    padding: '33px 23px 23px',
    marginBottom: '14px',
    position: 'relative',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(4, 23.5%)`,
    gap: '0 2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  addBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    margin: '20px auto 0',
    display: 'block',
  },
  removeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    top: '8px',
  },
  clearBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  label: {
    color: '#000000',
    margin: '0px!important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  labelCurrency: {
    color: '#000000',
    margin: '8.5px 0 0 0 !important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  materialInput: {
    backgroundColor: 'unset',
    border: 'none',
    borderBottom: `1px solid #E5E5E5`,
    color: '#5A5A5A',
    margin: '0 auto',
    borderRadius: 0,
    padding: '10px 15px 4px 4px',
    fontFamily: 'SourceSansPro-Regular !important',
    fontSize: '14px',
  },
  selectMaterial: {
    width: '100%',
    '& .MuiInput-input': {
      fontFamily: 'SourceSansPro-Regular !important',
      fontSize: '14px',
      lineHeight: '15px',
      color: '#5A5A5A',
      paddingBottom: '0px !important',
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.20)',
        fontStyle: 'italic',
        opacity: '10',
        fontSize: '14px',
      },
    },
    '& .MuiInputBase-root:before': {
      border: 'none',
      backgroundColor: 'unset !important',
      borderRadius: '0 !important',
      borderBottom: `1px solid #E5E5E5`,
    },
    '& .MuiInput-root': {
      height: '34px',
      paddingBottom: '0px !important',
      paddingLeft: '4px',
    },
    '& .MuiInput-root.Mui-disabled:before': {
      borderBottomStyle: 'solid',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInputBase-root:after':
      {
        borderBottom: `1px solid #E5E5E5`,
      },
  },
  inputProps: {
    fontSize: '14px !important',
    width: '100%',
    backgroundColor: 'unset',
    '& .MuiInputBase-input': {
      width: '100%',
      backgroundColor: 'unset',
      color: '#5A5A5A',
      margin: '0 auto',
      fontFamily: 'SourceSansPro-Regular !important',
      fontSize: '14px !important',
    },
    '& .MuiFilledInput-root': {
      '& .MuiInputAdornment-root': {
        marginLeft: '-40px',
        fontFamily: 'SourceSansPro-Regular !important',
        fontSize: '14px !important',
      },
    },
    '& .MuiButtonBase-root': {
      marginRight: '1px',
    },
  },
  boldLabel: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
    margin: '0px !important',
    padding: '0px !important',
  },
}));

const PolicyCovers: React.FC<IPolicyCovers> = ({
  generalData,
  pageState,
  onChange,
  isGroupPolicy,
}) => {
  const { classes } = useStyles();
  const values = pageState?.values?.covers;
  const errors = pageState.errors.covers;
  const touched = pageState.touched.covers;

  const onFieldBlur = (fieldName: string, rowIndex: number) => {
    const newPageState = cloneDeep(pageState);
    newPageState.touched.covers[rowIndex][fieldName] = true;
    onChange(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IAmendmentCover,
    value: unknown,
    rowIndex: number,
    touched = false
  ) => {
    const newPageState = cloneDeep(pageState);
    if (fieldName === 'policyCover') {
      //
    }
    if (fieldName === 'coverPremiumType') {
      newPageState.values.covers[rowIndex].coverPremiumPercentage = null;
      newPageState.values.covers[rowIndex].coverPremiumValue = null;
      newPageState.errors.covers[rowIndex].coverPremiumPercentage = '';
      newPageState.errors.covers[rowIndex].coverPremiumValue = '';
      newPageState.touched.covers[rowIndex].coverPremiumPercentage = false;
      newPageState.touched.covers[rowIndex].coverPremiumValue = false;
    }
    if (fieldName === 'excessOnClaimType') {
      newPageState.values.covers[rowIndex].excessOnClaimPercentage = null;
      newPageState.values.covers[rowIndex].excessOnClaimAmount = null;
      newPageState.values.covers[rowIndex].excessOnClaimDays = null;
      newPageState.errors.covers[rowIndex].excessOnClaimPercentage = '';
      newPageState.errors.covers[rowIndex].excessOnClaimAmount = '';
      newPageState.errors.covers[rowIndex].excessOnClaimDays = '';
      newPageState.touched.covers[rowIndex].excessOnClaimPercentage = false;
      newPageState.touched.covers[rowIndex].excessOnClaimAmount = false;
      newPageState.touched.covers[rowIndex].excessOnClaimDays = false;
    }
    (newPageState.values.covers[rowIndex] as any)[fieldName] = value;
    newPageState.errors.covers[rowIndex][fieldName] =
      validateCoverRepeaterField(
        fieldName,
        value,
        newPageState.values.covers[rowIndex]
      );
    if (touched) {
      newPageState.touched.covers[rowIndex][fieldName] = true;
    }
    onChange(newPageState);
  };

  const renderRow = (row: IAmendmentCover, rowIndex: number) => {
    const disabledRow =
      !generalData?.businessUser?.allowPlanCoverEdits ||
      !row.isEditable ||
      !['NEW', 'IN_PROGRESS'].includes(generalData.amendmentStatus);

    return (
      <div className={classes.container} key={rowIndex}>
        <div className={classes.fieldRow}>
          <SelectFormField
            key="policyCover"
            name="policyCover"
            title="Policy Cover"
            placeholder="Policy Cover"
            value={values[rowIndex].policyCover}
            error={
              touched[rowIndex].policyCover ? errors[rowIndex].policyCover : ''
            }
            onChange={(v) => onFieldUpdate('policyCover', v, rowIndex)}
            selectOptions={{
              [values[rowIndex].policyCover]: values[rowIndex].name,
            }}
            disabled
            material
          />
          <CurrencyFormField
            key="coverSumInsured"
            name="coverSumInsured"
            title="Sum Insured"
            placeholder="Sum Insured"
            value={values[rowIndex].coverSumInsured || ''}
            error={
              touched[rowIndex].coverSumInsured
                ? errors[rowIndex].coverSumInsured
                : ''
            }
            onBlur={() => onFieldBlur('coverSumInsured', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('coverSumInsured', v.target.value, rowIndex)
            }
            disabled
            material
            currencySymbol={generalData?.currency?.Symbol}
          />
          <SelectFormField
            key="sumInsuredIsAdditive"
            name="sumInsuredIsAdditive"
            title="Additive SumInsured"
            placeholder="Additive SumInsured"
            value={
              isEmpty(values[rowIndex].sumInsuredIsAdditive)
                ? ''
                : values[rowIndex].sumInsuredIsAdditive
                  ? 'YES'
                  : 'NO'
            }
            error={
              touched[rowIndex].sumInsuredIsAdditive
                ? errors[rowIndex].sumInsuredIsAdditive
                : ''
            }
            onChange={(v) =>
              onFieldUpdate(
                'sumInsuredIsAdditive',
                v === 'YES' ? true : false,
                rowIndex
              )
            }
            disabled
            selectOptions={{ YES: 'Yes', NO: 'No' }}
            material
          />
          <SelectFormField
            key="isMandatory"
            name="isMandatory"
            title="Mandatory"
            placeholder="Mandatory"
            value={
              isEmpty(values[rowIndex].isMandatory)
                ? ''
                : values[rowIndex].isMandatory
                  ? 'YES'
                  : 'NO'
            }
            error={
              touched[rowIndex].isMandatory ? errors[rowIndex].isMandatory : ''
            }
            onChange={(v) =>
              onFieldUpdate('isMandatory', v === 'YES' ? true : false, rowIndex)
            }
            disabled
            selectOptions={{ YES: 'Yes', NO: 'No' }}
            material
          />

          {!isGroupPolicy && (
            <>
              <SelectFormField
                key="coverPremiumType"
                name="coverPremiumType"
                title="Premium Type"
                placeholder="Premium Type"
                value={values[rowIndex].coverPremiumType}
                error={
                  touched[rowIndex].coverPremiumType
                    ? errors[rowIndex].coverPremiumType
                    : ''
                }
                onChange={(v) => onFieldUpdate('coverPremiumType', v, rowIndex)}
                selectOptions={{ PERCENTAGE: 'Percentage', VALUE: 'Value' }}
                disabled
                material
              />
              {values[rowIndex].coverPremiumType === 'PERCENTAGE' && (
                <EnhancedPercentageInput
                  key="coverPremiumPercentage"
                  name="coverPremiumPercentage"
                  title="Premium Percentage"
                  placeholder="Premium Percentage"
                  value={values[rowIndex].coverPremiumPercentage}
                  error={
                    touched[rowIndex].coverPremiumPercentage
                      ? errors[rowIndex].coverPremiumPercentage
                      : ''
                  }
                  onBlur={() => onFieldBlur('coverPremiumPercentage', rowIndex)}
                  onChange={(v) =>
                    onFieldUpdate(
                      'coverPremiumPercentage',
                      v.target.value,
                      rowIndex
                    )
                  }
                  disabled
                  material
                  customStyles={{
                    labelStyles: classes.labelCurrency,
                    inputStyles: classes.materialInput,
                  }}
                />
              )}
              {values[rowIndex].coverPremiumType === 'VALUE' && (
                <CurrencyFormField
                  key="coverPremiumValue"
                  name="coverPremiumValue"
                  title="Premium"
                  placeholder="Premium"
                  value={values[rowIndex].coverPremiumValue || ''}
                  error={
                    touched[rowIndex].coverPremiumValue
                      ? errors[rowIndex].coverPremiumValue
                      : ''
                  }
                  onBlur={() => onFieldBlur('coverPremiumValue', rowIndex)}
                  onChange={(v) =>
                    onFieldUpdate('coverPremiumValue', v.target.value, rowIndex)
                  }
                  disabled
                  material
                  currencySymbol={generalData?.currency?.Symbol}
                />
              )}
            </>
          )}

          <SelectFormField
            key="excessOnClaimType"
            name="excessOnClaimType"
            title="Excess Type"
            placeholder="Excess Type"
            value={values[rowIndex].excessOnClaimType}
            error={
              touched[rowIndex].excessOnClaimType
                ? errors[rowIndex].excessOnClaimType
                : ''
            }
            onChange={(v) => onFieldUpdate('excessOnClaimType', v, rowIndex)}
            selectOptions={{
              PERCENTAGE: 'Percentage',
              VALUE: 'Value',
              DAYS: 'Days',
            }}
            disabled
            material
          />
          {values[rowIndex].excessOnClaimType === 'PERCENTAGE' && (
            <EnhancedPercentageInput
              key="excessOnClaimPercentage"
              name="excessOnClaimPercentage"
              title="Excess"
              placeholder="Excess On Claim"
              value={values[rowIndex].excessOnClaimPercentage}
              error={
                touched[rowIndex].excessOnClaimPercentage
                  ? errors[rowIndex].excessOnClaimPercentage
                  : ''
              }
              onBlur={() => onFieldBlur('excessOnClaimPercentage', rowIndex)}
              onChange={(v) =>
                onFieldUpdate(
                  'excessOnClaimPercentage',
                  v.target.value,
                  rowIndex
                )
              }
              disabled
              material
              customStyles={{
                labelStyles: classes.labelCurrency,
                inputStyles: classes.materialInput,
              }}
            />
          )}
          {values[rowIndex].excessOnClaimType === 'VALUE' && (
            <CurrencyFormField
              key="excessOnClaimAmount"
              name="excessOnClaimAmount"
              title="Excess"
              placeholder="Excess On Claim"
              value={values[rowIndex].excessOnClaimAmount || ''}
              error={
                touched[rowIndex].excessOnClaimAmount
                  ? errors[rowIndex].excessOnClaimAmount
                  : ''
              }
              onBlur={() => onFieldBlur('excessOnClaimAmount', rowIndex)}
              onChange={(v) =>
                onFieldUpdate('excessOnClaimAmount', v.target.value, rowIndex)
              }
              disabled
              material
              currencySymbol={generalData?.currency?.Symbol}
            />
          )}
          {values[rowIndex].excessOnClaimType === 'DAYS' && (
            <EnhancedFormattedNumberInput
              key="excessOnClaimDays"
              name="excessOnClaimDays"
              title="Excess (Days)"
              placeholder="Excess On Claim (Days)"
              value={values[rowIndex].excessOnClaimDays}
              error={
                touched[rowIndex].excessOnClaimDays
                  ? errors[rowIndex].excessOnClaimDays
                  : ''
              }
              onBlur={() => onFieldBlur('excessOnClaimDays', rowIndex)}
              onChange={(v) =>
                onFieldUpdate('excessOnClaimDays', v.target.value, rowIndex)
              }
              disabled
              material
              customStyles={{
                labelStyles: classes.labelCurrency,
                inputStyles: classes.materialInput,
              }}
            />
          )}
          <EnhancedDatePicker
            key="coverEffectiveFrom"
            name="coverEffectiveFrom"
            title="Effective From"
            placeholder="Effective From"
            value={values[rowIndex].coverEffectiveFrom}
            error={
              touched[rowIndex].coverEffectiveFrom
                ? errors[rowIndex].coverEffectiveFrom
                : ''
            }
            onDateChange={(v) =>
              onFieldUpdate('coverEffectiveFrom', v, rowIndex)
            }
            disabled
            material
            onBlur={() => onFieldBlur('coverEffectiveFrom', rowIndex)}
            customStyles={{
              labelStyles: classes.boldLabel,
              inputStyles: classes.inputProps,
            }}
          />
          <EnhancedDatePicker
            key="coverEffectiveTo"
            name="coverEffectiveTo"
            title="Effective To"
            placeholder="Effective To"
            value={values[rowIndex].coverEffectiveTo}
            error={
              touched[rowIndex].coverEffectiveTo
                ? errors[rowIndex].coverEffectiveTo
                : ''
            }
            onDateChange={(v) => onFieldUpdate('coverEffectiveTo', v, rowIndex)}
            onBlur={() => onFieldBlur('coverEffectiveTo', rowIndex)}
            disabled
            material
            customStyles={{
              labelStyles: classes.boldLabel,
              inputStyles: classes.inputProps,
            }}
          />
        </div>
        <WidgetSection
          title="Amendment Details"
          narrowSpacing
          hideHeaderBorder
          isFieldsGrid
          fieldsPerRow={3}
        >
          <CurrencyFormField
            key="amendmentCoverSumInsured"
            name="amendmentCoverSumInsured"
            title="Sum Insured Variance"
            placeholder="Sum Insured Variance"
            value={values[rowIndex].amendmentCoverSumInsured || ''}
            error={
              touched[rowIndex].amendmentCoverSumInsured
                ? errors[rowIndex].amendmentCoverSumInsured
                : ''
            }
            onBlur={() => onFieldBlur('amendmentCoverSumInsured', rowIndex)}
            onChange={(v) =>
              onFieldUpdate(
                'amendmentCoverSumInsured',
                v.target.value,
                rowIndex
              )
            }
            disabled={disabledRow}
            material
            currencySymbol={generalData?.currency?.Symbol}
          />

          {!isGroupPolicy &&
            (values[rowIndex].coverPremiumType === 'VALUE' ? (
              <CurrencyFormField
                key="amendmentCoverPremiumValue"
                name="amendmentCoverPremiumValue"
                title="Premium Variance"
                placeholder="Premium Variance"
                value={values[rowIndex].amendmentCoverPremiumValue || ''}
                error={
                  touched[rowIndex].amendmentCoverPremiumValue
                    ? errors[rowIndex].amendmentCoverPremiumValue
                    : ''
                }
                onBlur={() =>
                  onFieldBlur('amendmentCoverPremiumValue', rowIndex)
                }
                onChange={(v) =>
                  onFieldUpdate(
                    'amendmentCoverPremiumValue',
                    v.target.value,
                    rowIndex
                  )
                }
                disabled={disabledRow}
                material
                currencySymbol={generalData?.currency?.Symbol}
              />
            ) : (
              <EnhancedPercentageInput
                key="amendmentCoverPremiumPercentage"
                name="amendmentCoverPremiumPercentage"
                title="Premium Percentage Variance"
                placeholder="Premium Percentage Variance"
                value={values[rowIndex].amendmentCoverPremiumPercentage}
                error={
                  touched[rowIndex].amendmentCoverPremiumPercentage
                    ? errors[rowIndex].amendmentCoverPremiumPercentage
                    : ''
                }
                onBlur={() =>
                  onFieldBlur('amendmentCoverPremiumPercentage', rowIndex)
                }
                onChange={(v) =>
                  onFieldUpdate(
                    'amendmentCoverPremiumPercentage',
                    v.target.value,
                    rowIndex
                  )
                }
                disabled={disabledRow}
                material
                customStyles={{
                  labelStyles: classes.labelCurrency,
                  inputStyles: classes.materialInput,
                }}
              />
            ))}

          {values[rowIndex].excessOnClaimType === 'PERCENTAGE' && (
            <EnhancedPercentageInput
              key="amendmentExcessOnClaimPercentage"
              name="amendmentExcessOnClaimPercentage"
              title="Excess Variance"
              placeholder="Excess On Claim"
              value={values[rowIndex].amendmentExcessOnClaimPercentage}
              error={
                touched[rowIndex].amendmentExcessOnClaimPercentage
                  ? errors[rowIndex].amendmentExcessOnClaimPercentage
                  : ''
              }
              onBlur={() =>
                onFieldBlur('amendmentExcessOnClaimPercentage', rowIndex)
              }
              onChange={(v) =>
                onFieldUpdate(
                  'amendmentExcessOnClaimPercentage',
                  v.target.value,
                  rowIndex
                )
              }
              disabled={disabledRow}
              material
              customStyles={{
                labelStyles: classes.labelCurrency,
                inputStyles: classes.materialInput,
              }}
            />
          )}
          {values[rowIndex].excessOnClaimType === 'VALUE' && (
            <CurrencyFormField
              key="amendmentExcessOnClaimAmount"
              name="amendmentExcessOnClaimAmount"
              title="Excess Variance"
              placeholder="Excess On Claim"
              value={values[rowIndex].amendmentExcessOnClaimAmount || ''}
              error={
                touched[rowIndex].amendmentExcessOnClaimAmount
                  ? errors[rowIndex].amendmentExcessOnClaimAmount
                  : ''
              }
              onBlur={() =>
                onFieldBlur('amendmentExcessOnClaimAmount', rowIndex)
              }
              onChange={(v) =>
                onFieldUpdate(
                  'amendmentExcessOnClaimAmount',
                  v.target.value,
                  rowIndex
                )
              }
              disabled={disabledRow}
              material
              currencySymbol={generalData?.currency?.Symbol}
            />
          )}
          {values[rowIndex].excessOnClaimType === 'DAYS' && (
            <EnhancedFormattedNumberInput
              key="amendmentExcessOnClaimDays"
              name="amendmentExcessOnClaimDays"
              title="Excess Variance (Days)"
              placeholder="Excess On Claim (Days)"
              value={values[rowIndex].amendmentExcessOnClaimDays}
              error={
                touched[rowIndex].amendmentExcessOnClaimDays
                  ? errors[rowIndex].amendmentExcessOnClaimDays
                  : ''
              }
              onBlur={() => onFieldBlur('amendmentExcessOnClaimDays', rowIndex)}
              onChange={(v) =>
                onFieldUpdate(
                  'amendmentExcessOnClaimDays',
                  v.target.value,
                  rowIndex
                )
              }
              disabled={disabledRow}
              material
              customStyles={{
                labelStyles: classes.labelCurrency,
                inputStyles: classes.materialInput,
              }}
            />
          )}
        </WidgetSection>
      </div>
    );
  };

  return (
    <WidgetPaper>
      <WidgetSection title="Policy Covers">
        {values?.map((v: IAmendmentCover, index: number) =>
          renderRow(v, index)
        )}
      </WidgetSection>
    </WidgetPaper>
  );
};

export default PolicyCovers;
