import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../../../components/widgets/custom-listing-filter';
import { FormInputTypes } from '../../../../DynamicForm';
import { IAbstractRecord } from '../../../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  Production_Policy_PolicyNumber: {
    name: 'Production_Policy_PolicyNumber',
    title: 'Policy #',
    type: EnhancedTableHeaderType.Link,
    urlTemplate: '/reinsurance/prod-dist/{policyId}',
    openUrlInNewTab: false,
  },
  PolicyLine_Name: {
    name: 'PolicyLine_Name',
    title: 'Line',
    type: EnhancedTableHeaderType.Text,
  },
  PolicySubLine_Name: {
    name: 'PolicySubLine_Name',
    title: 'Subline',
    type: EnhancedTableHeaderType.Text,
  },
  PolicySubLine_Category: {
    name: 'PolicySubLine_Category',
    title: 'Subline Category',
    type: EnhancedTableHeaderType.Text,
  },
  PolicyPlan_Name: {
    name: 'PolicyPlan_Name',
    title: 'Plan',
    type: EnhancedTableHeaderType.Text,
  },
  Production_Policy_PolicyCurrency: {
    name: 'Production_Policy_PolicyCurrency',
    title: 'Policy Currency',
    type: EnhancedTableHeaderType.Text,
  },
  Production_Policy_PolicyEffectiveDate: {
    name: 'Production_Policy_PolicyEffectiveDate',
    title: 'Policy Effective Date',
    type: EnhancedTableHeaderType.Date,
  },
  Production_Policy_PolicyExpiryDate: {
    name: 'Production_Policy_PolicyExpiryDate',
    title: 'Policy Expiry Date',
    type: EnhancedTableHeaderType.Date,
  },
};

export const filterSectionsContent = (
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] => [
  {
    title: 'Filters',
    inputs: {
      line: {
        name: 'line',
        title: 'Line',
        type: FormInputTypes.newmultiautocomplete,
        value: filterValues?.line || null,
        placeholder: 'Select a Line',
        triggerUpdate: true,
      },
      subline: {
        name: 'subline',
        title: 'Subline',
        type: FormInputTypes.newmultiautocomplete,
        value: filterValues?.subline || null,
        placeholder: 'Select subline',
        triggerUpdate: true,
      },
      effectiveDate: {
        name: 'effectiveDate',
        title: 'Policy Effective Date',
        type: FormInputTypes.daterange,
        value: filterValues?.effectiveDate || null,
        triggerUpdate: true,
        canClearDate: true,
      },
    },
  },
];
