import { quote } from '../../components/custom/QuotesCards';
import { IDocument } from '../../models/file';
import { getDefaultDate } from '../../utils/date-utils';
import dayjs from 'dayjs';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';

export function entityToPolicyDetails(data: any): any {
  const policyInfo =
    data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties;
  const insuredInfo =
    data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
      ?.ExpatDetailsID?.views?.Insurance_all?.properties;
  const policyDocumnetInfo =
    data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
      ?.PolicyDocument;
  const AdditionalDocumentInfo =
    data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
      ?.ExpatDetailsID?.views?.Insurance_all?.properties?.AdditionalDocument;
  const regeneratedDocuments = data?.Insurance?.queries?.regeneratedDocuments;
  const additionalDocuments = data?.Insurance?.queries?.additionalDocuments;

  return {
    nameTitle: policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties,
    fullName:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.FullName,
    firstName:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.FirstName,
    middleName:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.MiddleName,
    lastName:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.LastName,
    title:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.Title?.Id,
    insuredName: insuredInfo?.InsuredName,
    insuredFirstName: insuredInfo?.InsuredFirstName,
    insuredMiddleName: insuredInfo?.InsuredMiddleName,
    insuredLastName: insuredInfo?.InsuredLastName,
    insuredDateOfBirth: insuredInfo?.InsuredDateOfBirth,
    insuredNationality: insuredInfo?.InsuredNationality?.Title,
    insuredNationalityId: insuredInfo?.InsuredNationality?.Id,
    insuredGender: capitalizeFirstLetter(insuredInfo?.InsuredGender),
    insuredGenderCode: insuredInfo?.InsuredGender,
    insuredPassportNumber: insuredInfo?.InsuredPassportNumber,
    insuredPlan: insuredInfo?.InsuredPlan?.Code,
    insuredOccupation:
      insuredInfo?.InsuredOccupation?.Title +
      (insuredInfo?.InsuredOccupation?.TitleAr
        ? ' - ' + insuredInfo?.InsuredOccupation?.TitleAr
        : ''),
    insuredOccupationId: insuredInfo?.InsuredOccupation?.Id,
    statusReason: policyInfo?.PolicyStatusReason,
    barCodeNumber: insuredInfo?.NSSFFullCode,
    lineOfBusiness: policyInfo?.LineOfBusiness?.Title,
    agencyName:
      policyInfo?.AssignedAgencyID?.views?.Insurance_all?.properties
        ?.AgencyName,
    agentName:
      policyInfo?.AssignedAgentID?.views?.Insurance_all?.properties?.FirstName +
      ' ' +
      policyInfo?.AssignedAgentID?.views?.Insurance_all?.properties?.LastName,
    customerName:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.FullName,
    createdBy: `${data?.Insurance?.entities?.policy?.queries?.insurance?.relatedUsers[0]?.createdBy_firstName} ${data?.Insurance?.entities?.policy?.queries?.insurance?.relatedUsers[0]?.createdBy_lastName}`,
    createdOn: policyInfo?.createdOn,
    modifiedBy: `${data?.Insurance?.entities?.policy?.queries?.insurance?.relatedUsers[0]?.modifiedBy_firstName} ${data?.Insurance?.entities?.policy?.queries?.insurance?.relatedUsers[0]?.modifiedBy_lastName}`,
    modifiedOn: policyInfo?.modifiedOn,

    email: policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.Email,
    detailsPhoneType: capitalizeFirstLetter(
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.PhoneType
    ),
    phoneType:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.PhoneType,
    mobileNumber:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties
        ?.MobileNumber,
    countryId:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.Address
        ?.Country?.Id,
    country:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.Address
        ?.Country?.Title,
    city: policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.Address
      ?.City,
    street:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.Address
        ?.Street,
    building:
      policyInfo?.PolicyHolderID?.views?.Insurance_all?.properties?.Address
        ?.Building,
    policyNumber: policyInfo?.PolicyNumber,
    policyEffectiveDate: policyInfo?.PolicyEffectiveDate,
    cancelationEffectiveDate: policyInfo?.CancelationEffectiveDate,
    policyIssueDate: policyInfo?.PolicyIssueDate,
    policyExpiryDate: policyInfo?.PolicyExpiryDate,

    fullPolicyNumber: policyInfo?.FullPolicyNumber,
    isRenewal:
      insuredInfo?.PolicyID?.views?.Insurance_all?.properties?.IsRenewal,
    renewalNumber:
      insuredInfo?.PolicyID?.views?.Insurance_all?.properties?.RenewalNumber,
    pendingDocumentRegeneration: policyInfo?.PendingDocumentRegeneration,
    policyDocument: {
      id: policyDocumnetInfo?.id,
      location: policyDocumnetInfo?.location,
      path: policyDocumnetInfo?.path,
      fileName: policyDocumnetInfo?.fileName,
      length: policyDocumnetInfo?.length,
      contentType: policyDocumnetInfo?.contentType,
    },
    additionalDocument: {
      id: AdditionalDocumentInfo?.id,
      location: AdditionalDocumentInfo?.location,
      path: AdditionalDocumentInfo?.path,
      fileName: AdditionalDocumentInfo?.fileName,
      length: AdditionalDocumentInfo?.length,
      contentType: AdditionalDocumentInfo?.contentType,
    },
    regeneratedDocuments: getPolicyDocuments(regeneratedDocuments),
    additionalDocuments: getPolicyDocuments(additionalDocuments),
    userCanUploadAdditionalDocument:
      data?.Insurance?.entities?.policy?.actions
        ?.able_To_UploadAdditionalDocument,
  };
}

export function graphqlEntityToQuoteDetails(
  data: any,
  currencySymbol: string,
  lob: string
): quote[] {
  const policyExpatQuotes =
    data?.Insurance?.entities?.policy?.queries?.insurance?.policyExpatQuotes ||
    [];

  return policyExpatQuotes.map((quoteData: any) => ({
    lineOfBusiness: lob,
    id: quoteData.insuranceQuote_Id, // Assuming there's no ID in policyExpatQuotes, adjust if necessary
    title: quoteData.insuranceQuote_Name,
    status: quoteData.insuranceQuote_Status,
    statusReason: quoteData.insuranceQuote_StatusReason,
    createdOn: '', // Adjust if there's a createdOn field in policyExpatQuotes
    modifiedOn: quoteData.insuranceQuote_modifiedOn,
    hasOnlyMainAmount: true,
    hasCoverage: true,
    coverageAmount: '',
    coverageAmountCurrencySymbol: '',
    premiumAmount: quoteData.insuranceQuote_TotalAnnualPremium,
    premiumAmountCurrencySymbol: currencySymbol,

    policyId:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.ExpatDetailsID?.views?.Insurance_all?.properties?.PolicyID?.id,
    fullPolicyNumber:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.ExpatDetailsID?.views?.Insurance_all?.properties?.PolicyID?.views
        ?.Insurance_all?.properties?.FullPolicyNumber,
    disabled: true,
    expatDetails:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.ExpatDetailsID?.views?.Insurance_all?.properties,
    InsuredAge: quoteData.insuranceQuoteExpatDetails_InsuredAge,
    InsuredGender: quoteData.insuranceQuoteExpatDetails_InsuredGender,
    InsuredNationality: quoteData.insuranceQuoteExpatDetails_InsuredNationality,
  }));
}

function getPolicyDocuments(policyDocuments: any[]) {
  const policDoc: IDocument[] = [];
  if (policyDocuments && policyDocuments.length > 0) {
    policyDocuments.map((document) => {
      policDoc.push({
        id: document?.docs_Id,
        code: document?.docs_DocumentCode,
        version: document?.docs_Version,
        createdOn: getDefaultDate(
          dayjs(new Date(document?.docs_createdOn))?.toDate()
        )?.toDateString(),
        file: document?.docs_PolicyDocument,
      });
    });
  }
  return policDoc;
}
