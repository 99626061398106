import { gql } from '@apollo/client';

export const getTreatyDocumentsQuery = gql`
  query getTreatyDocuments($TreatyID: String!) {
    Production {
      queries {
        GetTreatyDocuments(TreatyID: $TreatyID) {
          production_TreatyDocuments_createdOn
          production_TreatyDocuments_DocumentName
          production_TreatyDocuments_DocumentType
          production_TreatyDocuments_Id
          production_TreatyDocuments_Version
          production_TreatyDocuments_Document {
            contentType
            fileName
            id
            length
            location
            path
          }
        }
      }
    }
  }
`;
