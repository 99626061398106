import React, { useEffect, useMemo, useState } from 'react';
import { IListingData } from '../../../../../../models/listing';
import { RVPageHeaders } from './content';
import { IEnhanceTableHeaderClickable } from '../../../../../../components/enhanced-table';
import { useLazyQuery } from '@apollo/client';
import { getReceivablesPaymentsOfBrokerQuery } from './queries';
import { useParams } from 'react-router-dom';
import { mapToPageListingData } from './utils';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../../../../../constants';
import { lookupListAsRecordObject } from '../../../../../../utils/graph-utils';
import ReceiptVoucherPopupShell from './add-receipt-vouchers-popup/ReceiptVoucherPopupShell';
import { IVoucherPopup } from '../shared/types';
import { IAbstractRecord } from '../../../../../../models';
import {
  ActionTarget,
  IEnhancedMenuItem,
} from '../../../../../../components/form-fields/table';
import ListingTable from '../../../../../../components/form-fields/table/ListingTable';

const ReceiptVouchersTable: React.FC<{
  paymentMethods: Record<string, string>;
  onSuccess: () => void;
}> = ({ paymentMethods, onSuccess }) => {
  const businessPartnerId = useParams().id;
  const [booted, setBooted] = useState<boolean>(false);
  const [voucherPopup, setVoucherPopup] = useState<IVoucherPopup>({
    open: false,
    isEdit: false,
    currentPaymentId: null,
  });
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageNumber: 1,
    pageSize: 5,
    totalCount: 0,
  });
  const [getReceivablesPaymentsOfBroker] = useLazyQuery(
    getReceivablesPaymentsOfBrokerQuery
  );

  const tableActions: IEnhancedMenuItem[] = useMemo(() => {
    return [
      {
        name: 'addreceiptvoucher',
        title: '+ Add Receipt Voucher',
        target: ActionTarget.Entity,
        rowIndependant: true,
        onClick: () => {
          setVoucherPopup({
            open: true,
            isEdit: false,
            currentPaymentId: null,
          });
        },
      },
    ];
  }, []);

  const initialize = async () => {
    try {
      setBooted(false);

      const res = await getReceivablesPaymentsOfBroker({
        variables: {
          businessPartnerID: businessPartnerId,
        },
      });

      const { data } = res;
      const items = data.Accounting.queries.GetReceivablesPaymentsOfBroker;

      const currencies = lookupListAsRecordObject(
        data.Core.lookups.currencies,
        false,
        'Id',
        'Symbol'
      );
      const pagedItems = mapToPageListingData(
        items,
        paymentMethods,
        currencies
      );

      setTableData({
        pagedItems: pagedItems,
        pageNumber: 1,
        pageSize: 5,
        totalCount: items?.length,
      });
      setBooted(true);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods]);

  (RVPageHeaders.paymentDate as IEnhanceTableHeaderClickable).callback = (
    payload: IAbstractRecord
  ) => {
    setVoucherPopup({
      open: true,
      isEdit: true,
      currentPaymentId: payload.columns.paymentId,
    });
  };

  return (
    <>
      <ListingTable
        name="receiptVouchers"
        inlineTitle="Receipt Vouchers"
        headers={RVPageHeaders}
        data={tableData}
        loader={!booted}
        actions={tableActions}
        disableSelection
        inline
      />
      {voucherPopup.open && (
        <ReceiptVoucherPopupShell
          open={voucherPopup.open}
          onSuccess={() => {
            initialize();
            onSuccess();
          }}
          onClose={() =>
            setVoucherPopup({
              ...voucherPopup,
              open: false,
            })
          }
          isEdit={voucherPopup.isEdit}
          currentPaymentId={voucherPopup.currentPaymentId}
        />
      )}
    </>
  );
};

export default ReceiptVouchersTable;
