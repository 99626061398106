import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { ITravelPlanRatesDrawerInfo } from '.';

export const initialValues: ITravelPlanRatesDrawerInfo = {
  planCurrency: '',
  policyCover: '',
  ageFrom: '',
  ageTo: '',
  gender: '',
  travelDurationFrom: '',
  travelDurationTo: '',
  travelDestination: '',
  nbOfAdherent: '',
  premium: '',
  isMain: false,
  isMandatory: false,
  isEditable: false,
  sumInsured: '',
  sumInsuredIsAdditive: false,
  tpaType: '',
  tpaAmount: '',
  tpaFeesPercentage: '',
  tpaFeesApplicableOn: '',
  minTPAFees: '',
  maxTPAFees: '',
  excessOnClaimType: '',
  excessOnClaimAmount: '',
  excessOnClaimPercentage: '',
  excessOnClaimDays: '',
  clauseReference: '',
};

export const getInputs = (
  values: ITravelPlanRatesDrawerInfo,
  lovs: Record<string, Record<string, any>> = {},
  planCurrency: string,
  planCurrencySymbol: string
): Record<string, DynamicFormInputType> => {
  return {
    planCurrency: {
      name: 'planCurrency',
      title: 'Plan Currency',
      type: FormInputTypes.chips,
      placeholder: 'Plan Currency',
      value: planCurrency,
      multiple: false,
      disabled: true,
      required: true,
      hidden: true,
    },
    policyCover: {
      name: 'policyCover',
      title: 'Policy Cover',
      type: FormInputTypes.newautocomplete,
      placeholder: 'Type to search for Policy Cover',
      value: values?.policyCover,
      disabled: false,
      required: true,
    },
    isMain: {
      name: 'isMain',
      title: 'Main',
      type: FormInputTypes.switch,
      value: values.isMain,
      required: true,
    },
    isMandatory: {
      name: 'isMandatory',
      title: 'Mandatory',
      type: FormInputTypes.switch,
      value: values.isMandatory,
      required: true,
    },
    isEditable: {
      name: 'isEditable',
      title: 'Editable',
      type: FormInputTypes.switch,
      value: values.isEditable,
      required: true,
    },
    nbOfAdherent: {
      name: 'nbOfAdherent',
      title: 'Number Of Adherent',
      placeholder: 'Number Of Adherent',
      type: FormInputTypes.number,
      value: values.nbOfAdherent,
      required: true,
      minNumber: 0,
    },
    ageFrom: {
      name: 'ageFrom',
      title: 'Age From',
      placeholder: 'Age From',
      type: FormInputTypes.number,
      value: values.ageFrom,
      required: true,
      minNumber: 0,
      maxNumber: 100,
    },
    ageTo: {
      name: 'ageTo',
      title: 'Age To',
      placeholder: 'Age To',
      type: FormInputTypes.number,
      value: values.ageTo,
      required: true,
      maxNumber: 100,
    },
    gender: {
      name: 'gender',
      title: 'Gender',
      type: FormInputTypes.chips,
      placeholder: 'Gender',
      value: values.gender,
      multiple: false,
      disabled: false,
      required: true,
      selectOptions: lovs?.gender,
    },
    travelDurationFrom: {
      name: 'travelDurationFrom',
      title: 'Travel Duration From (in Days)',
      placeholder: 'Travel Duration From',
      type: FormInputTypes.number,
      value: values.travelDurationFrom,
      required: true,
      minNumber: 0,
    },
    travelDurationTo: {
      name: 'travelDurationTo',
      title: 'Travel Duration To (in Days)',
      placeholder: 'Travel Duration To',
      type: FormInputTypes.number,
      value: values.travelDurationTo,
      required: true,
    },
    travelDestination: {
      name: 'travelDestination',
      title: 'Travel Destination',
      type: FormInputTypes.chips,
      placeholder: 'Travel Destination',
      value: values.travelDestination,
      multiple: false,
      disabled: false,
      required: true,
      selectOptions: lovs?.travelDestinations,
    },
    sumInsured: {
      name: 'sumInsured',
      title: 'SumInsured',
      placeholder: 'SumInsured',
      type: FormInputTypes.currency,
      value: values.sumInsured,
      required: true,
      minNumber: 0,
      maxDecimalPercision: 3,
      useCurrencySymbol: true,
      currencySymbol: planCurrencySymbol,
    },
    sumInsuredIsAdditive: {
      name: 'sumInsuredIsAdditive',
      title: 'Additive SumInsured',
      type: FormInputTypes.switch,
      value: values.sumInsuredIsAdditive,
      required: true,
    },
    premium: {
      name: 'premium',
      title: 'Premium',
      placeholder: 'Premium',
      type: FormInputTypes.currency,
      value: values.premium,
      required: true,
      minNumber: 0,
      maxDecimalPercision: 3,
      useCurrencySymbol: true,
      currencySymbol: planCurrencySymbol,
    },
    tpaType: {
      name: 'tpaType',
      title: 'TPA Type',
      type: FormInputTypes.chips,
      placeholder: 'TPA Type',
      value: values.tpaType,
      multiple: false,
      required: true,
      selectOptions: {
        PERCENTAGE: 'Percentage',
        AMOUNT: 'Amount',
      },
    },
    tpaAmount: {
      name: 'tpaAmount',
      title: 'TPA Amount',
      placeholder: 'TPA Amount',
      type: FormInputTypes.currency,
      value: values.tpaAmount,
      required: values.tpaType === 'AMOUNT',
      minNumber: 0,
      maxDecimalPercision: 3,
      useCurrencySymbol: true,
      currencySymbol: planCurrencySymbol,
      hidden: values.tpaType !== 'AMOUNT',
    },
    tpaFeesPercentage: {
      name: 'tpaFeesPercentage',
      title: 'TPA Fees Percentage',
      placeholder: 'TPA Fees Percentage',
      type: FormInputTypes.percentage,
      value: values.tpaFeesPercentage,
      required: values.tpaType === 'PERCENTAGE',
      hidden: values.tpaType !== 'PERCENTAGE',
    },
    tpaFeesApplicableOn: {
      name: 'tpaFeesApplicableOn',
      title: 'TPA Fees Applicable On',
      type: FormInputTypes.chips,
      placeholder: 'TPA Fees ApplicableOn',
      value: values.tpaFeesApplicableOn || '',
      multiple: false,
      disabled: false,
      required: values.tpaType === 'PERCENTAGE',
      selectOptions: {
        NET_PREMIUM: 'Net Premium',
        GROSS_PREMIUM: 'Gross Premium',
      },
      hidden: values.tpaType !== 'PERCENTAGE',
    },
    minTPAFees: {
      name: 'minTPAFees',
      title: 'Min TPA Fees',
      placeholder: 'Min TPA Fees',
      type: FormInputTypes.currency,
      value: values.minTPAFees,
      required: values.tpaType === 'PERCENTAGE',
      minNumber: 0,
      maxDecimalPercision: 3,
      useCurrencySymbol: true,
      currencySymbol: planCurrencySymbol,
      hidden: values.tpaType !== 'PERCENTAGE',
    },
    maxTPAFees: {
      name: 'maxTPAFees',
      title: 'Max TPA Fees',
      placeholder: 'Max TPA Fees',
      type: FormInputTypes.currency,
      value: values.maxTPAFees,
      required: values.tpaType === 'PERCENTAGE',
      minNumber: 0,
      maxDecimalPercision: 3,
      useCurrencySymbol: true,
      currencySymbol: planCurrencySymbol,
      hidden: values.tpaType !== 'PERCENTAGE',
    },
    excessOnClaimType: {
      name: 'excessOnClaimType',
      title: 'Excess On Claim Type',
      placeholder: 'Excess On Claim Type',
      type: FormInputTypes.chips,
      value: values.excessOnClaimType,
      multiple: false,
      required: true,
      selectOptions: {
        PERCENTAGE: 'Percentage',
        VALUE: 'Value',
        DAYS: 'Days',
      },
    },
    excessOnClaimAmount: {
      name: 'excessOnClaimAmount',
      title: 'Excess On Claim Amount',
      placeholder: 'Excess On Claim Amount',
      type: FormInputTypes.currency,
      value: values.excessOnClaimAmount,
      required: values.excessOnClaimType === 'VALUE',
      minNumber: 0,
      maxDecimalPercision: 3,
      useCurrencySymbol: true,
      currencySymbol: planCurrencySymbol,
      hidden: values.excessOnClaimType !== 'VALUE',
    },
    excessOnClaimPercentage: {
      name: 'excessOnClaimPercentage',
      title: 'Excess On Claim (Percentage)',
      placeholder: 'Excess On Claim (Percentage)',
      type: FormInputTypes.percentage,
      value: values.excessOnClaimPercentage,
      required: values.excessOnClaimType === 'PERCENTAGE',
      hidden: values.excessOnClaimType !== 'PERCENTAGE',
    },
    excessOnClaimDays: {
      name: 'excessOnClaimDays',
      title: 'Excess On Claim (Days)',
      placeholder: 'Excess On Claim (Days)',
      type: FormInputTypes.number,
      value: values.excessOnClaimDays,
      required: values.excessOnClaimType === 'DAYS',
      hidden: values.excessOnClaimType !== 'DAYS',
      minNumber: 0,
    },
    clauseReference: {
      name: 'clauseReference',
      title: 'Clause Reference',
      placeholder: 'Clause Reference',
      type: FormInputTypes.newautocomplete,
      value: values.clauseReference,
      required: false,
      order: 15,
    },
  };
};
