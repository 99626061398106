import React, { useEffect, useMemo, useState } from 'react';
import GenericDrawer from '../../../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../../../DynamicForm/DynamicForm';
import { getInputs } from './content';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  getError,
  lookupListAsRecordObject,
} from '../../../../utils/graph-utils';
import {
  createFacultativeMutation,
  getActiveProposals,
  getProposalDetailsQuery,
} from './queries';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../components/ToastErrorMessage';
import { extractProposalDetails } from './utils';
import { useNavigate } from 'react-router-dom';
import ToastSuccessMessage from '../../../../components/ToastSuccessMessage';
import { IFacultativeDrawerLovs, IFacultativeDrawerValues } from '.';
import { getReinsurersListQuery } from '../shared/queries';
import { DEFAULT_ERROR_TEXT } from '../../../../constants';

interface INewFacultativeDrawer {
  isOpen: boolean;
  onClose: () => void;
}

const initialValues: IFacultativeDrawerValues = {
  proposal: '',
  company: '',
  currencySymbol: '',
  currencyCode: '',
  cover: '',
  coverEffectiveDate: '',
  coverExpiryDate: '',
  reinsurer: '',
};

const NewFacultativeDrawer: React.FC<INewFacultativeDrawer> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const { data: activeProposalsData, loading: activeProposalsLoading } =
    useQuery(getActiveProposals);
  const [getReinsurersListLazy, { loading: reinsurersLoading }] = useLazyQuery(
    getReinsurersListQuery
  );
  const [getProposalDetailsLazy, { loading: proposalDetailsLoading }] =
    useLazyQuery(getProposalDetailsQuery);
  const [createFacultative, { loading }] = useMutation(
    createFacultativeMutation
  );
  // const [lodingFields, setLoadingFields] = useState<
  //   Record<'proposal' | 'reinsurer' | 'cover', boolean>
  // >({ proposal: activeProposalsLoading, reinsurer: reinsurersLoading, cover: proposalDetailsLoading });
  const [lovs, setLovs] = useState<IFacultativeDrawerLovs>({
    proposals: {},
    reinsurers: {
      options: {},
      totalCount: 0,
    },
    covers: {
      name: {},
      dates: {},
    },
  });

  const [values, setValues] = useState<IFacultativeDrawerValues>(initialValues);

  const formInputs = useMemo(() => getInputs(lovs), [lovs]);

  useEffect(() => {
    if (activeProposalsData) {
      const proposals = lookupListAsRecordObject(
        activeProposalsData.Production?.queries?.GetActiveProposals,
        false,
        'production_Proposal_Id',
        'production_Proposal_ProposalName'
      );

      setLovs((prev) => ({
        ...prev,
        proposals,
      }));
    }
  }, [activeProposalsData]);

  const fetchData = async (value: string, values: IFacultativeDrawerValues) => {
    try {
      const [proposalResponse, reinsurersResponse] = await Promise.all([
        getProposalDetailsLazy({
          variables: {
            proposalId: value,
          },
        }),
        getReinsurersListLazy({
          variables: {
            SelectedCompany: value,
            KeywordSearch: null,
            pagination: {
              pageSize: 10,
              pageNumber: 1,
            },
          },
        }),
      ]);

      const { data: proposalData, error: proposalError } = proposalResponse;
      const { data: reinsurersData, error: reinsurersError } =
        reinsurersResponse;

      const { company, currencyCode, currencySymbol, covers } =
        extractProposalDetails(proposalData);

      const reinsurers = {
        options: lookupListAsRecordObject(
          reinsurersData?.Production?.queries?.ReinsurersList?.items,
          false,
          'salesforceManagement_BusinessPartner_Id',
          'salesforceManagement_BusinessPartner_FullName'
        ),
        totalCount:
          reinsurersData?.Production?.queries?.getProposalList?.paging
            ?.totalCount,
      };

      setLovs((prev) => ({
        ...prev,
        covers,
        reinsurers,
      }));

      values.currencyCode = currencyCode;
      values.currencySymbol = currencySymbol;

      if (proposalError) {
        toast.error(
          <ToastErrorMessage>{getError(proposalError)}</ToastErrorMessage>
        );
        values.company = '';
        values.currencyCode = '';
        values.currencySymbol = '';
      } else {
        values.company = company;
      }

      if (reinsurersError) {
        toast.error(
          <ToastErrorMessage>{getError(reinsurersError)}</ToastErrorMessage>
        );
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const handleChange = async (
    fieldName: string,
    value: string,
    values: IFacultativeDrawerValues
  ) => {
    if (fieldName === 'proposal' && value) {
      await fetchData(value, values);
    } else if (fieldName === 'cover' && value) {
      const coverId = value as string;
      const cover = lovs.covers.dates[coverId];
      values.coverEffectiveDate = cover.effectiveDate;
      values.coverExpiryDate = cover.expiryDate;
    }

    setValues(values);
  };

  const handleSubmit = async () => {
    try {
      const { data, errors } = await createFacultative({
        variables: {
          Proposal: values.proposal,
          Company: values.company,
          ProposalCover: values.cover,
          Reinsurer: values.reinsurer,
        },
      });

      if (!errors) {
        const createdFacId = data?.production?.actions?.createFacultative?.id;

        if (createdFacId) {
          toast.success(
            <ToastSuccessMessage>
              Facultative created successfully
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            navigate('/reinsurance/facultatives/' + createdFacId);
          }, 750);
        }
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
    }
  };

  return (
    <GenericDrawer
      title={'Add New Facultative'}
      onClose={() => onClose()}
      isOpen={isOpen}
    >
      <DynamicForm
        disableForm={activeProposalsLoading || loading}
        isSubmitButtonDisabled={loading}
        inputs={formInputs}
        onSubmit={handleSubmit}
        buttonText={'Submit'}
        loadingFields={{
          proposal: activeProposalsLoading,
          reinsurer: reinsurersLoading,
          cover: proposalDetailsLoading,
        }}
        onChange={handleChange}
        onAutocompleteSearch={(
          fieldName: string,
          value: string,
          pagination
        ) => {
          if (fieldName === 'reinsurer' && values.company) {
            return getReinsurersListLazy({
              variables: {
                SelectedCompany: values.company,
                KeywordSearch: value,
                pagination,
              },
            }).then((result) => {
              return {
                options: lookupListAsRecordObject(
                  result.data?.Production?.queries?.ReinsurersList?.items,
                  false,
                  'salesforceManagement_BusinessPartner_Id',
                  'salesforceManagement_BusinessPartner_FullName'
                ),
                totalCount:
                  result.data?.Production?.queries?.getProposalList?.paging
                    ?.totalCount,
              };
            });
          }

          return new Promise<{
            options: Record<string, string>;
            totalCount: number;
          }>((resolve) => {
            resolve({ options: {}, totalCount: 0 });
          });
        }}
      />
    </GenericDrawer>
  );
};

export default NewFacultativeDrawer;
