import React, { useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import Loader from '../../components/Loader';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import { useMutation } from '@apollo/client';
import { getMarineInputs } from './content';
import { IMarineDrawerProps } from '.';
import { updateMarine } from './queries';

const MarineDrawer: React.FC<IMarineDrawerProps> = ({
  open,
  onClose,
  onSuccess,
  data,
  lovs,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [saveMarineData] = useMutation(updateMarine());

  const inputForm = useMemo(() => getMarineInputs(lovs, data), [lovs, data]);

  const submitForm = async (formValues: Record<string, any>) => {
    try {
      setFormDisabled(true);
      setSubmitButtonState('loading');

      const normalizedValues = normaliseDynamicValues(
        getMarineInputs(lovs, undefined),
        formValues
      );

      const { errors } = await saveMarineData({
        variables: { ...normalizedValues },
      });

      if (errors) {
        throw new Error('Submission failed');
      }

      toast.success(
        <ToastSuccessMessage>
          Marine details updated successfully!
        </ToastSuccessMessage>
      );
      setSubmitButtonState('success');
      onSuccess();
      onClose();
    } catch (err) {
      toast.error(<ToastErrorMessage>Something went wrong</ToastErrorMessage>);
      setSubmitButtonState(undefined);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title="Modify Marine Details"
      isOpen={!!open}
      onClose={onClose}
    >
      {!data ? (
        <Loader />
      ) : (
        <DynamicForm
          inputs={inputForm}
          onSubmit={submitForm}
          buttonText="Submit"
          submitButtonState={submitButtonState}
          disableForm={formDisabled}
          title="Marine Information"
        />
      )}
    </GenericDrawer>
  );
};

export default MarineDrawer;
