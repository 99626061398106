import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../../../../constants';
import { EnhancedButtonStatus } from '../../../../../components/EnhancedButton';
import Loader from '../../../../../components/Loader';
import {
  DynamicFormInputType,
  FormInputTypes,
} from '../../../../../DynamicForm';
import GenericDrawer from '../../../../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../../../../DynamicForm/DynamicForm';
import { useMutation } from '@apollo/client';
import { createAmendmentMutation } from './queries';
import { useNavigate, useParams } from 'react-router-dom';
import { getError } from '../../../../../utils/graph-utils';
import ToastSuccessMessage from '../../../../../components/ToastSuccessMessage';

interface IAddAmendmentDrawerProps {
  onClose: () => void;
  open: boolean;
  data: Record<string, Record<string, string>>;
}

const AddAmendmentDrawer: React.FC<IAddAmendmentDrawerProps> = ({
  open,
  onClose,
  data,
}) => {
  const { id: currentPolicyId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [createAmendment] = useMutation(createAmendmentMutation);

  const inputsForm = useMemo((): Record<string, DynamicFormInputType> => {
    return {
      type: {
        name: 'type',
        title: 'Type',
        type: FormInputTypes.chips,
        value: '',
        required: true,
        selectOptions: data.types,
      },
      subtype: {
        name: 'subtype',
        title: 'Sub Type',
        type: FormInputTypes.chips,
        value: '',
        selectOptions: data.subTypes,
        conditionalRequired: (values) => {
          return values.type === 'POLICY_ENDORSEMENT';
        },
        conditionalHidden: (values) => {
          return values.type !== 'POLICY_ENDORSEMENT';
        },
      },
    };
  }, [data]);

  const submitForm = async (values: Record<string, any>) => {
    setFormDisabled(true);
    setSubmitButtonState('loading');
    try {
      const variables: Record<string, string> = {
        policyID: currentPolicyId,
        amendmentType: values.type,
      };

      if (values.type === 'POLICY_ENDORSEMENT') {
        variables.amendmentSubType = values.subtype;
      }

      const { data, errors } = await createAmendment({
        variables,
        errorPolicy: 'all',
      });

      const createdAmendmentId = data?.production?.actions?.createAmendment?.id;

      if (createdAmendmentId) {
        toast.success(
          <ToastSuccessMessage>
            Amendment successfully created
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          navigate(`/production/amendment/${createdAmendmentId}`);
        }, 750);
      } else {
        setSubmitButtonState(undefined);
        const errorMessage = getError({ errors });
        toast.error(<ToastErrorMessage>{errorMessage}</ToastErrorMessage>);
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title={'Add Amendment'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(v) => submitForm(v)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default AddAmendmentDrawer;
