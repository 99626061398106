import {
  IMotorAmendmentVariables,
  IPolicyCoverVariables,
  IPolicyPersonsVariables,
} from '.';
import { IAmendmentPageData } from '../..';
import { SEND_TO_BACKEND_DATE_FORMAT } from '../../../../../constants';
import { formatDate } from '../../../../../utils/formatting-utils';
import { numberOrNull } from '../../../../../utils/helper-utils';
import { isEmpty, isValidNumber } from '../../../../../utils/validationUtils';
import { ILineAmendmentProps } from '../../line-amendment';

const getPersonsVariables = (
  values: IAmendmentPageData
): IPolicyPersonsVariables[] => {
  if (!values.policyPersons) return [];
  const policyPersons: IPolicyPersonsVariables[] = [];

  const personsValues = values.policyPersons;

  if (personsValues?.payer?.payerId) {
    policyPersons.push({
      personId: values.policyPersons.payer.payerId,
      personAddressId: values.policyPersons.payer.payerAddress,
      type: 'PAYER',
      ownerIsDifferent: values.policyPersons.payer.ownerIsDifferent,
    });
  }

  if (personsValues?.owner?.ownerId) {
    policyPersons.push({
      personId: values.policyPersons.owner.ownerId,
      personAddressId: values.policyPersons.owner.ownerAddress,
      type: 'OWNER',
    });
  } else if (personsValues?.payer?.payerId) {
    policyPersons.push({
      personId: values.policyPersons.payer.payerId,
      personAddressId: values.policyPersons.payer.payerAddress,
      type: 'OWNER',
    });
  }

  if (personsValues?.insured?.length > 0 && personsValues.insured?.[0]?.id) {
    personsValues.insured.forEach((person) => {
      policyPersons.push({
        personId: person.id,
        personAddressId: person.address,
        type: 'INSURED',
        orderNumber: numberOrNull(person.order),
      });
    });
  }

  if (
    personsValues?.beneficiaries?.length > 0 &&
    personsValues.beneficiaries?.[0]?.id
  ) {
    personsValues.beneficiaries.forEach((person) => {
      policyPersons.push({
        personId: person.id,
        personAddressId: person.address,
        type: 'BENEFICIARY',
      });
    });
  }

  return policyPersons;
};

const getCoversVariables = (
  values: IAmendmentPageData
): IPolicyCoverVariables[] => {
  return values.covers
    .filter((c) => !isEmpty(c.policyCover))
    .map((cover, i) => ({
      order: i + 1,
      sumInsuredIsAdditive: cover.sumInsuredIsAdditive,
      planCoverID: cover.policyCover,
      coverPremiumPercentage: isValidNumber(cover.coverPremiumPercentage)
        ? cover.coverPremiumPercentage / 100
        : null,
      coverPremiumType: cover.coverPremiumType,
      coverPremiumValue: numberOrNull(cover.coverPremiumValue),
      excessOnClaimAmount: numberOrNull(cover.excessOnClaimAmount),
      excessOnClaimDays: numberOrNull(cover.excessOnClaimDays),
      excessOnClaimPercentage: isValidNumber(cover.excessOnClaimPercentage)
        ? cover.excessOnClaimPercentage / 100
        : null,
      excessOnClaimType: cover.excessOnClaimType,
      isEditable: cover.isEditable,
      coverSumInsured: numberOrNull(cover.coverSumInsured),
      isMandatory: cover.isMandatory,
      isMain: cover.isMain,
      clauseID: cover.clauseID,
      coverEffectiveFrom: cover.coverEffectiveFrom,
      coverEffectiveTo: cover.coverEffectiveTo,

      amendmentPolicyPlanCoverInput: {
        AmendmentCoverEffectiveFrom: formatDate(
          cover.coverEffectiveFrom,
          SEND_TO_BACKEND_DATE_FORMAT
        ),
        AmendmentCoverEffectiveTo: formatDate(
          cover.coverEffectiveTo,
          SEND_TO_BACKEND_DATE_FORMAT
        ),
        AmendmentCoverPremiumPercentage: Number(
          cover.amendmentCoverPremiumPercentage
        ),
        AmendmentCoverPremiumType: cover.coverPremiumType,
        AmendmentCoverPremiumValue: Number(cover.amendmentCoverPremiumValue),
        AmendmentCoverSumInsured: Number(cover.amendmentCoverSumInsured),
        AmendmentExcessOnClaimAmount: Number(
          cover.amendmentExcessOnClaimAmount
        ),
        AmendmentExcessOnClaimDays: Number(cover.amendmentExcessOnClaimDays),
        AmendmentExcessOnClaimPercentage: Number(
          cover.amendmentExcessOnClaimPercentage
        ),
        AmendmentExcessOnClaimType: cover.excessOnClaimType,
        AmendmentSumInsuredIsAdditive: cover.sumInsuredIsAdditive,
        PolicyPlanCoverID: cover.policyCover,
      },
    }));
};

export const getMotorCalculateVariables = (
  values: IAmendmentPageData,
  generalData: ILineAmendmentProps['generalData']
): IMotorAmendmentVariables => {
  const data: IMotorAmendmentVariables = {
    policyID: generalData.policyId,
    amendmentID: generalData.currentAmendmentId,
    policyVersionID: generalData.versionId,
    planID: generalData.planId,
    amendmentEffectiveDate: formatDate(
      values.amendmentDetails.amendmentEffectiveDate,
      SEND_TO_BACKEND_DATE_FORMAT
    ),
    amendmentExpiryDate: formatDate(
      values.amendmentDetails.amendmentExpiryDate,
      SEND_TO_BACKEND_DATE_FORMAT
    ),
    amendmentChargesPercentage: numberOrNull(values.costs.chargesPercentage),
    amendmentCost: numberOrNull(values.costs.amendmentCost),
    amendmentTPAFeesAmount: numberOrNull(values.costs.tpaFeesAmount),
    nameOnLicenseEn: values.motorDetails.nameOnLicenseEn,
    nameOnLicenseAr: values.motorDetails.nameOnLicenseAr,
    vignetteCode: values.motorDetails.vignetteCode,
    vignette: numberOrNull(values.motorDetails.vignette),
    plateCode: values.motorDetails.plateCode,
    plateNumber: values.motorDetails.plateNumber,
    usageType: values.motorDetails.usageType,
    bodyType: values.motorDetails.bodyType,
    engineType: values.motorDetails.engineType,
    make: values.motorDetails.brand,
    model: values.motorDetails.model,
    yearOfMake: numberOrNull(values.motorDetails.yearOfMake),
    agencyRepairYear: numberOrNull(values.motorDetails.agencyRepairYear),
    depreciationYears: numberOrNull(values.motorDetails.depreciationYears),
    weight: numberOrNull(values.motorDetails.weight),
    engine: values.motorDetails.engineSize,
    engineSize: numberOrNull(values.motorDetails.engineSize),
    seatingCapacity: numberOrNull(values.motorDetails.seats),
    chassis: values.motorDetails.chassis,
    horsePower: numberOrNull(values.motorDetails.horsePower),
    carValue: numberOrNull(values.motorDetails.carValue),
    color: values.motorDetails.color,
    policyPersonInput: getPersonsVariables(values),
    policyPlanCoverInput: getCoversVariables(values),
  };

  return data;
};
