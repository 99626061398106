import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  calculateAndSaveFleet,
  downloadFleetDocument,
  getNonSelectedPlanCover,
  getProposalFleetDetails,
  issuePolicyFleet,
  saveProposalDraftFleet,
} from './queries';
import { validateProposalPageForm } from './validation';
import {
  convertProposalPageStateToSubmission,
  IProposalDetailsFleetPageSubmissionModel,
} from './submission';
import { toast } from 'react-toastify';
import { isEmpty, isValidNumber } from '../../../../../utils/validationUtils';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import { IEnhancedMenuItem } from '../../../../../components';
import SimpleActionBar from '../../../../../components/SimpleActionBar';
import { gridWidthToPercentage } from '../../../../../utils/grid-utils';
import EnhancedStepper from '../../../../../components/common/EnhancedStepper';
import { MAIN_ONE_THEME } from '../../../../../constants';
import { useParams } from 'react-router-dom';
import CloseProposalDrawer from '../../../../../forms/close-proposal-drawer/CloseProposalDrawer';
import StaticLayout from '../../../../../page-layout/static-layout/StaticLayout';
import Loader from '../../../../../components/Loader';
import { ComissionProposalWidget } from '../../../../../forms/proposal-details-page/custom-widgets/ComissionProposalWidget';
import { getProposalStatus, LookupToList } from './utils';
import PolicyProposalQuoteWidget from '../../../../../forms/proposal-details-page/custom-widgets/PolicyProposalQuoteWidget';
import PolicyCoversRepeaterWidget from '../../../../../forms/proposal-details-page/custom-widgets/PolicyCoversRepeaterWidget';
import PolicyCostChargesWidget from '../../../../../forms/proposal-details-page/custom-widgets/PolicyCostChargesWidget';
import PolicyInsuredWidget from '../../../../../forms/proposal-details-page/custom-widgets/PolicyInsuredWidget';
import {
  getProposalPageFormState,
  IProposalCover,
  IProposalPageFormState,
} from './form';
import {
  convertToDetailsSummary,
  convertToPolicyPersons,
  convertToPolicyPlanProposalCovers,
  convertToProposalCover,
  getPlanCoversLov,
  IProposalDetailsPageResponse,
  IProposalDetailsSummary,
} from './index2';
import PolicyDetailsEntityInfo from '../../../../../forms/proposal-details-page/custom-widgets/PolicyDetailsEntityInfo';
import { PolicyDetailsWidget } from '../../../../../forms/proposal-details-page/custom-widgets/PolicyDetailsWidget';
import ToastSuccessMessage from '../../../../../components/ToastSuccessMessage';
import { getError } from '../../../../../utils/graph-utils';
import {
  downloadFileFromUrl,
  generateDownloadLink,
} from '../../../../../utils/file-utils';
import { useAppDispatch } from '../../../../../redux/hooks';
import PreviewProposalClauseModal from '../../../../../forms/proposal-details-page/modals/PreviewProposalClauseModal';
import { IConfirmation } from '../../../../../redux/confirmation/types';
import { OpenConfirmationAction } from '../../../../../redux/confirmation/actions';

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '95%',
    width: '95%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
  buttonsContainer: {
    width: '95%',
    margin: '10px auto 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  widgetTopMargin: {
    marginTop: '1em',
  },
}));

const ProposalFleetDetailsPage: React.FC = () => {
  const { classes } = useStyles();

  const [closeProposalDrawerOpen, setCloseProposalDrawerOpen] =
    useState<boolean>(false);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const params = useParams();
  const entityId = params.id;

  const [pageState, onPageStateUpdate] = useState<IProposalPageFormState>(
    getProposalPageFormState()
  );

  const [data, setData] = useState<IProposalDetailsSummary>();
  const [coversList, setCoversList] = useState<IProposalCover[]>([]);

  const [planCoversList, setPlanCoversList] = useState<
    Record<string, IProposalCover>
  >({});

  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({
    planCovers: {},
  });

  const [saveAndCalculationAction] = useMutation(calculateAndSaveFleet());
  const [saveAsDraftAction] = useMutation(saveProposalDraftFleet());
  const [issuePolicyAction] = useMutation(issuePolicyFleet());
  const [downloadAction] = useMutation(downloadFleetDocument());

  const dispatch = useAppDispatch();

  const [issueModalOpen, setIssueModalOpen] = useState<boolean>(false);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isClosed =
    data?.Status === 'CLOSED_WON' || data?.Status === 'CLOSED_LOST';

  const disabledPage = isClosed || submitting || loading;

  const [getProposalDetailsLazy] = useLazyQuery(getProposalFleetDetails());

  const [getNonSelectedPlanCoverLazy] = useLazyQuery(getNonSelectedPlanCover());

  const getPlanCoverDetails = async (
    planId: string,
    planCoverIds: string[]
  ) => {
    const planCoverList: { data: any } = await getNonSelectedPlanCoverLazy({
      variables: {
        selectedPlanID: planId,
        selectedPlanCoverIDs: planCoverIds,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return planCoverList.data;
  };

  const initialize = async () => {
    setLoading(true);
    const result: { data: IProposalDetailsPageResponse } =
      await getProposalDetailsLazy({
        variables: { id: entityId },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

    const summaryData = convertToDetailsSummary(result.data);

    const initialCovers = convertToPolicyPlanProposalCovers(result.data);
    const initialPersons = convertToPolicyPersons(result.data);

    const planCoverDetails = await getPlanCoverDetails(
      summaryData.PlanID.Id,
      []
    );

    const lists = LookupToList(result.data);

    setLovs({
      currency: lists.currency,
      medicalClasses: lists.medicalClasses,
      emergencyOptions: lists.emergencyOptions,
      continuityOptions: lists.continuityOptions,
      planCovers: getPlanCoversLov(planCoverDetails),
    });

    setPlanCoversList(
      convertToProposalCover(
        planCoverDetails,
        summaryData.PolicyCurrency?.Code,
        summaryData.PricingOption.PlanToPolicyRate,
        summaryData.PolicyEffectiveDate,
        summaryData.PolicyExpiryDate
      )
    );
    setCoversList(initialCovers);
    onPageStateUpdate(
      getProposalPageFormState(summaryData, initialCovers, initialPersons)
    );
    setData(summaryData);
    setLoading(false);
  };

  const renderLeftSection = () => {
    if (data) {
      return <PolicyDetailsEntityInfo data={data as any} />;
    }

    return <></>;
  };

  const onSubmit = async () => {
    const validationResult = validateProposalPageForm(data, pageState.values);

    if (!submitting) {
      setSubmitting(true);
      const newPageState = {
        ...pageState,
        errors: validationResult.errors,
        touched: validationResult.touched,
      };

      onPageStateUpdate(newPageState);
      if (validationResult.isValid) {
        const dataToSubmit = convertProposalPageStateToSubmission(
          data,
          pageState
        );
        const result = await saveAndCalculationAction({
          variables: { ...dataToSubmit },
          errorPolicy: 'all',
        });
        if (isEmpty(result.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {'Proposal successfully saved.'}
            </ToastSuccessMessage>
          );
          initialize();
        } else {
          toast.error(
            <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
          );
        }
      } else {
        toast.error(<ToastErrorMessage>Incomplete Form</ToastErrorMessage>);
      }
      setSubmitting(false);
    }
  };

  const onSaveDraft = async () => {
    if (!submitting) {
      setSubmitting(true);
      const dataToSubmit = convertProposalPageStateToSubmission(
        data,
        pageState
      ) as Partial<IProposalDetailsFleetPageSubmissionModel>;

      if (isEmpty(dataToSubmit.policyPersonInput[0]?.personId)) {
        dataToSubmit.policyPersonInput = [];
      }

      const result = await saveAsDraftAction({
        variables: { ...dataToSubmit },
        errorPolicy: 'all',
      });

      if (isEmpty(result.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {'Proposal successfully saved.'}
          </ToastSuccessMessage>
        );

        initialize();
      } else {
        toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
      }
    }
    setSubmitting(false);
  };

  const onIssuePolicyFleetAction = async () => {
    if (!submitting) {
      setSubmitting(true);
      const dataToSubmit = {
        entityId: data.Id,
        clauseText: data.ClauseText || '',
      };
      const result = await issuePolicyAction({
        variables: { ...dataToSubmit },
        errorPolicy: 'all',
      });

      if (isEmpty(result.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {'Policy successfully issued.'}
          </ToastSuccessMessage>
        );

        const filesInfo = getFileDetailsList(result.data);

        for (const fileInfo of filesInfo) {
          const downloadLink = generateDownloadLink(
            fileInfo.fileId,
            fileInfo.EntityTypeId,
            fileInfo.EntityId,
            fileInfo.EntityViewId,
            fileInfo.PropertyId
          );
          await downloadFileFromUrl(downloadLink, fileInfo.fileName);
        }
        setSubmitting(false);
        initialize();
      } else {
        setSubmitting(false);
        toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
      }
    }
  };

  const downloadDocument = async () => {
    if (!submitting) {
      setSubmitting(true);
      const dataToSubmit = {
        entityId: data.Id,
      };
      const result = await downloadAction({
        variables: { ...dataToSubmit },
        errorPolicy: 'all',
      });
      if (isEmpty(result.errors)) {
        const fileInfo = getFileDetails(result.data);
        const downloadLink = generateDownloadLink(
          fileInfo.fileId,
          fileInfo.EntityTypeId,
          fileInfo.EntityId,
          fileInfo.EntityViewId,
          fileInfo.PropertyId
        );

        await downloadFileFromUrl(downloadLink, fileInfo.fileName);
        toast.success(
          <ToastSuccessMessage>
            {'Policy sample successfully downloaded.'}
          </ToastSuccessMessage>
        );
        setSubmitting(false);
      } else {
        setSubmitting(false);
        toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
      }
    }
  };

  const renderActions = () => {
    const lineExternalCode = Number(data?.LineId?.ExternalCode);
    const isLineValid =
      isValidNumber(data?.LineId?.ExternalCode) &&
      [50, 51, 4].includes(lineExternalCode);
    const isLine50 = isLineValid && lineExternalCode === 50;
    const isLine51Or4 = isLineValid && [51, 4].includes(lineExternalCode);

    const actions: IEnhancedMenuItem[] = [
      {
        title: 'Save Draft',
        onClick: () => {
          onSaveDraft();
        },
        isSecondary: false,
        backgroundColor: '#000',
        color: '#fff',
        disabled: disabledPage,
        hidden:
          isClosed || data?.Status?.toLowerCase() !== 'new' || !isLineValid,
      },
      {
        title: 'Calculate & Save',
        onClick: () => {
          onSubmit();
        },
        isSecondary: false,
        backgroundColor: '#000',
        color: '#fff',
        disabled: disabledPage,
        hidden: isClosed || !isLineValid,
      },
      {
        title: 'Issue Policy',
        onClick: () => {
          if (isLine50) {
            const confirmation: IConfirmation = {
              open: true,
              title: 'Confirmation',
              message: `Are you sure you want to issue the selected policy?`,
              callback: async () => {
                onIssuePolicyFleetAction();
              },
              submitButtonText: 'Yes',
              cancelButtonText: 'No',
            };
            dispatch(OpenConfirmationAction(confirmation));
          } else if (isLine51Or4) {
            setIssueModalOpen(true);
          }
        },
        isSecondary: false,
        hidden:
          isClosed ||
          !(data?.Status?.toLowerCase() === 'in_progress' && isLineValid),
        backgroundColor: '#000',
        color: '#fff',
        disabled: disabledPage,
      },
      {
        title: 'Download Policy Sample',
        onClick: () => {
          downloadDocument();
        },
        isSecondary: isLine50,
        hidden:
          isClosed ||
          !(data?.Status?.toLowerCase() === 'in_progress' && isLine50),
        backgroundColor: '#000',
        color: '#fff',
        disabled: disabledPage,
      },
      {
        title: 'Close Proposal',
        onClick: () => {
          setCloseProposalDrawerOpen(true);
        },
        isSecondary: true,
        backgroundColor: '#000',
        color: '#fff',
        disabled: disabledPage,
        hidden: isClosed,
      },
    ];

    return <SimpleActionBar items={actions} />;
  };

  const renderMainChildren = () => {
    if (data) {
      const isProposalWon = data.Status?.toUpperCase() === 'CLOSED_WON';
      const isProposalUserNew = data.Status?.toLowerCase() === 'new';
      const steps = isProposalUserNew
        ? ['New', 'In Progress', 'Closed Won']
        : ['New', 'In Progress', isProposalWon ? 'Closed Won' : 'Closed Lost'];

      return (
        <>
          <div style={{ marginTop: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <div style={{ width: `${gridWidthToPercentage(8)}%` }}>
                <EnhancedStepper
                  activeStep={getProposalStatus(steps, data.Status)}
                  steps={steps}
                  buttonTitle="Calculate & Save"
                  buttonOnClick={() => undefined}
                  className={classes.stepper}
                />
              </div>
              <div style={{ width: `${gridWidthToPercentage(4)}%` }}>
                {renderActions()}
              </div>
            </div>
            <div
              style={{
                marginTop: '18px',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  width: `${gridWidthToPercentage(8)}%`,
                  padding: '0px 1em 1em 0',
                }}
              >
                <PolicyDetailsWidget data={data as any} />
                <PolicyCoversRepeaterWidget
                  defaultPlanCoversList={planCoversList}
                  pageState={pageState as any}
                  disabledForm={disabledPage}
                  status={data.Status}
                  data={data as any}
                  allowPlanCoverEdits={data.BusinessUserID.AllowPlanCoverEdits}
                  onPageStateUpdate={onPageStateUpdate as any}
                  lovs={lovs}
                  isFleet={true}
                />
                <PolicyCostChargesWidget
                  pageState={pageState as any}
                  disabledForm={disabledPage}
                  status={data.Status}
                  editableLine={data.BusinessUserID?.EditableLineChargesIDs?.includes(
                    data?.LineId.Id
                  )}
                  line={data?.LineId.ExternalCode?.toString() || ''}
                  onPageStateUpdate={onPageStateUpdate as any}
                  currencySymbol={data.PolicyCurrency.Symbol}
                />

                <PolicyInsuredWidget
                  pageState={pageState as any}
                  onPageStateUpdate={onPageStateUpdate as any}
                  lovs={lovs}
                  disabledForm={disabledPage}
                  maxNumber={data.PlanID.MaxNbInsured}
                  data={data as any}
                />

                {closeProposalDrawerOpen && (
                  <CloseProposalDrawer
                    open={closeProposalDrawerOpen}
                    onClose={() => {
                      setCloseProposalDrawerOpen(false);
                    }}
                    onSuccess={() => {
                      initialize();
                      setCloseProposalDrawerOpen(false);
                    }}
                    id={entityId}
                  />
                )}
              </div>
              <div
                style={{
                  width: `${gridWidthToPercentage(4)}%`,
                  padding: '0px',
                }}
              >
                <PolicyProposalQuoteWidget
                  data={data as any}
                  covers={coversList}
                />
                {data?.Status && data?.Status?.toLowerCase() !== 'new' && (
                  <ComissionProposalWidget
                    className={classes.widgetTopMargin}
                    data={data as any}
                    onUpdateSuccess={() => {
                      initialize();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      );
    }

    return <></>;
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <StaticLayout
        name={'Proposal Details'}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
      />
      {issueModalOpen && (
        <PreviewProposalClauseModal
          data={data as any}
          onSuccess={() => {
            setIssueModalOpen(false);
            initialize();
          }}
          onClose={() => setIssueModalOpen(false)}
        />
      )}
    </>
  );
};

export default ProposalFleetDetailsPage;

function getFileDetails(data: any) {
  const downloadPolicySampleMotor =
    data.production.entities.proposal.production.downloadPolicySampleMotor;
  const { EntityTypeId, EntityId, EntityViewId, PropertyId, File } =
    downloadPolicySampleMotor;
  const { id: fileId, fileName } = File;

  return {
    EntityTypeId,
    EntityId,
    EntityViewId,
    PropertyId,
    fileId,
    fileName,
  };
}

function getFileDetailsList(data: any): FileDetail[] {
  const fileDetails: FileDetail[] = [];

  const items =
    data.production.entities.proposal.production.issuePolicyMotor.Items;
  for (const item of items) {
    const fileDetail: FileDetail = {
      EntityTypeId: item.EntityTypeId,
      EntityId: item.EntityId,
      EntityViewId: item.EntityViewId,
      PropertyId: item.PropertyId,
      fileId: item.File.id,
      fileName: item.File.fileName,
    };
    fileDetails.push(fileDetail);
  }

  return fileDetails;
}

type FileDetail = {
  EntityTypeId: string;
  EntityId: string;
  EntityViewId: string;
  PropertyId: string;
  fileId: string;
  fileName: string;
};
