import React, { useEffect, useState } from 'react';
import ListingTable from '../../../../../components/form-fields/table/ListingTable';
import { facDistributionHeaders } from '../content';
import { IListingData } from '../../../../../models/listing';
import { useQuery } from '@apollo/client';
import { getFacOfPolicyCoversQuery } from '../queries';
import { mapToFacDistTableData } from '../utils';

const FacDistributionWidget: React.FC<{
  currentPolicyId: string;
}> = ({ currentPolicyId }) => {
  const { data, loading } = useQuery(getFacOfPolicyCoversQuery, {
    variables: {
      PolicyID: currentPolicyId,
    },
    errorPolicy: 'all',
  });

  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageNumber: 1,
    pageSize: 5,
    totalCount: 0,
  });

  useEffect(() => {
    if (data) {
      setTableData(
        mapToFacDistTableData(data.Production?.queries?.GetFacOfPolicyCovers)
      );
    }
  }, [data]);

  return (
    <ListingTable
      name="facDistribution"
      inlineTitle="Facultative Distribution"
      headers={facDistributionHeaders}
      data={tableData}
      loader={loading}
      actions={[]}
      inline
      disableSelection
    />
  );
};

export default FacDistributionWidget;
