import { cloneDeep } from 'lodash';
import { ITenant } from '../redux/tenant/types';
import { getCurrentEnviroment, getEnviromentDetails } from './env-utils';
import { getClient } from './identity-utils';

export function resolveGraphqlBaseUrl(): string {
  const baseUrl = getEnviromentDetails().graphBaseUrl;

  return baseUrl;
}

export function resolveIdentityBaseUrl(): string {
  const baseUrl = getEnviromentDetails().identityBaseUrl;

  return baseUrl;
}

export function resolveIdentityData(): URLSearchParams {
  const { client_id, client_secret } = getClient();
  const data = new URLSearchParams();
  data.append('grant_type', 'password');
  data.append('client_id', client_id);
  data.append('scope', 'openid profile');
  data.append('client_secret', client_secret);

  return data;
}

export function resolveMainHostUrl(): string {
  const baseUrl = getEnviromentDetails().mainHostUrl;

  return baseUrl;
}

export function updateTenantConfigPerEnvironment(
  tenantResult: ITenant
): ITenant {
  const currentEnv = getCurrentEnviroment();
  const result = cloneDeep(tenantResult);
  switch (currentEnv) {
    case 'staging':
      result.login.logoUrl = '/images/logo-large-red-testing-3.png';
      result.header.logoUrl = '/images/testing-icon.png';
      break;
    default:
      break;
  }
  return result;
}
