import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../../../components/enhanced-table';

export const facultativesHeaders: Record<string, EnhancedTableHeader> = {
  facultativeId: {
    name: 'facultativeId',
    title: 'Facultative ID',
    type: EnhancedTableHeaderType.Text,
  },
  slipRefNo: {
    name: 'slipRefNo',
    title: 'Slip Ref. No.',
    type: EnhancedTableHeaderType.Text,
  },
  policy: {
    name: 'policy',
    title: 'Policy',
    type: EnhancedTableHeaderType.Text,
  },
  dueDate: {
    name: 'dueDate',
    title: 'Due Date',
    type: EnhancedTableHeaderType.Date,
  },
  cededPremium: {
    name: 'cededPremium',
    title: 'Ceded Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  commissionAmount: {
    name: 'commissionAmount',
    title: 'Commission Amount',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  amountDue: {
    name: 'amountDue',
    title: 'Amount Due',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  amountPaid: {
    name: 'amountPaid',
    title: 'Amount Paid',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
};
