import { IFacultativesWidgetData } from '.';
import { IAbstractRecord } from '../../../../../../models';
import { formatDate } from '../../../../../../utils/formatting-utils';
import { compareStrings } from '../../../../../../utils/helper-utils';
import { isEmpty } from '../../../../../../utils/validationUtils';

export const mapToListingData = (data: IAbstractRecord[]) => {
  const list: Record<string, IFacultativesWidgetData> = {};

  if (isEmpty(data)) {
    return {};
  }

  /**
   * Facultative ID is compared first.
   *    If IDs differ, their order is decided by that comparison.
   *    If IDs are the same, then the policy is compared.
   *        If policies are also identical, the cover name comparison decides the order.
   */
  const sorted = [...data].sort((a, b) => {
    // 1) Compare FacID
    const facIdComparison = compareStrings(
      a.facultative_FacultativeID,
      b.facultative_FacultativeID
    );
    if (facIdComparison !== 0) return facIdComparison;

    // 2) Compare policy
    const policyComparison = compareStrings(
      a.policy_PolicyNumber,
      b.policy_PolicyNumber
    );
    if (policyComparison !== 0) return policyComparison;

    // 3) Compare cover
    return compareStrings(a.cover_Name, b.cover_Name);
  });

  sorted.forEach((item: IAbstractRecord, index: number) => {
    list[index] = {
      facultativeId: item.facultative_FacultativeID,
      slipRefNo: item.facultative_SlipReferenceNumber,
      policy: item.policy_PolicyNumber,
      cover: item.cover_Name,
      dueDate: item.facultative_PaymentDueDate,
      cededPremium: Number(
        item.production_PolicyFacultativeDistribution_CededNetPremium
      ),
      commissionAmount:
        item.production_PolicyFacultativeDistribution_Commission,
      amountDue: item.facBill_AmountDue,
      amountPaid: item.facBill_AmountPaid,
      Currency: item.facBill_Currency?.Symbol,
    };
  });
  console.log('sorted', list);

  return list;
};
