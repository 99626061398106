import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { getBreakdown } from '../queries';
import { makeStyles } from 'tss-react/mui';
import { IAbstractRecord } from '../../../../../models';
import {
  transformPayableBillsArrayToObject,
  transformReceivableBillsArrayToObject,
} from '../utils';
import { payableBillsHeaders, receivableBillsHeaders } from '../content';
import { IEnhancedTableProps } from '../../../../../components/enhanced-table/EnhancedTable';
import ListingWidget from '../../../../../components/form-fields/listing-widget/ListingWidget';

export interface IFormValues {
  PolicyNumber: string;
  PolicyName: string;
  PaymentDivisionType: string;
  PaymentMode: string;
}

interface IViewBreakdownProps {
  policyId: string;
  businessPartnerId: string;
}

const useStyles = makeStyles()(() => ({
  dialogPaper: {
    height: '80%',
    width: '80%',
    maxWidth: '1539px',
  },
  fieldRow: {
    marginTop: '20px',
    display: 'grid',
    gridTemplateColumns: `repeat(2, 30%)`,
    gap: '3%',
    alignItems: 'center',
  },
  tableRow: {
    marginBottom: '40px',
  },
  receivablesTitle: {
    color: '#36B37E',
    fontWeight: 'bold',
  },
  payablesTitle: {
    color: '#D36E34',
    fontWeight: 'bold',
  },
}));

const ViewBreakdown: React.FC<IViewBreakdownProps> = ({
  policyId,
  businessPartnerId,
}) => {
  const { classes } = useStyles();
  const { data: bills } = useQuery(getBreakdown, {
    variables: {
      PolicyID: policyId,
      BusinessPartnerID: businessPartnerId,
      pagination: { pageSize: 10000000, pageNumber: 1 },
    },
  });

  const receivableBillsData: IEnhancedTableProps['data'] = useMemo(() => {
    if (!bills)
      return {
        pagedItems: {},
        pageSize: 5,
        pageNumber: 0,
        totalCount: 0,
      };

    const receivableBillsItems: IAbstractRecord[] =
      bills?.Accounting?.queries?.GetPolicyReceivableBills;

    return {
      pagedItems: transformReceivableBillsArrayToObject(receivableBillsItems),
      pageNumber: 1,
      pageSize: 5,
      totalCount: receivableBillsItems?.length,
    };
  }, [bills]);

  const payableBillsData: IEnhancedTableProps['data'] = useMemo(() => {
    if (!bills)
      return {
        pagedItems: {},
        pageSize: 5,
        pageNumber: 0,
        totalCount: 0,
      };

    const payableBills =
      bills?.Accounting?.queries?.GetBusinessPartnerBillOfPolicy;

    const payableBillsItems: IAbstractRecord[] = payableBills?.items;

    return {
      pagedItems: transformPayableBillsArrayToObject(payableBillsItems),
      pageNumber: 1,
      pageSize: 5,
      totalCount: payableBillsItems?.length,
    };
  }, [bills]);

  return (
    <>
      <div className={classes.tableRow}>
        <p className={classes.receivablesTitle}>Receivables</p>
        <ListingWidget
          name="receivableBillsSchedule"
          data={receivableBillsData}
          tableSettings={{ headers: receivableBillsHeaders }}
          disableSelection
          inline
          title=""
          actions={[]}
        />
      </div>
      <div>
        <p className={classes.payablesTitle}>Payables</p>
        <ListingWidget
          name="payableBillsSchedule"
          data={payableBillsData}
          tableSettings={{ headers: payableBillsHeaders }}
          disableSelection
          inline
          title=""
          actions={[]}
        />
      </div>
    </>
  );
};

export default ViewBreakdown;
