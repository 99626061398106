import { IGenericTaxationsTableData } from '.';
import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { trimTrailingZeros } from '../../utils/formatting-utils';
import { isEmpty } from '../../utils/validationUtils';

export function mapToListingData(data: IAbstractRecord): IListingData {
  const list: IAbstractRecord = {};

  data.Configuration.queries.getTaxationList.items.forEach(
    (obj: IGenericTaxationsTableData) => {
      list[obj.configuration_Taxation_Id] = {
        Id: obj.configuration_Taxation_Id,
        lineId: obj.line_Id,
        line_Name: obj.line_Name,
        line_ExternalCode: obj.line_ExternalCode,
        configuration_Taxation_FixedStamp: `${obj.configuration_Taxation_FixedStampCurrency?.Symbol} ${trimTrailingZeros(obj.configuration_Taxation_FixedStamp)}`,
        configuration_Taxation_ProportionalStamp:
          obj.configuration_Taxation_ProportionalStamp,
        configuration_Taxation_MunicipalityTax:
          obj.configuration_Taxation_MunicipalityTax,
        configuration_Taxation_TaxOnCommission:
          obj.configuration_Taxation_TaxOnCommission,
        configuration_Taxation_ReinsuranceTax:
          obj.configuration_Taxation_ReinsuranceTax,
        configuration_Taxation_EffectiveFrom:
          obj.configuration_Taxation_EffectiveFrom,
        configuration_Taxation_EffectiveTo:
          obj.configuration_Taxation_EffectiveTo,
        configuration_Taxation_createdOn: obj.configuration_Taxation_createdOn,
        configuration_Taxation_modifiedOn:
          obj.configuration_Taxation_modifiedOn,
        AvailableActions:
          new Date(obj.configuration_Taxation_EffectiveFrom) > new Date() &&
          isEmpty(obj.configuration_Taxation_EffectiveTo)
            ? ['modify']
            : [],
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Configuration.queries.getTaxationList?.paging?.pageSize,
    pageNumber: data.Configuration.queries.getTaxationList?.paging?.pageNumber,
    totalCount: data.Configuration.queries.getTaxationList?.paging?.totalCount,
  };
}

export function dataToLovs(
  data: IAbstractRecord
): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    line: {},
  };

  data?.PlanConfigManagement?.queries?.allLines?.forEach(
    (obj: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Name: string;
    }) => {
      lovs.line[obj.planConfigManagement_Line_Id] =
        obj.planConfigManagement_Line_Name;
    }
  );

  return lovs;
}
