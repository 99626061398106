import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  planCurrency: '',
  ageFrom: '',
  ageTo: '',
  gender: '',
  nationality: '',
  occupationClass: '',
  premium: '',
  medicalClass: '',
  emergency: '',
};

export const getInputs = (
  planCurrencySymbol: string
): Record<string, DynamicFormInputType> => ({
  planCurrency: {
    name: 'planCurrency',
    title: 'Plan Currency',
    type: FormInputTypes.chips,
    placeholder: 'Plan Currency',
    value: !isEmpty(initialValues.planCurrency)
      ? initialValues.planCurrency
      : '',
    multiple: false,
    disabled: true,
    required: true,
    hidden: true,
  },
  ageFrom: {
    name: 'ageFrom',
    title: 'Age From',
    placeholder: 'Age From',
    type: FormInputTypes.number,
    value: initialValues.ageFrom,
    required: true,
    minNumber: 0,
  },
  ageTo: {
    name: 'ageTo',
    title: 'Age To',
    placeholder: 'Age To',
    type: FormInputTypes.number,
    value: initialValues.ageTo,
    required: true,
  },
  gender: {
    name: 'gender',
    title: 'Gender',
    type: FormInputTypes.chips,
    placeholder: 'Gender',
    value: !isEmpty(initialValues.gender) ? initialValues.gender : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  occupationClass: {
    name: 'occupationClass',
    title: 'Occupation Class',
    type: FormInputTypes.chips,
    placeholder: 'Occupation Class',
    value: !isEmpty(initialValues.occupationClass)
      ? initialValues.occupationClass
      : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  nationality: {
    name: 'nationality',
    title: 'Nationality',
    type: FormInputTypes.chips,
    placeholder: 'Nationality',
    value: !isEmpty(initialValues.nationality) ? initialValues.nationality : '',
    multiple: true,
    disabled: false,
    required: true,
    showSelectAll: true,
  },
  medicalClass: {
    name: 'medicalClass',
    title: 'Medical Class',
    type: FormInputTypes.chips,
    placeholder: 'Medical Class',
    value: !isEmpty(initialValues.medicalClass)
      ? initialValues.medicalClass
      : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  emergency: {
    name: 'emergency',
    title: 'Emergency Included',
    type: FormInputTypes.chips,
    placeholder: 'Emergency Included',
    value: !isEmpty(initialValues.emergency) ? initialValues.emergency : '',
    multiple: false,
    disabled: false,
    required: true,
  },
  premium: {
    name: 'premium',
    title: 'Premium',
    placeholder: 'Premium',
    type: FormInputTypes.currency,
    value: initialValues.premium,
    required: true,
    minNumber: 0,
    maxDecimalPercision: 3,
    useCurrencySymbol: true,
    currencySymbol: planCurrencySymbol,
  },
});
