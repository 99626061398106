import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  widgetPaper: {
    marginTop: '1em',
  },
  addBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  removeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    top: '8px',
    right: '0',
    margin: '0',
  },
  clearBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 !important',
  },
  cardTitle: {
    fontSize: '15px !important',
    lineHeight: '21px !important',
    margin: '0 0 16px',
    padding: '0',
    fontFamily: 'SourceSansPro-Bold !important',
  },
  tabLabel: {
    fontSize: '14px',
    fontFamily: 'SourceSansPro-Regular',
    textTransform: 'none',
    lineHeight: '18px',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
  },
  tabActiveTitle: {
    fontFamily: 'SourceSansPro-SemiBold !important',
    fontWeight: 600,
  },
  tabIconButton: {
    marginLeft: '8px',
    padding: '0',
  },
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    padding: '0',
    '&::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#ccc',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#aaa',
    },
  },
  tabsWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  indicator: {
    display: 'none',
  },
  tabRoot: {
    textTransform: 'none',
    minWidth: 72,
    minHeight: '31px',
    padding: '4px 8px',
    marginRight: '4px',
    color: '#8e8e8e',
    border: '1px solid #E5E5E5',
    borderBottom: 'none',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: '#F0F0F0',
    fontFamily: 'SourceSansPro-Regular',
    '&.Mui-selected': {
      color: '#000000',
      fontFamily: 'SourceSansPro-SemiBold',
      backgroundColor: '#F9F9F9',
      borderBottom: 'none',
      fontWeight: 600,
    },
  },
  tabsRoot: {
    minHeight: 'auto',
    overflowX: 'auto',
    '& .MuiTabs-flexContainer': {
      borderBottom: 'none',
    },
  },
}));

export default useStyles;
