import React, { useEffect, useState } from 'react';
import { ILineAmendmentProps } from '.';
import AmendmentDetails from '../components/AmendmentDetails';
import Loader from '../../../../components/Loader';
import { cloneDeep } from 'lodash';
import { getAmendmentInitialState } from '../content';
import {
  convertToPolicyPlanProposalCovers,
  convertToPolicyPropertyDetails,
  mapToAmendmentDetails,
} from '../utils';
import { useLazyQuery } from '@apollo/client';
import {
  getBBBDetailsQuery,
  getCoversPersonsQuery,
  getPropertyRiskDetailsQuery,
  getMarineDetailsQuery,
  getMarineHullDetailsQuery,
} from '../queries';
import { initialCoverValues } from '../components/content';
import { convertToPolicyPersons } from '../components/policy-persons-widgets/utils';
import PolicyCovers from '../components/PolicyCovers';
import CostsAndCharges from '../components/CostsAndCharges';
import InsuredWidget from '../components/policy-persons-widgets/InsuredWidget';
import PayerOwnerWidget from '../components/policy-persons-widgets/PayerOwnerWidget';
import BeneficiaryWidget from '../components/policy-persons-widgets/BeneficiaryWidget';
import PropertyDescriptionDetailsWidget from '../components/PropertyDescription';
import RiskDescription from '../components/RiskDescription';
import BankersDetails from '../components/BankersDetails';
import MarineDetails from '../components/MarineDetails';
import MarineHullDetails from '../components/MarineHullDetails';
import { lookupListAsRecordObject } from '../../../../utils/graph-utils';
import { IAbstractRecord } from '../../../../models';
import {
  GenericLines,
  getGenericLine,
  hasProperty,
  hasRisk,
} from '../../../../utils/helper-utils';

interface IGenericLovs {
  plans: Record<string, string>;
  geoLocations: Record<string, string>;
  matters: Record<string, string>;
}

const GenericAmendment: React.FC<ILineAmendmentProps> = ({
  generalData,
  data,
  pageState,
  handleStateChange,
}) => {
  const isGroupPolicy = generalData.policyType === 'GROUP';
  const genericLine = getGenericLine(generalData.lineExternalCode);
  const [loading, setLoading] = useState<boolean>();
  const isDisabled = ['COMPLETED', 'CANCELLED', 'EXPIRED'].includes(
    generalData.amendmentStatus
  );

  const [lovs, setLovs] = useState<IGenericLovs>({
    plans: {},
    geoLocations: {},
    matters: {},
  });

  const [getCoversPersonsLazy] = useLazyQuery(getCoversPersonsQuery('both'));

  const [getPropertyRiskDetailsLazy] = useLazyQuery(
    getPropertyRiskDetailsQuery,
    {
      variables: {
        selectedAmendmentID: generalData.currentAmendmentId,
        selectedProposalID: generalData.proposalId,
        version: generalData.version,
      },
      errorPolicy: 'all',
    }
  );

  const [getBBBDetailsLazy] = useLazyQuery(getBBBDetailsQuery, {
    variables: {
      selectedPolicyID: generalData.policyId,
      version: generalData.version,
    },
    errorPolicy: 'all',
  });

  const [getMarineDetailsLazy] = useLazyQuery(getMarineDetailsQuery, {
    variables: {
      selectedAmendmentID: generalData.currentAmendmentId,
      version: generalData.version,
    },
    errorPolicy: 'all',
  });

  const [getMarineHullDetailsLazy] = useLazyQuery(getMarineHullDetailsQuery, {
    variables: {
      selectedAmendmentID: generalData.currentAmendmentId,
      version: generalData.version,
    },
    errorPolicy: 'all',
  });

  const initialize = async () => {
    setLoading(true);

    let detailsData: IAbstractRecord;

    if (genericLine === GenericLines.BBB) {
      const { data: responseData } = await getBBBDetailsLazy();
      detailsData = {
        ...data?.[0],
        bankersDetails:
          responseData.Production.queries.getPolicyBankersBlanketBondDetails,
      };
    } else if (genericLine === GenericLines.MARINE) {
      const { data: responseData } = await getMarineDetailsLazy();
      detailsData = {
        ...data?.[0],
        ...responseData.Production.queries.getAmendmentDetails[0],
      };
    } else if (genericLine === GenericLines.MARINE_HULL) {
      const { data: responseData } = await getMarineHullDetailsLazy();
      detailsData = {
        ...data?.[0],
        ...responseData.Production.queries.getAmendmentDetails[0],
      };
    }

    const newValues = mapToAmendmentDetails(detailsData);

    if (hasProperty(generalData.lineExternalCode)) {
      const { data } = await getPropertyRiskDetailsLazy();
      newValues.riskDetails = convertToPolicyPropertyDetails(
        data.Production.queries.getPolicyPropertyDetails
      );
    }
    const newErrors = cloneDeep(
      getAmendmentInitialState(generalData?.lineCategory).errors
    );
    const newTouched = cloneDeep(
      getAmendmentInitialState(generalData?.lineCategory).touched
    );

    // get covers and/or persons --start
    const { data: coversPersonsData } = await getCoversPersonsLazy({
      variables: {
        id: generalData?.proposalId,
        version: generalData.version,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    const covers = convertToPolicyPlanProposalCovers(coversPersonsData, data);
    newValues.covers = covers;
    newErrors.covers = covers.map(() => initialCoverValues.initialError);
    newTouched.covers = covers.map(() => initialCoverValues.initialTouched);

    const persons = convertToPolicyPersons(coversPersonsData);
    newValues.policyPersons = persons;
    newErrors.policyPersons = {
      owner: newErrors['policyPersons'].owner,
      payer: newErrors['policyPersons'].payer,
      insured: persons.insured.map(() => newErrors['policyPersons'].insured[0]),
      beneficiaries: persons.beneficiaries.map(
        () => newErrors['policyPersons'].beneficiaries[0]
      ),
    };
    newTouched.policyPersons = {
      owner: newTouched['policyPersons'].owner,
      payer: newTouched['policyPersons'].payer,
      insured: persons.insured.map(
        () => newTouched['policyPersons'].insured[0]
      ),
      beneficiaries: persons.beneficiaries.map(
        () => newTouched['policyPersons'].beneficiaries[0]
      ),
    };
    // get covers and/or persons --end

    setLovs((prev) => ({
      ...prev,
      plans: { [data?.[0]?.plan_Id]: data?.[0]?.plan_Name },
      matters:
        genericLine === GenericLines.MARINE
          ? lookupListAsRecordObject(detailsData.Core.lookups.matters)
          : {},
      geoLocations: hasProperty(generalData.lineExternalCode)
        ? lookupListAsRecordObject(detailsData?.Core?.lookups?.geoLocations)
        : {},
    }));

    handleStateChange({
      values: newValues,
      errors: newErrors,
      touched: newTouched,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <AmendmentDetails
        generalData={generalData}
        lovs={lovs}
        pageState={pageState}
        onChange={handleStateChange}
      />
      <PolicyCovers
        generalData={generalData}
        pageState={pageState}
        onChange={handleStateChange}
        isGroupPolicy={isGroupPolicy}
      />
      {hasProperty && (
        <PropertyDescriptionDetailsWidget
          generalData={generalData}
          pageState={pageState}
          onPageStateUpdate={handleStateChange}
          isDisabled={isDisabled}
          lovs={{
            geoLocations: lovs.geoLocations,
            planCovers: lovs.plans,
          }}
        />
      )}
      <CostsAndCharges
        generalData={generalData}
        pageState={pageState}
        onPageStateUpdate={handleStateChange}
        isDisabled={
          isDisabled ||
          generalData?.businessUser?.editableLines?.includes(
            generalData?.lineExternalCode
          )
        }
      />
      {hasRisk(generalData.lineExternalCode) && (
        <RiskDescription
          generalData={generalData}
          pageState={pageState}
          onPageStateUpdate={handleStateChange}
          isDisabled={isDisabled}
          geoLocationsLov={lovs.geoLocations}
        />
      )}
      <InsuredWidget
        generalData={generalData}
        pageState={pageState}
        onPageStateUpdate={handleStateChange}
        isDisabled={isDisabled}
        maxNumber={
          data?.[0]?.policy_PlanID?.views?.PlanConfigManagement_all?.properties
            ?.MaxNbInsured
        }
      />
      <PayerOwnerWidget
        generalData={generalData}
        pageState={pageState}
        onPageStateUpdate={handleStateChange}
        isDisabled={isDisabled}
      />
      <BeneficiaryWidget
        generalData={generalData}
        pageState={pageState}
        onPageStateUpdate={handleStateChange}
        isDisabled={isDisabled}
        maxNumber={
          data?.[0]?.policy_PlanID?.views?.PlanConfigManagement_all?.properties
            ?.MaxNbBeneficiary
        }
      />
      {genericLine === GenericLines.BBB && (
        <BankersDetails
          generalData={generalData}
          pageState={pageState}
          onPageStateUpdate={handleStateChange}
          isDisabled={isDisabled}
        />
      )}
      {!isGroupPolicy && genericLine === GenericLines.MARINE && (
        <MarineDetails
          generalData={generalData}
          pageState={pageState}
          onPageStateUpdate={handleStateChange}
          isDisabled={isDisabled}
          mattersLov={lovs.matters}
        />
      )}
      {genericLine === GenericLines.MARINE_HULL && (
        <MarineHullDetails
          generalData={generalData}
          pageState={pageState}
          onPageStateUpdate={handleStateChange}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
};

export default GenericAmendment;
