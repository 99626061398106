import { gql } from '@apollo/client';

export const getAmendmentListQuery = gql`
  query getAmendmentList($policyId: String!) {
    Production_AmendmentTypes: __type(name: "Production_AmendmentTypes") {
      name
      enumValues {
        name
      }
    }
    Production_AmendmentStatuses: __type(name: "Production_AmendmentStatuses") {
      name
      enumValues {
        name
      }
    }
    Production {
      lookups {
        amendmentSubTypes {
          Id
          Title
        }
      }
      queries {
        getAmendmentList(policyID: $policyId) {
          production_Amendment_Id
          production_Amendment_AmendmentNumber
          production_Amendment_AmendmentType
          production_Amendment_AmendmentSubType {
            Title
          }
          production_Amendment_AmendmentEffectiveDate
          production_Amendment_AmendmentStatus
          production_Amendment_Id
        }
      }
    }
  }
`;

export const createAmendmentMutation = gql`
  mutation createAmendment(
    $policyID: String!
    $amendmentType: Production_AmendmentTypes!
    $amendmentSubType: String
  ) {
    production {
      actions {
        createAmendment(
          policyID: $policyID
          amendmentType: $amendmentType
          amendmentSubType: $amendmentSubType
        ) {
          id
        }
      }
    }
  }
`;
