import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
import { IAmendmentMarineDetails, IAmendmentPageState } from '..';
import { ILineAmendmentProps } from '../line-amendment';
import { validateMarineDetailsField } from './validation';
import WidgetPaper from '../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../components/common/WidgetSection';
import EnhancedChipInput from '../../../../components/enhanced-form/EnhancedChipInput';
import EnhancedInput from '../../../../components/enhanced-form/EnhancedInput';
import EnhancedFormattedNumberInput from '../../../../components/enhanced-form/EnhancedFormattedNumberInput';
import CurrencyFormField from '../../../../components/form-fields/CurrencyFormField';
import EnhancedDatePicker from '../../../../components/enhanced-form/EnhancedDatePicker';
import EnhancedPercentageInput from '../../../../components/enhanced-form/EnhancedPercentageInput';
import { useAmendmentStyles } from '../styles';

interface IMarineDetailsProps {
  generalData: ILineAmendmentProps['generalData'];
  pageState: IAmendmentPageState;
  onPageStateUpdate: (pageState: IAmendmentPageState) => void;
  isDisabled: boolean;
  mattersLov: Record<string, string>;
}

const MarineDetails: React.FC<IMarineDetailsProps> = ({
  generalData,
  pageState,
  onPageStateUpdate,
  isDisabled,
  mattersLov,
}) => {
  const { classes } = useAmendmentStyles();

  const values = pageState.values.marineDetails;
  const errors = pageState.errors.marineDetails;
  const touched = pageState.touched.marineDetails;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const initialize = async () => {};

  useEffect(() => {
    initialize();
  }, []);

  const onFieldBlur = async (fieldName: string) => {
    const newPageState = cloneDeep(pageState);
    newPageState.touched.marineDetails[fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IAmendmentMarineDetails,
    value: IAmendmentMarineDetails[keyof IAmendmentMarineDetails],
    touched = false
  ) => {
    const newPageState = cloneDeep(pageState);
    (newPageState.values.marineDetails as any)[fieldName] = value;
    newPageState.errors.marineDetails[fieldName] = validateMarineDetailsField(
      fieldName,
      value
    );

    if (touched) {
      newPageState.touched.marineDetails[fieldName] = true;
    }

    onPageStateUpdate(newPageState);
  };

  return (
    <WidgetPaper>
      <WidgetSection title="Marine Details" isFieldsGrid>
        <EnhancedChipInput
          key="matter"
          name="matter"
          title="Matter*"
          placeholder="Matter"
          value={values.matter}
          error={touched.matter ? errors.matter : ''}
          onChange={(v) => onFieldUpdate('matter', v, true)}
          disabled={isDisabled}
          selectOptions={mattersLov}
          required
          multiple={false}
          material
          className={classes.fullWidthItem}
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedInput
          key="description"
          name="description"
          title="Description*"
          placeholder="Description"
          value={values.description}
          error={touched.description ? errors.description : ''}
          onBlur={() => onFieldBlur('description')}
          onChange={(v) => onFieldUpdate('description', v.target.value)}
          disabled={isDisabled}
          material
          type="text"
          className={classes.fullWidthItem}
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedInput
          key="vesselName"
          name="vesselName"
          title="Vessel Name*"
          placeholder="Vessel Name"
          value={values.vesselName}
          error={touched.vesselName ? errors.vesselName : ''}
          onBlur={() => onFieldBlur('vesselName')}
          onChange={(v) => onFieldUpdate('vesselName', v.target.value)}
          disabled={isDisabled}
          material
          type="text"
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedFormattedNumberInput
          key="vesselAge"
          name="vesselAge"
          title="Vessel Age"
          placeholder="Vessel Age"
          value={values.vesselAge}
          error={touched.vesselAge ? errors.vesselAge : ''}
          onBlur={() => onFieldBlur('vesselAge')}
          onChange={(v) => onFieldUpdate('vesselAge', v.target.value)}
          disabled={isDisabled}
          material
          type="text"
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedInput
          key="from"
          name="from"
          title="Departure From*"
          placeholder="Departure From"
          value={values.from}
          error={touched.from ? errors.from : ''}
          onBlur={() => onFieldBlur('from')}
          onChange={(v) => onFieldUpdate('from', v.target.value)}
          disabled={isDisabled}
          material
          type="text"
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedInput
          key="to"
          name="to"
          title="Destination To*"
          placeholder="Destination To"
          value={values.to}
          error={touched.to ? errors.to : ''}
          onBlur={() => onFieldBlur('to')}
          onChange={(v) => onFieldUpdate('to', v.target.value)}
          disabled={isDisabled}
          material
          type="text"
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedInput
          key="via"
          name="via"
          title="Transportation Via"
          placeholder="Transportation Via"
          value={values.via}
          error={touched.via ? errors.via : ''}
          onBlur={() => onFieldBlur('via')}
          onChange={(v) => onFieldUpdate('via', v.target.value)}
          disabled={isDisabled}
          material
          type="text"
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedInput
          key="survey"
          name="survey"
          title="Survey*"
          placeholder="Survey"
          value={values.survey}
          error={touched.survey ? errors.survey : ''}
          onBlur={() => onFieldBlur('survey')}
          onChange={(v) => onFieldUpdate('survey', v.target.value)}
          disabled={isDisabled}
          material
          type="text"
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedInput
          key="cargoContract"
          name="cargoContract"
          title="Cargo Contract*"
          placeholder="Cargo Contract"
          value={values.cargoContract}
          error={touched.cargoContract ? errors.cargoContract : ''}
          onBlur={() => onFieldBlur('cargoContract')}
          onChange={(v) => onFieldUpdate('cargoContract', v.target.value)}
          disabled={isDisabled}
          material
          type="text"
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <CurrencyFormField
          key="sumPerShipment"
          name="sumPerShipment"
          title="Sum Per Shipment"
          placeholder="Sum Per Shipment"
          value={values.sumPerShipment}
          error={touched.sumPerShipment ? errors.sumPerShipment : ''}
          onBlur={() => onFieldBlur('sumPerShipment')}
          onChange={(v) => onFieldUpdate('sumPerShipment', v.target.value)}
          disabled={isDisabled}
          material
          currencySymbol={generalData.currency.Symbol}
        />

        <EnhancedInput
          key="lcNumber"
          name="lcNumber"
          title="LC Number"
          placeholder="LC Number"
          value={values.lcNumber}
          error={touched.lcNumber ? errors.lcNumber : ''}
          onBlur={() => onFieldBlur('lcNumber')}
          onChange={(v) => onFieldUpdate('lcNumber', v.target.value)}
          disabled={isDisabled}
          material
          type="text"
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedDatePicker
          key="lcIssueDate"
          name="lcIssueDate"
          title="LC Issue Date"
          placeholder="LC Issue Date"
          value={values.lcIssueDate}
          error={touched.lcIssueDate ? errors.lcIssueDate : ''}
          onDateChange={(v) => {
            (values.lcIssueDate = v.toLocaleString()),
              onFieldUpdate('lcIssueDate', v.toLocaleString(), true);
          }}
          disabled={isDisabled}
          material
          customStyles={{
            useWrapperLabel: true,
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedInput
          key="bank"
          name="bank"
          title="Bank"
          placeholder="Bank"
          value={values.bank}
          error={touched.bank ? errors.bank : ''}
          onBlur={() => onFieldBlur('bank')}
          onChange={(v) => onFieldUpdate('bank', v.target.value)}
          disabled={isDisabled}
          material
          type="text"
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedInput
          key="claimsPayableBy"
          name="claimsPayableBy"
          title="Claims Payable By*"
          placeholder="Claims Payable By"
          value={values.claimsPayableBy}
          error={touched.claimsPayableBy ? errors.claimsPayableBy : ''}
          onBlur={() => onFieldBlur('claimsPayableBy')}
          onChange={(v) => onFieldUpdate('claimsPayableBy', v.target.value)}
          disabled={isDisabled}
          material
          type="text"
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedPercentageInput
          key="loading"
          name="loading"
          title="Loading*"
          placeholder="Loading"
          value={values.loading}
          error={touched.loading ? errors.loading : ''}
          onBlur={() => onFieldBlur('loading')}
          onChange={(v) => onFieldUpdate('loading', v.target.value)}
          disabled={isDisabled}
          material
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedInput
          key="remarks"
          name="remarks"
          title="Remarks"
          placeholder="Remarks"
          value={values.remarks}
          error={touched.remarks ? errors.remarks : ''}
          onBlur={() => onFieldBlur('remarks')}
          onChange={(v) => onFieldUpdate('remarks', v.target.value)}
          disabled={isDisabled}
          material
          type="text"
          className={classes.fullWidthItem}
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />
      </WidgetSection>
    </WidgetPaper>
  );
};

export default MarineDetails;
