import { ITabPageProps } from '../components/widgets';
import { IColumn } from './three-column-layout';

export interface IThreeColumnLayout<K = IColumn>
  extends ILayoutBase,
    IThreeColumnLayoutBase<K> {
  typeLogicalId: LayoutType.threecolumns;
}

export interface IDashboardLayout<K = IColumn>
  extends ILayoutBase,
    IThreeColumnLayoutBase<K> {
  typeLogicalId: LayoutType.dashboardlayout;
  topBanner: K;
}

export interface ITabsLayout<K = IColumn>
  extends ILayoutBase,
    IThreeColumnLayoutBase<K> {
  typeLogicalId: LayoutType.tabslayout;
  tabs?: ITabsProps[];
}

export interface ITabsProps {
  name: string;
  title: string;
  iconUrl?: string;
  count?: number;
  widgets?: ITabPageProps[];
  hidden?: boolean;
}

export interface IThreeColumnLayoutBase<K = IColumn> {
  leftColumn: K;
  mainColumn: K;
  rightColumn: K;
}

export enum LayoutType {
  default = 'default',
  threecolumns = 'threecolumns',
  tabslayout = 'tabslayout',
  dashboardlayout = 'dashboardlayout',
}

export interface ILayoutBase {
  typeLogicalId: string;
}
