export function LookupToList(data: any) {
  const policyCoverEnums: Record<string, Record<string, string>> = {};
  const lines: Record<string, string> = {};
  const treaties: Record<string, string> = {};

  data.PlanConfigManagement.queries.allLines.forEach(
    (element: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Name: string;
    }) => {
      lines[element.planConfigManagement_Line_Id] =
        element.planConfigManagement_Line_Name;
    }
  );

  data.Production.queries.GetActiveTreaties.forEach(
    (element: {
      production_Treaties_Id: string;
      production_Treaties_TreatyID: string;
      production_Treaties_TreatyName: string;
    }) => {
      treaties[element.production_Treaties_Id] =
        `${element.production_Treaties_TreatyID} - ${element.production_Treaties_TreatyName} `;
    }
  );

  policyCoverEnums['lines'] = lines;
  policyCoverEnums['treaties'] = treaties;

  return policyCoverEnums;
}

export function graphqlEntityToPolicyCoverInfo(
  data: any,
  lineId: string,
  lineName: string
): IPolicyCoverInfo {
  return {
    policyCoverId: '',
    lineId: lineId,
    lineName: lineId,
    policyCoverName:
      data?.PlanConfigManagement?.entities?.policyCover?.views
        ?.PlanConfigManagement_all?.properties?.Name,
    policyCoverExternalCode:
      data?.PlanConfigManagement?.entities?.policyCover?.views
        ?.PlanConfigManagement_all?.properties?.ExternalCode,
    arabicName:
      data?.PlanConfigManagement?.entities?.policyCover?.views
        ?.PlanConfigManagement_all?.properties?.NameArabic,
    policyCoverPrintingOrder:
      data?.PlanConfigManagement?.entities?.policyCover?.views
        ?.PlanConfigManagement_all?.properties?.PrintingOrder,
    policyCoverDescription:
      data?.PlanConfigManagement?.entities?.policyCover?.views
        ?.PlanConfigManagement_all?.properties?.PolicyCoverDescription,
    policyCoverTreatyID:
      data?.Production?.queries?.CheckIfPolicyCoverTreatyExist[0]?.treaty_Id,
  };
}
