import { gql } from '@apollo/client';

export function getCertificatePolicyDetailsQuery() {
  return gql`
    query getCertificatePolicyDetails($policyId: String!) {
      Accounting {
        queries {
          GetPolicyCertificatesBills(ParentPolicyID: $policyId) {
            accounting_Bills_BillNumber
            accounting_Bills_Id
            accounting_Bills_DueDate
            accounting_Bills_TotalPremium
            accounting_Bills_AmountDue
            accounting_Bills_TotalCommission
            accounting_Bills_TaxOnCommission
            payableBills_AmountPaid
            accounting_Bills_AmountReceived
            accounting_Bills_Currency {
              Symbol
            }
          }
        }
      }
    }
  `;
}
