import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../redux/hooks';
import { useQuery } from '@apollo/client';
import { mapFileToDocumentWidgetFiles } from '../utils';
import Loader from '../../../../../components/Loader';
import DocumentWidget from '../../../../../components/widgets/file-card/DocumentWidget';
import EnhancedButton from '../../../../../components/form-fields/buttons/EnhancedButton';
import { getFacDocumentsQuery } from '../queries';
import { IFileCardProps } from '../../../../../models/file';
import AddFacDocumentDrawer from './AddFacDocumentDrawer';

const FacultativeDocumentsWidget = () => {
  const { id: currentFacId } = useParams<{ id: string }>();
  const tenant = useAppSelector((state) => state.tenant);
  const [showAddDrawer, setShowAddDrawer] = useState<boolean>(false);

  const [documentsData, setDocumentsData] = useState<IFileCardProps[]>();

  const { refetch, data, loading } = useQuery(getFacDocumentsQuery, {
    variables: { FacultativeID: currentFacId },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const docsList = mapFileToDocumentWidgetFiles(
      data?.Production?.queries?.GetFacDocuments
    );

    setDocumentsData(docsList);
  }, [data]);
  return loading ? (
    <Loader />
  ) : (
    <>
      <DocumentWidget
        title="Supporting Documents"
        cdnUrl={tenant.cdnUrl}
        files={documentsData}
        actions={
          <EnhancedButton
            isPrimary={false}
            onClick={() => setShowAddDrawer(true)}
          >
            Add Document
          </EnhancedButton>
        }
      />

      {showAddDrawer && (
        <AddFacDocumentDrawer
          currentFacId={currentFacId}
          isOpen={showAddDrawer}
          onClose={() => setShowAddDrawer(false)}
          onSuccess={() => refetch()}
        />
      )}
    </>
  );
};

export default FacultativeDocumentsWidget;
