import React, { useEffect, useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getInputs } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  createBusinessPartnerPlan,
  getBusinessPartnerPlansLinesListWithoutPagination,
  getPlanListByAssignedLines,
  getDefaultCommissionEnums,
} from './queries';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../constants';
import { isEmpty, isEmptyValue } from '../../utils/validationUtils';
import { getError } from '../../utils/graph-utils';
import {
  extractLines,
  extractPlans,
  extractSelectedPlanByLine,
  getBusinessPartnerLineDetails,
  LookupToList,
} from './utils';
import Loader from '../../components/Loader';
import { IBusinessPartnerAddPlansDrawerProps } from '.';
import {
  formatDateTime,
  trimTrailingZeros,
} from '../../utils/formatting-utils';
import EnhancedNote from '../../components/enhanced-form/EnhancedNote';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(() => ({
  note: {
    color: 'red !important',
    fontSize: '12px !important',
    fontStyle: 'italic',
    margin: '10px 0 0 0',
  },
}));

const BusinessPartnerAddPlansDrawer: React.FC<
  IBusinessPartnerAddPlansDrawerProps
> = ({
  businessPartnerId,
  businessPartnerType,
  assignedLineId,
  selectedBusinessPlanIds,
  selectedDetailedSelection,
  open,
  onSuccess,
  onClose,
}) => {
  const { classes } = useStyles();

  const [createBusinessPartnerPlanAction] = useMutation(
    createBusinessPartnerPlan()
  );
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [values, setValues] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);

  const [getBusinessPartnerLinesListWithoutPaginationLazy, businessLineData] =
    useLazyQuery(getBusinessPartnerPlansLinesListWithoutPagination());

  const [getPlanListByAssignedLinesLazy] = useLazyQuery(
    getPlanListByAssignedLines(),
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [plansData, setPlansData] = useState<any>();

  const [getDefaultCommissionEnumsLazy] = useLazyQuery(
    getDefaultCommissionEnums()
  );

  const [lists, setLists] = useState<Record<string, Record<string, string>>>({
    line: {},
    plan: {},
    commissionType: {},
    ValueCurrency: {},
    ApplicableOn: {},
  });

  const initialize = async () => {
    setLoading(true);

    const businessPartnerLines =
      await handleBusinessPartnerSelection(businessPartnerId);

    const commissionEnumsData = await getDefaultCommissionEnumsLazy();

    const newDefualtCommissionEnums = LookupToList(commissionEnumsData.data);

    const newList: Record<string, Record<string, string>> = {
      line: businessPartnerLines.result,
      lineMapping: businessPartnerLines.partnerLineDictionary,
      plan: {},
      commissionType: newDefualtCommissionEnums['commissionTypes'] || {},
      ValueCurrency: newDefualtCommissionEnums['currencies'] || {},
      ApplicableOn:
        newDefualtCommissionEnums['commissionApplicableOnOptions'] || {},
    };

    if (assignedLineId) {
      const plans = await handleLineSelection(assignedLineId);
      newList['plan'] = plans;
      setValues((prevValues) => ({
        ...prevValues,
        line: assignedLineId,
        plan: selectedBusinessPlanIds || [],
        ...selectedDetailedSelection,
      }));
    }

    setLists(newList);
    setLoading(false);
  };

  useEffect(() => {
    initialize();
  }, [businessPartnerId]);

  const handleBusinessPartnerSelection = async (selectedOption: string) => {
    if (!isEmpty(selectedOption)) {
      const newData = await getBusinessPartnerLinesListWithoutPaginationLazy({
        fetchPolicy: 'no-cache',
        variables: {
          SelectedStatuses: ['ACTIVE'],
          selectedBusinessPartnerID: businessPartnerId,
        },
      });
      return extractLines(newData.data);
    }
    return { result: {}, partnerLineDictionary: {} };
  };

  const handleLineSelection = async (selectedOption: string) => {
    if (!isEmpty(selectedOption)) {
      const newData = await getPlanListByAssignedLinesLazy({
        variables: {
          selectedBusinessPartnerID: businessPartnerId,
          selectedLineID: selectedOption,
          SelectedStatuses: ['ACTIVE'],
        },
        errorPolicy: 'all',
      });
      setPlansData(newData.data);
      return extractPlans(newData.data);
    }
    return {} as Record<string, string>;
  };

  const getDataFromSeletedPlans = (selectedDetailsPlan: any) => {
    const type =
      selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
      true
        ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionType.toUpperCase()
        : selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionType !==
            null
          ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionType
          : 'RATE';
    const rate =
      selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
      true
        ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionRate *
          100
        : selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionRate !==
            (0 || undefined || null)
          ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionRate *
            100
          : 0;
    const commissionValue =
      selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
      true
        ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValue
        : selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionValue !==
            (0 || undefined || null)
          ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionValue
          : 0;

    const valueCurrency =
      selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
      true
        ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValueCurrency
        : !isEmptyValue(
              selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ValueCurrency
            )
          ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ValueCurrency
          : '39735';

    const applicableOn =
      selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
      true
        ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicableOn
        : selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ApplicableOn !==
            null
          ? selectedDetailsPlan?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ApplicableOn
          : '';
    const activeFrom =
      selectedDetailsPlan?.businessPartnerActiveFrom !== null
        ? selectedDetailsPlan?.businessPartnerActiveFrom
        : '';
    const activeTo =
      selectedDetailsPlan?.businessPartnerActiveTo !== null
        ? selectedDetailsPlan?.businessPartnerActiveTo
        : '';

    return {
      type,
      rate,
      commissionValue,
      valueCurrency,
      applicableOn,
      activeFrom,
      activeTo,
    };
  };

  const onCustomChange = async (
    fieldName: string,
    value: any,
    allValues: Record<string, any>,
    errors: Record<string, string>
  ) => {
    const newLists = { ...lists };

    if (fieldName === 'line') {
      setFormDisabled(true);
      newLists['plan'] = await handleLineSelection(value);
    }

    if (fieldName === 'plan') {
      const newListCount = value?.length || 0;

      if (plansData && newListCount == 1) {
        const selectedOptionData =
          plansData.SalesforceManagement.queries.GetPlanListByAssignedLines.find(
            (item: any) => item.planConfigManagement_Plan_Id === value[0]
          );
        const selectedPlanData = extractSelectedPlanByLine(
          lists?.lineMapping[allValues.line] || '',
          selectedOptionData
        );

        const lineDetails = getBusinessPartnerLineDetails(
          businessLineData.data,
          allValues.line
        );
        const { type, rate, commissionValue, valueCurrency, applicableOn } =
          getDataFromSeletedPlans(selectedPlanData);
        allValues.commissionType = type || lineDetails?.type;
        allValues.lineCommissionValue = commissionValue || lineDetails?.value;
        allValues.lineCommissionRate = rate || lineDetails?.rate;
        allValues.ValueCurrency = valueCurrency || lineDetails?.currency;
        allValues.ApplicableOn = applicableOn || lineDetails?.applicableOn;
        allValues.activeFrom =
          selectedOptionData.planConfigManagement_Plan_EffectiveFrom;
        allValues.activeTo =
          selectedOptionData.planConfigManagement_Plan_EffectiveTo;

        errors.commissionType = '';
        errors.lineCommissionRate = '';
        errors.ValueCurrency = '';
        errors.ApplicableOn = '';
        errors.activeFrom = '';
        errors.activeTo = '';
        errors.lineCommissionValue = '';
      } else {
        allValues.commissionType = 'RATE';
        allValues.lineCommissionValue = '';
        allValues.lineCommissionRate = '';
        allValues.ValueCurrency = '';
        allValues.ApplicableOn = '';
        allValues.activeFrom = '';
        allValues.activeTo = '';
        errors.commissionType = '';
        errors.lineCommissionRate = '';
        errors.ValueCurrency = '';
        errors.ApplicableOn = '';
        errors.activeFrom = '';
        errors.activeTo = '';
        errors.lineCommissionValue = '';
      }
    }

    if (fieldName === 'commissionType') {
      const isRate = value.toLowerCase() === 'rate';
      if (isRate) {
        allValues['lineCommissionValue'] = '';
        allValues['ValueCurrency'] = '';
      } else {
        allValues['lineCommissionRate'] = '';
      }
    }

    if (fieldName === 'activeFrom' || fieldName === 'activeTo') {
      if (isEmpty(value)) {
        errors[fieldName] = 'Required';
      } else {
        errors[fieldName] = '';
      }
    }

    setValues(allValues);
    setLists(newLists);
    setFormDisabled(false);
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(
      getInputs(values as any, lists, businessPartnerType),
      values
    );

    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const isRate = data.commissionType.toLowerCase() === 'rate';

      const variables = {
        selectedPlanIDs: data.plan || [],
        businessPartnerLineID: lists?.lineMapping[data.line] || '',
        businessPartnerPlanInputs: {
          activeFrom: formatDateTime(
            data.activeFrom,
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          activeTo: formatDateTime(data.activeTo, SEND_TO_BACKEND_DATE_FORMAT),
          planCommissionApplicableOn: data.ApplicableOn || null,
          planCommissionRate: isRate ? data.lineCommissionRate / 100 : 0,
          planCommissionType: data.commissionType,
          planCommissionValue: !isRate
            ? trimTrailingZeros(data.lineCommissionValue)
            : 0,
          planCommissionValueCurrency: !isRate ? data.ValueCurrency : '39735',
        },
      };

      const response = await createBusinessPartnerPlanAction({
        variables: { ...variables, entityId: businessPartnerId },
        errorPolicy: 'all',
      });

      if (isEmpty(response.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {'Plan(s) successfully assigned.'}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState('success');
          onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(
          <ToastErrorMessage>{getError(response)}</ToastErrorMessage>
        );
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  const inputForm = useMemo(() => {
    return getInputs(values as any, lists, businessPartnerType);
  }, [values, lists, businessPartnerType]);

  return (
    <GenericDrawer
      title={'Add Plan(s)'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            onChange={onCustomChange}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
            noteSection={
              <EnhancedNote
                name="note"
                title=""
                text="Note that plans' preset special commissions will override the commissions entered in this form."
                className={classes.note}
              />
            }
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default BusinessPartnerAddPlansDrawer;
