import React from 'react';
import { ITheme } from '../../../redux/tenant/types';
import { makeStyles } from 'tss-react/mui';
import { useAppSelector } from '../../../redux/hooks';
import { MAIN_ONE_THEME } from '../../../constants';
import { valueCommaSeparated } from '../../../utils/formatting-utils';

interface IOfferCoverageProps {
  disabled: boolean;
  premium: number;
  currency: string;
  premiumTitle: string;
  coverageTitle: string;
  coverage: number;
  hidePremium: boolean;
}

const useStyles = makeStyles<{ theme: ITheme }>()((_, { theme }) => ({
  container: {
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    color: theme.palette.primary1.main,
    fontSize: '14px',
    lineHeight: '17px',
    display: 'block',
    textAlign: 'left',
  },
  mainAmount: {
    color: theme.palette.primary1.main,
    fontSize: '20px',
    lineHeight: '25px',
    textAlign: 'left',
    fontWeight: 'bold',
    margin: '3px 0',
  },
  secondaryAmount: {
    color: theme.palette.primary5.main,
    fontSize: '20px',
    lineHeight: '25px',
    textAlign: 'left',
    fontWeight: 'bold',
    margin: '3px 0',
  },
  detailContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
    paddingTop: '10px',
  },
  coverages: {},
  numberValue: {
    fontSize: '20px',
  },
  textValue: {
    fontSize: '12px',
  },
  mainAmountValue: {
    color: MAIN_ONE_THEME.palette.primary5.main,
    fontSize: '20px',
    lineHeight: '20px',
    float: 'left',
    fontWeight: 'bold',
    // height: '100px',
  },
  premiumAmount: {
    color: MAIN_ONE_THEME.palette.error.main,
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: 'bold',
    float: 'right',
  },
}));

const OfferCoverage: React.FC<IOfferCoverageProps> = ({
  premiumTitle,
  coverageTitle,
  currency,
  coverage,
  premium,
}) => {
  const theme = useAppSelector((state) => state.tenant.theme);

  const { classes } = useStyles({ theme });

  return (
    <div className={classes.container}>
      <div
        className={classes.titleContainer}
        style={{
          justifyContent: premiumTitle ? 'space-between' : 'flex-start',
        }}
      >
        <span className={classes.title}>{coverageTitle}</span>
        {!!premiumTitle && (
          <span className={classes.title}>{premiumTitle}</span>
        )}
      </div>
      <div className={classes.detailContainer}>
        <span className={classes.mainAmountValue}>
          {`${currency} ${valueCommaSeparated(coverage?.toFixed(0))}`}
        </span>
        <span className={classes.premiumAmount}>
          {`${currency} ${valueCommaSeparated(premium?.toFixed(0))}`}
        </span>

        {/* {renderValues()}



        
        <CustomDropdown
          disabled={hidePremiumBreakdown || disabled}
          options={options}
          value={getDropdownText()}
          classes={{
            container: offerClasses?.dropdownClasses?.container,
            button: offerClasses?.dropdownClasses?.button,
            arrow: offerClasses?.dropdownClasses?.arrow,
          }}
          onOptionClick={(option: string) =>
            onCycleChange(option as CompanyOfferWidgetPremiums)
          }
        /> */}
      </div>
    </div>
  );
};

export default OfferCoverage;
