import React, { useEffect, useState } from 'react';
import ListingTable from '../../../../../../components/form-fields/table/ListingTable';
import { facultativesHeaders } from './content';
import ToastErrorMessage from '../../../../../../components/ToastErrorMessage';
import { getError } from '../../../../../../utils/graph-utils';
import { toast } from 'react-toastify';
import { IListingData } from '../../../../../../models/listing';
import { useQuery } from '@apollo/client';
import { getFacOfPoliciesQuery } from './queries';
import { mapToListingData } from './utils';
import { useParams } from 'react-router-dom';

const FacultativesWidget = () => {
  const { id: currentReinsurerId } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageNumber: 1,
    pageSize: 5,
    totalCount: 0,
  });

  const { data, error, loading } = useQuery(getFacOfPoliciesQuery, {
    variables: {
      ReinsurerID: currentReinsurerId,
    },
    errorPolicy: 'all',
  });

  if (error) {
    toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
  }

  const initialize = () => {
    try {
      if (data) {
        const mappedData = mapToListingData(
          data.Accounting.queries.GetFacOfPoliciesOfReinsurer
        );
        setTableData({
          pagedItems: mappedData,
          pageNumber: 1,
          pageSize: 5,
          totalCount: Object.keys(mappedData).length,
        });
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ListingTable
      name={'facultatives'}
      inlineTitle="Facultatives"
      headers={facultativesHeaders}
      data={tableData}
      loader={loading}
      actions={[]}
      disableSelection
      inline
    />
  );
};

export default FacultativesWidget;
