import { cloneDeep } from 'lodash';
import React from 'react';
import { IAmendmentPageState, IRiskDescription } from '..';
import { ILineAmendmentProps } from '../line-amendment';
import WidgetPaper from '../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../components/common/WidgetSection';
import EnhancedInput from '../../../../components/enhanced-form/EnhancedInput';
import EnhancedChipInput from '../../../../components/enhanced-form/EnhancedChipInput';
import { useAmendmentStyles } from '../styles';

interface IRiskDescriptionProps {
  generalData: ILineAmendmentProps['generalData'];
  pageState: IAmendmentPageState;
  onPageStateUpdate: (pageState: IAmendmentPageState) => void;
  isDisabled: boolean;
  geoLocationsLov: Record<string, string>;
}

const RiskDescription: React.FC<IRiskDescriptionProps> = ({
  pageState,
  onPageStateUpdate,
  isDisabled,
  geoLocationsLov,
}) => {
  const { classes } = useAmendmentStyles();

  const values = pageState.values.descriptionOfRiskDetails;
  const errors = pageState.errors.descriptionOfRiskDetails;
  const touched = pageState.touched.descriptionOfRiskDetails;

  const onFieldBlur = (fieldName: string) => {
    const newPageState = cloneDeep(pageState);
    newPageState.touched.descriptionOfRiskDetails[fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IRiskDescription,
    value: IRiskDescription[keyof IRiskDescription],
    touched = false
  ) => {
    const newPageState = cloneDeep(pageState);
    newPageState.values.descriptionOfRiskDetails[fieldName] = value;

    if (touched) {
      newPageState.touched.descriptionOfRiskDetails[fieldName] = true;
    }

    onPageStateUpdate(newPageState);
  };

  return (
    <WidgetPaper>
      <WidgetSection title="Description of Risk" isFieldsGrid fieldsPerRow={2}>
        <EnhancedInput
          key="descriptionOfRisk"
          name="descriptionOfRisk"
          title="Description of Risk"
          placeholder="Description of Risk"
          value={values.descriptionOfRisk}
          error={touched.descriptionOfRisk ? errors.descriptionOfRisk : ''}
          onBlur={() => onFieldBlur('descriptionOfRisk')}
          onChange={(v) => onFieldUpdate('descriptionOfRisk', v.target.value)}
          disabled={isDisabled}
          material
          type="text"
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedChipInput
          key="geoLocation"
          name="geoLocation"
          title="Geographical Location*"
          placeholder="Geographical Location"
          value={values.geoLocation}
          error={touched.geoLocation ? errors.geoLocation : ''}
          onChange={(v) => onFieldUpdate('geoLocation', v, true)}
          disabled={isDisabled}
          selectOptions={geoLocationsLov}
          required
          multiple={false}
          material
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />
      </WidgetSection>
    </WidgetPaper>
  );
};

export default RiskDescription;
