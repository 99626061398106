import {
  IMarineGroupCertficateForm,
  IMarineGroupCertificateGroupCover,
  IProposalCover,
} from '..';
import { IAbstractRecord } from '../../../../../../../../models';

export function extractSublines(data: IAbstractRecord) {
  const sublines: Record<string, string> = {};

  data?.SalesforceManagement?.queries?.getActiveAssignedBPPlanList?.items?.forEach(
    (element: {
      subline_Id: string;
      salesforceManagement_AssignedBPPlan_PlanID: {
        views: {
          PlanConfigManagement_all: {
            properties: {
              SublineID: {
                views: {
                  PlanConfigManagement_all: {
                    properties: {
                      Name: string;
                      ExternalCode: string;
                    };
                  };
                };
              };
            };
          };
        };
      };
    }) => {
      sublines[element.subline_Id] =
        `${element.salesforceManagement_AssignedBPPlan_PlanID.views.PlanConfigManagement_all.properties.SublineID.views.PlanConfigManagement_all.properties.ExternalCode} - ${element.salesforceManagement_AssignedBPPlan_PlanID.views.PlanConfigManagement_all.properties.SublineID.views.PlanConfigManagement_all.properties.Name}`;
    }
  );

  return sublines;
}

export function extractPlans(data: IAbstractRecord) {
  const lines: Record<string, string> = {};
  const planCurrencies: Record<string, string> = {};
  const policyCosts: Record<string, Record<string, number>> = {};

  data?.SalesforceManagement?.queries?.getActiveAssignedBPPlanList?.items?.forEach(
    (element: {
      plan_Id: string;
      salesforceManagement_AssignedBPPlan_PlanID: {
        views: {
          PlanConfigManagement_all: {
            properties: {
              PolicyCost: number;
              TPAFeesAmount: number;
              ChargesPercentage: number;
              Name: string;
              ExternalCode: string;
              PlanCurrency: {
                Code: string;
              };
            };
          };
        };
      };
    }) => {
      const properties =
        element.salesforceManagement_AssignedBPPlan_PlanID.views
          .PlanConfigManagement_all.properties;
      lines[element.plan_Id] =
        `${properties.ExternalCode} - ${properties.Name}`;

      planCurrencies[element.plan_Id] = properties.PlanCurrency?.Code;

      policyCosts[element.plan_Id] = {
        policyCost: properties.PolicyCost,
        tpaFeesAmount: properties.TPAFeesAmount,
        chargesPercentage: Number(properties.ChargesPercentage) * 100,
      };
    }
  );

  return { lines, planCurrencies, policyCosts };
}

export function extractCovers(
  data: IAbstractRecord,
  values: IMarineGroupCertficateForm
) {
  const coversList: Record<string, string> = {};
  const planCovers: Record<string, IProposalCover> = {};

  data?.Production?.queries?.GetPlanCovers?.forEach(
    (element: {
      policyCover_Id: string;
      policyCover_Name: string;
      policyCover_ExternalCode: string;
      planConfigManagement_PlanCover_Id: string;
      planConfigManagement_PlanCover_IsMain: boolean;
      planConfigManagement_PlanCover_IsMandatory: boolean;
      planConfigManagement_PlanCover_IsEditable: boolean;
      planConfigManagement_PlanCover_CoverPremiumType: string;
      planConfigManagement_PlanCover_CoverPremiumPercentage: string;
      planConfigManagement_PlanCover_CoverPremiumValue: number;
      planConfigManagement_PlanCover_CoverSumInsured: number;
      planConfigManagement_PlanCover_SumInsuredIsAdditive: boolean;
      planConfigManagement_PlanCover_ExcessOnClaimType: string;
      planConfigManagement_PlanCover_ExcessOnClaimAmount: number;
      planConfigManagement_PlanCover_ExcessOnClaimPercentage: string;
      planConfigManagement_PlanCover_ExcessOnClaimDays: number;
      planConfigManagement_PlanCover_ClauseID: {
        id: string;
      };
      planConfigManagement_PlanCover_PlanID: {
        views: {
          PlanConfigManagement_all: {
            properties: {
              PlanCurrency: {
                Code: string;
              };
            };
          };
        };
      };
    }) => {
      coversList[element.policyCover_Id] =
        `${element.policyCover_ExternalCode} - ${element.policyCover_Name}`;

      planCovers[element.policyCover_Id] = {
        name: element.policyCover_Name,
        isMain: element.planConfigManagement_PlanCover_IsMain,
        isMandatory: element.planConfigManagement_PlanCover_IsMandatory,
        isEditable: element.planConfigManagement_PlanCover_IsEditable,
        policyCover: element.policyCover_Id,
        coverPremiumType:
          element.planConfigManagement_PlanCover_CoverPremiumType,
        coverPremiumPercentage:
          Number(
            element.planConfigManagement_PlanCover_CoverPremiumPercentage
          ) * 100,
        coverPremiumValue:
          element.planConfigManagement_PlanCover_CoverPremiumValue,
        premium:
          element.planConfigManagement_PlanCover_CoverPremiumType === 'VALUE'
            ? element.planConfigManagement_PlanCover_CoverPremiumValue
            : Number(
                element.planConfigManagement_PlanCover_CoverPremiumPercentage
              ) * 100,
        currency:
          element.planConfigManagement_PlanCover_PlanID.views
            .PlanConfigManagement_all.properties.PlanCurrency.Code,
        coverSumInsured: element.planConfigManagement_PlanCover_CoverSumInsured,
        sumInsuredIsAdditive:
          element.planConfigManagement_PlanCover_SumInsuredIsAdditive,
        excessOnClaimType:
          element.planConfigManagement_PlanCover_ExcessOnClaimType,
        excessOnClaimAmount:
          element.planConfigManagement_PlanCover_ExcessOnClaimAmount,
        excessOnClaimPercentage:
          Number(
            element.planConfigManagement_PlanCover_ExcessOnClaimPercentage
          ) * 100,
        excessOnClaimDays:
          element.planConfigManagement_PlanCover_ExcessOnClaimDays,
        clauseID: element.planConfigManagement_PlanCover_ClauseID?.id,
        planCoverId: element.planConfigManagement_PlanCover_Id,
        coverEffectiveFrom: values.policyDetails.policyEffectiveDate,
        coverEffectiveTo: values.policyDetails.policyExpiryDate,
      };
    }
  );

  return { planCovers, coversList };
}
