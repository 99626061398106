import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import PageTitle from './shared/PageTitle';
import { useLazyQuery } from '@apollo/client';
import { getLovs } from '../queries';
import PaymentVouchersTable from './payment-vouchers/PaymentVouchersTable';
import { IFormValues } from './ViewBreakdown';
import { enumListAsRecordObject } from '../../../../../utils/graph-utils';
import ViewBreakdownShell from './ViewBreakdownShell';
import FacultativesWidget from './facultatives/FacultativesWidget';

interface IMainColumnProps {
  mainEntityId: string;
  businessPartnerType: string;
}

const useStyles = makeStyles()(() => ({
  container: {
    marginTop: '36px',
  },
}));

const MainColumn: React.FC<IMainColumnProps> = ({
  mainEntityId,
  businessPartnerType,
}) => {
  const { classes } = useStyles();
  const [fetchLovs, { data: lovs }] = useLazyQuery(getLovs);
  const [viewBreakdownPopup, setViewBreakdownPopup] = useState<{
    open: boolean;
    selected?: {
      id: string;
    } & IFormValues;
  }>({
    open: false,
  });

  useEffect(() => {
    fetchLovs();
  }, [fetchLovs, mainEntityId]);

  const paymentMethods = useMemo(() => {
    if (!lovs) return {};

    return enumListAsRecordObject(
      lovs.Accounting_PaymentMethodList?.enumValues as {
        __typename: string;
        name: string;
      }[]
    );
  }, [lovs]);

  return (
    <div>
      {viewBreakdownPopup.open && (
        <ViewBreakdownShell
          open={viewBreakdownPopup.open}
          policyId={viewBreakdownPopup.selected?.id}
          businessPartnerId={mainEntityId}
          data={viewBreakdownPopup.selected}
          onClose={() => {
            setViewBreakdownPopup({
              open: false,
              selected: undefined,
            });
          }}
        />
      )}
      <PageTitle text={'Receivables & Payables'} />
      {businessPartnerType === 'Reinsurer' && (
        <div className={classes.container}>
          <FacultativesWidget />
        </div>
      )}
      <div className={classes.container}>
        <PaymentVouchersTable
          paymentMethods={paymentMethods}
          onSuccess={() => {}}
        />
      </div>
    </div>
  );
};

export default MainColumn;
