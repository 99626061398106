import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../../../components/enhanced-table';

export const headers: Record<string, EnhancedTableHeader> = {
  TreatyReinsurersBusinessPartner_FullName: {
    name: 'TreatyReinsurersBusinessPartner_FullName',
    title: 'Reinsurer',
    type: EnhancedTableHeaderType.Text,
  },
  Production_TreatyReinsurers_SharePercentage: {
    name: 'Production_TreatyReinsurers_SharePercentage',
    title: 'Share',
    type: EnhancedTableHeaderType.Percentage,
  },
};
