export interface IGenericPoliciesTableData {
  production_Policy_Id: string;
  production_Policy_PolicyName: string;
  production_Policy_PolicyNumber: string;
  production_Policy_InternalCode: string;
  production_Policy_PolicyEffectiveDate: string;
  production_Policy_PolicyIssueDate: string;
  production_Policy_PolicyExpiryDate: string;
  production_Policy_Status: string;
  production_Policy_StatusReason: {
    Code: string;
    Title: string;
  };
  production_Policy_MotorDetailsID: {
    views: {
      Production_all: {
        properties: {
          NameOnLicenseEn: string;
          NameOnLicenseAr: string;
        };
      };
    };
  };
  production_Policy_PrimaryBPID: {
    views: {
      SalesforceManagement_all: {
        properties: {
          Code: string;
        };
      };
    };
  };
  production_Policy_InsuredID: {
    views: {
      Production_all: {
        properties: {
          PersonCode: string;
          FullName: string;
        };
      };
    };
  };
  production_Policy_createdOn: string;
  production_Policy_modifiedOn: string;
  line_Id: string;
  line_Name: string;
  line_ExternalCode: string;
  production_Policy_LineID: {
    views: {
      PlanConfigManagement_all: {
        properties: {
          Name: string;
          ExternalCode: string;
        };
      };
    };
  };
  production_Policy_SublineID: {
    views: {
      PlanConfigManagement_all: {
        properties: {
          Name: string;
          ExternalCode: string;
        };
      };
    };
  };
  production_Policy_PlanID: {
    views: {
      PlanConfigManagement_all: {
        properties: {
          Name: string;
          ExternalCode: string;
        };
      };
    };
  };
  production_Policy_BusinessUserID: {
    views: {
      SalesforceManagement_all: {
        properties: {
          Account: string;
        };
      };
    };
  };
}

export interface IPolicyFilterLovs {
  line: Record<string, string>;
  subline: Record<string, string>;
  plan: Record<string, string>;
  businessPartner: Record<string, string>;
  insured: Record<string, string>;
  status: Record<string, string>;
  statusReason: Record<string, string>;
}

export const DEFAULT_FILTER_LOVS = {
  line: {},
  subline: {},
  plan: {},
  businessPartner: {},
  insured: {},
  status: {},
  statusReason: {},
};
