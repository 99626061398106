import IReceivablesPayablesTableData, {
  IReceivablesPayablesResponseData,
} from '.';
import { IAbstractRecord } from '../../../models';
import { IListingData } from '../../../models/listing';
import { lookupListAsRecordObject } from '../../../utils/graph-utils';

export function mapToListingData(data: IAbstractRecord): IListingData {
  const list: Record<string, IReceivablesPayablesTableData> = {};

  data.Accounting.queries.GetBusinessPartnerPolicies?.items?.forEach(
    (obj: IReceivablesPayablesResponseData) => {
      const businessPartnerId = obj.salesforceManagement_BusinessPartner_Id;

      if (!list[businessPartnerId]) {
        list[businessPartnerId] = {
          SalesforceManagement_BusinessPartner_Id: businessPartnerId,
          SalesforceManagement_BusinessPartner_FullName:
            obj.salesforceManagement_BusinessPartner_FullName,
          SalesforceManagement_BusinessPartner_Type:
            obj.salesforceManagement_BusinessPartner_Type.Title,
        };
      }
    }
  );

  const paging = data.Accounting.queries.GetBusinessPartnerPolicies?.paging;

  return {
    pagedItems: list,
    pageSize: paging.pageSize,
    pageNumber: paging.pageNumber,
    totalCount: paging.totalCount,
  };
}

export function toLookupsData(lovsData: Record<string, any>) {
  const lovs: Record<string, Record<string, string>> = {
    company: {},
    businessPartnerType: {},
  };

  lovs.company = lookupListAsRecordObject(
    lovsData?.SalesforceManagement?.lookups?.relatedCompanies
  );

  lovs.businessPartnerType = lookupListAsRecordObject(
    lovsData?.SalesforceManagement?.lookups?.businessPartnerTypes
  );

  return lovs;
}
