import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import SelectFormField from '../../../../../../components/form-fields/SelectFormField';
import {
  ITreatyLovs,
  ITreatyDetails,
  ITreatyErrors,
  ITreatyLayer,
} from '../..';
import { cloneDeep } from 'lodash';
import EnhancedPercentageInput from '../../../../../../components/enhanced-form/EnhancedPercentageInput';
import DatePickerFormField from '../../../../../../components/form-fields/DatePickerFormField';
import CurrencyFormField from '../../../../../../components/form-fields/CurrencyFormField';
import AddIcon from '@mui/icons-material/Add';
import CategoryRepeater from './CategoryRepeater';
import CloseIcon from '@mui/icons-material/Close';

export interface ITabsRepeater {
  values: ITreatyDetails;
  onChange?: (allValues: ITreatyDetails) => void;
  errors: ITreatyErrors;
  updateErrors: (errors: ITreatyErrors) => void;
  lovs: ITreatyLovs;
  tabsTitle: string;
  maxTabs?: number;
  minTabs?: number;
  showTabs?: boolean;
  isDisabled?: boolean;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    boxSizing: 'border-box',
  },
  tabLabel: {
    fontSize: '14px',
    fontFamily: 'SourceSansPro-Regular',
    textTransform: 'none',
    lineHeight: '18px',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
  },
  tabActiveTitle: {
    fontFamily: 'SourceSansPro-SemiBold !important',
    fontWeight: 600,
  },
  tabIconButton: {
    marginLeft: '8px',
    padding: '0',
  },
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tabsWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  indicator: {
    display: 'none',
  },
  fieldRowChildSpan2: {
    gridColumn: 'span 2',
  },
  tabsRoot: {
    minHeight: 'auto',
    overflowX: 'auto',
    '& .MuiTabs-flexContainer': {
      borderBottom: 'none',
    },
  },
  tabRoot: {
    textTransform: 'none',
    minWidth: 72,
    minHeight: 'auto',
    padding: '6px 12px',
    border: '1px solid #E5E5E5',
    borderBottom: 'none',
    borderRadius: '4px 4px 0 0',
    marginRight: '2px',
    // backgroundColor: '#F9F9F9',
    '&.Mui-selected': {
      fontWeight: 600,
    },
  },
  addBtn: {
    marginLeft: '2px',
    minWidth: 'auto',
    minHeight: 'auto',
    backgroundColor: 'transparent',
    padding: 0,
    margin: '0 0 0 10px',
    alignSelf: 'center',
    border: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  deleteTabButton: {
    backgroundColor: 'transparent',
    border: 'none',
    position: 'absolute',
    top: '8px',
    right: '8px',
    cursor: 'pointer',
  },
  tabPanel: {
    boxSizing: 'border-box',
    border: '1px solid #E5E5E5',
    borderTop: 0,
    // backgroundColor: '#F9F9F9',
    position: 'relative',
  },
  tabPanelContent: {
    display: 'grid',
    gridTemplateColumns: `repeat(3, 32%)`,
    gap: '2%',
  },
}));

const TabsRepeater: React.FC<ITabsRepeater> = ({
  values,
  onChange,
  errors,
  updateErrors,
  lovs,
  tabsTitle,
  showTabs = true,
  isDisabled = false,
}) => {
  const { classes } = useStyles();
  const [activeTab, setActiveTab] = useState<string>('tab_0');

  const tabs = Object.keys(values.layers);

  const handleAddTab = () => {
    const newTabKey = `tab_${tabs.length}`;

    const newValues = cloneDeep(values) as ITreatyDetails;
    newValues.layers[newTabKey] = {
      treatySubType: 'QuotaShare',
      retentionShare: '',
      paymentDate: '',
      paymentAmount: '',
      totalTreatyPremium: '',
      totalExpectedPremium: '',
      premiumRate: '',
      categories: [
        {
          category: '',
          minimumLayerLimit: '',
          maximumLayerLimit: '',
          retentionLimit: '',
          priorityLimit: '',
          liabilityLimit: '',
          aggregateLimit: '',
          commissionRate: '',
        },
      ],
    };

    const newErrors = cloneDeep(errors) as ITreatyErrors;
    newErrors.layers[`tab_${tabs.length}`] = {
      treatySubType: '',
      retentionShare: '',
      paymentDate: '',
      paymentAmount: '',
      totalTreatyPremium: '',
      totalExpectedPremium: '',
      premiumRate: '',
      categories: [
        {
          category: '',
          minimumLayerLimit: '',
          maximumLayerLimit: '',
          retentionLimit: '',
          priorityLimit: '',
          liabilityLimit: '',
          aggregateLimit: '',
          commissionRate: '',
        },
      ],
    };

    setActiveTab(newTabKey);

    onChange(newValues);
    updateErrors(newErrors);
  };

  const handleDeleteTab = (currentLayer: string) => {
    const newValues = cloneDeep(values) as ITreatyDetails;
    const newErrors = cloneDeep(errors) as ITreatyErrors;

    delete newValues.layers[currentLayer];
    delete newErrors.layers[currentLayer];

    const layerKeys = Object.keys(newValues.layers);
    newValues.layers = layerKeys.reduce((acc, key, index) => {
      acc[`tab_${index}`] = newValues.layers[key];
      return acc;
    }, {} as any);

    newErrors.layers = layerKeys.reduce((acc, key, index) => {
      acc[`tab_${index}`] = newErrors.layers[key];
      return acc;
    }, {} as any);

    const newTabs = Object.keys(newValues.layers);
    if (newTabs.length > 0) {
      setActiveTab(newTabs[0]);
    } else {
      setActiveTab('');
    }

    onChange(newValues);
    updateErrors(newErrors);
  };

  const handleFieldChange = (
    value: any,
    fieldName: keyof ITreatyLayer,
    tab: string
  ) => {
    const newValues = cloneDeep(values) as ITreatyDetails;
    newValues.layers[tab][fieldName] = value;
    onChange(newValues);
  };

  useEffect(() => {
    if (values?.treatyType?.toLowerCase() === 'non_proportional') {
      setActiveTab('tab_0');
    }
  }, [values?.treatyType]);

  return (
    <section className={classes.container}>
      <TabContext value={activeTab}>
        <Box
          className={classes.tabsContainer}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <TabList
            onChange={(event: React.SyntheticEvent, newValue: string) => {
              setActiveTab(newValue);
            }}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.indicator,
            }}
          >
            {showTabs &&
              tabs.map((tab, index) => (
                <Tab
                  key={index}
                  value={`tab_${index}`}
                  className={classes.tabRoot}
                  label={
                    <div
                      className={clsx(classes.tabLabel, {
                        [classes.tabActiveTitle]: activeTab === `tab_${index}`,
                      })}
                    >
                      {tabsTitle} {index + 1}
                      {!isDisabled && tabs.length > 1 && (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteTab(`tab_${index}`);
                          }}
                          className={classes.tabIconButton}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      )}
                    </div>
                  }
                />
              ))}
            {showTabs && (
              <IconButton
                className={classes.addBtn}
                onClick={handleAddTab}
                disabled={isDisabled}
                aria-label="Add"
              >
                <AddIcon color="primary" />
              </IconButton>
            )}
          </TabList>
        </Box>
        {Object.keys(values.layers).map((currentLayer: string, index) => {
          const tabValues = values.layers[currentLayer] as ITreatyLayer;
          const tabErrors = errors.layers[currentLayer];
          const currencySymbol = lovs?.currency?.[values.currency];
          return (
            <TabPanel
              key={index}
              value={currentLayer}
              className={classes.tabPanel}
            >
              <div className={classes.tabPanelContent}>
                <SelectFormField
                  name="treatySubType"
                  title="Type*"
                  value={tabValues?.treatySubType}
                  placeholder="Select Type"
                  selectOptions={
                    lovs.treatySubType[
                      values?.treatyType?.toLowerCase() as keyof ITreatyLovs['treatySubType']
                    ]
                  }
                  onChange={(v) => {
                    handleFieldChange(v, 'treatySubType', currentLayer);
                  }}
                  error={tabErrors?.treatySubType}
                  disabled={isDisabled}
                />
                {tabValues?.treatySubType?.toLowerCase() === 'quotashare' && (
                  <EnhancedPercentageInput
                    name="retentionShare"
                    title="Retention Share %*"
                    placeholder="Enter Quota Share"
                    value={tabValues?.retentionShare}
                    onChange={(e) => {
                      handleFieldChange(
                        e.target.value,
                        'retentionShare',
                        currentLayer
                      );
                    }}
                    onBlur={() => {}}
                    error={tabErrors?.retentionShare}
                    disabled={isDisabled}
                  />
                )}
                {values?.treatyType?.toLowerCase() === 'non_proportional' && (
                  <>
                    <DatePickerFormField
                      name="paymentDate"
                      title="Payment Date*"
                      value={tabValues?.paymentDate}
                      onDateChange={(v) => {
                        handleFieldChange(v, 'paymentDate', currentLayer);
                      }}
                      error={tabErrors?.paymentDate}
                      disabled={isDisabled}
                    />
                    <CurrencyFormField
                      name="paymentAmount"
                      title="Payment Amount*"
                      placeholder="Enter Payment Amount"
                      value={tabValues?.paymentAmount}
                      onChange={(e) => {
                        handleFieldChange(
                          e.target.value,
                          'paymentAmount',
                          currentLayer
                        );
                      }}
                      currencySymbol={currencySymbol}
                      error={tabErrors?.paymentAmount}
                      disabled={isDisabled}
                    />
                    <CurrencyFormField
                      name="totalTreatyPremium"
                      title="Total Treaty Premium*"
                      placeholder="Enter Total Treaty Premium"
                      value={tabValues?.totalTreatyPremium}
                      onChange={(e) => {
                        handleFieldChange(
                          e.target.value,
                          'totalTreatyPremium',
                          currentLayer
                        );
                      }}
                      currencySymbol={currencySymbol}
                      error={tabErrors?.totalTreatyPremium}
                      disabled={isDisabled}
                    />
                    <CurrencyFormField
                      name="totalExpectedPremium"
                      title="Total Expected Premium*"
                      placeholder="Enter Total Expected Premium"
                      value={tabValues?.totalExpectedPremium}
                      onChange={(e) => {
                        handleFieldChange(
                          e.target.value,
                          'totalExpectedPremium',
                          currentLayer
                        );
                      }}
                      currencySymbol={currencySymbol}
                      error={tabErrors?.totalExpectedPremium}
                      disabled={isDisabled}
                    />
                    <CurrencyFormField
                      name="premiumRate"
                      title="Premium Rate*"
                      placeholder="Enter Premium Rate"
                      value={tabValues?.premiumRate}
                      onChange={(e) => {
                        handleFieldChange(
                          e.target.value,
                          'premiumRate',
                          currentLayer
                        );
                      }}
                      currencySymbol={currencySymbol}
                      error={tabErrors?.premiumRate}
                      disabled={isDisabled}
                    />
                  </>
                )}
              </div>
              <CategoryRepeater
                treatyValues={values}
                currentLayerValues={tabValues}
                currentLayerErrors={tabErrors}
                isDisabled={isDisabled}
                lovs={lovs}
                onChange={(layerValues) => {
                  handleFieldChange(layerValues, 'categories', currentLayer);
                }}
                updateErrors={(layerErrors) => {
                  const newErrors = cloneDeep(errors);
                  newErrors.layers[currentLayer] = layerErrors;
                  updateErrors(newErrors);
                }}
              />
            </TabPanel>
          );
        })}
      </TabContext>
    </section>
  );
};

export default TabsRepeater;
