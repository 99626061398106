import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.Production.queries.getPolicyCoverList.items.forEach(
    (obj: IGenericPolicyCoversTableData, i: number) => {
      const lineName =
        obj.planConfigManagement_PolicyCover_LineID?.views
          ?.PlanConfigManagement_all?.properties?.Name;
      list[i] = {
        planConfigManagement_PolicyCover_Id:
          obj.planConfigManagement_PolicyCover_Id,
        planConfigManagement_PolicyCover_Name:
          obj.planConfigManagement_PolicyCover_Name,
        planConfigManagement_PolicyCover_LineID: lineName,
        planConfigManagement_PolicyCover_ExternalCode:
          obj.planConfigManagement_PolicyCover_ExternalCode,
        planConfigManagement_PolicyCover_NameArabic:
          obj.planConfigManagement_PolicyCover_NameArabic,
        planConfigManagement_PolicyCover_createdOn:
          obj.planConfigManagement_PolicyCover_createdOn,
        planConfigManagement_PolicyCover_modifiedOn:
          obj.planConfigManagement_PolicyCover_modifiedOn,
        planConfigManagement_PolicyCover_PrintingOrder:
          obj.planConfigManagement_PolicyCover_PrintingOrder,
        planConfigManagement_PolicyCover_PolicyCoverDescription:
          obj.planConfigManagement_PolicyCover_PolicyCoverDescription,
        treaty_TreatyName: obj.treaty_TreatyName,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Production.queries.getPolicyCoverList?.paging?.pageSize,
    pageNumber: data.Production.queries.getPolicyCoverList?.paging?.pageNumber,
    totalCount: data.Production.queries.getPolicyCoverList?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    line: {},
    status: {},
  };

  data?.PlanConfigManagement?.queries?.allLines?.forEach(
    (obj: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Name: string;
    }) => {
      lovs.line[obj.planConfigManagement_Line_Id] =
        obj.planConfigManagement_Line_Name;
    }
  );

  return lovs;
}
