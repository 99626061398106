import { IFacultativeDrawerLovs } from '.';
import { DynamicFormInputType, FormInputTypes } from '../../../../DynamicForm';

export const getInputs = (
  lovs: IFacultativeDrawerLovs
): Record<string, DynamicFormInputType> => {
  return {
    proposal: {
      name: 'proposal',
      title: 'Proposal',
      placeholder: 'Select Proposal',
      type: FormInputTypes.newselect,
      value: '',
      selectOptions: lovs.proposals,
      required: true,
    },
    company: {
      name: 'company',
      title: 'Company',
      type: FormInputTypes.newtext,
      value: '',
      disabled: true,
      hidden: true,
    },
    currencySymbol: {
      name: 'currencySymbol',
      title: 'Currency',
      placeholder: 'Currency',
      type: FormInputTypes.newtext,
      value: '',
      disabled: true,
      required: true,
    },
    cover: {
      name: 'cover',
      title: 'Cover',
      placeholder: 'Select Cover',
      type: FormInputTypes.newselect,
      selectOptions: lovs.covers?.name,
      value: '',
      required: true,
    },
    coverEffectiveDate: {
      name: 'coverEffectiveDate',
      title: 'Cover Effective Date',
      type: FormInputTypes.newdate,
      value: '',
      required: true,
      disabled: true,
    },
    coverExpiryDate: {
      name: 'coverExpiryDate',
      title: 'Cover Expiry Date',
      type: FormInputTypes.newdate,
      value: '',
      required: true,
      disabled: true,
    },
    reinsurer: {
      name: 'reinsurer',
      title: 'Reinsurer',
      placeholder: 'Type to search reinsurers',
      initialOptions: lovs.reinsurers,
      type: FormInputTypes.newautocomplete,
      value: '',
      required: true,
    },
  };
};
