import React from 'react';
import { useParams } from 'react-router-dom';
import StaticLayout from '../../../page-layout/static-layout/StaticLayout';
import LeftPanel from './sections/LeftPanel';
import MainColumn from './sections/MainColumn';
import { useQuery } from '@apollo/client';
import { getAmendmentMainDetailsQuery } from './queries';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import { getError } from '../../../utils/graph-utils';
import Loader from '../../../components/Loader';

const AmendmentDetailsPage: React.FC = () => {
  const { id: currentAmendmentId } = useParams<{ id: string }>();
  const { refetch, data, error, loading } = useQuery(
    getAmendmentMainDetailsQuery,
    {
      variables: {
        selectedAmendmentID: currentAmendmentId,
      },
      errorPolicy: 'all',
    }
  );

  if (error) {
    toast.error(
      <ToastErrorMessage>{getError({ errors: error })}</ToastErrorMessage>
    );
  }

  const amendmentData = data?.Production?.queries?.getAmendmentDetails;

  if (loading) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name={'Proposal Details'}
      leftChildren={<LeftPanel data={amendmentData?.[0]} />}
      mainChildren={<MainColumn data={amendmentData} onSuccess={refetch} />}
    />
  );
};

export default AmendmentDetailsPage;
