import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  editableLineCharges: '',
  allowPolicyEndDateEdits: false,
  allowPlanCoverEdits: false,
  allowClauseEdits: false,
  allowGroupPolicy: false,
  allowEndorsement: false,
  allowEndorsementCostEdits: false,
  allowPolicyCorrection: false,
};

export const inputs: Record<string, DynamicFormInputType> = {
  editableLineCharges: {
    name: 'editableLineCharges',
    title: 'Editable Line Charges',
    type: FormInputTypes.chips,
    placeholder: 'Editable Line Charges',
    value: !isEmpty(initialValues.editableLineCharges)
      ? [initialValues.editableLineCharges]
      : [],
    multiple: true,
    disabled: false,
    required: false,
  },
  allowPolicyEndDateEdits: {
    name: 'allowPolicyEndDateEdits',
    title: 'Allow Policy End Date Edits',
    placeholder: 'Allow Policy End Date Edits',
    type: FormInputTypes.switch,
    value: initialValues.allowPolicyEndDateEdits,
    required: true,
    disabled: false,
  },
  allowPlanCoverEdits: {
    name: 'allowPlanCoverEdits',
    title: 'Allow Plan Cover Details Edits',
    placeholder: 'Allow Plan Cover Details Edits',
    type: FormInputTypes.switch,
    value: initialValues.allowPlanCoverEdits,
    required: true,
    disabled: false,
  },
  allowClauseEdits: {
    name: 'allowClauseEdits',
    title: 'Allow Clause Edits',
    placeholder: 'Allow Clause Edits',
    type: FormInputTypes.switch,
    value: initialValues.allowClauseEdits,
    required: true,
    disabled: false,
  },
  allowGroupPolicy: {
    name: 'allowGroupPolicy',
    title: 'Allow Group Policy',
    placeholder: 'Allow Group Policy',
    type: FormInputTypes.switch,
    value: initialValues.allowGroupPolicy,
    required: true,
    disabled: false,
  },
  allowEndorsement: {
    name: 'allowEndorsement',
    title: 'Allow Endorsement',
    placeholder: 'Allow Endorsement',
    type: FormInputTypes.switch,
    value: initialValues.allowEndorsement,
    required: true,
    disabled: false,
  },
  allowEndorsementCostEdits: {
    name: 'allowEndorsementCostEdits',
    title: 'Allow Endorsement Cost Edits',
    placeholder: 'Allow Endorsement Cost Edits',
    type: FormInputTypes.switch,
    value: initialValues.allowEndorsementCostEdits,
    required: true,
    disabled: false,
  },
  allowPolicyCorrection: {
    name: 'allowPolicyCorrection',
    title: 'Allow Policy Correction',
    placeholder: 'Allow Policy Correction',
    type: FormInputTypes.switch,
    value: initialValues.allowPolicyCorrection,
    required: true,
    disabled: false,
  },
};
