import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { InformationDataType } from '.';
import { MAIN_ONE_THEME } from '../../constants';
import { useAppSelector } from '../../redux/hooks';
import { ITheme } from '../../redux/tenant/types';
import {
  addZeroesAndSeparatevalue,
  changeValueToPercentage,
  formatDate,
  formatDateTime,
  trimTrailingZeros,
  valueCommaSeparated,
} from '../../utils/formatting-utils';
import { isValidNumber } from '../../utils/validationUtils';

interface IInformationBoxCellProps {
  type?: InformationDataType;
  value: string;
  currency: string;
  cdnUrl: string;
  dateFormat: string;
}

const useStyles = makeStyles<{ theme: ITheme }>()((_, { theme }) => ({
  text: {
    fontSize: '13px',
    color: MAIN_ONE_THEME.palette.primary5.main,
    display: 'inline-block',
    textAlign: 'right',
    lineHeight: '15px',
    width: '100%',
  },
  figure: {
    width: '15px',
    height: '15px',
    backgroundSize: '100%',
    backgroundPosition: 'center',
    float: 'right',
    margin: 0,
    backgroundRepeat: 'no-repeat',
  },
  statusCircle: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
  },
}));

const InformationBoxBodyCell: React.FC<IInformationBoxCellProps> = ({
  type = InformationDataType.Text,
  value,
  cdnUrl,
  currency = '$',
}) => {
  const tenant = useAppSelector((state) => state.tenant);
  const { classes } = useStyles({ theme: tenant.theme });

  const renderCell = () => {
    switch (type) {
      case InformationDataType.Integer: {
        return (
          <div className={classes.text}>
            {isValidNumber(value) ? Number(value) : 'NA'}
          </div>
        );
      }
      case InformationDataType.Currency: {
        if (isValidNumber(value)) {
          if (value) {
            const numberValue = Number(value);
            if (numberValue >= 0) {
              return (
                <div className={classes.text}>
                  {`${currency} ${addZeroesAndSeparatevalue(numberValue)}`}
                </div>
              );
            }
            return (
              <div className={classes.text}>
                {`${currency} (${addZeroesAndSeparatevalue(
                  Math.abs(numberValue)
                )})`}
              </div>
            );
          }
          return <div className={classes.text}>{`${currency} 0`}</div>;
        }
        return <div className={classes.text}>NA</div>;
      }
      case InformationDataType.RoundedCurrency: {
        if (isValidNumber(value)) {
          if (value) {
            const numberValue = Number(value);
            if (numberValue >= 0) {
              return (
                <div className={classes.text}>
                  {`${currency} ${trimTrailingZeros(valueCommaSeparated(numberValue), 3, true)}`}
                </div>
              );
            }
            return (
              <div className={classes.text}>
                {`${currency} (${trimTrailingZeros(
                  valueCommaSeparated(Math.abs(numberValue)),
                  3,
                  true
                )})`}
              </div>
            );
          }
          return <div className={classes.text}>{`${currency} 0`}</div>;
        }
        return <div className={classes.text}>NA</div>;
      }
      case InformationDataType.Percentage: {
        return (
          <div className={classes.text}>
            {isValidNumber(value) ? `${valueCommaSeparated(value)}%` : 'NA'}
          </div>
        );
      }
      case InformationDataType.Rate: {
        return (
          <div className={classes.text}>
            {isValidNumber(value)
              ? `${valueCommaSeparated(changeValueToPercentage(value))}%`
              : 'NA'}
          </div>
        );
      }
      case InformationDataType.Date:
        return <div className={classes.text}>{formatDate(value)}</div>;
      case InformationDataType.DateAndTime:
        return <div className={classes.text}>{formatDateTime(value)}</div>;
      case InformationDataType.YesNo:
        return (
          <div className={classes.text}>
            <figure
              className={classes.figure}
              style={{
                backgroundImage:
                  value === 'Yes'
                    ? `url(${cdnUrl}/icons/tick-primary.svg)`
                    : `url(${cdnUrl}/icons/x-secondary.svg)`,
                backgroundSize: value === 'Yes' ? '100%' : '80%',
              }}
            />
          </div>
        );
      case InformationDataType.Status: {
        return (
          <div
            style={{ backgroundColor: value }}
            className={classes.statusCircle}
          />
        );
      }
      case InformationDataType.SummaryList: {
        return <span>{value}</span>;
      }
      default:
        return <div className={classes.text}>{value || 'NA'}</div>;
    }
  };
  return renderCell();
};

export default InformationBoxBodyCell;
