import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { IAbstractRecord } from '../../models';
import { IProductionDetailsSummary } from '../../modules/production/policy/page';

export const initialValues = {
  nameOnLicenseEn: '',
  nameOnLicenseAr: '',
  vignetteCode: '',
  vignette: '',
  plateCode: '',
  plateNumber: '',
  usageType: '',
  bodyType: '',
  engineType: '',
  make: '',
  model: '',
  color: '',
  yearOfMake: '',
  engine: '',
  weight: '',
  engineSize: '',
  seatingCapacity: '',
  chassis: '',
  horsePower: '',
  carValue: '',
  agencyRepairYear: '',
  depreciationYears: '',
};

export const getInputs = (
  lovs: Record<string, IAbstractRecord> = {},
  values: IProductionDetailsSummary
): Record<string, DynamicFormInputType> => {
  const externalCode = values.LineId.ExternalCode;
  return {
    nameOnLicenseEn: {
      name: 'nameOnLicenseEn',
      title: 'Name on License (EN)',
      type: FormInputTypes.text,
      placeholder: 'Name on License (EN)',
      value: values.NameOnLicenseEn,
      required: true,
      disabled: false,
    },
    nameOnLicenseAr: {
      name: 'nameOnLicenseAr',
      title: 'Name on License (AR)',
      type: FormInputTypes.text,
      placeholder: 'Name on License (AR)',
      value: values.NameOnLicenseAr,
      required: true,
      disabled: false,
    },
    vignetteCode: {
      name: 'vignetteCode',
      title: 'Vignette Code',
      type: FormInputTypes.text,
      placeholder: 'Vignette Code',
      value: values.PolicyMotor.VignetteCode,
      required: externalCode === '50',
      disabled: externalCode !== '50',
      hidden: externalCode !== '50',
    },
    vignette: {
      name: 'vignette',
      title: 'Vignette',
      type: FormInputTypes.number,
      placeholder: 'Vignette',
      value: values.PolicyMotor.Vignette,
      required: externalCode === '50',
      disabled: externalCode !== '50',
      hidden: externalCode !== '50',
    },
    plateCode: {
      name: 'plateCode',
      title: 'Plate Code',
      type: FormInputTypes.chips,
      placeholder: 'Plate Code',
      value: values?.Vehicle?.PlateCode,
      required: false,
      disabled: false,
      selectOptions: lovs.plateCodes,
    },
    plateNumber: {
      name: 'plateNumber',
      title: 'Plate Number',
      type: FormInputTypes.number,
      placeholder: 'Plate Number',
      value: values?.Vehicle?.PlateNumber,
      required: false,
      disabled: false,
    },
    usageType: {
      name: 'usageType',
      title: 'Usage',
      type: FormInputTypes.chips,
      placeholder: 'Usage',
      value: values?.Vehicle?.UsageType,
      required: true,
      disabled: true,
      selectOptions: lovs.filteredUsageTypes,
    },
    bodyType: {
      name: 'bodyType',
      title: 'Body',
      type: FormInputTypes.chips,
      placeholder: 'Body',
      value: values?.Vehicle?.BodyType,
      required: true,
      disabled: true,
      selectOptions: lovs.filteredBodyTypes,
    },
    engineType: {
      name: 'engineType',
      title: 'Engine Type',
      type: FormInputTypes.text,
      placeholder: 'Engine Type',
      value: values?.Vehicle?.EngineType,
      required: true,
      disabled: true,
    },
    make: {
      name: 'make',
      title: 'Make',
      type: FormInputTypes.text,
      placeholder: 'Make',
      value: values?.Vehicle?.Brand,
      required: true,
      disabled: false,
    },
    model: {
      name: 'model',
      title: 'Model',
      type: FormInputTypes.text,
      placeholder: 'Model',
      value: values?.Vehicle?.Model,
      required: true,
      disabled: false,
    },
    color: {
      name: 'color',
      title: 'Color',
      type: FormInputTypes.text,
      placeholder: 'Color',
      value: values?.Vehicle?.Color,
      required: false,
      disabled: false,
    },
    yearOfMake: {
      name: 'yearOfMake',
      title: 'Year of Make',
      type: FormInputTypes.number,
      placeholder: 'Year of Make',
      value: values?.Vehicle?.YearOfMake,
      required: externalCode !== '51',
      disabled: false,
    },
    engine: {
      name: 'engine',
      title: 'Engine',
      type: FormInputTypes.text,
      placeholder: 'Engine',
      value: values?.Vehicle?.Motor,
      required: true,
      disabled: false,
    },
    weight: {
      name: 'weight',
      title: 'Weight (in Tons)',
      type: FormInputTypes.number,
      placeholder: 'Weight (in Tons)',
      value: values?.Vehicle?.Weight,
      required: ['VAN', 'PICKUP', 'F_TANKER'].includes(
        values?.Vehicle?.BodyType
      ),
      disabled: true,
    },
    engineSize: {
      name: 'engineSize',
      title: 'Engine Size (in cc)',
      type: FormInputTypes.number,
      placeholder: 'Engine Size (in cc)',
      value: values?.Vehicle?.EngineSize,
      required: values?.Vehicle?.BodyType === 'MOTORCYCLE',
      disabled: true,
    },
    seatingCapacity: {
      name: 'seatingCapacity',
      title: 'Seating Capacity',
      type: FormInputTypes.number,
      placeholder: 'Seating Capacity',
      value: values?.Vehicle?.Seats,
      required: false,
      disabled: true,
    },
    chassis: {
      name: 'chassis',
      title: 'Chassis',
      type: FormInputTypes.text,
      placeholder: 'Chassis',
      value: values?.Vehicle?.Chassis,
      required: true,
      disabled: false,
    },
    horsePower: {
      name: 'horsePower',
      title: 'Horse Power',
      type: FormInputTypes.number,
      placeholder: 'Horse Power',
      value: values?.Vehicle?.HorsePower,
      required: false,
      disabled: true,
    },
    carValue: {
      name: 'carValue',
      title: 'Car Value',
      type: FormInputTypes.currency,
      placeholder: 'Car Value',
      value: values?.Vehicle?.CarValue,
      required: externalCode === '51',
      disabled: true,
      hidden: externalCode !== '51',
      maxDecimalPercision: 3,
    },
    agencyRepairYear: {
      name: 'agencyRepairYear',
      title: 'Agency Repair Years',
      type: FormInputTypes.number,
      placeholder: 'Agency Repair Years',
      value: values?.PolicyMotor?.AgencyRepairYear,
      required: externalCode === '51',
      disabled: true,
      hidden: externalCode !== '51',
    },
    depreciationYears: {
      name: 'depreciationYears',
      title: 'Depreciation Years',
      type: FormInputTypes.number,
      placeholder: 'Depreciation Years',
      value: values?.PolicyMotor?.DepreciationYears,
      required: externalCode === '51',
      disabled: true,
      hidden: externalCode !== '51',
    },
  };
};
