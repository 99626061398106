import { FormInputTypes, IDynamicSection } from '../../../../DynamicForm';
import { addDays, isDateBefore } from '../../../../utils/date-utils';
import { IReinsurer } from './NewTreatyDrawer';

export const getSections = (
  values: Record<string, any>,
  lovs: Record<string, any>,
  reinsurersList: Record<string, any>
): Record<string, IDynamicSection> => {
  return {
    section1: {
      title: 'Information',
      inputs: {
        company: {
          name: 'company',
          title: 'Company',
          type: FormInputTypes.text,
          value: values?.company,
          disabled: true,
          hidden: true,
        },
        treatyName: {
          name: 'treatyName',
          title: 'Treaty Name',
          placeholder: 'Enter Treaty Name',
          type: FormInputTypes.text,
          value: values?.treatyName,
          required: true,
        },
        treatyType: {
          name: 'treatyType',
          title: 'Treaty Type',
          placeholder: 'Select Treaty Type',
          type: FormInputTypes.chips,
          selectOptions: lovs?.treatyType || {},
          value: values?.treatyType,
          required: true,
        },
        currency: {
          name: 'currency',
          title: 'Currency',
          placeholder: 'Select Currency',
          type: FormInputTypes.chips,
          selectOptions: lovs?.currencySymbols || {},
          value: values?.currency,
          required: true,
        },
        effectiveDate: {
          name: 'effectiveDate',
          title: 'Effective Date',
          type: FormInputTypes.date,
          value: values?.effectiveDate,
          required: true,
          customValidator: (values) => {
            const isInvalid = isDateBefore(
              values?.expiryDate,
              values?.effectiveDate
            );
            return isInvalid ? 'cannot be greater than expiry date' : '';
          },
        },
        expiryDate: {
          name: 'expiryDate',
          title: 'Expiry Date',
          type: FormInputTypes.date,
          value: values?.expiryDate,
          required: true,
          minDate: addDays(values?.effectiveDate, 1),
          customValidator: (values) => {
            const isInvalid = isDateBefore(
              values?.expiryDate,
              values?.effectiveDate
            );
            return isInvalid ? 'cannot be less than effective date' : '';
          },
        },
        underwritingLimit: {
          name: 'underwritingLimit',
          title: 'Underwriting Limit',
          placeholder: 'Enter Underwriting Limit',
          type: FormInputTypes.currency,
          useCurrencySymbol: true,
          currencySymbol: lovs.currencySymbols?.[values?.currency],
          value: values?.underwritingLimit,
          required: true,
        },
        claimadviseLimit: {
          name: 'claimadviseLimit',
          title: 'Claim Advise Limit',
          placeholder: 'Enter Claim Advise Limit',
          type: FormInputTypes.currency,
          useCurrencySymbol: true,
          currencySymbol: lovs.currencySymbols?.[values?.currency],
          value: values?.claimadviseLimit,
          required: true,
        },
        substandardLimit: {
          name: 'substandardLimit',
          title: 'Substandard Limit*',
          placeholder: 'Enter Substandard Limit',
          type: FormInputTypes.percentage,
          value: values?.substandardLimit,
          required: false,
          hidden: values?.treatyType?.toLowerCase() !== 'proportional',
        },
      },
    },
    section2: {
      title: 'Reinsurers',
      inputs: {
        reinsurersRepeater: {
          name: 'reinsurersRepeater',
          title: '',
          type: FormInputTypes.repeaterbox,
          addButtonText: '+Add Reinsurer',
          value: values?.reinsurersRepeater,
          maxRepeaterLength: 999,
          minRepeaterLength: 1,
          inputs: {
            reinsurer: {
              name: 'reinsurer',
              title: 'Additional Reinsurer', // This is the default title
              dynamicTitle: ['Leader Reinsurer*'], // Dynamic title based on the index
              placeholder: 'Type to search for Reinsurer',
              type: FormInputTypes.chips,
              selectOptions: reinsurersList || {},
              value: '',
              error: '',
              required: true,
              multiple: false,
              disabled: false,
              hasPagination: false,
              customValidator: (values) => {
                const reinsurerIds = values.reinsurersRepeater.map(
                  (item: IReinsurer) => item.reinsurer
                );
                const isDuplicated = reinsurerIds.some(
                  (id: string, index: number) =>
                    reinsurerIds.indexOf(id) !== index
                );
                return isDuplicated ? 'Reinsurer already selected' : '';
              },
            },
            share: {
              name: 'share',
              title: 'Share',
              dynamicTitle: ['Share*'],
              placeholder: 'Enter Share',
              type: FormInputTypes.percentage,
              value: '',
              error: '',
              required: true,
              customValidator: (values) => {
                const totalShare = values.reinsurersRepeater.reduce(
                  (acc: number, item: any) => acc + parseFloat(item.share || 0),
                  0
                );

                return totalShare !== 100
                  ? 'Total Share should be equal to 100%'
                  : '';
              },
            },
          },
        },
      },
    },
  };
};
