import React, { useMemo, useState } from 'react';
import { IAbstractRecord } from '../../../../models';
import Stepper from './stepper/Stepper';
import { getLineCategory, LineCategory } from '../../../../utils/helper-utils';
import MotorAmendment from '../line-amendment/MotorAmendment';
import MedicalAmendment from '../line-amendment/MedicalAmendment';
import TravelAmendment from '../line-amendment/TravelAmendment';
import ExpatAmendment from '../line-amendment/ExpatAmendment';
import GenericAmendment from '../line-amendment/GenericAmendment';
import { ILineAmendmentProps } from '../line-amendment';
import { makeStyles } from 'tss-react/mui';
import { IAmendmentPageState, IPricingOptions } from '..';
import { getAmendmentInitialState } from '../content';
import { useParams } from 'react-router-dom';
import AmendmentOfferWidget from '../components/AmendmentOfferWidget';
import { useLazyQuery } from '@apollo/client';
import { getPricingOptionsQuery } from '../queries';

interface IMainColumn {
  data: IAbstractRecord[];
  onSuccess: () => void;
}

const useStyles = makeStyles()(() => ({
  mainContainer: {
    margin: '20px 0',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: '1em',
    alignItems: 'start',
  },
  widgetsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2em',
    '& .amendment-offer-widget': {
      padding: 0,
    },
  },
}));

const LineCategoryComponents: Record<
  LineCategory,
  React.FC<ILineAmendmentProps>
> = {
  Motor: MotorAmendment,
  Medical: MedicalAmendment,
  Travel: TravelAmendment,
  Expat: ExpatAmendment,
  Generic: GenericAmendment,
};

const MainColumn: React.FC<IMainColumn> = ({ data: mainData, onSuccess }) => {
  const [getPricingOptions] = useLazyQuery(getPricingOptionsQuery);
  const { id: currentAmendmentId } = useParams<{ id: string }>();
  const { classes } = useStyles();
  const amendmentData = mainData?.[0];
  const lineCategory = getLineCategory(amendmentData?.line_ExternalCode);
  const LineComponents: React.FC<ILineAmendmentProps> =
    LineCategoryComponents[lineCategory];

  const [pricingOption, setPricingOption] = useState<Record<
    'Current' | 'New' | 'Variance',
    IPricingOptions
  > | null>();
  const [pageState, setPageState] = useState<IAmendmentPageState>(
    getAmendmentInitialState(lineCategory)
  );

  const generalData: ILineAmendmentProps['generalData'] = useMemo(() => {
    if (!amendmentData)
      return {
        lineCategory: LineCategory.Generic,
        currentAmendmentId: '',
        lineExternalCode: '',
        lineId: '',
        clauseText: '',
        proposalId: '',
        policyId: '',
        policyType: '',
        planId: '',
        versionId: '',
        version: '',
        policyEffectiveDate: '',
        primaryBPID: '',
        planClauseEditable: false,
        amendmentStatus: '',
        businessUser: {
          allowClauseEdits: false,
          allowPlanCoverEdits: false,
          editableLines: [],
        },
        currency: {
          Code: '',
          Title: '',
          Symbol: '',
        },
      };

    const policyId = amendmentData?.policy_Id;

    if (
      policyId &&
      amendmentData.production_Amendment_AmendmentStatus !== 'NEW'
    ) {
      getPricingOptions({
        variables: {
          selectedPolicyID: policyId,
          selectedAmendmentID: currentAmendmentId,
          version: amendmentData.policyVersion_Version,
        },
      }).then((result) => {
        const { data } = result;
        const {
          current: currentQuery,
          new: newQuery,
          variance: varianceQuery,
        } = data.Production.queries;

        const current = currentQuery?.[0] || {};
        const variance = varianceQuery?.[0] || {};
        const newPricingOption = newQuery?.[0] || {};

        setPricingOption({
          Current: {
            BasicPremium: current.production_PricingOption_BasicPremium,
            NetPremium: current.production_PricingOption_NetPremium,
            PolicyCostAmount: current.production_PricingOption_PolicyCostAmount,
            ChargesAmount: current.production_PricingOption_ChargesAmount,
            TPAFeesTotalAmount:
              current.production_PricingOption_TPAFeesTotalAmount,
            GrossPremium: current.production_PricingOption_GrossPremium,
            FixedStampAmount: current.production_PricingOption_FixedStampAmount,
            ProportionalStampAmount:
              current.production_PricingOption_ProportionalStampAmount,
            MunicipalityTaxAmount:
              current.production_PricingOption_MunicipalityTaxAmount,
            TotalPremium: current.production_PricingOption_TotalPremium,
            currencySymbol:
              current.production_PricingOption_PricingOptionCurrency?.Symbol,
          },
          Variance: {
            BasicPremium:
              variance.amendmentPricingOptionVariance_AmendmentBasicPremium,
            NetPremium:
              variance.amendmentPricingOptionVariance_AmendmentNetPremium,
            PolicyCostAmount:
              variance.production_PricingOption_PolicyCostAmount,
            ChargesAmount:
              variance.amendmentPricingOptionVariance_AmendmentChargesAmount,
            TPAFeesTotalAmount:
              variance.production_PricingOption_TPAFeesTotalAmount,
            GrossPremium:
              variance.amendmentPricingOptionVariance_AmendmentGrossPremium,
            FixedStampAmount:
              variance.amendmentPricingOptionVariance_AmendmentFixedStampAmount,
            ProportionalStampAmount:
              variance.amendmentPricingOptionVariance_AmendmentProportionalStampAmount,
            MunicipalityTaxAmount:
              variance.amendmentPricingOptionVariance_AmendmentMunicipalityTax,
            TotalPremium:
              variance.amendmentPricingOptionVariance_AmendmentTotalPremium,
            currencySymbol:
              variance.amendmentPricingOptionVariance_PricingOptionCurrency
                ?.Symbol,
          },
          New: {
            BasicPremium:
              newPricingOption.production_PricingOption_BasicPremium,
            NetPremium: newPricingOption.production_PricingOption_NetPremium,
            PolicyCostAmount:
              newPricingOption.production_PricingOption_PolicyCostAmount,
            ChargesAmount:
              newPricingOption.production_PricingOption_ChargesAmount,
            TPAFeesTotalAmount:
              newPricingOption.production_PricingOption_TPAFeesTotalAmount,
            GrossPremium:
              newPricingOption.production_PricingOption_GrossPremium,
            FixedStampAmount:
              newPricingOption.production_PricingOption_FixedStampAmount,
            ProportionalStampAmount:
              newPricingOption.production_PricingOption_ProportionalStampAmount,
            MunicipalityTaxAmount:
              newPricingOption.production_PricingOption_MunicipalityTaxAmount,
            TotalPremium:
              newPricingOption.production_PricingOption_TotalPremium,
            currencySymbol:
              newPricingOption.production_PricingOption_PricingOptionCurrency
                ?.Symbol,
          },
        });
      });
    }

    const lines =
      amendmentData.policy_BusinessUserID?.views?.SalesforceManagement_all
        ?.properties?.EditableLineChargesIDs;

    return {
      currentAmendmentId: currentAmendmentId,
      lineId: amendmentData.line_Id,
      lineCategory: lineCategory,
      lineExternalCode: amendmentData.line_ExternalCode,
      clauseText: amendmentData.policy_ClauseText,
      proposalId: amendmentData.policy_ProposalID?.id,
      policyId: amendmentData.policy_Id,
      policyType: amendmentData.policy_Type,
      planId: amendmentData.plan_Id,
      planClauseEditable:
        amendmentData.policy_PlanID?.views?.PlanConfigManagement_all?.properties
          ?.ClauseEditable,
      versionId: amendmentData.policyVersion_Id,
      version: amendmentData.policyVersion_Version,
      policyEffectiveDate: amendmentData.policy_PolicyEffectiveDate,
      primaryBPID: amendmentData.policy_PrimaryBPID?.id,
      amendmentStatus: amendmentData.production_Amendment_AmendmentStatus,
      businessUser: {
        allowClauseEdits:
          amendmentData?.policy_BusinessUserID?.views?.SalesforceManagement_all
            ?.properties?.AllowClauseEdits,
        allowPlanCoverEdits:
          amendmentData.policy_BusinessUserID?.views?.SalesforceManagement_all
            ?.properties?.AllowPlanCoverEdits,
        editableLines: lines.map((line: IAbstractRecord) => {
          return line.views.PlanConfigManagement_all.properties.ExternalCode;
        }),
      },
      currency: amendmentData?.policy_PolicyCurrency,
    };
  }, [currentAmendmentId, lineCategory, amendmentData]);

  return (
    <>
      <Stepper
        generalData={generalData}
        values={pageState.values}
        onSuccess={onSuccess}
      />
      <section className={classes.mainContainer}>
        <div className={classes.widgetsContainer}>
          <LineComponents
            generalData={generalData}
            data={mainData}
            pageState={pageState}
            handleStateChange={setPageState}
          />
        </div>
        {generalData.amendmentStatus !== 'NEW' && pricingOption && (
          <div className={classes.widgetsContainer}>
            <AmendmentOfferWidget data={{ PricingOption: pricingOption }} />
          </div>
        )}
      </section>
    </>
  );
};

export default MainColumn;
