import { gql } from '@apollo/client';

export const getFacOfPoliciesQuery = gql`
  query getFacOfPoliciesOfReinsurer($ReinsurerID: String!) {
    Accounting {
      queries {
        GetFacOfPoliciesOfReinsurer(ReinsurerID: $ReinsurerID) {
          facultative_Id
          facultative_FacultativeID
          facultative_SlipReferenceNumber
          policy_PolicyNumber
          cover_Name
          facultative_PaymentDueDate
          production_PolicyFacultativeDistribution_CededNetPremium
          production_PolicyFacultativeDistribution_Commission
          facBill_AmountDue
          facBill_AmountPaid
          facBill_Currency {
            Code
            Symbol
          }
        }
      }
    }
  }
`;
