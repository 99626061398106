import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLazyQuery, useMutation } from '@apollo/client';
import StaticLayout from '../../../page-layout/static-layout/StaticLayout';
import {
  getAccountIdsByCompany,
  getTransferLinks,
  getTransferLinksFilterConfig,
  updateTransferLinksMutation,
} from './queries';
import { isEmpty } from '../../../utils/validationUtils';
import { toast } from 'react-toastify';
import ToastSuccessMessage from '../../../components/ToastSuccessMessage';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import { getError, lookupListAsRecordObject } from '../../../utils/graph-utils';
import ListingWidget from '../../../components/form-fields/listing-widget/ListingWidget';
import { filterSectionsContent, initialHeaders } from './content';
import { IListingData } from '../../../models/listing';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../components/enhanced-table';
import ListingFilterWidget from '../../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IListingFilterWidgetSection } from '../../../components/widgets/custom-listing-filter';
import {
  IEnhancedMenuItem,
  IFilterModel,
} from '../../../components/form-fields/table';
import { IAbstractRecord } from '../../../models';
import { cloneDeep, isEqual } from 'lodash';
import { getFilter, setFilter } from '../../../utils/filter-utils';

const PAGE_CONTEXT = 'TransferLinks';
const FILTER_SESSION_KEY = 'transferLinksFilter';

const useStyles = makeStyles()(() => ({
  inputContainer: {
    maxWidth: '330px',
    height: '28px !important',
    maxHeight: '28px !important',
    '& .MuiInputBase-root': {
      minHeight: '26px !important',
      maxHeight: '28px !important',
    },
  },
}));

const TransferLinksListing: React.FC = () => {
  const { classes } = useStyles();
  const [headers, setHeaders] =
    useState<Record<string, EnhancedTableHeader>>(initialHeaders);
  const [accountsAuxiliary, setAccountsAuxiliary] = useState<
    Record<
      string,
      {
        ID: string;
        Account: string;
      }
    >
  >({});
  const lovs = useRef<Record<string, Record<string, string>>>({
    transferLinks: {},
    allTransferLinks: {},
    transferTypes: {},
    relatedCompanies: {},
  });
  const initialfilterValues: IAbstractRecord =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          company: '',
          transferType: '',
          transferLinks: [],
          allTransferLinks: [],
        };
  const [filterValues, setFilterValues] = useState<IFilterModel>({
    namedFilters: initialfilterValues,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  const [filterSections, setFilterSections] = useState<
    IListingFilterWidgetSection[]
  >([]);
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [getTransferLinksFilterQuery] = useLazyQuery(
    getTransferLinksFilterConfig()
  );
  const [getTransferLinksQuery, { loading: loadingTransferLinks }] =
    useLazyQuery(getTransferLinks());
  const [getCompanyAccounts, { loading: loadingCompanyAccounts }] =
    useLazyQuery(getAccountIdsByCompany());
  const [updateTransferLinks, { loading: loadingUpdateTransferLinks }] =
    useMutation(updateTransferLinksMutation());

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = () => {
    getTransferLinksFilterQuery().then(({ data }) => {
      const transferLinks = lookupListAsRecordObject(
        data.Accounting.lookups.transferLink
      );
      const transferTypes = lookupListAsRecordObject(
        data.Accounting.lookups.transferType
      );
      const transferTypeInitialFilterValue = Object.keys(transferTypes)[0];
      const transferTypeFilterValue =
        filterValues.namedFilters.type ||
        transferTypeInitialFilterValue?.toString();
      const newLovs = {
        allTransferLinks: transferLinks,
        transferLinks: Object.keys(transferLinks)
          .filter((key) => key.startsWith(transferTypeFilterValue))
          .reduce(
            (acc, key) => {
              acc[key] = transferLinks[key];
              return acc;
            },
            {} as Record<string, string>
          ),
        transferTypes: lookupListAsRecordObject(
          data.Accounting.lookups.transferType
        ),
        relatedCompanies: lookupListAsRecordObject(
          data.SalesforceManagement.lookups.relatedCompanies
        ),
      };
      lovs.current = newLovs;

      const newFilterValues = {
        company:
          filterValues.namedFilters.company ||
          Object.keys(newLovs.relatedCompanies)[0]?.toString() ||
          '',
        type: transferTypeFilterValue || '',
        link: filterValues.namedFilters.link || [],
      };

      const filters = filterSectionsContent(newLovs, newFilterValues);
      setFilterSections(filters);
      const newFilters = {
        ...filterValues,
        namedFilters: newFilterValues,
      };
      setFilterValues(newFilters);
      getHeaders(newFilterValues);
      getListingData(newFilters);
    });
  };

  const getHeaders = (filterValues: IAbstractRecord) => {
    const newHeaders = cloneDeep(initialHeaders);
    getCompanyAccounts({
      variables: {
        company: filterValues.namedFilters?.company,
      },
    }).then(({ data }) => {
      const companyAccounts: Record<string, string> = {};
      data.Accounting.queries.GetAccountsOfCompany.forEach(
        (item: IAbstractRecord) => {
          if (
            !Object.keys(companyAccounts).filter(
              (x) => x === item.accounting_ChartOfAccounts_AccountID
            ).length
          ) {
            companyAccounts[item.accounting_ChartOfAccounts_AccountID] =
              item.accounting_ChartOfAccounts_AccountID +
              ' ' +
              item.accounting_ChartOfAccounts_AccountName;
          }
        }
      );

      if (newHeaders.account.type === EnhancedTableHeaderType.Input) {
        newHeaders.account.options = companyAccounts;
      }

      setHeaders(newHeaders);
    });
  };

  const getListingData = (filter = filterValues) => {
    if (!filter || !filter.namedFilters) {
      return {};
    }

    const filterValue = filter.namedFilters;
    return getTransferLinksQuery({
      variables: {
        pageNumber: 1,
        pageSize: 100000000,
        company: filterValue.company,
        transferType: filterValue.type,
        links: filterValue.link,
      },
    }).then(({ data }) => {
      const tempData: IListingData = {
        ...tableData,
        pagedItems: {},
      };
      // const pagination = data.Accounting.queries.GetTransferLinks.paging;

      data.Accounting.queries.GetTransferLinks.items.forEach(
        (item: IAbstractRecord) => {
          tempData.pagedItems[item.accounting_AccountingTransferLinks_Id] = {
            id: item.accounting_AccountingTransferLinks_Id,
            account:
              item.accounting_AccountingTransferLinks_ChartofAccount || '',
            line: item.line_Name,
            link: item.accounting_AccountingTransferLinks_TransferLink.Title,
          };
        }
      );

      tempData.totalCount = Object.keys(tempData.pagedItems).length;

      setTableData(tempData);
      setAccountsAuxiliary(
        Object.values(tempData.pagedItems).reduce((acc, row) => {
          acc[row.id] = row.account;
          return acc;
        }, {})
      );
    });
  };

  const onFilterUpdate = async (
    filters: IAbstractRecord,
    inputName: string
  ) => {
    if (isEqual(filters, filterValues.namedFilters)) {
      // Do nothing if filters are the same
      return;
    }

    const newNamedFilters = {
      ...filters,
    };

    if (inputName === 'type') {
      newNamedFilters.link = [];
      const transferTypeInitialFilterValue = Object.keys(
        lovs.current.transferTypes
      )[0];

      const transferTypeFilterValue =
        newNamedFilters.type || transferTypeInitialFilterValue?.toString();

      lovs.current.transferLinks = Object.keys(lovs.current.allTransferLinks)
        .filter((key) => key.startsWith(transferTypeFilterValue))
        .reduce(
          (acc, key) => {
            acc[key] = lovs.current.allTransferLinks[key];
            return acc;
          },
          {} as Record<string, string>
        );

      const filterSections = filterSectionsContent(
        lovs.current,
        newNamedFilters
      );
      setFilterSections(filterSections);
    }

    const newFilters = {
      ...filterValues,
      namedFilters: newNamedFilters,
    };
    setFilterValues(newFilters);
    setFilter(newNamedFilters, FILTER_SESSION_KEY);
    getListingData(newFilters);
    const newFilterSections = filterSectionsContent(
      lovs.current,
      newNamedFilters
    );
    setFilterSections(newFilterSections);
  };

  async function updateData() {
    const result = await updateTransferLinks({
      variables: {
        transferLinks: Object.keys(accountsAuxiliary).map((id) => {
          return { ID: id, Account: accountsAuxiliary[id] };
        }),
      },
    });

    if (isEmpty(result.errors)) {
      toast.success(
        <ToastSuccessMessage>
          {'Successfully updated transfer links.'}
        </ToastSuccessMessage>
      );
    } else {
      toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
    }
  }

  // function filterTransferLinks(transferType: string) {
  //   const filteredLinks: Record<string, string> = {};
  //   Object.entries(filterConfig.transferLinks)
  //     .filter(([key, value]) => key.startsWith(transferType))
  //     .forEach((link) => {
  //       filteredLinks[link[0]] = link[1];
  //     });
  //   return filteredLinks;
  // }

  const handleCellValueChanged = (
    rowKey: number,
    property: string,
    value: unknown,
    id?: string
  ) => {
    const item = tableData['pagedItems'][id];
    const newAuxiliary = { ...accountsAuxiliary, [item.id]: value };
    setAccountsAuxiliary(newAuxiliary);
  };

  function handlePageChange(
    page: number,
    filterModel = filterValues
  ): Promise<void> {
    return new Promise<void>(() =>
      getListingData({
        ...filterModel,
        pagination: {
          ...filterModel.pagination,
          pageNumber: page,
        },
      })
    );
  }

  const actions: IEnhancedMenuItem[] = [
    {
      name: 'save',
      title: 'Save',
      onClick: () => {
        updateData();
      },
    },
  ];

  const renderMainChildren = () => {
    return (
      <ListingWidget
        name="transferLinks"
        title="Transfer Links"
        // inline
        // onPageChange={(filterModel) => {
        //   const newFilterModel = {
        //     ...filterValues,
        //     ...filterModel,
        //   };
        //   const page = filterModel.pagination.pageNumber;
        //   return handlePageChange(page, newFilterModel);
        // }}
        handleCellValueChanged={handleCellValueChanged}
        loading={loadingTransferLinks || loadingCompanyAccounts}
        data={tableData}
        tableSettings={{
          headers,
        }}
        actions={actions}
        pageContext={PAGE_CONTEXT}
        disableSelection
        tableClasses={{
          inputContainer: classes.inputContainer,
        }}
        // usePagination={false}
      />
    );
  };

  const renderLeftSide = () => {
    return (
      <ListingFilterWidget
        name={''}
        filters={filterSections}
        onApplyFilter={(v, inputName) => {
          onFilterUpdate(v, inputName);
        }}
      />
    );
  };

  return (
    <StaticLayout
      loading={
        loadingTransferLinks ||
        loadingCompanyAccounts ||
        loadingUpdateTransferLinks
      }
      name={'Transfer Links'}
      leftChildren={renderLeftSide()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default TransferLinksListing;
