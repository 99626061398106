import { EnhancedDisplayType } from '../../../components/enhanced-form/EnhancedDisplay';
import { IEntityInfoView } from '../../../components/widgets/entity-info/EntityInfoFields';
import { IAbstractRecord } from '../../../models';
import {
  GenericLines,
  getGenericLine,
  getLineCategory,
  LineCategory,
} from '../../../utils/helper-utils';
import {
  capitalizeFirstLetterLowerOthers,
  trimTrailingZeros,
} from '../../../utils/formatting-utils';
import { isEmpty } from '../../../utils/validationUtils';
import {
  IAmendmentBankerDetails,
  IAmendmentCostCharges,
  IAmendmentCover,
  IAmendmentDetails,
  IAmendmentMotorDetails,
  IAmendmentPageData,
  IAmendmentRiskDetails,
} from '.';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../../utils/graph-utils';
import { cloneDeep } from 'lodash';
import { getAmendmentInitialState } from './content';
import { IMedicalLovs } from './components/MedicalDetails';
import { ITravelLovs } from './components/TravelDetails';

export function mapToAmendmentDetails(
  amendmentData: IAbstractRecord
): IAmendmentPageData {
  const lineCategory = getLineCategory(amendmentData?.line_ExternalCode);

  const result: IAmendmentPageData = cloneDeep(
    getAmendmentInitialState(lineCategory).values
  );

  if (!amendmentData) {
    return result;
  }

  const amendmentDetails: IAmendmentDetails = {
    plan: amendmentData.plan_Id,
    amendmentEffectiveDate:
      amendmentData.production_Amendment_AmendmentEffectiveDate,
    amendmentExpiryDate: amendmentData.production_Amendment_AmendedExpiryDate,
    policyCurrency: amendmentData.policy_PolicyCurrency?.Code,
  };
  result.amendmentDetails = amendmentDetails;

  const costs: IAmendmentCostCharges = {
    amendmentCost:
      amendmentData.amendmentPricingOptionVariance_AmendmentCostAmount,
    chargesPercentage:
      amendmentData.amendmentPricingOptionVariance_AmendmentChargesPercentage,
    tpaFeesAmount:
      amendmentData.amendmentPricingOptionVariance_AmendmentTPAFeesAmount,
    tpaFeesPercentage:
      amendmentData.amendmentPricingOptionVariance_AmendmentTPAFeesPercentage,
    tpaFeesType:
      amendmentData.amendmentPricingOptionVariance_AmendmentTPAFeesType,
  };
  result.costs = costs;

  if (lineCategory === LineCategory.Motor) {
    const motorData =
      amendmentData.policyVersion_MotorDetailsID?.views?.Production_all
        ?.properties;
    const vehicleData = motorData?.VehicleID?.views?.Production_all?.properties;

    const motorDetails: IAmendmentMotorDetails = {
      nameOnLicenseEn: motorData?.NameOnLicenseEn,
      nameOnLicenseAr: motorData?.NameOnLicenseAr,
      vignetteCode: motorData?.VignetteCode,
      vignette: motorData?.Vignette,
      plateCode: vehicleData?.PlateCode?.Title,
      plateNumber: vehicleData?.PlateNumber,
      usageType: vehicleData?.UsageType?.Id,
      bodyType: vehicleData?.BodyType?.Id,
      engineType: vehicleData?.EngineType?.Id,
      brand: vehicleData?.Make?.Id,
      model: vehicleData?.Model?.Id,
      color: vehicleData?.Color?.Id,
      yearOfMake: vehicleData?.YearOfMake,
      motor: vehicleData?.Engine,
      weight: vehicleData?.Weight,
      engineSize: vehicleData?.EngineSize,
      seats: vehicleData?.SeatingCapacity,
      chassis: vehicleData?.Chassis,
      horsePower: vehicleData?.HorsePower,
      carValue: vehicleData?.CarValue,
      agencyRepairYear: motorData?.AgencyRepairYear,
      depreciationYears: motorData?.DepreciationYears,
    };
    result.motorDetails = motorDetails;
  } else if (lineCategory === LineCategory.Expat) {
    result.expatDetails = {
      continuity: amendmentData.policyExpat_Continuity,
      continuityNumber: amendmentData.policyExpat_ContinuityNumber,
      exclusion: amendmentData.policyExpat_Exclusion,
      medicalClass: amendmentData.policyExpat_MedicalClass.Code,
      remarks: amendmentData.policyExpat_Remarks,
    };
  } else if (lineCategory === LineCategory.Medical) {
    result.medicalDetails = {
      class: amendmentData.production_PolicyMedical_Class?.Code,
      cnss: amendmentData.production_PolicyMedical_Nssf,
      level: amendmentData.production_PolicyMedical_Level?.Code,
      policyCover: amendmentData.production_PolicyMedical_CoversIDText,
    };
  } else if (lineCategory === LineCategory.Travel) {
    result.travelDetails = {
      destination: amendmentData.production_PolicyTravel_Destination?.Code,
      duration: amendmentData.production_PolicyTravel_Duration,
      policyCover: amendmentData.production_PolicyTravel_PolicyCoverIDText,
    };
  } else if (lineCategory === LineCategory.Generic) {
    const genericLine = getGenericLine(amendmentData.line_ExternalCode);

    switch (genericLine) {
      case GenericLines.BBB:
        result.bankersDetails = convertToPolicyBankersBlanketBondDetails(
          amendmentData.bankersDetails
        );
        break;
      case GenericLines.MARINE:
        result.marineDetails = {
          matter: amendmentData.policyMarine_Matter.Code,
          description: amendmentData.policyMarine_Description,
          vesselName: amendmentData.policyMarine_VesselName,
          vesselAge: amendmentData.policyMarine_VesselAge,
          from: amendmentData.policyMarine_DepartureFrom,
          to: amendmentData.policyMarine_DestinationTo,
          via: amendmentData.policyMarine_TransportationVia,
          survey: amendmentData.policyMarine_Survey,
          cargoContract: amendmentData.policyMarine_CargoContract,
          lcNumber: amendmentData.policyMarine_LCNumber,
          lcIssueDate: amendmentData.policyMarine_LCIssueDate,
          bank: amendmentData.policyMarine_Bank,
          claimsPayableBy: amendmentData.policyMarine_ClaimsPayableBy,
          loading: amendmentData.policyMarine_Loading * 100,
          remarks: amendmentData.policyMarine_Remarks,
          sumPerShipment: amendmentData.policyMarine_SumPerShipment,
        };
        break;
      case GenericLines.MARINE_HULL:
        result.marineHullDetails = {
          vesselName: amendmentData.policyMaineHull_VesselName,
          connectionPort: amendmentData.policyMaineHull_ConnectionPort,
          territorialWaters: amendmentData.policyMaineHull_TerritorialWaters,
          usage: amendmentData.policyMaineHull_Usage,
          builder: amendmentData.policyMaineHull_Builder,
          constructionYear: amendmentData.policyMaineHull_ConstructionYear,
          registeryPort: amendmentData.policyMaineHull_RegisteryPort,
          length: amendmentData.policyMaineHull_Length,
          weight: amendmentData.policyMaineHull_Weight,
          height: amendmentData.policyMaineHull_Height,
          engineType: amendmentData.policyMaineHull_EngineType,
          engineNumber: amendmentData.policyMaineHull_EngineNumber,
          engineYearBuilt: amendmentData.policyMaineHull_EngineYearBuilt,
          engineHorsePower: amendmentData.policyMaineHull_EngineHorsePower,
          engineMaxDesignedSpeed:
            amendmentData.policyMaineHull_EngineMaxDesignedSpeed,
          engineFuel: amendmentData.policyMaineHull_EngineFuel,
          remarks: amendmentData.policyMaineHull_Remarks,
        };
        break;
    }
  }

  return result;
}

export function getLineEntityView(
  data: IAbstractRecord,
  names: {
    createdBy: string;
    modifiedBy: string;
  }
): IEntityInfoView {
  if (!data) {
    return {
      title: '',
      iconUrl: '',
      socialMedia: {
        facebook: '',
        twitter: '',
        linkedIn: '',
      },
      sections: [],
    };
  }

  const lineCategory = getLineCategory(data.line_ExternalCode);

  const subline =
    data.policy_SublineID?.views?.PlanConfigManagement_all?.properties;
  const businessUser =
    data.policy_BusinessUserID?.views?.SalesforceManagement_all?.properties;
  const businessUserPerson =
    businessUser?.PersonID?.views?.SalesforceManagement_all?.properties;
  const mainBP =
    data.policy_PrimaryBPID?.views?.SalesforceManagement_all?.properties;
  const mainBPPerson =
    mainBP?.PersonID?.views?.SalesforceManagement_all?.properties;
  const relatedCompany = mainBP.RelatedCompany;
  const plan = data.policy_PlanID?.views?.PlanConfigManagement_all?.properties;

  const isGroupPolicy = data?.policy_Type === 'GROUP';

  const sections: IEntityInfoView['sections'] = [
    {
      name: '1',
      title: '',
      properties: {
        StatusReason: {
          multiline: false,
          value: data.policy_StatusReason?.Title,
          name: 'Status Reason',
          title: 'Status Reason',
        },
        PolicyNumber: {
          multiline: false,
          value: data?.policy_PolicyNumber,
          name: 'PolicyNumber',
          title: 'Policy Number',
          type: EnhancedDisplayType.RelativeLink,
          url: isGroupPolicy
            ? `/production/group-policies/${data.policy_Id}`
            : `/production/policies/${data.policy_Id}`,
        },
        Line: {
          multiline: false,
          value: data.line_ExternalCode + ' - ' + data.line_Name,
          name: 'Line',
          title: 'Line',
        },
        Subline: {
          multiline: false,
          value: subline.ExternalCode + ' - ' + subline.Name,
          name: 'Subline',
          title: 'Subline',
        },
        RelatedCompany: {
          multiline: false,
          value: relatedCompany.Title,
          name: 'RelatedCompany',
          title: 'Related Company',
        },
        GroupPolicy: {
          multiline: false,
          value: 'Yes',
          name: 'GroupPolicy',
          title: 'Group Policy',
          hidden: !isGroupPolicy,
        },
      },
    },
  ];

  if (
    lineCategory === LineCategory.Motor ||
    lineCategory === LineCategory.Medical
  ) {
    sections.push({
      name: '2',
      title: '',
      properties: {
        ...(!isEmpty(plan?.AcalCategory?.Code) && {
          AcalCategory: {
            multiline: false,
            value: plan?.AcalCategory?.Title,
            name: 'AcalCategory',
            title: 'Acal Category',
          },
        }),
        ...(!isEmpty(plan?.Scratching) && {
          Scratching: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(plan?.Scratching),
            name: 'Scratching',
            title: 'Scratching',
          },
        }),
        ...(!isEmpty(plan?.SOSService) && {
          SOSService: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(
              plan?.SOSService || '',
              '_'
            ),
            name: 'SOSService',
            title: 'SOS Service',
          },
        }),
        ...(!isEmpty(plan?.ReplacementCarApplied) && {
          ReplacementCarApplied: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(
              plan?.ReplacementCarApplied || ''
            ),
            name: 'ReplacementCarApplied',
            title: 'Replacement Car Applied',
          },
        }),
        ...(!isEmpty(plan?.ReplacementCarValue) && {
          ReplacementCarValue: {
            multiline: false,
            value: `${
              data.policy_PolicyCurrency?.Symbol
            } ${plan?.ReplacementCarValue?.toString()}`,
            name: 'ReplacementCarValue',
            title: 'Replacement Car Value',
          },
        }),
        ...(!isEmpty(plan?.ReplacementCarDays) && {
          ReplacementCarDays: {
            multiline: false,
            value: `${plan?.ReplacementCarDays?.toString()} days`,
            name: 'ReplacementCarDays',
            title: 'Replacement Car Days',
          },
        }),
        ...(lineCategory === LineCategory.Medical && {
          GRLimitDays: {
            multiline: false,
            value: plan?.GRLimitDays?.toString() || '',
            name: 'GRLimitDays',
            title: 'GR Limit Days',
          },
        }),
      },
    });
  }

  sections.push(
    {
      name: '3',
      title: '',
      properties: {
        BusinessUser: {
          multiline: false,
          value: businessUser.Code + ' - ' + businessUserPerson.FullName,
          name: 'BusinessUser',
          title: 'Business User',
        },
        MainBP: {
          multiline: false,
          value: mainBP.Code + ' - ' + mainBPPerson.FullName,
          name: 'MainBP',
          title: 'Main Business Partner',
        },
      },
    },
    {
      name: '4',
      title: '',
      properties: {
        CreatedBy: {
          multiline: false,
          value: names?.createdBy,
          name: 'CreatedBy',
          title: 'Created By',
        },
        CreatedOn: {
          multiline: false,
          value: data.production_Amendment_createdOn,
          name: 'CreatedOn',
          title: 'Created On',
          type: EnhancedDisplayType.DateTime,
        },
        ModifiedBy: {
          multiline: false,
          value: names?.modifiedBy,
          name: 'ModifiedBy',
          title: 'Modified By',
        },
        ModifiedOn: {
          multiline: false,
          value: data.production_Amendment_modifiedOn,
          name: 'ModifiedOn',
          title: 'Modified On',
          type: EnhancedDisplayType.DateTime,
        },
      },
    }
  );

  return {
    title: data.production_Amendment_AmendmentNumber,
    iconUrl: '',
    socialMedia: {
      facebook: '',
      twitter: '',
      linkedIn: '',
    },
    sections: sections,
  };
}

export function convertToPolicyPlanProposalCovers(
  data: IAbstractRecord,
  amendmentData: IAbstractRecord[]
): IAmendmentCover[] {
  const covers = data.Production.queries.getPolicyPlanCovers;
  const proposal = data?.Production?.entities?.proposal;

  const amendmentCovers: IAbstractRecord = {};
  amendmentData.forEach((amendment: IAbstractRecord) => {
    amendmentCovers[
      amendment.amendmentPolicyPlanCoverVariance_PlanCoverID?.id
    ] = amendment;
  });

  const result: IAmendmentCover[] = covers.map((coverData: IAbstractRecord) => {
    const cover: IAmendmentCover = {
      isMain: coverData.production_PolicyPlanCover_IsMain,
      isEditable: coverData.production_PolicyPlanCover_IsEditable,
      isMandatory: coverData.production_PolicyPlanCover_IsMandatory,
      policyCover: coverData.planCover_Id,
      coverPremiumType: coverData.production_PolicyPlanCover_CoverPremiumType,
      coverPremiumPercentage: trimTrailingZeros(
        coverData.production_PolicyPlanCover_CoverPremiumPercentage * 100
      ),
      coverPremiumValue: trimTrailingZeros(
        coverData.production_PolicyPlanCover_CoverPremiumValue
      ),
      currency:
        coverData.production_PolicyPlanCover_ProposalID?.views?.Production_all
          ?.properties?.PolicyCurrency?.Code,
      coverSumInsured: trimTrailingZeros(
        coverData.production_PolicyPlanCover_CoverSumInsured
      ),
      sumInsuredIsAdditive:
        coverData.production_PolicyPlanCover_SumInsuredIsAdditive,
      excessOnClaimType: coverData.production_PolicyPlanCover_ExcessOnClaimType,
      excessOnClaimAmount: trimTrailingZeros(
        coverData.production_PolicyPlanCover_ExcessOnClaimAmount
      ),
      excessOnClaimPercentage: trimTrailingZeros(
        coverData.production_PolicyPlanCover_ExcessOnClaimPercentage * 100
      ),
      excessOnClaimDays: coverData.production_PolicyPlanCover_ExcessOnClaimDays,
      name: coverData.policyCover_Name,
      clauseID: coverData.production_PolicyPlanCover_ClauseID?.id,
      coverEffectiveFrom:
        coverData?.production_PolicyPlanCover_CoverEffectiveFrom ||
        proposal?.views?.Production_all?.properties?.PolicyEffectiveDate,
      coverEffectiveTo:
        coverData?.production_PolicyPlanCover_CoverEffectiveTo ||
        proposal?.views?.Production_all?.properties?.PolicyExpiryDate,
      amendmentCoverPremiumPercentage:
        amendmentCovers[coverData.planCover_Id]
          ?.amendmentPolicyPlanCoverVariance_AmendmentCoverPremiumPercentage,
      amendmentCoverPremiumValue:
        amendmentCovers[coverData.planCover_Id]
          ?.amendmentPolicyPlanCoverVariance_AmendmentCoverPremiumValue,
      amendmentCoverSumInsured:
        amendmentCovers[coverData.planCover_Id]
          ?.amendmentPolicyPlanCoverVariance_AmendmentCoverSumInsured,
      amendmentExcessOnClaimAmount:
        amendmentCovers[coverData.planCover_Id]
          ?.amendmentPolicyPlanCoverVariance_AmendmentExcessOnClaimAmount,
      amendmentExcessOnClaimDays:
        amendmentCovers[coverData.planCover_Id]
          ?.amendmentPolicyPlanCoverVariance_AmendmentExcessOnClaimDays,
      amendmentExcessOnClaimPercentage:
        amendmentCovers[coverData.planCover_Id]
          ?.amendmentPolicyPlanCoverVariance_AmendmentExcessOnClaimPercentage,
    };

    return cover;
  });

  return result;
}

export function getMotorLovs(data: IAbstractRecord) {
  const result: Record<string, Record<string, string>> = {};
  result['usageTypes'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.usageTypes
  );
  result['bodyTypes'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.bodyTypes
  );
  result['engineTypes'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.engineTypes
  );
  result['plateCodes'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.plateCodes
  );
  result['brands'] = lookupListAsRecordObject(data?.Insurance?.lookups?.brands);
  result['colors'] = lookupListAsRecordObject(data?.Core?.lookups?.colors);

  return result;
}

export function getExpatLovs(data: IAbstractRecord) {
  const result: Record<string, Record<string, string>> = {};

  result['medicalClasses'] = lookupListAsRecordObject(
    data?.Core?.lookups?.medicalClasses
  );
  result['continuityOptions'] = enumListAsRecordObject(
    data.Production_ContinuityOptions.enumValues
  );
  result['emergencyOptions'] = enumListAsRecordObject(
    data.PlanConfigManagement_EmergencyOptions.enumValues
  );

  return result;
}

export function getMedicalPrincipalLovs(
  data: IAbstractRecord,
  lineExternalCode: string
) {
  const result: Record<string, Record<string, string>> = {};

  result['insuredRelations'] = enumListAsRecordObject(
    data.Insurance_InsuredRelations.enumValues
  );

  result['continuity'] = enumListAsRecordObject(
    data.Production_ContinuityOptions.enumValues
  );

  if (lineExternalCode === '19') {
    delete result['insuredRelations']['PRINCIPAL'];
  }
  return result;
}

export function getMedicalLovs(data: IAbstractRecord) {
  const result: Record<keyof IMedicalLovs, Record<string, string>> = {
    classes: {},
    levels: {},
    cnssOptions: {},
    policyCovers: {},
  };

  result.classes = lookupListAsRecordObject(
    data?.Core?.lookups?.medicalClasses
  );

  result.levels = lookupListAsRecordObject(data?.Core?.lookups?.medicalLevels);

  result.cnssOptions = enumListAsRecordObject(
    data.Core_CoNSSFOptions.enumValues
  );

  data.PlanConfigManagement.queries.getPlanSpecificMedicalCovers.forEach(
    (planCover: IAbstractRecord) => {
      const id =
        planCover.planConfigManagement_PlanSpecificMedical_PolicyCoverID?.id;
      const policyCover =
        planCover.planConfigManagement_PlanSpecificMedical_PolicyCoverID.views
          .PlanConfigManagement_all.properties;
      result.policyCovers[id] =
        `${policyCover.ExternalCode} - ${policyCover.Name}`;
    }
  );

  return result;
}

export function getTravelLovs(data: IAbstractRecord) {
  const result: Record<keyof ITravelLovs, Record<string, string>> = {
    destinations: {},
    policyCovers: {},
  };

  result.destinations = lookupListAsRecordObject(
    data?.Core?.lookups?.travelDestinations
  );

  data.PlanConfigManagement.queries.getPlanSpecificTravelCovers.forEach(
    (planCover: IAbstractRecord) => {
      const id =
        planCover.planConfigManagement_PlanSpecificTravel_PolicyCoverID?.id;
      const policyCover =
        planCover.planConfigManagement_PlanSpecificTravel_PolicyCoverID.views
          .PlanConfigManagement_all.properties;
      result.policyCovers[id] =
        `${policyCover.ExternalCode} - ${policyCover.Name}`;
    }
  );

  return result;
}

export function convertToPolicyPropertyDetails(
  propertyFields: IAbstractRecord[]
): IAmendmentRiskDetails[] {
  if (propertyFields && propertyFields.length > 0) {
    const groupedProperties: { [key: string]: IAmendmentRiskDetails } = {};

    propertyFields.forEach((propertyField: IAbstractRecord) => {
      const order = propertyField.production_PolicyProperty_Order;
      const planCoverDetails =
        propertyField.production_PolicyProperty_PlanCoverPropertyDetails;

      planCoverDetails.forEach((detail: IAbstractRecord) => {
        const geoLocationCode = detail.GeoLocation?.Code || '';
        const key = geoLocationCode;

        if (!groupedProperties[key]) {
          groupedProperties[key] = {
            riskDescription: detail.RiskDescription || '',
            geoLocation: geoLocationCode,
            location: detail.Location || '',
            planCovers: [],
            order: order,
          };
        }

        groupedProperties[key].planCovers.push({
          planCoverID: detail.PlanCoverID || '',
          riskRate: trimTrailingZeros(detail.RiskRate),
          sumInsured: trimTrailingZeros(detail.SumInsured),
          premium: trimTrailingZeros(detail.Premium),
        });
      });
    });

    return Object.values(groupedProperties).sort(
      (a: IAbstractRecord, b: IAbstractRecord) => a.order - b.order
    );
  } else {
    return [
      {
        riskDescription: '',
        geoLocation: '',
        location: '',
        planCovers: [
          {
            planCoverID: '',
            riskRate: null,
            sumInsured: null,
            premium: null,
          },
        ],
      },
    ];
  }
}

export function convertToPolicyBankersBlanketBondDetails(
  bankersDetails: IAbstractRecord[]
): IAmendmentBankerDetails[] {
  if (bankersDetails.length > 0) {
    return bankersDetails
      .sort(
        (a: IAbstractRecord, b: IAbstractRecord) =>
          a.production_PolicyBBBond_Order - b.production_PolicyBBBond_Order
      )
      .map((detail: IAbstractRecord) => ({
        branchName: detail.production_PolicyBBBond_BranchName || '',
        isMain: isEmpty(detail.production_PolicyBBBond_IsMain)
          ? ''
          : detail.production_PolicyBBBond_IsMain
            ? 'YES'
            : 'NO',
        isAgency: isEmpty(detail.production_PolicyBBBond_IsAgency)
          ? ''
          : detail.production_PolicyBBBond_IsAgency
            ? 'YES'
            : 'NO',
        numberOfEmployees: Number(
          detail.production_PolicyBBBond_NumberOfEmployees
        ),
        underwritingYear: Number(
          detail.production_PolicyBBBond_UnderwritingYear
        ),
        infidelity: trimTrailingZeros(
          detail.production_PolicyBBBond_Infidelity
        ),
        burglary: trimTrailingZeros(detail.production_PolicyBBBond_Burglary),
        holdUp: trimTrailingZeros(detail.production_PolicyBBBond_HoldUp),
        inSafe: trimTrailingZeros(detail.production_PolicyBBBond_InSafe),
        inTransit: trimTrailingZeros(detail.production_PolicyBBBond_InTransit),
        forgedChecks: trimTrailingZeros(
          detail.production_PolicyBBBond_ForgedCheckes
        ),
        counterfeitCurrencies: trimTrailingZeros(
          detail.production_PolicyBBBond_CounterFeitCurrencies
        ),
        clause6: trimTrailingZeros(detail.production_PolicyBBBond_Clause6),
        falsification: trimTrailingZeros(
          detail.production_PolicyBBBond_Falsification
        ),
      }));
  } else {
    return [
      {
        branchName: '',
        isMain: '',
        isAgency: '',
        numberOfEmployees: null,
        underwritingYear: null,
        infidelity: null,
        burglary: null,
        holdUp: null,
        inSafe: null,
        inTransit: null,
        forgedChecks: null,
        counterfeitCurrencies: null,
        clause6: null,
        falsification: null,
      },
    ];
  }
}
