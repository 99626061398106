import { gql } from '@apollo/client';

export function getPolicyCoverDetailsInfo() {
  return gql`
    query getPolicyCoverDetailsInfo($id: String!) {
      PlanConfigManagement {
        entities {
          policyCover(id: $id) {
            views {
              PlanConfigManagement_all {
                properties {
                  createdOn
                  createdBy
                  modifiedOn
                  modifiedBy
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          Status
                        }
                      }
                    }
                  }
                  ExternalCode
                  Name
                  NameArabic
                  PrintingOrder
                  PolicyCoverDescription
                }
              }
            }
            queries {
              planConfigManagement {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
            }
          }
        }
      }
      Production {
        queries {
          CheckIfPolicyCoverTreatyExist(policyCoverId: $id) {
            production_PolicyCovertoTreaty_Id
            treaty_TreatyID
            treaty_Id
            treaty_TreatyName
          }
        }
      }
    }
  `;
}

export function getPolicyCoverClaimCoverList() {
  return gql`
    query getPolicyCoverClaimCoverList(
      $currentPage: Int!
      $currentPageSize: Int!
      $SelectedLineId: [String]
      $selectedPolicyCoverID: [String]
      $Attribute: String!
      $Descending: Boolean!
    ) {
      PlanConfigManagement {
        queries {
          getClaimCoverList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            SelectedLineId: $SelectedLineId
            selectedPolicyCoverID: $selectedPolicyCoverID
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              planConfigManagement_ClaimCover_Id
              planConfigManagement_ClaimCover_Name
              planConfigManagement_ClaimCover_ExternalCode
              planConfigManagement_ClaimCover_PolicyCoverID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                    }
                  }
                }
              }
              planConfigManagement_ClaimCover_NameArabic
              planConfigManagement_ClaimCover_PrintingOrder
              planConfigManagement_ClaimCover_ClaimCoverDescription
              planConfigManagement_ClaimCover_createdOn
              planConfigManagement_ClaimCover_modifiedOn
            }
          }
        }
      }
    }
  `;
}
