import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query LIST_POLICYCOVERSAPPLICATIONS(
      $currentPage: Int!
      $currentPageSize: Int!
      $SelectedLineId: [String]
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
      $FromCreatedOn: Date
      $ToCreatedOn: Date
    ) {
      Production {
        queries {
          getPolicyCoverList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            SelectedLineId: $SelectedLineId
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
            FromCreatedOn: $FromCreatedOn
            ToCreatedOn: $ToCreatedOn
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              planConfigManagement_PolicyCover_Id
              planConfigManagement_PolicyCover_createdOn
              planConfigManagement_PolicyCover_modifiedOn
              planConfigManagement_PolicyCover_ExternalCode
              planConfigManagement_PolicyCover_Name
              planConfigManagement_PolicyCover_NameArabic
              planConfigManagement_PolicyCover_NameFrench
              planConfigManagement_PolicyCover_PrintingOrder
              planConfigManagement_PolicyCover_PolicyCoverDescription
              treaty_TreatyName
              planConfigManagement_PolicyCover_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      ExternalCode
                      Name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function filterQuery() {
  return gql`
    query POLICYCOVERS_LISTING_LOVS {
      PlanConfigManagement {
        queries {
          allLines {
            planConfigManagement_Line_Id
            planConfigManagement_Line_Name
            planConfigManagement_Line_Status
          }
        }
      }
    }
  `;
}
