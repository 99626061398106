import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useMutation, useQuery } from '@apollo/client';
import Loader from '../../components/Loader';
import { cloneDeep, isEmpty } from 'lodash';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { IAuthoritiesDrawerProps } from '.';
import {
  getAssignedBPPlanLists,
  updateBusinessUserAuthorities,
} from './queries';
import { getError } from '../../utils/graph-utils';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { toLookups } from './utils';

const SalesForceBusinessUserAuthoritiesDrawer: React.FC<
  IAuthoritiesDrawerProps
> = ({ open, onSuccess, onClose, authoritiesDetailsInfo, businessUserId }) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const assignedBPPlanListResults = useQuery(getAssignedBPPlanLists(), {
    variables: { selectedBusinessUserID: businessUserId },
  });

  const [authoritiesAction] = useMutation(updateBusinessUserAuthorities());

  useEffect(() => {
    initialize();
  }, [assignedBPPlanListResults.data]);

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;

  const initialize = () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      if (assignedBPPlanListResults.data) {
        const lookupLists = toLookups(assignedBPPlanListResults.data);

        (
          updatedInputs.editableLineCharges as IFormSelectDynamicProps
        ).selectOptions = lookupLists;
      }

      if (authoritiesDetailsInfo) {
        updatedInputs.editableLineCharges.value =
          authoritiesDetailsInfo.editableLineCharges;
        updatedInputs.allowPlanCoverEdits.value =
          authoritiesDetailsInfo.allowPlanCoverEdits;
        updatedInputs.allowPolicyEndDateEdits.value =
          authoritiesDetailsInfo.allowPolicyEndDateEdits;
        updatedInputs.allowClauseEdits.value =
          authoritiesDetailsInfo.allowClauseEdits;
        updatedInputs.allowGroupPolicy.value =
          authoritiesDetailsInfo.allowGroupPolicy;
        updatedInputs.allowEndorsement.value =
          authoritiesDetailsInfo.allowEndorsement;
        updatedInputs.allowEndorsementCostEdits.value =
          authoritiesDetailsInfo.allowEndorsementCostEdits;
        updatedInputs.allowPolicyCorrection.value =
          authoritiesDetailsInfo.allowPolicyCorrection;
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      let variablesMutation = {};
      variablesMutation = {
        entityId: businessUserId,
        allowPlanCoverEdits: data.allowPlanCoverEdits,
        allowPolicyEndDateEdits: data.allowPolicyEndDateEdits,
        allowClauseEdits: data.allowClauseEdits,
        editableLineChargesIDs: data.editableLineCharges || [],
        allowGroupPolicy: data.allowGroupPolicy,
        allowEndorsement: data.allowEndorsement,
        allowEndorsementCostEdits: data.allowEndorsementCostEdits,
        allowPolicyCorrection: data.allowPolicyCorrection,
      };

      authoritiesAction({
        variables: variablesMutation,
        errorPolicy: 'all',
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {'Authorities of Business User successfully updated.'}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState('success');
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title={'Modify Production Authorities'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {assignedBPPlanListResults.loading && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default SalesForceBusinessUserAuthoritiesDrawer;
