import React from 'react';
import EnhancedStepper from '../../../../../components/common/EnhancedStepper';
import { makeStyles } from 'tss-react/mui';
import { MAIN_ONE_THEME } from '../../../../../constants';
import { formatEnum } from '../../../../../utils/formatting-utils';
import StepperActions from './StepperActions';
import { IAmendmentPageData } from '../..';
import { ILineAmendmentProps } from '../../line-amendment';
import ShowForUser from '../../../../../components/user/ShowForUser';

interface IStepper {
  generalData: ILineAmendmentProps['generalData'];
  values: IAmendmentPageData;
  onSuccess: () => void;
}

const useStyles = makeStyles()(() => ({
  stepperContainer: {
    margin: '20px 0',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: '1em',
    alignItems: 'start',
  },
  stepper: {
    backgroundColor: 'transparent',
    width: '95%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
}));

const Stepper: React.FC<IStepper> = ({ generalData, values, onSuccess }) => {
  const { classes } = useStyles();

  const amendmentActiveOrNew =
    generalData.amendmentStatus === 'NEW' ||
    generalData.amendmentStatus === 'IN_PROGRESS';

  const steps = ['NEW', 'IN_PROGRESS', 'COMPLETED'];
  if (!steps.includes(generalData.amendmentStatus)) {
    steps.pop();
    steps.push(generalData.amendmentStatus);
  }

  return (
    <section className={classes.stepperContainer}>
      <EnhancedStepper
        activeStep={formatEnum(generalData.amendmentStatus ?? steps[0], ' ')}
        steps={steps.map((step) => formatEnum(step, ' '))}
        className={classes.stepper}
      />
      {amendmentActiveOrNew && (
        <ShowForUser
          allowedRoles={['Core-BusinessUser']}
          allowedAgentTypes={['']}
        >
          <StepperActions
            generalData={generalData}
            values={values}
            onSuccess={onSuccess}
          />
        </ShowForUser>
      )}
    </section>
  );
};

export default Stepper;
