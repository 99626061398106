import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../components/enhanced-table';
import { IAbstractRecord } from '../../../../models';

export const treatyDistributionHeaders: Record<string, EnhancedTableHeader> = {
  coverName: {
    name: 'coverName',
    title: 'Cover',
    type: EnhancedTableHeaderType.Text,
  },
  treatyName: {
    name: 'treatyName',
    title: 'Treaty',
    type: EnhancedTableHeaderType.Text,
  },
  treatyCurrency: {
    name: 'treatyCurrency',
    title: 'Treaty Currency',
    type: EnhancedTableHeaderType.Text,
  },
  treatyEffectiveDate: {
    name: 'treatyEffectiveDate',
    title: 'Treaty Effective Date',
    type: EnhancedTableHeaderType.Date,
  },
  treatyExpiryDate: {
    name: 'treatyExpiryDate',
    title: 'Treaty Expiry Date',
    type: EnhancedTableHeaderType.Date,
  },
  mainReinsurer: {
    name: 'mainReinsurer',
    title: 'Main Reinsurer',
    type: EnhancedTableHeaderType.Text,
  },
  totalCededSumInsured: {
    name: 'totalCededSumInsured',
    title: 'Total Ceded Sum Insured',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  totalCededNetPremium: {
    name: 'totalCededNetPremium',
    title: 'Total Ceded Net Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  totalCommission: {
    name: 'totalCommission',
    title: 'Total Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
};

export const getTreatyBreakdownTableData = (
  data: IAbstractRecord
): Record<string, EnhancedTableHeader> => {
  const systemBasicCurrencySymbol =
    data?.Core?.queries?.getSystemCurrencies[0]
      ?.core_SystemCurrency_BasicCurrency?.Symbol;

  const systemSecondaryCurrencySymbol =
    data?.Core?.queries?.getSystemCurrencies[0]
      ?.core_SystemCurrency_SecondaryCurrency?.Symbol;

  return {
    layerType: {
      name: 'layerType',
      title: 'Layer',
      type: EnhancedTableHeaderType.Text,
    },
    excessAmount: {
      name: 'excessAmount',
      title: 'Excess Amount',
      type: EnhancedTableHeaderType.RoundedCurrency,
    },
    retainedSumInsured: {
      name: 'retainedSumInsured',
      title: 'Retained Sum Insured',
      type: EnhancedTableHeaderType.RoundedCurrency,
    },
    retainedNetPremium: {
      name: 'retainedNetPremium',
      title: 'Retained Net Premium',
      type: EnhancedTableHeaderType.RoundedCurrency,
    },
    retainedNetPremiumBasic: {
      name: 'retainedNetPremiumBasic',
      title: 'Retained Net Premium (Basic Currency)',
      type: EnhancedTableHeaderType.RoundedCurrency,
      currency: systemBasicCurrencySymbol,
    },
    retainedNetPremiumSecondary: {
      name: 'retainedNetPremiumSecondary',
      title: 'Retained Net Premium (Secondary Currency)',
      type: EnhancedTableHeaderType.RoundedCurrency,
      currency: systemSecondaryCurrencySymbol,
    },
    cededSumInsured: {
      name: 'cededSumInsured',
      title: 'Ceded Sum Insured',
      type: EnhancedTableHeaderType.RoundedCurrency,
    },
    cededNetPremium: {
      name: 'cededNetPremium',
      title: 'Ceded Net Premium',
      type: EnhancedTableHeaderType.RoundedCurrency,
    },
    cededNetPremiumBasic: {
      name: 'cededNetPremiumBasic',
      title: 'Ceded Net Premium (Basic Currency)',
      type: EnhancedTableHeaderType.RoundedCurrency,
      currency: systemBasicCurrencySymbol,
    },
    cededNetPremiumSecondary: {
      name: 'cededNetPremiumSecondary',
      title: 'Ceded Net Premium (Secondary Currency)',
      type: EnhancedTableHeaderType.RoundedCurrency,
      currency: systemSecondaryCurrencySymbol,
    },
    commission: {
      name: 'commission',
      title: 'Commission',
      type: EnhancedTableHeaderType.RoundedCurrency,
    },
  };
};

export const facDistributionHeaders: Record<string, EnhancedTableHeader> = {
  coverName: {
    name: 'coverName',
    title: 'Cover',
    type: EnhancedTableHeaderType.Text,
  },
  facSlipRefNum: {
    name: 'facSlipRefNum',
    title: 'Facultative Slip Ref. No.',
    type: EnhancedTableHeaderType.Link,
    urlTemplate: '/reinsurance/facultatives/{facId}',
    openUrlInNewTab: false,
  },
  coverCurrency: {
    name: 'coverCurrency',
    title: 'Cover Currency',
    type: EnhancedTableHeaderType.Text,
  },
  coverEffectiveDate: {
    name: 'coverEffectiveDate',
    title: 'Cover Effective Date',
    type: EnhancedTableHeaderType.Date,
  },
  coverExpiryDate: {
    name: 'coverExpiryDate',
    title: 'Cover Expiry Date',
    type: EnhancedTableHeaderType.Date,
  },
  mainReinsurer: {
    name: 'mainReinsurer',
    title: 'Main Reinsurer',
    type: EnhancedTableHeaderType.Text,
  },
  cededSumInsured: {
    name: 'cededSumInsured',
    title: 'Ceded Sum Insured',
    type: EnhancedTableHeaderType.Currency,
  },
  cededNetPremium: {
    name: 'cededNetPremium',
    title: 'Ceded Net Premium',
    type: EnhancedTableHeaderType.Currency,
  },
  commission: {
    name: 'commission',
    title: 'Commission',
    type: EnhancedTableHeaderType.Currency,
  },
};
