import { gql } from '@apollo/client';

export const getBreakdown = gql(`
  query GetReceivableAndPayableBillsByParentBillID(
  $selectedParentBillID: String!
) {
  Accounting {
    queries {
      GetReceivableAndPayableBillsByParentBillID(selectedParentBillID: $selectedParentBillID) {
       accounting_Bills_AmountDue
        accounting_Bills_AmountPaid
        accounting_Bills_AmountReceived
        accounting_Bills_BillNumber
        accounting_Bills_BillType
        payableBills_AmountPaid
        accounting_Bills_Currency {
          Symbol
        }
        accounting_Bills_DueDate
        accounting_Bills_Id
        accounting_Bills_TaxOnCommission
        accounting_Bills_TotalCommission
        accounting_Bills_TotalPremium
      }
    }
  }
}
`);
