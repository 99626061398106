import React, { useEffect, useState } from 'react';
import WidgetPaper from '../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../components/common/WidgetSection';
import EnhancedChipInput from '../../../../components/enhanced-form/EnhancedChipInput';
import NumberInputFormField from '../../../../components/form-fields/NumberInputFormField';
import { IAmendmentPageState, IAmendmentTravelDetails } from '..';
import { ILineAmendmentProps } from '../line-amendment';
import { useLazyQuery } from '@apollo/client';
import { getTravelLovsQuery } from '../queries';
import { getTravelLovs } from '../utils';
import Loader from '../../../../components/Loader';
import { useAmendmentStyles } from '../styles';

interface ITravelDetailsWidgetProps {
  generalData: ILineAmendmentProps['generalData'];
  pageState: IAmendmentPageState;
  onPageStateUpdate: (pageState: IAmendmentPageState) => void;
  isDisabled?: boolean;
}

export interface ITravelLovs {
  destinations: Record<string, string>;
  policyCovers: Record<string, string>;
}

const TravelDetailsWidget: React.FC<ITravelDetailsWidgetProps> = ({
  generalData,
  pageState,
  onPageStateUpdate,
  isDisabled,
}) => {
  const { classes } = useAmendmentStyles();

  const values = pageState.values.travelDetails;
  const errors = pageState.errors.travelDetails;
  const touched = pageState.touched.travelDetails;

  const [lovs, setLovs] = useState<ITravelLovs>({
    destinations: {},
    policyCovers: {},
  });
  const [getTravelLovsLazy, { loading }] = useLazyQuery(getTravelLovsQuery);

  const initialize = async () => {
    const { data: lovsData } = await getTravelLovsLazy({
      variables: {
        selectedPlanID: generalData.planId,
      },
    });

    const lists = getTravelLovs(lovsData);
    setLovs({
      destinations: lists.destinations,
      policyCovers: lists.policyCovers,
    });
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFieldBlur = (fieldName: string) => {
    const newPageState = { ...pageState };
    newPageState.touched.travelDetails[fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IAmendmentTravelDetails,
    value: unknown
  ) => {
    const newPageState = { ...pageState };
    (newPageState.values.travelDetails as any)[fieldName] = value;
    onPageStateUpdate(newPageState);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <WidgetPaper>
      <WidgetSection title="Travel Details" isFieldsGrid>
        <EnhancedChipInput
          key="destination"
          name="destination"
          title="Destination*"
          placeholder="Destination"
          value={values.destination}
          error={touched.destination ? errors.destination : ''}
          onChange={(v) => onFieldUpdate('destination', v)}
          disabled={isDisabled}
          selectOptions={lovs.destinations}
          required
          multiple={false}
          material
          customStyles={{ labelStyles: classes.labelDropdown }}
        />

        <NumberInputFormField
          key="duration"
          name="duration"
          title="Duration (in Days)*"
          placeholder="Duration"
          value={values.duration}
          error={touched.duration ? errors.duration : ''}
          onBlur={() => onFieldBlur('duration')}
          onChange={(e) => onFieldUpdate('duration', e.target.value)}
          disabled={isDisabled}
          material
        />

        <EnhancedChipInput
          key="policyCover"
          name="policyCover"
          title="Policy Cover*"
          placeholder="Policy Cover"
          value={values.policyCover}
          error={touched.policyCover ? errors.policyCover : ''}
          onChange={(v) => onFieldUpdate('policyCover', v)}
          disabled={isDisabled}
          selectOptions={lovs.policyCovers}
          required
          multiple
          material
          className={classes.fullWidthItem}
          customStyles={{ labelStyles: classes.labelDropdown }}
        />
      </WidgetSection>
    </WidgetPaper>
  );
};

export default TravelDetailsWidget;
