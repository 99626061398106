import { IPersonDetailsInfo } from '.';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const personEnums: Record<string, Record<string, string>> = {};

  personEnums[data.SalesforceManagement_PersonTypes.name] =
    enumListAsRecordObject(data.SalesforceManagement_PersonTypes.enumValues);

  if (personEnums[data.SalesforceManagement_PersonTypes.name]['PERSON']) {
    personEnums[data.SalesforceManagement_PersonTypes.name]['PERSON'] =
      'Individual';
  }

  personEnums['phoneType'] = enumListAsRecordObject(
    data?.Insurance_PhoneType?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );
  personEnums['genders'] = enumListAsRecordObject(
    data?.Insurance_Genders?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  personEnums['titles'] = lookupListAsRecordObject(
    data.Insurance.lookups.titles
  );

  personEnums['nationalities'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.nationalities
  );

  personEnums['occupationClasses'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.occupationClasses
  );

  personEnums['addressType'] = enumListAsRecordObject(
    data?.Core_AddressTypes?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  personEnums['countries'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.countries
  );

  return personEnums;
}

export function graphqlEntityToPersonInfo(data: any): IPersonDetailsInfo {
  const personProperties =
    data?.SalesforceManagement?.entities?.person?.views
      ?.SalesforceManagement_all?.properties;
  return {
    fullName: personProperties?.PersonType,
    dateOfBirth: personProperties?.DateOfBirth,
    phoneType: personProperties?.PhoneDetails?.PhoneType,
    phoneNumber: personProperties?.PhoneDetails?.PhoneNumber,
    nationalityOne: personProperties?.NationalityOne?.Code,
    isTaxable: personProperties?.IsTaxable,
    occupationClass: personProperties?.NationalityOne,
    profession: personProperties?.OccupationClass?.Code,
    country: personProperties?.Country?.Code,
    zone: personProperties?.Zone?.Code,
    city: personProperties?.City?.Code,
    street: personProperties?.Street?.Code,
    building: personProperties?.Building?.Code,
    addressDetails: personProperties?.AddressDetails,
    addressDetailsArabic: personProperties?.AddressDetailsArabic,
  };
}
