import { gql } from '@apollo/client';

export const getLovs = gql`
  query getLovsQuery {
    SalesforceManagement {
      lookups {
        relatedCompanies {
          Id
          Code
          Title
        }
        businessPartnerTypes {
          Id
          Code
          Title
        }
      }
    }
  }
`;

export const getPolicyBusinessPartnerListQuery = gql`
  query GetPolicyBusinessPartnerList(
    $keywordSearch: String
    $selectedCompany: String!
    $orderByField: String
    $descendingField: Boolean
    $businessPartnerType: [String]
    $pagination: PaginationInput!
  ) {
    Accounting {
      queries {
        GetBusinessPartnerPolicies(
          KeywordSearch: $keywordSearch
          SelectedCompany: $selectedCompany
          BusinessPartnerType: $businessPartnerType
          OrderByField: $orderByField
          DescendingField: $descendingField
          pagination: $pagination
        ) {
          items {
            salesforceManagement_BusinessPartner_FullName
            salesforceManagement_BusinessPartner_Id
            salesforceManagement_BusinessPartner_Type {
              Code
              Title
            }
          }
          paging {
            pageSize
            pageNumber
            totalCount
          }
        }
      }
    }
  }
`;
