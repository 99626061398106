import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  TextField,
  IconButton,
  Box,
  InputAdornment,
} from '@mui/material';
import { debounce, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import { IEnhancedFormInputBaseProps } from '.';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Clear } from '@mui/icons-material';

export interface IMultiAutocompleteFormFieldProps
  extends IEnhancedFormInputBaseProps {
  value: string[];
  loader?: boolean;
  freeSolo?: boolean;
  onChange?: (value: string[]) => void;
  onBlur?: (value: string[]) => void;
  classes?: { input?: string; wrapper?: string };
  maxItemsDisplayed?: number;
  canClearValue?: boolean;
  fetchOptions: (
    query: string,
    pagination: { pageNumber: number; pageSize: number }
  ) => Promise<{
    options: Record<string, string>;
    totalCount: number;
  }>;
  fetchOptionsByValues?: (
    values: string[],
    pagination: { pageNumber: number; pageSize: number }
  ) => Promise<{
    options: Record<string, string>;
    totalCount: number;
  }>;
}

const useStyles = makeStyles<{
  error?: string;
}>()((theme, { error }) => ({
  inputContainerStyles: {
    width: '100%',
    marginBottom: '5px',
  },
  inputWrapper: {
    position: 'relative',
  },
  inputStyles: {
    width: '100%',
    border: '1px solid #E3E3E3',
    borderRadius: '5px',
    outline: 'none',
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontStyle: 'italic',
        fontFamily: contentFontFamilyRegular,
        color: '#33333333',
        opacity: 1,
        fontSize: '14px',
      },
    },
    '& .MuiInputBase-root': {
      fontSize: `13px`,
      lineHeight: '15px',
      minHeight: '35px',
      width: '100%',
      padding: '0 25px 0 15px!important',
      margin: '0 auto',
      backgroundColor: `#f9f9f9`,
      outlineColor: error ? theme.palette.error.main : undefined,
      border: error
        ? `1px solid ${theme.palette.error.main}`
        : `0px solid #DFE3EB`,
      boxSizing: 'border-box',
      borderRadius: '5px',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  },
  optionDesign: {
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
    lineHeight: '15px',
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontFamily: contentFontFamilyRegular,
  },
  paginationIcons: {
    display: 'flex',
    gap: '5px',
    justifyContent: 'end',
    alignItems: 'center',
    fontSize: '14px',
    padding: '5px 7px 0 0',
    '& .MuiSvgIcon-root': {
      width: '0.8em',
    },
    '& .MuiIconButton-root': {
      padding: '2px',
    },
  },
  chipStyle: {
    height: 'unset',
    color: '#fff',
    backgroundColor: MAIN_ONE_THEME.palette.primary1.main,
    borderRadius: '0px',
    margin: '3px',
    '& .MuiChip-deleteIcon': {
      color: 'white !important',
    },
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 10px)',
    right: '3%',
    opacity: 1,
    height: '25px',
    width: '25px',
    fontFamily: contentFontFamilyRegular,
  },
  selectMaterial: {
    '& .MuiInput-input': {
      fontSize: '13px',
      lineHeight: '15px',
      color: `#07295A`,
    },
    '& .MuiInputBase-root:before': {
      border: 'none',
      backgroundColor: 'unset',
      borderRadius: '0',
      borderBottom: `1px solid #DFE3EB`,
    },

    '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInputBase-root:after':
      {
        borderBottom: `1px solid #DFE3EB`,
      },
  },
  selectError: {
    '& .MuiInputBase-root:before': {
      borderBottom: `1px solid ${theme.palette.error.main}`,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInputBase-root:after':
      {
        borderBottom: `1px solid ${theme.palette.error.main}`,
      },
    '& .MuiInputBase-root:focus': {
      borderColor: theme.palette.error.main,
      outlineColor: theme.palette.error.main,
      boxShadow: 'none',
      borderRadius: 0,
    },
    '& .MuiInputBase-root': {
      borderColor: `${theme.palette.error.main}`,
      outlineColor: `${theme.palette.error.main}`,
    },
  },
  clearBtnContainer: {
    marginRight: '10px',
  },
  clearBtn: {
    cursor: 'pointer',
    height: '12px',
    width: '12px',
  },
}));

export interface IAutocompletePagination {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
}

function getPaginationRange(
  pageNumber: number,
  pageSize: number,
  totalCount: number
) {
  const from = (pageNumber - 1) * pageSize + 1;
  const to = Math.min(pageNumber * pageSize, totalCount);
  return `${from} – ${to} of ${totalCount}`;
}

const MultiAutocompleteFormField: React.FC<
  IMultiAutocompleteFormFieldProps
> = ({
  name,
  title,
  placeholder,
  className,
  style,
  classes = {},
  value,
  error,
  onChange,
  onBlur,
  disabled,
  loader,
  inputOnly,
  freeSolo = false,
  material,
  maxItemsDisplayed = 10,
  fetchOptions,
  fetchOptionsByValues,
  canClearValue = false,
}) => {
  const { classes: inputClasses } = useStyles({ error });
  const [options, setOptions] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<IAutocompletePagination>({
    pageNumber: 1,
    pageSize: maxItemsDisplayed,
    totalCount: 0,
  });
  const [inputValue, setInputValue] = useState('');

  const valueRef = useRef(value);
  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  useEffect(() => {
    debouncedGetOptions('', pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedGetOptions = useCallback(
    debounce(
      (inputValue: string, paginationValues: IAutocompletePagination) => {
        setLoading(true);
        fetchOptions(inputValue, {
          pageSize: paginationValues.pageSize,
          pageNumber: paginationValues.pageNumber,
        })
          .then(({ options: newOptions, totalCount }) => {
            setOptions((prevOptions) => {
              const selectedOptions = valueRef.current.reduce(
                (acc: Record<string, string>, val) => {
                  acc[val] = prevOptions[val] || val;
                  return acc;
                },
                {}
              );
              return {
                ...newOptions,
                ...selectedOptions,
              };
            });
            setPagination({
              ...paginationValues,
              totalCount,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
      300
    ),
    [fetchOptions]
  );

  useEffect(() => {
    if (Array.isArray(value) && value.length > 0) {
      const fetchInitialOptions = async () => {
        const missingValues = value.filter((val) => !options[val]);
        if (missingValues.length > 0) {
          setLoading(true);
          try {
            const fetchedOptions = await fetchOptionsByValues(
              missingValues,
              pagination
            );
            setOptions((prevOptions) => ({
              ...prevOptions,
              ...fetchedOptions.options,
            }));
          } finally {
            setLoading(false);
          }
        }
      };

      fetchInitialOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleClearValue = () => {
    onChange?.([]);
  };

  const renderInput = () => {
    const optionsLength = Object.keys(options).length - value?.length;

    return (
      <div className={inputClasses.inputContainerStyles}>
        <FormControl
          error={!!error}
          disabled={!!disabled}
          className={inputClasses.inputContainerStyles}
          size="small"
          variant="outlined"
        >
          <Autocomplete
            freeSolo={freeSolo}
            multiple={true}
            disabled={!!disabled}
            disablePortal={false}
            options={Object.keys(options)}
            onInputChange={(event, newInputValue) => {
              if (newInputValue !== inputValue) {
                setInputValue(newInputValue);
                if (event?.type !== 'click') {
                  debouncedGetOptions(newInputValue, {
                    pageNumber: 1,
                    pageSize: maxItemsDisplayed,
                    totalCount: pagination.totalCount,
                  });
                }
                if (isEmpty(newInputValue)) {
                  debouncedGetOptions('', {
                    pageNumber: 1,
                    pageSize: maxItemsDisplayed,
                    totalCount: pagination.totalCount,
                  });
                }
              }
            }}
            onBlur={() => {
              const invalidValues = value.filter(
                (val) => !options[val] && !isEmpty(val)
              );
              if (invalidValues.length > 0) {
                setInputValue('');
                onChange?.(value.filter((val) => options[val]));
                debouncedGetOptions('', {
                  pageNumber: 1,
                  pageSize: maxItemsDisplayed,
                  totalCount: pagination.totalCount,
                });
              }
              onBlur?.(value);
            }}
            onChange={(event, newValue) => {
              onChange?.(newValue);
              setOptions((prevOptions) => {
                const selectedOptions = newValue.reduce(
                  (acc: Record<string, string>, val: string) => {
                    acc[val] = prevOptions[val];
                    return acc;
                  },
                  {}
                );

                const deletedOptions = Object.keys(prevOptions).filter(
                  (option) => !newValue.includes(option)
                );

                deletedOptions.forEach((option) => {
                  delete prevOptions[option];
                });

                return {
                  ...prevOptions,
                  ...selectedOptions,
                };
              });
            }}
            value={value}
            getOptionLabel={(option) => options[option] || option}
            disableClearable={true}
            selectOnFocus
            loading={loading}
            noOptionsText={loading ? 'Fetching data...' : 'No data'}
            renderOption={(props, option, state) => {
              const showPagination =
                optionsLength < pagination.totalCount &&
                state.index === optionsLength - 1 &&
                (value.length === 0 || !isEmpty(inputValue));
              return (
                <>
                  <li {...props} key={option}>
                    <span className={inputClasses.optionDesign}>
                      {options[option]}
                    </span>
                  </li>
                  {showPagination && (
                    <Box className={inputClasses.paginationIcons}>
                      {getPaginationRange(
                        pagination.pageNumber,
                        pagination.pageSize,
                        pagination.totalCount
                      )}
                      <IconButton
                        size="small"
                        disabled={pagination.pageNumber === 1 || loading}
                        onClick={(e) => {
                          e.stopPropagation();
                          const pageNumber = Math.max(
                            pagination.pageNumber - 1,
                            1
                          );
                          debouncedGetOptions(inputValue, {
                            pageSize: pagination.pageSize,
                            pageNumber,
                            totalCount: pagination.totalCount,
                          });
                        }}
                      >
                        <NavigateBeforeIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        disabled={loading}
                        onClick={(e) => {
                          e.stopPropagation();
                          const pageNumber = pagination.pageNumber + 1;
                          debouncedGetOptions(inputValue, {
                            pageSize: pagination.pageSize,
                            pageNumber,
                            totalCount: pagination.totalCount,
                          });
                        }}
                      >
                        <NavigateNextIcon />
                      </IconButton>
                    </Box>
                  )}
                </>
              );
            }}
            renderTags={(value, getTagProps) =>
              Array.isArray(value) &&
              (value as readonly string[]).map(
                (option: string, index: number) =>
                  !isEmpty(options[option]) ? (
                    <Chip
                      key={option}
                      label={options[option]}
                      {...getTagProps({ index })}
                      className={inputClasses.chipStyle}
                    />
                  ) : (
                    <Chip
                      key={option}
                      label={option}
                      {...getTagProps({ index })}
                      className={inputClasses.chipStyle}
                    />
                  )
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                placeholder={placeholder}
                className={clsx(
                  !material && inputClasses.inputStyles,
                  material && inputClasses.selectMaterial,
                  error && inputClasses.selectError
                )}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: params.InputProps.startAdornment,
                  endAdornment: (
                    <>
                      {value && canClearValue && (
                        <InputAdornment
                          position="end"
                          className={inputClasses.clearBtnContainer}
                        >
                          <IconButton
                            aria-label="clear date"
                            onClick={handleClearValue}
                            edge="end"
                            size="small"
                            style={{ marginRight: material ? '30px' : '-17px' }}
                          >
                            <Clear className={inputClasses.clearBtn} />
                          </IconButton>
                        </InputAdornment>
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                variant="standard"
              />
            )}
          />
        </FormControl>
      </div>
    );
  };

  return inputOnly ? (
    renderInput()
  ) : (
    <>
      <EnhancedInputsWrapper
        title={title}
        error={error}
        name={name}
        className={clsx(classes.wrapper, className)}
        style={style}
      >
        {renderInput()}
        {loader && (
          <CircularProgress
            className={inputClasses.loader}
            size={20}
            thickness={3}
          />
        )}
      </EnhancedInputsWrapper>
    </>
  );
};

export default MultiAutocompleteFormField;
