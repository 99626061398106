import React, { useCallback, useMemo, useState } from 'react';
import ListingTableWithCollapsibleFilters, {
  IListingTableWithCollapsibleFiltersProps,
} from '../../../../../../components/form-fields/table/with-collapsable-filters/ListingTableWithCollapsableFilters';

interface ITableWithFiltersProps
  extends Pick<
    IListingTableWithCollapsibleFiltersProps,
    'inlineTitle' | 'headers' | 'data' | 'actions' | 'tableFilters'
  > {
  loading?: boolean;
  applyFilters: (
    values: Record<string, Date[] | string[]>,
    page?: number
  ) => void;
}

const TableWithFilters: React.FC<ITableWithFiltersProps> = ({
  loading,
  tableFilters,
  inlineTitle,
  headers,
  data,
  actions,
  applyFilters,
}) => {
  const [filterValues, setFilterValues] = useState<
    Record<string, Date[] | string[]>
  >({});

  const filters: ITableWithFiltersProps['tableFilters'] = useMemo(() => {
    const filtersWithValues: ITableWithFiltersProps['tableFilters'] = {};
    let key = '';
    Object.keys(tableFilters).forEach((filter) => {
      switch (tableFilters[filter].type) {
        case 'select': {
          key = 'selectedValues';
          break;
        }
        default: {
          key = 'value';
          break;
        }
      }
      filtersWithValues[filter] = {
        ...tableFilters[filter],
        [key]: filterValues[filter],
      };
    });
    return filtersWithValues;
  }, [filterValues, tableFilters]);

  const clearFilter = useCallback(() => {
    const resetFilterValues = Object.keys(filterValues).reduce(
      (acc: Record<string, Date[] | string[]>, key) => {
        acc[key] = undefined;
        return acc;
      },
      {}
    );
    setFilterValues(resetFilterValues);
    applyFilters(resetFilterValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ListingTableWithCollapsibleFilters
      name={inlineTitle}
      inlineTitle={inlineTitle}
      tableFilters={filters}
      headers={headers}
      data={data}
      loader={loading}
      actions={actions}
      handleFilterChange={(name, value) => {
        setTimeout(() => {
          setFilterValues((oldFilterValues) => ({
            ...oldFilterValues,
            [name]: value,
          }));
        }, 200);
      }}
      handleFilterClear={clearFilter}
      handleFilterAction={() => applyFilters(filterValues)}
    />
  );
};

export default TableWithFilters;
