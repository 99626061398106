import { gql } from '@apollo/client';

export function updateMotor() {
  return gql`
    mutation updatePlan(
      $entityId: String!
      $planDetailInputs: PlanConfigManagement_PlanDetailInputs!
    ) {
      planConfigManagement {
        entities {
          plan {
            planConfigManagement {
              updatePlanDetails(
                entityId: $entityId
                planDetailInputs: $planDetailInputs
              ) {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                      NameArabic
                      NameFrench
                      Abbreviation
                      EffectiveFrom
                      EffectiveTo
                      PlanStatus
                      SublineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              Name
                              ExternalCode
                            }
                          }
                        }
                      }
                      LineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              Name
                              ExternalCode
                            }
                          }
                        }
                      }
                      createdOn
                      createdBy
                      modifiedOn
                      modifiedBy
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getModelsByBrand() {
  return gql`
    query getModelsByBrand($modelId: String!) {
      Insurance {
        lookups {
          models(Insurance_Brands: $modelId) {
            Id
            Title
          }
        }
      }
    }
  `;
}
