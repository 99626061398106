import { FormInputTypes, IDynamicSection } from '../../DynamicForm';
import { IProductionDetailsSummary } from '../../modules/production/policy/page';

export const getMarineHullSections = (
  data: IProductionDetailsSummary
): Record<string, IDynamicSection> => ({
  section1: {
    title: 'Information',
    inputs: {
      vesselName: {
        name: 'vesselName',
        title: 'Vessel Name',
        placeholder: 'Vessel Name',
        type: FormInputTypes.text,
        value: data?.MarineHull?.VesselName || '',
        required: true,
      },
      connectionPort: {
        name: 'connectionPort',
        title: 'Connection Port',
        placeholder: 'Connection Port',
        type: FormInputTypes.text,
        value: data?.MarineHull?.ConnectionPort || '',
        required: true,
      },
      territorialWaters: {
        name: 'territorialWaters',
        title: 'Territorial Waters',
        placeholder: 'Territorial Waters',
        type: FormInputTypes.text,
        value: data?.MarineHull?.TerritorialWaters || '',
        required: true,
      },
    },
  },
  section2: {
    title: 'Body Details',
    inputs: {
      usage: {
        name: 'usage',
        title: 'Usage',
        placeholder: 'Usage',
        type: FormInputTypes.text,
        value: data?.MarineHull?.Usage || '',
        required: true,
      },
      builder: {
        name: 'builder',
        title: 'Builder',
        placeholder: 'Builder',
        type: FormInputTypes.text,
        value: data?.MarineHull?.Builder || '',
        required: true,
      },
      constructionYear: {
        name: 'constructionYear',
        title: 'Year of Construction',
        placeholder: 'Year of Construction',
        type: FormInputTypes.text,
        value: data?.MarineHull?.ConstructionYear || '',
        required: true,
      },
      registryPort: {
        name: 'registryPort',
        title: 'Port of Registry & Number',
        placeholder: 'Port of Registry & Number',
        type: FormInputTypes.text,
        value: data?.MarineHull?.RegisteryPort || '',
        required: true,
      },
      length: {
        name: 'length',
        title: 'Length',
        placeholder: 'Length',
        type: FormInputTypes.text,
        value: data?.MarineHull?.Length || '',
        required: true,
      },
      weight: {
        name: 'weight',
        title: 'Weight',
        placeholder: 'Weight',
        type: FormInputTypes.text,
        value: data?.MarineHull?.Weight || '',
        required: false,
      },
      height: {
        name: 'height',
        title: 'Height',
        placeholder: 'Height',
        type: FormInputTypes.text,
        value: data?.MarineHull?.Height || '',
        required: false,
      },
    },
  },
  section3: {
    title: 'Engine Details',
    inputs: {
      engineType: {
        name: 'engineType',
        title: 'Type',
        placeholder: 'Type',
        type: FormInputTypes.text,
        value: data?.MarineHull?.EngineType || '',
        required: true,
      },
      engineNumber: {
        name: 'engineNumber',
        title: 'Engine Number',
        placeholder: 'Engine Number',
        type: FormInputTypes.text,
        value: data?.MarineHull?.EngineNumber || '',
        required: true,
      },
      engineYearBuilt: {
        name: 'engineYearBuilt',
        title: 'Year Built',
        placeholder: 'Year Built',
        type: FormInputTypes.text,
        value: data?.MarineHull?.EngineYearBuilt || '',
        required: true,
      },
      engineHorsePower: {
        name: 'engineHorsePower',
        title: 'Horse Power',
        placeholder: 'Horse Power',
        type: FormInputTypes.text,
        value: data?.MarineHull?.EngineHorsePower || '',
        required: true,
      },
      engineMaxDesignedSpeed: {
        name: 'engineMaxDesignedSpeed',
        title: 'Max. Designed Speed',
        placeholder: 'Max. Designed Speed',
        type: FormInputTypes.text,
        value: data?.MarineHull?.EngineMaxDesignedSpeed || '',
        required: false,
      },
      engineFuel: {
        name: 'engineFuel',
        title: 'Fuel',
        placeholder: 'Fuel',
        type: FormInputTypes.text,
        value: data?.MarineHull?.EngineFuel || '',
        required: false,
      },
      remarks: {
        name: 'remarks',
        title: 'Remarks',
        placeholder: 'Remarks',
        type: FormInputTypes.text,
        value: data?.MarineHull?.Remarks || '',
        required: false,
      },
    },
  },
});
