import { gql } from '@apollo/client';

export function generateFleetCertificateOutput() {
  return gql`
    mutation generateFleetCertificateOutput(
      $entityId: String!
      $certificateDetailsOutputInput: [Production_CertificateDetailsOutputInput!]!
    ) {
      production {
        entities {
          policy {
            production {
              generateFleetCertificateOutput(
                entityId: $entityId
                certificateDetailsOutputInput: $certificateDetailsOutputInput
              )
            }
          }
        }
      }
    }
  `;
}
