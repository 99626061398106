import React, { useMemo, useState } from 'react';
import { getBenefieryView, getInsuredView, getPayerView } from '.';
import { IProductionDetailsSummary } from '..';
import DynamicWidget from '../../../../../components/widgets/dynamic-display/DynamicWidget';
import { makeStyles } from 'tss-react/mui';
import PersonDetailsDrawer from '../../../../../forms/modify-person-drawer/PersonDetailsDrawer';
import EnhancedButton from '../../../../../components/form-fields/buttons/EnhancedButton';

const useStyles = makeStyles()(() => ({
  actionButton: {
    display: 'inline-block',
    marginRight: '5px',
  },
  spacer: {
    height: '1em',
  },
}));

const PersonsWidget: React.FC<{ data: IProductionDetailsSummary }> = ({
  data,
}) => {
  const { classes } = useStyles();

  const [personInfoDrawerOpen, setPersonInfoDrawerOpen] =
    useState<boolean>(false);

  const elements = useMemo(() => {
    const items = [];

    const hasPayer = data?.Persons?.payer?.name;
    const hasOwner = data?.Persons?.owner?.name;
    const hasInsured = data?.Persons?.insured?.length > 0;
    const hasBeneficiary = data?.Persons?.beneficiaries?.length > 0;

    if (hasPayer || hasOwner) {
      items.push(
        <DynamicWidget
          key="payer"
          hasFourFields
          collapsibleSectionTitle="Payer"
          view={getPayerView(data.Persons.payer, data.Persons.owner)}
          actions={
            <div>
              <EnhancedButton
                isPrimary
                onClick={() => {
                  setPersonInfoDrawerOpen(true);
                }}
              >
                Modify
              </EnhancedButton>
            </div>
          }
        />
      );
    }

    if (hasInsured) {
      if (items.length > 0)
        items.push(<div key="spacer-insured" className={classes.spacer} />);
      items.push(
        <DynamicWidget
          key="insured"
          hasFourFields
          collapsibleSectionTitle="Insured"
          view={getInsuredView(
            data.Persons.insured,
            data?.LineId?.ExternalCode
          )}
        />
      );
    }

    if (hasBeneficiary) {
      if (items.length > 0)
        items.push(<div key="spacer-beneficiary" className={classes.spacer} />);
      items.push(
        <DynamicWidget
          key="Beneficiary"
          hasFourFields
          collapsibleSectionTitle="Beneficiary"
          view={getBenefieryView(data.Persons.beneficiaries)}
        />
      );
    }

    return items;
  }, [data]);

  return elements.length > 0 ? (
    <>
      {elements}
      {personInfoDrawerOpen && (
        <PersonDetailsDrawer
          open={personInfoDrawerOpen}
          onClose={() => setPersonInfoDrawerOpen(false)}
          onSuccess={() => undefined}
          proposalId={data.Id}
          section={'payer'}
        />
      )}
    </>
  ) : null;
};

export default PersonsWidget;
