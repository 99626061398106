import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  checkPolicyCoverExists,
  getPolicyCoverEnums,
  getPolicyCoverInfo,
  checkPolicyCoverPrintingOrderExists,
  createPolicyCover,
  updatePolicyCover,
} from './queries';
import { LookupToList, graphqlEntityToPolicyCoverInfo } from './utils';
import Loader from '../../components/Loader';
import { cloneDeep } from 'lodash';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { isEmpty } from '../../utils/validationUtils';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { getError } from '../../utils/graph-utils';
import { useNavigate } from 'react-router-dom';

const PolicyCoverDrawer: React.FC<IPolicyCoverDrawerProps> = ({
  policyCoverId,
  lineId,
  lineName,
  isLineNameDisabled = true,
  canNavigateToDetailsPage = false,
  open,
  onSuccess,
  onClose,
}) => {
  let policyCoverInfoResult: any;

  const navigate = useNavigate();

  const [policyCoverEnumResults, { loading: policyCoverEnumResultsLoading }] =
    useLazyQuery(getPolicyCoverEnums());

  const [policyCoverAction] = useMutation(
    policyCoverId ? updatePolicyCover() : createPolicyCover()
  );

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  if (policyCoverId) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    policyCoverInfoResult = useQuery(getPolicyCoverInfo(), {
      variables: { id: policyCoverId },
    });
  }

  const [checkPolicyCoverExistsLazy] = useLazyQuery(
    checkPolicyCoverExists(),
    {}
  );
  const [checkPolicyCoverPrintingOrderExistsLazy] = useLazyQuery(
    checkPolicyCoverPrintingOrderExists(),
    {}
  );

  const initialize = async () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      if (isLineNameDisabled) {
        updatedInputs.lineName.disabled = isLineNameDisabled;
        updatedInputs.lineName.value = lineId;
      }

      if (policyCoverInfoResult?.data) {
        const policyCoverEntity = graphqlEntityToPolicyCoverInfo(
          policyCoverInfoResult.data,
          lineId,
          lineName
        );

        if (policyCoverEntity) {
          updatedInputs.lineName.value = policyCoverEntity.lineName;
          updatedInputs.policyCoverName.value =
            policyCoverEntity.policyCoverName;
          updatedInputs.policyCoverExternalCode.value =
            policyCoverEntity.policyCoverExternalCode;
          updatedInputs.arabicName.value = policyCoverEntity.arabicName;
          updatedInputs.policyCoverPrintingOrder.value =
            policyCoverEntity.policyCoverPrintingOrder;
          updatedInputs.policyCoverDescription.value =
            policyCoverEntity.policyCoverDescription;
          updatedInputs.policyCoverTreatyID.value =
            policyCoverEntity.policyCoverTreatyID;
          // selectedLineId = selectedLineId;
        }
      }
      const result = await policyCoverEnumResults({
        variables: {
          TreatyID: updatedInputs.policyCoverTreatyID.value,
        },
      });

      updatedInputs.lineName.value = lineId;
      if (result.data) {
        const newPlanEnums = LookupToList(result.data);

        if (!lineId) {
          updatedInputs.lineName.value = lineId;
        }
        (updatedInputs.lineName as IFormSelectDynamicProps).selectOptions =
          newPlanEnums['lines'];
        (
          updatedInputs.policyCoverTreatyID as IFormSelectDynamicProps
        ).selectOptions = newPlanEnums['treaties'];
      }

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    setFormDisabled(true);
    setSubmitButtonState('loading');
    const [data] = normaliseDynamicValues(inputs, values);

    try {
      const variables = {
        policyCoverInputs: {
          name: data.policyCoverName,
          externalCode: data.policyCoverExternalCode,
          nameArabic: data.arabicName,
          printingOrder: parseInt(
            data.policyCoverPrintingOrder as unknown as string
          ),
          policyCoverDescription: data.policyCoverDescription,
          treatyID: data.policyCoverTreatyID,
          policyCoverID: data.policyCover,
          lineID: lineId || data.lineName,
        },
      };

      policyCoverAction({
        variables: policyCoverId
          ? { ...variables, entityId: policyCoverId }
          : variables,
        errorPolicy: 'all',
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {policyCoverId
                ? 'Policy Cover successfully updated'
                : 'Policy Cover successfully created'}
            </ToastSuccessMessage>
          );

          if (canNavigateToDetailsPage) {
            const newPolicyCoverId =
              res.data.planConfigManagement.actions.createPolicyCover.id;
            setTimeout(() => {
              setSubmitButtonState('success');
              setFormDisabled(false);
              onSuccess();
              onClose();
              navigate(`/plan/covers/` + newPolicyCoverId);
            }, 500);
          } else {
            setTimeout(() => {
              setSubmitButtonState('success');
              setFormDisabled(false);
              onSuccess();
              onClose();
            }, 500);
          }
        } else {
          setFormDisabled(false);
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setFormDisabled(false);
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const validatePolicyCoverExistsOnNameChange = async (
    selectedLineId?: string,
    policyCoverName?: string
  ): Promise<string> => {
    if (!isEmpty(lineId || selectedLineId) && !isEmpty(policyCoverName)) {
      const validationResponse = await checkPolicyCoverExistsLazy({
        variables: {
          lineId: lineId || selectedLineId,
          name: policyCoverName,
          policyCoverId: policyCoverId,
          externalCode: '',
        },
      });

      if (
        validationResponse.data.PlanConfigManagement?.queries
          ?.checkPolicyCoverExists.length > 0
      ) {
        return 'Policy Cover Name already exists under the same Line';
      } else {
        return '';
      }
    }
    return '';
  };

  const validatePolicyCoverExistsOnExternalCodeChange = async (
    selectedLineId?: string,
    policyCoverExternalCode?: string
  ) => {
    if (
      !isEmpty(lineId || selectedLineId) &&
      !isEmpty(policyCoverExternalCode)
    ) {
      const validationResponse = await checkPolicyCoverExistsLazy({
        variables: {
          lineId: lineId || selectedLineId,
          externalCode: policyCoverExternalCode,
          policyCoverId: policyCoverId,
          name: '',
        },
      });

      if (
        validationResponse.data.PlanConfigManagement?.queries
          ?.checkPolicyCoverExists.length > 0
      ) {
        return 'External Code already exists under the same Line';
      } else {
        return '';
      }
    }
    return '';
  };

  const validatePolicyCoverPrintingOrderExistsOnPrintingOrderChange = async (
    selectedLineId: string,
    policyCoverPrintingOrder: number
  ) => {
    if (
      !isEmpty(lineId || selectedLineId) &&
      !isEmpty(policyCoverPrintingOrder)
    ) {
      const validationResponse = await checkPolicyCoverPrintingOrderExistsLazy({
        variables: {
          printingOrder: Number(policyCoverPrintingOrder),
          policyCoverId: policyCoverId,
          lineId: lineId || selectedLineId,
        },
      });

      if (
        validationResponse.data.PlanConfigManagement?.queries
          ?.checkPolicyCoverPrintingOrderExists.length > 0
      ) {
        return 'Printing Order already assigned for another Policy Cover under the same line';
      } else {
        return '';
      }
    }
    return '';
  };

  useEffect(() => {
    initialize();
  }, [lineName, policyCoverInfoResult?.data]);

  const onChange = async (
    fieldName: string,
    value: unknown,
    values: Record<string, any>,
    errors: Record<string, string>,
    touched: Record<string, boolean>
  ) => {
    if (fieldName === 'lineName') {
      const [
        policyCoverError,
        policyCoverExternalCodeError,
        policyCoverPrintingOrderError,
      ] = await Promise.all([
        onCustomValidate('policyCoverName', values),
        onCustomValidate('policyCoverExternalCode', values),
        onCustomValidate('policyCoverPrintingOrder', values),
      ]);
      errors.policyCoverName = policyCoverError;
      errors.policyCoverExternalCode = policyCoverExternalCodeError;
      errors.policyCoverPrintingOrder = policyCoverPrintingOrderError;

      touched.policyCoverExternalCode = true;
      touched.policyCoverExternalCode = true;
      touched.policyCoverPrintingOrder = true;
    }
  };

  const onCustomValidate = async (
    fieldName: string,
    values: Record<string, any>
  ) => {
    if (fieldName === 'policyCoverName') {
      return await validatePolicyCoverExistsOnNameChange(
        values.lineName,
        values[fieldName]
      );
    }

    if (fieldName === 'policyCoverExternalCode') {
      return await validatePolicyCoverExistsOnExternalCodeChange(
        values.lineName,
        values[fieldName]
      );
    }

    if (fieldName === 'policyCoverPrintingOrder') {
      return await validatePolicyCoverPrintingOrderExistsOnPrintingOrderChange(
        values.lineName,
        values[fieldName]
      );
    }
    return '';
  };

  const onCustomBlur = async (
    fieldName: string,
    values: Record<string, any>,
    errors: Record<string, string>
  ) => {
    if (
      fieldName === 'policyCoverName' ||
      fieldName === 'policyCoverExternalCode' ||
      fieldName === 'policyCoverPrintingOrder'
    ) {
      if (!errors[fieldName]) {
        errors[fieldName] = await onCustomValidate(fieldName, values);
      }
    }
    return { values, errors };
  };

  const onCustomValidateForm = async (
    values: Record<string, any>,
    errors: Record<string, string>
  ) => {
    if (!errors.policyCoverName) {
      errors.policyCoverName = await onCustomValidate(
        'policyCoverName',
        values
      );
    }

    if (!errors.policyCoverExternalCode) {
      errors.policyCoverExternalCode = await onCustomValidate(
        'policyCoverExternalCode',
        values
      );
    }
    if (!errors.policyCoverPrintingOrder) {
      errors.policyCoverPrintingOrder = await onCustomValidate(
        'policyCoverPrintingOrder',
        values
      );
    }

    return errors;
  };

  return (
    <GenericDrawer
      title={policyCoverId ? 'Edit Policy Cover' : 'New Policy Cover'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {(policyCoverEnumResultsLoading || policyCoverInfoResult?.loading) &&
      open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            onChange={onChange}
            onCustomBlur={(f, v, e) => onCustomBlur(f, v, e)}
            onCustomValidate={onCustomValidateForm}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PolicyCoverDrawer;
