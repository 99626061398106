/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import WidgetPaper from '../../../components/common/WidgetPaper';
import WidgetSection from '../../../components/common/WidgetSection';
import { IProposalPageFormState } from '../../../modules/production/proposal/page/medical/form';
import { IProposalDetailsSummary } from '../../../modules/production/proposal/page/expat/index2';
import { useAppSelector } from '../../../redux/hooks';
import { formatDateYMD } from '../../../utils/formatting-utils';
import {
  contentFontFamilyBold,
  contentFontFamilyRegular,
} from '../../../constants';
import { excelToJson } from '../../../utils/excel-reader';
import UploadFileFormField from '../../../components/form-fields/UploadFileFormField';
import { cloneDeep } from 'lodash';
import { IAbstractRecord } from '../../../models';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import { isValidDate } from '../../../utils/date-utils';
import { isEmpty } from '../../../utils/validationUtils';

interface IProposalMedicalDetailsWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  allowPlanCoverEdits: boolean;
  status: string;
  data: IProposalDetailsSummary;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    marginTop: '1em',
  },
  section: {
    padding: '23px 0 25px',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(1, 100%)`,
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '20px',
    margin: '0',
    fontFamily: contentFontFamilyBold,
  },
  list: {
    fontSize: '16px',
    lineHeight: '20px',
    margin: '3px 0 10px',
    fontFamily: contentFontFamilyRegular,
  },
  inputWrapper: {
    width: '281px',
  },
  uploadInput: {
    marginBottom: '0',
  },
  uploadInputShell: {
    paddingTop: '0',
    paddingBottom: '0',
    height: '30px !important',
  },
}));

const ProposalMedicalGroupDetailsWidget: React.FC<
  IProposalMedicalDetailsWidgetProps
> = ({ disabledForm, lovs, pageState, onPageStateUpdate }) => {
  const { classes } = useStyles();
  const [fileName, setFileName] = useState(
    pageState.values.groupMedicalDetails?.length > 0
      ? 'List of Insured.xlsx'
      : ''
  );
  const tenant = useAppSelector((a) => a.tenant);

  const handleUploadFile = async (file: File) => {
    const newPageState = cloneDeep(pageState);
    setFileName(file.name);
    const insuredData = await mapExcelDataToProductionInputs(file, lovs);
    newPageState.values.groupMedicalDetails = insuredData;
    onPageStateUpdate(newPageState);
  };

  return (
    <WidgetPaper style={{ marginTop: '1em' }}>
      <WidgetSection
        title="Group Medical Details "
        hasTitleSpecificDesign={false}
        classes={{
          container: classes.section,
        }}
        useSeparator={false}
      >
        <div className={classes.fieldRow}>
          <div>
            <p className={classes.paragraph}>
              The plan selected includes the below covers:
            </p>
            <ul className={classes.list}>
              {Object.values(lovs.policyCovers).map((cover) => (
                <li key={cover}>{cover}</li>
              ))}
            </ul>
          </div>
          <UploadFileFormField
            value={fileName}
            onUpload={handleUploadFile}
            name={'listOfInsured'}
            title={'List of Insured'}
            placeholder="Upload a document"
            iconUrl={`${tenant.cdnUrl}/icons/upload-field-primary-new.svg`}
            allowedFileTypes={[]}
            className={classes.inputWrapper}
            classes={{
              label: classes.uploadInput,
              inputShell: classes.uploadInputShell,
            }}
            material={true}
            disabled={disabledForm}
            downloadLinks={[
              {
                downloadUrl:
                  tenant.cdnUrl + '/files/Group List of Insured.xlsx',
                displayName: 'Download Template',
              },
            ]}
          />
        </div>
      </WidgetSection>
    </WidgetPaper>
  );
};

export default ProposalMedicalGroupDetailsWidget;

const mapExcelDataToProductionInputs = async (
  file: File,
  lovs: Record<string, Record<string, string>>
): Promise<any[]> => {
  const excelData = await excelToJson(file);

  // Assuming data is in the first sheet
  const sheetNames = Object.keys(excelData);
  if (sheetNames.length === 0) {
    throw new Error('Excel file has no sheets');
  }
  const firstSheetName = sheetNames[0];
  const rows = excelData[firstSheetName];

  // Map Excel headers to JSON fields
  const headerMapping: Record<string, string> = {
    'Reference*': 'Reference',
    'First Name*': 'FirstName',
    'Middle Name*': 'MiddleName',
    'Last Name*': 'LastName',
    'Date of Birth*': 'DateOfBirth',
    'Gender*': 'Gender',
    'Principal Reference*': 'PrincipalReference',
    'Relation*': 'Relation',
    'Class*': 'Class',
    'Co-NSSF*': 'Nssf',
    'Level*': 'Level',
    Address: 'AddressDetails',
    Phone: 'Phone',
    'Exclusion/Limitation': 'Exclusions',
    Remarks: 'Remarks',
    Continuity: 'Continuity',
    'TPA Reference1': 'TPAReference1',
    'TPA Reference2': 'TPAReference2',
  };

  const productionInputs: any[] = [];

  for (const row of rows) {
    const productionInput: Partial<any> = {};

    for (const excelHeader in headerMapping) {
      const jsonField = headerMapping[excelHeader];
      let value = row[excelHeader];

      if (jsonField === 'Gender') {
        value = getKeyFromValue(lovs.gender, value);
      }

      if (jsonField === 'Relation') {
        value = getKeyFromValue(lovs.insuredRelations, value);

        if (
          value === 'PRINCIPAL' &&
          (isEmpty(row['Address']) ||
            (typeof row['Address'] === 'string' &&
              row['Address']?.trim() === ''))
        ) {
          toast.error(
            <ToastErrorMessage>
              {'Cannot Submit. One or many Principal records have null address'}
            </ToastErrorMessage>
          );
          break;
        }
      }

      if (jsonField === 'Class') {
        value = getKeyFromValue(lovs.classes, value);
      }

      if (jsonField === 'Nssf') {
        value = getKeyFromValue(lovs.cnssOptions, value);
      }

      if (jsonField === 'Level') {
        value = getKeyFromValue(lovs.levels, value);
      }

      if (jsonField === 'Continuity') {
        value = getKeyFromValue(lovs.continuity, value, true) || undefined;
      }

      if (jsonField === 'DateOfBirth') {
        if (!isValidDate(value)) {
          toast.error(
            <ToastErrorMessage>
              {
                'Cannot Submit. One or many Date of Birth fields have wrong format'
              }
            </ToastErrorMessage>
          );
          break;
        }
        if (isEmptyValue(value)) {
          toast.error(
            <ToastErrorMessage>
              {'Cannot Submit. One or many Date of Birth fields are null'}
            </ToastErrorMessage>
          );
          break;
        }
        value = formatDateYMD(value);
      }

      if (jsonField === 'Phone') {
        if (!isEmpty(value) && !isValidPhoneNumber(value)) {
          toast.error(
            <ToastErrorMessage>
              {
                'Cannot Submit. One or many fields in Phone column have invalid format'
              }
            </ToastErrorMessage>
          );
          break;
        }
      }

      if (
        [
          'Reference',
          'FirstName',
          'MiddleName',
          'LastName',
          'PrincipalReference',
        ].includes(jsonField)
      ) {
        if (getRequiredValidation(value)) {
          break;
        }
      }

      productionInput[jsonField] = value?.toString();
    }

    productionInputs.push(productionInput);
  }

  if (findReferenceDuplicates(productionInputs)) {
    toast.error(
      <ToastErrorMessage>
        {
          'Cannot Submit. One or many Reference Number are mentioned for more than one record'
        }
      </ToastErrorMessage>
    );
  }

  return productionInputs;
};

function findReferenceDuplicates(items: IAbstractRecord[]): boolean {
  const referenceCount: Record<string, number> = {};
  const duplicates: string[] = [];

  items.forEach((item) => {
    referenceCount[item.Reference] = (referenceCount[item.Reference] || 0) + 1;
    if (referenceCount[item.Reference] === 2) {
      duplicates.push(item.Reference);
    }
  });

  return duplicates.length > 0;
}

function getKeyFromValue(
  lov: Record<string, string>,
  value: string,
  skipValidation = false
): string | undefined {
  if (skipValidation) {
    for (const [key, val] of Object.entries(lov)) {
      if (val === value?.toString()) {
        return key; // Return the key if a match is found
      }
    }
    return '';
  }

  if (getRequiredValidation(value)) {
    return '';
  }

  for (const [key, val] of Object.entries(lov)) {
    if (val === value?.toString()) {
      return key; // Return the key if a match is found
    }
  }

  toast.error(
    <ToastErrorMessage>
      {'Cannot Submit. One or many fields have invalid format'}
    </ToastErrorMessage>
  );
  return '';
}

function isEmptyValue(value: string | number): boolean {
  return isEmpty(value) || (typeof value === 'string' && value?.trim() === '');
}

function getRequiredValidation(value: string | number): boolean {
  if (isEmptyValue(value)) {
    toast.error(
      <ToastErrorMessage>
        {'Cannot Submit. One or many mandatory fields are null.'}
      </ToastErrorMessage>
    );
    return true;
  }
  return false;
}

function isValidPhoneNumber(phone: string): boolean {
  const phoneRegex = /^(?:\+961\d{7}|961\d{7}|03\d{6}|03 \d{6})$/;
  return phoneRegex.test(phone);
}
