import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { IAmendmentMedicalDetails, IAmendmentPageState } from '..';
import { ILineAmendmentProps } from '../line-amendment';
import { validateMedicalDetailsField } from './validation';
import WidgetPaper from '../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../components/common/WidgetSection';
import EnhancedChipInput from '../../../../components/enhanced-form/EnhancedChipInput';
import { useLazyQuery } from '@apollo/client';
import { getMedicalLovsQuery } from '../queries';
import { getMedicalLovs } from '../utils';
import Loader from '../../../../components/Loader';
import { useAmendmentStyles } from '../styles';

interface IMedicalDetailsWidgetProps {
  generalData: ILineAmendmentProps['generalData'];
  pageState: IAmendmentPageState;
  onPageStateUpdate: (pageState: IAmendmentPageState) => void;
  isDisabled?: boolean;
}

export interface IMedicalLovs {
  classes: Record<string, string>;
  levels: Record<string, string>;
  policyCovers: Record<string, string>;
  cnssOptions: Record<string, string>;
}

const MedicalDetailsWidget: React.FC<IMedicalDetailsWidgetProps> = ({
  generalData,
  pageState,
  onPageStateUpdate,
  isDisabled,
}) => {
  const { classes } = useAmendmentStyles();

  const values = pageState.values.medicalDetails;
  const errors = pageState.errors.medicalDetails;
  const touched = pageState.touched.medicalDetails;

  const [lovs, setLovs] = useState<IMedicalLovs>({
    classes: {},
    cnssOptions: {},
    levels: {},
    policyCovers: {},
  });

  const [getMedicalLovsLazy, { loading }] = useLazyQuery(getMedicalLovsQuery);

  const initialize = async () => {
    const { data: lovsData } = await getMedicalLovsLazy({
      variables: {
        selectedPlanID: generalData.planId,
      },
    });

    const lists = getMedicalLovs(lovsData);
    setLovs({
      classes: lists.classes,
      cnssOptions: lists.cnssOptions,
      levels: lists.levels,
      policyCovers: lists.policyCovers,
    });
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFieldUpdate = (
    fieldName: keyof IAmendmentMedicalDetails,
    value: unknown,
    touchedField = false
  ) => {
    const newPageState = _.cloneDeep(pageState);
    (newPageState.values.medicalDetails as any)[fieldName] = value;
    newPageState.errors.medicalDetails[fieldName] = validateMedicalDetailsField(
      fieldName,
      value,
      generalData?.lineExternalCode
    );
    if (touchedField) {
      newPageState.touched.medicalDetails[fieldName] = true;
    }
    onPageStateUpdate(newPageState);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <WidgetPaper>
      <WidgetSection title="Medical Details" isFieldsGrid fieldsPerRow={3}>
        <EnhancedChipInput
          key="class"
          name="class"
          title="Class*"
          placeholder="Class"
          value={values.class}
          error={touched.class ? errors.class : ''}
          onChange={(v) => onFieldUpdate('class', v, true)}
          disabled={isDisabled}
          selectOptions={lovs.classes}
          required
          multiple={false}
          material
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedChipInput
          key="level"
          name="level"
          title="Level*"
          placeholder="Level"
          value={values.level}
          error={touched.level ? errors.level : ''}
          onChange={(v) => onFieldUpdate('level', v, true)}
          disabled={isDisabled}
          selectOptions={lovs.levels}
          required
          multiple={false}
          material
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedChipInput
          key="cnss"
          name="cnss"
          title="CNSS*"
          placeholder="CNSS"
          value={values.cnss}
          error={touched.cnss ? errors.cnss : ''}
          onChange={(v) => onFieldUpdate('cnss', v, true)}
          disabled={isDisabled}
          selectOptions={lovs.cnssOptions}
          required
          multiple={false}
          material
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />

        <EnhancedChipInput
          key="policyCover"
          name="policyCover"
          title="Policy Cover*"
          placeholder="Policy Cover"
          value={values.policyCover}
          error={touched.policyCover ? errors.policyCover : ''}
          onChange={(v) => onFieldUpdate('policyCover', v, true)}
          disabled={isDisabled}
          selectOptions={lovs.policyCovers}
          required
          multiple
          material
          className={classes.fullWidthItem}
          customStyles={{
            labelStyles: classes.labelHeader,
          }}
        />
      </WidgetSection>
    </WidgetPaper>
  );
};

export default MedicalDetailsWidget;
